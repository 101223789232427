/*eslint-disable*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";
$protobuf.util.Long = require('long');
$protobuf.configure();
    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.protocol = (function() {
    
        /**
         * Namespace protocol.
         * @exports protocol
         * @namespace
         */
        var protocol = {};
    
        protocol.ClientRequest = (function() {
    
            /**
             * Properties of a ClientRequest.
             * @memberof protocol
             * @interface IClientRequest
             * @property {string|null} [trx] ClientRequest trx
             * @property {string|null} [clientId] ClientRequest clientId
             * @property {string|null} [authToken] ClientRequest authToken
             * @property {protocol.ICreateNewAddressRequest|null} [createNewAddressRequest] ClientRequest createNewAddressRequest
             * @property {protocol.IBalancesRequest|null} [balancesRequest] ClientRequest balancesRequest
             * @property {protocol.ITransactionsRequest|null} [transactionsRequest] ClientRequest transactionsRequest
             * @property {protocol.IEnqueuePayoutRequest|null} [enqueuePayoutRequest] ClientRequest enqueuePayoutRequest
             * @property {protocol.IPayoutRequestDetailsRequest|null} [payoutRequestDetailsRequest] ClientRequest payoutRequestDetailsRequest
             * @property {protocol.IMainAccountsRequest|null} [mainAccountsRequest] ClientRequest mainAccountsRequest
             * @property {protocol.IModifyTagsRequest|null} [modifyTagsRequest] ClientRequest modifyTagsRequest
             * @property {protocol.IListTagsRequest|null} [listTagsRequest] ClientRequest listTagsRequest
             * @property {protocol.IConfirmedClientTransactionsRequest|null} [confirmedClientTransactionsRequest] ClientRequest confirmedClientTransactionsRequest
             * @property {protocol.IPayoutDetailsRequest|null} [payoutDetailsRequest] ClientRequest payoutDetailsRequest
             * @property {protocol.IConvertCryptoToFiatRequest|null} [convertCryptoToFiatRequest] ClientRequest convertCryptoToFiatRequest
             * @property {protocol.ITransactionFeeRequest|null} [transactionFeeRequest] ClientRequest transactionFeeRequest
             * @property {protocol.IClientRequestLogsRequest|null} [clientRequestLogsRequest] ClientRequest clientRequestLogsRequest
             * @property {protocol.IListPayoutRequestsRequest|null} [listPayoutRequestsRequest] ClientRequest listPayoutRequestsRequest
             * @property {protocol.IAMLSingleCheckRequest|null} [amlSingleCheckRequest] ClientRequest amlSingleCheckRequest
             * @property {protocol.IAMLCheckResultRequest|null} [amlCheckResultRequest] ClientRequest amlCheckResultRequest
             * @property {protocol.IAMLClientBatchCheckStartRequest|null} [amlClientBatchCheckStartRequest] ClientRequest amlClientBatchCheckStartRequest
             * @property {protocol.IAMLClientBatchCheckStopRequest|null} [amlClientBatchCheckStopRequest] ClientRequest amlClientBatchCheckStopRequest
             * @property {protocol.IAMLBatchCheckResultsRequest|null} [amlBatchCheckResultsRequest] ClientRequest amlBatchCheckResultsRequest
             * @property {protocol.IAMLServiceStatusRequest|null} [amlServiceStatusRequest] ClientRequest amlServiceStatusRequest
             * @property {protocol.ITransactionsPagedRequest|null} [transactionsPagedRequest] ClientRequest transactionsPagedRequest
             * @property {protocol.ITransactionsStatsRequest|null} [transactionsStatsRequest] ClientRequest transactionsStatsRequest
             * @property {protocol.IAMLClientOverallStatsRequest|null} [amlClientOverallStatsRequest] ClientRequest amlClientOverallStatsRequest
             * @property {protocol.IAMLClientAddressesListRequest|null} [amlClientAddressesListRequest] ClientRequest amlClientAddressesListRequest
             * @property {protocol.IAMLClientBatchStatsRequest|null} [amlClientBatchStatsRequest] ClientRequest amlClientBatchStatsRequest
             * @property {protocol.IAMLClientBatchAddressesListRequest|null} [amlClientBatchAddressesListRequest] ClientRequest amlClientBatchAddressesListRequest
             * @property {protocol.IAMLClientBatchChecksListRequest|null} [amlClientBatchChecksListRequest] ClientRequest amlClientBatchChecksListRequest
             * @property {protocol.IAMLClientBatchAddressRequest|null} [amlClientBatchAddressRequest] ClientRequest amlClientBatchAddressRequest
             * @property {protocol.IFeeRatesRequest|null} [feeRatesRequest] ClientRequest feeRatesRequest
             * @property {protocol.ICreateClientPeerRequest|null} [createClientPeerRequest] ClientRequest createClientPeerRequest
             * @property {protocol.IGenerateNewClientPeerAddressRequest|null} [generateNewClientPeerAddressRequest] ClientRequest generateNewClientPeerAddressRequest
             * @property {protocol.IRegenerateClientPeerAddressRequest|null} [regenerateClientPeerAddressRequest] ClientRequest regenerateClientPeerAddressRequest
             * @property {protocol.IClientPeersShortListRequest|null} [clientPeersShortListRequest] ClientRequest clientPeersShortListRequest
             * @property {protocol.IClientPeersListRequest|null} [clientPeersListRequest] ClientRequest clientPeersListRequest
             * @property {protocol.IClientPeerDetailsRequest|null} [clientPeerDetailsRequest] ClientRequest clientPeerDetailsRequest
             * @property {protocol.IClientPeerAddressesListRequest|null} [clientPeerAddressesListRequest] ClientRequest clientPeerAddressesListRequest
             * @property {protocol.IClientPeerTransactionsRequest|null} [clientPeerTransactionsRequest] ClientRequest clientPeerTransactionsRequest
             * @property {protocol.IDeleteClientPeerAddressRequest|null} [deleteClientPeerAddressRequest] ClientRequest deleteClientPeerAddressRequest
             * @property {protocol.IAuthUserRequest|null} [authUserRequest] ClientRequest authUserRequest
             * @property {protocol.ICheckSessionRequest|null} [checkSessionRequest] ClientRequest checkSessionRequest
             * @property {protocol.IAdminCreateClientUserRequest|null} [adminCreateClientUserRequest] ClientRequest adminCreateClientUserRequest
             * @property {protocol.IAdminClientUsersListRequest|null} [adminClientUsersListRequest] ClientRequest adminClientUsersListRequest
             * @property {protocol.IAdminUpdateClientUserAccessGroupsRequest|null} [adminUpdateClientUserAccessGroupsRequest] ClientRequest adminUpdateClientUserAccessGroupsRequest
             * @property {protocol.IAdminUpdateClientUserPasswordRequest|null} [adminUpdateClientUserPasswordRequest] ClientRequest adminUpdateClientUserPasswordRequest
             * @property {protocol.IClientPeersStatsRequest|null} [clientPeersStatsRequest] ClientRequest clientPeersStatsRequest
             * @property {protocol.IClientPeerTransactionsStatsRequest|null} [clientPeerTransactionsStatsRequest] ClientRequest clientPeerTransactionsStatsRequest
             * @property {protocol.IClientAddressesTagsListRequest|null} [clientAddressesTagsListRequest] ClientRequest clientAddressesTagsListRequest
             * @property {protocol.IClientSystemAddressesTagGroupsListRequest|null} [clientSystemAddressesTagGroupsListRequest] ClientRequest clientSystemAddressesTagGroupsListRequest
             * @property {protocol.ICreateClientSystemAddressRequest|null} [createClientSystemAddressRequest] ClientRequest createClientSystemAddressRequest
             * @property {protocol.IClientTotalBalancesRequest|null} [clientTotalBalancesRequest] ClientRequest clientTotalBalancesRequest
             * @property {protocol.IClientPeerChangeTransactionAmountRequest|null} [clientPeerChangeTransactionAmountRequest] ClientRequest clientPeerChangeTransactionAmountRequest
             * @property {protocol.IAppVersionRequest|null} [appVersionRequest] ClientRequest appVersionRequest
             * @property {protocol.IHasChangesDashboardRequest|null} [hasChangesDashboardRequest] ClientRequest hasChangesDashboardRequest
             * @property {protocol.ILatestChangesDashboardRequest|null} [latestChangesDashboardRequest] ClientRequest latestChangesDashboardRequest
             * @property {protocol.ICheckTokenPayoutSystemBalanceRequest|null} [checkTokenPayoutSystemBalanceRequest] ClientRequest checkTokenPayoutSystemBalanceRequest
             * @property {protocol.ICancelPendingPayoutRequestRequest|null} [cancelPendingPayoutRequestRequest] ClientRequest cancelPendingPayoutRequestRequest
             * @property {protocol.IAMLClientUnboundChecksListRequest|null} [amlClientUnboundChecksListRequest] ClientRequest amlClientUnboundChecksListRequest
             * @property {protocol.IClientAddressesListRequest|null} [clientAddressesListRequest] ClientRequest clientAddressesListRequest
             */
    
            /**
             * Constructs a new ClientRequest.
             * @memberof protocol
             * @classdesc Represents a ClientRequest.
             * @implements IClientRequest
             * @constructor
             * @param {protocol.IClientRequest=} [properties] Properties to set
             */
            function ClientRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientRequest trx.
             * @member {string} trx
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.trx = "";
    
            /**
             * ClientRequest clientId.
             * @member {string} clientId
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.clientId = "";
    
            /**
             * ClientRequest authToken.
             * @member {string} authToken
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.authToken = "";
    
            /**
             * ClientRequest createNewAddressRequest.
             * @member {protocol.ICreateNewAddressRequest|null|undefined} createNewAddressRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.createNewAddressRequest = null;
    
            /**
             * ClientRequest balancesRequest.
             * @member {protocol.IBalancesRequest|null|undefined} balancesRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.balancesRequest = null;
    
            /**
             * ClientRequest transactionsRequest.
             * @member {protocol.ITransactionsRequest|null|undefined} transactionsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.transactionsRequest = null;
    
            /**
             * ClientRequest enqueuePayoutRequest.
             * @member {protocol.IEnqueuePayoutRequest|null|undefined} enqueuePayoutRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.enqueuePayoutRequest = null;
    
            /**
             * ClientRequest payoutRequestDetailsRequest.
             * @member {protocol.IPayoutRequestDetailsRequest|null|undefined} payoutRequestDetailsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.payoutRequestDetailsRequest = null;
    
            /**
             * ClientRequest mainAccountsRequest.
             * @member {protocol.IMainAccountsRequest|null|undefined} mainAccountsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.mainAccountsRequest = null;
    
            /**
             * ClientRequest modifyTagsRequest.
             * @member {protocol.IModifyTagsRequest|null|undefined} modifyTagsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.modifyTagsRequest = null;
    
            /**
             * ClientRequest listTagsRequest.
             * @member {protocol.IListTagsRequest|null|undefined} listTagsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.listTagsRequest = null;
    
            /**
             * ClientRequest confirmedClientTransactionsRequest.
             * @member {protocol.IConfirmedClientTransactionsRequest|null|undefined} confirmedClientTransactionsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.confirmedClientTransactionsRequest = null;
    
            /**
             * ClientRequest payoutDetailsRequest.
             * @member {protocol.IPayoutDetailsRequest|null|undefined} payoutDetailsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.payoutDetailsRequest = null;
    
            /**
             * ClientRequest convertCryptoToFiatRequest.
             * @member {protocol.IConvertCryptoToFiatRequest|null|undefined} convertCryptoToFiatRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.convertCryptoToFiatRequest = null;
    
            /**
             * ClientRequest transactionFeeRequest.
             * @member {protocol.ITransactionFeeRequest|null|undefined} transactionFeeRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.transactionFeeRequest = null;
    
            /**
             * ClientRequest clientRequestLogsRequest.
             * @member {protocol.IClientRequestLogsRequest|null|undefined} clientRequestLogsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.clientRequestLogsRequest = null;
    
            /**
             * ClientRequest listPayoutRequestsRequest.
             * @member {protocol.IListPayoutRequestsRequest|null|undefined} listPayoutRequestsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.listPayoutRequestsRequest = null;
    
            /**
             * ClientRequest amlSingleCheckRequest.
             * @member {protocol.IAMLSingleCheckRequest|null|undefined} amlSingleCheckRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.amlSingleCheckRequest = null;
    
            /**
             * ClientRequest amlCheckResultRequest.
             * @member {protocol.IAMLCheckResultRequest|null|undefined} amlCheckResultRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.amlCheckResultRequest = null;
    
            /**
             * ClientRequest amlClientBatchCheckStartRequest.
             * @member {protocol.IAMLClientBatchCheckStartRequest|null|undefined} amlClientBatchCheckStartRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.amlClientBatchCheckStartRequest = null;
    
            /**
             * ClientRequest amlClientBatchCheckStopRequest.
             * @member {protocol.IAMLClientBatchCheckStopRequest|null|undefined} amlClientBatchCheckStopRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.amlClientBatchCheckStopRequest = null;
    
            /**
             * ClientRequest amlBatchCheckResultsRequest.
             * @member {protocol.IAMLBatchCheckResultsRequest|null|undefined} amlBatchCheckResultsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.amlBatchCheckResultsRequest = null;
    
            /**
             * ClientRequest amlServiceStatusRequest.
             * @member {protocol.IAMLServiceStatusRequest|null|undefined} amlServiceStatusRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.amlServiceStatusRequest = null;
    
            /**
             * ClientRequest transactionsPagedRequest.
             * @member {protocol.ITransactionsPagedRequest|null|undefined} transactionsPagedRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.transactionsPagedRequest = null;
    
            /**
             * ClientRequest transactionsStatsRequest.
             * @member {protocol.ITransactionsStatsRequest|null|undefined} transactionsStatsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.transactionsStatsRequest = null;
    
            /**
             * ClientRequest amlClientOverallStatsRequest.
             * @member {protocol.IAMLClientOverallStatsRequest|null|undefined} amlClientOverallStatsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.amlClientOverallStatsRequest = null;
    
            /**
             * ClientRequest amlClientAddressesListRequest.
             * @member {protocol.IAMLClientAddressesListRequest|null|undefined} amlClientAddressesListRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.amlClientAddressesListRequest = null;
    
            /**
             * ClientRequest amlClientBatchStatsRequest.
             * @member {protocol.IAMLClientBatchStatsRequest|null|undefined} amlClientBatchStatsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.amlClientBatchStatsRequest = null;
    
            /**
             * ClientRequest amlClientBatchAddressesListRequest.
             * @member {protocol.IAMLClientBatchAddressesListRequest|null|undefined} amlClientBatchAddressesListRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.amlClientBatchAddressesListRequest = null;
    
            /**
             * ClientRequest amlClientBatchChecksListRequest.
             * @member {protocol.IAMLClientBatchChecksListRequest|null|undefined} amlClientBatchChecksListRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.amlClientBatchChecksListRequest = null;
    
            /**
             * ClientRequest amlClientBatchAddressRequest.
             * @member {protocol.IAMLClientBatchAddressRequest|null|undefined} amlClientBatchAddressRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.amlClientBatchAddressRequest = null;
    
            /**
             * ClientRequest feeRatesRequest.
             * @member {protocol.IFeeRatesRequest|null|undefined} feeRatesRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.feeRatesRequest = null;
    
            /**
             * ClientRequest createClientPeerRequest.
             * @member {protocol.ICreateClientPeerRequest|null|undefined} createClientPeerRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.createClientPeerRequest = null;
    
            /**
             * ClientRequest generateNewClientPeerAddressRequest.
             * @member {protocol.IGenerateNewClientPeerAddressRequest|null|undefined} generateNewClientPeerAddressRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.generateNewClientPeerAddressRequest = null;
    
            /**
             * ClientRequest regenerateClientPeerAddressRequest.
             * @member {protocol.IRegenerateClientPeerAddressRequest|null|undefined} regenerateClientPeerAddressRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.regenerateClientPeerAddressRequest = null;
    
            /**
             * ClientRequest clientPeersShortListRequest.
             * @member {protocol.IClientPeersShortListRequest|null|undefined} clientPeersShortListRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.clientPeersShortListRequest = null;
    
            /**
             * ClientRequest clientPeersListRequest.
             * @member {protocol.IClientPeersListRequest|null|undefined} clientPeersListRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.clientPeersListRequest = null;
    
            /**
             * ClientRequest clientPeerDetailsRequest.
             * @member {protocol.IClientPeerDetailsRequest|null|undefined} clientPeerDetailsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.clientPeerDetailsRequest = null;
    
            /**
             * ClientRequest clientPeerAddressesListRequest.
             * @member {protocol.IClientPeerAddressesListRequest|null|undefined} clientPeerAddressesListRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.clientPeerAddressesListRequest = null;
    
            /**
             * ClientRequest clientPeerTransactionsRequest.
             * @member {protocol.IClientPeerTransactionsRequest|null|undefined} clientPeerTransactionsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.clientPeerTransactionsRequest = null;
    
            /**
             * ClientRequest deleteClientPeerAddressRequest.
             * @member {protocol.IDeleteClientPeerAddressRequest|null|undefined} deleteClientPeerAddressRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.deleteClientPeerAddressRequest = null;
    
            /**
             * ClientRequest authUserRequest.
             * @member {protocol.IAuthUserRequest|null|undefined} authUserRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.authUserRequest = null;
    
            /**
             * ClientRequest checkSessionRequest.
             * @member {protocol.ICheckSessionRequest|null|undefined} checkSessionRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.checkSessionRequest = null;
    
            /**
             * ClientRequest adminCreateClientUserRequest.
             * @member {protocol.IAdminCreateClientUserRequest|null|undefined} adminCreateClientUserRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.adminCreateClientUserRequest = null;
    
            /**
             * ClientRequest adminClientUsersListRequest.
             * @member {protocol.IAdminClientUsersListRequest|null|undefined} adminClientUsersListRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.adminClientUsersListRequest = null;
    
            /**
             * ClientRequest adminUpdateClientUserAccessGroupsRequest.
             * @member {protocol.IAdminUpdateClientUserAccessGroupsRequest|null|undefined} adminUpdateClientUserAccessGroupsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.adminUpdateClientUserAccessGroupsRequest = null;
    
            /**
             * ClientRequest adminUpdateClientUserPasswordRequest.
             * @member {protocol.IAdminUpdateClientUserPasswordRequest|null|undefined} adminUpdateClientUserPasswordRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.adminUpdateClientUserPasswordRequest = null;
    
            /**
             * ClientRequest clientPeersStatsRequest.
             * @member {protocol.IClientPeersStatsRequest|null|undefined} clientPeersStatsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.clientPeersStatsRequest = null;
    
            /**
             * ClientRequest clientPeerTransactionsStatsRequest.
             * @member {protocol.IClientPeerTransactionsStatsRequest|null|undefined} clientPeerTransactionsStatsRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.clientPeerTransactionsStatsRequest = null;
    
            /**
             * ClientRequest clientAddressesTagsListRequest.
             * @member {protocol.IClientAddressesTagsListRequest|null|undefined} clientAddressesTagsListRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.clientAddressesTagsListRequest = null;
    
            /**
             * ClientRequest clientSystemAddressesTagGroupsListRequest.
             * @member {protocol.IClientSystemAddressesTagGroupsListRequest|null|undefined} clientSystemAddressesTagGroupsListRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.clientSystemAddressesTagGroupsListRequest = null;
    
            /**
             * ClientRequest createClientSystemAddressRequest.
             * @member {protocol.ICreateClientSystemAddressRequest|null|undefined} createClientSystemAddressRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.createClientSystemAddressRequest = null;
    
            /**
             * ClientRequest clientTotalBalancesRequest.
             * @member {protocol.IClientTotalBalancesRequest|null|undefined} clientTotalBalancesRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.clientTotalBalancesRequest = null;
    
            /**
             * ClientRequest clientPeerChangeTransactionAmountRequest.
             * @member {protocol.IClientPeerChangeTransactionAmountRequest|null|undefined} clientPeerChangeTransactionAmountRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.clientPeerChangeTransactionAmountRequest = null;
    
            /**
             * ClientRequest appVersionRequest.
             * @member {protocol.IAppVersionRequest|null|undefined} appVersionRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.appVersionRequest = null;
    
            /**
             * ClientRequest hasChangesDashboardRequest.
             * @member {protocol.IHasChangesDashboardRequest|null|undefined} hasChangesDashboardRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.hasChangesDashboardRequest = null;
    
            /**
             * ClientRequest latestChangesDashboardRequest.
             * @member {protocol.ILatestChangesDashboardRequest|null|undefined} latestChangesDashboardRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.latestChangesDashboardRequest = null;
    
            /**
             * ClientRequest checkTokenPayoutSystemBalanceRequest.
             * @member {protocol.ICheckTokenPayoutSystemBalanceRequest|null|undefined} checkTokenPayoutSystemBalanceRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.checkTokenPayoutSystemBalanceRequest = null;
    
            /**
             * ClientRequest cancelPendingPayoutRequestRequest.
             * @member {protocol.ICancelPendingPayoutRequestRequest|null|undefined} cancelPendingPayoutRequestRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.cancelPendingPayoutRequestRequest = null;
    
            /**
             * ClientRequest amlClientUnboundChecksListRequest.
             * @member {protocol.IAMLClientUnboundChecksListRequest|null|undefined} amlClientUnboundChecksListRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.amlClientUnboundChecksListRequest = null;
    
            /**
             * ClientRequest clientAddressesListRequest.
             * @member {protocol.IClientAddressesListRequest|null|undefined} clientAddressesListRequest
             * @memberof protocol.ClientRequest
             * @instance
             */
            ClientRequest.prototype.clientAddressesListRequest = null;
    
            /**
             * Creates a new ClientRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ClientRequest
             * @static
             * @param {protocol.IClientRequest=} [properties] Properties to set
             * @returns {protocol.ClientRequest} ClientRequest instance
             */
            ClientRequest.create = function create(properties) {
                return new ClientRequest(properties);
            };
    
            /**
             * Encodes the specified ClientRequest message. Does not implicitly {@link protocol.ClientRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientRequest
             * @static
             * @param {protocol.IClientRequest} message ClientRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.trx != null && Object.hasOwnProperty.call(message, "trx"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.trx);
                if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.clientId);
                if (message.authToken != null && Object.hasOwnProperty.call(message, "authToken"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.authToken);
                if (message.createNewAddressRequest != null && Object.hasOwnProperty.call(message, "createNewAddressRequest"))
                    $root.protocol.CreateNewAddressRequest.encode(message.createNewAddressRequest, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.balancesRequest != null && Object.hasOwnProperty.call(message, "balancesRequest"))
                    $root.protocol.BalancesRequest.encode(message.balancesRequest, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.transactionsRequest != null && Object.hasOwnProperty.call(message, "transactionsRequest"))
                    $root.protocol.TransactionsRequest.encode(message.transactionsRequest, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.enqueuePayoutRequest != null && Object.hasOwnProperty.call(message, "enqueuePayoutRequest"))
                    $root.protocol.EnqueuePayoutRequest.encode(message.enqueuePayoutRequest, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.payoutRequestDetailsRequest != null && Object.hasOwnProperty.call(message, "payoutRequestDetailsRequest"))
                    $root.protocol.PayoutRequestDetailsRequest.encode(message.payoutRequestDetailsRequest, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.mainAccountsRequest != null && Object.hasOwnProperty.call(message, "mainAccountsRequest"))
                    $root.protocol.MainAccountsRequest.encode(message.mainAccountsRequest, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.modifyTagsRequest != null && Object.hasOwnProperty.call(message, "modifyTagsRequest"))
                    $root.protocol.ModifyTagsRequest.encode(message.modifyTagsRequest, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.listTagsRequest != null && Object.hasOwnProperty.call(message, "listTagsRequest"))
                    $root.protocol.ListTagsRequest.encode(message.listTagsRequest, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.confirmedClientTransactionsRequest != null && Object.hasOwnProperty.call(message, "confirmedClientTransactionsRequest"))
                    $root.protocol.ConfirmedClientTransactionsRequest.encode(message.confirmedClientTransactionsRequest, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.payoutDetailsRequest != null && Object.hasOwnProperty.call(message, "payoutDetailsRequest"))
                    $root.protocol.PayoutDetailsRequest.encode(message.payoutDetailsRequest, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                if (message.convertCryptoToFiatRequest != null && Object.hasOwnProperty.call(message, "convertCryptoToFiatRequest"))
                    $root.protocol.ConvertCryptoToFiatRequest.encode(message.convertCryptoToFiatRequest, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.transactionFeeRequest != null && Object.hasOwnProperty.call(message, "transactionFeeRequest"))
                    $root.protocol.TransactionFeeRequest.encode(message.transactionFeeRequest, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.clientRequestLogsRequest != null && Object.hasOwnProperty.call(message, "clientRequestLogsRequest"))
                    $root.protocol.ClientRequestLogsRequest.encode(message.clientRequestLogsRequest, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                if (message.listPayoutRequestsRequest != null && Object.hasOwnProperty.call(message, "listPayoutRequestsRequest"))
                    $root.protocol.ListPayoutRequestsRequest.encode(message.listPayoutRequestsRequest, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                if (message.amlSingleCheckRequest != null && Object.hasOwnProperty.call(message, "amlSingleCheckRequest"))
                    $root.protocol.AMLSingleCheckRequest.encode(message.amlSingleCheckRequest, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                if (message.amlCheckResultRequest != null && Object.hasOwnProperty.call(message, "amlCheckResultRequest"))
                    $root.protocol.AMLCheckResultRequest.encode(message.amlCheckResultRequest, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                if (message.amlClientBatchCheckStartRequest != null && Object.hasOwnProperty.call(message, "amlClientBatchCheckStartRequest"))
                    $root.protocol.AMLClientBatchCheckStartRequest.encode(message.amlClientBatchCheckStartRequest, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                if (message.amlClientBatchCheckStopRequest != null && Object.hasOwnProperty.call(message, "amlClientBatchCheckStopRequest"))
                    $root.protocol.AMLClientBatchCheckStopRequest.encode(message.amlClientBatchCheckStopRequest, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                if (message.amlBatchCheckResultsRequest != null && Object.hasOwnProperty.call(message, "amlBatchCheckResultsRequest"))
                    $root.protocol.AMLBatchCheckResultsRequest.encode(message.amlBatchCheckResultsRequest, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                if (message.amlServiceStatusRequest != null && Object.hasOwnProperty.call(message, "amlServiceStatusRequest"))
                    $root.protocol.AMLServiceStatusRequest.encode(message.amlServiceStatusRequest, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
                if (message.transactionsPagedRequest != null && Object.hasOwnProperty.call(message, "transactionsPagedRequest"))
                    $root.protocol.TransactionsPagedRequest.encode(message.transactionsPagedRequest, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
                if (message.transactionsStatsRequest != null && Object.hasOwnProperty.call(message, "transactionsStatsRequest"))
                    $root.protocol.TransactionsStatsRequest.encode(message.transactionsStatsRequest, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
                if (message.amlClientOverallStatsRequest != null && Object.hasOwnProperty.call(message, "amlClientOverallStatsRequest"))
                    $root.protocol.AMLClientOverallStatsRequest.encode(message.amlClientOverallStatsRequest, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
                if (message.amlClientAddressesListRequest != null && Object.hasOwnProperty.call(message, "amlClientAddressesListRequest"))
                    $root.protocol.AMLClientAddressesListRequest.encode(message.amlClientAddressesListRequest, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
                if (message.amlClientBatchStatsRequest != null && Object.hasOwnProperty.call(message, "amlClientBatchStatsRequest"))
                    $root.protocol.AMLClientBatchStatsRequest.encode(message.amlClientBatchStatsRequest, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                if (message.amlClientBatchAddressesListRequest != null && Object.hasOwnProperty.call(message, "amlClientBatchAddressesListRequest"))
                    $root.protocol.AMLClientBatchAddressesListRequest.encode(message.amlClientBatchAddressesListRequest, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
                if (message.amlClientBatchChecksListRequest != null && Object.hasOwnProperty.call(message, "amlClientBatchChecksListRequest"))
                    $root.protocol.AMLClientBatchChecksListRequest.encode(message.amlClientBatchChecksListRequest, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
                if (message.amlClientBatchAddressRequest != null && Object.hasOwnProperty.call(message, "amlClientBatchAddressRequest"))
                    $root.protocol.AMLClientBatchAddressRequest.encode(message.amlClientBatchAddressRequest, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
                if (message.feeRatesRequest != null && Object.hasOwnProperty.call(message, "feeRatesRequest"))
                    $root.protocol.FeeRatesRequest.encode(message.feeRatesRequest, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
                if (message.createClientPeerRequest != null && Object.hasOwnProperty.call(message, "createClientPeerRequest"))
                    $root.protocol.CreateClientPeerRequest.encode(message.createClientPeerRequest, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
                if (message.generateNewClientPeerAddressRequest != null && Object.hasOwnProperty.call(message, "generateNewClientPeerAddressRequest"))
                    $root.protocol.GenerateNewClientPeerAddressRequest.encode(message.generateNewClientPeerAddressRequest, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
                if (message.regenerateClientPeerAddressRequest != null && Object.hasOwnProperty.call(message, "regenerateClientPeerAddressRequest"))
                    $root.protocol.RegenerateClientPeerAddressRequest.encode(message.regenerateClientPeerAddressRequest, writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
                if (message.clientPeersShortListRequest != null && Object.hasOwnProperty.call(message, "clientPeersShortListRequest"))
                    $root.protocol.ClientPeersShortListRequest.encode(message.clientPeersShortListRequest, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
                if (message.clientPeersListRequest != null && Object.hasOwnProperty.call(message, "clientPeersListRequest"))
                    $root.protocol.ClientPeersListRequest.encode(message.clientPeersListRequest, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
                if (message.clientPeerDetailsRequest != null && Object.hasOwnProperty.call(message, "clientPeerDetailsRequest"))
                    $root.protocol.ClientPeerDetailsRequest.encode(message.clientPeerDetailsRequest, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
                if (message.clientPeerAddressesListRequest != null && Object.hasOwnProperty.call(message, "clientPeerAddressesListRequest"))
                    $root.protocol.ClientPeerAddressesListRequest.encode(message.clientPeerAddressesListRequest, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
                if (message.clientPeerTransactionsRequest != null && Object.hasOwnProperty.call(message, "clientPeerTransactionsRequest"))
                    $root.protocol.ClientPeerTransactionsRequest.encode(message.clientPeerTransactionsRequest, writer.uint32(/* id 47, wireType 2 =*/378).fork()).ldelim();
                if (message.deleteClientPeerAddressRequest != null && Object.hasOwnProperty.call(message, "deleteClientPeerAddressRequest"))
                    $root.protocol.DeleteClientPeerAddressRequest.encode(message.deleteClientPeerAddressRequest, writer.uint32(/* id 48, wireType 2 =*/386).fork()).ldelim();
                if (message.authUserRequest != null && Object.hasOwnProperty.call(message, "authUserRequest"))
                    $root.protocol.AuthUserRequest.encode(message.authUserRequest, writer.uint32(/* id 49, wireType 2 =*/394).fork()).ldelim();
                if (message.checkSessionRequest != null && Object.hasOwnProperty.call(message, "checkSessionRequest"))
                    $root.protocol.CheckSessionRequest.encode(message.checkSessionRequest, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
                if (message.adminCreateClientUserRequest != null && Object.hasOwnProperty.call(message, "adminCreateClientUserRequest"))
                    $root.protocol.AdminCreateClientUserRequest.encode(message.adminCreateClientUserRequest, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
                if (message.adminClientUsersListRequest != null && Object.hasOwnProperty.call(message, "adminClientUsersListRequest"))
                    $root.protocol.AdminClientUsersListRequest.encode(message.adminClientUsersListRequest, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
                if (message.adminUpdateClientUserAccessGroupsRequest != null && Object.hasOwnProperty.call(message, "adminUpdateClientUserAccessGroupsRequest"))
                    $root.protocol.AdminUpdateClientUserAccessGroupsRequest.encode(message.adminUpdateClientUserAccessGroupsRequest, writer.uint32(/* id 53, wireType 2 =*/426).fork()).ldelim();
                if (message.adminUpdateClientUserPasswordRequest != null && Object.hasOwnProperty.call(message, "adminUpdateClientUserPasswordRequest"))
                    $root.protocol.AdminUpdateClientUserPasswordRequest.encode(message.adminUpdateClientUserPasswordRequest, writer.uint32(/* id 54, wireType 2 =*/434).fork()).ldelim();
                if (message.clientPeersStatsRequest != null && Object.hasOwnProperty.call(message, "clientPeersStatsRequest"))
                    $root.protocol.ClientPeersStatsRequest.encode(message.clientPeersStatsRequest, writer.uint32(/* id 55, wireType 2 =*/442).fork()).ldelim();
                if (message.clientPeerTransactionsStatsRequest != null && Object.hasOwnProperty.call(message, "clientPeerTransactionsStatsRequest"))
                    $root.protocol.ClientPeerTransactionsStatsRequest.encode(message.clientPeerTransactionsStatsRequest, writer.uint32(/* id 56, wireType 2 =*/450).fork()).ldelim();
                if (message.clientAddressesTagsListRequest != null && Object.hasOwnProperty.call(message, "clientAddressesTagsListRequest"))
                    $root.protocol.ClientAddressesTagsListRequest.encode(message.clientAddressesTagsListRequest, writer.uint32(/* id 57, wireType 2 =*/458).fork()).ldelim();
                if (message.clientSystemAddressesTagGroupsListRequest != null && Object.hasOwnProperty.call(message, "clientSystemAddressesTagGroupsListRequest"))
                    $root.protocol.ClientSystemAddressesTagGroupsListRequest.encode(message.clientSystemAddressesTagGroupsListRequest, writer.uint32(/* id 58, wireType 2 =*/466).fork()).ldelim();
                if (message.createClientSystemAddressRequest != null && Object.hasOwnProperty.call(message, "createClientSystemAddressRequest"))
                    $root.protocol.CreateClientSystemAddressRequest.encode(message.createClientSystemAddressRequest, writer.uint32(/* id 59, wireType 2 =*/474).fork()).ldelim();
                if (message.clientTotalBalancesRequest != null && Object.hasOwnProperty.call(message, "clientTotalBalancesRequest"))
                    $root.protocol.ClientTotalBalancesRequest.encode(message.clientTotalBalancesRequest, writer.uint32(/* id 60, wireType 2 =*/482).fork()).ldelim();
                if (message.clientPeerChangeTransactionAmountRequest != null && Object.hasOwnProperty.call(message, "clientPeerChangeTransactionAmountRequest"))
                    $root.protocol.ClientPeerChangeTransactionAmountRequest.encode(message.clientPeerChangeTransactionAmountRequest, writer.uint32(/* id 61, wireType 2 =*/490).fork()).ldelim();
                if (message.appVersionRequest != null && Object.hasOwnProperty.call(message, "appVersionRequest"))
                    $root.protocol.AppVersionRequest.encode(message.appVersionRequest, writer.uint32(/* id 62, wireType 2 =*/498).fork()).ldelim();
                if (message.hasChangesDashboardRequest != null && Object.hasOwnProperty.call(message, "hasChangesDashboardRequest"))
                    $root.protocol.HasChangesDashboardRequest.encode(message.hasChangesDashboardRequest, writer.uint32(/* id 63, wireType 2 =*/506).fork()).ldelim();
                if (message.latestChangesDashboardRequest != null && Object.hasOwnProperty.call(message, "latestChangesDashboardRequest"))
                    $root.protocol.LatestChangesDashboardRequest.encode(message.latestChangesDashboardRequest, writer.uint32(/* id 64, wireType 2 =*/514).fork()).ldelim();
                if (message.checkTokenPayoutSystemBalanceRequest != null && Object.hasOwnProperty.call(message, "checkTokenPayoutSystemBalanceRequest"))
                    $root.protocol.CheckTokenPayoutSystemBalanceRequest.encode(message.checkTokenPayoutSystemBalanceRequest, writer.uint32(/* id 65, wireType 2 =*/522).fork()).ldelim();
                if (message.cancelPendingPayoutRequestRequest != null && Object.hasOwnProperty.call(message, "cancelPendingPayoutRequestRequest"))
                    $root.protocol.CancelPendingPayoutRequestRequest.encode(message.cancelPendingPayoutRequestRequest, writer.uint32(/* id 66, wireType 2 =*/530).fork()).ldelim();
                if (message.amlClientUnboundChecksListRequest != null && Object.hasOwnProperty.call(message, "amlClientUnboundChecksListRequest"))
                    $root.protocol.AMLClientUnboundChecksListRequest.encode(message.amlClientUnboundChecksListRequest, writer.uint32(/* id 67, wireType 2 =*/538).fork()).ldelim();
                if (message.clientAddressesListRequest != null && Object.hasOwnProperty.call(message, "clientAddressesListRequest"))
                    $root.protocol.ClientAddressesListRequest.encode(message.clientAddressesListRequest, writer.uint32(/* id 68, wireType 2 =*/546).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ClientRequest message, length delimited. Does not implicitly {@link protocol.ClientRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientRequest
             * @static
             * @param {protocol.IClientRequest} message ClientRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientRequest} ClientRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.trx = reader.string();
                        break;
                    case 2:
                        message.clientId = reader.string();
                        break;
                    case 3:
                        message.authToken = reader.string();
                        break;
                    case 10:
                        message.createNewAddressRequest = $root.protocol.CreateNewAddressRequest.decode(reader, reader.uint32());
                        break;
                    case 11:
                        message.balancesRequest = $root.protocol.BalancesRequest.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.transactionsRequest = $root.protocol.TransactionsRequest.decode(reader, reader.uint32());
                        break;
                    case 13:
                        message.enqueuePayoutRequest = $root.protocol.EnqueuePayoutRequest.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.payoutRequestDetailsRequest = $root.protocol.PayoutRequestDetailsRequest.decode(reader, reader.uint32());
                        break;
                    case 15:
                        message.mainAccountsRequest = $root.protocol.MainAccountsRequest.decode(reader, reader.uint32());
                        break;
                    case 16:
                        message.modifyTagsRequest = $root.protocol.ModifyTagsRequest.decode(reader, reader.uint32());
                        break;
                    case 17:
                        message.listTagsRequest = $root.protocol.ListTagsRequest.decode(reader, reader.uint32());
                        break;
                    case 18:
                        message.confirmedClientTransactionsRequest = $root.protocol.ConfirmedClientTransactionsRequest.decode(reader, reader.uint32());
                        break;
                    case 19:
                        message.payoutDetailsRequest = $root.protocol.PayoutDetailsRequest.decode(reader, reader.uint32());
                        break;
                    case 20:
                        message.convertCryptoToFiatRequest = $root.protocol.ConvertCryptoToFiatRequest.decode(reader, reader.uint32());
                        break;
                    case 21:
                        message.transactionFeeRequest = $root.protocol.TransactionFeeRequest.decode(reader, reader.uint32());
                        break;
                    case 22:
                        message.clientRequestLogsRequest = $root.protocol.ClientRequestLogsRequest.decode(reader, reader.uint32());
                        break;
                    case 23:
                        message.listPayoutRequestsRequest = $root.protocol.ListPayoutRequestsRequest.decode(reader, reader.uint32());
                        break;
                    case 24:
                        message.amlSingleCheckRequest = $root.protocol.AMLSingleCheckRequest.decode(reader, reader.uint32());
                        break;
                    case 25:
                        message.amlCheckResultRequest = $root.protocol.AMLCheckResultRequest.decode(reader, reader.uint32());
                        break;
                    case 26:
                        message.amlClientBatchCheckStartRequest = $root.protocol.AMLClientBatchCheckStartRequest.decode(reader, reader.uint32());
                        break;
                    case 27:
                        message.amlClientBatchCheckStopRequest = $root.protocol.AMLClientBatchCheckStopRequest.decode(reader, reader.uint32());
                        break;
                    case 28:
                        message.amlBatchCheckResultsRequest = $root.protocol.AMLBatchCheckResultsRequest.decode(reader, reader.uint32());
                        break;
                    case 29:
                        message.amlServiceStatusRequest = $root.protocol.AMLServiceStatusRequest.decode(reader, reader.uint32());
                        break;
                    case 30:
                        message.transactionsPagedRequest = $root.protocol.TransactionsPagedRequest.decode(reader, reader.uint32());
                        break;
                    case 31:
                        message.transactionsStatsRequest = $root.protocol.TransactionsStatsRequest.decode(reader, reader.uint32());
                        break;
                    case 32:
                        message.amlClientOverallStatsRequest = $root.protocol.AMLClientOverallStatsRequest.decode(reader, reader.uint32());
                        break;
                    case 33:
                        message.amlClientAddressesListRequest = $root.protocol.AMLClientAddressesListRequest.decode(reader, reader.uint32());
                        break;
                    case 34:
                        message.amlClientBatchStatsRequest = $root.protocol.AMLClientBatchStatsRequest.decode(reader, reader.uint32());
                        break;
                    case 35:
                        message.amlClientBatchAddressesListRequest = $root.protocol.AMLClientBatchAddressesListRequest.decode(reader, reader.uint32());
                        break;
                    case 36:
                        message.amlClientBatchChecksListRequest = $root.protocol.AMLClientBatchChecksListRequest.decode(reader, reader.uint32());
                        break;
                    case 37:
                        message.amlClientBatchAddressRequest = $root.protocol.AMLClientBatchAddressRequest.decode(reader, reader.uint32());
                        break;
                    case 38:
                        message.feeRatesRequest = $root.protocol.FeeRatesRequest.decode(reader, reader.uint32());
                        break;
                    case 39:
                        message.createClientPeerRequest = $root.protocol.CreateClientPeerRequest.decode(reader, reader.uint32());
                        break;
                    case 41:
                        message.generateNewClientPeerAddressRequest = $root.protocol.GenerateNewClientPeerAddressRequest.decode(reader, reader.uint32());
                        break;
                    case 42:
                        message.regenerateClientPeerAddressRequest = $root.protocol.RegenerateClientPeerAddressRequest.decode(reader, reader.uint32());
                        break;
                    case 43:
                        message.clientPeersShortListRequest = $root.protocol.ClientPeersShortListRequest.decode(reader, reader.uint32());
                        break;
                    case 44:
                        message.clientPeersListRequest = $root.protocol.ClientPeersListRequest.decode(reader, reader.uint32());
                        break;
                    case 45:
                        message.clientPeerDetailsRequest = $root.protocol.ClientPeerDetailsRequest.decode(reader, reader.uint32());
                        break;
                    case 46:
                        message.clientPeerAddressesListRequest = $root.protocol.ClientPeerAddressesListRequest.decode(reader, reader.uint32());
                        break;
                    case 47:
                        message.clientPeerTransactionsRequest = $root.protocol.ClientPeerTransactionsRequest.decode(reader, reader.uint32());
                        break;
                    case 48:
                        message.deleteClientPeerAddressRequest = $root.protocol.DeleteClientPeerAddressRequest.decode(reader, reader.uint32());
                        break;
                    case 49:
                        message.authUserRequest = $root.protocol.AuthUserRequest.decode(reader, reader.uint32());
                        break;
                    case 50:
                        message.checkSessionRequest = $root.protocol.CheckSessionRequest.decode(reader, reader.uint32());
                        break;
                    case 51:
                        message.adminCreateClientUserRequest = $root.protocol.AdminCreateClientUserRequest.decode(reader, reader.uint32());
                        break;
                    case 52:
                        message.adminClientUsersListRequest = $root.protocol.AdminClientUsersListRequest.decode(reader, reader.uint32());
                        break;
                    case 53:
                        message.adminUpdateClientUserAccessGroupsRequest = $root.protocol.AdminUpdateClientUserAccessGroupsRequest.decode(reader, reader.uint32());
                        break;
                    case 54:
                        message.adminUpdateClientUserPasswordRequest = $root.protocol.AdminUpdateClientUserPasswordRequest.decode(reader, reader.uint32());
                        break;
                    case 55:
                        message.clientPeersStatsRequest = $root.protocol.ClientPeersStatsRequest.decode(reader, reader.uint32());
                        break;
                    case 56:
                        message.clientPeerTransactionsStatsRequest = $root.protocol.ClientPeerTransactionsStatsRequest.decode(reader, reader.uint32());
                        break;
                    case 57:
                        message.clientAddressesTagsListRequest = $root.protocol.ClientAddressesTagsListRequest.decode(reader, reader.uint32());
                        break;
                    case 58:
                        message.clientSystemAddressesTagGroupsListRequest = $root.protocol.ClientSystemAddressesTagGroupsListRequest.decode(reader, reader.uint32());
                        break;
                    case 59:
                        message.createClientSystemAddressRequest = $root.protocol.CreateClientSystemAddressRequest.decode(reader, reader.uint32());
                        break;
                    case 60:
                        message.clientTotalBalancesRequest = $root.protocol.ClientTotalBalancesRequest.decode(reader, reader.uint32());
                        break;
                    case 61:
                        message.clientPeerChangeTransactionAmountRequest = $root.protocol.ClientPeerChangeTransactionAmountRequest.decode(reader, reader.uint32());
                        break;
                    case 62:
                        message.appVersionRequest = $root.protocol.AppVersionRequest.decode(reader, reader.uint32());
                        break;
                    case 63:
                        message.hasChangesDashboardRequest = $root.protocol.HasChangesDashboardRequest.decode(reader, reader.uint32());
                        break;
                    case 64:
                        message.latestChangesDashboardRequest = $root.protocol.LatestChangesDashboardRequest.decode(reader, reader.uint32());
                        break;
                    case 65:
                        message.checkTokenPayoutSystemBalanceRequest = $root.protocol.CheckTokenPayoutSystemBalanceRequest.decode(reader, reader.uint32());
                        break;
                    case 66:
                        message.cancelPendingPayoutRequestRequest = $root.protocol.CancelPendingPayoutRequestRequest.decode(reader, reader.uint32());
                        break;
                    case 67:
                        message.amlClientUnboundChecksListRequest = $root.protocol.AMLClientUnboundChecksListRequest.decode(reader, reader.uint32());
                        break;
                    case 68:
                        message.clientAddressesListRequest = $root.protocol.ClientAddressesListRequest.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientRequest} ClientRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientRequest message.
             * @function verify
             * @memberof protocol.ClientRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.trx != null && message.hasOwnProperty("trx"))
                    if (!$util.isString(message.trx))
                        return "trx: string expected";
                if (message.clientId != null && message.hasOwnProperty("clientId"))
                    if (!$util.isString(message.clientId))
                        return "clientId: string expected";
                if (message.authToken != null && message.hasOwnProperty("authToken"))
                    if (!$util.isString(message.authToken))
                        return "authToken: string expected";
                if (message.createNewAddressRequest != null && message.hasOwnProperty("createNewAddressRequest")) {
                    var error = $root.protocol.CreateNewAddressRequest.verify(message.createNewAddressRequest);
                    if (error)
                        return "createNewAddressRequest." + error;
                }
                if (message.balancesRequest != null && message.hasOwnProperty("balancesRequest")) {
                    var error = $root.protocol.BalancesRequest.verify(message.balancesRequest);
                    if (error)
                        return "balancesRequest." + error;
                }
                if (message.transactionsRequest != null && message.hasOwnProperty("transactionsRequest")) {
                    var error = $root.protocol.TransactionsRequest.verify(message.transactionsRequest);
                    if (error)
                        return "transactionsRequest." + error;
                }
                if (message.enqueuePayoutRequest != null && message.hasOwnProperty("enqueuePayoutRequest")) {
                    var error = $root.protocol.EnqueuePayoutRequest.verify(message.enqueuePayoutRequest);
                    if (error)
                        return "enqueuePayoutRequest." + error;
                }
                if (message.payoutRequestDetailsRequest != null && message.hasOwnProperty("payoutRequestDetailsRequest")) {
                    var error = $root.protocol.PayoutRequestDetailsRequest.verify(message.payoutRequestDetailsRequest);
                    if (error)
                        return "payoutRequestDetailsRequest." + error;
                }
                if (message.mainAccountsRequest != null && message.hasOwnProperty("mainAccountsRequest")) {
                    var error = $root.protocol.MainAccountsRequest.verify(message.mainAccountsRequest);
                    if (error)
                        return "mainAccountsRequest." + error;
                }
                if (message.modifyTagsRequest != null && message.hasOwnProperty("modifyTagsRequest")) {
                    var error = $root.protocol.ModifyTagsRequest.verify(message.modifyTagsRequest);
                    if (error)
                        return "modifyTagsRequest." + error;
                }
                if (message.listTagsRequest != null && message.hasOwnProperty("listTagsRequest")) {
                    var error = $root.protocol.ListTagsRequest.verify(message.listTagsRequest);
                    if (error)
                        return "listTagsRequest." + error;
                }
                if (message.confirmedClientTransactionsRequest != null && message.hasOwnProperty("confirmedClientTransactionsRequest")) {
                    var error = $root.protocol.ConfirmedClientTransactionsRequest.verify(message.confirmedClientTransactionsRequest);
                    if (error)
                        return "confirmedClientTransactionsRequest." + error;
                }
                if (message.payoutDetailsRequest != null && message.hasOwnProperty("payoutDetailsRequest")) {
                    var error = $root.protocol.PayoutDetailsRequest.verify(message.payoutDetailsRequest);
                    if (error)
                        return "payoutDetailsRequest." + error;
                }
                if (message.convertCryptoToFiatRequest != null && message.hasOwnProperty("convertCryptoToFiatRequest")) {
                    var error = $root.protocol.ConvertCryptoToFiatRequest.verify(message.convertCryptoToFiatRequest);
                    if (error)
                        return "convertCryptoToFiatRequest." + error;
                }
                if (message.transactionFeeRequest != null && message.hasOwnProperty("transactionFeeRequest")) {
                    var error = $root.protocol.TransactionFeeRequest.verify(message.transactionFeeRequest);
                    if (error)
                        return "transactionFeeRequest." + error;
                }
                if (message.clientRequestLogsRequest != null && message.hasOwnProperty("clientRequestLogsRequest")) {
                    var error = $root.protocol.ClientRequestLogsRequest.verify(message.clientRequestLogsRequest);
                    if (error)
                        return "clientRequestLogsRequest." + error;
                }
                if (message.listPayoutRequestsRequest != null && message.hasOwnProperty("listPayoutRequestsRequest")) {
                    var error = $root.protocol.ListPayoutRequestsRequest.verify(message.listPayoutRequestsRequest);
                    if (error)
                        return "listPayoutRequestsRequest." + error;
                }
                if (message.amlSingleCheckRequest != null && message.hasOwnProperty("amlSingleCheckRequest")) {
                    var error = $root.protocol.AMLSingleCheckRequest.verify(message.amlSingleCheckRequest);
                    if (error)
                        return "amlSingleCheckRequest." + error;
                }
                if (message.amlCheckResultRequest != null && message.hasOwnProperty("amlCheckResultRequest")) {
                    var error = $root.protocol.AMLCheckResultRequest.verify(message.amlCheckResultRequest);
                    if (error)
                        return "amlCheckResultRequest." + error;
                }
                if (message.amlClientBatchCheckStartRequest != null && message.hasOwnProperty("amlClientBatchCheckStartRequest")) {
                    var error = $root.protocol.AMLClientBatchCheckStartRequest.verify(message.amlClientBatchCheckStartRequest);
                    if (error)
                        return "amlClientBatchCheckStartRequest." + error;
                }
                if (message.amlClientBatchCheckStopRequest != null && message.hasOwnProperty("amlClientBatchCheckStopRequest")) {
                    var error = $root.protocol.AMLClientBatchCheckStopRequest.verify(message.amlClientBatchCheckStopRequest);
                    if (error)
                        return "amlClientBatchCheckStopRequest." + error;
                }
                if (message.amlBatchCheckResultsRequest != null && message.hasOwnProperty("amlBatchCheckResultsRequest")) {
                    var error = $root.protocol.AMLBatchCheckResultsRequest.verify(message.amlBatchCheckResultsRequest);
                    if (error)
                        return "amlBatchCheckResultsRequest." + error;
                }
                if (message.amlServiceStatusRequest != null && message.hasOwnProperty("amlServiceStatusRequest")) {
                    var error = $root.protocol.AMLServiceStatusRequest.verify(message.amlServiceStatusRequest);
                    if (error)
                        return "amlServiceStatusRequest." + error;
                }
                if (message.transactionsPagedRequest != null && message.hasOwnProperty("transactionsPagedRequest")) {
                    var error = $root.protocol.TransactionsPagedRequest.verify(message.transactionsPagedRequest);
                    if (error)
                        return "transactionsPagedRequest." + error;
                }
                if (message.transactionsStatsRequest != null && message.hasOwnProperty("transactionsStatsRequest")) {
                    var error = $root.protocol.TransactionsStatsRequest.verify(message.transactionsStatsRequest);
                    if (error)
                        return "transactionsStatsRequest." + error;
                }
                if (message.amlClientOverallStatsRequest != null && message.hasOwnProperty("amlClientOverallStatsRequest")) {
                    var error = $root.protocol.AMLClientOverallStatsRequest.verify(message.amlClientOverallStatsRequest);
                    if (error)
                        return "amlClientOverallStatsRequest." + error;
                }
                if (message.amlClientAddressesListRequest != null && message.hasOwnProperty("amlClientAddressesListRequest")) {
                    var error = $root.protocol.AMLClientAddressesListRequest.verify(message.amlClientAddressesListRequest);
                    if (error)
                        return "amlClientAddressesListRequest." + error;
                }
                if (message.amlClientBatchStatsRequest != null && message.hasOwnProperty("amlClientBatchStatsRequest")) {
                    var error = $root.protocol.AMLClientBatchStatsRequest.verify(message.amlClientBatchStatsRequest);
                    if (error)
                        return "amlClientBatchStatsRequest." + error;
                }
                if (message.amlClientBatchAddressesListRequest != null && message.hasOwnProperty("amlClientBatchAddressesListRequest")) {
                    var error = $root.protocol.AMLClientBatchAddressesListRequest.verify(message.amlClientBatchAddressesListRequest);
                    if (error)
                        return "amlClientBatchAddressesListRequest." + error;
                }
                if (message.amlClientBatchChecksListRequest != null && message.hasOwnProperty("amlClientBatchChecksListRequest")) {
                    var error = $root.protocol.AMLClientBatchChecksListRequest.verify(message.amlClientBatchChecksListRequest);
                    if (error)
                        return "amlClientBatchChecksListRequest." + error;
                }
                if (message.amlClientBatchAddressRequest != null && message.hasOwnProperty("amlClientBatchAddressRequest")) {
                    var error = $root.protocol.AMLClientBatchAddressRequest.verify(message.amlClientBatchAddressRequest);
                    if (error)
                        return "amlClientBatchAddressRequest." + error;
                }
                if (message.feeRatesRequest != null && message.hasOwnProperty("feeRatesRequest")) {
                    var error = $root.protocol.FeeRatesRequest.verify(message.feeRatesRequest);
                    if (error)
                        return "feeRatesRequest." + error;
                }
                if (message.createClientPeerRequest != null && message.hasOwnProperty("createClientPeerRequest")) {
                    var error = $root.protocol.CreateClientPeerRequest.verify(message.createClientPeerRequest);
                    if (error)
                        return "createClientPeerRequest." + error;
                }
                if (message.generateNewClientPeerAddressRequest != null && message.hasOwnProperty("generateNewClientPeerAddressRequest")) {
                    var error = $root.protocol.GenerateNewClientPeerAddressRequest.verify(message.generateNewClientPeerAddressRequest);
                    if (error)
                        return "generateNewClientPeerAddressRequest." + error;
                }
                if (message.regenerateClientPeerAddressRequest != null && message.hasOwnProperty("regenerateClientPeerAddressRequest")) {
                    var error = $root.protocol.RegenerateClientPeerAddressRequest.verify(message.regenerateClientPeerAddressRequest);
                    if (error)
                        return "regenerateClientPeerAddressRequest." + error;
                }
                if (message.clientPeersShortListRequest != null && message.hasOwnProperty("clientPeersShortListRequest")) {
                    var error = $root.protocol.ClientPeersShortListRequest.verify(message.clientPeersShortListRequest);
                    if (error)
                        return "clientPeersShortListRequest." + error;
                }
                if (message.clientPeersListRequest != null && message.hasOwnProperty("clientPeersListRequest")) {
                    var error = $root.protocol.ClientPeersListRequest.verify(message.clientPeersListRequest);
                    if (error)
                        return "clientPeersListRequest." + error;
                }
                if (message.clientPeerDetailsRequest != null && message.hasOwnProperty("clientPeerDetailsRequest")) {
                    var error = $root.protocol.ClientPeerDetailsRequest.verify(message.clientPeerDetailsRequest);
                    if (error)
                        return "clientPeerDetailsRequest." + error;
                }
                if (message.clientPeerAddressesListRequest != null && message.hasOwnProperty("clientPeerAddressesListRequest")) {
                    var error = $root.protocol.ClientPeerAddressesListRequest.verify(message.clientPeerAddressesListRequest);
                    if (error)
                        return "clientPeerAddressesListRequest." + error;
                }
                if (message.clientPeerTransactionsRequest != null && message.hasOwnProperty("clientPeerTransactionsRequest")) {
                    var error = $root.protocol.ClientPeerTransactionsRequest.verify(message.clientPeerTransactionsRequest);
                    if (error)
                        return "clientPeerTransactionsRequest." + error;
                }
                if (message.deleteClientPeerAddressRequest != null && message.hasOwnProperty("deleteClientPeerAddressRequest")) {
                    var error = $root.protocol.DeleteClientPeerAddressRequest.verify(message.deleteClientPeerAddressRequest);
                    if (error)
                        return "deleteClientPeerAddressRequest." + error;
                }
                if (message.authUserRequest != null && message.hasOwnProperty("authUserRequest")) {
                    var error = $root.protocol.AuthUserRequest.verify(message.authUserRequest);
                    if (error)
                        return "authUserRequest." + error;
                }
                if (message.checkSessionRequest != null && message.hasOwnProperty("checkSessionRequest")) {
                    var error = $root.protocol.CheckSessionRequest.verify(message.checkSessionRequest);
                    if (error)
                        return "checkSessionRequest." + error;
                }
                if (message.adminCreateClientUserRequest != null && message.hasOwnProperty("adminCreateClientUserRequest")) {
                    var error = $root.protocol.AdminCreateClientUserRequest.verify(message.adminCreateClientUserRequest);
                    if (error)
                        return "adminCreateClientUserRequest." + error;
                }
                if (message.adminClientUsersListRequest != null && message.hasOwnProperty("adminClientUsersListRequest")) {
                    var error = $root.protocol.AdminClientUsersListRequest.verify(message.adminClientUsersListRequest);
                    if (error)
                        return "adminClientUsersListRequest." + error;
                }
                if (message.adminUpdateClientUserAccessGroupsRequest != null && message.hasOwnProperty("adminUpdateClientUserAccessGroupsRequest")) {
                    var error = $root.protocol.AdminUpdateClientUserAccessGroupsRequest.verify(message.adminUpdateClientUserAccessGroupsRequest);
                    if (error)
                        return "adminUpdateClientUserAccessGroupsRequest." + error;
                }
                if (message.adminUpdateClientUserPasswordRequest != null && message.hasOwnProperty("adminUpdateClientUserPasswordRequest")) {
                    var error = $root.protocol.AdminUpdateClientUserPasswordRequest.verify(message.adminUpdateClientUserPasswordRequest);
                    if (error)
                        return "adminUpdateClientUserPasswordRequest." + error;
                }
                if (message.clientPeersStatsRequest != null && message.hasOwnProperty("clientPeersStatsRequest")) {
                    var error = $root.protocol.ClientPeersStatsRequest.verify(message.clientPeersStatsRequest);
                    if (error)
                        return "clientPeersStatsRequest." + error;
                }
                if (message.clientPeerTransactionsStatsRequest != null && message.hasOwnProperty("clientPeerTransactionsStatsRequest")) {
                    var error = $root.protocol.ClientPeerTransactionsStatsRequest.verify(message.clientPeerTransactionsStatsRequest);
                    if (error)
                        return "clientPeerTransactionsStatsRequest." + error;
                }
                if (message.clientAddressesTagsListRequest != null && message.hasOwnProperty("clientAddressesTagsListRequest")) {
                    var error = $root.protocol.ClientAddressesTagsListRequest.verify(message.clientAddressesTagsListRequest);
                    if (error)
                        return "clientAddressesTagsListRequest." + error;
                }
                if (message.clientSystemAddressesTagGroupsListRequest != null && message.hasOwnProperty("clientSystemAddressesTagGroupsListRequest")) {
                    var error = $root.protocol.ClientSystemAddressesTagGroupsListRequest.verify(message.clientSystemAddressesTagGroupsListRequest);
                    if (error)
                        return "clientSystemAddressesTagGroupsListRequest." + error;
                }
                if (message.createClientSystemAddressRequest != null && message.hasOwnProperty("createClientSystemAddressRequest")) {
                    var error = $root.protocol.CreateClientSystemAddressRequest.verify(message.createClientSystemAddressRequest);
                    if (error)
                        return "createClientSystemAddressRequest." + error;
                }
                if (message.clientTotalBalancesRequest != null && message.hasOwnProperty("clientTotalBalancesRequest")) {
                    var error = $root.protocol.ClientTotalBalancesRequest.verify(message.clientTotalBalancesRequest);
                    if (error)
                        return "clientTotalBalancesRequest." + error;
                }
                if (message.clientPeerChangeTransactionAmountRequest != null && message.hasOwnProperty("clientPeerChangeTransactionAmountRequest")) {
                    var error = $root.protocol.ClientPeerChangeTransactionAmountRequest.verify(message.clientPeerChangeTransactionAmountRequest);
                    if (error)
                        return "clientPeerChangeTransactionAmountRequest." + error;
                }
                if (message.appVersionRequest != null && message.hasOwnProperty("appVersionRequest")) {
                    var error = $root.protocol.AppVersionRequest.verify(message.appVersionRequest);
                    if (error)
                        return "appVersionRequest." + error;
                }
                if (message.hasChangesDashboardRequest != null && message.hasOwnProperty("hasChangesDashboardRequest")) {
                    var error = $root.protocol.HasChangesDashboardRequest.verify(message.hasChangesDashboardRequest);
                    if (error)
                        return "hasChangesDashboardRequest." + error;
                }
                if (message.latestChangesDashboardRequest != null && message.hasOwnProperty("latestChangesDashboardRequest")) {
                    var error = $root.protocol.LatestChangesDashboardRequest.verify(message.latestChangesDashboardRequest);
                    if (error)
                        return "latestChangesDashboardRequest." + error;
                }
                if (message.checkTokenPayoutSystemBalanceRequest != null && message.hasOwnProperty("checkTokenPayoutSystemBalanceRequest")) {
                    var error = $root.protocol.CheckTokenPayoutSystemBalanceRequest.verify(message.checkTokenPayoutSystemBalanceRequest);
                    if (error)
                        return "checkTokenPayoutSystemBalanceRequest." + error;
                }
                if (message.cancelPendingPayoutRequestRequest != null && message.hasOwnProperty("cancelPendingPayoutRequestRequest")) {
                    var error = $root.protocol.CancelPendingPayoutRequestRequest.verify(message.cancelPendingPayoutRequestRequest);
                    if (error)
                        return "cancelPendingPayoutRequestRequest." + error;
                }
                if (message.amlClientUnboundChecksListRequest != null && message.hasOwnProperty("amlClientUnboundChecksListRequest")) {
                    var error = $root.protocol.AMLClientUnboundChecksListRequest.verify(message.amlClientUnboundChecksListRequest);
                    if (error)
                        return "amlClientUnboundChecksListRequest." + error;
                }
                if (message.clientAddressesListRequest != null && message.hasOwnProperty("clientAddressesListRequest")) {
                    var error = $root.protocol.ClientAddressesListRequest.verify(message.clientAddressesListRequest);
                    if (error)
                        return "clientAddressesListRequest." + error;
                }
                return null;
            };
    
            /**
             * Creates a ClientRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientRequest} ClientRequest
             */
            ClientRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientRequest)
                    return object;
                var message = new $root.protocol.ClientRequest();
                if (object.trx != null)
                    message.trx = String(object.trx);
                if (object.clientId != null)
                    message.clientId = String(object.clientId);
                if (object.authToken != null)
                    message.authToken = String(object.authToken);
                if (object.createNewAddressRequest != null) {
                    if (typeof object.createNewAddressRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.createNewAddressRequest: object expected");
                    message.createNewAddressRequest = $root.protocol.CreateNewAddressRequest.fromObject(object.createNewAddressRequest);
                }
                if (object.balancesRequest != null) {
                    if (typeof object.balancesRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.balancesRequest: object expected");
                    message.balancesRequest = $root.protocol.BalancesRequest.fromObject(object.balancesRequest);
                }
                if (object.transactionsRequest != null) {
                    if (typeof object.transactionsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.transactionsRequest: object expected");
                    message.transactionsRequest = $root.protocol.TransactionsRequest.fromObject(object.transactionsRequest);
                }
                if (object.enqueuePayoutRequest != null) {
                    if (typeof object.enqueuePayoutRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.enqueuePayoutRequest: object expected");
                    message.enqueuePayoutRequest = $root.protocol.EnqueuePayoutRequest.fromObject(object.enqueuePayoutRequest);
                }
                if (object.payoutRequestDetailsRequest != null) {
                    if (typeof object.payoutRequestDetailsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.payoutRequestDetailsRequest: object expected");
                    message.payoutRequestDetailsRequest = $root.protocol.PayoutRequestDetailsRequest.fromObject(object.payoutRequestDetailsRequest);
                }
                if (object.mainAccountsRequest != null) {
                    if (typeof object.mainAccountsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.mainAccountsRequest: object expected");
                    message.mainAccountsRequest = $root.protocol.MainAccountsRequest.fromObject(object.mainAccountsRequest);
                }
                if (object.modifyTagsRequest != null) {
                    if (typeof object.modifyTagsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.modifyTagsRequest: object expected");
                    message.modifyTagsRequest = $root.protocol.ModifyTagsRequest.fromObject(object.modifyTagsRequest);
                }
                if (object.listTagsRequest != null) {
                    if (typeof object.listTagsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.listTagsRequest: object expected");
                    message.listTagsRequest = $root.protocol.ListTagsRequest.fromObject(object.listTagsRequest);
                }
                if (object.confirmedClientTransactionsRequest != null) {
                    if (typeof object.confirmedClientTransactionsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.confirmedClientTransactionsRequest: object expected");
                    message.confirmedClientTransactionsRequest = $root.protocol.ConfirmedClientTransactionsRequest.fromObject(object.confirmedClientTransactionsRequest);
                }
                if (object.payoutDetailsRequest != null) {
                    if (typeof object.payoutDetailsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.payoutDetailsRequest: object expected");
                    message.payoutDetailsRequest = $root.protocol.PayoutDetailsRequest.fromObject(object.payoutDetailsRequest);
                }
                if (object.convertCryptoToFiatRequest != null) {
                    if (typeof object.convertCryptoToFiatRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.convertCryptoToFiatRequest: object expected");
                    message.convertCryptoToFiatRequest = $root.protocol.ConvertCryptoToFiatRequest.fromObject(object.convertCryptoToFiatRequest);
                }
                if (object.transactionFeeRequest != null) {
                    if (typeof object.transactionFeeRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.transactionFeeRequest: object expected");
                    message.transactionFeeRequest = $root.protocol.TransactionFeeRequest.fromObject(object.transactionFeeRequest);
                }
                if (object.clientRequestLogsRequest != null) {
                    if (typeof object.clientRequestLogsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.clientRequestLogsRequest: object expected");
                    message.clientRequestLogsRequest = $root.protocol.ClientRequestLogsRequest.fromObject(object.clientRequestLogsRequest);
                }
                if (object.listPayoutRequestsRequest != null) {
                    if (typeof object.listPayoutRequestsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.listPayoutRequestsRequest: object expected");
                    message.listPayoutRequestsRequest = $root.protocol.ListPayoutRequestsRequest.fromObject(object.listPayoutRequestsRequest);
                }
                if (object.amlSingleCheckRequest != null) {
                    if (typeof object.amlSingleCheckRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.amlSingleCheckRequest: object expected");
                    message.amlSingleCheckRequest = $root.protocol.AMLSingleCheckRequest.fromObject(object.amlSingleCheckRequest);
                }
                if (object.amlCheckResultRequest != null) {
                    if (typeof object.amlCheckResultRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.amlCheckResultRequest: object expected");
                    message.amlCheckResultRequest = $root.protocol.AMLCheckResultRequest.fromObject(object.amlCheckResultRequest);
                }
                if (object.amlClientBatchCheckStartRequest != null) {
                    if (typeof object.amlClientBatchCheckStartRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.amlClientBatchCheckStartRequest: object expected");
                    message.amlClientBatchCheckStartRequest = $root.protocol.AMLClientBatchCheckStartRequest.fromObject(object.amlClientBatchCheckStartRequest);
                }
                if (object.amlClientBatchCheckStopRequest != null) {
                    if (typeof object.amlClientBatchCheckStopRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.amlClientBatchCheckStopRequest: object expected");
                    message.amlClientBatchCheckStopRequest = $root.protocol.AMLClientBatchCheckStopRequest.fromObject(object.amlClientBatchCheckStopRequest);
                }
                if (object.amlBatchCheckResultsRequest != null) {
                    if (typeof object.amlBatchCheckResultsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.amlBatchCheckResultsRequest: object expected");
                    message.amlBatchCheckResultsRequest = $root.protocol.AMLBatchCheckResultsRequest.fromObject(object.amlBatchCheckResultsRequest);
                }
                if (object.amlServiceStatusRequest != null) {
                    if (typeof object.amlServiceStatusRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.amlServiceStatusRequest: object expected");
                    message.amlServiceStatusRequest = $root.protocol.AMLServiceStatusRequest.fromObject(object.amlServiceStatusRequest);
                }
                if (object.transactionsPagedRequest != null) {
                    if (typeof object.transactionsPagedRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.transactionsPagedRequest: object expected");
                    message.transactionsPagedRequest = $root.protocol.TransactionsPagedRequest.fromObject(object.transactionsPagedRequest);
                }
                if (object.transactionsStatsRequest != null) {
                    if (typeof object.transactionsStatsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.transactionsStatsRequest: object expected");
                    message.transactionsStatsRequest = $root.protocol.TransactionsStatsRequest.fromObject(object.transactionsStatsRequest);
                }
                if (object.amlClientOverallStatsRequest != null) {
                    if (typeof object.amlClientOverallStatsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.amlClientOverallStatsRequest: object expected");
                    message.amlClientOverallStatsRequest = $root.protocol.AMLClientOverallStatsRequest.fromObject(object.amlClientOverallStatsRequest);
                }
                if (object.amlClientAddressesListRequest != null) {
                    if (typeof object.amlClientAddressesListRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.amlClientAddressesListRequest: object expected");
                    message.amlClientAddressesListRequest = $root.protocol.AMLClientAddressesListRequest.fromObject(object.amlClientAddressesListRequest);
                }
                if (object.amlClientBatchStatsRequest != null) {
                    if (typeof object.amlClientBatchStatsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.amlClientBatchStatsRequest: object expected");
                    message.amlClientBatchStatsRequest = $root.protocol.AMLClientBatchStatsRequest.fromObject(object.amlClientBatchStatsRequest);
                }
                if (object.amlClientBatchAddressesListRequest != null) {
                    if (typeof object.amlClientBatchAddressesListRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.amlClientBatchAddressesListRequest: object expected");
                    message.amlClientBatchAddressesListRequest = $root.protocol.AMLClientBatchAddressesListRequest.fromObject(object.amlClientBatchAddressesListRequest);
                }
                if (object.amlClientBatchChecksListRequest != null) {
                    if (typeof object.amlClientBatchChecksListRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.amlClientBatchChecksListRequest: object expected");
                    message.amlClientBatchChecksListRequest = $root.protocol.AMLClientBatchChecksListRequest.fromObject(object.amlClientBatchChecksListRequest);
                }
                if (object.amlClientBatchAddressRequest != null) {
                    if (typeof object.amlClientBatchAddressRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.amlClientBatchAddressRequest: object expected");
                    message.amlClientBatchAddressRequest = $root.protocol.AMLClientBatchAddressRequest.fromObject(object.amlClientBatchAddressRequest);
                }
                if (object.feeRatesRequest != null) {
                    if (typeof object.feeRatesRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.feeRatesRequest: object expected");
                    message.feeRatesRequest = $root.protocol.FeeRatesRequest.fromObject(object.feeRatesRequest);
                }
                if (object.createClientPeerRequest != null) {
                    if (typeof object.createClientPeerRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.createClientPeerRequest: object expected");
                    message.createClientPeerRequest = $root.protocol.CreateClientPeerRequest.fromObject(object.createClientPeerRequest);
                }
                if (object.generateNewClientPeerAddressRequest != null) {
                    if (typeof object.generateNewClientPeerAddressRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.generateNewClientPeerAddressRequest: object expected");
                    message.generateNewClientPeerAddressRequest = $root.protocol.GenerateNewClientPeerAddressRequest.fromObject(object.generateNewClientPeerAddressRequest);
                }
                if (object.regenerateClientPeerAddressRequest != null) {
                    if (typeof object.regenerateClientPeerAddressRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.regenerateClientPeerAddressRequest: object expected");
                    message.regenerateClientPeerAddressRequest = $root.protocol.RegenerateClientPeerAddressRequest.fromObject(object.regenerateClientPeerAddressRequest);
                }
                if (object.clientPeersShortListRequest != null) {
                    if (typeof object.clientPeersShortListRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.clientPeersShortListRequest: object expected");
                    message.clientPeersShortListRequest = $root.protocol.ClientPeersShortListRequest.fromObject(object.clientPeersShortListRequest);
                }
                if (object.clientPeersListRequest != null) {
                    if (typeof object.clientPeersListRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.clientPeersListRequest: object expected");
                    message.clientPeersListRequest = $root.protocol.ClientPeersListRequest.fromObject(object.clientPeersListRequest);
                }
                if (object.clientPeerDetailsRequest != null) {
                    if (typeof object.clientPeerDetailsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.clientPeerDetailsRequest: object expected");
                    message.clientPeerDetailsRequest = $root.protocol.ClientPeerDetailsRequest.fromObject(object.clientPeerDetailsRequest);
                }
                if (object.clientPeerAddressesListRequest != null) {
                    if (typeof object.clientPeerAddressesListRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.clientPeerAddressesListRequest: object expected");
                    message.clientPeerAddressesListRequest = $root.protocol.ClientPeerAddressesListRequest.fromObject(object.clientPeerAddressesListRequest);
                }
                if (object.clientPeerTransactionsRequest != null) {
                    if (typeof object.clientPeerTransactionsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.clientPeerTransactionsRequest: object expected");
                    message.clientPeerTransactionsRequest = $root.protocol.ClientPeerTransactionsRequest.fromObject(object.clientPeerTransactionsRequest);
                }
                if (object.deleteClientPeerAddressRequest != null) {
                    if (typeof object.deleteClientPeerAddressRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.deleteClientPeerAddressRequest: object expected");
                    message.deleteClientPeerAddressRequest = $root.protocol.DeleteClientPeerAddressRequest.fromObject(object.deleteClientPeerAddressRequest);
                }
                if (object.authUserRequest != null) {
                    if (typeof object.authUserRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.authUserRequest: object expected");
                    message.authUserRequest = $root.protocol.AuthUserRequest.fromObject(object.authUserRequest);
                }
                if (object.checkSessionRequest != null) {
                    if (typeof object.checkSessionRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.checkSessionRequest: object expected");
                    message.checkSessionRequest = $root.protocol.CheckSessionRequest.fromObject(object.checkSessionRequest);
                }
                if (object.adminCreateClientUserRequest != null) {
                    if (typeof object.adminCreateClientUserRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.adminCreateClientUserRequest: object expected");
                    message.adminCreateClientUserRequest = $root.protocol.AdminCreateClientUserRequest.fromObject(object.adminCreateClientUserRequest);
                }
                if (object.adminClientUsersListRequest != null) {
                    if (typeof object.adminClientUsersListRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.adminClientUsersListRequest: object expected");
                    message.adminClientUsersListRequest = $root.protocol.AdminClientUsersListRequest.fromObject(object.adminClientUsersListRequest);
                }
                if (object.adminUpdateClientUserAccessGroupsRequest != null) {
                    if (typeof object.adminUpdateClientUserAccessGroupsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.adminUpdateClientUserAccessGroupsRequest: object expected");
                    message.adminUpdateClientUserAccessGroupsRequest = $root.protocol.AdminUpdateClientUserAccessGroupsRequest.fromObject(object.adminUpdateClientUserAccessGroupsRequest);
                }
                if (object.adminUpdateClientUserPasswordRequest != null) {
                    if (typeof object.adminUpdateClientUserPasswordRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.adminUpdateClientUserPasswordRequest: object expected");
                    message.adminUpdateClientUserPasswordRequest = $root.protocol.AdminUpdateClientUserPasswordRequest.fromObject(object.adminUpdateClientUserPasswordRequest);
                }
                if (object.clientPeersStatsRequest != null) {
                    if (typeof object.clientPeersStatsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.clientPeersStatsRequest: object expected");
                    message.clientPeersStatsRequest = $root.protocol.ClientPeersStatsRequest.fromObject(object.clientPeersStatsRequest);
                }
                if (object.clientPeerTransactionsStatsRequest != null) {
                    if (typeof object.clientPeerTransactionsStatsRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.clientPeerTransactionsStatsRequest: object expected");
                    message.clientPeerTransactionsStatsRequest = $root.protocol.ClientPeerTransactionsStatsRequest.fromObject(object.clientPeerTransactionsStatsRequest);
                }
                if (object.clientAddressesTagsListRequest != null) {
                    if (typeof object.clientAddressesTagsListRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.clientAddressesTagsListRequest: object expected");
                    message.clientAddressesTagsListRequest = $root.protocol.ClientAddressesTagsListRequest.fromObject(object.clientAddressesTagsListRequest);
                }
                if (object.clientSystemAddressesTagGroupsListRequest != null) {
                    if (typeof object.clientSystemAddressesTagGroupsListRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.clientSystemAddressesTagGroupsListRequest: object expected");
                    message.clientSystemAddressesTagGroupsListRequest = $root.protocol.ClientSystemAddressesTagGroupsListRequest.fromObject(object.clientSystemAddressesTagGroupsListRequest);
                }
                if (object.createClientSystemAddressRequest != null) {
                    if (typeof object.createClientSystemAddressRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.createClientSystemAddressRequest: object expected");
                    message.createClientSystemAddressRequest = $root.protocol.CreateClientSystemAddressRequest.fromObject(object.createClientSystemAddressRequest);
                }
                if (object.clientTotalBalancesRequest != null) {
                    if (typeof object.clientTotalBalancesRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.clientTotalBalancesRequest: object expected");
                    message.clientTotalBalancesRequest = $root.protocol.ClientTotalBalancesRequest.fromObject(object.clientTotalBalancesRequest);
                }
                if (object.clientPeerChangeTransactionAmountRequest != null) {
                    if (typeof object.clientPeerChangeTransactionAmountRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.clientPeerChangeTransactionAmountRequest: object expected");
                    message.clientPeerChangeTransactionAmountRequest = $root.protocol.ClientPeerChangeTransactionAmountRequest.fromObject(object.clientPeerChangeTransactionAmountRequest);
                }
                if (object.appVersionRequest != null) {
                    if (typeof object.appVersionRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.appVersionRequest: object expected");
                    message.appVersionRequest = $root.protocol.AppVersionRequest.fromObject(object.appVersionRequest);
                }
                if (object.hasChangesDashboardRequest != null) {
                    if (typeof object.hasChangesDashboardRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.hasChangesDashboardRequest: object expected");
                    message.hasChangesDashboardRequest = $root.protocol.HasChangesDashboardRequest.fromObject(object.hasChangesDashboardRequest);
                }
                if (object.latestChangesDashboardRequest != null) {
                    if (typeof object.latestChangesDashboardRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.latestChangesDashboardRequest: object expected");
                    message.latestChangesDashboardRequest = $root.protocol.LatestChangesDashboardRequest.fromObject(object.latestChangesDashboardRequest);
                }
                if (object.checkTokenPayoutSystemBalanceRequest != null) {
                    if (typeof object.checkTokenPayoutSystemBalanceRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.checkTokenPayoutSystemBalanceRequest: object expected");
                    message.checkTokenPayoutSystemBalanceRequest = $root.protocol.CheckTokenPayoutSystemBalanceRequest.fromObject(object.checkTokenPayoutSystemBalanceRequest);
                }
                if (object.cancelPendingPayoutRequestRequest != null) {
                    if (typeof object.cancelPendingPayoutRequestRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.cancelPendingPayoutRequestRequest: object expected");
                    message.cancelPendingPayoutRequestRequest = $root.protocol.CancelPendingPayoutRequestRequest.fromObject(object.cancelPendingPayoutRequestRequest);
                }
                if (object.amlClientUnboundChecksListRequest != null) {
                    if (typeof object.amlClientUnboundChecksListRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.amlClientUnboundChecksListRequest: object expected");
                    message.amlClientUnboundChecksListRequest = $root.protocol.AMLClientUnboundChecksListRequest.fromObject(object.amlClientUnboundChecksListRequest);
                }
                if (object.clientAddressesListRequest != null) {
                    if (typeof object.clientAddressesListRequest !== "object")
                        throw TypeError(".protocol.ClientRequest.clientAddressesListRequest: object expected");
                    message.clientAddressesListRequest = $root.protocol.ClientAddressesListRequest.fromObject(object.clientAddressesListRequest);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientRequest
             * @static
             * @param {protocol.ClientRequest} message ClientRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.trx = "";
                    object.clientId = "";
                    object.authToken = "";
                    object.createNewAddressRequest = null;
                    object.balancesRequest = null;
                    object.transactionsRequest = null;
                    object.enqueuePayoutRequest = null;
                    object.payoutRequestDetailsRequest = null;
                    object.mainAccountsRequest = null;
                    object.modifyTagsRequest = null;
                    object.listTagsRequest = null;
                    object.confirmedClientTransactionsRequest = null;
                    object.payoutDetailsRequest = null;
                    object.convertCryptoToFiatRequest = null;
                    object.transactionFeeRequest = null;
                    object.clientRequestLogsRequest = null;
                    object.listPayoutRequestsRequest = null;
                    object.amlSingleCheckRequest = null;
                    object.amlCheckResultRequest = null;
                    object.amlClientBatchCheckStartRequest = null;
                    object.amlClientBatchCheckStopRequest = null;
                    object.amlBatchCheckResultsRequest = null;
                    object.amlServiceStatusRequest = null;
                    object.transactionsPagedRequest = null;
                    object.transactionsStatsRequest = null;
                    object.amlClientOverallStatsRequest = null;
                    object.amlClientAddressesListRequest = null;
                    object.amlClientBatchStatsRequest = null;
                    object.amlClientBatchAddressesListRequest = null;
                    object.amlClientBatchChecksListRequest = null;
                    object.amlClientBatchAddressRequest = null;
                    object.feeRatesRequest = null;
                    object.createClientPeerRequest = null;
                    object.generateNewClientPeerAddressRequest = null;
                    object.regenerateClientPeerAddressRequest = null;
                    object.clientPeersShortListRequest = null;
                    object.clientPeersListRequest = null;
                    object.clientPeerDetailsRequest = null;
                    object.clientPeerAddressesListRequest = null;
                    object.clientPeerTransactionsRequest = null;
                    object.deleteClientPeerAddressRequest = null;
                    object.authUserRequest = null;
                    object.checkSessionRequest = null;
                    object.adminCreateClientUserRequest = null;
                    object.adminClientUsersListRequest = null;
                    object.adminUpdateClientUserAccessGroupsRequest = null;
                    object.adminUpdateClientUserPasswordRequest = null;
                    object.clientPeersStatsRequest = null;
                    object.clientPeerTransactionsStatsRequest = null;
                    object.clientAddressesTagsListRequest = null;
                    object.clientSystemAddressesTagGroupsListRequest = null;
                    object.createClientSystemAddressRequest = null;
                    object.clientTotalBalancesRequest = null;
                    object.clientPeerChangeTransactionAmountRequest = null;
                    object.appVersionRequest = null;
                    object.hasChangesDashboardRequest = null;
                    object.latestChangesDashboardRequest = null;
                    object.checkTokenPayoutSystemBalanceRequest = null;
                    object.cancelPendingPayoutRequestRequest = null;
                    object.amlClientUnboundChecksListRequest = null;
                    object.clientAddressesListRequest = null;
                }
                if (message.trx != null && message.hasOwnProperty("trx"))
                    object.trx = message.trx;
                if (message.clientId != null && message.hasOwnProperty("clientId"))
                    object.clientId = message.clientId;
                if (message.authToken != null && message.hasOwnProperty("authToken"))
                    object.authToken = message.authToken;
                if (message.createNewAddressRequest != null && message.hasOwnProperty("createNewAddressRequest"))
                    object.createNewAddressRequest = $root.protocol.CreateNewAddressRequest.toObject(message.createNewAddressRequest, options);
                if (message.balancesRequest != null && message.hasOwnProperty("balancesRequest"))
                    object.balancesRequest = $root.protocol.BalancesRequest.toObject(message.balancesRequest, options);
                if (message.transactionsRequest != null && message.hasOwnProperty("transactionsRequest"))
                    object.transactionsRequest = $root.protocol.TransactionsRequest.toObject(message.transactionsRequest, options);
                if (message.enqueuePayoutRequest != null && message.hasOwnProperty("enqueuePayoutRequest"))
                    object.enqueuePayoutRequest = $root.protocol.EnqueuePayoutRequest.toObject(message.enqueuePayoutRequest, options);
                if (message.payoutRequestDetailsRequest != null && message.hasOwnProperty("payoutRequestDetailsRequest"))
                    object.payoutRequestDetailsRequest = $root.protocol.PayoutRequestDetailsRequest.toObject(message.payoutRequestDetailsRequest, options);
                if (message.mainAccountsRequest != null && message.hasOwnProperty("mainAccountsRequest"))
                    object.mainAccountsRequest = $root.protocol.MainAccountsRequest.toObject(message.mainAccountsRequest, options);
                if (message.modifyTagsRequest != null && message.hasOwnProperty("modifyTagsRequest"))
                    object.modifyTagsRequest = $root.protocol.ModifyTagsRequest.toObject(message.modifyTagsRequest, options);
                if (message.listTagsRequest != null && message.hasOwnProperty("listTagsRequest"))
                    object.listTagsRequest = $root.protocol.ListTagsRequest.toObject(message.listTagsRequest, options);
                if (message.confirmedClientTransactionsRequest != null && message.hasOwnProperty("confirmedClientTransactionsRequest"))
                    object.confirmedClientTransactionsRequest = $root.protocol.ConfirmedClientTransactionsRequest.toObject(message.confirmedClientTransactionsRequest, options);
                if (message.payoutDetailsRequest != null && message.hasOwnProperty("payoutDetailsRequest"))
                    object.payoutDetailsRequest = $root.protocol.PayoutDetailsRequest.toObject(message.payoutDetailsRequest, options);
                if (message.convertCryptoToFiatRequest != null && message.hasOwnProperty("convertCryptoToFiatRequest"))
                    object.convertCryptoToFiatRequest = $root.protocol.ConvertCryptoToFiatRequest.toObject(message.convertCryptoToFiatRequest, options);
                if (message.transactionFeeRequest != null && message.hasOwnProperty("transactionFeeRequest"))
                    object.transactionFeeRequest = $root.protocol.TransactionFeeRequest.toObject(message.transactionFeeRequest, options);
                if (message.clientRequestLogsRequest != null && message.hasOwnProperty("clientRequestLogsRequest"))
                    object.clientRequestLogsRequest = $root.protocol.ClientRequestLogsRequest.toObject(message.clientRequestLogsRequest, options);
                if (message.listPayoutRequestsRequest != null && message.hasOwnProperty("listPayoutRequestsRequest"))
                    object.listPayoutRequestsRequest = $root.protocol.ListPayoutRequestsRequest.toObject(message.listPayoutRequestsRequest, options);
                if (message.amlSingleCheckRequest != null && message.hasOwnProperty("amlSingleCheckRequest"))
                    object.amlSingleCheckRequest = $root.protocol.AMLSingleCheckRequest.toObject(message.amlSingleCheckRequest, options);
                if (message.amlCheckResultRequest != null && message.hasOwnProperty("amlCheckResultRequest"))
                    object.amlCheckResultRequest = $root.protocol.AMLCheckResultRequest.toObject(message.amlCheckResultRequest, options);
                if (message.amlClientBatchCheckStartRequest != null && message.hasOwnProperty("amlClientBatchCheckStartRequest"))
                    object.amlClientBatchCheckStartRequest = $root.protocol.AMLClientBatchCheckStartRequest.toObject(message.amlClientBatchCheckStartRequest, options);
                if (message.amlClientBatchCheckStopRequest != null && message.hasOwnProperty("amlClientBatchCheckStopRequest"))
                    object.amlClientBatchCheckStopRequest = $root.protocol.AMLClientBatchCheckStopRequest.toObject(message.amlClientBatchCheckStopRequest, options);
                if (message.amlBatchCheckResultsRequest != null && message.hasOwnProperty("amlBatchCheckResultsRequest"))
                    object.amlBatchCheckResultsRequest = $root.protocol.AMLBatchCheckResultsRequest.toObject(message.amlBatchCheckResultsRequest, options);
                if (message.amlServiceStatusRequest != null && message.hasOwnProperty("amlServiceStatusRequest"))
                    object.amlServiceStatusRequest = $root.protocol.AMLServiceStatusRequest.toObject(message.amlServiceStatusRequest, options);
                if (message.transactionsPagedRequest != null && message.hasOwnProperty("transactionsPagedRequest"))
                    object.transactionsPagedRequest = $root.protocol.TransactionsPagedRequest.toObject(message.transactionsPagedRequest, options);
                if (message.transactionsStatsRequest != null && message.hasOwnProperty("transactionsStatsRequest"))
                    object.transactionsStatsRequest = $root.protocol.TransactionsStatsRequest.toObject(message.transactionsStatsRequest, options);
                if (message.amlClientOverallStatsRequest != null && message.hasOwnProperty("amlClientOverallStatsRequest"))
                    object.amlClientOverallStatsRequest = $root.protocol.AMLClientOverallStatsRequest.toObject(message.amlClientOverallStatsRequest, options);
                if (message.amlClientAddressesListRequest != null && message.hasOwnProperty("amlClientAddressesListRequest"))
                    object.amlClientAddressesListRequest = $root.protocol.AMLClientAddressesListRequest.toObject(message.amlClientAddressesListRequest, options);
                if (message.amlClientBatchStatsRequest != null && message.hasOwnProperty("amlClientBatchStatsRequest"))
                    object.amlClientBatchStatsRequest = $root.protocol.AMLClientBatchStatsRequest.toObject(message.amlClientBatchStatsRequest, options);
                if (message.amlClientBatchAddressesListRequest != null && message.hasOwnProperty("amlClientBatchAddressesListRequest"))
                    object.amlClientBatchAddressesListRequest = $root.protocol.AMLClientBatchAddressesListRequest.toObject(message.amlClientBatchAddressesListRequest, options);
                if (message.amlClientBatchChecksListRequest != null && message.hasOwnProperty("amlClientBatchChecksListRequest"))
                    object.amlClientBatchChecksListRequest = $root.protocol.AMLClientBatchChecksListRequest.toObject(message.amlClientBatchChecksListRequest, options);
                if (message.amlClientBatchAddressRequest != null && message.hasOwnProperty("amlClientBatchAddressRequest"))
                    object.amlClientBatchAddressRequest = $root.protocol.AMLClientBatchAddressRequest.toObject(message.amlClientBatchAddressRequest, options);
                if (message.feeRatesRequest != null && message.hasOwnProperty("feeRatesRequest"))
                    object.feeRatesRequest = $root.protocol.FeeRatesRequest.toObject(message.feeRatesRequest, options);
                if (message.createClientPeerRequest != null && message.hasOwnProperty("createClientPeerRequest"))
                    object.createClientPeerRequest = $root.protocol.CreateClientPeerRequest.toObject(message.createClientPeerRequest, options);
                if (message.generateNewClientPeerAddressRequest != null && message.hasOwnProperty("generateNewClientPeerAddressRequest"))
                    object.generateNewClientPeerAddressRequest = $root.protocol.GenerateNewClientPeerAddressRequest.toObject(message.generateNewClientPeerAddressRequest, options);
                if (message.regenerateClientPeerAddressRequest != null && message.hasOwnProperty("regenerateClientPeerAddressRequest"))
                    object.regenerateClientPeerAddressRequest = $root.protocol.RegenerateClientPeerAddressRequest.toObject(message.regenerateClientPeerAddressRequest, options);
                if (message.clientPeersShortListRequest != null && message.hasOwnProperty("clientPeersShortListRequest"))
                    object.clientPeersShortListRequest = $root.protocol.ClientPeersShortListRequest.toObject(message.clientPeersShortListRequest, options);
                if (message.clientPeersListRequest != null && message.hasOwnProperty("clientPeersListRequest"))
                    object.clientPeersListRequest = $root.protocol.ClientPeersListRequest.toObject(message.clientPeersListRequest, options);
                if (message.clientPeerDetailsRequest != null && message.hasOwnProperty("clientPeerDetailsRequest"))
                    object.clientPeerDetailsRequest = $root.protocol.ClientPeerDetailsRequest.toObject(message.clientPeerDetailsRequest, options);
                if (message.clientPeerAddressesListRequest != null && message.hasOwnProperty("clientPeerAddressesListRequest"))
                    object.clientPeerAddressesListRequest = $root.protocol.ClientPeerAddressesListRequest.toObject(message.clientPeerAddressesListRequest, options);
                if (message.clientPeerTransactionsRequest != null && message.hasOwnProperty("clientPeerTransactionsRequest"))
                    object.clientPeerTransactionsRequest = $root.protocol.ClientPeerTransactionsRequest.toObject(message.clientPeerTransactionsRequest, options);
                if (message.deleteClientPeerAddressRequest != null && message.hasOwnProperty("deleteClientPeerAddressRequest"))
                    object.deleteClientPeerAddressRequest = $root.protocol.DeleteClientPeerAddressRequest.toObject(message.deleteClientPeerAddressRequest, options);
                if (message.authUserRequest != null && message.hasOwnProperty("authUserRequest"))
                    object.authUserRequest = $root.protocol.AuthUserRequest.toObject(message.authUserRequest, options);
                if (message.checkSessionRequest != null && message.hasOwnProperty("checkSessionRequest"))
                    object.checkSessionRequest = $root.protocol.CheckSessionRequest.toObject(message.checkSessionRequest, options);
                if (message.adminCreateClientUserRequest != null && message.hasOwnProperty("adminCreateClientUserRequest"))
                    object.adminCreateClientUserRequest = $root.protocol.AdminCreateClientUserRequest.toObject(message.adminCreateClientUserRequest, options);
                if (message.adminClientUsersListRequest != null && message.hasOwnProperty("adminClientUsersListRequest"))
                    object.adminClientUsersListRequest = $root.protocol.AdminClientUsersListRequest.toObject(message.adminClientUsersListRequest, options);
                if (message.adminUpdateClientUserAccessGroupsRequest != null && message.hasOwnProperty("adminUpdateClientUserAccessGroupsRequest"))
                    object.adminUpdateClientUserAccessGroupsRequest = $root.protocol.AdminUpdateClientUserAccessGroupsRequest.toObject(message.adminUpdateClientUserAccessGroupsRequest, options);
                if (message.adminUpdateClientUserPasswordRequest != null && message.hasOwnProperty("adminUpdateClientUserPasswordRequest"))
                    object.adminUpdateClientUserPasswordRequest = $root.protocol.AdminUpdateClientUserPasswordRequest.toObject(message.adminUpdateClientUserPasswordRequest, options);
                if (message.clientPeersStatsRequest != null && message.hasOwnProperty("clientPeersStatsRequest"))
                    object.clientPeersStatsRequest = $root.protocol.ClientPeersStatsRequest.toObject(message.clientPeersStatsRequest, options);
                if (message.clientPeerTransactionsStatsRequest != null && message.hasOwnProperty("clientPeerTransactionsStatsRequest"))
                    object.clientPeerTransactionsStatsRequest = $root.protocol.ClientPeerTransactionsStatsRequest.toObject(message.clientPeerTransactionsStatsRequest, options);
                if (message.clientAddressesTagsListRequest != null && message.hasOwnProperty("clientAddressesTagsListRequest"))
                    object.clientAddressesTagsListRequest = $root.protocol.ClientAddressesTagsListRequest.toObject(message.clientAddressesTagsListRequest, options);
                if (message.clientSystemAddressesTagGroupsListRequest != null && message.hasOwnProperty("clientSystemAddressesTagGroupsListRequest"))
                    object.clientSystemAddressesTagGroupsListRequest = $root.protocol.ClientSystemAddressesTagGroupsListRequest.toObject(message.clientSystemAddressesTagGroupsListRequest, options);
                if (message.createClientSystemAddressRequest != null && message.hasOwnProperty("createClientSystemAddressRequest"))
                    object.createClientSystemAddressRequest = $root.protocol.CreateClientSystemAddressRequest.toObject(message.createClientSystemAddressRequest, options);
                if (message.clientTotalBalancesRequest != null && message.hasOwnProperty("clientTotalBalancesRequest"))
                    object.clientTotalBalancesRequest = $root.protocol.ClientTotalBalancesRequest.toObject(message.clientTotalBalancesRequest, options);
                if (message.clientPeerChangeTransactionAmountRequest != null && message.hasOwnProperty("clientPeerChangeTransactionAmountRequest"))
                    object.clientPeerChangeTransactionAmountRequest = $root.protocol.ClientPeerChangeTransactionAmountRequest.toObject(message.clientPeerChangeTransactionAmountRequest, options);
                if (message.appVersionRequest != null && message.hasOwnProperty("appVersionRequest"))
                    object.appVersionRequest = $root.protocol.AppVersionRequest.toObject(message.appVersionRequest, options);
                if (message.hasChangesDashboardRequest != null && message.hasOwnProperty("hasChangesDashboardRequest"))
                    object.hasChangesDashboardRequest = $root.protocol.HasChangesDashboardRequest.toObject(message.hasChangesDashboardRequest, options);
                if (message.latestChangesDashboardRequest != null && message.hasOwnProperty("latestChangesDashboardRequest"))
                    object.latestChangesDashboardRequest = $root.protocol.LatestChangesDashboardRequest.toObject(message.latestChangesDashboardRequest, options);
                if (message.checkTokenPayoutSystemBalanceRequest != null && message.hasOwnProperty("checkTokenPayoutSystemBalanceRequest"))
                    object.checkTokenPayoutSystemBalanceRequest = $root.protocol.CheckTokenPayoutSystemBalanceRequest.toObject(message.checkTokenPayoutSystemBalanceRequest, options);
                if (message.cancelPendingPayoutRequestRequest != null && message.hasOwnProperty("cancelPendingPayoutRequestRequest"))
                    object.cancelPendingPayoutRequestRequest = $root.protocol.CancelPendingPayoutRequestRequest.toObject(message.cancelPendingPayoutRequestRequest, options);
                if (message.amlClientUnboundChecksListRequest != null && message.hasOwnProperty("amlClientUnboundChecksListRequest"))
                    object.amlClientUnboundChecksListRequest = $root.protocol.AMLClientUnboundChecksListRequest.toObject(message.amlClientUnboundChecksListRequest, options);
                if (message.clientAddressesListRequest != null && message.hasOwnProperty("clientAddressesListRequest"))
                    object.clientAddressesListRequest = $root.protocol.ClientAddressesListRequest.toObject(message.clientAddressesListRequest, options);
                return object;
            };
    
            /**
             * Converts this ClientRequest to JSON.
             * @function toJSON
             * @memberof protocol.ClientRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientRequest;
        })();
    
        protocol.ServerResponse = (function() {
    
            /**
             * Properties of a ServerResponse.
             * @memberof protocol
             * @interface IServerResponse
             * @property {protocol.ICreateNewAddressResponse|null} [createNewAddressResponse] ServerResponse createNewAddressResponse
             * @property {protocol.IBalancesResponse|null} [balancesResponse] ServerResponse balancesResponse
             * @property {protocol.ITransactionsResponse|null} [transactionsResponse] ServerResponse transactionsResponse
             * @property {protocol.IEnqueuePayoutResponse|null} [enqueuePayoutResponse] ServerResponse enqueuePayoutResponse
             * @property {protocol.IPayoutRequestDetailsResponse|null} [payoutRequestDetailsResponse] ServerResponse payoutRequestDetailsResponse
             * @property {protocol.IMainAccountsResponse|null} [mainAccountsResponse] ServerResponse mainAccountsResponse
             * @property {protocol.IModifyTagsResponse|null} [modifyTagsResponse] ServerResponse modifyTagsResponse
             * @property {protocol.IListTagsResponse|null} [listTagsResponse] ServerResponse listTagsResponse
             * @property {protocol.IConfirmedClientTransactionsResponse|null} [confirmedClientTransactionsResponse] ServerResponse confirmedClientTransactionsResponse
             * @property {protocol.IPayoutDetailsResponse|null} [payoutDetailsResponse] ServerResponse payoutDetailsResponse
             * @property {protocol.IConvertCryptoToFiatResponse|null} [convertCryptoToFiatResponse] ServerResponse convertCryptoToFiatResponse
             * @property {protocol.ITransactionFeeResponse|null} [transactionFeeResponse] ServerResponse transactionFeeResponse
             * @property {protocol.IClientRequestLogsResponse|null} [clientRequestLogsResponse] ServerResponse clientRequestLogsResponse
             * @property {protocol.IListPayoutRequestsResponse|null} [listPayoutRequestsResponse] ServerResponse listPayoutRequestsResponse
             * @property {protocol.IAMLSingleCheckResponse|null} [amlSingleCheckResponse] ServerResponse amlSingleCheckResponse
             * @property {protocol.IAMLCheckResultResponse|null} [amlCheckResultResponse] ServerResponse amlCheckResultResponse
             * @property {protocol.IAMLClientBatchCheckStartResponse|null} [amlClientBatchCheckStartResponse] ServerResponse amlClientBatchCheckStartResponse
             * @property {protocol.IAMLClientBatchCheckStopResponse|null} [amlClientBatchCheckStopResponse] ServerResponse amlClientBatchCheckStopResponse
             * @property {protocol.IAMLBatchCheckResultsResponse|null} [amlBatchCheckResultsResponse] ServerResponse amlBatchCheckResultsResponse
             * @property {protocol.IAMLServiceStatusResponse|null} [amlServiceStatusResponse] ServerResponse amlServiceStatusResponse
             * @property {protocol.ITransactionsPagedResponse|null} [transactionsPagedResponse] ServerResponse transactionsPagedResponse
             * @property {protocol.ITransactionsStatsResponse|null} [transactionsStatsResponse] ServerResponse transactionsStatsResponse
             * @property {protocol.IAMLClientOverallStatsResponse|null} [amlClientOverallStatsResponse] ServerResponse amlClientOverallStatsResponse
             * @property {protocol.IAMLClientAddressesListResponse|null} [amlClientAddressesListResponse] ServerResponse amlClientAddressesListResponse
             * @property {protocol.IAMLClientBatchStatsResponse|null} [amlClientBatchStatsResponse] ServerResponse amlClientBatchStatsResponse
             * @property {protocol.IAMLClientBatchAddressesListResponse|null} [amlClientBatchAddressesListResponse] ServerResponse amlClientBatchAddressesListResponse
             * @property {protocol.IAMLClientBatchChecksListResponse|null} [amlClientBatchChecksListResponse] ServerResponse amlClientBatchChecksListResponse
             * @property {protocol.IAMLClientBatchAddressResponse|null} [amlClientBatchAddressResponse] ServerResponse amlClientBatchAddressResponse
             * @property {protocol.IFeeRatesResponse|null} [feeRatesResponse] ServerResponse feeRatesResponse
             * @property {protocol.ICreateClientPeerResponse|null} [createClientPeerResponse] ServerResponse createClientPeerResponse
             * @property {protocol.IGenerateNewClientPeerAddressResponse|null} [generateNewClientPeerAddressResponse] ServerResponse generateNewClientPeerAddressResponse
             * @property {protocol.IRegenerateClientPeerAddressResponse|null} [regenerateClientPeerAddressResponse] ServerResponse regenerateClientPeerAddressResponse
             * @property {protocol.IClientPeersShortListResponse|null} [clientPeersShortListResponse] ServerResponse clientPeersShortListResponse
             * @property {protocol.IClientPeersListResponse|null} [clientPeersListResponse] ServerResponse clientPeersListResponse
             * @property {protocol.IClientPeerDetailsResponse|null} [clientPeerDetailsResponse] ServerResponse clientPeerDetailsResponse
             * @property {protocol.IClientPeerAddressesListResponse|null} [clientPeerAddressesListResponse] ServerResponse clientPeerAddressesListResponse
             * @property {protocol.IClientPeerTransactionsResponse|null} [clientPeerTransactionsResponse] ServerResponse clientPeerTransactionsResponse
             * @property {protocol.IDeleteClientPeerAddressResponse|null} [deleteClientPeerAddressResponse] ServerResponse deleteClientPeerAddressResponse
             * @property {protocol.IAuthUserResponse|null} [authUserResponse] ServerResponse authUserResponse
             * @property {protocol.ICheckSessionResponse|null} [checkSessionResponse] ServerResponse checkSessionResponse
             * @property {protocol.IAdminCreateClientUserResponse|null} [adminCreateClientUserResponse] ServerResponse adminCreateClientUserResponse
             * @property {protocol.IAdminClientUsersListResponse|null} [adminClientUsersListResponse] ServerResponse adminClientUsersListResponse
             * @property {protocol.IAdminUpdateClientUserAccessGroupsResponse|null} [adminUpdateClientUserAccessGroupsResponse] ServerResponse adminUpdateClientUserAccessGroupsResponse
             * @property {protocol.IAdminUpdateClientUserPasswordResponse|null} [adminUpdateClientUserPasswordResponse] ServerResponse adminUpdateClientUserPasswordResponse
             * @property {protocol.IClientPeersStatsResponse|null} [clientPeersStatsResponse] ServerResponse clientPeersStatsResponse
             * @property {protocol.IClientPeerTransactionsStatsResponse|null} [clientPeerTransactionsStatsResponse] ServerResponse clientPeerTransactionsStatsResponse
             * @property {protocol.IClientAddressesTagsListResponse|null} [clientAddressesTagsListResponse] ServerResponse clientAddressesTagsListResponse
             * @property {protocol.IClientSystemAddressesTagGroupsListResponse|null} [clientSystemAddressesTagGroupsListResponse] ServerResponse clientSystemAddressesTagGroupsListResponse
             * @property {protocol.ICreateClientSystemAddressResponse|null} [createClientSystemAddressResponse] ServerResponse createClientSystemAddressResponse
             * @property {protocol.IClientTotalBalancesResponse|null} [clientTotalBalancesResponse] ServerResponse clientTotalBalancesResponse
             * @property {protocol.IClientPeerChangeTransactionAmountResponse|null} [clientPeerChangeTransactionAmountResponse] ServerResponse clientPeerChangeTransactionAmountResponse
             * @property {protocol.IAppVersionResponse|null} [appVersionResponse] ServerResponse appVersionResponse
             * @property {protocol.IHasChangesDashboardResponse|null} [hasChangesDashboardResponse] ServerResponse hasChangesDashboardResponse
             * @property {protocol.ILatestChangesDashboardResponse|null} [latestChangesDashboardResponse] ServerResponse latestChangesDashboardResponse
             * @property {protocol.ICheckTokenPayoutSystemBalanceResponse|null} [checkTokenPayoutSystemBalanceResponse] ServerResponse checkTokenPayoutSystemBalanceResponse
             * @property {protocol.ICancelPendingPayoutRequestResponse|null} [cancelPendingPayoutRequestResponse] ServerResponse cancelPendingPayoutRequestResponse
             * @property {protocol.IAMLClientUnboundChecksListResponse|null} [amlClientUnboundChecksListResponse] ServerResponse amlClientUnboundChecksListResponse
             * @property {protocol.IClientAddressesListResponse|null} [clientAddressesListResponse] ServerResponse clientAddressesListResponse
             * @property {protocol.ServerError|null} [error] ServerResponse error
             */
    
            /**
             * Constructs a new ServerResponse.
             * @memberof protocol
             * @classdesc Represents a ServerResponse.
             * @implements IServerResponse
             * @constructor
             * @param {protocol.IServerResponse=} [properties] Properties to set
             */
            function ServerResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ServerResponse createNewAddressResponse.
             * @member {protocol.ICreateNewAddressResponse|null|undefined} createNewAddressResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.createNewAddressResponse = null;
    
            /**
             * ServerResponse balancesResponse.
             * @member {protocol.IBalancesResponse|null|undefined} balancesResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.balancesResponse = null;
    
            /**
             * ServerResponse transactionsResponse.
             * @member {protocol.ITransactionsResponse|null|undefined} transactionsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.transactionsResponse = null;
    
            /**
             * ServerResponse enqueuePayoutResponse.
             * @member {protocol.IEnqueuePayoutResponse|null|undefined} enqueuePayoutResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.enqueuePayoutResponse = null;
    
            /**
             * ServerResponse payoutRequestDetailsResponse.
             * @member {protocol.IPayoutRequestDetailsResponse|null|undefined} payoutRequestDetailsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.payoutRequestDetailsResponse = null;
    
            /**
             * ServerResponse mainAccountsResponse.
             * @member {protocol.IMainAccountsResponse|null|undefined} mainAccountsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.mainAccountsResponse = null;
    
            /**
             * ServerResponse modifyTagsResponse.
             * @member {protocol.IModifyTagsResponse|null|undefined} modifyTagsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.modifyTagsResponse = null;
    
            /**
             * ServerResponse listTagsResponse.
             * @member {protocol.IListTagsResponse|null|undefined} listTagsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.listTagsResponse = null;
    
            /**
             * ServerResponse confirmedClientTransactionsResponse.
             * @member {protocol.IConfirmedClientTransactionsResponse|null|undefined} confirmedClientTransactionsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.confirmedClientTransactionsResponse = null;
    
            /**
             * ServerResponse payoutDetailsResponse.
             * @member {protocol.IPayoutDetailsResponse|null|undefined} payoutDetailsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.payoutDetailsResponse = null;
    
            /**
             * ServerResponse convertCryptoToFiatResponse.
             * @member {protocol.IConvertCryptoToFiatResponse|null|undefined} convertCryptoToFiatResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.convertCryptoToFiatResponse = null;
    
            /**
             * ServerResponse transactionFeeResponse.
             * @member {protocol.ITransactionFeeResponse|null|undefined} transactionFeeResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.transactionFeeResponse = null;
    
            /**
             * ServerResponse clientRequestLogsResponse.
             * @member {protocol.IClientRequestLogsResponse|null|undefined} clientRequestLogsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.clientRequestLogsResponse = null;
    
            /**
             * ServerResponse listPayoutRequestsResponse.
             * @member {protocol.IListPayoutRequestsResponse|null|undefined} listPayoutRequestsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.listPayoutRequestsResponse = null;
    
            /**
             * ServerResponse amlSingleCheckResponse.
             * @member {protocol.IAMLSingleCheckResponse|null|undefined} amlSingleCheckResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.amlSingleCheckResponse = null;
    
            /**
             * ServerResponse amlCheckResultResponse.
             * @member {protocol.IAMLCheckResultResponse|null|undefined} amlCheckResultResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.amlCheckResultResponse = null;
    
            /**
             * ServerResponse amlClientBatchCheckStartResponse.
             * @member {protocol.IAMLClientBatchCheckStartResponse|null|undefined} amlClientBatchCheckStartResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.amlClientBatchCheckStartResponse = null;
    
            /**
             * ServerResponse amlClientBatchCheckStopResponse.
             * @member {protocol.IAMLClientBatchCheckStopResponse|null|undefined} amlClientBatchCheckStopResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.amlClientBatchCheckStopResponse = null;
    
            /**
             * ServerResponse amlBatchCheckResultsResponse.
             * @member {protocol.IAMLBatchCheckResultsResponse|null|undefined} amlBatchCheckResultsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.amlBatchCheckResultsResponse = null;
    
            /**
             * ServerResponse amlServiceStatusResponse.
             * @member {protocol.IAMLServiceStatusResponse|null|undefined} amlServiceStatusResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.amlServiceStatusResponse = null;
    
            /**
             * ServerResponse transactionsPagedResponse.
             * @member {protocol.ITransactionsPagedResponse|null|undefined} transactionsPagedResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.transactionsPagedResponse = null;
    
            /**
             * ServerResponse transactionsStatsResponse.
             * @member {protocol.ITransactionsStatsResponse|null|undefined} transactionsStatsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.transactionsStatsResponse = null;
    
            /**
             * ServerResponse amlClientOverallStatsResponse.
             * @member {protocol.IAMLClientOverallStatsResponse|null|undefined} amlClientOverallStatsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.amlClientOverallStatsResponse = null;
    
            /**
             * ServerResponse amlClientAddressesListResponse.
             * @member {protocol.IAMLClientAddressesListResponse|null|undefined} amlClientAddressesListResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.amlClientAddressesListResponse = null;
    
            /**
             * ServerResponse amlClientBatchStatsResponse.
             * @member {protocol.IAMLClientBatchStatsResponse|null|undefined} amlClientBatchStatsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.amlClientBatchStatsResponse = null;
    
            /**
             * ServerResponse amlClientBatchAddressesListResponse.
             * @member {protocol.IAMLClientBatchAddressesListResponse|null|undefined} amlClientBatchAddressesListResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.amlClientBatchAddressesListResponse = null;
    
            /**
             * ServerResponse amlClientBatchChecksListResponse.
             * @member {protocol.IAMLClientBatchChecksListResponse|null|undefined} amlClientBatchChecksListResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.amlClientBatchChecksListResponse = null;
    
            /**
             * ServerResponse amlClientBatchAddressResponse.
             * @member {protocol.IAMLClientBatchAddressResponse|null|undefined} amlClientBatchAddressResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.amlClientBatchAddressResponse = null;
    
            /**
             * ServerResponse feeRatesResponse.
             * @member {protocol.IFeeRatesResponse|null|undefined} feeRatesResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.feeRatesResponse = null;
    
            /**
             * ServerResponse createClientPeerResponse.
             * @member {protocol.ICreateClientPeerResponse|null|undefined} createClientPeerResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.createClientPeerResponse = null;
    
            /**
             * ServerResponse generateNewClientPeerAddressResponse.
             * @member {protocol.IGenerateNewClientPeerAddressResponse|null|undefined} generateNewClientPeerAddressResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.generateNewClientPeerAddressResponse = null;
    
            /**
             * ServerResponse regenerateClientPeerAddressResponse.
             * @member {protocol.IRegenerateClientPeerAddressResponse|null|undefined} regenerateClientPeerAddressResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.regenerateClientPeerAddressResponse = null;
    
            /**
             * ServerResponse clientPeersShortListResponse.
             * @member {protocol.IClientPeersShortListResponse|null|undefined} clientPeersShortListResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.clientPeersShortListResponse = null;
    
            /**
             * ServerResponse clientPeersListResponse.
             * @member {protocol.IClientPeersListResponse|null|undefined} clientPeersListResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.clientPeersListResponse = null;
    
            /**
             * ServerResponse clientPeerDetailsResponse.
             * @member {protocol.IClientPeerDetailsResponse|null|undefined} clientPeerDetailsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.clientPeerDetailsResponse = null;
    
            /**
             * ServerResponse clientPeerAddressesListResponse.
             * @member {protocol.IClientPeerAddressesListResponse|null|undefined} clientPeerAddressesListResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.clientPeerAddressesListResponse = null;
    
            /**
             * ServerResponse clientPeerTransactionsResponse.
             * @member {protocol.IClientPeerTransactionsResponse|null|undefined} clientPeerTransactionsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.clientPeerTransactionsResponse = null;
    
            /**
             * ServerResponse deleteClientPeerAddressResponse.
             * @member {protocol.IDeleteClientPeerAddressResponse|null|undefined} deleteClientPeerAddressResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.deleteClientPeerAddressResponse = null;
    
            /**
             * ServerResponse authUserResponse.
             * @member {protocol.IAuthUserResponse|null|undefined} authUserResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.authUserResponse = null;
    
            /**
             * ServerResponse checkSessionResponse.
             * @member {protocol.ICheckSessionResponse|null|undefined} checkSessionResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.checkSessionResponse = null;
    
            /**
             * ServerResponse adminCreateClientUserResponse.
             * @member {protocol.IAdminCreateClientUserResponse|null|undefined} adminCreateClientUserResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.adminCreateClientUserResponse = null;
    
            /**
             * ServerResponse adminClientUsersListResponse.
             * @member {protocol.IAdminClientUsersListResponse|null|undefined} adminClientUsersListResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.adminClientUsersListResponse = null;
    
            /**
             * ServerResponse adminUpdateClientUserAccessGroupsResponse.
             * @member {protocol.IAdminUpdateClientUserAccessGroupsResponse|null|undefined} adminUpdateClientUserAccessGroupsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.adminUpdateClientUserAccessGroupsResponse = null;
    
            /**
             * ServerResponse adminUpdateClientUserPasswordResponse.
             * @member {protocol.IAdminUpdateClientUserPasswordResponse|null|undefined} adminUpdateClientUserPasswordResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.adminUpdateClientUserPasswordResponse = null;
    
            /**
             * ServerResponse clientPeersStatsResponse.
             * @member {protocol.IClientPeersStatsResponse|null|undefined} clientPeersStatsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.clientPeersStatsResponse = null;
    
            /**
             * ServerResponse clientPeerTransactionsStatsResponse.
             * @member {protocol.IClientPeerTransactionsStatsResponse|null|undefined} clientPeerTransactionsStatsResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.clientPeerTransactionsStatsResponse = null;
    
            /**
             * ServerResponse clientAddressesTagsListResponse.
             * @member {protocol.IClientAddressesTagsListResponse|null|undefined} clientAddressesTagsListResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.clientAddressesTagsListResponse = null;
    
            /**
             * ServerResponse clientSystemAddressesTagGroupsListResponse.
             * @member {protocol.IClientSystemAddressesTagGroupsListResponse|null|undefined} clientSystemAddressesTagGroupsListResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.clientSystemAddressesTagGroupsListResponse = null;
    
            /**
             * ServerResponse createClientSystemAddressResponse.
             * @member {protocol.ICreateClientSystemAddressResponse|null|undefined} createClientSystemAddressResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.createClientSystemAddressResponse = null;
    
            /**
             * ServerResponse clientTotalBalancesResponse.
             * @member {protocol.IClientTotalBalancesResponse|null|undefined} clientTotalBalancesResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.clientTotalBalancesResponse = null;
    
            /**
             * ServerResponse clientPeerChangeTransactionAmountResponse.
             * @member {protocol.IClientPeerChangeTransactionAmountResponse|null|undefined} clientPeerChangeTransactionAmountResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.clientPeerChangeTransactionAmountResponse = null;
    
            /**
             * ServerResponse appVersionResponse.
             * @member {protocol.IAppVersionResponse|null|undefined} appVersionResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.appVersionResponse = null;
    
            /**
             * ServerResponse hasChangesDashboardResponse.
             * @member {protocol.IHasChangesDashboardResponse|null|undefined} hasChangesDashboardResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.hasChangesDashboardResponse = null;
    
            /**
             * ServerResponse latestChangesDashboardResponse.
             * @member {protocol.ILatestChangesDashboardResponse|null|undefined} latestChangesDashboardResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.latestChangesDashboardResponse = null;
    
            /**
             * ServerResponse checkTokenPayoutSystemBalanceResponse.
             * @member {protocol.ICheckTokenPayoutSystemBalanceResponse|null|undefined} checkTokenPayoutSystemBalanceResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.checkTokenPayoutSystemBalanceResponse = null;
    
            /**
             * ServerResponse cancelPendingPayoutRequestResponse.
             * @member {protocol.ICancelPendingPayoutRequestResponse|null|undefined} cancelPendingPayoutRequestResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.cancelPendingPayoutRequestResponse = null;
    
            /**
             * ServerResponse amlClientUnboundChecksListResponse.
             * @member {protocol.IAMLClientUnboundChecksListResponse|null|undefined} amlClientUnboundChecksListResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.amlClientUnboundChecksListResponse = null;
    
            /**
             * ServerResponse clientAddressesListResponse.
             * @member {protocol.IClientAddressesListResponse|null|undefined} clientAddressesListResponse
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.clientAddressesListResponse = null;
    
            /**
             * ServerResponse error.
             * @member {protocol.ServerError} error
             * @memberof protocol.ServerResponse
             * @instance
             */
            ServerResponse.prototype.error = 0;
    
            /**
             * Creates a new ServerResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ServerResponse
             * @static
             * @param {protocol.IServerResponse=} [properties] Properties to set
             * @returns {protocol.ServerResponse} ServerResponse instance
             */
            ServerResponse.create = function create(properties) {
                return new ServerResponse(properties);
            };
    
            /**
             * Encodes the specified ServerResponse message. Does not implicitly {@link protocol.ServerResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ServerResponse
             * @static
             * @param {protocol.IServerResponse} message ServerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.createNewAddressResponse != null && Object.hasOwnProperty.call(message, "createNewAddressResponse"))
                    $root.protocol.CreateNewAddressResponse.encode(message.createNewAddressResponse, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.balancesResponse != null && Object.hasOwnProperty.call(message, "balancesResponse"))
                    $root.protocol.BalancesResponse.encode(message.balancesResponse, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.transactionsResponse != null && Object.hasOwnProperty.call(message, "transactionsResponse"))
                    $root.protocol.TransactionsResponse.encode(message.transactionsResponse, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.enqueuePayoutResponse != null && Object.hasOwnProperty.call(message, "enqueuePayoutResponse"))
                    $root.protocol.EnqueuePayoutResponse.encode(message.enqueuePayoutResponse, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.payoutRequestDetailsResponse != null && Object.hasOwnProperty.call(message, "payoutRequestDetailsResponse"))
                    $root.protocol.PayoutRequestDetailsResponse.encode(message.payoutRequestDetailsResponse, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.mainAccountsResponse != null && Object.hasOwnProperty.call(message, "mainAccountsResponse"))
                    $root.protocol.MainAccountsResponse.encode(message.mainAccountsResponse, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.modifyTagsResponse != null && Object.hasOwnProperty.call(message, "modifyTagsResponse"))
                    $root.protocol.ModifyTagsResponse.encode(message.modifyTagsResponse, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.listTagsResponse != null && Object.hasOwnProperty.call(message, "listTagsResponse"))
                    $root.protocol.ListTagsResponse.encode(message.listTagsResponse, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.confirmedClientTransactionsResponse != null && Object.hasOwnProperty.call(message, "confirmedClientTransactionsResponse"))
                    $root.protocol.ConfirmedClientTransactionsResponse.encode(message.confirmedClientTransactionsResponse, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.payoutDetailsResponse != null && Object.hasOwnProperty.call(message, "payoutDetailsResponse"))
                    $root.protocol.PayoutDetailsResponse.encode(message.payoutDetailsResponse, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.convertCryptoToFiatResponse != null && Object.hasOwnProperty.call(message, "convertCryptoToFiatResponse"))
                    $root.protocol.ConvertCryptoToFiatResponse.encode(message.convertCryptoToFiatResponse, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.transactionFeeResponse != null && Object.hasOwnProperty.call(message, "transactionFeeResponse"))
                    $root.protocol.TransactionFeeResponse.encode(message.transactionFeeResponse, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.clientRequestLogsResponse != null && Object.hasOwnProperty.call(message, "clientRequestLogsResponse"))
                    $root.protocol.ClientRequestLogsResponse.encode(message.clientRequestLogsResponse, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.listPayoutRequestsResponse != null && Object.hasOwnProperty.call(message, "listPayoutRequestsResponse"))
                    $root.protocol.ListPayoutRequestsResponse.encode(message.listPayoutRequestsResponse, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.amlSingleCheckResponse != null && Object.hasOwnProperty.call(message, "amlSingleCheckResponse"))
                    $root.protocol.AMLSingleCheckResponse.encode(message.amlSingleCheckResponse, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.amlCheckResultResponse != null && Object.hasOwnProperty.call(message, "amlCheckResultResponse"))
                    $root.protocol.AMLCheckResultResponse.encode(message.amlCheckResultResponse, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.amlClientBatchCheckStartResponse != null && Object.hasOwnProperty.call(message, "amlClientBatchCheckStartResponse"))
                    $root.protocol.AMLClientBatchCheckStartResponse.encode(message.amlClientBatchCheckStartResponse, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.amlClientBatchCheckStopResponse != null && Object.hasOwnProperty.call(message, "amlClientBatchCheckStopResponse"))
                    $root.protocol.AMLClientBatchCheckStopResponse.encode(message.amlClientBatchCheckStopResponse, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.amlBatchCheckResultsResponse != null && Object.hasOwnProperty.call(message, "amlBatchCheckResultsResponse"))
                    $root.protocol.AMLBatchCheckResultsResponse.encode(message.amlBatchCheckResultsResponse, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                if (message.amlServiceStatusResponse != null && Object.hasOwnProperty.call(message, "amlServiceStatusResponse"))
                    $root.protocol.AMLServiceStatusResponse.encode(message.amlServiceStatusResponse, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.transactionsPagedResponse != null && Object.hasOwnProperty.call(message, "transactionsPagedResponse"))
                    $root.protocol.TransactionsPagedResponse.encode(message.transactionsPagedResponse, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.transactionsStatsResponse != null && Object.hasOwnProperty.call(message, "transactionsStatsResponse"))
                    $root.protocol.TransactionsStatsResponse.encode(message.transactionsStatsResponse, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                if (message.amlClientOverallStatsResponse != null && Object.hasOwnProperty.call(message, "amlClientOverallStatsResponse"))
                    $root.protocol.AMLClientOverallStatsResponse.encode(message.amlClientOverallStatsResponse, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                if (message.amlClientAddressesListResponse != null && Object.hasOwnProperty.call(message, "amlClientAddressesListResponse"))
                    $root.protocol.AMLClientAddressesListResponse.encode(message.amlClientAddressesListResponse, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                if (message.amlClientBatchStatsResponse != null && Object.hasOwnProperty.call(message, "amlClientBatchStatsResponse"))
                    $root.protocol.AMLClientBatchStatsResponse.encode(message.amlClientBatchStatsResponse, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                if (message.amlClientBatchAddressesListResponse != null && Object.hasOwnProperty.call(message, "amlClientBatchAddressesListResponse"))
                    $root.protocol.AMLClientBatchAddressesListResponse.encode(message.amlClientBatchAddressesListResponse, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                if (message.amlClientBatchChecksListResponse != null && Object.hasOwnProperty.call(message, "amlClientBatchChecksListResponse"))
                    $root.protocol.AMLClientBatchChecksListResponse.encode(message.amlClientBatchChecksListResponse, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                if (message.amlClientBatchAddressResponse != null && Object.hasOwnProperty.call(message, "amlClientBatchAddressResponse"))
                    $root.protocol.AMLClientBatchAddressResponse.encode(message.amlClientBatchAddressResponse, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                if (message.feeRatesResponse != null && Object.hasOwnProperty.call(message, "feeRatesResponse"))
                    $root.protocol.FeeRatesResponse.encode(message.feeRatesResponse, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
                if (message.createClientPeerResponse != null && Object.hasOwnProperty.call(message, "createClientPeerResponse"))
                    $root.protocol.CreateClientPeerResponse.encode(message.createClientPeerResponse, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
                if (message.generateNewClientPeerAddressResponse != null && Object.hasOwnProperty.call(message, "generateNewClientPeerAddressResponse"))
                    $root.protocol.GenerateNewClientPeerAddressResponse.encode(message.generateNewClientPeerAddressResponse, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
                if (message.regenerateClientPeerAddressResponse != null && Object.hasOwnProperty.call(message, "regenerateClientPeerAddressResponse"))
                    $root.protocol.RegenerateClientPeerAddressResponse.encode(message.regenerateClientPeerAddressResponse, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
                if (message.clientPeersShortListResponse != null && Object.hasOwnProperty.call(message, "clientPeersShortListResponse"))
                    $root.protocol.ClientPeersShortListResponse.encode(message.clientPeersShortListResponse, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                if (message.clientPeersListResponse != null && Object.hasOwnProperty.call(message, "clientPeersListResponse"))
                    $root.protocol.ClientPeersListResponse.encode(message.clientPeersListResponse, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
                if (message.clientPeerDetailsResponse != null && Object.hasOwnProperty.call(message, "clientPeerDetailsResponse"))
                    $root.protocol.ClientPeerDetailsResponse.encode(message.clientPeerDetailsResponse, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
                if (message.clientPeerAddressesListResponse != null && Object.hasOwnProperty.call(message, "clientPeerAddressesListResponse"))
                    $root.protocol.ClientPeerAddressesListResponse.encode(message.clientPeerAddressesListResponse, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
                if (message.clientPeerTransactionsResponse != null && Object.hasOwnProperty.call(message, "clientPeerTransactionsResponse"))
                    $root.protocol.ClientPeerTransactionsResponse.encode(message.clientPeerTransactionsResponse, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
                if (message.deleteClientPeerAddressResponse != null && Object.hasOwnProperty.call(message, "deleteClientPeerAddressResponse"))
                    $root.protocol.DeleteClientPeerAddressResponse.encode(message.deleteClientPeerAddressResponse, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
                if (message.authUserResponse != null && Object.hasOwnProperty.call(message, "authUserResponse"))
                    $root.protocol.AuthUserResponse.encode(message.authUserResponse, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
                if (message.checkSessionResponse != null && Object.hasOwnProperty.call(message, "checkSessionResponse"))
                    $root.protocol.CheckSessionResponse.encode(message.checkSessionResponse, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
                if (message.adminCreateClientUserResponse != null && Object.hasOwnProperty.call(message, "adminCreateClientUserResponse"))
                    $root.protocol.AdminCreateClientUserResponse.encode(message.adminCreateClientUserResponse, writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
                if (message.adminClientUsersListResponse != null && Object.hasOwnProperty.call(message, "adminClientUsersListResponse"))
                    $root.protocol.AdminClientUsersListResponse.encode(message.adminClientUsersListResponse, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
                if (message.adminUpdateClientUserAccessGroupsResponse != null && Object.hasOwnProperty.call(message, "adminUpdateClientUserAccessGroupsResponse"))
                    $root.protocol.AdminUpdateClientUserAccessGroupsResponse.encode(message.adminUpdateClientUserAccessGroupsResponse, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
                if (message.adminUpdateClientUserPasswordResponse != null && Object.hasOwnProperty.call(message, "adminUpdateClientUserPasswordResponse"))
                    $root.protocol.AdminUpdateClientUserPasswordResponse.encode(message.adminUpdateClientUserPasswordResponse, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
                if (message.clientPeersStatsResponse != null && Object.hasOwnProperty.call(message, "clientPeersStatsResponse"))
                    $root.protocol.ClientPeersStatsResponse.encode(message.clientPeersStatsResponse, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
                if (message.clientPeerTransactionsStatsResponse != null && Object.hasOwnProperty.call(message, "clientPeerTransactionsStatsResponse"))
                    $root.protocol.ClientPeerTransactionsStatsResponse.encode(message.clientPeerTransactionsStatsResponse, writer.uint32(/* id 47, wireType 2 =*/378).fork()).ldelim();
                if (message.clientAddressesTagsListResponse != null && Object.hasOwnProperty.call(message, "clientAddressesTagsListResponse"))
                    $root.protocol.ClientAddressesTagsListResponse.encode(message.clientAddressesTagsListResponse, writer.uint32(/* id 48, wireType 2 =*/386).fork()).ldelim();
                if (message.clientSystemAddressesTagGroupsListResponse != null && Object.hasOwnProperty.call(message, "clientSystemAddressesTagGroupsListResponse"))
                    $root.protocol.ClientSystemAddressesTagGroupsListResponse.encode(message.clientSystemAddressesTagGroupsListResponse, writer.uint32(/* id 49, wireType 2 =*/394).fork()).ldelim();
                if (message.createClientSystemAddressResponse != null && Object.hasOwnProperty.call(message, "createClientSystemAddressResponse"))
                    $root.protocol.CreateClientSystemAddressResponse.encode(message.createClientSystemAddressResponse, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
                if (message.clientTotalBalancesResponse != null && Object.hasOwnProperty.call(message, "clientTotalBalancesResponse"))
                    $root.protocol.ClientTotalBalancesResponse.encode(message.clientTotalBalancesResponse, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
                if (message.clientPeerChangeTransactionAmountResponse != null && Object.hasOwnProperty.call(message, "clientPeerChangeTransactionAmountResponse"))
                    $root.protocol.ClientPeerChangeTransactionAmountResponse.encode(message.clientPeerChangeTransactionAmountResponse, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
                if (message.appVersionResponse != null && Object.hasOwnProperty.call(message, "appVersionResponse"))
                    $root.protocol.AppVersionResponse.encode(message.appVersionResponse, writer.uint32(/* id 53, wireType 2 =*/426).fork()).ldelim();
                if (message.hasChangesDashboardResponse != null && Object.hasOwnProperty.call(message, "hasChangesDashboardResponse"))
                    $root.protocol.HasChangesDashboardResponse.encode(message.hasChangesDashboardResponse, writer.uint32(/* id 54, wireType 2 =*/434).fork()).ldelim();
                if (message.latestChangesDashboardResponse != null && Object.hasOwnProperty.call(message, "latestChangesDashboardResponse"))
                    $root.protocol.LatestChangesDashboardResponse.encode(message.latestChangesDashboardResponse, writer.uint32(/* id 55, wireType 2 =*/442).fork()).ldelim();
                if (message.checkTokenPayoutSystemBalanceResponse != null && Object.hasOwnProperty.call(message, "checkTokenPayoutSystemBalanceResponse"))
                    $root.protocol.CheckTokenPayoutSystemBalanceResponse.encode(message.checkTokenPayoutSystemBalanceResponse, writer.uint32(/* id 56, wireType 2 =*/450).fork()).ldelim();
                if (message.cancelPendingPayoutRequestResponse != null && Object.hasOwnProperty.call(message, "cancelPendingPayoutRequestResponse"))
                    $root.protocol.CancelPendingPayoutRequestResponse.encode(message.cancelPendingPayoutRequestResponse, writer.uint32(/* id 57, wireType 2 =*/458).fork()).ldelim();
                if (message.amlClientUnboundChecksListResponse != null && Object.hasOwnProperty.call(message, "amlClientUnboundChecksListResponse"))
                    $root.protocol.AMLClientUnboundChecksListResponse.encode(message.amlClientUnboundChecksListResponse, writer.uint32(/* id 58, wireType 2 =*/466).fork()).ldelim();
                if (message.clientAddressesListResponse != null && Object.hasOwnProperty.call(message, "clientAddressesListResponse"))
                    $root.protocol.ClientAddressesListResponse.encode(message.clientAddressesListResponse, writer.uint32(/* id 59, wireType 2 =*/474).fork()).ldelim();
                if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                    writer.uint32(/* id 100, wireType 0 =*/800).int32(message.error);
                return writer;
            };
    
            /**
             * Encodes the specified ServerResponse message, length delimited. Does not implicitly {@link protocol.ServerResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ServerResponse
             * @static
             * @param {protocol.IServerResponse} message ServerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ServerResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ServerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ServerResponse} ServerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ServerResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.createNewAddressResponse = $root.protocol.CreateNewAddressResponse.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.balancesResponse = $root.protocol.BalancesResponse.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.transactionsResponse = $root.protocol.TransactionsResponse.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.enqueuePayoutResponse = $root.protocol.EnqueuePayoutResponse.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.payoutRequestDetailsResponse = $root.protocol.PayoutRequestDetailsResponse.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.mainAccountsResponse = $root.protocol.MainAccountsResponse.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.modifyTagsResponse = $root.protocol.ModifyTagsResponse.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.listTagsResponse = $root.protocol.ListTagsResponse.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.confirmedClientTransactionsResponse = $root.protocol.ConfirmedClientTransactionsResponse.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.payoutDetailsResponse = $root.protocol.PayoutDetailsResponse.decode(reader, reader.uint32());
                        break;
                    case 11:
                        message.convertCryptoToFiatResponse = $root.protocol.ConvertCryptoToFiatResponse.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.transactionFeeResponse = $root.protocol.TransactionFeeResponse.decode(reader, reader.uint32());
                        break;
                    case 13:
                        message.clientRequestLogsResponse = $root.protocol.ClientRequestLogsResponse.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.listPayoutRequestsResponse = $root.protocol.ListPayoutRequestsResponse.decode(reader, reader.uint32());
                        break;
                    case 15:
                        message.amlSingleCheckResponse = $root.protocol.AMLSingleCheckResponse.decode(reader, reader.uint32());
                        break;
                    case 16:
                        message.amlCheckResultResponse = $root.protocol.AMLCheckResultResponse.decode(reader, reader.uint32());
                        break;
                    case 17:
                        message.amlClientBatchCheckStartResponse = $root.protocol.AMLClientBatchCheckStartResponse.decode(reader, reader.uint32());
                        break;
                    case 18:
                        message.amlClientBatchCheckStopResponse = $root.protocol.AMLClientBatchCheckStopResponse.decode(reader, reader.uint32());
                        break;
                    case 19:
                        message.amlBatchCheckResultsResponse = $root.protocol.AMLBatchCheckResultsResponse.decode(reader, reader.uint32());
                        break;
                    case 20:
                        message.amlServiceStatusResponse = $root.protocol.AMLServiceStatusResponse.decode(reader, reader.uint32());
                        break;
                    case 21:
                        message.transactionsPagedResponse = $root.protocol.TransactionsPagedResponse.decode(reader, reader.uint32());
                        break;
                    case 22:
                        message.transactionsStatsResponse = $root.protocol.TransactionsStatsResponse.decode(reader, reader.uint32());
                        break;
                    case 23:
                        message.amlClientOverallStatsResponse = $root.protocol.AMLClientOverallStatsResponse.decode(reader, reader.uint32());
                        break;
                    case 24:
                        message.amlClientAddressesListResponse = $root.protocol.AMLClientAddressesListResponse.decode(reader, reader.uint32());
                        break;
                    case 25:
                        message.amlClientBatchStatsResponse = $root.protocol.AMLClientBatchStatsResponse.decode(reader, reader.uint32());
                        break;
                    case 26:
                        message.amlClientBatchAddressesListResponse = $root.protocol.AMLClientBatchAddressesListResponse.decode(reader, reader.uint32());
                        break;
                    case 27:
                        message.amlClientBatchChecksListResponse = $root.protocol.AMLClientBatchChecksListResponse.decode(reader, reader.uint32());
                        break;
                    case 28:
                        message.amlClientBatchAddressResponse = $root.protocol.AMLClientBatchAddressResponse.decode(reader, reader.uint32());
                        break;
                    case 29:
                        message.feeRatesResponse = $root.protocol.FeeRatesResponse.decode(reader, reader.uint32());
                        break;
                    case 30:
                        message.createClientPeerResponse = $root.protocol.CreateClientPeerResponse.decode(reader, reader.uint32());
                        break;
                    case 32:
                        message.generateNewClientPeerAddressResponse = $root.protocol.GenerateNewClientPeerAddressResponse.decode(reader, reader.uint32());
                        break;
                    case 33:
                        message.regenerateClientPeerAddressResponse = $root.protocol.RegenerateClientPeerAddressResponse.decode(reader, reader.uint32());
                        break;
                    case 34:
                        message.clientPeersShortListResponse = $root.protocol.ClientPeersShortListResponse.decode(reader, reader.uint32());
                        break;
                    case 35:
                        message.clientPeersListResponse = $root.protocol.ClientPeersListResponse.decode(reader, reader.uint32());
                        break;
                    case 36:
                        message.clientPeerDetailsResponse = $root.protocol.ClientPeerDetailsResponse.decode(reader, reader.uint32());
                        break;
                    case 37:
                        message.clientPeerAddressesListResponse = $root.protocol.ClientPeerAddressesListResponse.decode(reader, reader.uint32());
                        break;
                    case 38:
                        message.clientPeerTransactionsResponse = $root.protocol.ClientPeerTransactionsResponse.decode(reader, reader.uint32());
                        break;
                    case 39:
                        message.deleteClientPeerAddressResponse = $root.protocol.DeleteClientPeerAddressResponse.decode(reader, reader.uint32());
                        break;
                    case 40:
                        message.authUserResponse = $root.protocol.AuthUserResponse.decode(reader, reader.uint32());
                        break;
                    case 41:
                        message.checkSessionResponse = $root.protocol.CheckSessionResponse.decode(reader, reader.uint32());
                        break;
                    case 42:
                        message.adminCreateClientUserResponse = $root.protocol.AdminCreateClientUserResponse.decode(reader, reader.uint32());
                        break;
                    case 43:
                        message.adminClientUsersListResponse = $root.protocol.AdminClientUsersListResponse.decode(reader, reader.uint32());
                        break;
                    case 44:
                        message.adminUpdateClientUserAccessGroupsResponse = $root.protocol.AdminUpdateClientUserAccessGroupsResponse.decode(reader, reader.uint32());
                        break;
                    case 45:
                        message.adminUpdateClientUserPasswordResponse = $root.protocol.AdminUpdateClientUserPasswordResponse.decode(reader, reader.uint32());
                        break;
                    case 46:
                        message.clientPeersStatsResponse = $root.protocol.ClientPeersStatsResponse.decode(reader, reader.uint32());
                        break;
                    case 47:
                        message.clientPeerTransactionsStatsResponse = $root.protocol.ClientPeerTransactionsStatsResponse.decode(reader, reader.uint32());
                        break;
                    case 48:
                        message.clientAddressesTagsListResponse = $root.protocol.ClientAddressesTagsListResponse.decode(reader, reader.uint32());
                        break;
                    case 49:
                        message.clientSystemAddressesTagGroupsListResponse = $root.protocol.ClientSystemAddressesTagGroupsListResponse.decode(reader, reader.uint32());
                        break;
                    case 50:
                        message.createClientSystemAddressResponse = $root.protocol.CreateClientSystemAddressResponse.decode(reader, reader.uint32());
                        break;
                    case 51:
                        message.clientTotalBalancesResponse = $root.protocol.ClientTotalBalancesResponse.decode(reader, reader.uint32());
                        break;
                    case 52:
                        message.clientPeerChangeTransactionAmountResponse = $root.protocol.ClientPeerChangeTransactionAmountResponse.decode(reader, reader.uint32());
                        break;
                    case 53:
                        message.appVersionResponse = $root.protocol.AppVersionResponse.decode(reader, reader.uint32());
                        break;
                    case 54:
                        message.hasChangesDashboardResponse = $root.protocol.HasChangesDashboardResponse.decode(reader, reader.uint32());
                        break;
                    case 55:
                        message.latestChangesDashboardResponse = $root.protocol.LatestChangesDashboardResponse.decode(reader, reader.uint32());
                        break;
                    case 56:
                        message.checkTokenPayoutSystemBalanceResponse = $root.protocol.CheckTokenPayoutSystemBalanceResponse.decode(reader, reader.uint32());
                        break;
                    case 57:
                        message.cancelPendingPayoutRequestResponse = $root.protocol.CancelPendingPayoutRequestResponse.decode(reader, reader.uint32());
                        break;
                    case 58:
                        message.amlClientUnboundChecksListResponse = $root.protocol.AMLClientUnboundChecksListResponse.decode(reader, reader.uint32());
                        break;
                    case 59:
                        message.clientAddressesListResponse = $root.protocol.ClientAddressesListResponse.decode(reader, reader.uint32());
                        break;
                    case 100:
                        message.error = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ServerResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ServerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ServerResponse} ServerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ServerResponse message.
             * @function verify
             * @memberof protocol.ServerResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServerResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.createNewAddressResponse != null && message.hasOwnProperty("createNewAddressResponse")) {
                    var error = $root.protocol.CreateNewAddressResponse.verify(message.createNewAddressResponse);
                    if (error)
                        return "createNewAddressResponse." + error;
                }
                if (message.balancesResponse != null && message.hasOwnProperty("balancesResponse")) {
                    var error = $root.protocol.BalancesResponse.verify(message.balancesResponse);
                    if (error)
                        return "balancesResponse." + error;
                }
                if (message.transactionsResponse != null && message.hasOwnProperty("transactionsResponse")) {
                    var error = $root.protocol.TransactionsResponse.verify(message.transactionsResponse);
                    if (error)
                        return "transactionsResponse." + error;
                }
                if (message.enqueuePayoutResponse != null && message.hasOwnProperty("enqueuePayoutResponse")) {
                    var error = $root.protocol.EnqueuePayoutResponse.verify(message.enqueuePayoutResponse);
                    if (error)
                        return "enqueuePayoutResponse." + error;
                }
                if (message.payoutRequestDetailsResponse != null && message.hasOwnProperty("payoutRequestDetailsResponse")) {
                    var error = $root.protocol.PayoutRequestDetailsResponse.verify(message.payoutRequestDetailsResponse);
                    if (error)
                        return "payoutRequestDetailsResponse." + error;
                }
                if (message.mainAccountsResponse != null && message.hasOwnProperty("mainAccountsResponse")) {
                    var error = $root.protocol.MainAccountsResponse.verify(message.mainAccountsResponse);
                    if (error)
                        return "mainAccountsResponse." + error;
                }
                if (message.modifyTagsResponse != null && message.hasOwnProperty("modifyTagsResponse")) {
                    var error = $root.protocol.ModifyTagsResponse.verify(message.modifyTagsResponse);
                    if (error)
                        return "modifyTagsResponse." + error;
                }
                if (message.listTagsResponse != null && message.hasOwnProperty("listTagsResponse")) {
                    var error = $root.protocol.ListTagsResponse.verify(message.listTagsResponse);
                    if (error)
                        return "listTagsResponse." + error;
                }
                if (message.confirmedClientTransactionsResponse != null && message.hasOwnProperty("confirmedClientTransactionsResponse")) {
                    var error = $root.protocol.ConfirmedClientTransactionsResponse.verify(message.confirmedClientTransactionsResponse);
                    if (error)
                        return "confirmedClientTransactionsResponse." + error;
                }
                if (message.payoutDetailsResponse != null && message.hasOwnProperty("payoutDetailsResponse")) {
                    var error = $root.protocol.PayoutDetailsResponse.verify(message.payoutDetailsResponse);
                    if (error)
                        return "payoutDetailsResponse." + error;
                }
                if (message.convertCryptoToFiatResponse != null && message.hasOwnProperty("convertCryptoToFiatResponse")) {
                    var error = $root.protocol.ConvertCryptoToFiatResponse.verify(message.convertCryptoToFiatResponse);
                    if (error)
                        return "convertCryptoToFiatResponse." + error;
                }
                if (message.transactionFeeResponse != null && message.hasOwnProperty("transactionFeeResponse")) {
                    var error = $root.protocol.TransactionFeeResponse.verify(message.transactionFeeResponse);
                    if (error)
                        return "transactionFeeResponse." + error;
                }
                if (message.clientRequestLogsResponse != null && message.hasOwnProperty("clientRequestLogsResponse")) {
                    var error = $root.protocol.ClientRequestLogsResponse.verify(message.clientRequestLogsResponse);
                    if (error)
                        return "clientRequestLogsResponse." + error;
                }
                if (message.listPayoutRequestsResponse != null && message.hasOwnProperty("listPayoutRequestsResponse")) {
                    var error = $root.protocol.ListPayoutRequestsResponse.verify(message.listPayoutRequestsResponse);
                    if (error)
                        return "listPayoutRequestsResponse." + error;
                }
                if (message.amlSingleCheckResponse != null && message.hasOwnProperty("amlSingleCheckResponse")) {
                    var error = $root.protocol.AMLSingleCheckResponse.verify(message.amlSingleCheckResponse);
                    if (error)
                        return "amlSingleCheckResponse." + error;
                }
                if (message.amlCheckResultResponse != null && message.hasOwnProperty("amlCheckResultResponse")) {
                    var error = $root.protocol.AMLCheckResultResponse.verify(message.amlCheckResultResponse);
                    if (error)
                        return "amlCheckResultResponse." + error;
                }
                if (message.amlClientBatchCheckStartResponse != null && message.hasOwnProperty("amlClientBatchCheckStartResponse")) {
                    var error = $root.protocol.AMLClientBatchCheckStartResponse.verify(message.amlClientBatchCheckStartResponse);
                    if (error)
                        return "amlClientBatchCheckStartResponse." + error;
                }
                if (message.amlClientBatchCheckStopResponse != null && message.hasOwnProperty("amlClientBatchCheckStopResponse")) {
                    var error = $root.protocol.AMLClientBatchCheckStopResponse.verify(message.amlClientBatchCheckStopResponse);
                    if (error)
                        return "amlClientBatchCheckStopResponse." + error;
                }
                if (message.amlBatchCheckResultsResponse != null && message.hasOwnProperty("amlBatchCheckResultsResponse")) {
                    var error = $root.protocol.AMLBatchCheckResultsResponse.verify(message.amlBatchCheckResultsResponse);
                    if (error)
                        return "amlBatchCheckResultsResponse." + error;
                }
                if (message.amlServiceStatusResponse != null && message.hasOwnProperty("amlServiceStatusResponse")) {
                    var error = $root.protocol.AMLServiceStatusResponse.verify(message.amlServiceStatusResponse);
                    if (error)
                        return "amlServiceStatusResponse." + error;
                }
                if (message.transactionsPagedResponse != null && message.hasOwnProperty("transactionsPagedResponse")) {
                    var error = $root.protocol.TransactionsPagedResponse.verify(message.transactionsPagedResponse);
                    if (error)
                        return "transactionsPagedResponse." + error;
                }
                if (message.transactionsStatsResponse != null && message.hasOwnProperty("transactionsStatsResponse")) {
                    var error = $root.protocol.TransactionsStatsResponse.verify(message.transactionsStatsResponse);
                    if (error)
                        return "transactionsStatsResponse." + error;
                }
                if (message.amlClientOverallStatsResponse != null && message.hasOwnProperty("amlClientOverallStatsResponse")) {
                    var error = $root.protocol.AMLClientOverallStatsResponse.verify(message.amlClientOverallStatsResponse);
                    if (error)
                        return "amlClientOverallStatsResponse." + error;
                }
                if (message.amlClientAddressesListResponse != null && message.hasOwnProperty("amlClientAddressesListResponse")) {
                    var error = $root.protocol.AMLClientAddressesListResponse.verify(message.amlClientAddressesListResponse);
                    if (error)
                        return "amlClientAddressesListResponse." + error;
                }
                if (message.amlClientBatchStatsResponse != null && message.hasOwnProperty("amlClientBatchStatsResponse")) {
                    var error = $root.protocol.AMLClientBatchStatsResponse.verify(message.amlClientBatchStatsResponse);
                    if (error)
                        return "amlClientBatchStatsResponse." + error;
                }
                if (message.amlClientBatchAddressesListResponse != null && message.hasOwnProperty("amlClientBatchAddressesListResponse")) {
                    var error = $root.protocol.AMLClientBatchAddressesListResponse.verify(message.amlClientBatchAddressesListResponse);
                    if (error)
                        return "amlClientBatchAddressesListResponse." + error;
                }
                if (message.amlClientBatchChecksListResponse != null && message.hasOwnProperty("amlClientBatchChecksListResponse")) {
                    var error = $root.protocol.AMLClientBatchChecksListResponse.verify(message.amlClientBatchChecksListResponse);
                    if (error)
                        return "amlClientBatchChecksListResponse." + error;
                }
                if (message.amlClientBatchAddressResponse != null && message.hasOwnProperty("amlClientBatchAddressResponse")) {
                    var error = $root.protocol.AMLClientBatchAddressResponse.verify(message.amlClientBatchAddressResponse);
                    if (error)
                        return "amlClientBatchAddressResponse." + error;
                }
                if (message.feeRatesResponse != null && message.hasOwnProperty("feeRatesResponse")) {
                    var error = $root.protocol.FeeRatesResponse.verify(message.feeRatesResponse);
                    if (error)
                        return "feeRatesResponse." + error;
                }
                if (message.createClientPeerResponse != null && message.hasOwnProperty("createClientPeerResponse")) {
                    var error = $root.protocol.CreateClientPeerResponse.verify(message.createClientPeerResponse);
                    if (error)
                        return "createClientPeerResponse." + error;
                }
                if (message.generateNewClientPeerAddressResponse != null && message.hasOwnProperty("generateNewClientPeerAddressResponse")) {
                    var error = $root.protocol.GenerateNewClientPeerAddressResponse.verify(message.generateNewClientPeerAddressResponse);
                    if (error)
                        return "generateNewClientPeerAddressResponse." + error;
                }
                if (message.regenerateClientPeerAddressResponse != null && message.hasOwnProperty("regenerateClientPeerAddressResponse")) {
                    var error = $root.protocol.RegenerateClientPeerAddressResponse.verify(message.regenerateClientPeerAddressResponse);
                    if (error)
                        return "regenerateClientPeerAddressResponse." + error;
                }
                if (message.clientPeersShortListResponse != null && message.hasOwnProperty("clientPeersShortListResponse")) {
                    var error = $root.protocol.ClientPeersShortListResponse.verify(message.clientPeersShortListResponse);
                    if (error)
                        return "clientPeersShortListResponse." + error;
                }
                if (message.clientPeersListResponse != null && message.hasOwnProperty("clientPeersListResponse")) {
                    var error = $root.protocol.ClientPeersListResponse.verify(message.clientPeersListResponse);
                    if (error)
                        return "clientPeersListResponse." + error;
                }
                if (message.clientPeerDetailsResponse != null && message.hasOwnProperty("clientPeerDetailsResponse")) {
                    var error = $root.protocol.ClientPeerDetailsResponse.verify(message.clientPeerDetailsResponse);
                    if (error)
                        return "clientPeerDetailsResponse." + error;
                }
                if (message.clientPeerAddressesListResponse != null && message.hasOwnProperty("clientPeerAddressesListResponse")) {
                    var error = $root.protocol.ClientPeerAddressesListResponse.verify(message.clientPeerAddressesListResponse);
                    if (error)
                        return "clientPeerAddressesListResponse." + error;
                }
                if (message.clientPeerTransactionsResponse != null && message.hasOwnProperty("clientPeerTransactionsResponse")) {
                    var error = $root.protocol.ClientPeerTransactionsResponse.verify(message.clientPeerTransactionsResponse);
                    if (error)
                        return "clientPeerTransactionsResponse." + error;
                }
                if (message.deleteClientPeerAddressResponse != null && message.hasOwnProperty("deleteClientPeerAddressResponse")) {
                    var error = $root.protocol.DeleteClientPeerAddressResponse.verify(message.deleteClientPeerAddressResponse);
                    if (error)
                        return "deleteClientPeerAddressResponse." + error;
                }
                if (message.authUserResponse != null && message.hasOwnProperty("authUserResponse")) {
                    var error = $root.protocol.AuthUserResponse.verify(message.authUserResponse);
                    if (error)
                        return "authUserResponse." + error;
                }
                if (message.checkSessionResponse != null && message.hasOwnProperty("checkSessionResponse")) {
                    var error = $root.protocol.CheckSessionResponse.verify(message.checkSessionResponse);
                    if (error)
                        return "checkSessionResponse." + error;
                }
                if (message.adminCreateClientUserResponse != null && message.hasOwnProperty("adminCreateClientUserResponse")) {
                    var error = $root.protocol.AdminCreateClientUserResponse.verify(message.adminCreateClientUserResponse);
                    if (error)
                        return "adminCreateClientUserResponse." + error;
                }
                if (message.adminClientUsersListResponse != null && message.hasOwnProperty("adminClientUsersListResponse")) {
                    var error = $root.protocol.AdminClientUsersListResponse.verify(message.adminClientUsersListResponse);
                    if (error)
                        return "adminClientUsersListResponse." + error;
                }
                if (message.adminUpdateClientUserAccessGroupsResponse != null && message.hasOwnProperty("adminUpdateClientUserAccessGroupsResponse")) {
                    var error = $root.protocol.AdminUpdateClientUserAccessGroupsResponse.verify(message.adminUpdateClientUserAccessGroupsResponse);
                    if (error)
                        return "adminUpdateClientUserAccessGroupsResponse." + error;
                }
                if (message.adminUpdateClientUserPasswordResponse != null && message.hasOwnProperty("adminUpdateClientUserPasswordResponse")) {
                    var error = $root.protocol.AdminUpdateClientUserPasswordResponse.verify(message.adminUpdateClientUserPasswordResponse);
                    if (error)
                        return "adminUpdateClientUserPasswordResponse." + error;
                }
                if (message.clientPeersStatsResponse != null && message.hasOwnProperty("clientPeersStatsResponse")) {
                    var error = $root.protocol.ClientPeersStatsResponse.verify(message.clientPeersStatsResponse);
                    if (error)
                        return "clientPeersStatsResponse." + error;
                }
                if (message.clientPeerTransactionsStatsResponse != null && message.hasOwnProperty("clientPeerTransactionsStatsResponse")) {
                    var error = $root.protocol.ClientPeerTransactionsStatsResponse.verify(message.clientPeerTransactionsStatsResponse);
                    if (error)
                        return "clientPeerTransactionsStatsResponse." + error;
                }
                if (message.clientAddressesTagsListResponse != null && message.hasOwnProperty("clientAddressesTagsListResponse")) {
                    var error = $root.protocol.ClientAddressesTagsListResponse.verify(message.clientAddressesTagsListResponse);
                    if (error)
                        return "clientAddressesTagsListResponse." + error;
                }
                if (message.clientSystemAddressesTagGroupsListResponse != null && message.hasOwnProperty("clientSystemAddressesTagGroupsListResponse")) {
                    var error = $root.protocol.ClientSystemAddressesTagGroupsListResponse.verify(message.clientSystemAddressesTagGroupsListResponse);
                    if (error)
                        return "clientSystemAddressesTagGroupsListResponse." + error;
                }
                if (message.createClientSystemAddressResponse != null && message.hasOwnProperty("createClientSystemAddressResponse")) {
                    var error = $root.protocol.CreateClientSystemAddressResponse.verify(message.createClientSystemAddressResponse);
                    if (error)
                        return "createClientSystemAddressResponse." + error;
                }
                if (message.clientTotalBalancesResponse != null && message.hasOwnProperty("clientTotalBalancesResponse")) {
                    var error = $root.protocol.ClientTotalBalancesResponse.verify(message.clientTotalBalancesResponse);
                    if (error)
                        return "clientTotalBalancesResponse." + error;
                }
                if (message.clientPeerChangeTransactionAmountResponse != null && message.hasOwnProperty("clientPeerChangeTransactionAmountResponse")) {
                    var error = $root.protocol.ClientPeerChangeTransactionAmountResponse.verify(message.clientPeerChangeTransactionAmountResponse);
                    if (error)
                        return "clientPeerChangeTransactionAmountResponse." + error;
                }
                if (message.appVersionResponse != null && message.hasOwnProperty("appVersionResponse")) {
                    var error = $root.protocol.AppVersionResponse.verify(message.appVersionResponse);
                    if (error)
                        return "appVersionResponse." + error;
                }
                if (message.hasChangesDashboardResponse != null && message.hasOwnProperty("hasChangesDashboardResponse")) {
                    var error = $root.protocol.HasChangesDashboardResponse.verify(message.hasChangesDashboardResponse);
                    if (error)
                        return "hasChangesDashboardResponse." + error;
                }
                if (message.latestChangesDashboardResponse != null && message.hasOwnProperty("latestChangesDashboardResponse")) {
                    var error = $root.protocol.LatestChangesDashboardResponse.verify(message.latestChangesDashboardResponse);
                    if (error)
                        return "latestChangesDashboardResponse." + error;
                }
                if (message.checkTokenPayoutSystemBalanceResponse != null && message.hasOwnProperty("checkTokenPayoutSystemBalanceResponse")) {
                    var error = $root.protocol.CheckTokenPayoutSystemBalanceResponse.verify(message.checkTokenPayoutSystemBalanceResponse);
                    if (error)
                        return "checkTokenPayoutSystemBalanceResponse." + error;
                }
                if (message.cancelPendingPayoutRequestResponse != null && message.hasOwnProperty("cancelPendingPayoutRequestResponse")) {
                    var error = $root.protocol.CancelPendingPayoutRequestResponse.verify(message.cancelPendingPayoutRequestResponse);
                    if (error)
                        return "cancelPendingPayoutRequestResponse." + error;
                }
                if (message.amlClientUnboundChecksListResponse != null && message.hasOwnProperty("amlClientUnboundChecksListResponse")) {
                    var error = $root.protocol.AMLClientUnboundChecksListResponse.verify(message.amlClientUnboundChecksListResponse);
                    if (error)
                        return "amlClientUnboundChecksListResponse." + error;
                }
                if (message.clientAddressesListResponse != null && message.hasOwnProperty("clientAddressesListResponse")) {
                    var error = $root.protocol.ClientAddressesListResponse.verify(message.clientAddressesListResponse);
                    if (error)
                        return "clientAddressesListResponse." + error;
                }
                if (message.error != null && message.hasOwnProperty("error"))
                    switch (message.error) {
                    default:
                        return "error: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                    case 19:
                    case 20:
                    case 21:
                    case 22:
                    case 23:
                    case 24:
                    case 25:
                    case 26:
                    case 27:
                    case 28:
                    case 29:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a ServerResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ServerResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ServerResponse} ServerResponse
             */
            ServerResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ServerResponse)
                    return object;
                var message = new $root.protocol.ServerResponse();
                if (object.createNewAddressResponse != null) {
                    if (typeof object.createNewAddressResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.createNewAddressResponse: object expected");
                    message.createNewAddressResponse = $root.protocol.CreateNewAddressResponse.fromObject(object.createNewAddressResponse);
                }
                if (object.balancesResponse != null) {
                    if (typeof object.balancesResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.balancesResponse: object expected");
                    message.balancesResponse = $root.protocol.BalancesResponse.fromObject(object.balancesResponse);
                }
                if (object.transactionsResponse != null) {
                    if (typeof object.transactionsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.transactionsResponse: object expected");
                    message.transactionsResponse = $root.protocol.TransactionsResponse.fromObject(object.transactionsResponse);
                }
                if (object.enqueuePayoutResponse != null) {
                    if (typeof object.enqueuePayoutResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.enqueuePayoutResponse: object expected");
                    message.enqueuePayoutResponse = $root.protocol.EnqueuePayoutResponse.fromObject(object.enqueuePayoutResponse);
                }
                if (object.payoutRequestDetailsResponse != null) {
                    if (typeof object.payoutRequestDetailsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.payoutRequestDetailsResponse: object expected");
                    message.payoutRequestDetailsResponse = $root.protocol.PayoutRequestDetailsResponse.fromObject(object.payoutRequestDetailsResponse);
                }
                if (object.mainAccountsResponse != null) {
                    if (typeof object.mainAccountsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.mainAccountsResponse: object expected");
                    message.mainAccountsResponse = $root.protocol.MainAccountsResponse.fromObject(object.mainAccountsResponse);
                }
                if (object.modifyTagsResponse != null) {
                    if (typeof object.modifyTagsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.modifyTagsResponse: object expected");
                    message.modifyTagsResponse = $root.protocol.ModifyTagsResponse.fromObject(object.modifyTagsResponse);
                }
                if (object.listTagsResponse != null) {
                    if (typeof object.listTagsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.listTagsResponse: object expected");
                    message.listTagsResponse = $root.protocol.ListTagsResponse.fromObject(object.listTagsResponse);
                }
                if (object.confirmedClientTransactionsResponse != null) {
                    if (typeof object.confirmedClientTransactionsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.confirmedClientTransactionsResponse: object expected");
                    message.confirmedClientTransactionsResponse = $root.protocol.ConfirmedClientTransactionsResponse.fromObject(object.confirmedClientTransactionsResponse);
                }
                if (object.payoutDetailsResponse != null) {
                    if (typeof object.payoutDetailsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.payoutDetailsResponse: object expected");
                    message.payoutDetailsResponse = $root.protocol.PayoutDetailsResponse.fromObject(object.payoutDetailsResponse);
                }
                if (object.convertCryptoToFiatResponse != null) {
                    if (typeof object.convertCryptoToFiatResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.convertCryptoToFiatResponse: object expected");
                    message.convertCryptoToFiatResponse = $root.protocol.ConvertCryptoToFiatResponse.fromObject(object.convertCryptoToFiatResponse);
                }
                if (object.transactionFeeResponse != null) {
                    if (typeof object.transactionFeeResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.transactionFeeResponse: object expected");
                    message.transactionFeeResponse = $root.protocol.TransactionFeeResponse.fromObject(object.transactionFeeResponse);
                }
                if (object.clientRequestLogsResponse != null) {
                    if (typeof object.clientRequestLogsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.clientRequestLogsResponse: object expected");
                    message.clientRequestLogsResponse = $root.protocol.ClientRequestLogsResponse.fromObject(object.clientRequestLogsResponse);
                }
                if (object.listPayoutRequestsResponse != null) {
                    if (typeof object.listPayoutRequestsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.listPayoutRequestsResponse: object expected");
                    message.listPayoutRequestsResponse = $root.protocol.ListPayoutRequestsResponse.fromObject(object.listPayoutRequestsResponse);
                }
                if (object.amlSingleCheckResponse != null) {
                    if (typeof object.amlSingleCheckResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.amlSingleCheckResponse: object expected");
                    message.amlSingleCheckResponse = $root.protocol.AMLSingleCheckResponse.fromObject(object.amlSingleCheckResponse);
                }
                if (object.amlCheckResultResponse != null) {
                    if (typeof object.amlCheckResultResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.amlCheckResultResponse: object expected");
                    message.amlCheckResultResponse = $root.protocol.AMLCheckResultResponse.fromObject(object.amlCheckResultResponse);
                }
                if (object.amlClientBatchCheckStartResponse != null) {
                    if (typeof object.amlClientBatchCheckStartResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.amlClientBatchCheckStartResponse: object expected");
                    message.amlClientBatchCheckStartResponse = $root.protocol.AMLClientBatchCheckStartResponse.fromObject(object.amlClientBatchCheckStartResponse);
                }
                if (object.amlClientBatchCheckStopResponse != null) {
                    if (typeof object.amlClientBatchCheckStopResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.amlClientBatchCheckStopResponse: object expected");
                    message.amlClientBatchCheckStopResponse = $root.protocol.AMLClientBatchCheckStopResponse.fromObject(object.amlClientBatchCheckStopResponse);
                }
                if (object.amlBatchCheckResultsResponse != null) {
                    if (typeof object.amlBatchCheckResultsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.amlBatchCheckResultsResponse: object expected");
                    message.amlBatchCheckResultsResponse = $root.protocol.AMLBatchCheckResultsResponse.fromObject(object.amlBatchCheckResultsResponse);
                }
                if (object.amlServiceStatusResponse != null) {
                    if (typeof object.amlServiceStatusResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.amlServiceStatusResponse: object expected");
                    message.amlServiceStatusResponse = $root.protocol.AMLServiceStatusResponse.fromObject(object.amlServiceStatusResponse);
                }
                if (object.transactionsPagedResponse != null) {
                    if (typeof object.transactionsPagedResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.transactionsPagedResponse: object expected");
                    message.transactionsPagedResponse = $root.protocol.TransactionsPagedResponse.fromObject(object.transactionsPagedResponse);
                }
                if (object.transactionsStatsResponse != null) {
                    if (typeof object.transactionsStatsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.transactionsStatsResponse: object expected");
                    message.transactionsStatsResponse = $root.protocol.TransactionsStatsResponse.fromObject(object.transactionsStatsResponse);
                }
                if (object.amlClientOverallStatsResponse != null) {
                    if (typeof object.amlClientOverallStatsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.amlClientOverallStatsResponse: object expected");
                    message.amlClientOverallStatsResponse = $root.protocol.AMLClientOverallStatsResponse.fromObject(object.amlClientOverallStatsResponse);
                }
                if (object.amlClientAddressesListResponse != null) {
                    if (typeof object.amlClientAddressesListResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.amlClientAddressesListResponse: object expected");
                    message.amlClientAddressesListResponse = $root.protocol.AMLClientAddressesListResponse.fromObject(object.amlClientAddressesListResponse);
                }
                if (object.amlClientBatchStatsResponse != null) {
                    if (typeof object.amlClientBatchStatsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.amlClientBatchStatsResponse: object expected");
                    message.amlClientBatchStatsResponse = $root.protocol.AMLClientBatchStatsResponse.fromObject(object.amlClientBatchStatsResponse);
                }
                if (object.amlClientBatchAddressesListResponse != null) {
                    if (typeof object.amlClientBatchAddressesListResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.amlClientBatchAddressesListResponse: object expected");
                    message.amlClientBatchAddressesListResponse = $root.protocol.AMLClientBatchAddressesListResponse.fromObject(object.amlClientBatchAddressesListResponse);
                }
                if (object.amlClientBatchChecksListResponse != null) {
                    if (typeof object.amlClientBatchChecksListResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.amlClientBatchChecksListResponse: object expected");
                    message.amlClientBatchChecksListResponse = $root.protocol.AMLClientBatchChecksListResponse.fromObject(object.amlClientBatchChecksListResponse);
                }
                if (object.amlClientBatchAddressResponse != null) {
                    if (typeof object.amlClientBatchAddressResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.amlClientBatchAddressResponse: object expected");
                    message.amlClientBatchAddressResponse = $root.protocol.AMLClientBatchAddressResponse.fromObject(object.amlClientBatchAddressResponse);
                }
                if (object.feeRatesResponse != null) {
                    if (typeof object.feeRatesResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.feeRatesResponse: object expected");
                    message.feeRatesResponse = $root.protocol.FeeRatesResponse.fromObject(object.feeRatesResponse);
                }
                if (object.createClientPeerResponse != null) {
                    if (typeof object.createClientPeerResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.createClientPeerResponse: object expected");
                    message.createClientPeerResponse = $root.protocol.CreateClientPeerResponse.fromObject(object.createClientPeerResponse);
                }
                if (object.generateNewClientPeerAddressResponse != null) {
                    if (typeof object.generateNewClientPeerAddressResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.generateNewClientPeerAddressResponse: object expected");
                    message.generateNewClientPeerAddressResponse = $root.protocol.GenerateNewClientPeerAddressResponse.fromObject(object.generateNewClientPeerAddressResponse);
                }
                if (object.regenerateClientPeerAddressResponse != null) {
                    if (typeof object.regenerateClientPeerAddressResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.regenerateClientPeerAddressResponse: object expected");
                    message.regenerateClientPeerAddressResponse = $root.protocol.RegenerateClientPeerAddressResponse.fromObject(object.regenerateClientPeerAddressResponse);
                }
                if (object.clientPeersShortListResponse != null) {
                    if (typeof object.clientPeersShortListResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.clientPeersShortListResponse: object expected");
                    message.clientPeersShortListResponse = $root.protocol.ClientPeersShortListResponse.fromObject(object.clientPeersShortListResponse);
                }
                if (object.clientPeersListResponse != null) {
                    if (typeof object.clientPeersListResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.clientPeersListResponse: object expected");
                    message.clientPeersListResponse = $root.protocol.ClientPeersListResponse.fromObject(object.clientPeersListResponse);
                }
                if (object.clientPeerDetailsResponse != null) {
                    if (typeof object.clientPeerDetailsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.clientPeerDetailsResponse: object expected");
                    message.clientPeerDetailsResponse = $root.protocol.ClientPeerDetailsResponse.fromObject(object.clientPeerDetailsResponse);
                }
                if (object.clientPeerAddressesListResponse != null) {
                    if (typeof object.clientPeerAddressesListResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.clientPeerAddressesListResponse: object expected");
                    message.clientPeerAddressesListResponse = $root.protocol.ClientPeerAddressesListResponse.fromObject(object.clientPeerAddressesListResponse);
                }
                if (object.clientPeerTransactionsResponse != null) {
                    if (typeof object.clientPeerTransactionsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.clientPeerTransactionsResponse: object expected");
                    message.clientPeerTransactionsResponse = $root.protocol.ClientPeerTransactionsResponse.fromObject(object.clientPeerTransactionsResponse);
                }
                if (object.deleteClientPeerAddressResponse != null) {
                    if (typeof object.deleteClientPeerAddressResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.deleteClientPeerAddressResponse: object expected");
                    message.deleteClientPeerAddressResponse = $root.protocol.DeleteClientPeerAddressResponse.fromObject(object.deleteClientPeerAddressResponse);
                }
                if (object.authUserResponse != null) {
                    if (typeof object.authUserResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.authUserResponse: object expected");
                    message.authUserResponse = $root.protocol.AuthUserResponse.fromObject(object.authUserResponse);
                }
                if (object.checkSessionResponse != null) {
                    if (typeof object.checkSessionResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.checkSessionResponse: object expected");
                    message.checkSessionResponse = $root.protocol.CheckSessionResponse.fromObject(object.checkSessionResponse);
                }
                if (object.adminCreateClientUserResponse != null) {
                    if (typeof object.adminCreateClientUserResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.adminCreateClientUserResponse: object expected");
                    message.adminCreateClientUserResponse = $root.protocol.AdminCreateClientUserResponse.fromObject(object.adminCreateClientUserResponse);
                }
                if (object.adminClientUsersListResponse != null) {
                    if (typeof object.adminClientUsersListResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.adminClientUsersListResponse: object expected");
                    message.adminClientUsersListResponse = $root.protocol.AdminClientUsersListResponse.fromObject(object.adminClientUsersListResponse);
                }
                if (object.adminUpdateClientUserAccessGroupsResponse != null) {
                    if (typeof object.adminUpdateClientUserAccessGroupsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.adminUpdateClientUserAccessGroupsResponse: object expected");
                    message.adminUpdateClientUserAccessGroupsResponse = $root.protocol.AdminUpdateClientUserAccessGroupsResponse.fromObject(object.adminUpdateClientUserAccessGroupsResponse);
                }
                if (object.adminUpdateClientUserPasswordResponse != null) {
                    if (typeof object.adminUpdateClientUserPasswordResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.adminUpdateClientUserPasswordResponse: object expected");
                    message.adminUpdateClientUserPasswordResponse = $root.protocol.AdminUpdateClientUserPasswordResponse.fromObject(object.adminUpdateClientUserPasswordResponse);
                }
                if (object.clientPeersStatsResponse != null) {
                    if (typeof object.clientPeersStatsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.clientPeersStatsResponse: object expected");
                    message.clientPeersStatsResponse = $root.protocol.ClientPeersStatsResponse.fromObject(object.clientPeersStatsResponse);
                }
                if (object.clientPeerTransactionsStatsResponse != null) {
                    if (typeof object.clientPeerTransactionsStatsResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.clientPeerTransactionsStatsResponse: object expected");
                    message.clientPeerTransactionsStatsResponse = $root.protocol.ClientPeerTransactionsStatsResponse.fromObject(object.clientPeerTransactionsStatsResponse);
                }
                if (object.clientAddressesTagsListResponse != null) {
                    if (typeof object.clientAddressesTagsListResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.clientAddressesTagsListResponse: object expected");
                    message.clientAddressesTagsListResponse = $root.protocol.ClientAddressesTagsListResponse.fromObject(object.clientAddressesTagsListResponse);
                }
                if (object.clientSystemAddressesTagGroupsListResponse != null) {
                    if (typeof object.clientSystemAddressesTagGroupsListResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.clientSystemAddressesTagGroupsListResponse: object expected");
                    message.clientSystemAddressesTagGroupsListResponse = $root.protocol.ClientSystemAddressesTagGroupsListResponse.fromObject(object.clientSystemAddressesTagGroupsListResponse);
                }
                if (object.createClientSystemAddressResponse != null) {
                    if (typeof object.createClientSystemAddressResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.createClientSystemAddressResponse: object expected");
                    message.createClientSystemAddressResponse = $root.protocol.CreateClientSystemAddressResponse.fromObject(object.createClientSystemAddressResponse);
                }
                if (object.clientTotalBalancesResponse != null) {
                    if (typeof object.clientTotalBalancesResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.clientTotalBalancesResponse: object expected");
                    message.clientTotalBalancesResponse = $root.protocol.ClientTotalBalancesResponse.fromObject(object.clientTotalBalancesResponse);
                }
                if (object.clientPeerChangeTransactionAmountResponse != null) {
                    if (typeof object.clientPeerChangeTransactionAmountResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.clientPeerChangeTransactionAmountResponse: object expected");
                    message.clientPeerChangeTransactionAmountResponse = $root.protocol.ClientPeerChangeTransactionAmountResponse.fromObject(object.clientPeerChangeTransactionAmountResponse);
                }
                if (object.appVersionResponse != null) {
                    if (typeof object.appVersionResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.appVersionResponse: object expected");
                    message.appVersionResponse = $root.protocol.AppVersionResponse.fromObject(object.appVersionResponse);
                }
                if (object.hasChangesDashboardResponse != null) {
                    if (typeof object.hasChangesDashboardResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.hasChangesDashboardResponse: object expected");
                    message.hasChangesDashboardResponse = $root.protocol.HasChangesDashboardResponse.fromObject(object.hasChangesDashboardResponse);
                }
                if (object.latestChangesDashboardResponse != null) {
                    if (typeof object.latestChangesDashboardResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.latestChangesDashboardResponse: object expected");
                    message.latestChangesDashboardResponse = $root.protocol.LatestChangesDashboardResponse.fromObject(object.latestChangesDashboardResponse);
                }
                if (object.checkTokenPayoutSystemBalanceResponse != null) {
                    if (typeof object.checkTokenPayoutSystemBalanceResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.checkTokenPayoutSystemBalanceResponse: object expected");
                    message.checkTokenPayoutSystemBalanceResponse = $root.protocol.CheckTokenPayoutSystemBalanceResponse.fromObject(object.checkTokenPayoutSystemBalanceResponse);
                }
                if (object.cancelPendingPayoutRequestResponse != null) {
                    if (typeof object.cancelPendingPayoutRequestResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.cancelPendingPayoutRequestResponse: object expected");
                    message.cancelPendingPayoutRequestResponse = $root.protocol.CancelPendingPayoutRequestResponse.fromObject(object.cancelPendingPayoutRequestResponse);
                }
                if (object.amlClientUnboundChecksListResponse != null) {
                    if (typeof object.amlClientUnboundChecksListResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.amlClientUnboundChecksListResponse: object expected");
                    message.amlClientUnboundChecksListResponse = $root.protocol.AMLClientUnboundChecksListResponse.fromObject(object.amlClientUnboundChecksListResponse);
                }
                if (object.clientAddressesListResponse != null) {
                    if (typeof object.clientAddressesListResponse !== "object")
                        throw TypeError(".protocol.ServerResponse.clientAddressesListResponse: object expected");
                    message.clientAddressesListResponse = $root.protocol.ClientAddressesListResponse.fromObject(object.clientAddressesListResponse);
                }
                switch (object.error) {
                case "Ok":
                case 0:
                    message.error = 0;
                    break;
                case "MissingRequestBody":
                case 1:
                    message.error = 1;
                    break;
                case "ParsingError":
                case 2:
                    message.error = 2;
                    break;
                case "ProcessingError":
                case 3:
                    message.error = 3;
                    break;
                case "NotImplemented":
                case 4:
                    message.error = 4;
                    break;
                case "NotFound":
                case 5:
                    message.error = 5;
                    break;
                case "UnknownCurrency":
                case 6:
                    message.error = 6;
                    break;
                case "NotEnoughBalance":
                case 7:
                    message.error = 7;
                    break;
                case "UnknownClient":
                case 8:
                    message.error = 8;
                    break;
                case "MissingEntityField":
                case 9:
                    message.error = 9;
                    break;
                case "InvalidAddress":
                case 10:
                    message.error = 10;
                    break;
                case "InvalidAmount":
                case 11:
                    message.error = 11;
                    break;
                case "AmountTooSmall":
                case 12:
                    message.error = 12;
                    break;
                case "TxSizeTooLarge":
                case 13:
                    message.error = 13;
                    break;
                case "NoAddressesForCheck":
                case 14:
                    message.error = 14;
                    break;
                case "NoAMLAvailableChecksLeft":
                case 15:
                    message.error = 15;
                    break;
                case "AMLBatchCheckIsActive":
                case 16:
                    message.error = 16;
                    break;
                case "UnsupportedChain":
                case 17:
                    message.error = 17;
                    break;
                case "InvalidFeeRate":
                case 18:
                    message.error = 18;
                    break;
                case "DuplicateRecord":
                case 19:
                    message.error = 19;
                    break;
                case "InvalidLoginOrPassword":
                case 20:
                    message.error = 20;
                    break;
                case "InvalidToken":
                case 21:
                    message.error = 21;
                    break;
                case "TokenExpired":
                case 22:
                    message.error = 22;
                    break;
                case "EmptyToken":
                case 23:
                    message.error = 23;
                    break;
                case "EmptyAddressesList":
                case 24:
                    message.error = 24;
                    break;
                case "InvalidPayoutTags":
                case 25:
                    message.error = 25;
                    break;
                case "UnsupportedCurrency":
                case 26:
                    message.error = 26;
                    break;
                case "InvalidNetworkFeeAddresses":
                case 27:
                    message.error = 27;
                    break;
                case "InvalidPayoutAddresses":
                case 28:
                    message.error = 28;
                    break;
                case "AccessForbidden":
                case 29:
                    message.error = 29;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ServerResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ServerResponse
             * @static
             * @param {protocol.ServerResponse} message ServerResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServerResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.createNewAddressResponse = null;
                    object.balancesResponse = null;
                    object.transactionsResponse = null;
                    object.enqueuePayoutResponse = null;
                    object.payoutRequestDetailsResponse = null;
                    object.mainAccountsResponse = null;
                    object.modifyTagsResponse = null;
                    object.listTagsResponse = null;
                    object.confirmedClientTransactionsResponse = null;
                    object.payoutDetailsResponse = null;
                    object.convertCryptoToFiatResponse = null;
                    object.transactionFeeResponse = null;
                    object.clientRequestLogsResponse = null;
                    object.listPayoutRequestsResponse = null;
                    object.amlSingleCheckResponse = null;
                    object.amlCheckResultResponse = null;
                    object.amlClientBatchCheckStartResponse = null;
                    object.amlClientBatchCheckStopResponse = null;
                    object.amlBatchCheckResultsResponse = null;
                    object.amlServiceStatusResponse = null;
                    object.transactionsPagedResponse = null;
                    object.transactionsStatsResponse = null;
                    object.amlClientOverallStatsResponse = null;
                    object.amlClientAddressesListResponse = null;
                    object.amlClientBatchStatsResponse = null;
                    object.amlClientBatchAddressesListResponse = null;
                    object.amlClientBatchChecksListResponse = null;
                    object.amlClientBatchAddressResponse = null;
                    object.feeRatesResponse = null;
                    object.createClientPeerResponse = null;
                    object.generateNewClientPeerAddressResponse = null;
                    object.regenerateClientPeerAddressResponse = null;
                    object.clientPeersShortListResponse = null;
                    object.clientPeersListResponse = null;
                    object.clientPeerDetailsResponse = null;
                    object.clientPeerAddressesListResponse = null;
                    object.clientPeerTransactionsResponse = null;
                    object.deleteClientPeerAddressResponse = null;
                    object.authUserResponse = null;
                    object.checkSessionResponse = null;
                    object.adminCreateClientUserResponse = null;
                    object.adminClientUsersListResponse = null;
                    object.adminUpdateClientUserAccessGroupsResponse = null;
                    object.adminUpdateClientUserPasswordResponse = null;
                    object.clientPeersStatsResponse = null;
                    object.clientPeerTransactionsStatsResponse = null;
                    object.clientAddressesTagsListResponse = null;
                    object.clientSystemAddressesTagGroupsListResponse = null;
                    object.createClientSystemAddressResponse = null;
                    object.clientTotalBalancesResponse = null;
                    object.clientPeerChangeTransactionAmountResponse = null;
                    object.appVersionResponse = null;
                    object.hasChangesDashboardResponse = null;
                    object.latestChangesDashboardResponse = null;
                    object.checkTokenPayoutSystemBalanceResponse = null;
                    object.cancelPendingPayoutRequestResponse = null;
                    object.amlClientUnboundChecksListResponse = null;
                    object.clientAddressesListResponse = null;
                    object.error = options.enums === String ? "Ok" : 0;
                }
                if (message.createNewAddressResponse != null && message.hasOwnProperty("createNewAddressResponse"))
                    object.createNewAddressResponse = $root.protocol.CreateNewAddressResponse.toObject(message.createNewAddressResponse, options);
                if (message.balancesResponse != null && message.hasOwnProperty("balancesResponse"))
                    object.balancesResponse = $root.protocol.BalancesResponse.toObject(message.balancesResponse, options);
                if (message.transactionsResponse != null && message.hasOwnProperty("transactionsResponse"))
                    object.transactionsResponse = $root.protocol.TransactionsResponse.toObject(message.transactionsResponse, options);
                if (message.enqueuePayoutResponse != null && message.hasOwnProperty("enqueuePayoutResponse"))
                    object.enqueuePayoutResponse = $root.protocol.EnqueuePayoutResponse.toObject(message.enqueuePayoutResponse, options);
                if (message.payoutRequestDetailsResponse != null && message.hasOwnProperty("payoutRequestDetailsResponse"))
                    object.payoutRequestDetailsResponse = $root.protocol.PayoutRequestDetailsResponse.toObject(message.payoutRequestDetailsResponse, options);
                if (message.mainAccountsResponse != null && message.hasOwnProperty("mainAccountsResponse"))
                    object.mainAccountsResponse = $root.protocol.MainAccountsResponse.toObject(message.mainAccountsResponse, options);
                if (message.modifyTagsResponse != null && message.hasOwnProperty("modifyTagsResponse"))
                    object.modifyTagsResponse = $root.protocol.ModifyTagsResponse.toObject(message.modifyTagsResponse, options);
                if (message.listTagsResponse != null && message.hasOwnProperty("listTagsResponse"))
                    object.listTagsResponse = $root.protocol.ListTagsResponse.toObject(message.listTagsResponse, options);
                if (message.confirmedClientTransactionsResponse != null && message.hasOwnProperty("confirmedClientTransactionsResponse"))
                    object.confirmedClientTransactionsResponse = $root.protocol.ConfirmedClientTransactionsResponse.toObject(message.confirmedClientTransactionsResponse, options);
                if (message.payoutDetailsResponse != null && message.hasOwnProperty("payoutDetailsResponse"))
                    object.payoutDetailsResponse = $root.protocol.PayoutDetailsResponse.toObject(message.payoutDetailsResponse, options);
                if (message.convertCryptoToFiatResponse != null && message.hasOwnProperty("convertCryptoToFiatResponse"))
                    object.convertCryptoToFiatResponse = $root.protocol.ConvertCryptoToFiatResponse.toObject(message.convertCryptoToFiatResponse, options);
                if (message.transactionFeeResponse != null && message.hasOwnProperty("transactionFeeResponse"))
                    object.transactionFeeResponse = $root.protocol.TransactionFeeResponse.toObject(message.transactionFeeResponse, options);
                if (message.clientRequestLogsResponse != null && message.hasOwnProperty("clientRequestLogsResponse"))
                    object.clientRequestLogsResponse = $root.protocol.ClientRequestLogsResponse.toObject(message.clientRequestLogsResponse, options);
                if (message.listPayoutRequestsResponse != null && message.hasOwnProperty("listPayoutRequestsResponse"))
                    object.listPayoutRequestsResponse = $root.protocol.ListPayoutRequestsResponse.toObject(message.listPayoutRequestsResponse, options);
                if (message.amlSingleCheckResponse != null && message.hasOwnProperty("amlSingleCheckResponse"))
                    object.amlSingleCheckResponse = $root.protocol.AMLSingleCheckResponse.toObject(message.amlSingleCheckResponse, options);
                if (message.amlCheckResultResponse != null && message.hasOwnProperty("amlCheckResultResponse"))
                    object.amlCheckResultResponse = $root.protocol.AMLCheckResultResponse.toObject(message.amlCheckResultResponse, options);
                if (message.amlClientBatchCheckStartResponse != null && message.hasOwnProperty("amlClientBatchCheckStartResponse"))
                    object.amlClientBatchCheckStartResponse = $root.protocol.AMLClientBatchCheckStartResponse.toObject(message.amlClientBatchCheckStartResponse, options);
                if (message.amlClientBatchCheckStopResponse != null && message.hasOwnProperty("amlClientBatchCheckStopResponse"))
                    object.amlClientBatchCheckStopResponse = $root.protocol.AMLClientBatchCheckStopResponse.toObject(message.amlClientBatchCheckStopResponse, options);
                if (message.amlBatchCheckResultsResponse != null && message.hasOwnProperty("amlBatchCheckResultsResponse"))
                    object.amlBatchCheckResultsResponse = $root.protocol.AMLBatchCheckResultsResponse.toObject(message.amlBatchCheckResultsResponse, options);
                if (message.amlServiceStatusResponse != null && message.hasOwnProperty("amlServiceStatusResponse"))
                    object.amlServiceStatusResponse = $root.protocol.AMLServiceStatusResponse.toObject(message.amlServiceStatusResponse, options);
                if (message.transactionsPagedResponse != null && message.hasOwnProperty("transactionsPagedResponse"))
                    object.transactionsPagedResponse = $root.protocol.TransactionsPagedResponse.toObject(message.transactionsPagedResponse, options);
                if (message.transactionsStatsResponse != null && message.hasOwnProperty("transactionsStatsResponse"))
                    object.transactionsStatsResponse = $root.protocol.TransactionsStatsResponse.toObject(message.transactionsStatsResponse, options);
                if (message.amlClientOverallStatsResponse != null && message.hasOwnProperty("amlClientOverallStatsResponse"))
                    object.amlClientOverallStatsResponse = $root.protocol.AMLClientOverallStatsResponse.toObject(message.amlClientOverallStatsResponse, options);
                if (message.amlClientAddressesListResponse != null && message.hasOwnProperty("amlClientAddressesListResponse"))
                    object.amlClientAddressesListResponse = $root.protocol.AMLClientAddressesListResponse.toObject(message.amlClientAddressesListResponse, options);
                if (message.amlClientBatchStatsResponse != null && message.hasOwnProperty("amlClientBatchStatsResponse"))
                    object.amlClientBatchStatsResponse = $root.protocol.AMLClientBatchStatsResponse.toObject(message.amlClientBatchStatsResponse, options);
                if (message.amlClientBatchAddressesListResponse != null && message.hasOwnProperty("amlClientBatchAddressesListResponse"))
                    object.amlClientBatchAddressesListResponse = $root.protocol.AMLClientBatchAddressesListResponse.toObject(message.amlClientBatchAddressesListResponse, options);
                if (message.amlClientBatchChecksListResponse != null && message.hasOwnProperty("amlClientBatchChecksListResponse"))
                    object.amlClientBatchChecksListResponse = $root.protocol.AMLClientBatchChecksListResponse.toObject(message.amlClientBatchChecksListResponse, options);
                if (message.amlClientBatchAddressResponse != null && message.hasOwnProperty("amlClientBatchAddressResponse"))
                    object.amlClientBatchAddressResponse = $root.protocol.AMLClientBatchAddressResponse.toObject(message.amlClientBatchAddressResponse, options);
                if (message.feeRatesResponse != null && message.hasOwnProperty("feeRatesResponse"))
                    object.feeRatesResponse = $root.protocol.FeeRatesResponse.toObject(message.feeRatesResponse, options);
                if (message.createClientPeerResponse != null && message.hasOwnProperty("createClientPeerResponse"))
                    object.createClientPeerResponse = $root.protocol.CreateClientPeerResponse.toObject(message.createClientPeerResponse, options);
                if (message.generateNewClientPeerAddressResponse != null && message.hasOwnProperty("generateNewClientPeerAddressResponse"))
                    object.generateNewClientPeerAddressResponse = $root.protocol.GenerateNewClientPeerAddressResponse.toObject(message.generateNewClientPeerAddressResponse, options);
                if (message.regenerateClientPeerAddressResponse != null && message.hasOwnProperty("regenerateClientPeerAddressResponse"))
                    object.regenerateClientPeerAddressResponse = $root.protocol.RegenerateClientPeerAddressResponse.toObject(message.regenerateClientPeerAddressResponse, options);
                if (message.clientPeersShortListResponse != null && message.hasOwnProperty("clientPeersShortListResponse"))
                    object.clientPeersShortListResponse = $root.protocol.ClientPeersShortListResponse.toObject(message.clientPeersShortListResponse, options);
                if (message.clientPeersListResponse != null && message.hasOwnProperty("clientPeersListResponse"))
                    object.clientPeersListResponse = $root.protocol.ClientPeersListResponse.toObject(message.clientPeersListResponse, options);
                if (message.clientPeerDetailsResponse != null && message.hasOwnProperty("clientPeerDetailsResponse"))
                    object.clientPeerDetailsResponse = $root.protocol.ClientPeerDetailsResponse.toObject(message.clientPeerDetailsResponse, options);
                if (message.clientPeerAddressesListResponse != null && message.hasOwnProperty("clientPeerAddressesListResponse"))
                    object.clientPeerAddressesListResponse = $root.protocol.ClientPeerAddressesListResponse.toObject(message.clientPeerAddressesListResponse, options);
                if (message.clientPeerTransactionsResponse != null && message.hasOwnProperty("clientPeerTransactionsResponse"))
                    object.clientPeerTransactionsResponse = $root.protocol.ClientPeerTransactionsResponse.toObject(message.clientPeerTransactionsResponse, options);
                if (message.deleteClientPeerAddressResponse != null && message.hasOwnProperty("deleteClientPeerAddressResponse"))
                    object.deleteClientPeerAddressResponse = $root.protocol.DeleteClientPeerAddressResponse.toObject(message.deleteClientPeerAddressResponse, options);
                if (message.authUserResponse != null && message.hasOwnProperty("authUserResponse"))
                    object.authUserResponse = $root.protocol.AuthUserResponse.toObject(message.authUserResponse, options);
                if (message.checkSessionResponse != null && message.hasOwnProperty("checkSessionResponse"))
                    object.checkSessionResponse = $root.protocol.CheckSessionResponse.toObject(message.checkSessionResponse, options);
                if (message.adminCreateClientUserResponse != null && message.hasOwnProperty("adminCreateClientUserResponse"))
                    object.adminCreateClientUserResponse = $root.protocol.AdminCreateClientUserResponse.toObject(message.adminCreateClientUserResponse, options);
                if (message.adminClientUsersListResponse != null && message.hasOwnProperty("adminClientUsersListResponse"))
                    object.adminClientUsersListResponse = $root.protocol.AdminClientUsersListResponse.toObject(message.adminClientUsersListResponse, options);
                if (message.adminUpdateClientUserAccessGroupsResponse != null && message.hasOwnProperty("adminUpdateClientUserAccessGroupsResponse"))
                    object.adminUpdateClientUserAccessGroupsResponse = $root.protocol.AdminUpdateClientUserAccessGroupsResponse.toObject(message.adminUpdateClientUserAccessGroupsResponse, options);
                if (message.adminUpdateClientUserPasswordResponse != null && message.hasOwnProperty("adminUpdateClientUserPasswordResponse"))
                    object.adminUpdateClientUserPasswordResponse = $root.protocol.AdminUpdateClientUserPasswordResponse.toObject(message.adminUpdateClientUserPasswordResponse, options);
                if (message.clientPeersStatsResponse != null && message.hasOwnProperty("clientPeersStatsResponse"))
                    object.clientPeersStatsResponse = $root.protocol.ClientPeersStatsResponse.toObject(message.clientPeersStatsResponse, options);
                if (message.clientPeerTransactionsStatsResponse != null && message.hasOwnProperty("clientPeerTransactionsStatsResponse"))
                    object.clientPeerTransactionsStatsResponse = $root.protocol.ClientPeerTransactionsStatsResponse.toObject(message.clientPeerTransactionsStatsResponse, options);
                if (message.clientAddressesTagsListResponse != null && message.hasOwnProperty("clientAddressesTagsListResponse"))
                    object.clientAddressesTagsListResponse = $root.protocol.ClientAddressesTagsListResponse.toObject(message.clientAddressesTagsListResponse, options);
                if (message.clientSystemAddressesTagGroupsListResponse != null && message.hasOwnProperty("clientSystemAddressesTagGroupsListResponse"))
                    object.clientSystemAddressesTagGroupsListResponse = $root.protocol.ClientSystemAddressesTagGroupsListResponse.toObject(message.clientSystemAddressesTagGroupsListResponse, options);
                if (message.createClientSystemAddressResponse != null && message.hasOwnProperty("createClientSystemAddressResponse"))
                    object.createClientSystemAddressResponse = $root.protocol.CreateClientSystemAddressResponse.toObject(message.createClientSystemAddressResponse, options);
                if (message.clientTotalBalancesResponse != null && message.hasOwnProperty("clientTotalBalancesResponse"))
                    object.clientTotalBalancesResponse = $root.protocol.ClientTotalBalancesResponse.toObject(message.clientTotalBalancesResponse, options);
                if (message.clientPeerChangeTransactionAmountResponse != null && message.hasOwnProperty("clientPeerChangeTransactionAmountResponse"))
                    object.clientPeerChangeTransactionAmountResponse = $root.protocol.ClientPeerChangeTransactionAmountResponse.toObject(message.clientPeerChangeTransactionAmountResponse, options);
                if (message.appVersionResponse != null && message.hasOwnProperty("appVersionResponse"))
                    object.appVersionResponse = $root.protocol.AppVersionResponse.toObject(message.appVersionResponse, options);
                if (message.hasChangesDashboardResponse != null && message.hasOwnProperty("hasChangesDashboardResponse"))
                    object.hasChangesDashboardResponse = $root.protocol.HasChangesDashboardResponse.toObject(message.hasChangesDashboardResponse, options);
                if (message.latestChangesDashboardResponse != null && message.hasOwnProperty("latestChangesDashboardResponse"))
                    object.latestChangesDashboardResponse = $root.protocol.LatestChangesDashboardResponse.toObject(message.latestChangesDashboardResponse, options);
                if (message.checkTokenPayoutSystemBalanceResponse != null && message.hasOwnProperty("checkTokenPayoutSystemBalanceResponse"))
                    object.checkTokenPayoutSystemBalanceResponse = $root.protocol.CheckTokenPayoutSystemBalanceResponse.toObject(message.checkTokenPayoutSystemBalanceResponse, options);
                if (message.cancelPendingPayoutRequestResponse != null && message.hasOwnProperty("cancelPendingPayoutRequestResponse"))
                    object.cancelPendingPayoutRequestResponse = $root.protocol.CancelPendingPayoutRequestResponse.toObject(message.cancelPendingPayoutRequestResponse, options);
                if (message.amlClientUnboundChecksListResponse != null && message.hasOwnProperty("amlClientUnboundChecksListResponse"))
                    object.amlClientUnboundChecksListResponse = $root.protocol.AMLClientUnboundChecksListResponse.toObject(message.amlClientUnboundChecksListResponse, options);
                if (message.clientAddressesListResponse != null && message.hasOwnProperty("clientAddressesListResponse"))
                    object.clientAddressesListResponse = $root.protocol.ClientAddressesListResponse.toObject(message.clientAddressesListResponse, options);
                if (message.error != null && message.hasOwnProperty("error"))
                    object.error = options.enums === String ? $root.protocol.ServerError[message.error] : message.error;
                return object;
            };
    
            /**
             * Converts this ServerResponse to JSON.
             * @function toJSON
             * @memberof protocol.ServerResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServerResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ServerResponse;
        })();
    
        /**
         * ServerError enum.
         * @name protocol.ServerError
         * @enum {number}
         * @property {number} Ok=0 Ok value
         * @property {number} MissingRequestBody=1 MissingRequestBody value
         * @property {number} ParsingError=2 ParsingError value
         * @property {number} ProcessingError=3 ProcessingError value
         * @property {number} NotImplemented=4 NotImplemented value
         * @property {number} NotFound=5 NotFound value
         * @property {number} UnknownCurrency=6 UnknownCurrency value
         * @property {number} NotEnoughBalance=7 NotEnoughBalance value
         * @property {number} UnknownClient=8 UnknownClient value
         * @property {number} MissingEntityField=9 MissingEntityField value
         * @property {number} InvalidAddress=10 InvalidAddress value
         * @property {number} InvalidAmount=11 InvalidAmount value
         * @property {number} AmountTooSmall=12 AmountTooSmall value
         * @property {number} TxSizeTooLarge=13 TxSizeTooLarge value
         * @property {number} NoAddressesForCheck=14 NoAddressesForCheck value
         * @property {number} NoAMLAvailableChecksLeft=15 NoAMLAvailableChecksLeft value
         * @property {number} AMLBatchCheckIsActive=16 AMLBatchCheckIsActive value
         * @property {number} UnsupportedChain=17 UnsupportedChain value
         * @property {number} InvalidFeeRate=18 InvalidFeeRate value
         * @property {number} DuplicateRecord=19 DuplicateRecord value
         * @property {number} InvalidLoginOrPassword=20 InvalidLoginOrPassword value
         * @property {number} InvalidToken=21 InvalidToken value
         * @property {number} TokenExpired=22 TokenExpired value
         * @property {number} EmptyToken=23 EmptyToken value
         * @property {number} EmptyAddressesList=24 EmptyAddressesList value
         * @property {number} InvalidPayoutTags=25 InvalidPayoutTags value
         * @property {number} UnsupportedCurrency=26 UnsupportedCurrency value
         * @property {number} InvalidNetworkFeeAddresses=27 InvalidNetworkFeeAddresses value
         * @property {number} InvalidPayoutAddresses=28 InvalidPayoutAddresses value
         * @property {number} AccessForbidden=29 AccessForbidden value
         */
        protocol.ServerError = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "Ok"] = 0;
            values[valuesById[1] = "MissingRequestBody"] = 1;
            values[valuesById[2] = "ParsingError"] = 2;
            values[valuesById[3] = "ProcessingError"] = 3;
            values[valuesById[4] = "NotImplemented"] = 4;
            values[valuesById[5] = "NotFound"] = 5;
            values[valuesById[6] = "UnknownCurrency"] = 6;
            values[valuesById[7] = "NotEnoughBalance"] = 7;
            values[valuesById[8] = "UnknownClient"] = 8;
            values[valuesById[9] = "MissingEntityField"] = 9;
            values[valuesById[10] = "InvalidAddress"] = 10;
            values[valuesById[11] = "InvalidAmount"] = 11;
            values[valuesById[12] = "AmountTooSmall"] = 12;
            values[valuesById[13] = "TxSizeTooLarge"] = 13;
            values[valuesById[14] = "NoAddressesForCheck"] = 14;
            values[valuesById[15] = "NoAMLAvailableChecksLeft"] = 15;
            values[valuesById[16] = "AMLBatchCheckIsActive"] = 16;
            values[valuesById[17] = "UnsupportedChain"] = 17;
            values[valuesById[18] = "InvalidFeeRate"] = 18;
            values[valuesById[19] = "DuplicateRecord"] = 19;
            values[valuesById[20] = "InvalidLoginOrPassword"] = 20;
            values[valuesById[21] = "InvalidToken"] = 21;
            values[valuesById[22] = "TokenExpired"] = 22;
            values[valuesById[23] = "EmptyToken"] = 23;
            values[valuesById[24] = "EmptyAddressesList"] = 24;
            values[valuesById[25] = "InvalidPayoutTags"] = 25;
            values[valuesById[26] = "UnsupportedCurrency"] = 26;
            values[valuesById[27] = "InvalidNetworkFeeAddresses"] = 27;
            values[valuesById[28] = "InvalidPayoutAddresses"] = 28;
            values[valuesById[29] = "AccessForbidden"] = 29;
            return values;
        })();
    
        protocol.ClientAddress = (function() {
    
            /**
             * Properties of a ClientAddress.
             * @memberof protocol
             * @interface IClientAddress
             * @property {number|null} [id] ClientAddress id
             * @property {string|null} [address] ClientAddress address
             * @property {Object.<string,protocol.ClientAddress.IBalance>|null} [balances] ClientAddress balances
             * @property {protocol.Currency|null} [currency] ClientAddress currency
             * @property {string|null} [type] ClientAddress type
             * @property {number|null} [riskScore] ClientAddress riskScore
             * @property {protocol.AMLRiskLevel|null} [riskLevel] ClientAddress riskLevel
             * @property {Array.<string>|null} [tags] ClientAddress tags
             */
    
            /**
             * Constructs a new ClientAddress.
             * @memberof protocol
             * @classdesc Represents a ClientAddress.
             * @implements IClientAddress
             * @constructor
             * @param {protocol.IClientAddress=} [properties] Properties to set
             */
            function ClientAddress(properties) {
                this.balances = {};
                this.tags = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientAddress id.
             * @member {number} id
             * @memberof protocol.ClientAddress
             * @instance
             */
            ClientAddress.prototype.id = 0;
    
            /**
             * ClientAddress address.
             * @member {string} address
             * @memberof protocol.ClientAddress
             * @instance
             */
            ClientAddress.prototype.address = "";
    
            /**
             * ClientAddress balances.
             * @member {Object.<string,protocol.ClientAddress.IBalance>} balances
             * @memberof protocol.ClientAddress
             * @instance
             */
            ClientAddress.prototype.balances = $util.emptyObject;
    
            /**
             * ClientAddress currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.ClientAddress
             * @instance
             */
            ClientAddress.prototype.currency = 0;
    
            /**
             * ClientAddress type.
             * @member {string} type
             * @memberof protocol.ClientAddress
             * @instance
             */
            ClientAddress.prototype.type = "";
    
            /**
             * ClientAddress riskScore.
             * @member {number} riskScore
             * @memberof protocol.ClientAddress
             * @instance
             */
            ClientAddress.prototype.riskScore = 0;
    
            /**
             * ClientAddress riskLevel.
             * @member {protocol.AMLRiskLevel} riskLevel
             * @memberof protocol.ClientAddress
             * @instance
             */
            ClientAddress.prototype.riskLevel = 0;
    
            /**
             * ClientAddress tags.
             * @member {Array.<string>} tags
             * @memberof protocol.ClientAddress
             * @instance
             */
            ClientAddress.prototype.tags = $util.emptyArray;
    
            /**
             * Creates a new ClientAddress instance using the specified properties.
             * @function create
             * @memberof protocol.ClientAddress
             * @static
             * @param {protocol.IClientAddress=} [properties] Properties to set
             * @returns {protocol.ClientAddress} ClientAddress instance
             */
            ClientAddress.create = function create(properties) {
                return new ClientAddress(properties);
            };
    
            /**
             * Encodes the specified ClientAddress message. Does not implicitly {@link protocol.ClientAddress.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientAddress
             * @static
             * @param {protocol.IClientAddress} message ClientAddress message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientAddress.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
                if (message.balances != null && Object.hasOwnProperty.call(message, "balances"))
                    for (var keys = Object.keys(message.balances), i = 0; i < keys.length; ++i) {
                        writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                        $root.protocol.ClientAddress.Balance.encode(message.balances[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                    }
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.currency);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.type);
                if (message.riskScore != null && Object.hasOwnProperty.call(message, "riskScore"))
                    writer.uint32(/* id 6, wireType 5 =*/53).float(message.riskScore);
                if (message.riskLevel != null && Object.hasOwnProperty.call(message, "riskLevel"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.riskLevel);
                if (message.tags != null && message.tags.length)
                    for (var i = 0; i < message.tags.length; ++i)
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.tags[i]);
                return writer;
            };
    
            /**
             * Encodes the specified ClientAddress message, length delimited. Does not implicitly {@link protocol.ClientAddress.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientAddress
             * @static
             * @param {protocol.IClientAddress} message ClientAddress message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientAddress.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientAddress message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientAddress
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientAddress} ClientAddress
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientAddress.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientAddress(), key;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint32();
                        break;
                    case 2:
                        message.address = reader.string();
                        break;
                    case 3:
                        reader.skip().pos++;
                        if (message.balances === $util.emptyObject)
                            message.balances = {};
                        key = reader.string();
                        reader.pos++;
                        message.balances[key] = $root.protocol.ClientAddress.Balance.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.currency = reader.int32();
                        break;
                    case 5:
                        message.type = reader.string();
                        break;
                    case 6:
                        message.riskScore = reader.float();
                        break;
                    case 7:
                        message.riskLevel = reader.int32();
                        break;
                    case 8:
                        if (!(message.tags && message.tags.length))
                            message.tags = [];
                        message.tags.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientAddress message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientAddress
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientAddress} ClientAddress
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientAddress.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientAddress message.
             * @function verify
             * @memberof protocol.ClientAddress
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientAddress.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                if (message.balances != null && message.hasOwnProperty("balances")) {
                    if (!$util.isObject(message.balances))
                        return "balances: object expected";
                    var key = Object.keys(message.balances);
                    for (var i = 0; i < key.length; ++i) {
                        var error = $root.protocol.ClientAddress.Balance.verify(message.balances[key[i]]);
                        if (error)
                            return "balances." + error;
                    }
                }
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.type != null && message.hasOwnProperty("type"))
                    if (!$util.isString(message.type))
                        return "type: string expected";
                if (message.riskScore != null && message.hasOwnProperty("riskScore"))
                    if (typeof message.riskScore !== "number")
                        return "riskScore: number expected";
                if (message.riskLevel != null && message.hasOwnProperty("riskLevel"))
                    switch (message.riskLevel) {
                    default:
                        return "riskLevel: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.tags != null && message.hasOwnProperty("tags")) {
                    if (!Array.isArray(message.tags))
                        return "tags: array expected";
                    for (var i = 0; i < message.tags.length; ++i)
                        if (!$util.isString(message.tags[i]))
                            return "tags: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a ClientAddress message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientAddress
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientAddress} ClientAddress
             */
            ClientAddress.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientAddress)
                    return object;
                var message = new $root.protocol.ClientAddress();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.address != null)
                    message.address = String(object.address);
                if (object.balances) {
                    if (typeof object.balances !== "object")
                        throw TypeError(".protocol.ClientAddress.balances: object expected");
                    message.balances = {};
                    for (var keys = Object.keys(object.balances), i = 0; i < keys.length; ++i) {
                        if (typeof object.balances[keys[i]] !== "object")
                            throw TypeError(".protocol.ClientAddress.balances: object expected");
                        message.balances[keys[i]] = $root.protocol.ClientAddress.Balance.fromObject(object.balances[keys[i]]);
                    }
                }
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                if (object.type != null)
                    message.type = String(object.type);
                if (object.riskScore != null)
                    message.riskScore = Number(object.riskScore);
                switch (object.riskLevel) {
                case "UNK_AML_RISK_LEVEL":
                case 0:
                    message.riskLevel = 0;
                    break;
                case "LOW_AML_RISK_LEVEL":
                case 1:
                    message.riskLevel = 1;
                    break;
                case "MEDIUM_AML_RISK_LEVEL":
                case 2:
                    message.riskLevel = 2;
                    break;
                case "HIGH_AML_RISK_LEVEL":
                case 3:
                    message.riskLevel = 3;
                    break;
                }
                if (object.tags) {
                    if (!Array.isArray(object.tags))
                        throw TypeError(".protocol.ClientAddress.tags: array expected");
                    message.tags = [];
                    for (var i = 0; i < object.tags.length; ++i)
                        message.tags[i] = String(object.tags[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientAddress message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientAddress
             * @static
             * @param {protocol.ClientAddress} message ClientAddress
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientAddress.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.tags = [];
                if (options.objects || options.defaults)
                    object.balances = {};
                if (options.defaults) {
                    object.id = 0;
                    object.address = "";
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.type = "";
                    object.riskScore = 0;
                    object.riskLevel = options.enums === String ? "UNK_AML_RISK_LEVEL" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                var keys2;
                if (message.balances && (keys2 = Object.keys(message.balances)).length) {
                    object.balances = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.balances[keys2[j]] = $root.protocol.ClientAddress.Balance.toObject(message.balances[keys2[j]], options);
                }
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = message.type;
                if (message.riskScore != null && message.hasOwnProperty("riskScore"))
                    object.riskScore = options.json && !isFinite(message.riskScore) ? String(message.riskScore) : message.riskScore;
                if (message.riskLevel != null && message.hasOwnProperty("riskLevel"))
                    object.riskLevel = options.enums === String ? $root.protocol.AMLRiskLevel[message.riskLevel] : message.riskLevel;
                if (message.tags && message.tags.length) {
                    object.tags = [];
                    for (var j = 0; j < message.tags.length; ++j)
                        object.tags[j] = message.tags[j];
                }
                return object;
            };
    
            /**
             * Converts this ClientAddress to JSON.
             * @function toJSON
             * @memberof protocol.ClientAddress
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientAddress.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            ClientAddress.Balance = (function() {
    
                /**
                 * Properties of a Balance.
                 * @memberof protocol.ClientAddress
                 * @interface IBalance
                 * @property {protocol.Currency|null} [currency] Balance currency
                 * @property {string|null} [balance] Balance balance
                 * @property {string|null} [unconfirmedInBalance] Balance unconfirmedInBalance
                 * @property {string|null} [unconfirmedOutBalance] Balance unconfirmedOutBalance
                 */
    
                /**
                 * Constructs a new Balance.
                 * @memberof protocol.ClientAddress
                 * @classdesc Represents a Balance.
                 * @implements IBalance
                 * @constructor
                 * @param {protocol.ClientAddress.IBalance=} [properties] Properties to set
                 */
                function Balance(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Balance currency.
                 * @member {protocol.Currency} currency
                 * @memberof protocol.ClientAddress.Balance
                 * @instance
                 */
                Balance.prototype.currency = 0;
    
                /**
                 * Balance balance.
                 * @member {string} balance
                 * @memberof protocol.ClientAddress.Balance
                 * @instance
                 */
                Balance.prototype.balance = "";
    
                /**
                 * Balance unconfirmedInBalance.
                 * @member {string} unconfirmedInBalance
                 * @memberof protocol.ClientAddress.Balance
                 * @instance
                 */
                Balance.prototype.unconfirmedInBalance = "";
    
                /**
                 * Balance unconfirmedOutBalance.
                 * @member {string} unconfirmedOutBalance
                 * @memberof protocol.ClientAddress.Balance
                 * @instance
                 */
                Balance.prototype.unconfirmedOutBalance = "";
    
                /**
                 * Creates a new Balance instance using the specified properties.
                 * @function create
                 * @memberof protocol.ClientAddress.Balance
                 * @static
                 * @param {protocol.ClientAddress.IBalance=} [properties] Properties to set
                 * @returns {protocol.ClientAddress.Balance} Balance instance
                 */
                Balance.create = function create(properties) {
                    return new Balance(properties);
                };
    
                /**
                 * Encodes the specified Balance message. Does not implicitly {@link protocol.ClientAddress.Balance.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.ClientAddress.Balance
                 * @static
                 * @param {protocol.ClientAddress.IBalance} message Balance message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Balance.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currency);
                    if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.balance);
                    if (message.unconfirmedInBalance != null && Object.hasOwnProperty.call(message, "unconfirmedInBalance"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.unconfirmedInBalance);
                    if (message.unconfirmedOutBalance != null && Object.hasOwnProperty.call(message, "unconfirmedOutBalance"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.unconfirmedOutBalance);
                    return writer;
                };
    
                /**
                 * Encodes the specified Balance message, length delimited. Does not implicitly {@link protocol.ClientAddress.Balance.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.ClientAddress.Balance
                 * @static
                 * @param {protocol.ClientAddress.IBalance} message Balance message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Balance.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Balance message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.ClientAddress.Balance
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.ClientAddress.Balance} Balance
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Balance.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientAddress.Balance();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.currency = reader.int32();
                            break;
                        case 2:
                            message.balance = reader.string();
                            break;
                        case 3:
                            message.unconfirmedInBalance = reader.string();
                            break;
                        case 4:
                            message.unconfirmedOutBalance = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Balance message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.ClientAddress.Balance
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.ClientAddress.Balance} Balance
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Balance.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Balance message.
                 * @function verify
                 * @memberof protocol.ClientAddress.Balance
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Balance.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.currency != null && message.hasOwnProperty("currency"))
                        switch (message.currency) {
                        default:
                            return "currency: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.balance != null && message.hasOwnProperty("balance"))
                        if (!$util.isString(message.balance))
                            return "balance: string expected";
                    if (message.unconfirmedInBalance != null && message.hasOwnProperty("unconfirmedInBalance"))
                        if (!$util.isString(message.unconfirmedInBalance))
                            return "unconfirmedInBalance: string expected";
                    if (message.unconfirmedOutBalance != null && message.hasOwnProperty("unconfirmedOutBalance"))
                        if (!$util.isString(message.unconfirmedOutBalance))
                            return "unconfirmedOutBalance: string expected";
                    return null;
                };
    
                /**
                 * Creates a Balance message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.ClientAddress.Balance
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.ClientAddress.Balance} Balance
                 */
                Balance.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.ClientAddress.Balance)
                        return object;
                    var message = new $root.protocol.ClientAddress.Balance();
                    switch (object.currency) {
                    case "UNK_CUR":
                    case 0:
                        message.currency = 0;
                        break;
                    case "BTC_CUR":
                    case 1:
                        message.currency = 1;
                        break;
                    case "ETH_CUR":
                    case 2:
                        message.currency = 2;
                        break;
                    case "TRX_CUR":
                    case 3:
                        message.currency = 3;
                        break;
                    case "ERC20_USDT_CUR":
                    case 4:
                        message.currency = 4;
                        break;
                    case "TRC20_USDT_CUR":
                    case 5:
                        message.currency = 5;
                        break;
                    }
                    if (object.balance != null)
                        message.balance = String(object.balance);
                    if (object.unconfirmedInBalance != null)
                        message.unconfirmedInBalance = String(object.unconfirmedInBalance);
                    if (object.unconfirmedOutBalance != null)
                        message.unconfirmedOutBalance = String(object.unconfirmedOutBalance);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Balance message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.ClientAddress.Balance
                 * @static
                 * @param {protocol.ClientAddress.Balance} message Balance
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Balance.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.currency = options.enums === String ? "UNK_CUR" : 0;
                        object.balance = "";
                        object.unconfirmedInBalance = "";
                        object.unconfirmedOutBalance = "";
                    }
                    if (message.currency != null && message.hasOwnProperty("currency"))
                        object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                    if (message.balance != null && message.hasOwnProperty("balance"))
                        object.balance = message.balance;
                    if (message.unconfirmedInBalance != null && message.hasOwnProperty("unconfirmedInBalance"))
                        object.unconfirmedInBalance = message.unconfirmedInBalance;
                    if (message.unconfirmedOutBalance != null && message.hasOwnProperty("unconfirmedOutBalance"))
                        object.unconfirmedOutBalance = message.unconfirmedOutBalance;
                    return object;
                };
    
                /**
                 * Converts this Balance to JSON.
                 * @function toJSON
                 * @memberof protocol.ClientAddress.Balance
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Balance.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Balance;
            })();
    
            return ClientAddress;
        })();
    
        protocol.ClientAddressesListRequest = (function() {
    
            /**
             * Properties of a ClientAddressesListRequest.
             * @memberof protocol
             * @interface IClientAddressesListRequest
             * @property {protocol.Currency|null} [currency] ClientAddressesListRequest currency
             * @property {Array.<string>|null} [tags] ClientAddressesListRequest tags
             * @property {boolean|null} [includeWithEmptyBalance] ClientAddressesListRequest includeWithEmptyBalance
             * @property {boolean|null} [includeSystemAddresses] ClientAddressesListRequest includeSystemAddresses
             */
    
            /**
             * Constructs a new ClientAddressesListRequest.
             * @memberof protocol
             * @classdesc Represents a ClientAddressesListRequest.
             * @implements IClientAddressesListRequest
             * @constructor
             * @param {protocol.IClientAddressesListRequest=} [properties] Properties to set
             */
            function ClientAddressesListRequest(properties) {
                this.tags = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientAddressesListRequest currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.ClientAddressesListRequest
             * @instance
             */
            ClientAddressesListRequest.prototype.currency = 0;
    
            /**
             * ClientAddressesListRequest tags.
             * @member {Array.<string>} tags
             * @memberof protocol.ClientAddressesListRequest
             * @instance
             */
            ClientAddressesListRequest.prototype.tags = $util.emptyArray;
    
            /**
             * ClientAddressesListRequest includeWithEmptyBalance.
             * @member {boolean} includeWithEmptyBalance
             * @memberof protocol.ClientAddressesListRequest
             * @instance
             */
            ClientAddressesListRequest.prototype.includeWithEmptyBalance = false;
    
            /**
             * ClientAddressesListRequest includeSystemAddresses.
             * @member {boolean} includeSystemAddresses
             * @memberof protocol.ClientAddressesListRequest
             * @instance
             */
            ClientAddressesListRequest.prototype.includeSystemAddresses = false;
    
            /**
             * Creates a new ClientAddressesListRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ClientAddressesListRequest
             * @static
             * @param {protocol.IClientAddressesListRequest=} [properties] Properties to set
             * @returns {protocol.ClientAddressesListRequest} ClientAddressesListRequest instance
             */
            ClientAddressesListRequest.create = function create(properties) {
                return new ClientAddressesListRequest(properties);
            };
    
            /**
             * Encodes the specified ClientAddressesListRequest message. Does not implicitly {@link protocol.ClientAddressesListRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientAddressesListRequest
             * @static
             * @param {protocol.IClientAddressesListRequest} message ClientAddressesListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientAddressesListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currency);
                if (message.tags != null && message.tags.length)
                    for (var i = 0; i < message.tags.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.tags[i]);
                if (message.includeWithEmptyBalance != null && Object.hasOwnProperty.call(message, "includeWithEmptyBalance"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.includeWithEmptyBalance);
                if (message.includeSystemAddresses != null && Object.hasOwnProperty.call(message, "includeSystemAddresses"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeSystemAddresses);
                return writer;
            };
    
            /**
             * Encodes the specified ClientAddressesListRequest message, length delimited. Does not implicitly {@link protocol.ClientAddressesListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientAddressesListRequest
             * @static
             * @param {protocol.IClientAddressesListRequest} message ClientAddressesListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientAddressesListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientAddressesListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientAddressesListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientAddressesListRequest} ClientAddressesListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientAddressesListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientAddressesListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.currency = reader.int32();
                        break;
                    case 2:
                        if (!(message.tags && message.tags.length))
                            message.tags = [];
                        message.tags.push(reader.string());
                        break;
                    case 3:
                        message.includeWithEmptyBalance = reader.bool();
                        break;
                    case 4:
                        message.includeSystemAddresses = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientAddressesListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientAddressesListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientAddressesListRequest} ClientAddressesListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientAddressesListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientAddressesListRequest message.
             * @function verify
             * @memberof protocol.ClientAddressesListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientAddressesListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.tags != null && message.hasOwnProperty("tags")) {
                    if (!Array.isArray(message.tags))
                        return "tags: array expected";
                    for (var i = 0; i < message.tags.length; ++i)
                        if (!$util.isString(message.tags[i]))
                            return "tags: string[] expected";
                }
                if (message.includeWithEmptyBalance != null && message.hasOwnProperty("includeWithEmptyBalance"))
                    if (typeof message.includeWithEmptyBalance !== "boolean")
                        return "includeWithEmptyBalance: boolean expected";
                if (message.includeSystemAddresses != null && message.hasOwnProperty("includeSystemAddresses"))
                    if (typeof message.includeSystemAddresses !== "boolean")
                        return "includeSystemAddresses: boolean expected";
                return null;
            };
    
            /**
             * Creates a ClientAddressesListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientAddressesListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientAddressesListRequest} ClientAddressesListRequest
             */
            ClientAddressesListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientAddressesListRequest)
                    return object;
                var message = new $root.protocol.ClientAddressesListRequest();
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                if (object.tags) {
                    if (!Array.isArray(object.tags))
                        throw TypeError(".protocol.ClientAddressesListRequest.tags: array expected");
                    message.tags = [];
                    for (var i = 0; i < object.tags.length; ++i)
                        message.tags[i] = String(object.tags[i]);
                }
                if (object.includeWithEmptyBalance != null)
                    message.includeWithEmptyBalance = Boolean(object.includeWithEmptyBalance);
                if (object.includeSystemAddresses != null)
                    message.includeSystemAddresses = Boolean(object.includeSystemAddresses);
                return message;
            };
    
            /**
             * Creates a plain object from a ClientAddressesListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientAddressesListRequest
             * @static
             * @param {protocol.ClientAddressesListRequest} message ClientAddressesListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientAddressesListRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.tags = [];
                if (options.defaults) {
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.includeWithEmptyBalance = false;
                    object.includeSystemAddresses = false;
                }
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.tags && message.tags.length) {
                    object.tags = [];
                    for (var j = 0; j < message.tags.length; ++j)
                        object.tags[j] = message.tags[j];
                }
                if (message.includeWithEmptyBalance != null && message.hasOwnProperty("includeWithEmptyBalance"))
                    object.includeWithEmptyBalance = message.includeWithEmptyBalance;
                if (message.includeSystemAddresses != null && message.hasOwnProperty("includeSystemAddresses"))
                    object.includeSystemAddresses = message.includeSystemAddresses;
                return object;
            };
    
            /**
             * Converts this ClientAddressesListRequest to JSON.
             * @function toJSON
             * @memberof protocol.ClientAddressesListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientAddressesListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientAddressesListRequest;
        })();
    
        protocol.ClientAddressesListResponse = (function() {
    
            /**
             * Properties of a ClientAddressesListResponse.
             * @memberof protocol
             * @interface IClientAddressesListResponse
             * @property {Array.<protocol.IClientAddress>|null} [addresses] ClientAddressesListResponse addresses
             */
    
            /**
             * Constructs a new ClientAddressesListResponse.
             * @memberof protocol
             * @classdesc Represents a ClientAddressesListResponse.
             * @implements IClientAddressesListResponse
             * @constructor
             * @param {protocol.IClientAddressesListResponse=} [properties] Properties to set
             */
            function ClientAddressesListResponse(properties) {
                this.addresses = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientAddressesListResponse addresses.
             * @member {Array.<protocol.IClientAddress>} addresses
             * @memberof protocol.ClientAddressesListResponse
             * @instance
             */
            ClientAddressesListResponse.prototype.addresses = $util.emptyArray;
    
            /**
             * Creates a new ClientAddressesListResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ClientAddressesListResponse
             * @static
             * @param {protocol.IClientAddressesListResponse=} [properties] Properties to set
             * @returns {protocol.ClientAddressesListResponse} ClientAddressesListResponse instance
             */
            ClientAddressesListResponse.create = function create(properties) {
                return new ClientAddressesListResponse(properties);
            };
    
            /**
             * Encodes the specified ClientAddressesListResponse message. Does not implicitly {@link protocol.ClientAddressesListResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientAddressesListResponse
             * @static
             * @param {protocol.IClientAddressesListResponse} message ClientAddressesListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientAddressesListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.addresses != null && message.addresses.length)
                    for (var i = 0; i < message.addresses.length; ++i)
                        $root.protocol.ClientAddress.encode(message.addresses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ClientAddressesListResponse message, length delimited. Does not implicitly {@link protocol.ClientAddressesListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientAddressesListResponse
             * @static
             * @param {protocol.IClientAddressesListResponse} message ClientAddressesListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientAddressesListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientAddressesListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientAddressesListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientAddressesListResponse} ClientAddressesListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientAddressesListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientAddressesListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.addresses && message.addresses.length))
                            message.addresses = [];
                        message.addresses.push($root.protocol.ClientAddress.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientAddressesListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientAddressesListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientAddressesListResponse} ClientAddressesListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientAddressesListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientAddressesListResponse message.
             * @function verify
             * @memberof protocol.ClientAddressesListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientAddressesListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.addresses != null && message.hasOwnProperty("addresses")) {
                    if (!Array.isArray(message.addresses))
                        return "addresses: array expected";
                    for (var i = 0; i < message.addresses.length; ++i) {
                        var error = $root.protocol.ClientAddress.verify(message.addresses[i]);
                        if (error)
                            return "addresses." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ClientAddressesListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientAddressesListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientAddressesListResponse} ClientAddressesListResponse
             */
            ClientAddressesListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientAddressesListResponse)
                    return object;
                var message = new $root.protocol.ClientAddressesListResponse();
                if (object.addresses) {
                    if (!Array.isArray(object.addresses))
                        throw TypeError(".protocol.ClientAddressesListResponse.addresses: array expected");
                    message.addresses = [];
                    for (var i = 0; i < object.addresses.length; ++i) {
                        if (typeof object.addresses[i] !== "object")
                            throw TypeError(".protocol.ClientAddressesListResponse.addresses: object expected");
                        message.addresses[i] = $root.protocol.ClientAddress.fromObject(object.addresses[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientAddressesListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientAddressesListResponse
             * @static
             * @param {protocol.ClientAddressesListResponse} message ClientAddressesListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientAddressesListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.addresses = [];
                if (message.addresses && message.addresses.length) {
                    object.addresses = [];
                    for (var j = 0; j < message.addresses.length; ++j)
                        object.addresses[j] = $root.protocol.ClientAddress.toObject(message.addresses[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ClientAddressesListResponse to JSON.
             * @function toJSON
             * @memberof protocol.ClientAddressesListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientAddressesListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientAddressesListResponse;
        })();
    
        protocol.CreateNewAddressRequest = (function() {
    
            /**
             * Properties of a CreateNewAddressRequest.
             * @memberof protocol
             * @interface ICreateNewAddressRequest
             * @property {string|null} [walletId] CreateNewAddressRequest walletId
             * @property {protocol.Currency|null} [currency] CreateNewAddressRequest currency
             */
    
            /**
             * Constructs a new CreateNewAddressRequest.
             * @memberof protocol
             * @classdesc Represents a CreateNewAddressRequest.
             * @implements ICreateNewAddressRequest
             * @constructor
             * @param {protocol.ICreateNewAddressRequest=} [properties] Properties to set
             */
            function CreateNewAddressRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CreateNewAddressRequest walletId.
             * @member {string} walletId
             * @memberof protocol.CreateNewAddressRequest
             * @instance
             */
            CreateNewAddressRequest.prototype.walletId = "";
    
            /**
             * CreateNewAddressRequest currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.CreateNewAddressRequest
             * @instance
             */
            CreateNewAddressRequest.prototype.currency = 0;
    
            /**
             * Creates a new CreateNewAddressRequest instance using the specified properties.
             * @function create
             * @memberof protocol.CreateNewAddressRequest
             * @static
             * @param {protocol.ICreateNewAddressRequest=} [properties] Properties to set
             * @returns {protocol.CreateNewAddressRequest} CreateNewAddressRequest instance
             */
            CreateNewAddressRequest.create = function create(properties) {
                return new CreateNewAddressRequest(properties);
            };
    
            /**
             * Encodes the specified CreateNewAddressRequest message. Does not implicitly {@link protocol.CreateNewAddressRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.CreateNewAddressRequest
             * @static
             * @param {protocol.ICreateNewAddressRequest} message CreateNewAddressRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateNewAddressRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.walletId != null && Object.hasOwnProperty.call(message, "walletId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.walletId);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currency);
                return writer;
            };
    
            /**
             * Encodes the specified CreateNewAddressRequest message, length delimited. Does not implicitly {@link protocol.CreateNewAddressRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.CreateNewAddressRequest
             * @static
             * @param {protocol.ICreateNewAddressRequest} message CreateNewAddressRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateNewAddressRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateNewAddressRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.CreateNewAddressRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.CreateNewAddressRequest} CreateNewAddressRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateNewAddressRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.CreateNewAddressRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.walletId = reader.string();
                        break;
                    case 2:
                        message.currency = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CreateNewAddressRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.CreateNewAddressRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.CreateNewAddressRequest} CreateNewAddressRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateNewAddressRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateNewAddressRequest message.
             * @function verify
             * @memberof protocol.CreateNewAddressRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateNewAddressRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.walletId != null && message.hasOwnProperty("walletId"))
                    if (!$util.isString(message.walletId))
                        return "walletId: string expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a CreateNewAddressRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.CreateNewAddressRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.CreateNewAddressRequest} CreateNewAddressRequest
             */
            CreateNewAddressRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.CreateNewAddressRequest)
                    return object;
                var message = new $root.protocol.CreateNewAddressRequest();
                if (object.walletId != null)
                    message.walletId = String(object.walletId);
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CreateNewAddressRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.CreateNewAddressRequest
             * @static
             * @param {protocol.CreateNewAddressRequest} message CreateNewAddressRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateNewAddressRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.walletId = "";
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                }
                if (message.walletId != null && message.hasOwnProperty("walletId"))
                    object.walletId = message.walletId;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                return object;
            };
    
            /**
             * Converts this CreateNewAddressRequest to JSON.
             * @function toJSON
             * @memberof protocol.CreateNewAddressRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateNewAddressRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return CreateNewAddressRequest;
        })();
    
        protocol.CreateNewAddressResponse = (function() {
    
            /**
             * Properties of a CreateNewAddressResponse.
             * @memberof protocol
             * @interface ICreateNewAddressResponse
             * @property {string|null} [walletId] CreateNewAddressResponse walletId
             * @property {protocol.Currency|null} [currency] CreateNewAddressResponse currency
             * @property {string|null} [address] CreateNewAddressResponse address
             */
    
            /**
             * Constructs a new CreateNewAddressResponse.
             * @memberof protocol
             * @classdesc Represents a CreateNewAddressResponse.
             * @implements ICreateNewAddressResponse
             * @constructor
             * @param {protocol.ICreateNewAddressResponse=} [properties] Properties to set
             */
            function CreateNewAddressResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CreateNewAddressResponse walletId.
             * @member {string} walletId
             * @memberof protocol.CreateNewAddressResponse
             * @instance
             */
            CreateNewAddressResponse.prototype.walletId = "";
    
            /**
             * CreateNewAddressResponse currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.CreateNewAddressResponse
             * @instance
             */
            CreateNewAddressResponse.prototype.currency = 0;
    
            /**
             * CreateNewAddressResponse address.
             * @member {string} address
             * @memberof protocol.CreateNewAddressResponse
             * @instance
             */
            CreateNewAddressResponse.prototype.address = "";
    
            /**
             * Creates a new CreateNewAddressResponse instance using the specified properties.
             * @function create
             * @memberof protocol.CreateNewAddressResponse
             * @static
             * @param {protocol.ICreateNewAddressResponse=} [properties] Properties to set
             * @returns {protocol.CreateNewAddressResponse} CreateNewAddressResponse instance
             */
            CreateNewAddressResponse.create = function create(properties) {
                return new CreateNewAddressResponse(properties);
            };
    
            /**
             * Encodes the specified CreateNewAddressResponse message. Does not implicitly {@link protocol.CreateNewAddressResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.CreateNewAddressResponse
             * @static
             * @param {protocol.ICreateNewAddressResponse} message CreateNewAddressResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateNewAddressResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.walletId != null && Object.hasOwnProperty.call(message, "walletId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.walletId);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currency);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.address);
                return writer;
            };
    
            /**
             * Encodes the specified CreateNewAddressResponse message, length delimited. Does not implicitly {@link protocol.CreateNewAddressResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.CreateNewAddressResponse
             * @static
             * @param {protocol.ICreateNewAddressResponse} message CreateNewAddressResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateNewAddressResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateNewAddressResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.CreateNewAddressResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.CreateNewAddressResponse} CreateNewAddressResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateNewAddressResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.CreateNewAddressResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.walletId = reader.string();
                        break;
                    case 2:
                        message.currency = reader.int32();
                        break;
                    case 3:
                        message.address = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CreateNewAddressResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.CreateNewAddressResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.CreateNewAddressResponse} CreateNewAddressResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateNewAddressResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateNewAddressResponse message.
             * @function verify
             * @memberof protocol.CreateNewAddressResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateNewAddressResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.walletId != null && message.hasOwnProperty("walletId"))
                    if (!$util.isString(message.walletId))
                        return "walletId: string expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                return null;
            };
    
            /**
             * Creates a CreateNewAddressResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.CreateNewAddressResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.CreateNewAddressResponse} CreateNewAddressResponse
             */
            CreateNewAddressResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.CreateNewAddressResponse)
                    return object;
                var message = new $root.protocol.CreateNewAddressResponse();
                if (object.walletId != null)
                    message.walletId = String(object.walletId);
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                if (object.address != null)
                    message.address = String(object.address);
                return message;
            };
    
            /**
             * Creates a plain object from a CreateNewAddressResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.CreateNewAddressResponse
             * @static
             * @param {protocol.CreateNewAddressResponse} message CreateNewAddressResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateNewAddressResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.walletId = "";
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.address = "";
                }
                if (message.walletId != null && message.hasOwnProperty("walletId"))
                    object.walletId = message.walletId;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                return object;
            };
    
            /**
             * Converts this CreateNewAddressResponse to JSON.
             * @function toJSON
             * @memberof protocol.CreateNewAddressResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateNewAddressResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return CreateNewAddressResponse;
        })();
    
        protocol.ClientTotalBalancesRequest = (function() {
    
            /**
             * Properties of a ClientTotalBalancesRequest.
             * @memberof protocol
             * @interface IClientTotalBalancesRequest
             * @property {protocol.Currency|null} [currency] ClientTotalBalancesRequest currency
             * @property {Array.<string>|null} [tags] ClientTotalBalancesRequest tags
             * @property {boolean|null} [spendable] ClientTotalBalancesRequest spendable
             * @property {Array.<string>|null} [addresses] ClientTotalBalancesRequest addresses
             */
    
            /**
             * Constructs a new ClientTotalBalancesRequest.
             * @memberof protocol
             * @classdesc Represents a ClientTotalBalancesRequest.
             * @implements IClientTotalBalancesRequest
             * @constructor
             * @param {protocol.IClientTotalBalancesRequest=} [properties] Properties to set
             */
            function ClientTotalBalancesRequest(properties) {
                this.tags = [];
                this.addresses = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientTotalBalancesRequest currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.ClientTotalBalancesRequest
             * @instance
             */
            ClientTotalBalancesRequest.prototype.currency = 0;
    
            /**
             * ClientTotalBalancesRequest tags.
             * @member {Array.<string>} tags
             * @memberof protocol.ClientTotalBalancesRequest
             * @instance
             */
            ClientTotalBalancesRequest.prototype.tags = $util.emptyArray;
    
            /**
             * ClientTotalBalancesRequest spendable.
             * @member {boolean} spendable
             * @memberof protocol.ClientTotalBalancesRequest
             * @instance
             */
            ClientTotalBalancesRequest.prototype.spendable = false;
    
            /**
             * ClientTotalBalancesRequest addresses.
             * @member {Array.<string>} addresses
             * @memberof protocol.ClientTotalBalancesRequest
             * @instance
             */
            ClientTotalBalancesRequest.prototype.addresses = $util.emptyArray;
    
            /**
             * Creates a new ClientTotalBalancesRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ClientTotalBalancesRequest
             * @static
             * @param {protocol.IClientTotalBalancesRequest=} [properties] Properties to set
             * @returns {protocol.ClientTotalBalancesRequest} ClientTotalBalancesRequest instance
             */
            ClientTotalBalancesRequest.create = function create(properties) {
                return new ClientTotalBalancesRequest(properties);
            };
    
            /**
             * Encodes the specified ClientTotalBalancesRequest message. Does not implicitly {@link protocol.ClientTotalBalancesRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientTotalBalancesRequest
             * @static
             * @param {protocol.IClientTotalBalancesRequest} message ClientTotalBalancesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientTotalBalancesRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currency);
                if (message.tags != null && message.tags.length)
                    for (var i = 0; i < message.tags.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.tags[i]);
                if (message.spendable != null && Object.hasOwnProperty.call(message, "spendable"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.spendable);
                if (message.addresses != null && message.addresses.length)
                    for (var i = 0; i < message.addresses.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.addresses[i]);
                return writer;
            };
    
            /**
             * Encodes the specified ClientTotalBalancesRequest message, length delimited. Does not implicitly {@link protocol.ClientTotalBalancesRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientTotalBalancesRequest
             * @static
             * @param {protocol.IClientTotalBalancesRequest} message ClientTotalBalancesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientTotalBalancesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientTotalBalancesRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientTotalBalancesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientTotalBalancesRequest} ClientTotalBalancesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientTotalBalancesRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientTotalBalancesRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.currency = reader.int32();
                        break;
                    case 2:
                        if (!(message.tags && message.tags.length))
                            message.tags = [];
                        message.tags.push(reader.string());
                        break;
                    case 3:
                        message.spendable = reader.bool();
                        break;
                    case 4:
                        if (!(message.addresses && message.addresses.length))
                            message.addresses = [];
                        message.addresses.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientTotalBalancesRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientTotalBalancesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientTotalBalancesRequest} ClientTotalBalancesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientTotalBalancesRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientTotalBalancesRequest message.
             * @function verify
             * @memberof protocol.ClientTotalBalancesRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientTotalBalancesRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.tags != null && message.hasOwnProperty("tags")) {
                    if (!Array.isArray(message.tags))
                        return "tags: array expected";
                    for (var i = 0; i < message.tags.length; ++i)
                        if (!$util.isString(message.tags[i]))
                            return "tags: string[] expected";
                }
                if (message.spendable != null && message.hasOwnProperty("spendable"))
                    if (typeof message.spendable !== "boolean")
                        return "spendable: boolean expected";
                if (message.addresses != null && message.hasOwnProperty("addresses")) {
                    if (!Array.isArray(message.addresses))
                        return "addresses: array expected";
                    for (var i = 0; i < message.addresses.length; ++i)
                        if (!$util.isString(message.addresses[i]))
                            return "addresses: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a ClientTotalBalancesRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientTotalBalancesRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientTotalBalancesRequest} ClientTotalBalancesRequest
             */
            ClientTotalBalancesRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientTotalBalancesRequest)
                    return object;
                var message = new $root.protocol.ClientTotalBalancesRequest();
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                if (object.tags) {
                    if (!Array.isArray(object.tags))
                        throw TypeError(".protocol.ClientTotalBalancesRequest.tags: array expected");
                    message.tags = [];
                    for (var i = 0; i < object.tags.length; ++i)
                        message.tags[i] = String(object.tags[i]);
                }
                if (object.spendable != null)
                    message.spendable = Boolean(object.spendable);
                if (object.addresses) {
                    if (!Array.isArray(object.addresses))
                        throw TypeError(".protocol.ClientTotalBalancesRequest.addresses: array expected");
                    message.addresses = [];
                    for (var i = 0; i < object.addresses.length; ++i)
                        message.addresses[i] = String(object.addresses[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientTotalBalancesRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientTotalBalancesRequest
             * @static
             * @param {protocol.ClientTotalBalancesRequest} message ClientTotalBalancesRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientTotalBalancesRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.tags = [];
                    object.addresses = [];
                }
                if (options.defaults) {
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.spendable = false;
                }
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.tags && message.tags.length) {
                    object.tags = [];
                    for (var j = 0; j < message.tags.length; ++j)
                        object.tags[j] = message.tags[j];
                }
                if (message.spendable != null && message.hasOwnProperty("spendable"))
                    object.spendable = message.spendable;
                if (message.addresses && message.addresses.length) {
                    object.addresses = [];
                    for (var j = 0; j < message.addresses.length; ++j)
                        object.addresses[j] = message.addresses[j];
                }
                return object;
            };
    
            /**
             * Converts this ClientTotalBalancesRequest to JSON.
             * @function toJSON
             * @memberof protocol.ClientTotalBalancesRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientTotalBalancesRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientTotalBalancesRequest;
        })();
    
        protocol.ClientTotalBalancesResponse = (function() {
    
            /**
             * Properties of a ClientTotalBalancesResponse.
             * @memberof protocol
             * @interface IClientTotalBalancesResponse
             * @property {Array.<protocol.ClientTotalBalancesResponse.IBalance>|null} [balances] ClientTotalBalancesResponse balances
             */
    
            /**
             * Constructs a new ClientTotalBalancesResponse.
             * @memberof protocol
             * @classdesc Represents a ClientTotalBalancesResponse.
             * @implements IClientTotalBalancesResponse
             * @constructor
             * @param {protocol.IClientTotalBalancesResponse=} [properties] Properties to set
             */
            function ClientTotalBalancesResponse(properties) {
                this.balances = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientTotalBalancesResponse balances.
             * @member {Array.<protocol.ClientTotalBalancesResponse.IBalance>} balances
             * @memberof protocol.ClientTotalBalancesResponse
             * @instance
             */
            ClientTotalBalancesResponse.prototype.balances = $util.emptyArray;
    
            /**
             * Creates a new ClientTotalBalancesResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ClientTotalBalancesResponse
             * @static
             * @param {protocol.IClientTotalBalancesResponse=} [properties] Properties to set
             * @returns {protocol.ClientTotalBalancesResponse} ClientTotalBalancesResponse instance
             */
            ClientTotalBalancesResponse.create = function create(properties) {
                return new ClientTotalBalancesResponse(properties);
            };
    
            /**
             * Encodes the specified ClientTotalBalancesResponse message. Does not implicitly {@link protocol.ClientTotalBalancesResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientTotalBalancesResponse
             * @static
             * @param {protocol.IClientTotalBalancesResponse} message ClientTotalBalancesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientTotalBalancesResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.balances != null && message.balances.length)
                    for (var i = 0; i < message.balances.length; ++i)
                        $root.protocol.ClientTotalBalancesResponse.Balance.encode(message.balances[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ClientTotalBalancesResponse message, length delimited. Does not implicitly {@link protocol.ClientTotalBalancesResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientTotalBalancesResponse
             * @static
             * @param {protocol.IClientTotalBalancesResponse} message ClientTotalBalancesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientTotalBalancesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientTotalBalancesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientTotalBalancesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientTotalBalancesResponse} ClientTotalBalancesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientTotalBalancesResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientTotalBalancesResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.balances && message.balances.length))
                            message.balances = [];
                        message.balances.push($root.protocol.ClientTotalBalancesResponse.Balance.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientTotalBalancesResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientTotalBalancesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientTotalBalancesResponse} ClientTotalBalancesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientTotalBalancesResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientTotalBalancesResponse message.
             * @function verify
             * @memberof protocol.ClientTotalBalancesResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientTotalBalancesResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.balances != null && message.hasOwnProperty("balances")) {
                    if (!Array.isArray(message.balances))
                        return "balances: array expected";
                    for (var i = 0; i < message.balances.length; ++i) {
                        var error = $root.protocol.ClientTotalBalancesResponse.Balance.verify(message.balances[i]);
                        if (error)
                            return "balances." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ClientTotalBalancesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientTotalBalancesResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientTotalBalancesResponse} ClientTotalBalancesResponse
             */
            ClientTotalBalancesResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientTotalBalancesResponse)
                    return object;
                var message = new $root.protocol.ClientTotalBalancesResponse();
                if (object.balances) {
                    if (!Array.isArray(object.balances))
                        throw TypeError(".protocol.ClientTotalBalancesResponse.balances: array expected");
                    message.balances = [];
                    for (var i = 0; i < object.balances.length; ++i) {
                        if (typeof object.balances[i] !== "object")
                            throw TypeError(".protocol.ClientTotalBalancesResponse.balances: object expected");
                        message.balances[i] = $root.protocol.ClientTotalBalancesResponse.Balance.fromObject(object.balances[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientTotalBalancesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientTotalBalancesResponse
             * @static
             * @param {protocol.ClientTotalBalancesResponse} message ClientTotalBalancesResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientTotalBalancesResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.balances = [];
                if (message.balances && message.balances.length) {
                    object.balances = [];
                    for (var j = 0; j < message.balances.length; ++j)
                        object.balances[j] = $root.protocol.ClientTotalBalancesResponse.Balance.toObject(message.balances[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ClientTotalBalancesResponse to JSON.
             * @function toJSON
             * @memberof protocol.ClientTotalBalancesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientTotalBalancesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            ClientTotalBalancesResponse.Balance = (function() {
    
                /**
                 * Properties of a Balance.
                 * @memberof protocol.ClientTotalBalancesResponse
                 * @interface IBalance
                 * @property {protocol.Currency|null} [currency] Balance currency
                 * @property {string|null} [balance] Balance balance
                 * @property {Object.<string,string>|null} [balanceFiat] Balance balanceFiat
                 * @property {string|null} [unconfirmedInBalance] Balance unconfirmedInBalance
                 * @property {Object.<string,string>|null} [unconfirmedInBalanceFiat] Balance unconfirmedInBalanceFiat
                 * @property {string|null} [unconfirmedOutBalance] Balance unconfirmedOutBalance
                 * @property {Object.<string,string>|null} [unconfirmedOutBalanceFiat] Balance unconfirmedOutBalanceFiat
                 */
    
                /**
                 * Constructs a new Balance.
                 * @memberof protocol.ClientTotalBalancesResponse
                 * @classdesc Represents a Balance.
                 * @implements IBalance
                 * @constructor
                 * @param {protocol.ClientTotalBalancesResponse.IBalance=} [properties] Properties to set
                 */
                function Balance(properties) {
                    this.balanceFiat = {};
                    this.unconfirmedInBalanceFiat = {};
                    this.unconfirmedOutBalanceFiat = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Balance currency.
                 * @member {protocol.Currency} currency
                 * @memberof protocol.ClientTotalBalancesResponse.Balance
                 * @instance
                 */
                Balance.prototype.currency = 0;
    
                /**
                 * Balance balance.
                 * @member {string} balance
                 * @memberof protocol.ClientTotalBalancesResponse.Balance
                 * @instance
                 */
                Balance.prototype.balance = "";
    
                /**
                 * Balance balanceFiat.
                 * @member {Object.<string,string>} balanceFiat
                 * @memberof protocol.ClientTotalBalancesResponse.Balance
                 * @instance
                 */
                Balance.prototype.balanceFiat = $util.emptyObject;
    
                /**
                 * Balance unconfirmedInBalance.
                 * @member {string} unconfirmedInBalance
                 * @memberof protocol.ClientTotalBalancesResponse.Balance
                 * @instance
                 */
                Balance.prototype.unconfirmedInBalance = "";
    
                /**
                 * Balance unconfirmedInBalanceFiat.
                 * @member {Object.<string,string>} unconfirmedInBalanceFiat
                 * @memberof protocol.ClientTotalBalancesResponse.Balance
                 * @instance
                 */
                Balance.prototype.unconfirmedInBalanceFiat = $util.emptyObject;
    
                /**
                 * Balance unconfirmedOutBalance.
                 * @member {string} unconfirmedOutBalance
                 * @memberof protocol.ClientTotalBalancesResponse.Balance
                 * @instance
                 */
                Balance.prototype.unconfirmedOutBalance = "";
    
                /**
                 * Balance unconfirmedOutBalanceFiat.
                 * @member {Object.<string,string>} unconfirmedOutBalanceFiat
                 * @memberof protocol.ClientTotalBalancesResponse.Balance
                 * @instance
                 */
                Balance.prototype.unconfirmedOutBalanceFiat = $util.emptyObject;
    
                /**
                 * Creates a new Balance instance using the specified properties.
                 * @function create
                 * @memberof protocol.ClientTotalBalancesResponse.Balance
                 * @static
                 * @param {protocol.ClientTotalBalancesResponse.IBalance=} [properties] Properties to set
                 * @returns {protocol.ClientTotalBalancesResponse.Balance} Balance instance
                 */
                Balance.create = function create(properties) {
                    return new Balance(properties);
                };
    
                /**
                 * Encodes the specified Balance message. Does not implicitly {@link protocol.ClientTotalBalancesResponse.Balance.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.ClientTotalBalancesResponse.Balance
                 * @static
                 * @param {protocol.ClientTotalBalancesResponse.IBalance} message Balance message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Balance.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currency);
                    if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.balance);
                    if (message.balanceFiat != null && Object.hasOwnProperty.call(message, "balanceFiat"))
                        for (var keys = Object.keys(message.balanceFiat), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.balanceFiat[keys[i]]).ldelim();
                    if (message.unconfirmedInBalance != null && Object.hasOwnProperty.call(message, "unconfirmedInBalance"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.unconfirmedInBalance);
                    if (message.unconfirmedInBalanceFiat != null && Object.hasOwnProperty.call(message, "unconfirmedInBalanceFiat"))
                        for (var keys = Object.keys(message.unconfirmedInBalanceFiat), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.unconfirmedInBalanceFiat[keys[i]]).ldelim();
                    if (message.unconfirmedOutBalance != null && Object.hasOwnProperty.call(message, "unconfirmedOutBalance"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.unconfirmedOutBalance);
                    if (message.unconfirmedOutBalanceFiat != null && Object.hasOwnProperty.call(message, "unconfirmedOutBalanceFiat"))
                        for (var keys = Object.keys(message.unconfirmedOutBalanceFiat), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 7, wireType 2 =*/58).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.unconfirmedOutBalanceFiat[keys[i]]).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Balance message, length delimited. Does not implicitly {@link protocol.ClientTotalBalancesResponse.Balance.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.ClientTotalBalancesResponse.Balance
                 * @static
                 * @param {protocol.ClientTotalBalancesResponse.IBalance} message Balance message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Balance.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Balance message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.ClientTotalBalancesResponse.Balance
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.ClientTotalBalancesResponse.Balance} Balance
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Balance.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientTotalBalancesResponse.Balance(), key;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.currency = reader.int32();
                            break;
                        case 2:
                            message.balance = reader.string();
                            break;
                        case 3:
                            reader.skip().pos++;
                            if (message.balanceFiat === $util.emptyObject)
                                message.balanceFiat = {};
                            key = reader.string();
                            reader.pos++;
                            message.balanceFiat[key] = reader.string();
                            break;
                        case 4:
                            message.unconfirmedInBalance = reader.string();
                            break;
                        case 5:
                            reader.skip().pos++;
                            if (message.unconfirmedInBalanceFiat === $util.emptyObject)
                                message.unconfirmedInBalanceFiat = {};
                            key = reader.string();
                            reader.pos++;
                            message.unconfirmedInBalanceFiat[key] = reader.string();
                            break;
                        case 6:
                            message.unconfirmedOutBalance = reader.string();
                            break;
                        case 7:
                            reader.skip().pos++;
                            if (message.unconfirmedOutBalanceFiat === $util.emptyObject)
                                message.unconfirmedOutBalanceFiat = {};
                            key = reader.string();
                            reader.pos++;
                            message.unconfirmedOutBalanceFiat[key] = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Balance message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.ClientTotalBalancesResponse.Balance
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.ClientTotalBalancesResponse.Balance} Balance
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Balance.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Balance message.
                 * @function verify
                 * @memberof protocol.ClientTotalBalancesResponse.Balance
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Balance.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.currency != null && message.hasOwnProperty("currency"))
                        switch (message.currency) {
                        default:
                            return "currency: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.balance != null && message.hasOwnProperty("balance"))
                        if (!$util.isString(message.balance))
                            return "balance: string expected";
                    if (message.balanceFiat != null && message.hasOwnProperty("balanceFiat")) {
                        if (!$util.isObject(message.balanceFiat))
                            return "balanceFiat: object expected";
                        var key = Object.keys(message.balanceFiat);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isString(message.balanceFiat[key[i]]))
                                return "balanceFiat: string{k:string} expected";
                    }
                    if (message.unconfirmedInBalance != null && message.hasOwnProperty("unconfirmedInBalance"))
                        if (!$util.isString(message.unconfirmedInBalance))
                            return "unconfirmedInBalance: string expected";
                    if (message.unconfirmedInBalanceFiat != null && message.hasOwnProperty("unconfirmedInBalanceFiat")) {
                        if (!$util.isObject(message.unconfirmedInBalanceFiat))
                            return "unconfirmedInBalanceFiat: object expected";
                        var key = Object.keys(message.unconfirmedInBalanceFiat);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isString(message.unconfirmedInBalanceFiat[key[i]]))
                                return "unconfirmedInBalanceFiat: string{k:string} expected";
                    }
                    if (message.unconfirmedOutBalance != null && message.hasOwnProperty("unconfirmedOutBalance"))
                        if (!$util.isString(message.unconfirmedOutBalance))
                            return "unconfirmedOutBalance: string expected";
                    if (message.unconfirmedOutBalanceFiat != null && message.hasOwnProperty("unconfirmedOutBalanceFiat")) {
                        if (!$util.isObject(message.unconfirmedOutBalanceFiat))
                            return "unconfirmedOutBalanceFiat: object expected";
                        var key = Object.keys(message.unconfirmedOutBalanceFiat);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isString(message.unconfirmedOutBalanceFiat[key[i]]))
                                return "unconfirmedOutBalanceFiat: string{k:string} expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a Balance message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.ClientTotalBalancesResponse.Balance
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.ClientTotalBalancesResponse.Balance} Balance
                 */
                Balance.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.ClientTotalBalancesResponse.Balance)
                        return object;
                    var message = new $root.protocol.ClientTotalBalancesResponse.Balance();
                    switch (object.currency) {
                    case "UNK_CUR":
                    case 0:
                        message.currency = 0;
                        break;
                    case "BTC_CUR":
                    case 1:
                        message.currency = 1;
                        break;
                    case "ETH_CUR":
                    case 2:
                        message.currency = 2;
                        break;
                    case "TRX_CUR":
                    case 3:
                        message.currency = 3;
                        break;
                    case "ERC20_USDT_CUR":
                    case 4:
                        message.currency = 4;
                        break;
                    case "TRC20_USDT_CUR":
                    case 5:
                        message.currency = 5;
                        break;
                    }
                    if (object.balance != null)
                        message.balance = String(object.balance);
                    if (object.balanceFiat) {
                        if (typeof object.balanceFiat !== "object")
                            throw TypeError(".protocol.ClientTotalBalancesResponse.Balance.balanceFiat: object expected");
                        message.balanceFiat = {};
                        for (var keys = Object.keys(object.balanceFiat), i = 0; i < keys.length; ++i)
                            message.balanceFiat[keys[i]] = String(object.balanceFiat[keys[i]]);
                    }
                    if (object.unconfirmedInBalance != null)
                        message.unconfirmedInBalance = String(object.unconfirmedInBalance);
                    if (object.unconfirmedInBalanceFiat) {
                        if (typeof object.unconfirmedInBalanceFiat !== "object")
                            throw TypeError(".protocol.ClientTotalBalancesResponse.Balance.unconfirmedInBalanceFiat: object expected");
                        message.unconfirmedInBalanceFiat = {};
                        for (var keys = Object.keys(object.unconfirmedInBalanceFiat), i = 0; i < keys.length; ++i)
                            message.unconfirmedInBalanceFiat[keys[i]] = String(object.unconfirmedInBalanceFiat[keys[i]]);
                    }
                    if (object.unconfirmedOutBalance != null)
                        message.unconfirmedOutBalance = String(object.unconfirmedOutBalance);
                    if (object.unconfirmedOutBalanceFiat) {
                        if (typeof object.unconfirmedOutBalanceFiat !== "object")
                            throw TypeError(".protocol.ClientTotalBalancesResponse.Balance.unconfirmedOutBalanceFiat: object expected");
                        message.unconfirmedOutBalanceFiat = {};
                        for (var keys = Object.keys(object.unconfirmedOutBalanceFiat), i = 0; i < keys.length; ++i)
                            message.unconfirmedOutBalanceFiat[keys[i]] = String(object.unconfirmedOutBalanceFiat[keys[i]]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Balance message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.ClientTotalBalancesResponse.Balance
                 * @static
                 * @param {protocol.ClientTotalBalancesResponse.Balance} message Balance
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Balance.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults) {
                        object.balanceFiat = {};
                        object.unconfirmedInBalanceFiat = {};
                        object.unconfirmedOutBalanceFiat = {};
                    }
                    if (options.defaults) {
                        object.currency = options.enums === String ? "UNK_CUR" : 0;
                        object.balance = "";
                        object.unconfirmedInBalance = "";
                        object.unconfirmedOutBalance = "";
                    }
                    if (message.currency != null && message.hasOwnProperty("currency"))
                        object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                    if (message.balance != null && message.hasOwnProperty("balance"))
                        object.balance = message.balance;
                    var keys2;
                    if (message.balanceFiat && (keys2 = Object.keys(message.balanceFiat)).length) {
                        object.balanceFiat = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.balanceFiat[keys2[j]] = message.balanceFiat[keys2[j]];
                    }
                    if (message.unconfirmedInBalance != null && message.hasOwnProperty("unconfirmedInBalance"))
                        object.unconfirmedInBalance = message.unconfirmedInBalance;
                    if (message.unconfirmedInBalanceFiat && (keys2 = Object.keys(message.unconfirmedInBalanceFiat)).length) {
                        object.unconfirmedInBalanceFiat = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.unconfirmedInBalanceFiat[keys2[j]] = message.unconfirmedInBalanceFiat[keys2[j]];
                    }
                    if (message.unconfirmedOutBalance != null && message.hasOwnProperty("unconfirmedOutBalance"))
                        object.unconfirmedOutBalance = message.unconfirmedOutBalance;
                    if (message.unconfirmedOutBalanceFiat && (keys2 = Object.keys(message.unconfirmedOutBalanceFiat)).length) {
                        object.unconfirmedOutBalanceFiat = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.unconfirmedOutBalanceFiat[keys2[j]] = message.unconfirmedOutBalanceFiat[keys2[j]];
                    }
                    return object;
                };
    
                /**
                 * Converts this Balance to JSON.
                 * @function toJSON
                 * @memberof protocol.ClientTotalBalancesResponse.Balance
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Balance.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Balance;
            })();
    
            return ClientTotalBalancesResponse;
        })();
    
        protocol.BalanceRecord = (function() {
    
            /**
             * Properties of a BalanceRecord.
             * @memberof protocol
             * @interface IBalanceRecord
             * @property {string|null} [address] BalanceRecord address
             * @property {protocol.Currency|null} [currency] BalanceRecord currency
             * @property {string|null} [balance] BalanceRecord balance
             * @property {string|null} [unconfirmedInBalance] BalanceRecord unconfirmedInBalance
             * @property {string|null} [unconfirmedOutBalance] BalanceRecord unconfirmedOutBalance
             * @property {string|null} [walletId] BalanceRecord walletId
             * @property {string|null} [clientId] BalanceRecord clientId
             */
    
            /**
             * Constructs a new BalanceRecord.
             * @memberof protocol
             * @classdesc Represents a BalanceRecord.
             * @implements IBalanceRecord
             * @constructor
             * @param {protocol.IBalanceRecord=} [properties] Properties to set
             */
            function BalanceRecord(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BalanceRecord address.
             * @member {string} address
             * @memberof protocol.BalanceRecord
             * @instance
             */
            BalanceRecord.prototype.address = "";
    
            /**
             * BalanceRecord currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.BalanceRecord
             * @instance
             */
            BalanceRecord.prototype.currency = 0;
    
            /**
             * BalanceRecord balance.
             * @member {string} balance
             * @memberof protocol.BalanceRecord
             * @instance
             */
            BalanceRecord.prototype.balance = "";
    
            /**
             * BalanceRecord unconfirmedInBalance.
             * @member {string} unconfirmedInBalance
             * @memberof protocol.BalanceRecord
             * @instance
             */
            BalanceRecord.prototype.unconfirmedInBalance = "";
    
            /**
             * BalanceRecord unconfirmedOutBalance.
             * @member {string} unconfirmedOutBalance
             * @memberof protocol.BalanceRecord
             * @instance
             */
            BalanceRecord.prototype.unconfirmedOutBalance = "";
    
            /**
             * BalanceRecord walletId.
             * @member {string} walletId
             * @memberof protocol.BalanceRecord
             * @instance
             */
            BalanceRecord.prototype.walletId = "";
    
            /**
             * BalanceRecord clientId.
             * @member {string} clientId
             * @memberof protocol.BalanceRecord
             * @instance
             */
            BalanceRecord.prototype.clientId = "";
    
            /**
             * Creates a new BalanceRecord instance using the specified properties.
             * @function create
             * @memberof protocol.BalanceRecord
             * @static
             * @param {protocol.IBalanceRecord=} [properties] Properties to set
             * @returns {protocol.BalanceRecord} BalanceRecord instance
             */
            BalanceRecord.create = function create(properties) {
                return new BalanceRecord(properties);
            };
    
            /**
             * Encodes the specified BalanceRecord message. Does not implicitly {@link protocol.BalanceRecord.verify|verify} messages.
             * @function encode
             * @memberof protocol.BalanceRecord
             * @static
             * @param {protocol.IBalanceRecord} message BalanceRecord message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalanceRecord.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currency);
                if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.balance);
                if (message.unconfirmedInBalance != null && Object.hasOwnProperty.call(message, "unconfirmedInBalance"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.unconfirmedInBalance);
                if (message.unconfirmedOutBalance != null && Object.hasOwnProperty.call(message, "unconfirmedOutBalance"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.unconfirmedOutBalance);
                if (message.walletId != null && Object.hasOwnProperty.call(message, "walletId"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.walletId);
                if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.clientId);
                return writer;
            };
    
            /**
             * Encodes the specified BalanceRecord message, length delimited. Does not implicitly {@link protocol.BalanceRecord.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.BalanceRecord
             * @static
             * @param {protocol.IBalanceRecord} message BalanceRecord message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalanceRecord.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BalanceRecord message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.BalanceRecord
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.BalanceRecord} BalanceRecord
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalanceRecord.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.BalanceRecord();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.address = reader.string();
                        break;
                    case 2:
                        message.currency = reader.int32();
                        break;
                    case 3:
                        message.balance = reader.string();
                        break;
                    case 4:
                        message.unconfirmedInBalance = reader.string();
                        break;
                    case 5:
                        message.unconfirmedOutBalance = reader.string();
                        break;
                    case 6:
                        message.walletId = reader.string();
                        break;
                    case 7:
                        message.clientId = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BalanceRecord message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.BalanceRecord
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.BalanceRecord} BalanceRecord
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalanceRecord.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BalanceRecord message.
             * @function verify
             * @memberof protocol.BalanceRecord
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BalanceRecord.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.balance != null && message.hasOwnProperty("balance"))
                    if (!$util.isString(message.balance))
                        return "balance: string expected";
                if (message.unconfirmedInBalance != null && message.hasOwnProperty("unconfirmedInBalance"))
                    if (!$util.isString(message.unconfirmedInBalance))
                        return "unconfirmedInBalance: string expected";
                if (message.unconfirmedOutBalance != null && message.hasOwnProperty("unconfirmedOutBalance"))
                    if (!$util.isString(message.unconfirmedOutBalance))
                        return "unconfirmedOutBalance: string expected";
                if (message.walletId != null && message.hasOwnProperty("walletId"))
                    if (!$util.isString(message.walletId))
                        return "walletId: string expected";
                if (message.clientId != null && message.hasOwnProperty("clientId"))
                    if (!$util.isString(message.clientId))
                        return "clientId: string expected";
                return null;
            };
    
            /**
             * Creates a BalanceRecord message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.BalanceRecord
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.BalanceRecord} BalanceRecord
             */
            BalanceRecord.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.BalanceRecord)
                    return object;
                var message = new $root.protocol.BalanceRecord();
                if (object.address != null)
                    message.address = String(object.address);
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                if (object.balance != null)
                    message.balance = String(object.balance);
                if (object.unconfirmedInBalance != null)
                    message.unconfirmedInBalance = String(object.unconfirmedInBalance);
                if (object.unconfirmedOutBalance != null)
                    message.unconfirmedOutBalance = String(object.unconfirmedOutBalance);
                if (object.walletId != null)
                    message.walletId = String(object.walletId);
                if (object.clientId != null)
                    message.clientId = String(object.clientId);
                return message;
            };
    
            /**
             * Creates a plain object from a BalanceRecord message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.BalanceRecord
             * @static
             * @param {protocol.BalanceRecord} message BalanceRecord
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BalanceRecord.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.address = "";
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.balance = "";
                    object.unconfirmedInBalance = "";
                    object.unconfirmedOutBalance = "";
                    object.walletId = "";
                    object.clientId = "";
                }
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.balance != null && message.hasOwnProperty("balance"))
                    object.balance = message.balance;
                if (message.unconfirmedInBalance != null && message.hasOwnProperty("unconfirmedInBalance"))
                    object.unconfirmedInBalance = message.unconfirmedInBalance;
                if (message.unconfirmedOutBalance != null && message.hasOwnProperty("unconfirmedOutBalance"))
                    object.unconfirmedOutBalance = message.unconfirmedOutBalance;
                if (message.walletId != null && message.hasOwnProperty("walletId"))
                    object.walletId = message.walletId;
                if (message.clientId != null && message.hasOwnProperty("clientId"))
                    object.clientId = message.clientId;
                return object;
            };
    
            /**
             * Converts this BalanceRecord to JSON.
             * @function toJSON
             * @memberof protocol.BalanceRecord
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BalanceRecord.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return BalanceRecord;
        })();
    
        protocol.BalancesRequest = (function() {
    
            /**
             * Properties of a BalancesRequest.
             * @memberof protocol
             * @interface IBalancesRequest
             * @property {string|null} [walletId] BalancesRequest walletId
             * @property {string|null} [address] BalancesRequest address
             * @property {string|null} [clientId] BalancesRequest clientId
             */
    
            /**
             * Constructs a new BalancesRequest.
             * @memberof protocol
             * @classdesc Represents a BalancesRequest.
             * @implements IBalancesRequest
             * @constructor
             * @param {protocol.IBalancesRequest=} [properties] Properties to set
             */
            function BalancesRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BalancesRequest walletId.
             * @member {string} walletId
             * @memberof protocol.BalancesRequest
             * @instance
             */
            BalancesRequest.prototype.walletId = "";
    
            /**
             * BalancesRequest address.
             * @member {string} address
             * @memberof protocol.BalancesRequest
             * @instance
             */
            BalancesRequest.prototype.address = "";
    
            /**
             * BalancesRequest clientId.
             * @member {string} clientId
             * @memberof protocol.BalancesRequest
             * @instance
             */
            BalancesRequest.prototype.clientId = "";
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * BalancesRequest addr.
             * @member {"walletId"|"address"|"clientId"|undefined} addr
             * @memberof protocol.BalancesRequest
             * @instance
             */
            Object.defineProperty(BalancesRequest.prototype, "addr", {
                get: $util.oneOfGetter($oneOfFields = ["walletId", "address", "clientId"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new BalancesRequest instance using the specified properties.
             * @function create
             * @memberof protocol.BalancesRequest
             * @static
             * @param {protocol.IBalancesRequest=} [properties] Properties to set
             * @returns {protocol.BalancesRequest} BalancesRequest instance
             */
            BalancesRequest.create = function create(properties) {
                return new BalancesRequest(properties);
            };
    
            /**
             * Encodes the specified BalancesRequest message. Does not implicitly {@link protocol.BalancesRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.BalancesRequest
             * @static
             * @param {protocol.IBalancesRequest} message BalancesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalancesRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.walletId != null && Object.hasOwnProperty.call(message, "walletId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.walletId);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
                if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.clientId);
                return writer;
            };
    
            /**
             * Encodes the specified BalancesRequest message, length delimited. Does not implicitly {@link protocol.BalancesRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.BalancesRequest
             * @static
             * @param {protocol.IBalancesRequest} message BalancesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalancesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BalancesRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.BalancesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.BalancesRequest} BalancesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalancesRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.BalancesRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.walletId = reader.string();
                        break;
                    case 2:
                        message.address = reader.string();
                        break;
                    case 3:
                        message.clientId = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BalancesRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.BalancesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.BalancesRequest} BalancesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalancesRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BalancesRequest message.
             * @function verify
             * @memberof protocol.BalancesRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BalancesRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.walletId != null && message.hasOwnProperty("walletId")) {
                    properties.addr = 1;
                    if (!$util.isString(message.walletId))
                        return "walletId: string expected";
                }
                if (message.address != null && message.hasOwnProperty("address")) {
                    if (properties.addr === 1)
                        return "addr: multiple values";
                    properties.addr = 1;
                    if (!$util.isString(message.address))
                        return "address: string expected";
                }
                if (message.clientId != null && message.hasOwnProperty("clientId")) {
                    if (properties.addr === 1)
                        return "addr: multiple values";
                    properties.addr = 1;
                    if (!$util.isString(message.clientId))
                        return "clientId: string expected";
                }
                return null;
            };
    
            /**
             * Creates a BalancesRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.BalancesRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.BalancesRequest} BalancesRequest
             */
            BalancesRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.BalancesRequest)
                    return object;
                var message = new $root.protocol.BalancesRequest();
                if (object.walletId != null)
                    message.walletId = String(object.walletId);
                if (object.address != null)
                    message.address = String(object.address);
                if (object.clientId != null)
                    message.clientId = String(object.clientId);
                return message;
            };
    
            /**
             * Creates a plain object from a BalancesRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.BalancesRequest
             * @static
             * @param {protocol.BalancesRequest} message BalancesRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BalancesRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.walletId != null && message.hasOwnProperty("walletId")) {
                    object.walletId = message.walletId;
                    if (options.oneofs)
                        object.addr = "walletId";
                }
                if (message.address != null && message.hasOwnProperty("address")) {
                    object.address = message.address;
                    if (options.oneofs)
                        object.addr = "address";
                }
                if (message.clientId != null && message.hasOwnProperty("clientId")) {
                    object.clientId = message.clientId;
                    if (options.oneofs)
                        object.addr = "clientId";
                }
                return object;
            };
    
            /**
             * Converts this BalancesRequest to JSON.
             * @function toJSON
             * @memberof protocol.BalancesRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BalancesRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return BalancesRequest;
        })();
    
        protocol.BalancesResponse = (function() {
    
            /**
             * Properties of a BalancesResponse.
             * @memberof protocol
             * @interface IBalancesResponse
             * @property {Array.<protocol.IBalanceRecord>|null} [balances] BalancesResponse balances
             */
    
            /**
             * Constructs a new BalancesResponse.
             * @memberof protocol
             * @classdesc Represents a BalancesResponse.
             * @implements IBalancesResponse
             * @constructor
             * @param {protocol.IBalancesResponse=} [properties] Properties to set
             */
            function BalancesResponse(properties) {
                this.balances = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BalancesResponse balances.
             * @member {Array.<protocol.IBalanceRecord>} balances
             * @memberof protocol.BalancesResponse
             * @instance
             */
            BalancesResponse.prototype.balances = $util.emptyArray;
    
            /**
             * Creates a new BalancesResponse instance using the specified properties.
             * @function create
             * @memberof protocol.BalancesResponse
             * @static
             * @param {protocol.IBalancesResponse=} [properties] Properties to set
             * @returns {protocol.BalancesResponse} BalancesResponse instance
             */
            BalancesResponse.create = function create(properties) {
                return new BalancesResponse(properties);
            };
    
            /**
             * Encodes the specified BalancesResponse message. Does not implicitly {@link protocol.BalancesResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.BalancesResponse
             * @static
             * @param {protocol.IBalancesResponse} message BalancesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalancesResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.balances != null && message.balances.length)
                    for (var i = 0; i < message.balances.length; ++i)
                        $root.protocol.BalanceRecord.encode(message.balances[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified BalancesResponse message, length delimited. Does not implicitly {@link protocol.BalancesResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.BalancesResponse
             * @static
             * @param {protocol.IBalancesResponse} message BalancesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BalancesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BalancesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.BalancesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.BalancesResponse} BalancesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalancesResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.BalancesResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.balances && message.balances.length))
                            message.balances = [];
                        message.balances.push($root.protocol.BalanceRecord.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BalancesResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.BalancesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.BalancesResponse} BalancesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BalancesResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BalancesResponse message.
             * @function verify
             * @memberof protocol.BalancesResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BalancesResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.balances != null && message.hasOwnProperty("balances")) {
                    if (!Array.isArray(message.balances))
                        return "balances: array expected";
                    for (var i = 0; i < message.balances.length; ++i) {
                        var error = $root.protocol.BalanceRecord.verify(message.balances[i]);
                        if (error)
                            return "balances." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a BalancesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.BalancesResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.BalancesResponse} BalancesResponse
             */
            BalancesResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.BalancesResponse)
                    return object;
                var message = new $root.protocol.BalancesResponse();
                if (object.balances) {
                    if (!Array.isArray(object.balances))
                        throw TypeError(".protocol.BalancesResponse.balances: array expected");
                    message.balances = [];
                    for (var i = 0; i < object.balances.length; ++i) {
                        if (typeof object.balances[i] !== "object")
                            throw TypeError(".protocol.BalancesResponse.balances: object expected");
                        message.balances[i] = $root.protocol.BalanceRecord.fromObject(object.balances[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a BalancesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.BalancesResponse
             * @static
             * @param {protocol.BalancesResponse} message BalancesResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BalancesResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.balances = [];
                if (message.balances && message.balances.length) {
                    object.balances = [];
                    for (var j = 0; j < message.balances.length; ++j)
                        object.balances[j] = $root.protocol.BalanceRecord.toObject(message.balances[j], options);
                }
                return object;
            };
    
            /**
             * Converts this BalancesResponse to JSON.
             * @function toJSON
             * @memberof protocol.BalancesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BalancesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return BalancesResponse;
        })();
    
        protocol.CheckTokenPayoutSystemBalanceRequest = (function() {
    
            /**
             * Properties of a CheckTokenPayoutSystemBalanceRequest.
             * @memberof protocol
             * @interface ICheckTokenPayoutSystemBalanceRequest
             * @property {string|null} [walletId] CheckTokenPayoutSystemBalanceRequest walletId
             * @property {string|null} [address] CheckTokenPayoutSystemBalanceRequest address
             * @property {protocol.Currency|null} [currency] CheckTokenPayoutSystemBalanceRequest currency
             * @property {string|null} [to] CheckTokenPayoutSystemBalanceRequest to
             * @property {string|null} [amount] CheckTokenPayoutSystemBalanceRequest amount
             * @property {Array.<string>|null} [tagsToWithdrawFrom] CheckTokenPayoutSystemBalanceRequest tagsToWithdrawFrom
             * @property {Array.<string>|null} [networkFeeAddresses] CheckTokenPayoutSystemBalanceRequest networkFeeAddresses
             * @property {boolean|null} [activateNewTronAddress] CheckTokenPayoutSystemBalanceRequest activateNewTronAddress
             * @property {Array.<string>|null} [addressesToWithdrawFrom] CheckTokenPayoutSystemBalanceRequest addressesToWithdrawFrom
             */
    
            /**
             * Constructs a new CheckTokenPayoutSystemBalanceRequest.
             * @memberof protocol
             * @classdesc Represents a CheckTokenPayoutSystemBalanceRequest.
             * @implements ICheckTokenPayoutSystemBalanceRequest
             * @constructor
             * @param {protocol.ICheckTokenPayoutSystemBalanceRequest=} [properties] Properties to set
             */
            function CheckTokenPayoutSystemBalanceRequest(properties) {
                this.tagsToWithdrawFrom = [];
                this.networkFeeAddresses = [];
                this.addressesToWithdrawFrom = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CheckTokenPayoutSystemBalanceRequest walletId.
             * @member {string} walletId
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @instance
             */
            CheckTokenPayoutSystemBalanceRequest.prototype.walletId = "";
    
            /**
             * CheckTokenPayoutSystemBalanceRequest address.
             * @member {string} address
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @instance
             */
            CheckTokenPayoutSystemBalanceRequest.prototype.address = "";
    
            /**
             * CheckTokenPayoutSystemBalanceRequest currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @instance
             */
            CheckTokenPayoutSystemBalanceRequest.prototype.currency = 0;
    
            /**
             * CheckTokenPayoutSystemBalanceRequest to.
             * @member {string} to
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @instance
             */
            CheckTokenPayoutSystemBalanceRequest.prototype.to = "";
    
            /**
             * CheckTokenPayoutSystemBalanceRequest amount.
             * @member {string} amount
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @instance
             */
            CheckTokenPayoutSystemBalanceRequest.prototype.amount = "";
    
            /**
             * CheckTokenPayoutSystemBalanceRequest tagsToWithdrawFrom.
             * @member {Array.<string>} tagsToWithdrawFrom
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @instance
             */
            CheckTokenPayoutSystemBalanceRequest.prototype.tagsToWithdrawFrom = $util.emptyArray;
    
            /**
             * CheckTokenPayoutSystemBalanceRequest networkFeeAddresses.
             * @member {Array.<string>} networkFeeAddresses
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @instance
             */
            CheckTokenPayoutSystemBalanceRequest.prototype.networkFeeAddresses = $util.emptyArray;
    
            /**
             * CheckTokenPayoutSystemBalanceRequest activateNewTronAddress.
             * @member {boolean} activateNewTronAddress
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @instance
             */
            CheckTokenPayoutSystemBalanceRequest.prototype.activateNewTronAddress = false;
    
            /**
             * CheckTokenPayoutSystemBalanceRequest addressesToWithdrawFrom.
             * @member {Array.<string>} addressesToWithdrawFrom
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @instance
             */
            CheckTokenPayoutSystemBalanceRequest.prototype.addressesToWithdrawFrom = $util.emptyArray;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * CheckTokenPayoutSystemBalanceRequest addr.
             * @member {"walletId"|"address"|undefined} addr
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @instance
             */
            Object.defineProperty(CheckTokenPayoutSystemBalanceRequest.prototype, "addr", {
                get: $util.oneOfGetter($oneOfFields = ["walletId", "address"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new CheckTokenPayoutSystemBalanceRequest instance using the specified properties.
             * @function create
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @static
             * @param {protocol.ICheckTokenPayoutSystemBalanceRequest=} [properties] Properties to set
             * @returns {protocol.CheckTokenPayoutSystemBalanceRequest} CheckTokenPayoutSystemBalanceRequest instance
             */
            CheckTokenPayoutSystemBalanceRequest.create = function create(properties) {
                return new CheckTokenPayoutSystemBalanceRequest(properties);
            };
    
            /**
             * Encodes the specified CheckTokenPayoutSystemBalanceRequest message. Does not implicitly {@link protocol.CheckTokenPayoutSystemBalanceRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @static
             * @param {protocol.ICheckTokenPayoutSystemBalanceRequest} message CheckTokenPayoutSystemBalanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckTokenPayoutSystemBalanceRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.walletId != null && Object.hasOwnProperty.call(message, "walletId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.walletId);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.currency);
                if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.to);
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.amount);
                if (message.tagsToWithdrawFrom != null && message.tagsToWithdrawFrom.length)
                    for (var i = 0; i < message.tagsToWithdrawFrom.length; ++i)
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.tagsToWithdrawFrom[i]);
                if (message.networkFeeAddresses != null && message.networkFeeAddresses.length)
                    for (var i = 0; i < message.networkFeeAddresses.length; ++i)
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.networkFeeAddresses[i]);
                if (message.activateNewTronAddress != null && Object.hasOwnProperty.call(message, "activateNewTronAddress"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.activateNewTronAddress);
                if (message.addressesToWithdrawFrom != null && message.addressesToWithdrawFrom.length)
                    for (var i = 0; i < message.addressesToWithdrawFrom.length; ++i)
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.addressesToWithdrawFrom[i]);
                return writer;
            };
    
            /**
             * Encodes the specified CheckTokenPayoutSystemBalanceRequest message, length delimited. Does not implicitly {@link protocol.CheckTokenPayoutSystemBalanceRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @static
             * @param {protocol.ICheckTokenPayoutSystemBalanceRequest} message CheckTokenPayoutSystemBalanceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckTokenPayoutSystemBalanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CheckTokenPayoutSystemBalanceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.CheckTokenPayoutSystemBalanceRequest} CheckTokenPayoutSystemBalanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckTokenPayoutSystemBalanceRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.CheckTokenPayoutSystemBalanceRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.walletId = reader.string();
                        break;
                    case 2:
                        message.address = reader.string();
                        break;
                    case 3:
                        message.currency = reader.int32();
                        break;
                    case 4:
                        message.to = reader.string();
                        break;
                    case 5:
                        message.amount = reader.string();
                        break;
                    case 6:
                        if (!(message.tagsToWithdrawFrom && message.tagsToWithdrawFrom.length))
                            message.tagsToWithdrawFrom = [];
                        message.tagsToWithdrawFrom.push(reader.string());
                        break;
                    case 7:
                        if (!(message.networkFeeAddresses && message.networkFeeAddresses.length))
                            message.networkFeeAddresses = [];
                        message.networkFeeAddresses.push(reader.string());
                        break;
                    case 8:
                        message.activateNewTronAddress = reader.bool();
                        break;
                    case 9:
                        if (!(message.addressesToWithdrawFrom && message.addressesToWithdrawFrom.length))
                            message.addressesToWithdrawFrom = [];
                        message.addressesToWithdrawFrom.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CheckTokenPayoutSystemBalanceRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.CheckTokenPayoutSystemBalanceRequest} CheckTokenPayoutSystemBalanceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckTokenPayoutSystemBalanceRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CheckTokenPayoutSystemBalanceRequest message.
             * @function verify
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CheckTokenPayoutSystemBalanceRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.walletId != null && message.hasOwnProperty("walletId")) {
                    properties.addr = 1;
                    if (!$util.isString(message.walletId))
                        return "walletId: string expected";
                }
                if (message.address != null && message.hasOwnProperty("address")) {
                    if (properties.addr === 1)
                        return "addr: multiple values";
                    properties.addr = 1;
                    if (!$util.isString(message.address))
                        return "address: string expected";
                }
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.to != null && message.hasOwnProperty("to"))
                    if (!$util.isString(message.to))
                        return "to: string expected";
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (!$util.isString(message.amount))
                        return "amount: string expected";
                if (message.tagsToWithdrawFrom != null && message.hasOwnProperty("tagsToWithdrawFrom")) {
                    if (!Array.isArray(message.tagsToWithdrawFrom))
                        return "tagsToWithdrawFrom: array expected";
                    for (var i = 0; i < message.tagsToWithdrawFrom.length; ++i)
                        if (!$util.isString(message.tagsToWithdrawFrom[i]))
                            return "tagsToWithdrawFrom: string[] expected";
                }
                if (message.networkFeeAddresses != null && message.hasOwnProperty("networkFeeAddresses")) {
                    if (!Array.isArray(message.networkFeeAddresses))
                        return "networkFeeAddresses: array expected";
                    for (var i = 0; i < message.networkFeeAddresses.length; ++i)
                        if (!$util.isString(message.networkFeeAddresses[i]))
                            return "networkFeeAddresses: string[] expected";
                }
                if (message.activateNewTronAddress != null && message.hasOwnProperty("activateNewTronAddress"))
                    if (typeof message.activateNewTronAddress !== "boolean")
                        return "activateNewTronAddress: boolean expected";
                if (message.addressesToWithdrawFrom != null && message.hasOwnProperty("addressesToWithdrawFrom")) {
                    if (!Array.isArray(message.addressesToWithdrawFrom))
                        return "addressesToWithdrawFrom: array expected";
                    for (var i = 0; i < message.addressesToWithdrawFrom.length; ++i)
                        if (!$util.isString(message.addressesToWithdrawFrom[i]))
                            return "addressesToWithdrawFrom: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a CheckTokenPayoutSystemBalanceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.CheckTokenPayoutSystemBalanceRequest} CheckTokenPayoutSystemBalanceRequest
             */
            CheckTokenPayoutSystemBalanceRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.CheckTokenPayoutSystemBalanceRequest)
                    return object;
                var message = new $root.protocol.CheckTokenPayoutSystemBalanceRequest();
                if (object.walletId != null)
                    message.walletId = String(object.walletId);
                if (object.address != null)
                    message.address = String(object.address);
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                if (object.to != null)
                    message.to = String(object.to);
                if (object.amount != null)
                    message.amount = String(object.amount);
                if (object.tagsToWithdrawFrom) {
                    if (!Array.isArray(object.tagsToWithdrawFrom))
                        throw TypeError(".protocol.CheckTokenPayoutSystemBalanceRequest.tagsToWithdrawFrom: array expected");
                    message.tagsToWithdrawFrom = [];
                    for (var i = 0; i < object.tagsToWithdrawFrom.length; ++i)
                        message.tagsToWithdrawFrom[i] = String(object.tagsToWithdrawFrom[i]);
                }
                if (object.networkFeeAddresses) {
                    if (!Array.isArray(object.networkFeeAddresses))
                        throw TypeError(".protocol.CheckTokenPayoutSystemBalanceRequest.networkFeeAddresses: array expected");
                    message.networkFeeAddresses = [];
                    for (var i = 0; i < object.networkFeeAddresses.length; ++i)
                        message.networkFeeAddresses[i] = String(object.networkFeeAddresses[i]);
                }
                if (object.activateNewTronAddress != null)
                    message.activateNewTronAddress = Boolean(object.activateNewTronAddress);
                if (object.addressesToWithdrawFrom) {
                    if (!Array.isArray(object.addressesToWithdrawFrom))
                        throw TypeError(".protocol.CheckTokenPayoutSystemBalanceRequest.addressesToWithdrawFrom: array expected");
                    message.addressesToWithdrawFrom = [];
                    for (var i = 0; i < object.addressesToWithdrawFrom.length; ++i)
                        message.addressesToWithdrawFrom[i] = String(object.addressesToWithdrawFrom[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CheckTokenPayoutSystemBalanceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @static
             * @param {protocol.CheckTokenPayoutSystemBalanceRequest} message CheckTokenPayoutSystemBalanceRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CheckTokenPayoutSystemBalanceRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.tagsToWithdrawFrom = [];
                    object.networkFeeAddresses = [];
                    object.addressesToWithdrawFrom = [];
                }
                if (options.defaults) {
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.to = "";
                    object.amount = "";
                    object.activateNewTronAddress = false;
                }
                if (message.walletId != null && message.hasOwnProperty("walletId")) {
                    object.walletId = message.walletId;
                    if (options.oneofs)
                        object.addr = "walletId";
                }
                if (message.address != null && message.hasOwnProperty("address")) {
                    object.address = message.address;
                    if (options.oneofs)
                        object.addr = "address";
                }
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.to != null && message.hasOwnProperty("to"))
                    object.to = message.to;
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = message.amount;
                if (message.tagsToWithdrawFrom && message.tagsToWithdrawFrom.length) {
                    object.tagsToWithdrawFrom = [];
                    for (var j = 0; j < message.tagsToWithdrawFrom.length; ++j)
                        object.tagsToWithdrawFrom[j] = message.tagsToWithdrawFrom[j];
                }
                if (message.networkFeeAddresses && message.networkFeeAddresses.length) {
                    object.networkFeeAddresses = [];
                    for (var j = 0; j < message.networkFeeAddresses.length; ++j)
                        object.networkFeeAddresses[j] = message.networkFeeAddresses[j];
                }
                if (message.activateNewTronAddress != null && message.hasOwnProperty("activateNewTronAddress"))
                    object.activateNewTronAddress = message.activateNewTronAddress;
                if (message.addressesToWithdrawFrom && message.addressesToWithdrawFrom.length) {
                    object.addressesToWithdrawFrom = [];
                    for (var j = 0; j < message.addressesToWithdrawFrom.length; ++j)
                        object.addressesToWithdrawFrom[j] = message.addressesToWithdrawFrom[j];
                }
                return object;
            };
    
            /**
             * Converts this CheckTokenPayoutSystemBalanceRequest to JSON.
             * @function toJSON
             * @memberof protocol.CheckTokenPayoutSystemBalanceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CheckTokenPayoutSystemBalanceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return CheckTokenPayoutSystemBalanceRequest;
        })();
    
        protocol.CheckTokenPayoutSystemBalanceResponse = (function() {
    
            /**
             * Properties of a CheckTokenPayoutSystemBalanceResponse.
             * @memberof protocol
             * @interface ICheckTokenPayoutSystemBalanceResponse
             * @property {string|null} [requiredAmount] CheckTokenPayoutSystemBalanceResponse requiredAmount
             * @property {Array.<protocol.IPRSystemAddress>|null} [refillSystemAddresses] CheckTokenPayoutSystemBalanceResponse refillSystemAddresses
             * @property {Array.<protocol.CheckTokenPayoutSystemBalanceResponse.ISelectedTokenAddress>|null} [selectedTokenAddresses] CheckTokenPayoutSystemBalanceResponse selectedTokenAddresses
             * @property {Array.<protocol.CheckTokenPayoutSystemBalanceResponse.ISelectedNetworkFeeAddress>|null} [selectedNetworkFeeAddresses] CheckTokenPayoutSystemBalanceResponse selectedNetworkFeeAddresses
             */
    
            /**
             * Constructs a new CheckTokenPayoutSystemBalanceResponse.
             * @memberof protocol
             * @classdesc Represents a CheckTokenPayoutSystemBalanceResponse.
             * @implements ICheckTokenPayoutSystemBalanceResponse
             * @constructor
             * @param {protocol.ICheckTokenPayoutSystemBalanceResponse=} [properties] Properties to set
             */
            function CheckTokenPayoutSystemBalanceResponse(properties) {
                this.refillSystemAddresses = [];
                this.selectedTokenAddresses = [];
                this.selectedNetworkFeeAddresses = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CheckTokenPayoutSystemBalanceResponse requiredAmount.
             * @member {string} requiredAmount
             * @memberof protocol.CheckTokenPayoutSystemBalanceResponse
             * @instance
             */
            CheckTokenPayoutSystemBalanceResponse.prototype.requiredAmount = "";
    
            /**
             * CheckTokenPayoutSystemBalanceResponse refillSystemAddresses.
             * @member {Array.<protocol.IPRSystemAddress>} refillSystemAddresses
             * @memberof protocol.CheckTokenPayoutSystemBalanceResponse
             * @instance
             */
            CheckTokenPayoutSystemBalanceResponse.prototype.refillSystemAddresses = $util.emptyArray;
    
            /**
             * CheckTokenPayoutSystemBalanceResponse selectedTokenAddresses.
             * @member {Array.<protocol.CheckTokenPayoutSystemBalanceResponse.ISelectedTokenAddress>} selectedTokenAddresses
             * @memberof protocol.CheckTokenPayoutSystemBalanceResponse
             * @instance
             */
            CheckTokenPayoutSystemBalanceResponse.prototype.selectedTokenAddresses = $util.emptyArray;
    
            /**
             * CheckTokenPayoutSystemBalanceResponse selectedNetworkFeeAddresses.
             * @member {Array.<protocol.CheckTokenPayoutSystemBalanceResponse.ISelectedNetworkFeeAddress>} selectedNetworkFeeAddresses
             * @memberof protocol.CheckTokenPayoutSystemBalanceResponse
             * @instance
             */
            CheckTokenPayoutSystemBalanceResponse.prototype.selectedNetworkFeeAddresses = $util.emptyArray;
    
            /**
             * Creates a new CheckTokenPayoutSystemBalanceResponse instance using the specified properties.
             * @function create
             * @memberof protocol.CheckTokenPayoutSystemBalanceResponse
             * @static
             * @param {protocol.ICheckTokenPayoutSystemBalanceResponse=} [properties] Properties to set
             * @returns {protocol.CheckTokenPayoutSystemBalanceResponse} CheckTokenPayoutSystemBalanceResponse instance
             */
            CheckTokenPayoutSystemBalanceResponse.create = function create(properties) {
                return new CheckTokenPayoutSystemBalanceResponse(properties);
            };
    
            /**
             * Encodes the specified CheckTokenPayoutSystemBalanceResponse message. Does not implicitly {@link protocol.CheckTokenPayoutSystemBalanceResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.CheckTokenPayoutSystemBalanceResponse
             * @static
             * @param {protocol.ICheckTokenPayoutSystemBalanceResponse} message CheckTokenPayoutSystemBalanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckTokenPayoutSystemBalanceResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.requiredAmount != null && Object.hasOwnProperty.call(message, "requiredAmount"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.requiredAmount);
                if (message.refillSystemAddresses != null && message.refillSystemAddresses.length)
                    for (var i = 0; i < message.refillSystemAddresses.length; ++i)
                        $root.protocol.PRSystemAddress.encode(message.refillSystemAddresses[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.selectedTokenAddresses != null && message.selectedTokenAddresses.length)
                    for (var i = 0; i < message.selectedTokenAddresses.length; ++i)
                        $root.protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress.encode(message.selectedTokenAddresses[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.selectedNetworkFeeAddresses != null && message.selectedNetworkFeeAddresses.length)
                    for (var i = 0; i < message.selectedNetworkFeeAddresses.length; ++i)
                        $root.protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress.encode(message.selectedNetworkFeeAddresses[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified CheckTokenPayoutSystemBalanceResponse message, length delimited. Does not implicitly {@link protocol.CheckTokenPayoutSystemBalanceResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.CheckTokenPayoutSystemBalanceResponse
             * @static
             * @param {protocol.ICheckTokenPayoutSystemBalanceResponse} message CheckTokenPayoutSystemBalanceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckTokenPayoutSystemBalanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CheckTokenPayoutSystemBalanceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.CheckTokenPayoutSystemBalanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.CheckTokenPayoutSystemBalanceResponse} CheckTokenPayoutSystemBalanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckTokenPayoutSystemBalanceResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.CheckTokenPayoutSystemBalanceResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.requiredAmount = reader.string();
                        break;
                    case 2:
                        if (!(message.refillSystemAddresses && message.refillSystemAddresses.length))
                            message.refillSystemAddresses = [];
                        message.refillSystemAddresses.push($root.protocol.PRSystemAddress.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        if (!(message.selectedTokenAddresses && message.selectedTokenAddresses.length))
                            message.selectedTokenAddresses = [];
                        message.selectedTokenAddresses.push($root.protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress.decode(reader, reader.uint32()));
                        break;
                    case 4:
                        if (!(message.selectedNetworkFeeAddresses && message.selectedNetworkFeeAddresses.length))
                            message.selectedNetworkFeeAddresses = [];
                        message.selectedNetworkFeeAddresses.push($root.protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CheckTokenPayoutSystemBalanceResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.CheckTokenPayoutSystemBalanceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.CheckTokenPayoutSystemBalanceResponse} CheckTokenPayoutSystemBalanceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckTokenPayoutSystemBalanceResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CheckTokenPayoutSystemBalanceResponse message.
             * @function verify
             * @memberof protocol.CheckTokenPayoutSystemBalanceResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CheckTokenPayoutSystemBalanceResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.requiredAmount != null && message.hasOwnProperty("requiredAmount"))
                    if (!$util.isString(message.requiredAmount))
                        return "requiredAmount: string expected";
                if (message.refillSystemAddresses != null && message.hasOwnProperty("refillSystemAddresses")) {
                    if (!Array.isArray(message.refillSystemAddresses))
                        return "refillSystemAddresses: array expected";
                    for (var i = 0; i < message.refillSystemAddresses.length; ++i) {
                        var error = $root.protocol.PRSystemAddress.verify(message.refillSystemAddresses[i]);
                        if (error)
                            return "refillSystemAddresses." + error;
                    }
                }
                if (message.selectedTokenAddresses != null && message.hasOwnProperty("selectedTokenAddresses")) {
                    if (!Array.isArray(message.selectedTokenAddresses))
                        return "selectedTokenAddresses: array expected";
                    for (var i = 0; i < message.selectedTokenAddresses.length; ++i) {
                        var error = $root.protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress.verify(message.selectedTokenAddresses[i]);
                        if (error)
                            return "selectedTokenAddresses." + error;
                    }
                }
                if (message.selectedNetworkFeeAddresses != null && message.hasOwnProperty("selectedNetworkFeeAddresses")) {
                    if (!Array.isArray(message.selectedNetworkFeeAddresses))
                        return "selectedNetworkFeeAddresses: array expected";
                    for (var i = 0; i < message.selectedNetworkFeeAddresses.length; ++i) {
                        var error = $root.protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress.verify(message.selectedNetworkFeeAddresses[i]);
                        if (error)
                            return "selectedNetworkFeeAddresses." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a CheckTokenPayoutSystemBalanceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.CheckTokenPayoutSystemBalanceResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.CheckTokenPayoutSystemBalanceResponse} CheckTokenPayoutSystemBalanceResponse
             */
            CheckTokenPayoutSystemBalanceResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.CheckTokenPayoutSystemBalanceResponse)
                    return object;
                var message = new $root.protocol.CheckTokenPayoutSystemBalanceResponse();
                if (object.requiredAmount != null)
                    message.requiredAmount = String(object.requiredAmount);
                if (object.refillSystemAddresses) {
                    if (!Array.isArray(object.refillSystemAddresses))
                        throw TypeError(".protocol.CheckTokenPayoutSystemBalanceResponse.refillSystemAddresses: array expected");
                    message.refillSystemAddresses = [];
                    for (var i = 0; i < object.refillSystemAddresses.length; ++i) {
                        if (typeof object.refillSystemAddresses[i] !== "object")
                            throw TypeError(".protocol.CheckTokenPayoutSystemBalanceResponse.refillSystemAddresses: object expected");
                        message.refillSystemAddresses[i] = $root.protocol.PRSystemAddress.fromObject(object.refillSystemAddresses[i]);
                    }
                }
                if (object.selectedTokenAddresses) {
                    if (!Array.isArray(object.selectedTokenAddresses))
                        throw TypeError(".protocol.CheckTokenPayoutSystemBalanceResponse.selectedTokenAddresses: array expected");
                    message.selectedTokenAddresses = [];
                    for (var i = 0; i < object.selectedTokenAddresses.length; ++i) {
                        if (typeof object.selectedTokenAddresses[i] !== "object")
                            throw TypeError(".protocol.CheckTokenPayoutSystemBalanceResponse.selectedTokenAddresses: object expected");
                        message.selectedTokenAddresses[i] = $root.protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress.fromObject(object.selectedTokenAddresses[i]);
                    }
                }
                if (object.selectedNetworkFeeAddresses) {
                    if (!Array.isArray(object.selectedNetworkFeeAddresses))
                        throw TypeError(".protocol.CheckTokenPayoutSystemBalanceResponse.selectedNetworkFeeAddresses: array expected");
                    message.selectedNetworkFeeAddresses = [];
                    for (var i = 0; i < object.selectedNetworkFeeAddresses.length; ++i) {
                        if (typeof object.selectedNetworkFeeAddresses[i] !== "object")
                            throw TypeError(".protocol.CheckTokenPayoutSystemBalanceResponse.selectedNetworkFeeAddresses: object expected");
                        message.selectedNetworkFeeAddresses[i] = $root.protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress.fromObject(object.selectedNetworkFeeAddresses[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CheckTokenPayoutSystemBalanceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.CheckTokenPayoutSystemBalanceResponse
             * @static
             * @param {protocol.CheckTokenPayoutSystemBalanceResponse} message CheckTokenPayoutSystemBalanceResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CheckTokenPayoutSystemBalanceResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.refillSystemAddresses = [];
                    object.selectedTokenAddresses = [];
                    object.selectedNetworkFeeAddresses = [];
                }
                if (options.defaults)
                    object.requiredAmount = "";
                if (message.requiredAmount != null && message.hasOwnProperty("requiredAmount"))
                    object.requiredAmount = message.requiredAmount;
                if (message.refillSystemAddresses && message.refillSystemAddresses.length) {
                    object.refillSystemAddresses = [];
                    for (var j = 0; j < message.refillSystemAddresses.length; ++j)
                        object.refillSystemAddresses[j] = $root.protocol.PRSystemAddress.toObject(message.refillSystemAddresses[j], options);
                }
                if (message.selectedTokenAddresses && message.selectedTokenAddresses.length) {
                    object.selectedTokenAddresses = [];
                    for (var j = 0; j < message.selectedTokenAddresses.length; ++j)
                        object.selectedTokenAddresses[j] = $root.protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress.toObject(message.selectedTokenAddresses[j], options);
                }
                if (message.selectedNetworkFeeAddresses && message.selectedNetworkFeeAddresses.length) {
                    object.selectedNetworkFeeAddresses = [];
                    for (var j = 0; j < message.selectedNetworkFeeAddresses.length; ++j)
                        object.selectedNetworkFeeAddresses[j] = $root.protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress.toObject(message.selectedNetworkFeeAddresses[j], options);
                }
                return object;
            };
    
            /**
             * Converts this CheckTokenPayoutSystemBalanceResponse to JSON.
             * @function toJSON
             * @memberof protocol.CheckTokenPayoutSystemBalanceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CheckTokenPayoutSystemBalanceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress = (function() {
    
                /**
                 * Properties of a SelectedTokenAddress.
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse
                 * @interface ISelectedTokenAddress
                 * @property {string|null} [address] SelectedTokenAddress address
                 * @property {string|null} [amount] SelectedTokenAddress amount
                 */
    
                /**
                 * Constructs a new SelectedTokenAddress.
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse
                 * @classdesc Represents a SelectedTokenAddress.
                 * @implements ISelectedTokenAddress
                 * @constructor
                 * @param {protocol.CheckTokenPayoutSystemBalanceResponse.ISelectedTokenAddress=} [properties] Properties to set
                 */
                function SelectedTokenAddress(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SelectedTokenAddress address.
                 * @member {string} address
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress
                 * @instance
                 */
                SelectedTokenAddress.prototype.address = "";
    
                /**
                 * SelectedTokenAddress amount.
                 * @member {string} amount
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress
                 * @instance
                 */
                SelectedTokenAddress.prototype.amount = "";
    
                /**
                 * Creates a new SelectedTokenAddress instance using the specified properties.
                 * @function create
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress
                 * @static
                 * @param {protocol.CheckTokenPayoutSystemBalanceResponse.ISelectedTokenAddress=} [properties] Properties to set
                 * @returns {protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress} SelectedTokenAddress instance
                 */
                SelectedTokenAddress.create = function create(properties) {
                    return new SelectedTokenAddress(properties);
                };
    
                /**
                 * Encodes the specified SelectedTokenAddress message. Does not implicitly {@link protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress
                 * @static
                 * @param {protocol.CheckTokenPayoutSystemBalanceResponse.ISelectedTokenAddress} message SelectedTokenAddress message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SelectedTokenAddress.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
                    if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.amount);
                    return writer;
                };
    
                /**
                 * Encodes the specified SelectedTokenAddress message, length delimited. Does not implicitly {@link protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress
                 * @static
                 * @param {protocol.CheckTokenPayoutSystemBalanceResponse.ISelectedTokenAddress} message SelectedTokenAddress message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SelectedTokenAddress.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SelectedTokenAddress message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress} SelectedTokenAddress
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SelectedTokenAddress.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.address = reader.string();
                            break;
                        case 2:
                            message.amount = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SelectedTokenAddress message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress} SelectedTokenAddress
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SelectedTokenAddress.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SelectedTokenAddress message.
                 * @function verify
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SelectedTokenAddress.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        if (!$util.isString(message.amount))
                            return "amount: string expected";
                    return null;
                };
    
                /**
                 * Creates a SelectedTokenAddress message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress} SelectedTokenAddress
                 */
                SelectedTokenAddress.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress)
                        return object;
                    var message = new $root.protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress();
                    if (object.address != null)
                        message.address = String(object.address);
                    if (object.amount != null)
                        message.amount = String(object.amount);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SelectedTokenAddress message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress
                 * @static
                 * @param {protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress} message SelectedTokenAddress
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SelectedTokenAddress.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.address = "";
                        object.amount = "";
                    }
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        object.amount = message.amount;
                    return object;
                };
    
                /**
                 * Converts this SelectedTokenAddress to JSON.
                 * @function toJSON
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedTokenAddress
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SelectedTokenAddress.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return SelectedTokenAddress;
            })();
    
            CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress = (function() {
    
                /**
                 * Properties of a SelectedNetworkFeeAddress.
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse
                 * @interface ISelectedNetworkFeeAddress
                 * @property {string|null} [address] SelectedNetworkFeeAddress address
                 * @property {string|null} [feeAmount] SelectedNetworkFeeAddress feeAmount
                 * @property {string|null} [tag] SelectedNetworkFeeAddress tag
                 */
    
                /**
                 * Constructs a new SelectedNetworkFeeAddress.
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse
                 * @classdesc Represents a SelectedNetworkFeeAddress.
                 * @implements ISelectedNetworkFeeAddress
                 * @constructor
                 * @param {protocol.CheckTokenPayoutSystemBalanceResponse.ISelectedNetworkFeeAddress=} [properties] Properties to set
                 */
                function SelectedNetworkFeeAddress(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SelectedNetworkFeeAddress address.
                 * @member {string} address
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress
                 * @instance
                 */
                SelectedNetworkFeeAddress.prototype.address = "";
    
                /**
                 * SelectedNetworkFeeAddress feeAmount.
                 * @member {string} feeAmount
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress
                 * @instance
                 */
                SelectedNetworkFeeAddress.prototype.feeAmount = "";
    
                /**
                 * SelectedNetworkFeeAddress tag.
                 * @member {string} tag
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress
                 * @instance
                 */
                SelectedNetworkFeeAddress.prototype.tag = "";
    
                /**
                 * Creates a new SelectedNetworkFeeAddress instance using the specified properties.
                 * @function create
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress
                 * @static
                 * @param {protocol.CheckTokenPayoutSystemBalanceResponse.ISelectedNetworkFeeAddress=} [properties] Properties to set
                 * @returns {protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress} SelectedNetworkFeeAddress instance
                 */
                SelectedNetworkFeeAddress.create = function create(properties) {
                    return new SelectedNetworkFeeAddress(properties);
                };
    
                /**
                 * Encodes the specified SelectedNetworkFeeAddress message. Does not implicitly {@link protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress
                 * @static
                 * @param {protocol.CheckTokenPayoutSystemBalanceResponse.ISelectedNetworkFeeAddress} message SelectedNetworkFeeAddress message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SelectedNetworkFeeAddress.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
                    if (message.feeAmount != null && Object.hasOwnProperty.call(message, "feeAmount"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.feeAmount);
                    if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.tag);
                    return writer;
                };
    
                /**
                 * Encodes the specified SelectedNetworkFeeAddress message, length delimited. Does not implicitly {@link protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress
                 * @static
                 * @param {protocol.CheckTokenPayoutSystemBalanceResponse.ISelectedNetworkFeeAddress} message SelectedNetworkFeeAddress message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SelectedNetworkFeeAddress.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SelectedNetworkFeeAddress message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress} SelectedNetworkFeeAddress
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SelectedNetworkFeeAddress.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.address = reader.string();
                            break;
                        case 2:
                            message.feeAmount = reader.string();
                            break;
                        case 3:
                            message.tag = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SelectedNetworkFeeAddress message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress} SelectedNetworkFeeAddress
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SelectedNetworkFeeAddress.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SelectedNetworkFeeAddress message.
                 * @function verify
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SelectedNetworkFeeAddress.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    if (message.feeAmount != null && message.hasOwnProperty("feeAmount"))
                        if (!$util.isString(message.feeAmount))
                            return "feeAmount: string expected";
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        if (!$util.isString(message.tag))
                            return "tag: string expected";
                    return null;
                };
    
                /**
                 * Creates a SelectedNetworkFeeAddress message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress} SelectedNetworkFeeAddress
                 */
                SelectedNetworkFeeAddress.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress)
                        return object;
                    var message = new $root.protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress();
                    if (object.address != null)
                        message.address = String(object.address);
                    if (object.feeAmount != null)
                        message.feeAmount = String(object.feeAmount);
                    if (object.tag != null)
                        message.tag = String(object.tag);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SelectedNetworkFeeAddress message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress
                 * @static
                 * @param {protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress} message SelectedNetworkFeeAddress
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SelectedNetworkFeeAddress.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.address = "";
                        object.feeAmount = "";
                        object.tag = "";
                    }
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    if (message.feeAmount != null && message.hasOwnProperty("feeAmount"))
                        object.feeAmount = message.feeAmount;
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        object.tag = message.tag;
                    return object;
                };
    
                /**
                 * Converts this SelectedNetworkFeeAddress to JSON.
                 * @function toJSON
                 * @memberof protocol.CheckTokenPayoutSystemBalanceResponse.SelectedNetworkFeeAddress
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SelectedNetworkFeeAddress.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return SelectedNetworkFeeAddress;
            })();
    
            return CheckTokenPayoutSystemBalanceResponse;
        })();
    
        protocol.EnqueuePayoutRequest = (function() {
    
            /**
             * Properties of an EnqueuePayoutRequest.
             * @memberof protocol
             * @interface IEnqueuePayoutRequest
             * @property {string|null} [walletId] EnqueuePayoutRequest walletId
             * @property {string|null} [address] EnqueuePayoutRequest address
             * @property {protocol.Currency|null} [currency] EnqueuePayoutRequest currency
             * @property {string|null} [to] EnqueuePayoutRequest to
             * @property {string|null} [amount] EnqueuePayoutRequest amount
             * @property {string|null} [feeRate] EnqueuePayoutRequest feeRate
             * @property {Array.<string>|null} [tagsToWithdrawFrom] EnqueuePayoutRequest tagsToWithdrawFrom
             * @property {Array.<string>|null} [tags] EnqueuePayoutRequest tags
             * @property {Array.<string>|null} [networkFeeAddresses] EnqueuePayoutRequest networkFeeAddresses
             * @property {boolean|null} [activateNewTronAddress] EnqueuePayoutRequest activateNewTronAddress
             * @property {Array.<string>|null} [addressesToWithdrawFrom] EnqueuePayoutRequest addressesToWithdrawFrom
             */
    
            /**
             * Constructs a new EnqueuePayoutRequest.
             * @memberof protocol
             * @classdesc Represents an EnqueuePayoutRequest.
             * @implements IEnqueuePayoutRequest
             * @constructor
             * @param {protocol.IEnqueuePayoutRequest=} [properties] Properties to set
             */
            function EnqueuePayoutRequest(properties) {
                this.tagsToWithdrawFrom = [];
                this.tags = [];
                this.networkFeeAddresses = [];
                this.addressesToWithdrawFrom = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * EnqueuePayoutRequest walletId.
             * @member {string} walletId
             * @memberof protocol.EnqueuePayoutRequest
             * @instance
             */
            EnqueuePayoutRequest.prototype.walletId = "";
    
            /**
             * EnqueuePayoutRequest address.
             * @member {string} address
             * @memberof protocol.EnqueuePayoutRequest
             * @instance
             */
            EnqueuePayoutRequest.prototype.address = "";
    
            /**
             * EnqueuePayoutRequest currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.EnqueuePayoutRequest
             * @instance
             */
            EnqueuePayoutRequest.prototype.currency = 0;
    
            /**
             * EnqueuePayoutRequest to.
             * @member {string} to
             * @memberof protocol.EnqueuePayoutRequest
             * @instance
             */
            EnqueuePayoutRequest.prototype.to = "";
    
            /**
             * EnqueuePayoutRequest amount.
             * @member {string} amount
             * @memberof protocol.EnqueuePayoutRequest
             * @instance
             */
            EnqueuePayoutRequest.prototype.amount = "";
    
            /**
             * EnqueuePayoutRequest feeRate.
             * @member {string} feeRate
             * @memberof protocol.EnqueuePayoutRequest
             * @instance
             */
            EnqueuePayoutRequest.prototype.feeRate = "";
    
            /**
             * EnqueuePayoutRequest tagsToWithdrawFrom.
             * @member {Array.<string>} tagsToWithdrawFrom
             * @memberof protocol.EnqueuePayoutRequest
             * @instance
             */
            EnqueuePayoutRequest.prototype.tagsToWithdrawFrom = $util.emptyArray;
    
            /**
             * EnqueuePayoutRequest tags.
             * @member {Array.<string>} tags
             * @memberof protocol.EnqueuePayoutRequest
             * @instance
             */
            EnqueuePayoutRequest.prototype.tags = $util.emptyArray;
    
            /**
             * EnqueuePayoutRequest networkFeeAddresses.
             * @member {Array.<string>} networkFeeAddresses
             * @memberof protocol.EnqueuePayoutRequest
             * @instance
             */
            EnqueuePayoutRequest.prototype.networkFeeAddresses = $util.emptyArray;
    
            /**
             * EnqueuePayoutRequest activateNewTronAddress.
             * @member {boolean} activateNewTronAddress
             * @memberof protocol.EnqueuePayoutRequest
             * @instance
             */
            EnqueuePayoutRequest.prototype.activateNewTronAddress = false;
    
            /**
             * EnqueuePayoutRequest addressesToWithdrawFrom.
             * @member {Array.<string>} addressesToWithdrawFrom
             * @memberof protocol.EnqueuePayoutRequest
             * @instance
             */
            EnqueuePayoutRequest.prototype.addressesToWithdrawFrom = $util.emptyArray;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * EnqueuePayoutRequest addr.
             * @member {"walletId"|"address"|undefined} addr
             * @memberof protocol.EnqueuePayoutRequest
             * @instance
             */
            Object.defineProperty(EnqueuePayoutRequest.prototype, "addr", {
                get: $util.oneOfGetter($oneOfFields = ["walletId", "address"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new EnqueuePayoutRequest instance using the specified properties.
             * @function create
             * @memberof protocol.EnqueuePayoutRequest
             * @static
             * @param {protocol.IEnqueuePayoutRequest=} [properties] Properties to set
             * @returns {protocol.EnqueuePayoutRequest} EnqueuePayoutRequest instance
             */
            EnqueuePayoutRequest.create = function create(properties) {
                return new EnqueuePayoutRequest(properties);
            };
    
            /**
             * Encodes the specified EnqueuePayoutRequest message. Does not implicitly {@link protocol.EnqueuePayoutRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.EnqueuePayoutRequest
             * @static
             * @param {protocol.IEnqueuePayoutRequest} message EnqueuePayoutRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnqueuePayoutRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.walletId != null && Object.hasOwnProperty.call(message, "walletId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.walletId);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.currency);
                if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.to);
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.amount);
                if (message.feeRate != null && Object.hasOwnProperty.call(message, "feeRate"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.feeRate);
                if (message.tagsToWithdrawFrom != null && message.tagsToWithdrawFrom.length)
                    for (var i = 0; i < message.tagsToWithdrawFrom.length; ++i)
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.tagsToWithdrawFrom[i]);
                if (message.tags != null && message.tags.length)
                    for (var i = 0; i < message.tags.length; ++i)
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.tags[i]);
                if (message.networkFeeAddresses != null && message.networkFeeAddresses.length)
                    for (var i = 0; i < message.networkFeeAddresses.length; ++i)
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.networkFeeAddresses[i]);
                if (message.activateNewTronAddress != null && Object.hasOwnProperty.call(message, "activateNewTronAddress"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.activateNewTronAddress);
                if (message.addressesToWithdrawFrom != null && message.addressesToWithdrawFrom.length)
                    for (var i = 0; i < message.addressesToWithdrawFrom.length; ++i)
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.addressesToWithdrawFrom[i]);
                return writer;
            };
    
            /**
             * Encodes the specified EnqueuePayoutRequest message, length delimited. Does not implicitly {@link protocol.EnqueuePayoutRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.EnqueuePayoutRequest
             * @static
             * @param {protocol.IEnqueuePayoutRequest} message EnqueuePayoutRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnqueuePayoutRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EnqueuePayoutRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.EnqueuePayoutRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.EnqueuePayoutRequest} EnqueuePayoutRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnqueuePayoutRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.EnqueuePayoutRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.walletId = reader.string();
                        break;
                    case 2:
                        message.address = reader.string();
                        break;
                    case 3:
                        message.currency = reader.int32();
                        break;
                    case 4:
                        message.to = reader.string();
                        break;
                    case 5:
                        message.amount = reader.string();
                        break;
                    case 6:
                        message.feeRate = reader.string();
                        break;
                    case 7:
                        if (!(message.tagsToWithdrawFrom && message.tagsToWithdrawFrom.length))
                            message.tagsToWithdrawFrom = [];
                        message.tagsToWithdrawFrom.push(reader.string());
                        break;
                    case 8:
                        if (!(message.tags && message.tags.length))
                            message.tags = [];
                        message.tags.push(reader.string());
                        break;
                    case 9:
                        if (!(message.networkFeeAddresses && message.networkFeeAddresses.length))
                            message.networkFeeAddresses = [];
                        message.networkFeeAddresses.push(reader.string());
                        break;
                    case 10:
                        message.activateNewTronAddress = reader.bool();
                        break;
                    case 11:
                        if (!(message.addressesToWithdrawFrom && message.addressesToWithdrawFrom.length))
                            message.addressesToWithdrawFrom = [];
                        message.addressesToWithdrawFrom.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EnqueuePayoutRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.EnqueuePayoutRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.EnqueuePayoutRequest} EnqueuePayoutRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnqueuePayoutRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EnqueuePayoutRequest message.
             * @function verify
             * @memberof protocol.EnqueuePayoutRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnqueuePayoutRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.walletId != null && message.hasOwnProperty("walletId")) {
                    properties.addr = 1;
                    if (!$util.isString(message.walletId))
                        return "walletId: string expected";
                }
                if (message.address != null && message.hasOwnProperty("address")) {
                    if (properties.addr === 1)
                        return "addr: multiple values";
                    properties.addr = 1;
                    if (!$util.isString(message.address))
                        return "address: string expected";
                }
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.to != null && message.hasOwnProperty("to"))
                    if (!$util.isString(message.to))
                        return "to: string expected";
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (!$util.isString(message.amount))
                        return "amount: string expected";
                if (message.feeRate != null && message.hasOwnProperty("feeRate"))
                    if (!$util.isString(message.feeRate))
                        return "feeRate: string expected";
                if (message.tagsToWithdrawFrom != null && message.hasOwnProperty("tagsToWithdrawFrom")) {
                    if (!Array.isArray(message.tagsToWithdrawFrom))
                        return "tagsToWithdrawFrom: array expected";
                    for (var i = 0; i < message.tagsToWithdrawFrom.length; ++i)
                        if (!$util.isString(message.tagsToWithdrawFrom[i]))
                            return "tagsToWithdrawFrom: string[] expected";
                }
                if (message.tags != null && message.hasOwnProperty("tags")) {
                    if (!Array.isArray(message.tags))
                        return "tags: array expected";
                    for (var i = 0; i < message.tags.length; ++i)
                        if (!$util.isString(message.tags[i]))
                            return "tags: string[] expected";
                }
                if (message.networkFeeAddresses != null && message.hasOwnProperty("networkFeeAddresses")) {
                    if (!Array.isArray(message.networkFeeAddresses))
                        return "networkFeeAddresses: array expected";
                    for (var i = 0; i < message.networkFeeAddresses.length; ++i)
                        if (!$util.isString(message.networkFeeAddresses[i]))
                            return "networkFeeAddresses: string[] expected";
                }
                if (message.activateNewTronAddress != null && message.hasOwnProperty("activateNewTronAddress"))
                    if (typeof message.activateNewTronAddress !== "boolean")
                        return "activateNewTronAddress: boolean expected";
                if (message.addressesToWithdrawFrom != null && message.hasOwnProperty("addressesToWithdrawFrom")) {
                    if (!Array.isArray(message.addressesToWithdrawFrom))
                        return "addressesToWithdrawFrom: array expected";
                    for (var i = 0; i < message.addressesToWithdrawFrom.length; ++i)
                        if (!$util.isString(message.addressesToWithdrawFrom[i]))
                            return "addressesToWithdrawFrom: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates an EnqueuePayoutRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.EnqueuePayoutRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.EnqueuePayoutRequest} EnqueuePayoutRequest
             */
            EnqueuePayoutRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.EnqueuePayoutRequest)
                    return object;
                var message = new $root.protocol.EnqueuePayoutRequest();
                if (object.walletId != null)
                    message.walletId = String(object.walletId);
                if (object.address != null)
                    message.address = String(object.address);
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                if (object.to != null)
                    message.to = String(object.to);
                if (object.amount != null)
                    message.amount = String(object.amount);
                if (object.feeRate != null)
                    message.feeRate = String(object.feeRate);
                if (object.tagsToWithdrawFrom) {
                    if (!Array.isArray(object.tagsToWithdrawFrom))
                        throw TypeError(".protocol.EnqueuePayoutRequest.tagsToWithdrawFrom: array expected");
                    message.tagsToWithdrawFrom = [];
                    for (var i = 0; i < object.tagsToWithdrawFrom.length; ++i)
                        message.tagsToWithdrawFrom[i] = String(object.tagsToWithdrawFrom[i]);
                }
                if (object.tags) {
                    if (!Array.isArray(object.tags))
                        throw TypeError(".protocol.EnqueuePayoutRequest.tags: array expected");
                    message.tags = [];
                    for (var i = 0; i < object.tags.length; ++i)
                        message.tags[i] = String(object.tags[i]);
                }
                if (object.networkFeeAddresses) {
                    if (!Array.isArray(object.networkFeeAddresses))
                        throw TypeError(".protocol.EnqueuePayoutRequest.networkFeeAddresses: array expected");
                    message.networkFeeAddresses = [];
                    for (var i = 0; i < object.networkFeeAddresses.length; ++i)
                        message.networkFeeAddresses[i] = String(object.networkFeeAddresses[i]);
                }
                if (object.activateNewTronAddress != null)
                    message.activateNewTronAddress = Boolean(object.activateNewTronAddress);
                if (object.addressesToWithdrawFrom) {
                    if (!Array.isArray(object.addressesToWithdrawFrom))
                        throw TypeError(".protocol.EnqueuePayoutRequest.addressesToWithdrawFrom: array expected");
                    message.addressesToWithdrawFrom = [];
                    for (var i = 0; i < object.addressesToWithdrawFrom.length; ++i)
                        message.addressesToWithdrawFrom[i] = String(object.addressesToWithdrawFrom[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an EnqueuePayoutRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.EnqueuePayoutRequest
             * @static
             * @param {protocol.EnqueuePayoutRequest} message EnqueuePayoutRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnqueuePayoutRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.tagsToWithdrawFrom = [];
                    object.tags = [];
                    object.networkFeeAddresses = [];
                    object.addressesToWithdrawFrom = [];
                }
                if (options.defaults) {
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.to = "";
                    object.amount = "";
                    object.feeRate = "";
                    object.activateNewTronAddress = false;
                }
                if (message.walletId != null && message.hasOwnProperty("walletId")) {
                    object.walletId = message.walletId;
                    if (options.oneofs)
                        object.addr = "walletId";
                }
                if (message.address != null && message.hasOwnProperty("address")) {
                    object.address = message.address;
                    if (options.oneofs)
                        object.addr = "address";
                }
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.to != null && message.hasOwnProperty("to"))
                    object.to = message.to;
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = message.amount;
                if (message.feeRate != null && message.hasOwnProperty("feeRate"))
                    object.feeRate = message.feeRate;
                if (message.tagsToWithdrawFrom && message.tagsToWithdrawFrom.length) {
                    object.tagsToWithdrawFrom = [];
                    for (var j = 0; j < message.tagsToWithdrawFrom.length; ++j)
                        object.tagsToWithdrawFrom[j] = message.tagsToWithdrawFrom[j];
                }
                if (message.tags && message.tags.length) {
                    object.tags = [];
                    for (var j = 0; j < message.tags.length; ++j)
                        object.tags[j] = message.tags[j];
                }
                if (message.networkFeeAddresses && message.networkFeeAddresses.length) {
                    object.networkFeeAddresses = [];
                    for (var j = 0; j < message.networkFeeAddresses.length; ++j)
                        object.networkFeeAddresses[j] = message.networkFeeAddresses[j];
                }
                if (message.activateNewTronAddress != null && message.hasOwnProperty("activateNewTronAddress"))
                    object.activateNewTronAddress = message.activateNewTronAddress;
                if (message.addressesToWithdrawFrom && message.addressesToWithdrawFrom.length) {
                    object.addressesToWithdrawFrom = [];
                    for (var j = 0; j < message.addressesToWithdrawFrom.length; ++j)
                        object.addressesToWithdrawFrom[j] = message.addressesToWithdrawFrom[j];
                }
                return object;
            };
    
            /**
             * Converts this EnqueuePayoutRequest to JSON.
             * @function toJSON
             * @memberof protocol.EnqueuePayoutRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnqueuePayoutRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return EnqueuePayoutRequest;
        })();
    
        protocol.EnqueuePayoutResponse = (function() {
    
            /**
             * Properties of an EnqueuePayoutResponse.
             * @memberof protocol
             * @interface IEnqueuePayoutResponse
             * @property {protocol.IPayoutRequestRecord|null} [payoutRequest] EnqueuePayoutResponse payoutRequest
             */
    
            /**
             * Constructs a new EnqueuePayoutResponse.
             * @memberof protocol
             * @classdesc Represents an EnqueuePayoutResponse.
             * @implements IEnqueuePayoutResponse
             * @constructor
             * @param {protocol.IEnqueuePayoutResponse=} [properties] Properties to set
             */
            function EnqueuePayoutResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * EnqueuePayoutResponse payoutRequest.
             * @member {protocol.IPayoutRequestRecord|null|undefined} payoutRequest
             * @memberof protocol.EnqueuePayoutResponse
             * @instance
             */
            EnqueuePayoutResponse.prototype.payoutRequest = null;
    
            /**
             * Creates a new EnqueuePayoutResponse instance using the specified properties.
             * @function create
             * @memberof protocol.EnqueuePayoutResponse
             * @static
             * @param {protocol.IEnqueuePayoutResponse=} [properties] Properties to set
             * @returns {protocol.EnqueuePayoutResponse} EnqueuePayoutResponse instance
             */
            EnqueuePayoutResponse.create = function create(properties) {
                return new EnqueuePayoutResponse(properties);
            };
    
            /**
             * Encodes the specified EnqueuePayoutResponse message. Does not implicitly {@link protocol.EnqueuePayoutResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.EnqueuePayoutResponse
             * @static
             * @param {protocol.IEnqueuePayoutResponse} message EnqueuePayoutResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnqueuePayoutResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.payoutRequest != null && Object.hasOwnProperty.call(message, "payoutRequest"))
                    $root.protocol.PayoutRequestRecord.encode(message.payoutRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified EnqueuePayoutResponse message, length delimited. Does not implicitly {@link protocol.EnqueuePayoutResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.EnqueuePayoutResponse
             * @static
             * @param {protocol.IEnqueuePayoutResponse} message EnqueuePayoutResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnqueuePayoutResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EnqueuePayoutResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.EnqueuePayoutResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.EnqueuePayoutResponse} EnqueuePayoutResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnqueuePayoutResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.EnqueuePayoutResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.payoutRequest = $root.protocol.PayoutRequestRecord.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EnqueuePayoutResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.EnqueuePayoutResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.EnqueuePayoutResponse} EnqueuePayoutResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnqueuePayoutResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EnqueuePayoutResponse message.
             * @function verify
             * @memberof protocol.EnqueuePayoutResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnqueuePayoutResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.payoutRequest != null && message.hasOwnProperty("payoutRequest")) {
                    var error = $root.protocol.PayoutRequestRecord.verify(message.payoutRequest);
                    if (error)
                        return "payoutRequest." + error;
                }
                return null;
            };
    
            /**
             * Creates an EnqueuePayoutResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.EnqueuePayoutResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.EnqueuePayoutResponse} EnqueuePayoutResponse
             */
            EnqueuePayoutResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.EnqueuePayoutResponse)
                    return object;
                var message = new $root.protocol.EnqueuePayoutResponse();
                if (object.payoutRequest != null) {
                    if (typeof object.payoutRequest !== "object")
                        throw TypeError(".protocol.EnqueuePayoutResponse.payoutRequest: object expected");
                    message.payoutRequest = $root.protocol.PayoutRequestRecord.fromObject(object.payoutRequest);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an EnqueuePayoutResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.EnqueuePayoutResponse
             * @static
             * @param {protocol.EnqueuePayoutResponse} message EnqueuePayoutResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnqueuePayoutResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.payoutRequest = null;
                if (message.payoutRequest != null && message.hasOwnProperty("payoutRequest"))
                    object.payoutRequest = $root.protocol.PayoutRequestRecord.toObject(message.payoutRequest, options);
                return object;
            };
    
            /**
             * Converts this EnqueuePayoutResponse to JSON.
             * @function toJSON
             * @memberof protocol.EnqueuePayoutResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnqueuePayoutResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return EnqueuePayoutResponse;
        })();
    
        /**
         * PayoutRequestStatus enum.
         * @name protocol.PayoutRequestStatus
         * @enum {number}
         * @property {number} UNK_PAYOUT_REQUEST_STATUS=0 UNK_PAYOUT_REQUEST_STATUS value
         * @property {number} PENDING_PAYOUT_REQUEST_STATUS=1 PENDING_PAYOUT_REQUEST_STATUS value
         * @property {number} PROCESSING_PAYOUT_REQUEST_STATUS=2 PROCESSING_PAYOUT_REQUEST_STATUS value
         * @property {number} COMPLETED_PAYOUT_REQUEST_STATUS=4 COMPLETED_PAYOUT_REQUEST_STATUS value
         * @property {number} FAILED_PAYOUT_REQUEST_STATUS=5 FAILED_PAYOUT_REQUEST_STATUS value
         * @property {number} CANCELED_PAYOUT_REQUEST_STATUS=6 CANCELED_PAYOUT_REQUEST_STATUS value
         */
        protocol.PayoutRequestStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_PAYOUT_REQUEST_STATUS"] = 0;
            values[valuesById[1] = "PENDING_PAYOUT_REQUEST_STATUS"] = 1;
            values[valuesById[2] = "PROCESSING_PAYOUT_REQUEST_STATUS"] = 2;
            values[valuesById[4] = "COMPLETED_PAYOUT_REQUEST_STATUS"] = 4;
            values[valuesById[5] = "FAILED_PAYOUT_REQUEST_STATUS"] = 5;
            values[valuesById[6] = "CANCELED_PAYOUT_REQUEST_STATUS"] = 6;
            return values;
        })();
    
        /**
         * PayoutRequestType enum.
         * @name protocol.PayoutRequestType
         * @enum {number}
         * @property {number} UNK_PAYOUT_REQUEST_TYPE=0 UNK_PAYOUT_REQUEST_TYPE value
         * @property {number} INTERNAL_PAYOUT_REQUEST_TYPE=1 INTERNAL_PAYOUT_REQUEST_TYPE value
         * @property {number} EXTERNAL_PAYOUT_REQUEST_TYPE=2 EXTERNAL_PAYOUT_REQUEST_TYPE value
         * @property {number} TRON_ACCOUNT_ACTIVATION_PAYOUT_REQUEST_TYPE=3 TRON_ACCOUNT_ACTIVATION_PAYOUT_REQUEST_TYPE value
         */
        protocol.PayoutRequestType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_PAYOUT_REQUEST_TYPE"] = 0;
            values[valuesById[1] = "INTERNAL_PAYOUT_REQUEST_TYPE"] = 1;
            values[valuesById[2] = "EXTERNAL_PAYOUT_REQUEST_TYPE"] = 2;
            values[valuesById[3] = "TRON_ACCOUNT_ACTIVATION_PAYOUT_REQUEST_TYPE"] = 3;
            return values;
        })();
    
        protocol.PRSystemAddress = (function() {
    
            /**
             * Properties of a PRSystemAddress.
             * @memberof protocol
             * @interface IPRSystemAddress
             * @property {string|null} [address] PRSystemAddress address
             * @property {string|null} [tag] PRSystemAddress tag
             * @property {string|null} [balance] PRSystemAddress balance
             * @property {string|null} [availableBalance] PRSystemAddress availableBalance
             */
    
            /**
             * Constructs a new PRSystemAddress.
             * @memberof protocol
             * @classdesc Represents a PRSystemAddress.
             * @implements IPRSystemAddress
             * @constructor
             * @param {protocol.IPRSystemAddress=} [properties] Properties to set
             */
            function PRSystemAddress(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PRSystemAddress address.
             * @member {string} address
             * @memberof protocol.PRSystemAddress
             * @instance
             */
            PRSystemAddress.prototype.address = "";
    
            /**
             * PRSystemAddress tag.
             * @member {string} tag
             * @memberof protocol.PRSystemAddress
             * @instance
             */
            PRSystemAddress.prototype.tag = "";
    
            /**
             * PRSystemAddress balance.
             * @member {string} balance
             * @memberof protocol.PRSystemAddress
             * @instance
             */
            PRSystemAddress.prototype.balance = "";
    
            /**
             * PRSystemAddress availableBalance.
             * @member {string} availableBalance
             * @memberof protocol.PRSystemAddress
             * @instance
             */
            PRSystemAddress.prototype.availableBalance = "";
    
            /**
             * Creates a new PRSystemAddress instance using the specified properties.
             * @function create
             * @memberof protocol.PRSystemAddress
             * @static
             * @param {protocol.IPRSystemAddress=} [properties] Properties to set
             * @returns {protocol.PRSystemAddress} PRSystemAddress instance
             */
            PRSystemAddress.create = function create(properties) {
                return new PRSystemAddress(properties);
            };
    
            /**
             * Encodes the specified PRSystemAddress message. Does not implicitly {@link protocol.PRSystemAddress.verify|verify} messages.
             * @function encode
             * @memberof protocol.PRSystemAddress
             * @static
             * @param {protocol.IPRSystemAddress} message PRSystemAddress message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PRSystemAddress.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
                if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.tag);
                if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.balance);
                if (message.availableBalance != null && Object.hasOwnProperty.call(message, "availableBalance"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.availableBalance);
                return writer;
            };
    
            /**
             * Encodes the specified PRSystemAddress message, length delimited. Does not implicitly {@link protocol.PRSystemAddress.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.PRSystemAddress
             * @static
             * @param {protocol.IPRSystemAddress} message PRSystemAddress message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PRSystemAddress.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PRSystemAddress message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.PRSystemAddress
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.PRSystemAddress} PRSystemAddress
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PRSystemAddress.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.PRSystemAddress();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.address = reader.string();
                        break;
                    case 2:
                        message.tag = reader.string();
                        break;
                    case 3:
                        message.balance = reader.string();
                        break;
                    case 4:
                        message.availableBalance = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PRSystemAddress message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.PRSystemAddress
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.PRSystemAddress} PRSystemAddress
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PRSystemAddress.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PRSystemAddress message.
             * @function verify
             * @memberof protocol.PRSystemAddress
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PRSystemAddress.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                if (message.tag != null && message.hasOwnProperty("tag"))
                    if (!$util.isString(message.tag))
                        return "tag: string expected";
                if (message.balance != null && message.hasOwnProperty("balance"))
                    if (!$util.isString(message.balance))
                        return "balance: string expected";
                if (message.availableBalance != null && message.hasOwnProperty("availableBalance"))
                    if (!$util.isString(message.availableBalance))
                        return "availableBalance: string expected";
                return null;
            };
    
            /**
             * Creates a PRSystemAddress message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.PRSystemAddress
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.PRSystemAddress} PRSystemAddress
             */
            PRSystemAddress.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.PRSystemAddress)
                    return object;
                var message = new $root.protocol.PRSystemAddress();
                if (object.address != null)
                    message.address = String(object.address);
                if (object.tag != null)
                    message.tag = String(object.tag);
                if (object.balance != null)
                    message.balance = String(object.balance);
                if (object.availableBalance != null)
                    message.availableBalance = String(object.availableBalance);
                return message;
            };
    
            /**
             * Creates a plain object from a PRSystemAddress message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.PRSystemAddress
             * @static
             * @param {protocol.PRSystemAddress} message PRSystemAddress
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PRSystemAddress.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.address = "";
                    object.tag = "";
                    object.balance = "";
                    object.availableBalance = "";
                }
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.tag != null && message.hasOwnProperty("tag"))
                    object.tag = message.tag;
                if (message.balance != null && message.hasOwnProperty("balance"))
                    object.balance = message.balance;
                if (message.availableBalance != null && message.hasOwnProperty("availableBalance"))
                    object.availableBalance = message.availableBalance;
                return object;
            };
    
            /**
             * Converts this PRSystemAddress to JSON.
             * @function toJSON
             * @memberof protocol.PRSystemAddress
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PRSystemAddress.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return PRSystemAddress;
        })();
    
        protocol.PayoutRequestRecord = (function() {
    
            /**
             * Properties of a PayoutRequestRecord.
             * @memberof protocol
             * @interface IPayoutRequestRecord
             * @property {number|null} [id] PayoutRequestRecord id
             * @property {string|null} [to] PayoutRequestRecord to
             * @property {string|null} [requestedAmount] PayoutRequestRecord requestedAmount
             * @property {string|null} [paidAmount] PayoutRequestRecord paidAmount
             * @property {protocol.PayoutRequestStatus|null} [status] PayoutRequestRecord status
             * @property {protocol.Currency|null} [currency] PayoutRequestRecord currency
             * @property {protocol.Chain|null} [chain] PayoutRequestRecord chain
             * @property {string|null} [fromEntity] PayoutRequestRecord fromEntity
             * @property {Long|null} [createdAt] PayoutRequestRecord createdAt
             * @property {Long|null} [updatedAt] PayoutRequestRecord updatedAt
             * @property {protocol.PayoutRequestType|null} [type] PayoutRequestRecord type
             * @property {string|null} [operationId] PayoutRequestRecord operationId
             * @property {boolean|null} [hasEnoughFunds] PayoutRequestRecord hasEnoughFunds
             * @property {Array.<string>|null} [payoutTags] PayoutRequestRecord payoutTags
             * @property {Array.<protocol.IPRSystemAddress>|null} [refillSystemAddresses] PayoutRequestRecord refillSystemAddresses
             * @property {Array.<string>|null} [tags] PayoutRequestRecord tags
             * @property {Array.<string>|null} [payoutAddresses] PayoutRequestRecord payoutAddresses
             * @property {Array.<protocol.IPayoutRecord>|null} [payouts] PayoutRequestRecord payouts
             */
    
            /**
             * Constructs a new PayoutRequestRecord.
             * @memberof protocol
             * @classdesc Represents a PayoutRequestRecord.
             * @implements IPayoutRequestRecord
             * @constructor
             * @param {protocol.IPayoutRequestRecord=} [properties] Properties to set
             */
            function PayoutRequestRecord(properties) {
                this.payoutTags = [];
                this.refillSystemAddresses = [];
                this.tags = [];
                this.payoutAddresses = [];
                this.payouts = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PayoutRequestRecord id.
             * @member {number} id
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.id = 0;
    
            /**
             * PayoutRequestRecord to.
             * @member {string} to
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.to = "";
    
            /**
             * PayoutRequestRecord requestedAmount.
             * @member {string} requestedAmount
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.requestedAmount = "";
    
            /**
             * PayoutRequestRecord paidAmount.
             * @member {string} paidAmount
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.paidAmount = "";
    
            /**
             * PayoutRequestRecord status.
             * @member {protocol.PayoutRequestStatus} status
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.status = 0;
    
            /**
             * PayoutRequestRecord currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.currency = 0;
    
            /**
             * PayoutRequestRecord chain.
             * @member {protocol.Chain} chain
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.chain = 0;
    
            /**
             * PayoutRequestRecord fromEntity.
             * @member {string} fromEntity
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.fromEntity = "";
    
            /**
             * PayoutRequestRecord createdAt.
             * @member {Long} createdAt
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * PayoutRequestRecord updatedAt.
             * @member {Long} updatedAt
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * PayoutRequestRecord type.
             * @member {protocol.PayoutRequestType} type
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.type = 0;
    
            /**
             * PayoutRequestRecord operationId.
             * @member {string} operationId
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.operationId = "";
    
            /**
             * PayoutRequestRecord hasEnoughFunds.
             * @member {boolean} hasEnoughFunds
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.hasEnoughFunds = false;
    
            /**
             * PayoutRequestRecord payoutTags.
             * @member {Array.<string>} payoutTags
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.payoutTags = $util.emptyArray;
    
            /**
             * PayoutRequestRecord refillSystemAddresses.
             * @member {Array.<protocol.IPRSystemAddress>} refillSystemAddresses
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.refillSystemAddresses = $util.emptyArray;
    
            /**
             * PayoutRequestRecord tags.
             * @member {Array.<string>} tags
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.tags = $util.emptyArray;
    
            /**
             * PayoutRequestRecord payoutAddresses.
             * @member {Array.<string>} payoutAddresses
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.payoutAddresses = $util.emptyArray;
    
            /**
             * PayoutRequestRecord payouts.
             * @member {Array.<protocol.IPayoutRecord>} payouts
             * @memberof protocol.PayoutRequestRecord
             * @instance
             */
            PayoutRequestRecord.prototype.payouts = $util.emptyArray;
    
            /**
             * Creates a new PayoutRequestRecord instance using the specified properties.
             * @function create
             * @memberof protocol.PayoutRequestRecord
             * @static
             * @param {protocol.IPayoutRequestRecord=} [properties] Properties to set
             * @returns {protocol.PayoutRequestRecord} PayoutRequestRecord instance
             */
            PayoutRequestRecord.create = function create(properties) {
                return new PayoutRequestRecord(properties);
            };
    
            /**
             * Encodes the specified PayoutRequestRecord message. Does not implicitly {@link protocol.PayoutRequestRecord.verify|verify} messages.
             * @function encode
             * @memberof protocol.PayoutRequestRecord
             * @static
             * @param {protocol.IPayoutRequestRecord} message PayoutRequestRecord message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PayoutRequestRecord.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.to);
                if (message.requestedAmount != null && Object.hasOwnProperty.call(message, "requestedAmount"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.requestedAmount);
                if (message.paidAmount != null && Object.hasOwnProperty.call(message, "paidAmount"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.paidAmount);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.status);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.currency);
                if (message.chain != null && Object.hasOwnProperty.call(message, "chain"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.chain);
                if (message.fromEntity != null && Object.hasOwnProperty.call(message, "fromEntity"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.fromEntity);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.createdAt);
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.updatedAt);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int32(message.type);
                if (message.operationId != null && Object.hasOwnProperty.call(message, "operationId"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.operationId);
                if (message.hasEnoughFunds != null && Object.hasOwnProperty.call(message, "hasEnoughFunds"))
                    writer.uint32(/* id 13, wireType 0 =*/104).bool(message.hasEnoughFunds);
                if (message.payoutTags != null && message.payoutTags.length)
                    for (var i = 0; i < message.payoutTags.length; ++i)
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.payoutTags[i]);
                if (message.refillSystemAddresses != null && message.refillSystemAddresses.length)
                    for (var i = 0; i < message.refillSystemAddresses.length; ++i)
                        $root.protocol.PRSystemAddress.encode(message.refillSystemAddresses[i], writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.tags != null && message.tags.length)
                    for (var i = 0; i < message.tags.length; ++i)
                        writer.uint32(/* id 16, wireType 2 =*/130).string(message.tags[i]);
                if (message.payoutAddresses != null && message.payoutAddresses.length)
                    for (var i = 0; i < message.payoutAddresses.length; ++i)
                        writer.uint32(/* id 17, wireType 2 =*/138).string(message.payoutAddresses[i]);
                if (message.payouts != null && message.payouts.length)
                    for (var i = 0; i < message.payouts.length; ++i)
                        $root.protocol.PayoutRecord.encode(message.payouts[i], writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified PayoutRequestRecord message, length delimited. Does not implicitly {@link protocol.PayoutRequestRecord.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.PayoutRequestRecord
             * @static
             * @param {protocol.IPayoutRequestRecord} message PayoutRequestRecord message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PayoutRequestRecord.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PayoutRequestRecord message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.PayoutRequestRecord
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.PayoutRequestRecord} PayoutRequestRecord
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PayoutRequestRecord.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.PayoutRequestRecord();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint32();
                        break;
                    case 2:
                        message.to = reader.string();
                        break;
                    case 3:
                        message.requestedAmount = reader.string();
                        break;
                    case 4:
                        message.paidAmount = reader.string();
                        break;
                    case 5:
                        message.status = reader.int32();
                        break;
                    case 6:
                        message.currency = reader.int32();
                        break;
                    case 7:
                        message.chain = reader.int32();
                        break;
                    case 8:
                        message.fromEntity = reader.string();
                        break;
                    case 9:
                        message.createdAt = reader.uint64();
                        break;
                    case 10:
                        message.updatedAt = reader.uint64();
                        break;
                    case 11:
                        message.type = reader.int32();
                        break;
                    case 12:
                        message.operationId = reader.string();
                        break;
                    case 13:
                        message.hasEnoughFunds = reader.bool();
                        break;
                    case 14:
                        if (!(message.payoutTags && message.payoutTags.length))
                            message.payoutTags = [];
                        message.payoutTags.push(reader.string());
                        break;
                    case 15:
                        if (!(message.refillSystemAddresses && message.refillSystemAddresses.length))
                            message.refillSystemAddresses = [];
                        message.refillSystemAddresses.push($root.protocol.PRSystemAddress.decode(reader, reader.uint32()));
                        break;
                    case 16:
                        if (!(message.tags && message.tags.length))
                            message.tags = [];
                        message.tags.push(reader.string());
                        break;
                    case 17:
                        if (!(message.payoutAddresses && message.payoutAddresses.length))
                            message.payoutAddresses = [];
                        message.payoutAddresses.push(reader.string());
                        break;
                    case 50:
                        if (!(message.payouts && message.payouts.length))
                            message.payouts = [];
                        message.payouts.push($root.protocol.PayoutRecord.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PayoutRequestRecord message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.PayoutRequestRecord
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.PayoutRequestRecord} PayoutRequestRecord
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PayoutRequestRecord.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PayoutRequestRecord message.
             * @function verify
             * @memberof protocol.PayoutRequestRecord
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PayoutRequestRecord.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.to != null && message.hasOwnProperty("to"))
                    if (!$util.isString(message.to))
                        return "to: string expected";
                if (message.requestedAmount != null && message.hasOwnProperty("requestedAmount"))
                    if (!$util.isString(message.requestedAmount))
                        return "requestedAmount: string expected";
                if (message.paidAmount != null && message.hasOwnProperty("paidAmount"))
                    if (!$util.isString(message.paidAmount))
                        return "paidAmount: string expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.chain != null && message.hasOwnProperty("chain"))
                    switch (message.chain) {
                    default:
                        return "chain: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.fromEntity != null && message.hasOwnProperty("fromEntity"))
                    if (!$util.isString(message.fromEntity))
                        return "fromEntity: string expected";
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                        return "createdAt: integer|Long expected";
                if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                    if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                        return "updatedAt: integer|Long expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.operationId != null && message.hasOwnProperty("operationId"))
                    if (!$util.isString(message.operationId))
                        return "operationId: string expected";
                if (message.hasEnoughFunds != null && message.hasOwnProperty("hasEnoughFunds"))
                    if (typeof message.hasEnoughFunds !== "boolean")
                        return "hasEnoughFunds: boolean expected";
                if (message.payoutTags != null && message.hasOwnProperty("payoutTags")) {
                    if (!Array.isArray(message.payoutTags))
                        return "payoutTags: array expected";
                    for (var i = 0; i < message.payoutTags.length; ++i)
                        if (!$util.isString(message.payoutTags[i]))
                            return "payoutTags: string[] expected";
                }
                if (message.refillSystemAddresses != null && message.hasOwnProperty("refillSystemAddresses")) {
                    if (!Array.isArray(message.refillSystemAddresses))
                        return "refillSystemAddresses: array expected";
                    for (var i = 0; i < message.refillSystemAddresses.length; ++i) {
                        var error = $root.protocol.PRSystemAddress.verify(message.refillSystemAddresses[i]);
                        if (error)
                            return "refillSystemAddresses." + error;
                    }
                }
                if (message.tags != null && message.hasOwnProperty("tags")) {
                    if (!Array.isArray(message.tags))
                        return "tags: array expected";
                    for (var i = 0; i < message.tags.length; ++i)
                        if (!$util.isString(message.tags[i]))
                            return "tags: string[] expected";
                }
                if (message.payoutAddresses != null && message.hasOwnProperty("payoutAddresses")) {
                    if (!Array.isArray(message.payoutAddresses))
                        return "payoutAddresses: array expected";
                    for (var i = 0; i < message.payoutAddresses.length; ++i)
                        if (!$util.isString(message.payoutAddresses[i]))
                            return "payoutAddresses: string[] expected";
                }
                if (message.payouts != null && message.hasOwnProperty("payouts")) {
                    if (!Array.isArray(message.payouts))
                        return "payouts: array expected";
                    for (var i = 0; i < message.payouts.length; ++i) {
                        var error = $root.protocol.PayoutRecord.verify(message.payouts[i]);
                        if (error)
                            return "payouts." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a PayoutRequestRecord message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.PayoutRequestRecord
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.PayoutRequestRecord} PayoutRequestRecord
             */
            PayoutRequestRecord.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.PayoutRequestRecord)
                    return object;
                var message = new $root.protocol.PayoutRequestRecord();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.to != null)
                    message.to = String(object.to);
                if (object.requestedAmount != null)
                    message.requestedAmount = String(object.requestedAmount);
                if (object.paidAmount != null)
                    message.paidAmount = String(object.paidAmount);
                switch (object.status) {
                case "UNK_PAYOUT_REQUEST_STATUS":
                case 0:
                    message.status = 0;
                    break;
                case "PENDING_PAYOUT_REQUEST_STATUS":
                case 1:
                    message.status = 1;
                    break;
                case "PROCESSING_PAYOUT_REQUEST_STATUS":
                case 2:
                    message.status = 2;
                    break;
                case "COMPLETED_PAYOUT_REQUEST_STATUS":
                case 4:
                    message.status = 4;
                    break;
                case "FAILED_PAYOUT_REQUEST_STATUS":
                case 5:
                    message.status = 5;
                    break;
                case "CANCELED_PAYOUT_REQUEST_STATUS":
                case 6:
                    message.status = 6;
                    break;
                }
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                switch (object.chain) {
                case "UNK_CHAIN":
                case 0:
                    message.chain = 0;
                    break;
                case "BTC_CHAIN":
                case 1:
                    message.chain = 1;
                    break;
                case "ETH_CHAIN":
                case 2:
                    message.chain = 2;
                    break;
                case "TRX_CHAIN":
                case 3:
                    message.chain = 3;
                    break;
                }
                if (object.fromEntity != null)
                    message.fromEntity = String(object.fromEntity);
                if (object.createdAt != null)
                    if ($util.Long)
                        (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = true;
                    else if (typeof object.createdAt === "string")
                        message.createdAt = parseInt(object.createdAt, 10);
                    else if (typeof object.createdAt === "number")
                        message.createdAt = object.createdAt;
                    else if (typeof object.createdAt === "object")
                        message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber(true);
                if (object.updatedAt != null)
                    if ($util.Long)
                        (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = true;
                    else if (typeof object.updatedAt === "string")
                        message.updatedAt = parseInt(object.updatedAt, 10);
                    else if (typeof object.updatedAt === "number")
                        message.updatedAt = object.updatedAt;
                    else if (typeof object.updatedAt === "object")
                        message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber(true);
                switch (object.type) {
                case "UNK_PAYOUT_REQUEST_TYPE":
                case 0:
                    message.type = 0;
                    break;
                case "INTERNAL_PAYOUT_REQUEST_TYPE":
                case 1:
                    message.type = 1;
                    break;
                case "EXTERNAL_PAYOUT_REQUEST_TYPE":
                case 2:
                    message.type = 2;
                    break;
                case "TRON_ACCOUNT_ACTIVATION_PAYOUT_REQUEST_TYPE":
                case 3:
                    message.type = 3;
                    break;
                }
                if (object.operationId != null)
                    message.operationId = String(object.operationId);
                if (object.hasEnoughFunds != null)
                    message.hasEnoughFunds = Boolean(object.hasEnoughFunds);
                if (object.payoutTags) {
                    if (!Array.isArray(object.payoutTags))
                        throw TypeError(".protocol.PayoutRequestRecord.payoutTags: array expected");
                    message.payoutTags = [];
                    for (var i = 0; i < object.payoutTags.length; ++i)
                        message.payoutTags[i] = String(object.payoutTags[i]);
                }
                if (object.refillSystemAddresses) {
                    if (!Array.isArray(object.refillSystemAddresses))
                        throw TypeError(".protocol.PayoutRequestRecord.refillSystemAddresses: array expected");
                    message.refillSystemAddresses = [];
                    for (var i = 0; i < object.refillSystemAddresses.length; ++i) {
                        if (typeof object.refillSystemAddresses[i] !== "object")
                            throw TypeError(".protocol.PayoutRequestRecord.refillSystemAddresses: object expected");
                        message.refillSystemAddresses[i] = $root.protocol.PRSystemAddress.fromObject(object.refillSystemAddresses[i]);
                    }
                }
                if (object.tags) {
                    if (!Array.isArray(object.tags))
                        throw TypeError(".protocol.PayoutRequestRecord.tags: array expected");
                    message.tags = [];
                    for (var i = 0; i < object.tags.length; ++i)
                        message.tags[i] = String(object.tags[i]);
                }
                if (object.payoutAddresses) {
                    if (!Array.isArray(object.payoutAddresses))
                        throw TypeError(".protocol.PayoutRequestRecord.payoutAddresses: array expected");
                    message.payoutAddresses = [];
                    for (var i = 0; i < object.payoutAddresses.length; ++i)
                        message.payoutAddresses[i] = String(object.payoutAddresses[i]);
                }
                if (object.payouts) {
                    if (!Array.isArray(object.payouts))
                        throw TypeError(".protocol.PayoutRequestRecord.payouts: array expected");
                    message.payouts = [];
                    for (var i = 0; i < object.payouts.length; ++i) {
                        if (typeof object.payouts[i] !== "object")
                            throw TypeError(".protocol.PayoutRequestRecord.payouts: object expected");
                        message.payouts[i] = $root.protocol.PayoutRecord.fromObject(object.payouts[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a PayoutRequestRecord message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.PayoutRequestRecord
             * @static
             * @param {protocol.PayoutRequestRecord} message PayoutRequestRecord
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PayoutRequestRecord.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.payoutTags = [];
                    object.refillSystemAddresses = [];
                    object.tags = [];
                    object.payoutAddresses = [];
                    object.payouts = [];
                }
                if (options.defaults) {
                    object.id = 0;
                    object.to = "";
                    object.requestedAmount = "";
                    object.paidAmount = "";
                    object.status = options.enums === String ? "UNK_PAYOUT_REQUEST_STATUS" : 0;
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.chain = options.enums === String ? "UNK_CHAIN" : 0;
                    object.fromEntity = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAt = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updatedAt = options.longs === String ? "0" : 0;
                    object.type = options.enums === String ? "UNK_PAYOUT_REQUEST_TYPE" : 0;
                    object.operationId = "";
                    object.hasEnoughFunds = false;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.to != null && message.hasOwnProperty("to"))
                    object.to = message.to;
                if (message.requestedAmount != null && message.hasOwnProperty("requestedAmount"))
                    object.requestedAmount = message.requestedAmount;
                if (message.paidAmount != null && message.hasOwnProperty("paidAmount"))
                    object.paidAmount = message.paidAmount;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.protocol.PayoutRequestStatus[message.status] : message.status;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.chain != null && message.hasOwnProperty("chain"))
                    object.chain = options.enums === String ? $root.protocol.Chain[message.chain] : message.chain;
                if (message.fromEntity != null && message.hasOwnProperty("fromEntity"))
                    object.fromEntity = message.fromEntity;
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (typeof message.createdAt === "number")
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                    else
                        object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber(true) : message.createdAt;
                if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                    if (typeof message.updatedAt === "number")
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                    else
                        object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber(true) : message.updatedAt;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.protocol.PayoutRequestType[message.type] : message.type;
                if (message.operationId != null && message.hasOwnProperty("operationId"))
                    object.operationId = message.operationId;
                if (message.hasEnoughFunds != null && message.hasOwnProperty("hasEnoughFunds"))
                    object.hasEnoughFunds = message.hasEnoughFunds;
                if (message.payoutTags && message.payoutTags.length) {
                    object.payoutTags = [];
                    for (var j = 0; j < message.payoutTags.length; ++j)
                        object.payoutTags[j] = message.payoutTags[j];
                }
                if (message.refillSystemAddresses && message.refillSystemAddresses.length) {
                    object.refillSystemAddresses = [];
                    for (var j = 0; j < message.refillSystemAddresses.length; ++j)
                        object.refillSystemAddresses[j] = $root.protocol.PRSystemAddress.toObject(message.refillSystemAddresses[j], options);
                }
                if (message.tags && message.tags.length) {
                    object.tags = [];
                    for (var j = 0; j < message.tags.length; ++j)
                        object.tags[j] = message.tags[j];
                }
                if (message.payoutAddresses && message.payoutAddresses.length) {
                    object.payoutAddresses = [];
                    for (var j = 0; j < message.payoutAddresses.length; ++j)
                        object.payoutAddresses[j] = message.payoutAddresses[j];
                }
                if (message.payouts && message.payouts.length) {
                    object.payouts = [];
                    for (var j = 0; j < message.payouts.length; ++j)
                        object.payouts[j] = $root.protocol.PayoutRecord.toObject(message.payouts[j], options);
                }
                return object;
            };
    
            /**
             * Converts this PayoutRequestRecord to JSON.
             * @function toJSON
             * @memberof protocol.PayoutRequestRecord
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PayoutRequestRecord.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return PayoutRequestRecord;
        })();
    
        /**
         * PayoutStatus enum.
         * @name protocol.PayoutStatus
         * @enum {number}
         * @property {number} UNK_PAYOUT_STATUS=0 UNK_PAYOUT_STATUS value
         * @property {number} PENDING_PAYOUT_STATUS=1 PENDING_PAYOUT_STATUS value
         * @property {number} PROCESSING_PAYOUT_STATUS=2 PROCESSING_PAYOUT_STATUS value
         * @property {number} COMPLETED_PAYOUT_STATUS=3 COMPLETED_PAYOUT_STATUS value
         * @property {number} FAILED_PAYOUT_STATUS=4 FAILED_PAYOUT_STATUS value
         */
        protocol.PayoutStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_PAYOUT_STATUS"] = 0;
            values[valuesById[1] = "PENDING_PAYOUT_STATUS"] = 1;
            values[valuesById[2] = "PROCESSING_PAYOUT_STATUS"] = 2;
            values[valuesById[3] = "COMPLETED_PAYOUT_STATUS"] = 3;
            values[valuesById[4] = "FAILED_PAYOUT_STATUS"] = 4;
            return values;
        })();
    
        protocol.PayoutRecord = (function() {
    
            /**
             * Properties of a PayoutRecord.
             * @memberof protocol
             * @interface IPayoutRecord
             * @property {number|null} [id] PayoutRecord id
             * @property {string|null} [from] PayoutRecord from
             * @property {string|null} [amount] PayoutRecord amount
             * @property {protocol.PayoutStatus|null} [status] PayoutRecord status
             * @property {string|null} [failReason] PayoutRecord failReason
             * @property {Long|null} [createdAt] PayoutRecord createdAt
             * @property {Long|null} [updatedAt] PayoutRecord updatedAt
             * @property {string|null} [txHash] PayoutRecord txHash
             * @property {protocol.Currency|null} [currency] PayoutRecord currency
             * @property {number|null} [txId] PayoutRecord txId
             * @property {Array.<string>|null} [addressTags] PayoutRecord addressTags
             */
    
            /**
             * Constructs a new PayoutRecord.
             * @memberof protocol
             * @classdesc Represents a PayoutRecord.
             * @implements IPayoutRecord
             * @constructor
             * @param {protocol.IPayoutRecord=} [properties] Properties to set
             */
            function PayoutRecord(properties) {
                this.addressTags = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PayoutRecord id.
             * @member {number} id
             * @memberof protocol.PayoutRecord
             * @instance
             */
            PayoutRecord.prototype.id = 0;
    
            /**
             * PayoutRecord from.
             * @member {string} from
             * @memberof protocol.PayoutRecord
             * @instance
             */
            PayoutRecord.prototype.from = "";
    
            /**
             * PayoutRecord amount.
             * @member {string} amount
             * @memberof protocol.PayoutRecord
             * @instance
             */
            PayoutRecord.prototype.amount = "";
    
            /**
             * PayoutRecord status.
             * @member {protocol.PayoutStatus} status
             * @memberof protocol.PayoutRecord
             * @instance
             */
            PayoutRecord.prototype.status = 0;
    
            /**
             * PayoutRecord failReason.
             * @member {string} failReason
             * @memberof protocol.PayoutRecord
             * @instance
             */
            PayoutRecord.prototype.failReason = "";
    
            /**
             * PayoutRecord createdAt.
             * @member {Long} createdAt
             * @memberof protocol.PayoutRecord
             * @instance
             */
            PayoutRecord.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * PayoutRecord updatedAt.
             * @member {Long} updatedAt
             * @memberof protocol.PayoutRecord
             * @instance
             */
            PayoutRecord.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * PayoutRecord txHash.
             * @member {string} txHash
             * @memberof protocol.PayoutRecord
             * @instance
             */
            PayoutRecord.prototype.txHash = "";
    
            /**
             * PayoutRecord currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.PayoutRecord
             * @instance
             */
            PayoutRecord.prototype.currency = 0;
    
            /**
             * PayoutRecord txId.
             * @member {number} txId
             * @memberof protocol.PayoutRecord
             * @instance
             */
            PayoutRecord.prototype.txId = 0;
    
            /**
             * PayoutRecord addressTags.
             * @member {Array.<string>} addressTags
             * @memberof protocol.PayoutRecord
             * @instance
             */
            PayoutRecord.prototype.addressTags = $util.emptyArray;
    
            /**
             * Creates a new PayoutRecord instance using the specified properties.
             * @function create
             * @memberof protocol.PayoutRecord
             * @static
             * @param {protocol.IPayoutRecord=} [properties] Properties to set
             * @returns {protocol.PayoutRecord} PayoutRecord instance
             */
            PayoutRecord.create = function create(properties) {
                return new PayoutRecord(properties);
            };
    
            /**
             * Encodes the specified PayoutRecord message. Does not implicitly {@link protocol.PayoutRecord.verify|verify} messages.
             * @function encode
             * @memberof protocol.PayoutRecord
             * @static
             * @param {protocol.IPayoutRecord} message PayoutRecord message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PayoutRecord.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.from);
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.amount);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.status);
                if (message.failReason != null && Object.hasOwnProperty.call(message, "failReason"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.failReason);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.createdAt);
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.updatedAt);
                if (message.txHash != null && Object.hasOwnProperty.call(message, "txHash"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.txHash);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.currency);
                if (message.txId != null && Object.hasOwnProperty.call(message, "txId"))
                    writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.txId);
                if (message.addressTags != null && message.addressTags.length)
                    for (var i = 0; i < message.addressTags.length; ++i)
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.addressTags[i]);
                return writer;
            };
    
            /**
             * Encodes the specified PayoutRecord message, length delimited. Does not implicitly {@link protocol.PayoutRecord.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.PayoutRecord
             * @static
             * @param {protocol.IPayoutRecord} message PayoutRecord message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PayoutRecord.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PayoutRecord message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.PayoutRecord
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.PayoutRecord} PayoutRecord
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PayoutRecord.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.PayoutRecord();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint32();
                        break;
                    case 2:
                        message.from = reader.string();
                        break;
                    case 3:
                        message.amount = reader.string();
                        break;
                    case 4:
                        message.status = reader.int32();
                        break;
                    case 5:
                        message.failReason = reader.string();
                        break;
                    case 6:
                        message.createdAt = reader.uint64();
                        break;
                    case 7:
                        message.updatedAt = reader.uint64();
                        break;
                    case 8:
                        message.txHash = reader.string();
                        break;
                    case 9:
                        message.currency = reader.int32();
                        break;
                    case 10:
                        message.txId = reader.uint32();
                        break;
                    case 11:
                        if (!(message.addressTags && message.addressTags.length))
                            message.addressTags = [];
                        message.addressTags.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PayoutRecord message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.PayoutRecord
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.PayoutRecord} PayoutRecord
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PayoutRecord.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PayoutRecord message.
             * @function verify
             * @memberof protocol.PayoutRecord
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PayoutRecord.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.from != null && message.hasOwnProperty("from"))
                    if (!$util.isString(message.from))
                        return "from: string expected";
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (!$util.isString(message.amount))
                        return "amount: string expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.failReason != null && message.hasOwnProperty("failReason"))
                    if (!$util.isString(message.failReason))
                        return "failReason: string expected";
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                        return "createdAt: integer|Long expected";
                if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                    if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                        return "updatedAt: integer|Long expected";
                if (message.txHash != null && message.hasOwnProperty("txHash"))
                    if (!$util.isString(message.txHash))
                        return "txHash: string expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.txId != null && message.hasOwnProperty("txId"))
                    if (!$util.isInteger(message.txId))
                        return "txId: integer expected";
                if (message.addressTags != null && message.hasOwnProperty("addressTags")) {
                    if (!Array.isArray(message.addressTags))
                        return "addressTags: array expected";
                    for (var i = 0; i < message.addressTags.length; ++i)
                        if (!$util.isString(message.addressTags[i]))
                            return "addressTags: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a PayoutRecord message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.PayoutRecord
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.PayoutRecord} PayoutRecord
             */
            PayoutRecord.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.PayoutRecord)
                    return object;
                var message = new $root.protocol.PayoutRecord();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.from != null)
                    message.from = String(object.from);
                if (object.amount != null)
                    message.amount = String(object.amount);
                switch (object.status) {
                case "UNK_PAYOUT_STATUS":
                case 0:
                    message.status = 0;
                    break;
                case "PENDING_PAYOUT_STATUS":
                case 1:
                    message.status = 1;
                    break;
                case "PROCESSING_PAYOUT_STATUS":
                case 2:
                    message.status = 2;
                    break;
                case "COMPLETED_PAYOUT_STATUS":
                case 3:
                    message.status = 3;
                    break;
                case "FAILED_PAYOUT_STATUS":
                case 4:
                    message.status = 4;
                    break;
                }
                if (object.failReason != null)
                    message.failReason = String(object.failReason);
                if (object.createdAt != null)
                    if ($util.Long)
                        (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = true;
                    else if (typeof object.createdAt === "string")
                        message.createdAt = parseInt(object.createdAt, 10);
                    else if (typeof object.createdAt === "number")
                        message.createdAt = object.createdAt;
                    else if (typeof object.createdAt === "object")
                        message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber(true);
                if (object.updatedAt != null)
                    if ($util.Long)
                        (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = true;
                    else if (typeof object.updatedAt === "string")
                        message.updatedAt = parseInt(object.updatedAt, 10);
                    else if (typeof object.updatedAt === "number")
                        message.updatedAt = object.updatedAt;
                    else if (typeof object.updatedAt === "object")
                        message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber(true);
                if (object.txHash != null)
                    message.txHash = String(object.txHash);
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                if (object.txId != null)
                    message.txId = object.txId >>> 0;
                if (object.addressTags) {
                    if (!Array.isArray(object.addressTags))
                        throw TypeError(".protocol.PayoutRecord.addressTags: array expected");
                    message.addressTags = [];
                    for (var i = 0; i < object.addressTags.length; ++i)
                        message.addressTags[i] = String(object.addressTags[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a PayoutRecord message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.PayoutRecord
             * @static
             * @param {protocol.PayoutRecord} message PayoutRecord
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PayoutRecord.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.addressTags = [];
                if (options.defaults) {
                    object.id = 0;
                    object.from = "";
                    object.amount = "";
                    object.status = options.enums === String ? "UNK_PAYOUT_STATUS" : 0;
                    object.failReason = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAt = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updatedAt = options.longs === String ? "0" : 0;
                    object.txHash = "";
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.txId = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.from != null && message.hasOwnProperty("from"))
                    object.from = message.from;
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = message.amount;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.protocol.PayoutStatus[message.status] : message.status;
                if (message.failReason != null && message.hasOwnProperty("failReason"))
                    object.failReason = message.failReason;
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (typeof message.createdAt === "number")
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                    else
                        object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber(true) : message.createdAt;
                if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                    if (typeof message.updatedAt === "number")
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                    else
                        object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber(true) : message.updatedAt;
                if (message.txHash != null && message.hasOwnProperty("txHash"))
                    object.txHash = message.txHash;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.txId != null && message.hasOwnProperty("txId"))
                    object.txId = message.txId;
                if (message.addressTags && message.addressTags.length) {
                    object.addressTags = [];
                    for (var j = 0; j < message.addressTags.length; ++j)
                        object.addressTags[j] = message.addressTags[j];
                }
                return object;
            };
    
            /**
             * Converts this PayoutRecord to JSON.
             * @function toJSON
             * @memberof protocol.PayoutRecord
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PayoutRecord.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return PayoutRecord;
        })();
    
        protocol.ListPayoutRequestsRequest = (function() {
    
            /**
             * Properties of a ListPayoutRequestsRequest.
             * @memberof protocol
             * @interface IListPayoutRequestsRequest
             * @property {protocol.PayoutRequestStatus|null} [filterStatus] ListPayoutRequestsRequest filterStatus
             * @property {protocol.Currency|null} [filterCurrency] ListPayoutRequestsRequest filterCurrency
             * @property {protocol.Chain|null} [filterChain] ListPayoutRequestsRequest filterChain
             * @property {number|null} [lastSeenId] ListPayoutRequestsRequest lastSeenId
             */
    
            /**
             * Constructs a new ListPayoutRequestsRequest.
             * @memberof protocol
             * @classdesc Represents a ListPayoutRequestsRequest.
             * @implements IListPayoutRequestsRequest
             * @constructor
             * @param {protocol.IListPayoutRequestsRequest=} [properties] Properties to set
             */
            function ListPayoutRequestsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ListPayoutRequestsRequest filterStatus.
             * @member {protocol.PayoutRequestStatus} filterStatus
             * @memberof protocol.ListPayoutRequestsRequest
             * @instance
             */
            ListPayoutRequestsRequest.prototype.filterStatus = 0;
    
            /**
             * ListPayoutRequestsRequest filterCurrency.
             * @member {protocol.Currency} filterCurrency
             * @memberof protocol.ListPayoutRequestsRequest
             * @instance
             */
            ListPayoutRequestsRequest.prototype.filterCurrency = 0;
    
            /**
             * ListPayoutRequestsRequest filterChain.
             * @member {protocol.Chain} filterChain
             * @memberof protocol.ListPayoutRequestsRequest
             * @instance
             */
            ListPayoutRequestsRequest.prototype.filterChain = 0;
    
            /**
             * ListPayoutRequestsRequest lastSeenId.
             * @member {number} lastSeenId
             * @memberof protocol.ListPayoutRequestsRequest
             * @instance
             */
            ListPayoutRequestsRequest.prototype.lastSeenId = 0;
    
            /**
             * Creates a new ListPayoutRequestsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ListPayoutRequestsRequest
             * @static
             * @param {protocol.IListPayoutRequestsRequest=} [properties] Properties to set
             * @returns {protocol.ListPayoutRequestsRequest} ListPayoutRequestsRequest instance
             */
            ListPayoutRequestsRequest.create = function create(properties) {
                return new ListPayoutRequestsRequest(properties);
            };
    
            /**
             * Encodes the specified ListPayoutRequestsRequest message. Does not implicitly {@link protocol.ListPayoutRequestsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ListPayoutRequestsRequest
             * @static
             * @param {protocol.IListPayoutRequestsRequest} message ListPayoutRequestsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListPayoutRequestsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.filterStatus != null && Object.hasOwnProperty.call(message, "filterStatus"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.filterStatus);
                if (message.filterCurrency != null && Object.hasOwnProperty.call(message, "filterCurrency"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.filterCurrency);
                if (message.filterChain != null && Object.hasOwnProperty.call(message, "filterChain"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.filterChain);
                if (message.lastSeenId != null && Object.hasOwnProperty.call(message, "lastSeenId"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.lastSeenId);
                return writer;
            };
    
            /**
             * Encodes the specified ListPayoutRequestsRequest message, length delimited. Does not implicitly {@link protocol.ListPayoutRequestsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ListPayoutRequestsRequest
             * @static
             * @param {protocol.IListPayoutRequestsRequest} message ListPayoutRequestsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListPayoutRequestsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ListPayoutRequestsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ListPayoutRequestsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ListPayoutRequestsRequest} ListPayoutRequestsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListPayoutRequestsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ListPayoutRequestsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.filterStatus = reader.int32();
                        break;
                    case 2:
                        message.filterCurrency = reader.int32();
                        break;
                    case 3:
                        message.filterChain = reader.int32();
                        break;
                    case 4:
                        message.lastSeenId = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ListPayoutRequestsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ListPayoutRequestsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ListPayoutRequestsRequest} ListPayoutRequestsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListPayoutRequestsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ListPayoutRequestsRequest message.
             * @function verify
             * @memberof protocol.ListPayoutRequestsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ListPayoutRequestsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.filterStatus != null && message.hasOwnProperty("filterStatus"))
                    switch (message.filterStatus) {
                    default:
                        return "filterStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.filterCurrency != null && message.hasOwnProperty("filterCurrency"))
                    switch (message.filterCurrency) {
                    default:
                        return "filterCurrency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.filterChain != null && message.hasOwnProperty("filterChain"))
                    switch (message.filterChain) {
                    default:
                        return "filterChain: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.lastSeenId != null && message.hasOwnProperty("lastSeenId"))
                    if (!$util.isInteger(message.lastSeenId))
                        return "lastSeenId: integer expected";
                return null;
            };
    
            /**
             * Creates a ListPayoutRequestsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ListPayoutRequestsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ListPayoutRequestsRequest} ListPayoutRequestsRequest
             */
            ListPayoutRequestsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ListPayoutRequestsRequest)
                    return object;
                var message = new $root.protocol.ListPayoutRequestsRequest();
                switch (object.filterStatus) {
                case "UNK_PAYOUT_REQUEST_STATUS":
                case 0:
                    message.filterStatus = 0;
                    break;
                case "PENDING_PAYOUT_REQUEST_STATUS":
                case 1:
                    message.filterStatus = 1;
                    break;
                case "PROCESSING_PAYOUT_REQUEST_STATUS":
                case 2:
                    message.filterStatus = 2;
                    break;
                case "COMPLETED_PAYOUT_REQUEST_STATUS":
                case 4:
                    message.filterStatus = 4;
                    break;
                case "FAILED_PAYOUT_REQUEST_STATUS":
                case 5:
                    message.filterStatus = 5;
                    break;
                case "CANCELED_PAYOUT_REQUEST_STATUS":
                case 6:
                    message.filterStatus = 6;
                    break;
                }
                switch (object.filterCurrency) {
                case "UNK_CUR":
                case 0:
                    message.filterCurrency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.filterCurrency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.filterCurrency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.filterCurrency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.filterCurrency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.filterCurrency = 5;
                    break;
                }
                switch (object.filterChain) {
                case "UNK_CHAIN":
                case 0:
                    message.filterChain = 0;
                    break;
                case "BTC_CHAIN":
                case 1:
                    message.filterChain = 1;
                    break;
                case "ETH_CHAIN":
                case 2:
                    message.filterChain = 2;
                    break;
                case "TRX_CHAIN":
                case 3:
                    message.filterChain = 3;
                    break;
                }
                if (object.lastSeenId != null)
                    message.lastSeenId = object.lastSeenId >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a ListPayoutRequestsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ListPayoutRequestsRequest
             * @static
             * @param {protocol.ListPayoutRequestsRequest} message ListPayoutRequestsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListPayoutRequestsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.filterStatus = options.enums === String ? "UNK_PAYOUT_REQUEST_STATUS" : 0;
                    object.filterCurrency = options.enums === String ? "UNK_CUR" : 0;
                    object.filterChain = options.enums === String ? "UNK_CHAIN" : 0;
                    object.lastSeenId = 0;
                }
                if (message.filterStatus != null && message.hasOwnProperty("filterStatus"))
                    object.filterStatus = options.enums === String ? $root.protocol.PayoutRequestStatus[message.filterStatus] : message.filterStatus;
                if (message.filterCurrency != null && message.hasOwnProperty("filterCurrency"))
                    object.filterCurrency = options.enums === String ? $root.protocol.Currency[message.filterCurrency] : message.filterCurrency;
                if (message.filterChain != null && message.hasOwnProperty("filterChain"))
                    object.filterChain = options.enums === String ? $root.protocol.Chain[message.filterChain] : message.filterChain;
                if (message.lastSeenId != null && message.hasOwnProperty("lastSeenId"))
                    object.lastSeenId = message.lastSeenId;
                return object;
            };
    
            /**
             * Converts this ListPayoutRequestsRequest to JSON.
             * @function toJSON
             * @memberof protocol.ListPayoutRequestsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListPayoutRequestsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ListPayoutRequestsRequest;
        })();
    
        protocol.ListPayoutRequestsResponse = (function() {
    
            /**
             * Properties of a ListPayoutRequestsResponse.
             * @memberof protocol
             * @interface IListPayoutRequestsResponse
             * @property {Array.<protocol.IPayoutRequestRecord>|null} [payoutRequests] ListPayoutRequestsResponse payoutRequests
             */
    
            /**
             * Constructs a new ListPayoutRequestsResponse.
             * @memberof protocol
             * @classdesc Represents a ListPayoutRequestsResponse.
             * @implements IListPayoutRequestsResponse
             * @constructor
             * @param {protocol.IListPayoutRequestsResponse=} [properties] Properties to set
             */
            function ListPayoutRequestsResponse(properties) {
                this.payoutRequests = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ListPayoutRequestsResponse payoutRequests.
             * @member {Array.<protocol.IPayoutRequestRecord>} payoutRequests
             * @memberof protocol.ListPayoutRequestsResponse
             * @instance
             */
            ListPayoutRequestsResponse.prototype.payoutRequests = $util.emptyArray;
    
            /**
             * Creates a new ListPayoutRequestsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ListPayoutRequestsResponse
             * @static
             * @param {protocol.IListPayoutRequestsResponse=} [properties] Properties to set
             * @returns {protocol.ListPayoutRequestsResponse} ListPayoutRequestsResponse instance
             */
            ListPayoutRequestsResponse.create = function create(properties) {
                return new ListPayoutRequestsResponse(properties);
            };
    
            /**
             * Encodes the specified ListPayoutRequestsResponse message. Does not implicitly {@link protocol.ListPayoutRequestsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ListPayoutRequestsResponse
             * @static
             * @param {protocol.IListPayoutRequestsResponse} message ListPayoutRequestsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListPayoutRequestsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.payoutRequests != null && message.payoutRequests.length)
                    for (var i = 0; i < message.payoutRequests.length; ++i)
                        $root.protocol.PayoutRequestRecord.encode(message.payoutRequests[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ListPayoutRequestsResponse message, length delimited. Does not implicitly {@link protocol.ListPayoutRequestsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ListPayoutRequestsResponse
             * @static
             * @param {protocol.IListPayoutRequestsResponse} message ListPayoutRequestsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListPayoutRequestsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ListPayoutRequestsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ListPayoutRequestsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ListPayoutRequestsResponse} ListPayoutRequestsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListPayoutRequestsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ListPayoutRequestsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.payoutRequests && message.payoutRequests.length))
                            message.payoutRequests = [];
                        message.payoutRequests.push($root.protocol.PayoutRequestRecord.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ListPayoutRequestsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ListPayoutRequestsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ListPayoutRequestsResponse} ListPayoutRequestsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListPayoutRequestsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ListPayoutRequestsResponse message.
             * @function verify
             * @memberof protocol.ListPayoutRequestsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ListPayoutRequestsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.payoutRequests != null && message.hasOwnProperty("payoutRequests")) {
                    if (!Array.isArray(message.payoutRequests))
                        return "payoutRequests: array expected";
                    for (var i = 0; i < message.payoutRequests.length; ++i) {
                        var error = $root.protocol.PayoutRequestRecord.verify(message.payoutRequests[i]);
                        if (error)
                            return "payoutRequests." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ListPayoutRequestsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ListPayoutRequestsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ListPayoutRequestsResponse} ListPayoutRequestsResponse
             */
            ListPayoutRequestsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ListPayoutRequestsResponse)
                    return object;
                var message = new $root.protocol.ListPayoutRequestsResponse();
                if (object.payoutRequests) {
                    if (!Array.isArray(object.payoutRequests))
                        throw TypeError(".protocol.ListPayoutRequestsResponse.payoutRequests: array expected");
                    message.payoutRequests = [];
                    for (var i = 0; i < object.payoutRequests.length; ++i) {
                        if (typeof object.payoutRequests[i] !== "object")
                            throw TypeError(".protocol.ListPayoutRequestsResponse.payoutRequests: object expected");
                        message.payoutRequests[i] = $root.protocol.PayoutRequestRecord.fromObject(object.payoutRequests[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ListPayoutRequestsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ListPayoutRequestsResponse
             * @static
             * @param {protocol.ListPayoutRequestsResponse} message ListPayoutRequestsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListPayoutRequestsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.payoutRequests = [];
                if (message.payoutRequests && message.payoutRequests.length) {
                    object.payoutRequests = [];
                    for (var j = 0; j < message.payoutRequests.length; ++j)
                        object.payoutRequests[j] = $root.protocol.PayoutRequestRecord.toObject(message.payoutRequests[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ListPayoutRequestsResponse to JSON.
             * @function toJSON
             * @memberof protocol.ListPayoutRequestsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListPayoutRequestsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ListPayoutRequestsResponse;
        })();
    
        protocol.PayoutDetailsRequest = (function() {
    
            /**
             * Properties of a PayoutDetailsRequest.
             * @memberof protocol
             * @interface IPayoutDetailsRequest
             * @property {number|null} [id] PayoutDetailsRequest id
             */
    
            /**
             * Constructs a new PayoutDetailsRequest.
             * @memberof protocol
             * @classdesc Represents a PayoutDetailsRequest.
             * @implements IPayoutDetailsRequest
             * @constructor
             * @param {protocol.IPayoutDetailsRequest=} [properties] Properties to set
             */
            function PayoutDetailsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PayoutDetailsRequest id.
             * @member {number} id
             * @memberof protocol.PayoutDetailsRequest
             * @instance
             */
            PayoutDetailsRequest.prototype.id = 0;
    
            /**
             * Creates a new PayoutDetailsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.PayoutDetailsRequest
             * @static
             * @param {protocol.IPayoutDetailsRequest=} [properties] Properties to set
             * @returns {protocol.PayoutDetailsRequest} PayoutDetailsRequest instance
             */
            PayoutDetailsRequest.create = function create(properties) {
                return new PayoutDetailsRequest(properties);
            };
    
            /**
             * Encodes the specified PayoutDetailsRequest message. Does not implicitly {@link protocol.PayoutDetailsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.PayoutDetailsRequest
             * @static
             * @param {protocol.IPayoutDetailsRequest} message PayoutDetailsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PayoutDetailsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                return writer;
            };
    
            /**
             * Encodes the specified PayoutDetailsRequest message, length delimited. Does not implicitly {@link protocol.PayoutDetailsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.PayoutDetailsRequest
             * @static
             * @param {protocol.IPayoutDetailsRequest} message PayoutDetailsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PayoutDetailsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PayoutDetailsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.PayoutDetailsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.PayoutDetailsRequest} PayoutDetailsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PayoutDetailsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.PayoutDetailsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PayoutDetailsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.PayoutDetailsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.PayoutDetailsRequest} PayoutDetailsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PayoutDetailsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PayoutDetailsRequest message.
             * @function verify
             * @memberof protocol.PayoutDetailsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PayoutDetailsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                return null;
            };
    
            /**
             * Creates a PayoutDetailsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.PayoutDetailsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.PayoutDetailsRequest} PayoutDetailsRequest
             */
            PayoutDetailsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.PayoutDetailsRequest)
                    return object;
                var message = new $root.protocol.PayoutDetailsRequest();
                if (object.id != null)
                    message.id = object.id >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a PayoutDetailsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.PayoutDetailsRequest
             * @static
             * @param {protocol.PayoutDetailsRequest} message PayoutDetailsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PayoutDetailsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.id = 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                return object;
            };
    
            /**
             * Converts this PayoutDetailsRequest to JSON.
             * @function toJSON
             * @memberof protocol.PayoutDetailsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PayoutDetailsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return PayoutDetailsRequest;
        })();
    
        protocol.PayoutDetailsResponse = (function() {
    
            /**
             * Properties of a PayoutDetailsResponse.
             * @memberof protocol
             * @interface IPayoutDetailsResponse
             * @property {protocol.IPayoutRecord|null} [payout] PayoutDetailsResponse payout
             */
    
            /**
             * Constructs a new PayoutDetailsResponse.
             * @memberof protocol
             * @classdesc Represents a PayoutDetailsResponse.
             * @implements IPayoutDetailsResponse
             * @constructor
             * @param {protocol.IPayoutDetailsResponse=} [properties] Properties to set
             */
            function PayoutDetailsResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PayoutDetailsResponse payout.
             * @member {protocol.IPayoutRecord|null|undefined} payout
             * @memberof protocol.PayoutDetailsResponse
             * @instance
             */
            PayoutDetailsResponse.prototype.payout = null;
    
            /**
             * Creates a new PayoutDetailsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.PayoutDetailsResponse
             * @static
             * @param {protocol.IPayoutDetailsResponse=} [properties] Properties to set
             * @returns {protocol.PayoutDetailsResponse} PayoutDetailsResponse instance
             */
            PayoutDetailsResponse.create = function create(properties) {
                return new PayoutDetailsResponse(properties);
            };
    
            /**
             * Encodes the specified PayoutDetailsResponse message. Does not implicitly {@link protocol.PayoutDetailsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.PayoutDetailsResponse
             * @static
             * @param {protocol.IPayoutDetailsResponse} message PayoutDetailsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PayoutDetailsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.payout != null && Object.hasOwnProperty.call(message, "payout"))
                    $root.protocol.PayoutRecord.encode(message.payout, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified PayoutDetailsResponse message, length delimited. Does not implicitly {@link protocol.PayoutDetailsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.PayoutDetailsResponse
             * @static
             * @param {protocol.IPayoutDetailsResponse} message PayoutDetailsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PayoutDetailsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PayoutDetailsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.PayoutDetailsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.PayoutDetailsResponse} PayoutDetailsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PayoutDetailsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.PayoutDetailsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.payout = $root.protocol.PayoutRecord.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PayoutDetailsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.PayoutDetailsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.PayoutDetailsResponse} PayoutDetailsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PayoutDetailsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PayoutDetailsResponse message.
             * @function verify
             * @memberof protocol.PayoutDetailsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PayoutDetailsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.payout != null && message.hasOwnProperty("payout")) {
                    var error = $root.protocol.PayoutRecord.verify(message.payout);
                    if (error)
                        return "payout." + error;
                }
                return null;
            };
    
            /**
             * Creates a PayoutDetailsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.PayoutDetailsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.PayoutDetailsResponse} PayoutDetailsResponse
             */
            PayoutDetailsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.PayoutDetailsResponse)
                    return object;
                var message = new $root.protocol.PayoutDetailsResponse();
                if (object.payout != null) {
                    if (typeof object.payout !== "object")
                        throw TypeError(".protocol.PayoutDetailsResponse.payout: object expected");
                    message.payout = $root.protocol.PayoutRecord.fromObject(object.payout);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a PayoutDetailsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.PayoutDetailsResponse
             * @static
             * @param {protocol.PayoutDetailsResponse} message PayoutDetailsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PayoutDetailsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.payout = null;
                if (message.payout != null && message.hasOwnProperty("payout"))
                    object.payout = $root.protocol.PayoutRecord.toObject(message.payout, options);
                return object;
            };
    
            /**
             * Converts this PayoutDetailsResponse to JSON.
             * @function toJSON
             * @memberof protocol.PayoutDetailsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PayoutDetailsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return PayoutDetailsResponse;
        })();
    
        protocol.PayoutRequestDetailsRequest = (function() {
    
            /**
             * Properties of a PayoutRequestDetailsRequest.
             * @memberof protocol
             * @interface IPayoutRequestDetailsRequest
             * @property {number|null} [id] PayoutRequestDetailsRequest id
             */
    
            /**
             * Constructs a new PayoutRequestDetailsRequest.
             * @memberof protocol
             * @classdesc Represents a PayoutRequestDetailsRequest.
             * @implements IPayoutRequestDetailsRequest
             * @constructor
             * @param {protocol.IPayoutRequestDetailsRequest=} [properties] Properties to set
             */
            function PayoutRequestDetailsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PayoutRequestDetailsRequest id.
             * @member {number} id
             * @memberof protocol.PayoutRequestDetailsRequest
             * @instance
             */
            PayoutRequestDetailsRequest.prototype.id = 0;
    
            /**
             * Creates a new PayoutRequestDetailsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.PayoutRequestDetailsRequest
             * @static
             * @param {protocol.IPayoutRequestDetailsRequest=} [properties] Properties to set
             * @returns {protocol.PayoutRequestDetailsRequest} PayoutRequestDetailsRequest instance
             */
            PayoutRequestDetailsRequest.create = function create(properties) {
                return new PayoutRequestDetailsRequest(properties);
            };
    
            /**
             * Encodes the specified PayoutRequestDetailsRequest message. Does not implicitly {@link protocol.PayoutRequestDetailsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.PayoutRequestDetailsRequest
             * @static
             * @param {protocol.IPayoutRequestDetailsRequest} message PayoutRequestDetailsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PayoutRequestDetailsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                return writer;
            };
    
            /**
             * Encodes the specified PayoutRequestDetailsRequest message, length delimited. Does not implicitly {@link protocol.PayoutRequestDetailsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.PayoutRequestDetailsRequest
             * @static
             * @param {protocol.IPayoutRequestDetailsRequest} message PayoutRequestDetailsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PayoutRequestDetailsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PayoutRequestDetailsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.PayoutRequestDetailsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.PayoutRequestDetailsRequest} PayoutRequestDetailsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PayoutRequestDetailsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.PayoutRequestDetailsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PayoutRequestDetailsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.PayoutRequestDetailsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.PayoutRequestDetailsRequest} PayoutRequestDetailsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PayoutRequestDetailsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PayoutRequestDetailsRequest message.
             * @function verify
             * @memberof protocol.PayoutRequestDetailsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PayoutRequestDetailsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                return null;
            };
    
            /**
             * Creates a PayoutRequestDetailsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.PayoutRequestDetailsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.PayoutRequestDetailsRequest} PayoutRequestDetailsRequest
             */
            PayoutRequestDetailsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.PayoutRequestDetailsRequest)
                    return object;
                var message = new $root.protocol.PayoutRequestDetailsRequest();
                if (object.id != null)
                    message.id = object.id >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a PayoutRequestDetailsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.PayoutRequestDetailsRequest
             * @static
             * @param {protocol.PayoutRequestDetailsRequest} message PayoutRequestDetailsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PayoutRequestDetailsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.id = 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                return object;
            };
    
            /**
             * Converts this PayoutRequestDetailsRequest to JSON.
             * @function toJSON
             * @memberof protocol.PayoutRequestDetailsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PayoutRequestDetailsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return PayoutRequestDetailsRequest;
        })();
    
        protocol.PayoutRequestDetailsResponse = (function() {
    
            /**
             * Properties of a PayoutRequestDetailsResponse.
             * @memberof protocol
             * @interface IPayoutRequestDetailsResponse
             * @property {protocol.IPayoutRequestRecord|null} [payoutRequest] PayoutRequestDetailsResponse payoutRequest
             */
    
            /**
             * Constructs a new PayoutRequestDetailsResponse.
             * @memberof protocol
             * @classdesc Represents a PayoutRequestDetailsResponse.
             * @implements IPayoutRequestDetailsResponse
             * @constructor
             * @param {protocol.IPayoutRequestDetailsResponse=} [properties] Properties to set
             */
            function PayoutRequestDetailsResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PayoutRequestDetailsResponse payoutRequest.
             * @member {protocol.IPayoutRequestRecord|null|undefined} payoutRequest
             * @memberof protocol.PayoutRequestDetailsResponse
             * @instance
             */
            PayoutRequestDetailsResponse.prototype.payoutRequest = null;
    
            /**
             * Creates a new PayoutRequestDetailsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.PayoutRequestDetailsResponse
             * @static
             * @param {protocol.IPayoutRequestDetailsResponse=} [properties] Properties to set
             * @returns {protocol.PayoutRequestDetailsResponse} PayoutRequestDetailsResponse instance
             */
            PayoutRequestDetailsResponse.create = function create(properties) {
                return new PayoutRequestDetailsResponse(properties);
            };
    
            /**
             * Encodes the specified PayoutRequestDetailsResponse message. Does not implicitly {@link protocol.PayoutRequestDetailsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.PayoutRequestDetailsResponse
             * @static
             * @param {protocol.IPayoutRequestDetailsResponse} message PayoutRequestDetailsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PayoutRequestDetailsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.payoutRequest != null && Object.hasOwnProperty.call(message, "payoutRequest"))
                    $root.protocol.PayoutRequestRecord.encode(message.payoutRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified PayoutRequestDetailsResponse message, length delimited. Does not implicitly {@link protocol.PayoutRequestDetailsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.PayoutRequestDetailsResponse
             * @static
             * @param {protocol.IPayoutRequestDetailsResponse} message PayoutRequestDetailsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PayoutRequestDetailsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PayoutRequestDetailsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.PayoutRequestDetailsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.PayoutRequestDetailsResponse} PayoutRequestDetailsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PayoutRequestDetailsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.PayoutRequestDetailsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.payoutRequest = $root.protocol.PayoutRequestRecord.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PayoutRequestDetailsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.PayoutRequestDetailsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.PayoutRequestDetailsResponse} PayoutRequestDetailsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PayoutRequestDetailsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PayoutRequestDetailsResponse message.
             * @function verify
             * @memberof protocol.PayoutRequestDetailsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PayoutRequestDetailsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.payoutRequest != null && message.hasOwnProperty("payoutRequest")) {
                    var error = $root.protocol.PayoutRequestRecord.verify(message.payoutRequest);
                    if (error)
                        return "payoutRequest." + error;
                }
                return null;
            };
    
            /**
             * Creates a PayoutRequestDetailsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.PayoutRequestDetailsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.PayoutRequestDetailsResponse} PayoutRequestDetailsResponse
             */
            PayoutRequestDetailsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.PayoutRequestDetailsResponse)
                    return object;
                var message = new $root.protocol.PayoutRequestDetailsResponse();
                if (object.payoutRequest != null) {
                    if (typeof object.payoutRequest !== "object")
                        throw TypeError(".protocol.PayoutRequestDetailsResponse.payoutRequest: object expected");
                    message.payoutRequest = $root.protocol.PayoutRequestRecord.fromObject(object.payoutRequest);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a PayoutRequestDetailsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.PayoutRequestDetailsResponse
             * @static
             * @param {protocol.PayoutRequestDetailsResponse} message PayoutRequestDetailsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PayoutRequestDetailsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.payoutRequest = null;
                if (message.payoutRequest != null && message.hasOwnProperty("payoutRequest"))
                    object.payoutRequest = $root.protocol.PayoutRequestRecord.toObject(message.payoutRequest, options);
                return object;
            };
    
            /**
             * Converts this PayoutRequestDetailsResponse to JSON.
             * @function toJSON
             * @memberof protocol.PayoutRequestDetailsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PayoutRequestDetailsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return PayoutRequestDetailsResponse;
        })();
    
        protocol.CancelPendingPayoutRequestRequest = (function() {
    
            /**
             * Properties of a CancelPendingPayoutRequestRequest.
             * @memberof protocol
             * @interface ICancelPendingPayoutRequestRequest
             * @property {number|null} [id] CancelPendingPayoutRequestRequest id
             */
    
            /**
             * Constructs a new CancelPendingPayoutRequestRequest.
             * @memberof protocol
             * @classdesc Represents a CancelPendingPayoutRequestRequest.
             * @implements ICancelPendingPayoutRequestRequest
             * @constructor
             * @param {protocol.ICancelPendingPayoutRequestRequest=} [properties] Properties to set
             */
            function CancelPendingPayoutRequestRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CancelPendingPayoutRequestRequest id.
             * @member {number} id
             * @memberof protocol.CancelPendingPayoutRequestRequest
             * @instance
             */
            CancelPendingPayoutRequestRequest.prototype.id = 0;
    
            /**
             * Creates a new CancelPendingPayoutRequestRequest instance using the specified properties.
             * @function create
             * @memberof protocol.CancelPendingPayoutRequestRequest
             * @static
             * @param {protocol.ICancelPendingPayoutRequestRequest=} [properties] Properties to set
             * @returns {protocol.CancelPendingPayoutRequestRequest} CancelPendingPayoutRequestRequest instance
             */
            CancelPendingPayoutRequestRequest.create = function create(properties) {
                return new CancelPendingPayoutRequestRequest(properties);
            };
    
            /**
             * Encodes the specified CancelPendingPayoutRequestRequest message. Does not implicitly {@link protocol.CancelPendingPayoutRequestRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.CancelPendingPayoutRequestRequest
             * @static
             * @param {protocol.ICancelPendingPayoutRequestRequest} message CancelPendingPayoutRequestRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelPendingPayoutRequestRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                return writer;
            };
    
            /**
             * Encodes the specified CancelPendingPayoutRequestRequest message, length delimited. Does not implicitly {@link protocol.CancelPendingPayoutRequestRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.CancelPendingPayoutRequestRequest
             * @static
             * @param {protocol.ICancelPendingPayoutRequestRequest} message CancelPendingPayoutRequestRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelPendingPayoutRequestRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CancelPendingPayoutRequestRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.CancelPendingPayoutRequestRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.CancelPendingPayoutRequestRequest} CancelPendingPayoutRequestRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelPendingPayoutRequestRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.CancelPendingPayoutRequestRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CancelPendingPayoutRequestRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.CancelPendingPayoutRequestRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.CancelPendingPayoutRequestRequest} CancelPendingPayoutRequestRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelPendingPayoutRequestRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CancelPendingPayoutRequestRequest message.
             * @function verify
             * @memberof protocol.CancelPendingPayoutRequestRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CancelPendingPayoutRequestRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                return null;
            };
    
            /**
             * Creates a CancelPendingPayoutRequestRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.CancelPendingPayoutRequestRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.CancelPendingPayoutRequestRequest} CancelPendingPayoutRequestRequest
             */
            CancelPendingPayoutRequestRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.CancelPendingPayoutRequestRequest)
                    return object;
                var message = new $root.protocol.CancelPendingPayoutRequestRequest();
                if (object.id != null)
                    message.id = object.id >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a CancelPendingPayoutRequestRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.CancelPendingPayoutRequestRequest
             * @static
             * @param {protocol.CancelPendingPayoutRequestRequest} message CancelPendingPayoutRequestRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CancelPendingPayoutRequestRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.id = 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                return object;
            };
    
            /**
             * Converts this CancelPendingPayoutRequestRequest to JSON.
             * @function toJSON
             * @memberof protocol.CancelPendingPayoutRequestRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CancelPendingPayoutRequestRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return CancelPendingPayoutRequestRequest;
        })();
    
        protocol.CancelPendingPayoutRequestResponse = (function() {
    
            /**
             * Properties of a CancelPendingPayoutRequestResponse.
             * @memberof protocol
             * @interface ICancelPendingPayoutRequestResponse
             */
    
            /**
             * Constructs a new CancelPendingPayoutRequestResponse.
             * @memberof protocol
             * @classdesc Represents a CancelPendingPayoutRequestResponse.
             * @implements ICancelPendingPayoutRequestResponse
             * @constructor
             * @param {protocol.ICancelPendingPayoutRequestResponse=} [properties] Properties to set
             */
            function CancelPendingPayoutRequestResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new CancelPendingPayoutRequestResponse instance using the specified properties.
             * @function create
             * @memberof protocol.CancelPendingPayoutRequestResponse
             * @static
             * @param {protocol.ICancelPendingPayoutRequestResponse=} [properties] Properties to set
             * @returns {protocol.CancelPendingPayoutRequestResponse} CancelPendingPayoutRequestResponse instance
             */
            CancelPendingPayoutRequestResponse.create = function create(properties) {
                return new CancelPendingPayoutRequestResponse(properties);
            };
    
            /**
             * Encodes the specified CancelPendingPayoutRequestResponse message. Does not implicitly {@link protocol.CancelPendingPayoutRequestResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.CancelPendingPayoutRequestResponse
             * @static
             * @param {protocol.ICancelPendingPayoutRequestResponse} message CancelPendingPayoutRequestResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelPendingPayoutRequestResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified CancelPendingPayoutRequestResponse message, length delimited. Does not implicitly {@link protocol.CancelPendingPayoutRequestResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.CancelPendingPayoutRequestResponse
             * @static
             * @param {protocol.ICancelPendingPayoutRequestResponse} message CancelPendingPayoutRequestResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelPendingPayoutRequestResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CancelPendingPayoutRequestResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.CancelPendingPayoutRequestResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.CancelPendingPayoutRequestResponse} CancelPendingPayoutRequestResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelPendingPayoutRequestResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.CancelPendingPayoutRequestResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CancelPendingPayoutRequestResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.CancelPendingPayoutRequestResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.CancelPendingPayoutRequestResponse} CancelPendingPayoutRequestResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelPendingPayoutRequestResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CancelPendingPayoutRequestResponse message.
             * @function verify
             * @memberof protocol.CancelPendingPayoutRequestResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CancelPendingPayoutRequestResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a CancelPendingPayoutRequestResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.CancelPendingPayoutRequestResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.CancelPendingPayoutRequestResponse} CancelPendingPayoutRequestResponse
             */
            CancelPendingPayoutRequestResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.CancelPendingPayoutRequestResponse)
                    return object;
                return new $root.protocol.CancelPendingPayoutRequestResponse();
            };
    
            /**
             * Creates a plain object from a CancelPendingPayoutRequestResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.CancelPendingPayoutRequestResponse
             * @static
             * @param {protocol.CancelPendingPayoutRequestResponse} message CancelPendingPayoutRequestResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CancelPendingPayoutRequestResponse.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this CancelPendingPayoutRequestResponse to JSON.
             * @function toJSON
             * @memberof protocol.CancelPendingPayoutRequestResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CancelPendingPayoutRequestResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return CancelPendingPayoutRequestResponse;
        })();
    
        protocol.MainAccountsRequest = (function() {
    
            /**
             * Properties of a MainAccountsRequest.
             * @memberof protocol
             * @interface IMainAccountsRequest
             */
    
            /**
             * Constructs a new MainAccountsRequest.
             * @memberof protocol
             * @classdesc Represents a MainAccountsRequest.
             * @implements IMainAccountsRequest
             * @constructor
             * @param {protocol.IMainAccountsRequest=} [properties] Properties to set
             */
            function MainAccountsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new MainAccountsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.MainAccountsRequest
             * @static
             * @param {protocol.IMainAccountsRequest=} [properties] Properties to set
             * @returns {protocol.MainAccountsRequest} MainAccountsRequest instance
             */
            MainAccountsRequest.create = function create(properties) {
                return new MainAccountsRequest(properties);
            };
    
            /**
             * Encodes the specified MainAccountsRequest message. Does not implicitly {@link protocol.MainAccountsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.MainAccountsRequest
             * @static
             * @param {protocol.IMainAccountsRequest} message MainAccountsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MainAccountsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified MainAccountsRequest message, length delimited. Does not implicitly {@link protocol.MainAccountsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.MainAccountsRequest
             * @static
             * @param {protocol.IMainAccountsRequest} message MainAccountsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MainAccountsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a MainAccountsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.MainAccountsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.MainAccountsRequest} MainAccountsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MainAccountsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.MainAccountsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a MainAccountsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.MainAccountsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.MainAccountsRequest} MainAccountsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MainAccountsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a MainAccountsRequest message.
             * @function verify
             * @memberof protocol.MainAccountsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MainAccountsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a MainAccountsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.MainAccountsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.MainAccountsRequest} MainAccountsRequest
             */
            MainAccountsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.MainAccountsRequest)
                    return object;
                return new $root.protocol.MainAccountsRequest();
            };
    
            /**
             * Creates a plain object from a MainAccountsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.MainAccountsRequest
             * @static
             * @param {protocol.MainAccountsRequest} message MainAccountsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MainAccountsRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this MainAccountsRequest to JSON.
             * @function toJSON
             * @memberof protocol.MainAccountsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MainAccountsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return MainAccountsRequest;
        })();
    
        protocol.MainAccountsResponse = (function() {
    
            /**
             * Properties of a MainAccountsResponse.
             * @memberof protocol
             * @interface IMainAccountsResponse
             * @property {Array.<protocol.MainAccountsResponse.IAccount>|null} [accounts] MainAccountsResponse accounts
             */
    
            /**
             * Constructs a new MainAccountsResponse.
             * @memberof protocol
             * @classdesc Represents a MainAccountsResponse.
             * @implements IMainAccountsResponse
             * @constructor
             * @param {protocol.IMainAccountsResponse=} [properties] Properties to set
             */
            function MainAccountsResponse(properties) {
                this.accounts = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * MainAccountsResponse accounts.
             * @member {Array.<protocol.MainAccountsResponse.IAccount>} accounts
             * @memberof protocol.MainAccountsResponse
             * @instance
             */
            MainAccountsResponse.prototype.accounts = $util.emptyArray;
    
            /**
             * Creates a new MainAccountsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.MainAccountsResponse
             * @static
             * @param {protocol.IMainAccountsResponse=} [properties] Properties to set
             * @returns {protocol.MainAccountsResponse} MainAccountsResponse instance
             */
            MainAccountsResponse.create = function create(properties) {
                return new MainAccountsResponse(properties);
            };
    
            /**
             * Encodes the specified MainAccountsResponse message. Does not implicitly {@link protocol.MainAccountsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.MainAccountsResponse
             * @static
             * @param {protocol.IMainAccountsResponse} message MainAccountsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MainAccountsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.accounts != null && message.accounts.length)
                    for (var i = 0; i < message.accounts.length; ++i)
                        $root.protocol.MainAccountsResponse.Account.encode(message.accounts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified MainAccountsResponse message, length delimited. Does not implicitly {@link protocol.MainAccountsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.MainAccountsResponse
             * @static
             * @param {protocol.IMainAccountsResponse} message MainAccountsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MainAccountsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a MainAccountsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.MainAccountsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.MainAccountsResponse} MainAccountsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MainAccountsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.MainAccountsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.accounts && message.accounts.length))
                            message.accounts = [];
                        message.accounts.push($root.protocol.MainAccountsResponse.Account.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a MainAccountsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.MainAccountsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.MainAccountsResponse} MainAccountsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MainAccountsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a MainAccountsResponse message.
             * @function verify
             * @memberof protocol.MainAccountsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MainAccountsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.accounts != null && message.hasOwnProperty("accounts")) {
                    if (!Array.isArray(message.accounts))
                        return "accounts: array expected";
                    for (var i = 0; i < message.accounts.length; ++i) {
                        var error = $root.protocol.MainAccountsResponse.Account.verify(message.accounts[i]);
                        if (error)
                            return "accounts." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a MainAccountsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.MainAccountsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.MainAccountsResponse} MainAccountsResponse
             */
            MainAccountsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.MainAccountsResponse)
                    return object;
                var message = new $root.protocol.MainAccountsResponse();
                if (object.accounts) {
                    if (!Array.isArray(object.accounts))
                        throw TypeError(".protocol.MainAccountsResponse.accounts: array expected");
                    message.accounts = [];
                    for (var i = 0; i < object.accounts.length; ++i) {
                        if (typeof object.accounts[i] !== "object")
                            throw TypeError(".protocol.MainAccountsResponse.accounts: object expected");
                        message.accounts[i] = $root.protocol.MainAccountsResponse.Account.fromObject(object.accounts[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a MainAccountsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.MainAccountsResponse
             * @static
             * @param {protocol.MainAccountsResponse} message MainAccountsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MainAccountsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.accounts = [];
                if (message.accounts && message.accounts.length) {
                    object.accounts = [];
                    for (var j = 0; j < message.accounts.length; ++j)
                        object.accounts[j] = $root.protocol.MainAccountsResponse.Account.toObject(message.accounts[j], options);
                }
                return object;
            };
    
            /**
             * Converts this MainAccountsResponse to JSON.
             * @function toJSON
             * @memberof protocol.MainAccountsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MainAccountsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            MainAccountsResponse.Account = (function() {
    
                /**
                 * Properties of an Account.
                 * @memberof protocol.MainAccountsResponse
                 * @interface IAccount
                 * @property {string|null} [address] Account address
                 * @property {string|null} [balance] Account balance
                 * @property {protocol.Chain|null} [chain] Account chain
                 */
    
                /**
                 * Constructs a new Account.
                 * @memberof protocol.MainAccountsResponse
                 * @classdesc Represents an Account.
                 * @implements IAccount
                 * @constructor
                 * @param {protocol.MainAccountsResponse.IAccount=} [properties] Properties to set
                 */
                function Account(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Account address.
                 * @member {string} address
                 * @memberof protocol.MainAccountsResponse.Account
                 * @instance
                 */
                Account.prototype.address = "";
    
                /**
                 * Account balance.
                 * @member {string} balance
                 * @memberof protocol.MainAccountsResponse.Account
                 * @instance
                 */
                Account.prototype.balance = "";
    
                /**
                 * Account chain.
                 * @member {protocol.Chain} chain
                 * @memberof protocol.MainAccountsResponse.Account
                 * @instance
                 */
                Account.prototype.chain = 0;
    
                /**
                 * Creates a new Account instance using the specified properties.
                 * @function create
                 * @memberof protocol.MainAccountsResponse.Account
                 * @static
                 * @param {protocol.MainAccountsResponse.IAccount=} [properties] Properties to set
                 * @returns {protocol.MainAccountsResponse.Account} Account instance
                 */
                Account.create = function create(properties) {
                    return new Account(properties);
                };
    
                /**
                 * Encodes the specified Account message. Does not implicitly {@link protocol.MainAccountsResponse.Account.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.MainAccountsResponse.Account
                 * @static
                 * @param {protocol.MainAccountsResponse.IAccount} message Account message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Account.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
                    if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.balance);
                    if (message.chain != null && Object.hasOwnProperty.call(message, "chain"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.chain);
                    return writer;
                };
    
                /**
                 * Encodes the specified Account message, length delimited. Does not implicitly {@link protocol.MainAccountsResponse.Account.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.MainAccountsResponse.Account
                 * @static
                 * @param {protocol.MainAccountsResponse.IAccount} message Account message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Account.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Account message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.MainAccountsResponse.Account
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.MainAccountsResponse.Account} Account
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Account.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.MainAccountsResponse.Account();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.address = reader.string();
                            break;
                        case 2:
                            message.balance = reader.string();
                            break;
                        case 3:
                            message.chain = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Account message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.MainAccountsResponse.Account
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.MainAccountsResponse.Account} Account
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Account.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Account message.
                 * @function verify
                 * @memberof protocol.MainAccountsResponse.Account
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Account.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    if (message.balance != null && message.hasOwnProperty("balance"))
                        if (!$util.isString(message.balance))
                            return "balance: string expected";
                    if (message.chain != null && message.hasOwnProperty("chain"))
                        switch (message.chain) {
                        default:
                            return "chain: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates an Account message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.MainAccountsResponse.Account
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.MainAccountsResponse.Account} Account
                 */
                Account.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.MainAccountsResponse.Account)
                        return object;
                    var message = new $root.protocol.MainAccountsResponse.Account();
                    if (object.address != null)
                        message.address = String(object.address);
                    if (object.balance != null)
                        message.balance = String(object.balance);
                    switch (object.chain) {
                    case "UNK_CHAIN":
                    case 0:
                        message.chain = 0;
                        break;
                    case "BTC_CHAIN":
                    case 1:
                        message.chain = 1;
                        break;
                    case "ETH_CHAIN":
                    case 2:
                        message.chain = 2;
                        break;
                    case "TRX_CHAIN":
                    case 3:
                        message.chain = 3;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an Account message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.MainAccountsResponse.Account
                 * @static
                 * @param {protocol.MainAccountsResponse.Account} message Account
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Account.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.address = "";
                        object.balance = "";
                        object.chain = options.enums === String ? "UNK_CHAIN" : 0;
                    }
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    if (message.balance != null && message.hasOwnProperty("balance"))
                        object.balance = message.balance;
                    if (message.chain != null && message.hasOwnProperty("chain"))
                        object.chain = options.enums === String ? $root.protocol.Chain[message.chain] : message.chain;
                    return object;
                };
    
                /**
                 * Converts this Account to JSON.
                 * @function toJSON
                 * @memberof protocol.MainAccountsResponse.Account
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Account.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Account;
            })();
    
            return MainAccountsResponse;
        })();
    
        protocol.ConvertCurrenciesEntry = (function() {
    
            /**
             * Properties of a ConvertCurrenciesEntry.
             * @memberof protocol
             * @interface IConvertCurrenciesEntry
             * @property {number|null} [fromCurrencyCode] ConvertCurrenciesEntry fromCurrencyCode
             * @property {number|null} [toCurrencyCode] ConvertCurrenciesEntry toCurrencyCode
             * @property {string|null} [amount] ConvertCurrenciesEntry amount
             */
    
            /**
             * Constructs a new ConvertCurrenciesEntry.
             * @memberof protocol
             * @classdesc Represents a ConvertCurrenciesEntry.
             * @implements IConvertCurrenciesEntry
             * @constructor
             * @param {protocol.IConvertCurrenciesEntry=} [properties] Properties to set
             */
            function ConvertCurrenciesEntry(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ConvertCurrenciesEntry fromCurrencyCode.
             * @member {number} fromCurrencyCode
             * @memberof protocol.ConvertCurrenciesEntry
             * @instance
             */
            ConvertCurrenciesEntry.prototype.fromCurrencyCode = 0;
    
            /**
             * ConvertCurrenciesEntry toCurrencyCode.
             * @member {number} toCurrencyCode
             * @memberof protocol.ConvertCurrenciesEntry
             * @instance
             */
            ConvertCurrenciesEntry.prototype.toCurrencyCode = 0;
    
            /**
             * ConvertCurrenciesEntry amount.
             * @member {string} amount
             * @memberof protocol.ConvertCurrenciesEntry
             * @instance
             */
            ConvertCurrenciesEntry.prototype.amount = "";
    
            /**
             * Creates a new ConvertCurrenciesEntry instance using the specified properties.
             * @function create
             * @memberof protocol.ConvertCurrenciesEntry
             * @static
             * @param {protocol.IConvertCurrenciesEntry=} [properties] Properties to set
             * @returns {protocol.ConvertCurrenciesEntry} ConvertCurrenciesEntry instance
             */
            ConvertCurrenciesEntry.create = function create(properties) {
                return new ConvertCurrenciesEntry(properties);
            };
    
            /**
             * Encodes the specified ConvertCurrenciesEntry message. Does not implicitly {@link protocol.ConvertCurrenciesEntry.verify|verify} messages.
             * @function encode
             * @memberof protocol.ConvertCurrenciesEntry
             * @static
             * @param {protocol.IConvertCurrenciesEntry} message ConvertCurrenciesEntry message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConvertCurrenciesEntry.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.fromCurrencyCode != null && Object.hasOwnProperty.call(message, "fromCurrencyCode"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.fromCurrencyCode);
                if (message.toCurrencyCode != null && Object.hasOwnProperty.call(message, "toCurrencyCode"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.toCurrencyCode);
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.amount);
                return writer;
            };
    
            /**
             * Encodes the specified ConvertCurrenciesEntry message, length delimited. Does not implicitly {@link protocol.ConvertCurrenciesEntry.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ConvertCurrenciesEntry
             * @static
             * @param {protocol.IConvertCurrenciesEntry} message ConvertCurrenciesEntry message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConvertCurrenciesEntry.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ConvertCurrenciesEntry message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ConvertCurrenciesEntry
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ConvertCurrenciesEntry} ConvertCurrenciesEntry
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConvertCurrenciesEntry.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ConvertCurrenciesEntry();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.fromCurrencyCode = reader.uint32();
                        break;
                    case 2:
                        message.toCurrencyCode = reader.uint32();
                        break;
                    case 3:
                        message.amount = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ConvertCurrenciesEntry message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ConvertCurrenciesEntry
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ConvertCurrenciesEntry} ConvertCurrenciesEntry
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConvertCurrenciesEntry.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ConvertCurrenciesEntry message.
             * @function verify
             * @memberof protocol.ConvertCurrenciesEntry
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConvertCurrenciesEntry.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.fromCurrencyCode != null && message.hasOwnProperty("fromCurrencyCode"))
                    if (!$util.isInteger(message.fromCurrencyCode))
                        return "fromCurrencyCode: integer expected";
                if (message.toCurrencyCode != null && message.hasOwnProperty("toCurrencyCode"))
                    if (!$util.isInteger(message.toCurrencyCode))
                        return "toCurrencyCode: integer expected";
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (!$util.isString(message.amount))
                        return "amount: string expected";
                return null;
            };
    
            /**
             * Creates a ConvertCurrenciesEntry message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ConvertCurrenciesEntry
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ConvertCurrenciesEntry} ConvertCurrenciesEntry
             */
            ConvertCurrenciesEntry.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ConvertCurrenciesEntry)
                    return object;
                var message = new $root.protocol.ConvertCurrenciesEntry();
                if (object.fromCurrencyCode != null)
                    message.fromCurrencyCode = object.fromCurrencyCode >>> 0;
                if (object.toCurrencyCode != null)
                    message.toCurrencyCode = object.toCurrencyCode >>> 0;
                if (object.amount != null)
                    message.amount = String(object.amount);
                return message;
            };
    
            /**
             * Creates a plain object from a ConvertCurrenciesEntry message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ConvertCurrenciesEntry
             * @static
             * @param {protocol.ConvertCurrenciesEntry} message ConvertCurrenciesEntry
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConvertCurrenciesEntry.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.fromCurrencyCode = 0;
                    object.toCurrencyCode = 0;
                    object.amount = "";
                }
                if (message.fromCurrencyCode != null && message.hasOwnProperty("fromCurrencyCode"))
                    object.fromCurrencyCode = message.fromCurrencyCode;
                if (message.toCurrencyCode != null && message.hasOwnProperty("toCurrencyCode"))
                    object.toCurrencyCode = message.toCurrencyCode;
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = message.amount;
                return object;
            };
    
            /**
             * Converts this ConvertCurrenciesEntry to JSON.
             * @function toJSON
             * @memberof protocol.ConvertCurrenciesEntry
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConvertCurrenciesEntry.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ConvertCurrenciesEntry;
        })();
    
        protocol.ConvertCryptoToFiatRequest = (function() {
    
            /**
             * Properties of a ConvertCryptoToFiatRequest.
             * @memberof protocol
             * @interface IConvertCryptoToFiatRequest
             * @property {Array.<protocol.IConvertCurrenciesEntry>|null} [entries] ConvertCryptoToFiatRequest entries
             */
    
            /**
             * Constructs a new ConvertCryptoToFiatRequest.
             * @memberof protocol
             * @classdesc Represents a ConvertCryptoToFiatRequest.
             * @implements IConvertCryptoToFiatRequest
             * @constructor
             * @param {protocol.IConvertCryptoToFiatRequest=} [properties] Properties to set
             */
            function ConvertCryptoToFiatRequest(properties) {
                this.entries = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ConvertCryptoToFiatRequest entries.
             * @member {Array.<protocol.IConvertCurrenciesEntry>} entries
             * @memberof protocol.ConvertCryptoToFiatRequest
             * @instance
             */
            ConvertCryptoToFiatRequest.prototype.entries = $util.emptyArray;
    
            /**
             * Creates a new ConvertCryptoToFiatRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ConvertCryptoToFiatRequest
             * @static
             * @param {protocol.IConvertCryptoToFiatRequest=} [properties] Properties to set
             * @returns {protocol.ConvertCryptoToFiatRequest} ConvertCryptoToFiatRequest instance
             */
            ConvertCryptoToFiatRequest.create = function create(properties) {
                return new ConvertCryptoToFiatRequest(properties);
            };
    
            /**
             * Encodes the specified ConvertCryptoToFiatRequest message. Does not implicitly {@link protocol.ConvertCryptoToFiatRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ConvertCryptoToFiatRequest
             * @static
             * @param {protocol.IConvertCryptoToFiatRequest} message ConvertCryptoToFiatRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConvertCryptoToFiatRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.entries != null && message.entries.length)
                    for (var i = 0; i < message.entries.length; ++i)
                        $root.protocol.ConvertCurrenciesEntry.encode(message.entries[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ConvertCryptoToFiatRequest message, length delimited. Does not implicitly {@link protocol.ConvertCryptoToFiatRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ConvertCryptoToFiatRequest
             * @static
             * @param {protocol.IConvertCryptoToFiatRequest} message ConvertCryptoToFiatRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConvertCryptoToFiatRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ConvertCryptoToFiatRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ConvertCryptoToFiatRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ConvertCryptoToFiatRequest} ConvertCryptoToFiatRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConvertCryptoToFiatRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ConvertCryptoToFiatRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.entries && message.entries.length))
                            message.entries = [];
                        message.entries.push($root.protocol.ConvertCurrenciesEntry.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ConvertCryptoToFiatRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ConvertCryptoToFiatRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ConvertCryptoToFiatRequest} ConvertCryptoToFiatRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConvertCryptoToFiatRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ConvertCryptoToFiatRequest message.
             * @function verify
             * @memberof protocol.ConvertCryptoToFiatRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConvertCryptoToFiatRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.entries != null && message.hasOwnProperty("entries")) {
                    if (!Array.isArray(message.entries))
                        return "entries: array expected";
                    for (var i = 0; i < message.entries.length; ++i) {
                        var error = $root.protocol.ConvertCurrenciesEntry.verify(message.entries[i]);
                        if (error)
                            return "entries." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ConvertCryptoToFiatRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ConvertCryptoToFiatRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ConvertCryptoToFiatRequest} ConvertCryptoToFiatRequest
             */
            ConvertCryptoToFiatRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ConvertCryptoToFiatRequest)
                    return object;
                var message = new $root.protocol.ConvertCryptoToFiatRequest();
                if (object.entries) {
                    if (!Array.isArray(object.entries))
                        throw TypeError(".protocol.ConvertCryptoToFiatRequest.entries: array expected");
                    message.entries = [];
                    for (var i = 0; i < object.entries.length; ++i) {
                        if (typeof object.entries[i] !== "object")
                            throw TypeError(".protocol.ConvertCryptoToFiatRequest.entries: object expected");
                        message.entries[i] = $root.protocol.ConvertCurrenciesEntry.fromObject(object.entries[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ConvertCryptoToFiatRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ConvertCryptoToFiatRequest
             * @static
             * @param {protocol.ConvertCryptoToFiatRequest} message ConvertCryptoToFiatRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConvertCryptoToFiatRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.entries = [];
                if (message.entries && message.entries.length) {
                    object.entries = [];
                    for (var j = 0; j < message.entries.length; ++j)
                        object.entries[j] = $root.protocol.ConvertCurrenciesEntry.toObject(message.entries[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ConvertCryptoToFiatRequest to JSON.
             * @function toJSON
             * @memberof protocol.ConvertCryptoToFiatRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConvertCryptoToFiatRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ConvertCryptoToFiatRequest;
        })();
    
        protocol.ConvertCryptoToFiatResponse = (function() {
    
            /**
             * Properties of a ConvertCryptoToFiatResponse.
             * @memberof protocol
             * @interface IConvertCryptoToFiatResponse
             * @property {Array.<protocol.IConvertCurrenciesEntry>|null} [entries] ConvertCryptoToFiatResponse entries
             */
    
            /**
             * Constructs a new ConvertCryptoToFiatResponse.
             * @memberof protocol
             * @classdesc Represents a ConvertCryptoToFiatResponse.
             * @implements IConvertCryptoToFiatResponse
             * @constructor
             * @param {protocol.IConvertCryptoToFiatResponse=} [properties] Properties to set
             */
            function ConvertCryptoToFiatResponse(properties) {
                this.entries = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ConvertCryptoToFiatResponse entries.
             * @member {Array.<protocol.IConvertCurrenciesEntry>} entries
             * @memberof protocol.ConvertCryptoToFiatResponse
             * @instance
             */
            ConvertCryptoToFiatResponse.prototype.entries = $util.emptyArray;
    
            /**
             * Creates a new ConvertCryptoToFiatResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ConvertCryptoToFiatResponse
             * @static
             * @param {protocol.IConvertCryptoToFiatResponse=} [properties] Properties to set
             * @returns {protocol.ConvertCryptoToFiatResponse} ConvertCryptoToFiatResponse instance
             */
            ConvertCryptoToFiatResponse.create = function create(properties) {
                return new ConvertCryptoToFiatResponse(properties);
            };
    
            /**
             * Encodes the specified ConvertCryptoToFiatResponse message. Does not implicitly {@link protocol.ConvertCryptoToFiatResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ConvertCryptoToFiatResponse
             * @static
             * @param {protocol.IConvertCryptoToFiatResponse} message ConvertCryptoToFiatResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConvertCryptoToFiatResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.entries != null && message.entries.length)
                    for (var i = 0; i < message.entries.length; ++i)
                        $root.protocol.ConvertCurrenciesEntry.encode(message.entries[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ConvertCryptoToFiatResponse message, length delimited. Does not implicitly {@link protocol.ConvertCryptoToFiatResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ConvertCryptoToFiatResponse
             * @static
             * @param {protocol.IConvertCryptoToFiatResponse} message ConvertCryptoToFiatResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConvertCryptoToFiatResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ConvertCryptoToFiatResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ConvertCryptoToFiatResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ConvertCryptoToFiatResponse} ConvertCryptoToFiatResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConvertCryptoToFiatResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ConvertCryptoToFiatResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.entries && message.entries.length))
                            message.entries = [];
                        message.entries.push($root.protocol.ConvertCurrenciesEntry.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ConvertCryptoToFiatResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ConvertCryptoToFiatResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ConvertCryptoToFiatResponse} ConvertCryptoToFiatResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConvertCryptoToFiatResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ConvertCryptoToFiatResponse message.
             * @function verify
             * @memberof protocol.ConvertCryptoToFiatResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConvertCryptoToFiatResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.entries != null && message.hasOwnProperty("entries")) {
                    if (!Array.isArray(message.entries))
                        return "entries: array expected";
                    for (var i = 0; i < message.entries.length; ++i) {
                        var error = $root.protocol.ConvertCurrenciesEntry.verify(message.entries[i]);
                        if (error)
                            return "entries." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ConvertCryptoToFiatResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ConvertCryptoToFiatResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ConvertCryptoToFiatResponse} ConvertCryptoToFiatResponse
             */
            ConvertCryptoToFiatResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ConvertCryptoToFiatResponse)
                    return object;
                var message = new $root.protocol.ConvertCryptoToFiatResponse();
                if (object.entries) {
                    if (!Array.isArray(object.entries))
                        throw TypeError(".protocol.ConvertCryptoToFiatResponse.entries: array expected");
                    message.entries = [];
                    for (var i = 0; i < object.entries.length; ++i) {
                        if (typeof object.entries[i] !== "object")
                            throw TypeError(".protocol.ConvertCryptoToFiatResponse.entries: object expected");
                        message.entries[i] = $root.protocol.ConvertCurrenciesEntry.fromObject(object.entries[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ConvertCryptoToFiatResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ConvertCryptoToFiatResponse
             * @static
             * @param {protocol.ConvertCryptoToFiatResponse} message ConvertCryptoToFiatResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConvertCryptoToFiatResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.entries = [];
                if (message.entries && message.entries.length) {
                    object.entries = [];
                    for (var j = 0; j < message.entries.length; ++j)
                        object.entries[j] = $root.protocol.ConvertCurrenciesEntry.toObject(message.entries[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ConvertCryptoToFiatResponse to JSON.
             * @function toJSON
             * @memberof protocol.ConvertCryptoToFiatResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConvertCryptoToFiatResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ConvertCryptoToFiatResponse;
        })();
    
        protocol.ClientRequestLogsRequest = (function() {
    
            /**
             * Properties of a ClientRequestLogsRequest.
             * @memberof protocol
             * @interface IClientRequestLogsRequest
             * @property {Long|null} [lastCreatedAt] ClientRequestLogsRequest lastCreatedAt
             */
    
            /**
             * Constructs a new ClientRequestLogsRequest.
             * @memberof protocol
             * @classdesc Represents a ClientRequestLogsRequest.
             * @implements IClientRequestLogsRequest
             * @constructor
             * @param {protocol.IClientRequestLogsRequest=} [properties] Properties to set
             */
            function ClientRequestLogsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientRequestLogsRequest lastCreatedAt.
             * @member {Long} lastCreatedAt
             * @memberof protocol.ClientRequestLogsRequest
             * @instance
             */
            ClientRequestLogsRequest.prototype.lastCreatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new ClientRequestLogsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ClientRequestLogsRequest
             * @static
             * @param {protocol.IClientRequestLogsRequest=} [properties] Properties to set
             * @returns {protocol.ClientRequestLogsRequest} ClientRequestLogsRequest instance
             */
            ClientRequestLogsRequest.create = function create(properties) {
                return new ClientRequestLogsRequest(properties);
            };
    
            /**
             * Encodes the specified ClientRequestLogsRequest message. Does not implicitly {@link protocol.ClientRequestLogsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientRequestLogsRequest
             * @static
             * @param {protocol.IClientRequestLogsRequest} message ClientRequestLogsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientRequestLogsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.lastCreatedAt != null && Object.hasOwnProperty.call(message, "lastCreatedAt"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.lastCreatedAt);
                return writer;
            };
    
            /**
             * Encodes the specified ClientRequestLogsRequest message, length delimited. Does not implicitly {@link protocol.ClientRequestLogsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientRequestLogsRequest
             * @static
             * @param {protocol.IClientRequestLogsRequest} message ClientRequestLogsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientRequestLogsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientRequestLogsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientRequestLogsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientRequestLogsRequest} ClientRequestLogsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientRequestLogsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientRequestLogsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.lastCreatedAt = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientRequestLogsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientRequestLogsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientRequestLogsRequest} ClientRequestLogsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientRequestLogsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientRequestLogsRequest message.
             * @function verify
             * @memberof protocol.ClientRequestLogsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientRequestLogsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.lastCreatedAt != null && message.hasOwnProperty("lastCreatedAt"))
                    if (!$util.isInteger(message.lastCreatedAt) && !(message.lastCreatedAt && $util.isInteger(message.lastCreatedAt.low) && $util.isInteger(message.lastCreatedAt.high)))
                        return "lastCreatedAt: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a ClientRequestLogsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientRequestLogsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientRequestLogsRequest} ClientRequestLogsRequest
             */
            ClientRequestLogsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientRequestLogsRequest)
                    return object;
                var message = new $root.protocol.ClientRequestLogsRequest();
                if (object.lastCreatedAt != null)
                    if ($util.Long)
                        (message.lastCreatedAt = $util.Long.fromValue(object.lastCreatedAt)).unsigned = false;
                    else if (typeof object.lastCreatedAt === "string")
                        message.lastCreatedAt = parseInt(object.lastCreatedAt, 10);
                    else if (typeof object.lastCreatedAt === "number")
                        message.lastCreatedAt = object.lastCreatedAt;
                    else if (typeof object.lastCreatedAt === "object")
                        message.lastCreatedAt = new $util.LongBits(object.lastCreatedAt.low >>> 0, object.lastCreatedAt.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a ClientRequestLogsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientRequestLogsRequest
             * @static
             * @param {protocol.ClientRequestLogsRequest} message ClientRequestLogsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientRequestLogsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.lastCreatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lastCreatedAt = options.longs === String ? "0" : 0;
                if (message.lastCreatedAt != null && message.hasOwnProperty("lastCreatedAt"))
                    if (typeof message.lastCreatedAt === "number")
                        object.lastCreatedAt = options.longs === String ? String(message.lastCreatedAt) : message.lastCreatedAt;
                    else
                        object.lastCreatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.lastCreatedAt) : options.longs === Number ? new $util.LongBits(message.lastCreatedAt.low >>> 0, message.lastCreatedAt.high >>> 0).toNumber() : message.lastCreatedAt;
                return object;
            };
    
            /**
             * Converts this ClientRequestLogsRequest to JSON.
             * @function toJSON
             * @memberof protocol.ClientRequestLogsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientRequestLogsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientRequestLogsRequest;
        })();
    
        protocol.ClientRequestLogsResponse = (function() {
    
            /**
             * Properties of a ClientRequestLogsResponse.
             * @memberof protocol
             * @interface IClientRequestLogsResponse
             * @property {Array.<protocol.ClientRequestLogsResponse.ILog>|null} [logs] ClientRequestLogsResponse logs
             */
    
            /**
             * Constructs a new ClientRequestLogsResponse.
             * @memberof protocol
             * @classdesc Represents a ClientRequestLogsResponse.
             * @implements IClientRequestLogsResponse
             * @constructor
             * @param {protocol.IClientRequestLogsResponse=} [properties] Properties to set
             */
            function ClientRequestLogsResponse(properties) {
                this.logs = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientRequestLogsResponse logs.
             * @member {Array.<protocol.ClientRequestLogsResponse.ILog>} logs
             * @memberof protocol.ClientRequestLogsResponse
             * @instance
             */
            ClientRequestLogsResponse.prototype.logs = $util.emptyArray;
    
            /**
             * Creates a new ClientRequestLogsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ClientRequestLogsResponse
             * @static
             * @param {protocol.IClientRequestLogsResponse=} [properties] Properties to set
             * @returns {protocol.ClientRequestLogsResponse} ClientRequestLogsResponse instance
             */
            ClientRequestLogsResponse.create = function create(properties) {
                return new ClientRequestLogsResponse(properties);
            };
    
            /**
             * Encodes the specified ClientRequestLogsResponse message. Does not implicitly {@link protocol.ClientRequestLogsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientRequestLogsResponse
             * @static
             * @param {protocol.IClientRequestLogsResponse} message ClientRequestLogsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientRequestLogsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.logs != null && message.logs.length)
                    for (var i = 0; i < message.logs.length; ++i)
                        $root.protocol.ClientRequestLogsResponse.Log.encode(message.logs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ClientRequestLogsResponse message, length delimited. Does not implicitly {@link protocol.ClientRequestLogsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientRequestLogsResponse
             * @static
             * @param {protocol.IClientRequestLogsResponse} message ClientRequestLogsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientRequestLogsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientRequestLogsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientRequestLogsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientRequestLogsResponse} ClientRequestLogsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientRequestLogsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientRequestLogsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.logs && message.logs.length))
                            message.logs = [];
                        message.logs.push($root.protocol.ClientRequestLogsResponse.Log.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientRequestLogsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientRequestLogsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientRequestLogsResponse} ClientRequestLogsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientRequestLogsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientRequestLogsResponse message.
             * @function verify
             * @memberof protocol.ClientRequestLogsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientRequestLogsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.logs != null && message.hasOwnProperty("logs")) {
                    if (!Array.isArray(message.logs))
                        return "logs: array expected";
                    for (var i = 0; i < message.logs.length; ++i) {
                        var error = $root.protocol.ClientRequestLogsResponse.Log.verify(message.logs[i]);
                        if (error)
                            return "logs." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ClientRequestLogsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientRequestLogsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientRequestLogsResponse} ClientRequestLogsResponse
             */
            ClientRequestLogsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientRequestLogsResponse)
                    return object;
                var message = new $root.protocol.ClientRequestLogsResponse();
                if (object.logs) {
                    if (!Array.isArray(object.logs))
                        throw TypeError(".protocol.ClientRequestLogsResponse.logs: array expected");
                    message.logs = [];
                    for (var i = 0; i < object.logs.length; ++i) {
                        if (typeof object.logs[i] !== "object")
                            throw TypeError(".protocol.ClientRequestLogsResponse.logs: object expected");
                        message.logs[i] = $root.protocol.ClientRequestLogsResponse.Log.fromObject(object.logs[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientRequestLogsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientRequestLogsResponse
             * @static
             * @param {protocol.ClientRequestLogsResponse} message ClientRequestLogsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientRequestLogsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.logs = [];
                if (message.logs && message.logs.length) {
                    object.logs = [];
                    for (var j = 0; j < message.logs.length; ++j)
                        object.logs[j] = $root.protocol.ClientRequestLogsResponse.Log.toObject(message.logs[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ClientRequestLogsResponse to JSON.
             * @function toJSON
             * @memberof protocol.ClientRequestLogsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientRequestLogsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            ClientRequestLogsResponse.Log = (function() {
    
                /**
                 * Properties of a Log.
                 * @memberof protocol.ClientRequestLogsResponse
                 * @interface ILog
                 * @property {string|null} [ip] Log ip
                 * @property {number|null} [countryId] Log countryId
                 * @property {number|null} [cityId] Log cityId
                 * @property {string|null} [userAgent] Log userAgent
                 * @property {Long|null} [createdAt] Log createdAt
                 * @property {Long|null} [updatedAt] Log updatedAt
                 * @property {string|null} [requestName] Log requestName
                 * @property {string|null} [countryName] Log countryName
                 * @property {string|null} [cityName] Log cityName
                 */
    
                /**
                 * Constructs a new Log.
                 * @memberof protocol.ClientRequestLogsResponse
                 * @classdesc Represents a Log.
                 * @implements ILog
                 * @constructor
                 * @param {protocol.ClientRequestLogsResponse.ILog=} [properties] Properties to set
                 */
                function Log(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Log ip.
                 * @member {string} ip
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @instance
                 */
                Log.prototype.ip = "";
    
                /**
                 * Log countryId.
                 * @member {number} countryId
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @instance
                 */
                Log.prototype.countryId = 0;
    
                /**
                 * Log cityId.
                 * @member {number} cityId
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @instance
                 */
                Log.prototype.cityId = 0;
    
                /**
                 * Log userAgent.
                 * @member {string} userAgent
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @instance
                 */
                Log.prototype.userAgent = "";
    
                /**
                 * Log createdAt.
                 * @member {Long} createdAt
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @instance
                 */
                Log.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Log updatedAt.
                 * @member {Long} updatedAt
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @instance
                 */
                Log.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Log requestName.
                 * @member {string} requestName
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @instance
                 */
                Log.prototype.requestName = "";
    
                /**
                 * Log countryName.
                 * @member {string} countryName
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @instance
                 */
                Log.prototype.countryName = "";
    
                /**
                 * Log cityName.
                 * @member {string} cityName
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @instance
                 */
                Log.prototype.cityName = "";
    
                /**
                 * Creates a new Log instance using the specified properties.
                 * @function create
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @static
                 * @param {protocol.ClientRequestLogsResponse.ILog=} [properties] Properties to set
                 * @returns {protocol.ClientRequestLogsResponse.Log} Log instance
                 */
                Log.create = function create(properties) {
                    return new Log(properties);
                };
    
                /**
                 * Encodes the specified Log message. Does not implicitly {@link protocol.ClientRequestLogsResponse.Log.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @static
                 * @param {protocol.ClientRequestLogsResponse.ILog} message Log message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Log.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.ip);
                    if (message.countryId != null && Object.hasOwnProperty.call(message, "countryId"))
                        writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.countryId);
                    if (message.cityId != null && Object.hasOwnProperty.call(message, "cityId"))
                        writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.cityId);
                    if (message.userAgent != null && Object.hasOwnProperty.call(message, "userAgent"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.userAgent);
                    if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.createdAt);
                    if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int64(message.updatedAt);
                    if (message.requestName != null && Object.hasOwnProperty.call(message, "requestName"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.requestName);
                    if (message.countryName != null && Object.hasOwnProperty.call(message, "countryName"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.countryName);
                    if (message.cityName != null && Object.hasOwnProperty.call(message, "cityName"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.cityName);
                    return writer;
                };
    
                /**
                 * Encodes the specified Log message, length delimited. Does not implicitly {@link protocol.ClientRequestLogsResponse.Log.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @static
                 * @param {protocol.ClientRequestLogsResponse.ILog} message Log message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Log.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Log message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.ClientRequestLogsResponse.Log} Log
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Log.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientRequestLogsResponse.Log();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.ip = reader.string();
                            break;
                        case 2:
                            message.countryId = reader.uint32();
                            break;
                        case 3:
                            message.cityId = reader.uint32();
                            break;
                        case 4:
                            message.userAgent = reader.string();
                            break;
                        case 5:
                            message.createdAt = reader.int64();
                            break;
                        case 6:
                            message.updatedAt = reader.int64();
                            break;
                        case 7:
                            message.requestName = reader.string();
                            break;
                        case 8:
                            message.countryName = reader.string();
                            break;
                        case 9:
                            message.cityName = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Log message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.ClientRequestLogsResponse.Log} Log
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Log.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Log message.
                 * @function verify
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Log.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ip != null && message.hasOwnProperty("ip"))
                        if (!$util.isString(message.ip))
                            return "ip: string expected";
                    if (message.countryId != null && message.hasOwnProperty("countryId"))
                        if (!$util.isInteger(message.countryId))
                            return "countryId: integer expected";
                    if (message.cityId != null && message.hasOwnProperty("cityId"))
                        if (!$util.isInteger(message.cityId))
                            return "cityId: integer expected";
                    if (message.userAgent != null && message.hasOwnProperty("userAgent"))
                        if (!$util.isString(message.userAgent))
                            return "userAgent: string expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.requestName != null && message.hasOwnProperty("requestName"))
                        if (!$util.isString(message.requestName))
                            return "requestName: string expected";
                    if (message.countryName != null && message.hasOwnProperty("countryName"))
                        if (!$util.isString(message.countryName))
                            return "countryName: string expected";
                    if (message.cityName != null && message.hasOwnProperty("cityName"))
                        if (!$util.isString(message.cityName))
                            return "cityName: string expected";
                    return null;
                };
    
                /**
                 * Creates a Log message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.ClientRequestLogsResponse.Log} Log
                 */
                Log.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.ClientRequestLogsResponse.Log)
                        return object;
                    var message = new $root.protocol.ClientRequestLogsResponse.Log();
                    if (object.ip != null)
                        message.ip = String(object.ip);
                    if (object.countryId != null)
                        message.countryId = object.countryId >>> 0;
                    if (object.cityId != null)
                        message.cityId = object.cityId >>> 0;
                    if (object.userAgent != null)
                        message.userAgent = String(object.userAgent);
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.requestName != null)
                        message.requestName = String(object.requestName);
                    if (object.countryName != null)
                        message.countryName = String(object.countryName);
                    if (object.cityName != null)
                        message.cityName = String(object.cityName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Log message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @static
                 * @param {protocol.ClientRequestLogsResponse.Log} message Log
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Log.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.ip = "";
                        object.countryId = 0;
                        object.cityId = 0;
                        object.userAgent = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        object.requestName = "";
                        object.countryName = "";
                        object.cityName = "";
                    }
                    if (message.ip != null && message.hasOwnProperty("ip"))
                        object.ip = message.ip;
                    if (message.countryId != null && message.hasOwnProperty("countryId"))
                        object.countryId = message.countryId;
                    if (message.cityId != null && message.hasOwnProperty("cityId"))
                        object.cityId = message.cityId;
                    if (message.userAgent != null && message.hasOwnProperty("userAgent"))
                        object.userAgent = message.userAgent;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.requestName != null && message.hasOwnProperty("requestName"))
                        object.requestName = message.requestName;
                    if (message.countryName != null && message.hasOwnProperty("countryName"))
                        object.countryName = message.countryName;
                    if (message.cityName != null && message.hasOwnProperty("cityName"))
                        object.cityName = message.cityName;
                    return object;
                };
    
                /**
                 * Converts this Log to JSON.
                 * @function toJSON
                 * @memberof protocol.ClientRequestLogsResponse.Log
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Log.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Log;
            })();
    
            return ClientRequestLogsResponse;
        })();
    
        protocol.ClientSystemAddress = (function() {
    
            /**
             * Properties of a ClientSystemAddress.
             * @memberof protocol
             * @interface IClientSystemAddress
             * @property {string|null} [address] ClientSystemAddress address
             * @property {string|null} [balance] ClientSystemAddress balance
             * @property {protocol.Currency|null} [currency] ClientSystemAddress currency
             */
    
            /**
             * Constructs a new ClientSystemAddress.
             * @memberof protocol
             * @classdesc Represents a ClientSystemAddress.
             * @implements IClientSystemAddress
             * @constructor
             * @param {protocol.IClientSystemAddress=} [properties] Properties to set
             */
            function ClientSystemAddress(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientSystemAddress address.
             * @member {string} address
             * @memberof protocol.ClientSystemAddress
             * @instance
             */
            ClientSystemAddress.prototype.address = "";
    
            /**
             * ClientSystemAddress balance.
             * @member {string} balance
             * @memberof protocol.ClientSystemAddress
             * @instance
             */
            ClientSystemAddress.prototype.balance = "";
    
            /**
             * ClientSystemAddress currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.ClientSystemAddress
             * @instance
             */
            ClientSystemAddress.prototype.currency = 0;
    
            /**
             * Creates a new ClientSystemAddress instance using the specified properties.
             * @function create
             * @memberof protocol.ClientSystemAddress
             * @static
             * @param {protocol.IClientSystemAddress=} [properties] Properties to set
             * @returns {protocol.ClientSystemAddress} ClientSystemAddress instance
             */
            ClientSystemAddress.create = function create(properties) {
                return new ClientSystemAddress(properties);
            };
    
            /**
             * Encodes the specified ClientSystemAddress message. Does not implicitly {@link protocol.ClientSystemAddress.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientSystemAddress
             * @static
             * @param {protocol.IClientSystemAddress} message ClientSystemAddress message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientSystemAddress.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
                if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.balance);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.currency);
                return writer;
            };
    
            /**
             * Encodes the specified ClientSystemAddress message, length delimited. Does not implicitly {@link protocol.ClientSystemAddress.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientSystemAddress
             * @static
             * @param {protocol.IClientSystemAddress} message ClientSystemAddress message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientSystemAddress.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientSystemAddress message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientSystemAddress
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientSystemAddress} ClientSystemAddress
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientSystemAddress.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientSystemAddress();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.address = reader.string();
                        break;
                    case 2:
                        message.balance = reader.string();
                        break;
                    case 3:
                        message.currency = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientSystemAddress message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientSystemAddress
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientSystemAddress} ClientSystemAddress
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientSystemAddress.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientSystemAddress message.
             * @function verify
             * @memberof protocol.ClientSystemAddress
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientSystemAddress.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                if (message.balance != null && message.hasOwnProperty("balance"))
                    if (!$util.isString(message.balance))
                        return "balance: string expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a ClientSystemAddress message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientSystemAddress
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientSystemAddress} ClientSystemAddress
             */
            ClientSystemAddress.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientSystemAddress)
                    return object;
                var message = new $root.protocol.ClientSystemAddress();
                if (object.address != null)
                    message.address = String(object.address);
                if (object.balance != null)
                    message.balance = String(object.balance);
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientSystemAddress message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientSystemAddress
             * @static
             * @param {protocol.ClientSystemAddress} message ClientSystemAddress
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientSystemAddress.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.address = "";
                    object.balance = "";
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                }
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.balance != null && message.hasOwnProperty("balance"))
                    object.balance = message.balance;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                return object;
            };
    
            /**
             * Converts this ClientSystemAddress to JSON.
             * @function toJSON
             * @memberof protocol.ClientSystemAddress
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientSystemAddress.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientSystemAddress;
        })();
    
        protocol.ClientSystemAddressesTagGroupsListRequest = (function() {
    
            /**
             * Properties of a ClientSystemAddressesTagGroupsListRequest.
             * @memberof protocol
             * @interface IClientSystemAddressesTagGroupsListRequest
             */
    
            /**
             * Constructs a new ClientSystemAddressesTagGroupsListRequest.
             * @memberof protocol
             * @classdesc Represents a ClientSystemAddressesTagGroupsListRequest.
             * @implements IClientSystemAddressesTagGroupsListRequest
             * @constructor
             * @param {protocol.IClientSystemAddressesTagGroupsListRequest=} [properties] Properties to set
             */
            function ClientSystemAddressesTagGroupsListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new ClientSystemAddressesTagGroupsListRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ClientSystemAddressesTagGroupsListRequest
             * @static
             * @param {protocol.IClientSystemAddressesTagGroupsListRequest=} [properties] Properties to set
             * @returns {protocol.ClientSystemAddressesTagGroupsListRequest} ClientSystemAddressesTagGroupsListRequest instance
             */
            ClientSystemAddressesTagGroupsListRequest.create = function create(properties) {
                return new ClientSystemAddressesTagGroupsListRequest(properties);
            };
    
            /**
             * Encodes the specified ClientSystemAddressesTagGroupsListRequest message. Does not implicitly {@link protocol.ClientSystemAddressesTagGroupsListRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientSystemAddressesTagGroupsListRequest
             * @static
             * @param {protocol.IClientSystemAddressesTagGroupsListRequest} message ClientSystemAddressesTagGroupsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientSystemAddressesTagGroupsListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified ClientSystemAddressesTagGroupsListRequest message, length delimited. Does not implicitly {@link protocol.ClientSystemAddressesTagGroupsListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientSystemAddressesTagGroupsListRequest
             * @static
             * @param {protocol.IClientSystemAddressesTagGroupsListRequest} message ClientSystemAddressesTagGroupsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientSystemAddressesTagGroupsListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientSystemAddressesTagGroupsListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientSystemAddressesTagGroupsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientSystemAddressesTagGroupsListRequest} ClientSystemAddressesTagGroupsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientSystemAddressesTagGroupsListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientSystemAddressesTagGroupsListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientSystemAddressesTagGroupsListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientSystemAddressesTagGroupsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientSystemAddressesTagGroupsListRequest} ClientSystemAddressesTagGroupsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientSystemAddressesTagGroupsListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientSystemAddressesTagGroupsListRequest message.
             * @function verify
             * @memberof protocol.ClientSystemAddressesTagGroupsListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientSystemAddressesTagGroupsListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a ClientSystemAddressesTagGroupsListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientSystemAddressesTagGroupsListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientSystemAddressesTagGroupsListRequest} ClientSystemAddressesTagGroupsListRequest
             */
            ClientSystemAddressesTagGroupsListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientSystemAddressesTagGroupsListRequest)
                    return object;
                return new $root.protocol.ClientSystemAddressesTagGroupsListRequest();
            };
    
            /**
             * Creates a plain object from a ClientSystemAddressesTagGroupsListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientSystemAddressesTagGroupsListRequest
             * @static
             * @param {protocol.ClientSystemAddressesTagGroupsListRequest} message ClientSystemAddressesTagGroupsListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientSystemAddressesTagGroupsListRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this ClientSystemAddressesTagGroupsListRequest to JSON.
             * @function toJSON
             * @memberof protocol.ClientSystemAddressesTagGroupsListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientSystemAddressesTagGroupsListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientSystemAddressesTagGroupsListRequest;
        })();
    
        protocol.ClientSystemAddressesTagGroupsListResponse = (function() {
    
            /**
             * Properties of a ClientSystemAddressesTagGroupsListResponse.
             * @memberof protocol
             * @interface IClientSystemAddressesTagGroupsListResponse
             * @property {Array.<protocol.ClientSystemAddressesTagGroupsListResponse.IGroup>|null} [groups] ClientSystemAddressesTagGroupsListResponse groups
             */
    
            /**
             * Constructs a new ClientSystemAddressesTagGroupsListResponse.
             * @memberof protocol
             * @classdesc Represents a ClientSystemAddressesTagGroupsListResponse.
             * @implements IClientSystemAddressesTagGroupsListResponse
             * @constructor
             * @param {protocol.IClientSystemAddressesTagGroupsListResponse=} [properties] Properties to set
             */
            function ClientSystemAddressesTagGroupsListResponse(properties) {
                this.groups = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientSystemAddressesTagGroupsListResponse groups.
             * @member {Array.<protocol.ClientSystemAddressesTagGroupsListResponse.IGroup>} groups
             * @memberof protocol.ClientSystemAddressesTagGroupsListResponse
             * @instance
             */
            ClientSystemAddressesTagGroupsListResponse.prototype.groups = $util.emptyArray;
    
            /**
             * Creates a new ClientSystemAddressesTagGroupsListResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ClientSystemAddressesTagGroupsListResponse
             * @static
             * @param {protocol.IClientSystemAddressesTagGroupsListResponse=} [properties] Properties to set
             * @returns {protocol.ClientSystemAddressesTagGroupsListResponse} ClientSystemAddressesTagGroupsListResponse instance
             */
            ClientSystemAddressesTagGroupsListResponse.create = function create(properties) {
                return new ClientSystemAddressesTagGroupsListResponse(properties);
            };
    
            /**
             * Encodes the specified ClientSystemAddressesTagGroupsListResponse message. Does not implicitly {@link protocol.ClientSystemAddressesTagGroupsListResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientSystemAddressesTagGroupsListResponse
             * @static
             * @param {protocol.IClientSystemAddressesTagGroupsListResponse} message ClientSystemAddressesTagGroupsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientSystemAddressesTagGroupsListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.groups != null && message.groups.length)
                    for (var i = 0; i < message.groups.length; ++i)
                        $root.protocol.ClientSystemAddressesTagGroupsListResponse.Group.encode(message.groups[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ClientSystemAddressesTagGroupsListResponse message, length delimited. Does not implicitly {@link protocol.ClientSystemAddressesTagGroupsListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientSystemAddressesTagGroupsListResponse
             * @static
             * @param {protocol.IClientSystemAddressesTagGroupsListResponse} message ClientSystemAddressesTagGroupsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientSystemAddressesTagGroupsListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientSystemAddressesTagGroupsListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientSystemAddressesTagGroupsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientSystemAddressesTagGroupsListResponse} ClientSystemAddressesTagGroupsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientSystemAddressesTagGroupsListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientSystemAddressesTagGroupsListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.groups && message.groups.length))
                            message.groups = [];
                        message.groups.push($root.protocol.ClientSystemAddressesTagGroupsListResponse.Group.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientSystemAddressesTagGroupsListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientSystemAddressesTagGroupsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientSystemAddressesTagGroupsListResponse} ClientSystemAddressesTagGroupsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientSystemAddressesTagGroupsListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientSystemAddressesTagGroupsListResponse message.
             * @function verify
             * @memberof protocol.ClientSystemAddressesTagGroupsListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientSystemAddressesTagGroupsListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.groups != null && message.hasOwnProperty("groups")) {
                    if (!Array.isArray(message.groups))
                        return "groups: array expected";
                    for (var i = 0; i < message.groups.length; ++i) {
                        var error = $root.protocol.ClientSystemAddressesTagGroupsListResponse.Group.verify(message.groups[i]);
                        if (error)
                            return "groups." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ClientSystemAddressesTagGroupsListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientSystemAddressesTagGroupsListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientSystemAddressesTagGroupsListResponse} ClientSystemAddressesTagGroupsListResponse
             */
            ClientSystemAddressesTagGroupsListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientSystemAddressesTagGroupsListResponse)
                    return object;
                var message = new $root.protocol.ClientSystemAddressesTagGroupsListResponse();
                if (object.groups) {
                    if (!Array.isArray(object.groups))
                        throw TypeError(".protocol.ClientSystemAddressesTagGroupsListResponse.groups: array expected");
                    message.groups = [];
                    for (var i = 0; i < object.groups.length; ++i) {
                        if (typeof object.groups[i] !== "object")
                            throw TypeError(".protocol.ClientSystemAddressesTagGroupsListResponse.groups: object expected");
                        message.groups[i] = $root.protocol.ClientSystemAddressesTagGroupsListResponse.Group.fromObject(object.groups[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientSystemAddressesTagGroupsListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientSystemAddressesTagGroupsListResponse
             * @static
             * @param {protocol.ClientSystemAddressesTagGroupsListResponse} message ClientSystemAddressesTagGroupsListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientSystemAddressesTagGroupsListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.groups = [];
                if (message.groups && message.groups.length) {
                    object.groups = [];
                    for (var j = 0; j < message.groups.length; ++j)
                        object.groups[j] = $root.protocol.ClientSystemAddressesTagGroupsListResponse.Group.toObject(message.groups[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ClientSystemAddressesTagGroupsListResponse to JSON.
             * @function toJSON
             * @memberof protocol.ClientSystemAddressesTagGroupsListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientSystemAddressesTagGroupsListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            ClientSystemAddressesTagGroupsListResponse.Group = (function() {
    
                /**
                 * Properties of a Group.
                 * @memberof protocol.ClientSystemAddressesTagGroupsListResponse
                 * @interface IGroup
                 * @property {string|null} [tag] Group tag
                 * @property {protocol.Chain|null} [chain] Group chain
                 * @property {Array.<protocol.IClientSystemAddress>|null} [systemAddresses] Group systemAddresses
                 * @property {Array.<protocol.IClientSystemAddress>|null} [tokenAddresses] Group tokenAddresses
                 */
    
                /**
                 * Constructs a new Group.
                 * @memberof protocol.ClientSystemAddressesTagGroupsListResponse
                 * @classdesc Represents a Group.
                 * @implements IGroup
                 * @constructor
                 * @param {protocol.ClientSystemAddressesTagGroupsListResponse.IGroup=} [properties] Properties to set
                 */
                function Group(properties) {
                    this.systemAddresses = [];
                    this.tokenAddresses = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Group tag.
                 * @member {string} tag
                 * @memberof protocol.ClientSystemAddressesTagGroupsListResponse.Group
                 * @instance
                 */
                Group.prototype.tag = "";
    
                /**
                 * Group chain.
                 * @member {protocol.Chain} chain
                 * @memberof protocol.ClientSystemAddressesTagGroupsListResponse.Group
                 * @instance
                 */
                Group.prototype.chain = 0;
    
                /**
                 * Group systemAddresses.
                 * @member {Array.<protocol.IClientSystemAddress>} systemAddresses
                 * @memberof protocol.ClientSystemAddressesTagGroupsListResponse.Group
                 * @instance
                 */
                Group.prototype.systemAddresses = $util.emptyArray;
    
                /**
                 * Group tokenAddresses.
                 * @member {Array.<protocol.IClientSystemAddress>} tokenAddresses
                 * @memberof protocol.ClientSystemAddressesTagGroupsListResponse.Group
                 * @instance
                 */
                Group.prototype.tokenAddresses = $util.emptyArray;
    
                /**
                 * Creates a new Group instance using the specified properties.
                 * @function create
                 * @memberof protocol.ClientSystemAddressesTagGroupsListResponse.Group
                 * @static
                 * @param {protocol.ClientSystemAddressesTagGroupsListResponse.IGroup=} [properties] Properties to set
                 * @returns {protocol.ClientSystemAddressesTagGroupsListResponse.Group} Group instance
                 */
                Group.create = function create(properties) {
                    return new Group(properties);
                };
    
                /**
                 * Encodes the specified Group message. Does not implicitly {@link protocol.ClientSystemAddressesTagGroupsListResponse.Group.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.ClientSystemAddressesTagGroupsListResponse.Group
                 * @static
                 * @param {protocol.ClientSystemAddressesTagGroupsListResponse.IGroup} message Group message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Group.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.tag);
                    if (message.chain != null && Object.hasOwnProperty.call(message, "chain"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.chain);
                    if (message.systemAddresses != null && message.systemAddresses.length)
                        for (var i = 0; i < message.systemAddresses.length; ++i)
                            $root.protocol.ClientSystemAddress.encode(message.systemAddresses[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.tokenAddresses != null && message.tokenAddresses.length)
                        for (var i = 0; i < message.tokenAddresses.length; ++i)
                            $root.protocol.ClientSystemAddress.encode(message.tokenAddresses[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Group message, length delimited. Does not implicitly {@link protocol.ClientSystemAddressesTagGroupsListResponse.Group.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.ClientSystemAddressesTagGroupsListResponse.Group
                 * @static
                 * @param {protocol.ClientSystemAddressesTagGroupsListResponse.IGroup} message Group message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Group.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Group message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.ClientSystemAddressesTagGroupsListResponse.Group
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.ClientSystemAddressesTagGroupsListResponse.Group} Group
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Group.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientSystemAddressesTagGroupsListResponse.Group();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.tag = reader.string();
                            break;
                        case 2:
                            message.chain = reader.int32();
                            break;
                        case 3:
                            if (!(message.systemAddresses && message.systemAddresses.length))
                                message.systemAddresses = [];
                            message.systemAddresses.push($root.protocol.ClientSystemAddress.decode(reader, reader.uint32()));
                            break;
                        case 4:
                            if (!(message.tokenAddresses && message.tokenAddresses.length))
                                message.tokenAddresses = [];
                            message.tokenAddresses.push($root.protocol.ClientSystemAddress.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Group message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.ClientSystemAddressesTagGroupsListResponse.Group
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.ClientSystemAddressesTagGroupsListResponse.Group} Group
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Group.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Group message.
                 * @function verify
                 * @memberof protocol.ClientSystemAddressesTagGroupsListResponse.Group
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Group.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        if (!$util.isString(message.tag))
                            return "tag: string expected";
                    if (message.chain != null && message.hasOwnProperty("chain"))
                        switch (message.chain) {
                        default:
                            return "chain: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.systemAddresses != null && message.hasOwnProperty("systemAddresses")) {
                        if (!Array.isArray(message.systemAddresses))
                            return "systemAddresses: array expected";
                        for (var i = 0; i < message.systemAddresses.length; ++i) {
                            var error = $root.protocol.ClientSystemAddress.verify(message.systemAddresses[i]);
                            if (error)
                                return "systemAddresses." + error;
                        }
                    }
                    if (message.tokenAddresses != null && message.hasOwnProperty("tokenAddresses")) {
                        if (!Array.isArray(message.tokenAddresses))
                            return "tokenAddresses: array expected";
                        for (var i = 0; i < message.tokenAddresses.length; ++i) {
                            var error = $root.protocol.ClientSystemAddress.verify(message.tokenAddresses[i]);
                            if (error)
                                return "tokenAddresses." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Group message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.ClientSystemAddressesTagGroupsListResponse.Group
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.ClientSystemAddressesTagGroupsListResponse.Group} Group
                 */
                Group.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.ClientSystemAddressesTagGroupsListResponse.Group)
                        return object;
                    var message = new $root.protocol.ClientSystemAddressesTagGroupsListResponse.Group();
                    if (object.tag != null)
                        message.tag = String(object.tag);
                    switch (object.chain) {
                    case "UNK_CHAIN":
                    case 0:
                        message.chain = 0;
                        break;
                    case "BTC_CHAIN":
                    case 1:
                        message.chain = 1;
                        break;
                    case "ETH_CHAIN":
                    case 2:
                        message.chain = 2;
                        break;
                    case "TRX_CHAIN":
                    case 3:
                        message.chain = 3;
                        break;
                    }
                    if (object.systemAddresses) {
                        if (!Array.isArray(object.systemAddresses))
                            throw TypeError(".protocol.ClientSystemAddressesTagGroupsListResponse.Group.systemAddresses: array expected");
                        message.systemAddresses = [];
                        for (var i = 0; i < object.systemAddresses.length; ++i) {
                            if (typeof object.systemAddresses[i] !== "object")
                                throw TypeError(".protocol.ClientSystemAddressesTagGroupsListResponse.Group.systemAddresses: object expected");
                            message.systemAddresses[i] = $root.protocol.ClientSystemAddress.fromObject(object.systemAddresses[i]);
                        }
                    }
                    if (object.tokenAddresses) {
                        if (!Array.isArray(object.tokenAddresses))
                            throw TypeError(".protocol.ClientSystemAddressesTagGroupsListResponse.Group.tokenAddresses: array expected");
                        message.tokenAddresses = [];
                        for (var i = 0; i < object.tokenAddresses.length; ++i) {
                            if (typeof object.tokenAddresses[i] !== "object")
                                throw TypeError(".protocol.ClientSystemAddressesTagGroupsListResponse.Group.tokenAddresses: object expected");
                            message.tokenAddresses[i] = $root.protocol.ClientSystemAddress.fromObject(object.tokenAddresses[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Group message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.ClientSystemAddressesTagGroupsListResponse.Group
                 * @static
                 * @param {protocol.ClientSystemAddressesTagGroupsListResponse.Group} message Group
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Group.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.systemAddresses = [];
                        object.tokenAddresses = [];
                    }
                    if (options.defaults) {
                        object.tag = "";
                        object.chain = options.enums === String ? "UNK_CHAIN" : 0;
                    }
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        object.tag = message.tag;
                    if (message.chain != null && message.hasOwnProperty("chain"))
                        object.chain = options.enums === String ? $root.protocol.Chain[message.chain] : message.chain;
                    if (message.systemAddresses && message.systemAddresses.length) {
                        object.systemAddresses = [];
                        for (var j = 0; j < message.systemAddresses.length; ++j)
                            object.systemAddresses[j] = $root.protocol.ClientSystemAddress.toObject(message.systemAddresses[j], options);
                    }
                    if (message.tokenAddresses && message.tokenAddresses.length) {
                        object.tokenAddresses = [];
                        for (var j = 0; j < message.tokenAddresses.length; ++j)
                            object.tokenAddresses[j] = $root.protocol.ClientSystemAddress.toObject(message.tokenAddresses[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this Group to JSON.
                 * @function toJSON
                 * @memberof protocol.ClientSystemAddressesTagGroupsListResponse.Group
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Group.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Group;
            })();
    
            return ClientSystemAddressesTagGroupsListResponse;
        })();
    
        protocol.CreateClientSystemAddressRequest = (function() {
    
            /**
             * Properties of a CreateClientSystemAddressRequest.
             * @memberof protocol
             * @interface ICreateClientSystemAddressRequest
             * @property {string|null} [tag] CreateClientSystemAddressRequest tag
             * @property {protocol.Chain|null} [chain] CreateClientSystemAddressRequest chain
             */
    
            /**
             * Constructs a new CreateClientSystemAddressRequest.
             * @memberof protocol
             * @classdesc Represents a CreateClientSystemAddressRequest.
             * @implements ICreateClientSystemAddressRequest
             * @constructor
             * @param {protocol.ICreateClientSystemAddressRequest=} [properties] Properties to set
             */
            function CreateClientSystemAddressRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CreateClientSystemAddressRequest tag.
             * @member {string} tag
             * @memberof protocol.CreateClientSystemAddressRequest
             * @instance
             */
            CreateClientSystemAddressRequest.prototype.tag = "";
    
            /**
             * CreateClientSystemAddressRequest chain.
             * @member {protocol.Chain} chain
             * @memberof protocol.CreateClientSystemAddressRequest
             * @instance
             */
            CreateClientSystemAddressRequest.prototype.chain = 0;
    
            /**
             * Creates a new CreateClientSystemAddressRequest instance using the specified properties.
             * @function create
             * @memberof protocol.CreateClientSystemAddressRequest
             * @static
             * @param {protocol.ICreateClientSystemAddressRequest=} [properties] Properties to set
             * @returns {protocol.CreateClientSystemAddressRequest} CreateClientSystemAddressRequest instance
             */
            CreateClientSystemAddressRequest.create = function create(properties) {
                return new CreateClientSystemAddressRequest(properties);
            };
    
            /**
             * Encodes the specified CreateClientSystemAddressRequest message. Does not implicitly {@link protocol.CreateClientSystemAddressRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.CreateClientSystemAddressRequest
             * @static
             * @param {protocol.ICreateClientSystemAddressRequest} message CreateClientSystemAddressRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateClientSystemAddressRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.tag);
                if (message.chain != null && Object.hasOwnProperty.call(message, "chain"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.chain);
                return writer;
            };
    
            /**
             * Encodes the specified CreateClientSystemAddressRequest message, length delimited. Does not implicitly {@link protocol.CreateClientSystemAddressRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.CreateClientSystemAddressRequest
             * @static
             * @param {protocol.ICreateClientSystemAddressRequest} message CreateClientSystemAddressRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateClientSystemAddressRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateClientSystemAddressRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.CreateClientSystemAddressRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.CreateClientSystemAddressRequest} CreateClientSystemAddressRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateClientSystemAddressRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.CreateClientSystemAddressRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.tag = reader.string();
                        break;
                    case 2:
                        message.chain = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CreateClientSystemAddressRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.CreateClientSystemAddressRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.CreateClientSystemAddressRequest} CreateClientSystemAddressRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateClientSystemAddressRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateClientSystemAddressRequest message.
             * @function verify
             * @memberof protocol.CreateClientSystemAddressRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateClientSystemAddressRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tag != null && message.hasOwnProperty("tag"))
                    if (!$util.isString(message.tag))
                        return "tag: string expected";
                if (message.chain != null && message.hasOwnProperty("chain"))
                    switch (message.chain) {
                    default:
                        return "chain: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a CreateClientSystemAddressRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.CreateClientSystemAddressRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.CreateClientSystemAddressRequest} CreateClientSystemAddressRequest
             */
            CreateClientSystemAddressRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.CreateClientSystemAddressRequest)
                    return object;
                var message = new $root.protocol.CreateClientSystemAddressRequest();
                if (object.tag != null)
                    message.tag = String(object.tag);
                switch (object.chain) {
                case "UNK_CHAIN":
                case 0:
                    message.chain = 0;
                    break;
                case "BTC_CHAIN":
                case 1:
                    message.chain = 1;
                    break;
                case "ETH_CHAIN":
                case 2:
                    message.chain = 2;
                    break;
                case "TRX_CHAIN":
                case 3:
                    message.chain = 3;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CreateClientSystemAddressRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.CreateClientSystemAddressRequest
             * @static
             * @param {protocol.CreateClientSystemAddressRequest} message CreateClientSystemAddressRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateClientSystemAddressRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.tag = "";
                    object.chain = options.enums === String ? "UNK_CHAIN" : 0;
                }
                if (message.tag != null && message.hasOwnProperty("tag"))
                    object.tag = message.tag;
                if (message.chain != null && message.hasOwnProperty("chain"))
                    object.chain = options.enums === String ? $root.protocol.Chain[message.chain] : message.chain;
                return object;
            };
    
            /**
             * Converts this CreateClientSystemAddressRequest to JSON.
             * @function toJSON
             * @memberof protocol.CreateClientSystemAddressRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateClientSystemAddressRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return CreateClientSystemAddressRequest;
        })();
    
        protocol.CreateClientSystemAddressResponse = (function() {
    
            /**
             * Properties of a CreateClientSystemAddressResponse.
             * @memberof protocol
             * @interface ICreateClientSystemAddressResponse
             * @property {protocol.IClientSystemAddress|null} [address] CreateClientSystemAddressResponse address
             */
    
            /**
             * Constructs a new CreateClientSystemAddressResponse.
             * @memberof protocol
             * @classdesc Represents a CreateClientSystemAddressResponse.
             * @implements ICreateClientSystemAddressResponse
             * @constructor
             * @param {protocol.ICreateClientSystemAddressResponse=} [properties] Properties to set
             */
            function CreateClientSystemAddressResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CreateClientSystemAddressResponse address.
             * @member {protocol.IClientSystemAddress|null|undefined} address
             * @memberof protocol.CreateClientSystemAddressResponse
             * @instance
             */
            CreateClientSystemAddressResponse.prototype.address = null;
    
            /**
             * Creates a new CreateClientSystemAddressResponse instance using the specified properties.
             * @function create
             * @memberof protocol.CreateClientSystemAddressResponse
             * @static
             * @param {protocol.ICreateClientSystemAddressResponse=} [properties] Properties to set
             * @returns {protocol.CreateClientSystemAddressResponse} CreateClientSystemAddressResponse instance
             */
            CreateClientSystemAddressResponse.create = function create(properties) {
                return new CreateClientSystemAddressResponse(properties);
            };
    
            /**
             * Encodes the specified CreateClientSystemAddressResponse message. Does not implicitly {@link protocol.CreateClientSystemAddressResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.CreateClientSystemAddressResponse
             * @static
             * @param {protocol.ICreateClientSystemAddressResponse} message CreateClientSystemAddressResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateClientSystemAddressResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    $root.protocol.ClientSystemAddress.encode(message.address, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified CreateClientSystemAddressResponse message, length delimited. Does not implicitly {@link protocol.CreateClientSystemAddressResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.CreateClientSystemAddressResponse
             * @static
             * @param {protocol.ICreateClientSystemAddressResponse} message CreateClientSystemAddressResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateClientSystemAddressResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateClientSystemAddressResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.CreateClientSystemAddressResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.CreateClientSystemAddressResponse} CreateClientSystemAddressResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateClientSystemAddressResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.CreateClientSystemAddressResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.address = $root.protocol.ClientSystemAddress.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CreateClientSystemAddressResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.CreateClientSystemAddressResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.CreateClientSystemAddressResponse} CreateClientSystemAddressResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateClientSystemAddressResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateClientSystemAddressResponse message.
             * @function verify
             * @memberof protocol.CreateClientSystemAddressResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateClientSystemAddressResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address")) {
                    var error = $root.protocol.ClientSystemAddress.verify(message.address);
                    if (error)
                        return "address." + error;
                }
                return null;
            };
    
            /**
             * Creates a CreateClientSystemAddressResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.CreateClientSystemAddressResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.CreateClientSystemAddressResponse} CreateClientSystemAddressResponse
             */
            CreateClientSystemAddressResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.CreateClientSystemAddressResponse)
                    return object;
                var message = new $root.protocol.CreateClientSystemAddressResponse();
                if (object.address != null) {
                    if (typeof object.address !== "object")
                        throw TypeError(".protocol.CreateClientSystemAddressResponse.address: object expected");
                    message.address = $root.protocol.ClientSystemAddress.fromObject(object.address);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CreateClientSystemAddressResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.CreateClientSystemAddressResponse
             * @static
             * @param {protocol.CreateClientSystemAddressResponse} message CreateClientSystemAddressResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateClientSystemAddressResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.address = null;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = $root.protocol.ClientSystemAddress.toObject(message.address, options);
                return object;
            };
    
            /**
             * Converts this CreateClientSystemAddressResponse to JSON.
             * @function toJSON
             * @memberof protocol.CreateClientSystemAddressResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateClientSystemAddressResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return CreateClientSystemAddressResponse;
        })();
    
        protocol.AppVersionRequest = (function() {
    
            /**
             * Properties of an AppVersionRequest.
             * @memberof protocol
             * @interface IAppVersionRequest
             */
    
            /**
             * Constructs a new AppVersionRequest.
             * @memberof protocol
             * @classdesc Represents an AppVersionRequest.
             * @implements IAppVersionRequest
             * @constructor
             * @param {protocol.IAppVersionRequest=} [properties] Properties to set
             */
            function AppVersionRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new AppVersionRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AppVersionRequest
             * @static
             * @param {protocol.IAppVersionRequest=} [properties] Properties to set
             * @returns {protocol.AppVersionRequest} AppVersionRequest instance
             */
            AppVersionRequest.create = function create(properties) {
                return new AppVersionRequest(properties);
            };
    
            /**
             * Encodes the specified AppVersionRequest message. Does not implicitly {@link protocol.AppVersionRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AppVersionRequest
             * @static
             * @param {protocol.IAppVersionRequest} message AppVersionRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AppVersionRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified AppVersionRequest message, length delimited. Does not implicitly {@link protocol.AppVersionRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AppVersionRequest
             * @static
             * @param {protocol.IAppVersionRequest} message AppVersionRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AppVersionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AppVersionRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AppVersionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AppVersionRequest} AppVersionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AppVersionRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AppVersionRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AppVersionRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AppVersionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AppVersionRequest} AppVersionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AppVersionRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AppVersionRequest message.
             * @function verify
             * @memberof protocol.AppVersionRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AppVersionRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates an AppVersionRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AppVersionRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AppVersionRequest} AppVersionRequest
             */
            AppVersionRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AppVersionRequest)
                    return object;
                return new $root.protocol.AppVersionRequest();
            };
    
            /**
             * Creates a plain object from an AppVersionRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AppVersionRequest
             * @static
             * @param {protocol.AppVersionRequest} message AppVersionRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AppVersionRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this AppVersionRequest to JSON.
             * @function toJSON
             * @memberof protocol.AppVersionRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AppVersionRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AppVersionRequest;
        })();
    
        protocol.AppVersionResponse = (function() {
    
            /**
             * Properties of an AppVersionResponse.
             * @memberof protocol
             * @interface IAppVersionResponse
             * @property {Array.<protocol.AppVersionResponse.IVersion>|null} [versions] AppVersionResponse versions
             */
    
            /**
             * Constructs a new AppVersionResponse.
             * @memberof protocol
             * @classdesc Represents an AppVersionResponse.
             * @implements IAppVersionResponse
             * @constructor
             * @param {protocol.IAppVersionResponse=} [properties] Properties to set
             */
            function AppVersionResponse(properties) {
                this.versions = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AppVersionResponse versions.
             * @member {Array.<protocol.AppVersionResponse.IVersion>} versions
             * @memberof protocol.AppVersionResponse
             * @instance
             */
            AppVersionResponse.prototype.versions = $util.emptyArray;
    
            /**
             * Creates a new AppVersionResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AppVersionResponse
             * @static
             * @param {protocol.IAppVersionResponse=} [properties] Properties to set
             * @returns {protocol.AppVersionResponse} AppVersionResponse instance
             */
            AppVersionResponse.create = function create(properties) {
                return new AppVersionResponse(properties);
            };
    
            /**
             * Encodes the specified AppVersionResponse message. Does not implicitly {@link protocol.AppVersionResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AppVersionResponse
             * @static
             * @param {protocol.IAppVersionResponse} message AppVersionResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AppVersionResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.versions != null && message.versions.length)
                    for (var i = 0; i < message.versions.length; ++i)
                        $root.protocol.AppVersionResponse.Version.encode(message.versions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AppVersionResponse message, length delimited. Does not implicitly {@link protocol.AppVersionResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AppVersionResponse
             * @static
             * @param {protocol.IAppVersionResponse} message AppVersionResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AppVersionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AppVersionResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AppVersionResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AppVersionResponse} AppVersionResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AppVersionResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AppVersionResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.versions && message.versions.length))
                            message.versions = [];
                        message.versions.push($root.protocol.AppVersionResponse.Version.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AppVersionResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AppVersionResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AppVersionResponse} AppVersionResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AppVersionResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AppVersionResponse message.
             * @function verify
             * @memberof protocol.AppVersionResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AppVersionResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.versions != null && message.hasOwnProperty("versions")) {
                    if (!Array.isArray(message.versions))
                        return "versions: array expected";
                    for (var i = 0; i < message.versions.length; ++i) {
                        var error = $root.protocol.AppVersionResponse.Version.verify(message.versions[i]);
                        if (error)
                            return "versions." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an AppVersionResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AppVersionResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AppVersionResponse} AppVersionResponse
             */
            AppVersionResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AppVersionResponse)
                    return object;
                var message = new $root.protocol.AppVersionResponse();
                if (object.versions) {
                    if (!Array.isArray(object.versions))
                        throw TypeError(".protocol.AppVersionResponse.versions: array expected");
                    message.versions = [];
                    for (var i = 0; i < object.versions.length; ++i) {
                        if (typeof object.versions[i] !== "object")
                            throw TypeError(".protocol.AppVersionResponse.versions: object expected");
                        message.versions[i] = $root.protocol.AppVersionResponse.Version.fromObject(object.versions[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from an AppVersionResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AppVersionResponse
             * @static
             * @param {protocol.AppVersionResponse} message AppVersionResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AppVersionResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.versions = [];
                if (message.versions && message.versions.length) {
                    object.versions = [];
                    for (var j = 0; j < message.versions.length; ++j)
                        object.versions[j] = $root.protocol.AppVersionResponse.Version.toObject(message.versions[j], options);
                }
                return object;
            };
    
            /**
             * Converts this AppVersionResponse to JSON.
             * @function toJSON
             * @memberof protocol.AppVersionResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AppVersionResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            AppVersionResponse.Version = (function() {
    
                /**
                 * Properties of a Version.
                 * @memberof protocol.AppVersionResponse
                 * @interface IVersion
                 * @property {string|null} [url] Version url
                 * @property {string|null} [currentHash] Version currentHash
                 */
    
                /**
                 * Constructs a new Version.
                 * @memberof protocol.AppVersionResponse
                 * @classdesc Represents a Version.
                 * @implements IVersion
                 * @constructor
                 * @param {protocol.AppVersionResponse.IVersion=} [properties] Properties to set
                 */
                function Version(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Version url.
                 * @member {string} url
                 * @memberof protocol.AppVersionResponse.Version
                 * @instance
                 */
                Version.prototype.url = "";
    
                /**
                 * Version currentHash.
                 * @member {string} currentHash
                 * @memberof protocol.AppVersionResponse.Version
                 * @instance
                 */
                Version.prototype.currentHash = "";
    
                /**
                 * Creates a new Version instance using the specified properties.
                 * @function create
                 * @memberof protocol.AppVersionResponse.Version
                 * @static
                 * @param {protocol.AppVersionResponse.IVersion=} [properties] Properties to set
                 * @returns {protocol.AppVersionResponse.Version} Version instance
                 */
                Version.create = function create(properties) {
                    return new Version(properties);
                };
    
                /**
                 * Encodes the specified Version message. Does not implicitly {@link protocol.AppVersionResponse.Version.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.AppVersionResponse.Version
                 * @static
                 * @param {protocol.AppVersionResponse.IVersion} message Version message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Version.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
                    if (message.currentHash != null && Object.hasOwnProperty.call(message, "currentHash"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.currentHash);
                    return writer;
                };
    
                /**
                 * Encodes the specified Version message, length delimited. Does not implicitly {@link protocol.AppVersionResponse.Version.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.AppVersionResponse.Version
                 * @static
                 * @param {protocol.AppVersionResponse.IVersion} message Version message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Version.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Version message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.AppVersionResponse.Version
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.AppVersionResponse.Version} Version
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Version.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AppVersionResponse.Version();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.url = reader.string();
                            break;
                        case 2:
                            message.currentHash = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Version message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.AppVersionResponse.Version
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.AppVersionResponse.Version} Version
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Version.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Version message.
                 * @function verify
                 * @memberof protocol.AppVersionResponse.Version
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Version.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.currentHash != null && message.hasOwnProperty("currentHash"))
                        if (!$util.isString(message.currentHash))
                            return "currentHash: string expected";
                    return null;
                };
    
                /**
                 * Creates a Version message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.AppVersionResponse.Version
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.AppVersionResponse.Version} Version
                 */
                Version.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.AppVersionResponse.Version)
                        return object;
                    var message = new $root.protocol.AppVersionResponse.Version();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.currentHash != null)
                        message.currentHash = String(object.currentHash);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Version message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.AppVersionResponse.Version
                 * @static
                 * @param {protocol.AppVersionResponse.Version} message Version
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Version.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.currentHash = "";
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.currentHash != null && message.hasOwnProperty("currentHash"))
                        object.currentHash = message.currentHash;
                    return object;
                };
    
                /**
                 * Converts this Version to JSON.
                 * @function toJSON
                 * @memberof protocol.AppVersionResponse.Version
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Version.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Version;
            })();
    
            return AppVersionResponse;
        })();
    
        /**
         * Currency enum.
         * @name protocol.Currency
         * @enum {number}
         * @property {number} UNK_CUR=0 UNK_CUR value
         * @property {number} BTC_CUR=1 BTC_CUR value
         * @property {number} ETH_CUR=2 ETH_CUR value
         * @property {number} TRX_CUR=3 TRX_CUR value
         * @property {number} ERC20_USDT_CUR=4 ERC20_USDT_CUR value
         * @property {number} TRC20_USDT_CUR=5 TRC20_USDT_CUR value
         */
        protocol.Currency = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_CUR"] = 0;
            values[valuesById[1] = "BTC_CUR"] = 1;
            values[valuesById[2] = "ETH_CUR"] = 2;
            values[valuesById[3] = "TRX_CUR"] = 3;
            values[valuesById[4] = "ERC20_USDT_CUR"] = 4;
            values[valuesById[5] = "TRC20_USDT_CUR"] = 5;
            return values;
        })();
    
        /**
         * FiatCurrency enum.
         * @name protocol.FiatCurrency
         * @enum {number}
         * @property {number} UNK_FIAT_CUR=0 UNK_FIAT_CUR value
         * @property {number} USD_FIAT_CUR=1 USD_FIAT_CUR value
         * @property {number} EUR_FIAT_CUR=2 EUR_FIAT_CUR value
         * @property {number} ARS_FIAT_CUR=3 ARS_FIAT_CUR value
         * @property {number} PEN_FIAT_CUR=4 PEN_FIAT_CUR value
         * @property {number} BOB_FIAT_CUR=5 BOB_FIAT_CUR value
         * @property {number} CLP_FIAT_CUR=6 CLP_FIAT_CUR value
         * @property {number} BRL_FIAT_CUR=7 BRL_FIAT_CUR value
         */
        protocol.FiatCurrency = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_FIAT_CUR"] = 0;
            values[valuesById[1] = "USD_FIAT_CUR"] = 1;
            values[valuesById[2] = "EUR_FIAT_CUR"] = 2;
            values[valuesById[3] = "ARS_FIAT_CUR"] = 3;
            values[valuesById[4] = "PEN_FIAT_CUR"] = 4;
            values[valuesById[5] = "BOB_FIAT_CUR"] = 5;
            values[valuesById[6] = "CLP_FIAT_CUR"] = 6;
            values[valuesById[7] = "BRL_FIAT_CUR"] = 7;
            return values;
        })();
    
        /**
         * Chain enum.
         * @name protocol.Chain
         * @enum {number}
         * @property {number} UNK_CHAIN=0 UNK_CHAIN value
         * @property {number} BTC_CHAIN=1 BTC_CHAIN value
         * @property {number} ETH_CHAIN=2 ETH_CHAIN value
         * @property {number} TRX_CHAIN=3 TRX_CHAIN value
         */
        protocol.Chain = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_CHAIN"] = 0;
            values[valuesById[1] = "BTC_CHAIN"] = 1;
            values[valuesById[2] = "ETH_CHAIN"] = 2;
            values[valuesById[3] = "TRX_CHAIN"] = 3;
            return values;
        })();
    
        /**
         * SortBy enum.
         * @name protocol.SortBy
         * @enum {number}
         * @property {number} UNK_SORT_BY=0 UNK_SORT_BY value
         * @property {number} ID_SORT_BY=1 ID_SORT_BY value
         * @property {number} DATE_SORT_BY=2 DATE_SORT_BY value
         * @property {number} CURRENCY_SORT_BY=3 CURRENCY_SORT_BY value
         * @property {number} TAGS_SORT_BY=4 TAGS_SORT_BY value
         * @property {number} TX_HASH_SORT_BY=5 TX_HASH_SORT_BY value
         * @property {number} AMOUNT_SORT_BY=6 AMOUNT_SORT_BY value
         * @property {number} NAME_SORT_BY=7 NAME_SORT_BY value
         * @property {number} LAST_AMOUNT_SORT_BY=8 LAST_AMOUNT_SORT_BY value
         * @property {number} STATUS_SORT_BY=9 STATUS_SORT_BY value
         * @property {number} ENTITIES_COUNT_SORT_BY=10 ENTITIES_COUNT_SORT_BY value
         * @property {number} ADDRESS_SORT_BY=11 ADDRESS_SORT_BY value
         * @property {number} RISK_SCORE_SORT_BY=12 RISK_SCORE_SORT_BY value
         * @property {number} BALANCE_SORT_BY=13 BALANCE_SORT_BY value
         */
        protocol.SortBy = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_SORT_BY"] = 0;
            values[valuesById[1] = "ID_SORT_BY"] = 1;
            values[valuesById[2] = "DATE_SORT_BY"] = 2;
            values[valuesById[3] = "CURRENCY_SORT_BY"] = 3;
            values[valuesById[4] = "TAGS_SORT_BY"] = 4;
            values[valuesById[5] = "TX_HASH_SORT_BY"] = 5;
            values[valuesById[6] = "AMOUNT_SORT_BY"] = 6;
            values[valuesById[7] = "NAME_SORT_BY"] = 7;
            values[valuesById[8] = "LAST_AMOUNT_SORT_BY"] = 8;
            values[valuesById[9] = "STATUS_SORT_BY"] = 9;
            values[valuesById[10] = "ENTITIES_COUNT_SORT_BY"] = 10;
            values[valuesById[11] = "ADDRESS_SORT_BY"] = 11;
            values[valuesById[12] = "RISK_SCORE_SORT_BY"] = 12;
            values[valuesById[13] = "BALANCE_SORT_BY"] = 13;
            return values;
        })();
    
        protocol.TotalBalance = (function() {
    
            /**
             * Properties of a TotalBalance.
             * @memberof protocol
             * @interface ITotalBalance
             * @property {protocol.Currency|null} [currency] TotalBalance currency
             * @property {string|null} [balance] TotalBalance balance
             * @property {Object.<string,string>|null} [balanceFiat] TotalBalance balanceFiat
             * @property {string|null} [unconfirmedInBalance] TotalBalance unconfirmedInBalance
             * @property {Object.<string,string>|null} [unconfirmedInBalanceFiat] TotalBalance unconfirmedInBalanceFiat
             * @property {string|null} [unconfirmedOutBalance] TotalBalance unconfirmedOutBalance
             * @property {Object.<string,string>|null} [unconfirmedOutBalanceFiat] TotalBalance unconfirmedOutBalanceFiat
             */
    
            /**
             * Constructs a new TotalBalance.
             * @memberof protocol
             * @classdesc Represents a TotalBalance.
             * @implements ITotalBalance
             * @constructor
             * @param {protocol.ITotalBalance=} [properties] Properties to set
             */
            function TotalBalance(properties) {
                this.balanceFiat = {};
                this.unconfirmedInBalanceFiat = {};
                this.unconfirmedOutBalanceFiat = {};
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TotalBalance currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.TotalBalance
             * @instance
             */
            TotalBalance.prototype.currency = 0;
    
            /**
             * TotalBalance balance.
             * @member {string} balance
             * @memberof protocol.TotalBalance
             * @instance
             */
            TotalBalance.prototype.balance = "";
    
            /**
             * TotalBalance balanceFiat.
             * @member {Object.<string,string>} balanceFiat
             * @memberof protocol.TotalBalance
             * @instance
             */
            TotalBalance.prototype.balanceFiat = $util.emptyObject;
    
            /**
             * TotalBalance unconfirmedInBalance.
             * @member {string} unconfirmedInBalance
             * @memberof protocol.TotalBalance
             * @instance
             */
            TotalBalance.prototype.unconfirmedInBalance = "";
    
            /**
             * TotalBalance unconfirmedInBalanceFiat.
             * @member {Object.<string,string>} unconfirmedInBalanceFiat
             * @memberof protocol.TotalBalance
             * @instance
             */
            TotalBalance.prototype.unconfirmedInBalanceFiat = $util.emptyObject;
    
            /**
             * TotalBalance unconfirmedOutBalance.
             * @member {string} unconfirmedOutBalance
             * @memberof protocol.TotalBalance
             * @instance
             */
            TotalBalance.prototype.unconfirmedOutBalance = "";
    
            /**
             * TotalBalance unconfirmedOutBalanceFiat.
             * @member {Object.<string,string>} unconfirmedOutBalanceFiat
             * @memberof protocol.TotalBalance
             * @instance
             */
            TotalBalance.prototype.unconfirmedOutBalanceFiat = $util.emptyObject;
    
            /**
             * Creates a new TotalBalance instance using the specified properties.
             * @function create
             * @memberof protocol.TotalBalance
             * @static
             * @param {protocol.ITotalBalance=} [properties] Properties to set
             * @returns {protocol.TotalBalance} TotalBalance instance
             */
            TotalBalance.create = function create(properties) {
                return new TotalBalance(properties);
            };
    
            /**
             * Encodes the specified TotalBalance message. Does not implicitly {@link protocol.TotalBalance.verify|verify} messages.
             * @function encode
             * @memberof protocol.TotalBalance
             * @static
             * @param {protocol.ITotalBalance} message TotalBalance message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TotalBalance.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currency);
                if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.balance);
                if (message.balanceFiat != null && Object.hasOwnProperty.call(message, "balanceFiat"))
                    for (var keys = Object.keys(message.balanceFiat), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.balanceFiat[keys[i]]).ldelim();
                if (message.unconfirmedInBalance != null && Object.hasOwnProperty.call(message, "unconfirmedInBalance"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.unconfirmedInBalance);
                if (message.unconfirmedInBalanceFiat != null && Object.hasOwnProperty.call(message, "unconfirmedInBalanceFiat"))
                    for (var keys = Object.keys(message.unconfirmedInBalanceFiat), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 5, wireType 2 =*/42).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.unconfirmedInBalanceFiat[keys[i]]).ldelim();
                if (message.unconfirmedOutBalance != null && Object.hasOwnProperty.call(message, "unconfirmedOutBalance"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.unconfirmedOutBalance);
                if (message.unconfirmedOutBalanceFiat != null && Object.hasOwnProperty.call(message, "unconfirmedOutBalanceFiat"))
                    for (var keys = Object.keys(message.unconfirmedOutBalanceFiat), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 7, wireType 2 =*/58).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.unconfirmedOutBalanceFiat[keys[i]]).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TotalBalance message, length delimited. Does not implicitly {@link protocol.TotalBalance.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TotalBalance
             * @static
             * @param {protocol.ITotalBalance} message TotalBalance message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TotalBalance.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TotalBalance message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TotalBalance
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TotalBalance} TotalBalance
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TotalBalance.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TotalBalance(), key;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.currency = reader.int32();
                        break;
                    case 2:
                        message.balance = reader.string();
                        break;
                    case 3:
                        reader.skip().pos++;
                        if (message.balanceFiat === $util.emptyObject)
                            message.balanceFiat = {};
                        key = reader.string();
                        reader.pos++;
                        message.balanceFiat[key] = reader.string();
                        break;
                    case 4:
                        message.unconfirmedInBalance = reader.string();
                        break;
                    case 5:
                        reader.skip().pos++;
                        if (message.unconfirmedInBalanceFiat === $util.emptyObject)
                            message.unconfirmedInBalanceFiat = {};
                        key = reader.string();
                        reader.pos++;
                        message.unconfirmedInBalanceFiat[key] = reader.string();
                        break;
                    case 6:
                        message.unconfirmedOutBalance = reader.string();
                        break;
                    case 7:
                        reader.skip().pos++;
                        if (message.unconfirmedOutBalanceFiat === $util.emptyObject)
                            message.unconfirmedOutBalanceFiat = {};
                        key = reader.string();
                        reader.pos++;
                        message.unconfirmedOutBalanceFiat[key] = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TotalBalance message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TotalBalance
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TotalBalance} TotalBalance
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TotalBalance.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TotalBalance message.
             * @function verify
             * @memberof protocol.TotalBalance
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TotalBalance.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.balance != null && message.hasOwnProperty("balance"))
                    if (!$util.isString(message.balance))
                        return "balance: string expected";
                if (message.balanceFiat != null && message.hasOwnProperty("balanceFiat")) {
                    if (!$util.isObject(message.balanceFiat))
                        return "balanceFiat: object expected";
                    var key = Object.keys(message.balanceFiat);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.balanceFiat[key[i]]))
                            return "balanceFiat: string{k:string} expected";
                }
                if (message.unconfirmedInBalance != null && message.hasOwnProperty("unconfirmedInBalance"))
                    if (!$util.isString(message.unconfirmedInBalance))
                        return "unconfirmedInBalance: string expected";
                if (message.unconfirmedInBalanceFiat != null && message.hasOwnProperty("unconfirmedInBalanceFiat")) {
                    if (!$util.isObject(message.unconfirmedInBalanceFiat))
                        return "unconfirmedInBalanceFiat: object expected";
                    var key = Object.keys(message.unconfirmedInBalanceFiat);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.unconfirmedInBalanceFiat[key[i]]))
                            return "unconfirmedInBalanceFiat: string{k:string} expected";
                }
                if (message.unconfirmedOutBalance != null && message.hasOwnProperty("unconfirmedOutBalance"))
                    if (!$util.isString(message.unconfirmedOutBalance))
                        return "unconfirmedOutBalance: string expected";
                if (message.unconfirmedOutBalanceFiat != null && message.hasOwnProperty("unconfirmedOutBalanceFiat")) {
                    if (!$util.isObject(message.unconfirmedOutBalanceFiat))
                        return "unconfirmedOutBalanceFiat: object expected";
                    var key = Object.keys(message.unconfirmedOutBalanceFiat);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.unconfirmedOutBalanceFiat[key[i]]))
                            return "unconfirmedOutBalanceFiat: string{k:string} expected";
                }
                return null;
            };
    
            /**
             * Creates a TotalBalance message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TotalBalance
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TotalBalance} TotalBalance
             */
            TotalBalance.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TotalBalance)
                    return object;
                var message = new $root.protocol.TotalBalance();
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                if (object.balance != null)
                    message.balance = String(object.balance);
                if (object.balanceFiat) {
                    if (typeof object.balanceFiat !== "object")
                        throw TypeError(".protocol.TotalBalance.balanceFiat: object expected");
                    message.balanceFiat = {};
                    for (var keys = Object.keys(object.balanceFiat), i = 0; i < keys.length; ++i)
                        message.balanceFiat[keys[i]] = String(object.balanceFiat[keys[i]]);
                }
                if (object.unconfirmedInBalance != null)
                    message.unconfirmedInBalance = String(object.unconfirmedInBalance);
                if (object.unconfirmedInBalanceFiat) {
                    if (typeof object.unconfirmedInBalanceFiat !== "object")
                        throw TypeError(".protocol.TotalBalance.unconfirmedInBalanceFiat: object expected");
                    message.unconfirmedInBalanceFiat = {};
                    for (var keys = Object.keys(object.unconfirmedInBalanceFiat), i = 0; i < keys.length; ++i)
                        message.unconfirmedInBalanceFiat[keys[i]] = String(object.unconfirmedInBalanceFiat[keys[i]]);
                }
                if (object.unconfirmedOutBalance != null)
                    message.unconfirmedOutBalance = String(object.unconfirmedOutBalance);
                if (object.unconfirmedOutBalanceFiat) {
                    if (typeof object.unconfirmedOutBalanceFiat !== "object")
                        throw TypeError(".protocol.TotalBalance.unconfirmedOutBalanceFiat: object expected");
                    message.unconfirmedOutBalanceFiat = {};
                    for (var keys = Object.keys(object.unconfirmedOutBalanceFiat), i = 0; i < keys.length; ++i)
                        message.unconfirmedOutBalanceFiat[keys[i]] = String(object.unconfirmedOutBalanceFiat[keys[i]]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TotalBalance message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TotalBalance
             * @static
             * @param {protocol.TotalBalance} message TotalBalance
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TotalBalance.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.objects || options.defaults) {
                    object.balanceFiat = {};
                    object.unconfirmedInBalanceFiat = {};
                    object.unconfirmedOutBalanceFiat = {};
                }
                if (options.defaults) {
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.balance = "";
                    object.unconfirmedInBalance = "";
                    object.unconfirmedOutBalance = "";
                }
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.balance != null && message.hasOwnProperty("balance"))
                    object.balance = message.balance;
                var keys2;
                if (message.balanceFiat && (keys2 = Object.keys(message.balanceFiat)).length) {
                    object.balanceFiat = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.balanceFiat[keys2[j]] = message.balanceFiat[keys2[j]];
                }
                if (message.unconfirmedInBalance != null && message.hasOwnProperty("unconfirmedInBalance"))
                    object.unconfirmedInBalance = message.unconfirmedInBalance;
                if (message.unconfirmedInBalanceFiat && (keys2 = Object.keys(message.unconfirmedInBalanceFiat)).length) {
                    object.unconfirmedInBalanceFiat = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.unconfirmedInBalanceFiat[keys2[j]] = message.unconfirmedInBalanceFiat[keys2[j]];
                }
                if (message.unconfirmedOutBalance != null && message.hasOwnProperty("unconfirmedOutBalance"))
                    object.unconfirmedOutBalance = message.unconfirmedOutBalance;
                if (message.unconfirmedOutBalanceFiat && (keys2 = Object.keys(message.unconfirmedOutBalanceFiat)).length) {
                    object.unconfirmedOutBalanceFiat = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.unconfirmedOutBalanceFiat[keys2[j]] = message.unconfirmedOutBalanceFiat[keys2[j]];
                }
                return object;
            };
    
            /**
             * Converts this TotalBalance to JSON.
             * @function toJSON
             * @memberof protocol.TotalBalance
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TotalBalance.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TotalBalance;
        })();
    
        protocol.TransactionsStats = (function() {
    
            /**
             * Properties of a TransactionsStats.
             * @memberof protocol
             * @interface ITransactionsStats
             * @property {Long|null} [totalCount] TransactionsStats totalCount
             * @property {Long|null} [totalInCount] TransactionsStats totalInCount
             * @property {Long|null} [totalOutCount] TransactionsStats totalOutCount
             * @property {Object.<string,string>|null} [totalIncomes] TransactionsStats totalIncomes
             * @property {Object.<string,string>|null} [totalOutcomes] TransactionsStats totalOutcomes
             * @property {Object.<string,string>|null} [totalDeltas] TransactionsStats totalDeltas
             * @property {Object.<string,string>|null} [totalFees] TransactionsStats totalFees
             */
    
            /**
             * Constructs a new TransactionsStats.
             * @memberof protocol
             * @classdesc Represents a TransactionsStats.
             * @implements ITransactionsStats
             * @constructor
             * @param {protocol.ITransactionsStats=} [properties] Properties to set
             */
            function TransactionsStats(properties) {
                this.totalIncomes = {};
                this.totalOutcomes = {};
                this.totalDeltas = {};
                this.totalFees = {};
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TransactionsStats totalCount.
             * @member {Long} totalCount
             * @memberof protocol.TransactionsStats
             * @instance
             */
            TransactionsStats.prototype.totalCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TransactionsStats totalInCount.
             * @member {Long} totalInCount
             * @memberof protocol.TransactionsStats
             * @instance
             */
            TransactionsStats.prototype.totalInCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TransactionsStats totalOutCount.
             * @member {Long} totalOutCount
             * @memberof protocol.TransactionsStats
             * @instance
             */
            TransactionsStats.prototype.totalOutCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TransactionsStats totalIncomes.
             * @member {Object.<string,string>} totalIncomes
             * @memberof protocol.TransactionsStats
             * @instance
             */
            TransactionsStats.prototype.totalIncomes = $util.emptyObject;
    
            /**
             * TransactionsStats totalOutcomes.
             * @member {Object.<string,string>} totalOutcomes
             * @memberof protocol.TransactionsStats
             * @instance
             */
            TransactionsStats.prototype.totalOutcomes = $util.emptyObject;
    
            /**
             * TransactionsStats totalDeltas.
             * @member {Object.<string,string>} totalDeltas
             * @memberof protocol.TransactionsStats
             * @instance
             */
            TransactionsStats.prototype.totalDeltas = $util.emptyObject;
    
            /**
             * TransactionsStats totalFees.
             * @member {Object.<string,string>} totalFees
             * @memberof protocol.TransactionsStats
             * @instance
             */
            TransactionsStats.prototype.totalFees = $util.emptyObject;
    
            /**
             * Creates a new TransactionsStats instance using the specified properties.
             * @function create
             * @memberof protocol.TransactionsStats
             * @static
             * @param {protocol.ITransactionsStats=} [properties] Properties to set
             * @returns {protocol.TransactionsStats} TransactionsStats instance
             */
            TransactionsStats.create = function create(properties) {
                return new TransactionsStats(properties);
            };
    
            /**
             * Encodes the specified TransactionsStats message. Does not implicitly {@link protocol.TransactionsStats.verify|verify} messages.
             * @function encode
             * @memberof protocol.TransactionsStats
             * @static
             * @param {protocol.ITransactionsStats} message TransactionsStats message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionsStats.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.totalCount);
                if (message.totalInCount != null && Object.hasOwnProperty.call(message, "totalInCount"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalInCount);
                if (message.totalOutCount != null && Object.hasOwnProperty.call(message, "totalOutCount"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalOutCount);
                if (message.totalIncomes != null && Object.hasOwnProperty.call(message, "totalIncomes"))
                    for (var keys = Object.keys(message.totalIncomes), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.totalIncomes[keys[i]]).ldelim();
                if (message.totalOutcomes != null && Object.hasOwnProperty.call(message, "totalOutcomes"))
                    for (var keys = Object.keys(message.totalOutcomes), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 5, wireType 2 =*/42).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.totalOutcomes[keys[i]]).ldelim();
                if (message.totalDeltas != null && Object.hasOwnProperty.call(message, "totalDeltas"))
                    for (var keys = Object.keys(message.totalDeltas), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.totalDeltas[keys[i]]).ldelim();
                if (message.totalFees != null && Object.hasOwnProperty.call(message, "totalFees"))
                    for (var keys = Object.keys(message.totalFees), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 7, wireType 2 =*/58).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.totalFees[keys[i]]).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TransactionsStats message, length delimited. Does not implicitly {@link protocol.TransactionsStats.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TransactionsStats
             * @static
             * @param {protocol.ITransactionsStats} message TransactionsStats message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionsStats.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TransactionsStats message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TransactionsStats
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TransactionsStats} TransactionsStats
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionsStats.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TransactionsStats(), key;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.totalCount = reader.int64();
                        break;
                    case 2:
                        message.totalInCount = reader.int64();
                        break;
                    case 3:
                        message.totalOutCount = reader.int64();
                        break;
                    case 4:
                        reader.skip().pos++;
                        if (message.totalIncomes === $util.emptyObject)
                            message.totalIncomes = {};
                        key = reader.string();
                        reader.pos++;
                        message.totalIncomes[key] = reader.string();
                        break;
                    case 5:
                        reader.skip().pos++;
                        if (message.totalOutcomes === $util.emptyObject)
                            message.totalOutcomes = {};
                        key = reader.string();
                        reader.pos++;
                        message.totalOutcomes[key] = reader.string();
                        break;
                    case 6:
                        reader.skip().pos++;
                        if (message.totalDeltas === $util.emptyObject)
                            message.totalDeltas = {};
                        key = reader.string();
                        reader.pos++;
                        message.totalDeltas[key] = reader.string();
                        break;
                    case 7:
                        reader.skip().pos++;
                        if (message.totalFees === $util.emptyObject)
                            message.totalFees = {};
                        key = reader.string();
                        reader.pos++;
                        message.totalFees[key] = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TransactionsStats message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TransactionsStats
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TransactionsStats} TransactionsStats
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionsStats.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TransactionsStats message.
             * @function verify
             * @memberof protocol.TransactionsStats
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TransactionsStats.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (!$util.isInteger(message.totalCount) && !(message.totalCount && $util.isInteger(message.totalCount.low) && $util.isInteger(message.totalCount.high)))
                        return "totalCount: integer|Long expected";
                if (message.totalInCount != null && message.hasOwnProperty("totalInCount"))
                    if (!$util.isInteger(message.totalInCount) && !(message.totalInCount && $util.isInteger(message.totalInCount.low) && $util.isInteger(message.totalInCount.high)))
                        return "totalInCount: integer|Long expected";
                if (message.totalOutCount != null && message.hasOwnProperty("totalOutCount"))
                    if (!$util.isInteger(message.totalOutCount) && !(message.totalOutCount && $util.isInteger(message.totalOutCount.low) && $util.isInteger(message.totalOutCount.high)))
                        return "totalOutCount: integer|Long expected";
                if (message.totalIncomes != null && message.hasOwnProperty("totalIncomes")) {
                    if (!$util.isObject(message.totalIncomes))
                        return "totalIncomes: object expected";
                    var key = Object.keys(message.totalIncomes);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.totalIncomes[key[i]]))
                            return "totalIncomes: string{k:string} expected";
                }
                if (message.totalOutcomes != null && message.hasOwnProperty("totalOutcomes")) {
                    if (!$util.isObject(message.totalOutcomes))
                        return "totalOutcomes: object expected";
                    var key = Object.keys(message.totalOutcomes);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.totalOutcomes[key[i]]))
                            return "totalOutcomes: string{k:string} expected";
                }
                if (message.totalDeltas != null && message.hasOwnProperty("totalDeltas")) {
                    if (!$util.isObject(message.totalDeltas))
                        return "totalDeltas: object expected";
                    var key = Object.keys(message.totalDeltas);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.totalDeltas[key[i]]))
                            return "totalDeltas: string{k:string} expected";
                }
                if (message.totalFees != null && message.hasOwnProperty("totalFees")) {
                    if (!$util.isObject(message.totalFees))
                        return "totalFees: object expected";
                    var key = Object.keys(message.totalFees);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.totalFees[key[i]]))
                            return "totalFees: string{k:string} expected";
                }
                return null;
            };
    
            /**
             * Creates a TransactionsStats message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TransactionsStats
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TransactionsStats} TransactionsStats
             */
            TransactionsStats.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TransactionsStats)
                    return object;
                var message = new $root.protocol.TransactionsStats();
                if (object.totalCount != null)
                    if ($util.Long)
                        (message.totalCount = $util.Long.fromValue(object.totalCount)).unsigned = false;
                    else if (typeof object.totalCount === "string")
                        message.totalCount = parseInt(object.totalCount, 10);
                    else if (typeof object.totalCount === "number")
                        message.totalCount = object.totalCount;
                    else if (typeof object.totalCount === "object")
                        message.totalCount = new $util.LongBits(object.totalCount.low >>> 0, object.totalCount.high >>> 0).toNumber();
                if (object.totalInCount != null)
                    if ($util.Long)
                        (message.totalInCount = $util.Long.fromValue(object.totalInCount)).unsigned = false;
                    else if (typeof object.totalInCount === "string")
                        message.totalInCount = parseInt(object.totalInCount, 10);
                    else if (typeof object.totalInCount === "number")
                        message.totalInCount = object.totalInCount;
                    else if (typeof object.totalInCount === "object")
                        message.totalInCount = new $util.LongBits(object.totalInCount.low >>> 0, object.totalInCount.high >>> 0).toNumber();
                if (object.totalOutCount != null)
                    if ($util.Long)
                        (message.totalOutCount = $util.Long.fromValue(object.totalOutCount)).unsigned = false;
                    else if (typeof object.totalOutCount === "string")
                        message.totalOutCount = parseInt(object.totalOutCount, 10);
                    else if (typeof object.totalOutCount === "number")
                        message.totalOutCount = object.totalOutCount;
                    else if (typeof object.totalOutCount === "object")
                        message.totalOutCount = new $util.LongBits(object.totalOutCount.low >>> 0, object.totalOutCount.high >>> 0).toNumber();
                if (object.totalIncomes) {
                    if (typeof object.totalIncomes !== "object")
                        throw TypeError(".protocol.TransactionsStats.totalIncomes: object expected");
                    message.totalIncomes = {};
                    for (var keys = Object.keys(object.totalIncomes), i = 0; i < keys.length; ++i)
                        message.totalIncomes[keys[i]] = String(object.totalIncomes[keys[i]]);
                }
                if (object.totalOutcomes) {
                    if (typeof object.totalOutcomes !== "object")
                        throw TypeError(".protocol.TransactionsStats.totalOutcomes: object expected");
                    message.totalOutcomes = {};
                    for (var keys = Object.keys(object.totalOutcomes), i = 0; i < keys.length; ++i)
                        message.totalOutcomes[keys[i]] = String(object.totalOutcomes[keys[i]]);
                }
                if (object.totalDeltas) {
                    if (typeof object.totalDeltas !== "object")
                        throw TypeError(".protocol.TransactionsStats.totalDeltas: object expected");
                    message.totalDeltas = {};
                    for (var keys = Object.keys(object.totalDeltas), i = 0; i < keys.length; ++i)
                        message.totalDeltas[keys[i]] = String(object.totalDeltas[keys[i]]);
                }
                if (object.totalFees) {
                    if (typeof object.totalFees !== "object")
                        throw TypeError(".protocol.TransactionsStats.totalFees: object expected");
                    message.totalFees = {};
                    for (var keys = Object.keys(object.totalFees), i = 0; i < keys.length; ++i)
                        message.totalFees[keys[i]] = String(object.totalFees[keys[i]]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TransactionsStats message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TransactionsStats
             * @static
             * @param {protocol.TransactionsStats} message TransactionsStats
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransactionsStats.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.objects || options.defaults) {
                    object.totalIncomes = {};
                    object.totalOutcomes = {};
                    object.totalDeltas = {};
                    object.totalFees = {};
                }
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalCount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalInCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalInCount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalOutCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalOutCount = options.longs === String ? "0" : 0;
                }
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (typeof message.totalCount === "number")
                        object.totalCount = options.longs === String ? String(message.totalCount) : message.totalCount;
                    else
                        object.totalCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalCount) : options.longs === Number ? new $util.LongBits(message.totalCount.low >>> 0, message.totalCount.high >>> 0).toNumber() : message.totalCount;
                if (message.totalInCount != null && message.hasOwnProperty("totalInCount"))
                    if (typeof message.totalInCount === "number")
                        object.totalInCount = options.longs === String ? String(message.totalInCount) : message.totalInCount;
                    else
                        object.totalInCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalInCount) : options.longs === Number ? new $util.LongBits(message.totalInCount.low >>> 0, message.totalInCount.high >>> 0).toNumber() : message.totalInCount;
                if (message.totalOutCount != null && message.hasOwnProperty("totalOutCount"))
                    if (typeof message.totalOutCount === "number")
                        object.totalOutCount = options.longs === String ? String(message.totalOutCount) : message.totalOutCount;
                    else
                        object.totalOutCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalOutCount) : options.longs === Number ? new $util.LongBits(message.totalOutCount.low >>> 0, message.totalOutCount.high >>> 0).toNumber() : message.totalOutCount;
                var keys2;
                if (message.totalIncomes && (keys2 = Object.keys(message.totalIncomes)).length) {
                    object.totalIncomes = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.totalIncomes[keys2[j]] = message.totalIncomes[keys2[j]];
                }
                if (message.totalOutcomes && (keys2 = Object.keys(message.totalOutcomes)).length) {
                    object.totalOutcomes = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.totalOutcomes[keys2[j]] = message.totalOutcomes[keys2[j]];
                }
                if (message.totalDeltas && (keys2 = Object.keys(message.totalDeltas)).length) {
                    object.totalDeltas = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.totalDeltas[keys2[j]] = message.totalDeltas[keys2[j]];
                }
                if (message.totalFees && (keys2 = Object.keys(message.totalFees)).length) {
                    object.totalFees = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.totalFees[keys2[j]] = message.totalFees[keys2[j]];
                }
                return object;
            };
    
            /**
             * Converts this TransactionsStats to JSON.
             * @function toJSON
             * @memberof protocol.TransactionsStats
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransactionsStats.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TransactionsStats;
        })();
    
        protocol.FeeRatesRequest = (function() {
    
            /**
             * Properties of a FeeRatesRequest.
             * @memberof protocol
             * @interface IFeeRatesRequest
             * @property {protocol.Chain|null} [chain] FeeRatesRequest chain
             */
    
            /**
             * Constructs a new FeeRatesRequest.
             * @memberof protocol
             * @classdesc Represents a FeeRatesRequest.
             * @implements IFeeRatesRequest
             * @constructor
             * @param {protocol.IFeeRatesRequest=} [properties] Properties to set
             */
            function FeeRatesRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * FeeRatesRequest chain.
             * @member {protocol.Chain} chain
             * @memberof protocol.FeeRatesRequest
             * @instance
             */
            FeeRatesRequest.prototype.chain = 0;
    
            /**
             * Creates a new FeeRatesRequest instance using the specified properties.
             * @function create
             * @memberof protocol.FeeRatesRequest
             * @static
             * @param {protocol.IFeeRatesRequest=} [properties] Properties to set
             * @returns {protocol.FeeRatesRequest} FeeRatesRequest instance
             */
            FeeRatesRequest.create = function create(properties) {
                return new FeeRatesRequest(properties);
            };
    
            /**
             * Encodes the specified FeeRatesRequest message. Does not implicitly {@link protocol.FeeRatesRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.FeeRatesRequest
             * @static
             * @param {protocol.IFeeRatesRequest} message FeeRatesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FeeRatesRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chain != null && Object.hasOwnProperty.call(message, "chain"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.chain);
                return writer;
            };
    
            /**
             * Encodes the specified FeeRatesRequest message, length delimited. Does not implicitly {@link protocol.FeeRatesRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.FeeRatesRequest
             * @static
             * @param {protocol.IFeeRatesRequest} message FeeRatesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FeeRatesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a FeeRatesRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.FeeRatesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.FeeRatesRequest} FeeRatesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FeeRatesRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.FeeRatesRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chain = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a FeeRatesRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.FeeRatesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.FeeRatesRequest} FeeRatesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FeeRatesRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a FeeRatesRequest message.
             * @function verify
             * @memberof protocol.FeeRatesRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FeeRatesRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chain != null && message.hasOwnProperty("chain"))
                    switch (message.chain) {
                    default:
                        return "chain: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a FeeRatesRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.FeeRatesRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.FeeRatesRequest} FeeRatesRequest
             */
            FeeRatesRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.FeeRatesRequest)
                    return object;
                var message = new $root.protocol.FeeRatesRequest();
                switch (object.chain) {
                case "UNK_CHAIN":
                case 0:
                    message.chain = 0;
                    break;
                case "BTC_CHAIN":
                case 1:
                    message.chain = 1;
                    break;
                case "ETH_CHAIN":
                case 2:
                    message.chain = 2;
                    break;
                case "TRX_CHAIN":
                case 3:
                    message.chain = 3;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a FeeRatesRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.FeeRatesRequest
             * @static
             * @param {protocol.FeeRatesRequest} message FeeRatesRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FeeRatesRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.chain = options.enums === String ? "UNK_CHAIN" : 0;
                if (message.chain != null && message.hasOwnProperty("chain"))
                    object.chain = options.enums === String ? $root.protocol.Chain[message.chain] : message.chain;
                return object;
            };
    
            /**
             * Converts this FeeRatesRequest to JSON.
             * @function toJSON
             * @memberof protocol.FeeRatesRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FeeRatesRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return FeeRatesRequest;
        })();
    
        protocol.FeeRatesResponse = (function() {
    
            /**
             * Properties of a FeeRatesResponse.
             * @memberof protocol
             * @interface IFeeRatesResponse
             * @property {Array.<protocol.FeeRatesResponse.IRate>|null} [rates] FeeRatesResponse rates
             */
    
            /**
             * Constructs a new FeeRatesResponse.
             * @memberof protocol
             * @classdesc Represents a FeeRatesResponse.
             * @implements IFeeRatesResponse
             * @constructor
             * @param {protocol.IFeeRatesResponse=} [properties] Properties to set
             */
            function FeeRatesResponse(properties) {
                this.rates = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * FeeRatesResponse rates.
             * @member {Array.<protocol.FeeRatesResponse.IRate>} rates
             * @memberof protocol.FeeRatesResponse
             * @instance
             */
            FeeRatesResponse.prototype.rates = $util.emptyArray;
    
            /**
             * Creates a new FeeRatesResponse instance using the specified properties.
             * @function create
             * @memberof protocol.FeeRatesResponse
             * @static
             * @param {protocol.IFeeRatesResponse=} [properties] Properties to set
             * @returns {protocol.FeeRatesResponse} FeeRatesResponse instance
             */
            FeeRatesResponse.create = function create(properties) {
                return new FeeRatesResponse(properties);
            };
    
            /**
             * Encodes the specified FeeRatesResponse message. Does not implicitly {@link protocol.FeeRatesResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.FeeRatesResponse
             * @static
             * @param {protocol.IFeeRatesResponse} message FeeRatesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FeeRatesResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.rates != null && message.rates.length)
                    for (var i = 0; i < message.rates.length; ++i)
                        $root.protocol.FeeRatesResponse.Rate.encode(message.rates[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified FeeRatesResponse message, length delimited. Does not implicitly {@link protocol.FeeRatesResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.FeeRatesResponse
             * @static
             * @param {protocol.IFeeRatesResponse} message FeeRatesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FeeRatesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a FeeRatesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.FeeRatesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.FeeRatesResponse} FeeRatesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FeeRatesResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.FeeRatesResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.rates && message.rates.length))
                            message.rates = [];
                        message.rates.push($root.protocol.FeeRatesResponse.Rate.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a FeeRatesResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.FeeRatesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.FeeRatesResponse} FeeRatesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FeeRatesResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a FeeRatesResponse message.
             * @function verify
             * @memberof protocol.FeeRatesResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FeeRatesResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.rates != null && message.hasOwnProperty("rates")) {
                    if (!Array.isArray(message.rates))
                        return "rates: array expected";
                    for (var i = 0; i < message.rates.length; ++i) {
                        var error = $root.protocol.FeeRatesResponse.Rate.verify(message.rates[i]);
                        if (error)
                            return "rates." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a FeeRatesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.FeeRatesResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.FeeRatesResponse} FeeRatesResponse
             */
            FeeRatesResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.FeeRatesResponse)
                    return object;
                var message = new $root.protocol.FeeRatesResponse();
                if (object.rates) {
                    if (!Array.isArray(object.rates))
                        throw TypeError(".protocol.FeeRatesResponse.rates: array expected");
                    message.rates = [];
                    for (var i = 0; i < object.rates.length; ++i) {
                        if (typeof object.rates[i] !== "object")
                            throw TypeError(".protocol.FeeRatesResponse.rates: object expected");
                        message.rates[i] = $root.protocol.FeeRatesResponse.Rate.fromObject(object.rates[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a FeeRatesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.FeeRatesResponse
             * @static
             * @param {protocol.FeeRatesResponse} message FeeRatesResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FeeRatesResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.rates = [];
                if (message.rates && message.rates.length) {
                    object.rates = [];
                    for (var j = 0; j < message.rates.length; ++j)
                        object.rates[j] = $root.protocol.FeeRatesResponse.Rate.toObject(message.rates[j], options);
                }
                return object;
            };
    
            /**
             * Converts this FeeRatesResponse to JSON.
             * @function toJSON
             * @memberof protocol.FeeRatesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FeeRatesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            FeeRatesResponse.Rate = (function() {
    
                /**
                 * Properties of a Rate.
                 * @memberof protocol.FeeRatesResponse
                 * @interface IRate
                 * @property {Long|null} [targetBlocks] Rate targetBlocks
                 * @property {string|null} [rate] Rate rate
                 */
    
                /**
                 * Constructs a new Rate.
                 * @memberof protocol.FeeRatesResponse
                 * @classdesc Represents a Rate.
                 * @implements IRate
                 * @constructor
                 * @param {protocol.FeeRatesResponse.IRate=} [properties] Properties to set
                 */
                function Rate(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Rate targetBlocks.
                 * @member {Long} targetBlocks
                 * @memberof protocol.FeeRatesResponse.Rate
                 * @instance
                 */
                Rate.prototype.targetBlocks = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Rate rate.
                 * @member {string} rate
                 * @memberof protocol.FeeRatesResponse.Rate
                 * @instance
                 */
                Rate.prototype.rate = "";
    
                /**
                 * Creates a new Rate instance using the specified properties.
                 * @function create
                 * @memberof protocol.FeeRatesResponse.Rate
                 * @static
                 * @param {protocol.FeeRatesResponse.IRate=} [properties] Properties to set
                 * @returns {protocol.FeeRatesResponse.Rate} Rate instance
                 */
                Rate.create = function create(properties) {
                    return new Rate(properties);
                };
    
                /**
                 * Encodes the specified Rate message. Does not implicitly {@link protocol.FeeRatesResponse.Rate.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.FeeRatesResponse.Rate
                 * @static
                 * @param {protocol.FeeRatesResponse.IRate} message Rate message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Rate.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.targetBlocks != null && Object.hasOwnProperty.call(message, "targetBlocks"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.targetBlocks);
                    if (message.rate != null && Object.hasOwnProperty.call(message, "rate"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.rate);
                    return writer;
                };
    
                /**
                 * Encodes the specified Rate message, length delimited. Does not implicitly {@link protocol.FeeRatesResponse.Rate.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.FeeRatesResponse.Rate
                 * @static
                 * @param {protocol.FeeRatesResponse.IRate} message Rate message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Rate.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Rate message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.FeeRatesResponse.Rate
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.FeeRatesResponse.Rate} Rate
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Rate.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.FeeRatesResponse.Rate();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.targetBlocks = reader.int64();
                            break;
                        case 2:
                            message.rate = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Rate message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.FeeRatesResponse.Rate
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.FeeRatesResponse.Rate} Rate
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Rate.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Rate message.
                 * @function verify
                 * @memberof protocol.FeeRatesResponse.Rate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Rate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.targetBlocks != null && message.hasOwnProperty("targetBlocks"))
                        if (!$util.isInteger(message.targetBlocks) && !(message.targetBlocks && $util.isInteger(message.targetBlocks.low) && $util.isInteger(message.targetBlocks.high)))
                            return "targetBlocks: integer|Long expected";
                    if (message.rate != null && message.hasOwnProperty("rate"))
                        if (!$util.isString(message.rate))
                            return "rate: string expected";
                    return null;
                };
    
                /**
                 * Creates a Rate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.FeeRatesResponse.Rate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.FeeRatesResponse.Rate} Rate
                 */
                Rate.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.FeeRatesResponse.Rate)
                        return object;
                    var message = new $root.protocol.FeeRatesResponse.Rate();
                    if (object.targetBlocks != null)
                        if ($util.Long)
                            (message.targetBlocks = $util.Long.fromValue(object.targetBlocks)).unsigned = false;
                        else if (typeof object.targetBlocks === "string")
                            message.targetBlocks = parseInt(object.targetBlocks, 10);
                        else if (typeof object.targetBlocks === "number")
                            message.targetBlocks = object.targetBlocks;
                        else if (typeof object.targetBlocks === "object")
                            message.targetBlocks = new $util.LongBits(object.targetBlocks.low >>> 0, object.targetBlocks.high >>> 0).toNumber();
                    if (object.rate != null)
                        message.rate = String(object.rate);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Rate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.FeeRatesResponse.Rate
                 * @static
                 * @param {protocol.FeeRatesResponse.Rate} message Rate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Rate.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.targetBlocks = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.targetBlocks = options.longs === String ? "0" : 0;
                        object.rate = "";
                    }
                    if (message.targetBlocks != null && message.hasOwnProperty("targetBlocks"))
                        if (typeof message.targetBlocks === "number")
                            object.targetBlocks = options.longs === String ? String(message.targetBlocks) : message.targetBlocks;
                        else
                            object.targetBlocks = options.longs === String ? $util.Long.prototype.toString.call(message.targetBlocks) : options.longs === Number ? new $util.LongBits(message.targetBlocks.low >>> 0, message.targetBlocks.high >>> 0).toNumber() : message.targetBlocks;
                    if (message.rate != null && message.hasOwnProperty("rate"))
                        object.rate = message.rate;
                    return object;
                };
    
                /**
                 * Converts this Rate to JSON.
                 * @function toJSON
                 * @memberof protocol.FeeRatesResponse.Rate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Rate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Rate;
            })();
    
            return FeeRatesResponse;
        })();
    
        protocol.TransactionFeeRequest = (function() {
    
            /**
             * Properties of a TransactionFeeRequest.
             * @memberof protocol
             * @interface ITransactionFeeRequest
             * @property {protocol.Currency|null} [currency] TransactionFeeRequest currency
             * @property {string|null} [address] TransactionFeeRequest address
             * @property {string|null} [amount] TransactionFeeRequest amount
             * @property {string|null} [feeRate] TransactionFeeRequest feeRate
             */
    
            /**
             * Constructs a new TransactionFeeRequest.
             * @memberof protocol
             * @classdesc Represents a TransactionFeeRequest.
             * @implements ITransactionFeeRequest
             * @constructor
             * @param {protocol.ITransactionFeeRequest=} [properties] Properties to set
             */
            function TransactionFeeRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TransactionFeeRequest currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.TransactionFeeRequest
             * @instance
             */
            TransactionFeeRequest.prototype.currency = 0;
    
            /**
             * TransactionFeeRequest address.
             * @member {string} address
             * @memberof protocol.TransactionFeeRequest
             * @instance
             */
            TransactionFeeRequest.prototype.address = "";
    
            /**
             * TransactionFeeRequest amount.
             * @member {string} amount
             * @memberof protocol.TransactionFeeRequest
             * @instance
             */
            TransactionFeeRequest.prototype.amount = "";
    
            /**
             * TransactionFeeRequest feeRate.
             * @member {string} feeRate
             * @memberof protocol.TransactionFeeRequest
             * @instance
             */
            TransactionFeeRequest.prototype.feeRate = "";
    
            /**
             * Creates a new TransactionFeeRequest instance using the specified properties.
             * @function create
             * @memberof protocol.TransactionFeeRequest
             * @static
             * @param {protocol.ITransactionFeeRequest=} [properties] Properties to set
             * @returns {protocol.TransactionFeeRequest} TransactionFeeRequest instance
             */
            TransactionFeeRequest.create = function create(properties) {
                return new TransactionFeeRequest(properties);
            };
    
            /**
             * Encodes the specified TransactionFeeRequest message. Does not implicitly {@link protocol.TransactionFeeRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.TransactionFeeRequest
             * @static
             * @param {protocol.ITransactionFeeRequest} message TransactionFeeRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionFeeRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currency);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.amount);
                if (message.feeRate != null && Object.hasOwnProperty.call(message, "feeRate"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.feeRate);
                return writer;
            };
    
            /**
             * Encodes the specified TransactionFeeRequest message, length delimited. Does not implicitly {@link protocol.TransactionFeeRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TransactionFeeRequest
             * @static
             * @param {protocol.ITransactionFeeRequest} message TransactionFeeRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionFeeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TransactionFeeRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TransactionFeeRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TransactionFeeRequest} TransactionFeeRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionFeeRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TransactionFeeRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.currency = reader.int32();
                        break;
                    case 2:
                        message.address = reader.string();
                        break;
                    case 3:
                        message.amount = reader.string();
                        break;
                    case 4:
                        message.feeRate = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TransactionFeeRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TransactionFeeRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TransactionFeeRequest} TransactionFeeRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionFeeRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TransactionFeeRequest message.
             * @function verify
             * @memberof protocol.TransactionFeeRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TransactionFeeRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (!$util.isString(message.amount))
                        return "amount: string expected";
                if (message.feeRate != null && message.hasOwnProperty("feeRate"))
                    if (!$util.isString(message.feeRate))
                        return "feeRate: string expected";
                return null;
            };
    
            /**
             * Creates a TransactionFeeRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TransactionFeeRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TransactionFeeRequest} TransactionFeeRequest
             */
            TransactionFeeRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TransactionFeeRequest)
                    return object;
                var message = new $root.protocol.TransactionFeeRequest();
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                if (object.address != null)
                    message.address = String(object.address);
                if (object.amount != null)
                    message.amount = String(object.amount);
                if (object.feeRate != null)
                    message.feeRate = String(object.feeRate);
                return message;
            };
    
            /**
             * Creates a plain object from a TransactionFeeRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TransactionFeeRequest
             * @static
             * @param {protocol.TransactionFeeRequest} message TransactionFeeRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransactionFeeRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.address = "";
                    object.amount = "";
                    object.feeRate = "";
                }
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = message.amount;
                if (message.feeRate != null && message.hasOwnProperty("feeRate"))
                    object.feeRate = message.feeRate;
                return object;
            };
    
            /**
             * Converts this TransactionFeeRequest to JSON.
             * @function toJSON
             * @memberof protocol.TransactionFeeRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransactionFeeRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TransactionFeeRequest;
        })();
    
        protocol.TransactionFeeResponse = (function() {
    
            /**
             * Properties of a TransactionFeeResponse.
             * @memberof protocol
             * @interface ITransactionFeeResponse
             * @property {string|null} [fee] TransactionFeeResponse fee
             * @property {string|null} [feeRate] TransactionFeeResponse feeRate
             * @property {string|null} [dust] TransactionFeeResponse dust
             */
    
            /**
             * Constructs a new TransactionFeeResponse.
             * @memberof protocol
             * @classdesc Represents a TransactionFeeResponse.
             * @implements ITransactionFeeResponse
             * @constructor
             * @param {protocol.ITransactionFeeResponse=} [properties] Properties to set
             */
            function TransactionFeeResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TransactionFeeResponse fee.
             * @member {string} fee
             * @memberof protocol.TransactionFeeResponse
             * @instance
             */
            TransactionFeeResponse.prototype.fee = "";
    
            /**
             * TransactionFeeResponse feeRate.
             * @member {string} feeRate
             * @memberof protocol.TransactionFeeResponse
             * @instance
             */
            TransactionFeeResponse.prototype.feeRate = "";
    
            /**
             * TransactionFeeResponse dust.
             * @member {string} dust
             * @memberof protocol.TransactionFeeResponse
             * @instance
             */
            TransactionFeeResponse.prototype.dust = "";
    
            /**
             * Creates a new TransactionFeeResponse instance using the specified properties.
             * @function create
             * @memberof protocol.TransactionFeeResponse
             * @static
             * @param {protocol.ITransactionFeeResponse=} [properties] Properties to set
             * @returns {protocol.TransactionFeeResponse} TransactionFeeResponse instance
             */
            TransactionFeeResponse.create = function create(properties) {
                return new TransactionFeeResponse(properties);
            };
    
            /**
             * Encodes the specified TransactionFeeResponse message. Does not implicitly {@link protocol.TransactionFeeResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.TransactionFeeResponse
             * @static
             * @param {protocol.ITransactionFeeResponse} message TransactionFeeResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionFeeResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.fee != null && Object.hasOwnProperty.call(message, "fee"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.fee);
                if (message.feeRate != null && Object.hasOwnProperty.call(message, "feeRate"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.feeRate);
                if (message.dust != null && Object.hasOwnProperty.call(message, "dust"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.dust);
                return writer;
            };
    
            /**
             * Encodes the specified TransactionFeeResponse message, length delimited. Does not implicitly {@link protocol.TransactionFeeResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TransactionFeeResponse
             * @static
             * @param {protocol.ITransactionFeeResponse} message TransactionFeeResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionFeeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TransactionFeeResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TransactionFeeResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TransactionFeeResponse} TransactionFeeResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionFeeResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TransactionFeeResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.fee = reader.string();
                        break;
                    case 2:
                        message.feeRate = reader.string();
                        break;
                    case 3:
                        message.dust = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TransactionFeeResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TransactionFeeResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TransactionFeeResponse} TransactionFeeResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionFeeResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TransactionFeeResponse message.
             * @function verify
             * @memberof protocol.TransactionFeeResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TransactionFeeResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.fee != null && message.hasOwnProperty("fee"))
                    if (!$util.isString(message.fee))
                        return "fee: string expected";
                if (message.feeRate != null && message.hasOwnProperty("feeRate"))
                    if (!$util.isString(message.feeRate))
                        return "feeRate: string expected";
                if (message.dust != null && message.hasOwnProperty("dust"))
                    if (!$util.isString(message.dust))
                        return "dust: string expected";
                return null;
            };
    
            /**
             * Creates a TransactionFeeResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TransactionFeeResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TransactionFeeResponse} TransactionFeeResponse
             */
            TransactionFeeResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TransactionFeeResponse)
                    return object;
                var message = new $root.protocol.TransactionFeeResponse();
                if (object.fee != null)
                    message.fee = String(object.fee);
                if (object.feeRate != null)
                    message.feeRate = String(object.feeRate);
                if (object.dust != null)
                    message.dust = String(object.dust);
                return message;
            };
    
            /**
             * Creates a plain object from a TransactionFeeResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TransactionFeeResponse
             * @static
             * @param {protocol.TransactionFeeResponse} message TransactionFeeResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransactionFeeResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.fee = "";
                    object.feeRate = "";
                    object.dust = "";
                }
                if (message.fee != null && message.hasOwnProperty("fee"))
                    object.fee = message.fee;
                if (message.feeRate != null && message.hasOwnProperty("feeRate"))
                    object.feeRate = message.feeRate;
                if (message.dust != null && message.hasOwnProperty("dust"))
                    object.dust = message.dust;
                return object;
            };
    
            /**
             * Converts this TransactionFeeResponse to JSON.
             * @function toJSON
             * @memberof protocol.TransactionFeeResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransactionFeeResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TransactionFeeResponse;
        })();
    
        /**
         * TxStatus enum.
         * @name protocol.TxStatus
         * @enum {number}
         * @property {number} UNK_TX_STATUS=0 UNK_TX_STATUS value
         * @property {number} PENDING_TX_STATUS=1 PENDING_TX_STATUS value
         * @property {number} CONFIRMED_TX_STATUS=2 CONFIRMED_TX_STATUS value
         */
        protocol.TxStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_TX_STATUS"] = 0;
            values[valuesById[1] = "PENDING_TX_STATUS"] = 1;
            values[valuesById[2] = "CONFIRMED_TX_STATUS"] = 2;
            return values;
        })();
    
        /**
         * TxDirection enum.
         * @name protocol.TxDirection
         * @enum {number}
         * @property {number} UNK_TX_DIR=0 UNK_TX_DIR value
         * @property {number} IN_TX_DIR=1 IN_TX_DIR value
         * @property {number} OUT_TX_DIR=2 OUT_TX_DIR value
         */
        protocol.TxDirection = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_TX_DIR"] = 0;
            values[valuesById[1] = "IN_TX_DIR"] = 1;
            values[valuesById[2] = "OUT_TX_DIR"] = 2;
            return values;
        })();
    
        /**
         * TxType enum.
         * @name protocol.TxType
         * @enum {number}
         * @property {number} UNK_TX_TYPE=0 UNK_TX_TYPE value
         * @property {number} REGULAR_TX_TYPE=1 REGULAR_TX_TYPE value
         * @property {number} DEPOSIT_APPROVE_FEE_TX_TYPE=2 DEPOSIT_APPROVE_FEE_TX_TYPE value
         * @property {number} APPROVE_TOKEN_TRANSFER_TX_TYPE=3 APPROVE_TOKEN_TRANSFER_TX_TYPE value
         */
        protocol.TxType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_TX_TYPE"] = 0;
            values[valuesById[1] = "REGULAR_TX_TYPE"] = 1;
            values[valuesById[2] = "DEPOSIT_APPROVE_FEE_TX_TYPE"] = 2;
            values[valuesById[3] = "APPROVE_TOKEN_TRANSFER_TX_TYPE"] = 3;
            return values;
        })();
    
        protocol.TransactionsRequest = (function() {
    
            /**
             * Properties of a TransactionsRequest.
             * @memberof protocol
             * @interface ITransactionsRequest
             * @property {string|null} [walletId] TransactionsRequest walletId
             * @property {string|null} [address] TransactionsRequest address
             * @property {number|null} [txId] TransactionsRequest txId
             * @property {string|null} [txHash] TransactionsRequest txHash
             * @property {string|null} [clientId] TransactionsRequest clientId
             * @property {protocol.Chain|null} [chain] TransactionsRequest chain
             * @property {protocol.Currency|null} [currency] TransactionsRequest currency
             * @property {protocol.TxStatus|null} [status] TransactionsRequest status
             * @property {protocol.TxDirection|null} [direction] TransactionsRequest direction
             * @property {protocol.TxType|null} [type] TransactionsRequest type
             * @property {Long|null} [lastSeenId] TransactionsRequest lastSeenId
             */
    
            /**
             * Constructs a new TransactionsRequest.
             * @memberof protocol
             * @classdesc Represents a TransactionsRequest.
             * @implements ITransactionsRequest
             * @constructor
             * @param {protocol.ITransactionsRequest=} [properties] Properties to set
             */
            function TransactionsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TransactionsRequest walletId.
             * @member {string} walletId
             * @memberof protocol.TransactionsRequest
             * @instance
             */
            TransactionsRequest.prototype.walletId = "";
    
            /**
             * TransactionsRequest address.
             * @member {string} address
             * @memberof protocol.TransactionsRequest
             * @instance
             */
            TransactionsRequest.prototype.address = "";
    
            /**
             * TransactionsRequest txId.
             * @member {number} txId
             * @memberof protocol.TransactionsRequest
             * @instance
             */
            TransactionsRequest.prototype.txId = 0;
    
            /**
             * TransactionsRequest txHash.
             * @member {string} txHash
             * @memberof protocol.TransactionsRequest
             * @instance
             */
            TransactionsRequest.prototype.txHash = "";
    
            /**
             * TransactionsRequest clientId.
             * @member {string} clientId
             * @memberof protocol.TransactionsRequest
             * @instance
             */
            TransactionsRequest.prototype.clientId = "";
    
            /**
             * TransactionsRequest chain.
             * @member {protocol.Chain} chain
             * @memberof protocol.TransactionsRequest
             * @instance
             */
            TransactionsRequest.prototype.chain = 0;
    
            /**
             * TransactionsRequest currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.TransactionsRequest
             * @instance
             */
            TransactionsRequest.prototype.currency = 0;
    
            /**
             * TransactionsRequest status.
             * @member {protocol.TxStatus} status
             * @memberof protocol.TransactionsRequest
             * @instance
             */
            TransactionsRequest.prototype.status = 0;
    
            /**
             * TransactionsRequest direction.
             * @member {protocol.TxDirection} direction
             * @memberof protocol.TransactionsRequest
             * @instance
             */
            TransactionsRequest.prototype.direction = 0;
    
            /**
             * TransactionsRequest type.
             * @member {protocol.TxType} type
             * @memberof protocol.TransactionsRequest
             * @instance
             */
            TransactionsRequest.prototype.type = 0;
    
            /**
             * TransactionsRequest lastSeenId.
             * @member {Long} lastSeenId
             * @memberof protocol.TransactionsRequest
             * @instance
             */
            TransactionsRequest.prototype.lastSeenId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * TransactionsRequest entity.
             * @member {"walletId"|"address"|"txId"|"txHash"|"clientId"|undefined} entity
             * @memberof protocol.TransactionsRequest
             * @instance
             */
            Object.defineProperty(TransactionsRequest.prototype, "entity", {
                get: $util.oneOfGetter($oneOfFields = ["walletId", "address", "txId", "txHash", "clientId"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new TransactionsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.TransactionsRequest
             * @static
             * @param {protocol.ITransactionsRequest=} [properties] Properties to set
             * @returns {protocol.TransactionsRequest} TransactionsRequest instance
             */
            TransactionsRequest.create = function create(properties) {
                return new TransactionsRequest(properties);
            };
    
            /**
             * Encodes the specified TransactionsRequest message. Does not implicitly {@link protocol.TransactionsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.TransactionsRequest
             * @static
             * @param {protocol.ITransactionsRequest} message TransactionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.walletId != null && Object.hasOwnProperty.call(message, "walletId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.walletId);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
                if (message.txId != null && Object.hasOwnProperty.call(message, "txId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.txId);
                if (message.txHash != null && Object.hasOwnProperty.call(message, "txHash"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.txHash);
                if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.clientId);
                if (message.chain != null && Object.hasOwnProperty.call(message, "chain"))
                    writer.uint32(/* id 20, wireType 0 =*/160).int32(message.chain);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 21, wireType 0 =*/168).int32(message.currency);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 22, wireType 0 =*/176).int32(message.status);
                if (message.direction != null && Object.hasOwnProperty.call(message, "direction"))
                    writer.uint32(/* id 23, wireType 0 =*/184).int32(message.direction);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 24, wireType 0 =*/192).int32(message.type);
                if (message.lastSeenId != null && Object.hasOwnProperty.call(message, "lastSeenId"))
                    writer.uint32(/* id 25, wireType 0 =*/200).int64(message.lastSeenId);
                return writer;
            };
    
            /**
             * Encodes the specified TransactionsRequest message, length delimited. Does not implicitly {@link protocol.TransactionsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TransactionsRequest
             * @static
             * @param {protocol.ITransactionsRequest} message TransactionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TransactionsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TransactionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TransactionsRequest} TransactionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TransactionsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.walletId = reader.string();
                        break;
                    case 2:
                        message.address = reader.string();
                        break;
                    case 3:
                        message.txId = reader.uint32();
                        break;
                    case 4:
                        message.txHash = reader.string();
                        break;
                    case 5:
                        message.clientId = reader.string();
                        break;
                    case 20:
                        message.chain = reader.int32();
                        break;
                    case 21:
                        message.currency = reader.int32();
                        break;
                    case 22:
                        message.status = reader.int32();
                        break;
                    case 23:
                        message.direction = reader.int32();
                        break;
                    case 24:
                        message.type = reader.int32();
                        break;
                    case 25:
                        message.lastSeenId = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TransactionsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TransactionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TransactionsRequest} TransactionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TransactionsRequest message.
             * @function verify
             * @memberof protocol.TransactionsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TransactionsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.walletId != null && message.hasOwnProperty("walletId")) {
                    properties.entity = 1;
                    if (!$util.isString(message.walletId))
                        return "walletId: string expected";
                }
                if (message.address != null && message.hasOwnProperty("address")) {
                    if (properties.entity === 1)
                        return "entity: multiple values";
                    properties.entity = 1;
                    if (!$util.isString(message.address))
                        return "address: string expected";
                }
                if (message.txId != null && message.hasOwnProperty("txId")) {
                    if (properties.entity === 1)
                        return "entity: multiple values";
                    properties.entity = 1;
                    if (!$util.isInteger(message.txId))
                        return "txId: integer expected";
                }
                if (message.txHash != null && message.hasOwnProperty("txHash")) {
                    if (properties.entity === 1)
                        return "entity: multiple values";
                    properties.entity = 1;
                    if (!$util.isString(message.txHash))
                        return "txHash: string expected";
                }
                if (message.clientId != null && message.hasOwnProperty("clientId")) {
                    if (properties.entity === 1)
                        return "entity: multiple values";
                    properties.entity = 1;
                    if (!$util.isString(message.clientId))
                        return "clientId: string expected";
                }
                if (message.chain != null && message.hasOwnProperty("chain"))
                    switch (message.chain) {
                    default:
                        return "chain: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.direction != null && message.hasOwnProperty("direction"))
                    switch (message.direction) {
                    default:
                        return "direction: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.lastSeenId != null && message.hasOwnProperty("lastSeenId"))
                    if (!$util.isInteger(message.lastSeenId) && !(message.lastSeenId && $util.isInteger(message.lastSeenId.low) && $util.isInteger(message.lastSeenId.high)))
                        return "lastSeenId: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a TransactionsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TransactionsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TransactionsRequest} TransactionsRequest
             */
            TransactionsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TransactionsRequest)
                    return object;
                var message = new $root.protocol.TransactionsRequest();
                if (object.walletId != null)
                    message.walletId = String(object.walletId);
                if (object.address != null)
                    message.address = String(object.address);
                if (object.txId != null)
                    message.txId = object.txId >>> 0;
                if (object.txHash != null)
                    message.txHash = String(object.txHash);
                if (object.clientId != null)
                    message.clientId = String(object.clientId);
                switch (object.chain) {
                case "UNK_CHAIN":
                case 0:
                    message.chain = 0;
                    break;
                case "BTC_CHAIN":
                case 1:
                    message.chain = 1;
                    break;
                case "ETH_CHAIN":
                case 2:
                    message.chain = 2;
                    break;
                case "TRX_CHAIN":
                case 3:
                    message.chain = 3;
                    break;
                }
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                switch (object.status) {
                case "UNK_TX_STATUS":
                case 0:
                    message.status = 0;
                    break;
                case "PENDING_TX_STATUS":
                case 1:
                    message.status = 1;
                    break;
                case "CONFIRMED_TX_STATUS":
                case 2:
                    message.status = 2;
                    break;
                }
                switch (object.direction) {
                case "UNK_TX_DIR":
                case 0:
                    message.direction = 0;
                    break;
                case "IN_TX_DIR":
                case 1:
                    message.direction = 1;
                    break;
                case "OUT_TX_DIR":
                case 2:
                    message.direction = 2;
                    break;
                }
                switch (object.type) {
                case "UNK_TX_TYPE":
                case 0:
                    message.type = 0;
                    break;
                case "REGULAR_TX_TYPE":
                case 1:
                    message.type = 1;
                    break;
                case "DEPOSIT_APPROVE_FEE_TX_TYPE":
                case 2:
                    message.type = 2;
                    break;
                case "APPROVE_TOKEN_TRANSFER_TX_TYPE":
                case 3:
                    message.type = 3;
                    break;
                }
                if (object.lastSeenId != null)
                    if ($util.Long)
                        (message.lastSeenId = $util.Long.fromValue(object.lastSeenId)).unsigned = false;
                    else if (typeof object.lastSeenId === "string")
                        message.lastSeenId = parseInt(object.lastSeenId, 10);
                    else if (typeof object.lastSeenId === "number")
                        message.lastSeenId = object.lastSeenId;
                    else if (typeof object.lastSeenId === "object")
                        message.lastSeenId = new $util.LongBits(object.lastSeenId.low >>> 0, object.lastSeenId.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a TransactionsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TransactionsRequest
             * @static
             * @param {protocol.TransactionsRequest} message TransactionsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransactionsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.chain = options.enums === String ? "UNK_CHAIN" : 0;
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.status = options.enums === String ? "UNK_TX_STATUS" : 0;
                    object.direction = options.enums === String ? "UNK_TX_DIR" : 0;
                    object.type = options.enums === String ? "UNK_TX_TYPE" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.lastSeenId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lastSeenId = options.longs === String ? "0" : 0;
                }
                if (message.walletId != null && message.hasOwnProperty("walletId")) {
                    object.walletId = message.walletId;
                    if (options.oneofs)
                        object.entity = "walletId";
                }
                if (message.address != null && message.hasOwnProperty("address")) {
                    object.address = message.address;
                    if (options.oneofs)
                        object.entity = "address";
                }
                if (message.txId != null && message.hasOwnProperty("txId")) {
                    object.txId = message.txId;
                    if (options.oneofs)
                        object.entity = "txId";
                }
                if (message.txHash != null && message.hasOwnProperty("txHash")) {
                    object.txHash = message.txHash;
                    if (options.oneofs)
                        object.entity = "txHash";
                }
                if (message.clientId != null && message.hasOwnProperty("clientId")) {
                    object.clientId = message.clientId;
                    if (options.oneofs)
                        object.entity = "clientId";
                }
                if (message.chain != null && message.hasOwnProperty("chain"))
                    object.chain = options.enums === String ? $root.protocol.Chain[message.chain] : message.chain;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.protocol.TxStatus[message.status] : message.status;
                if (message.direction != null && message.hasOwnProperty("direction"))
                    object.direction = options.enums === String ? $root.protocol.TxDirection[message.direction] : message.direction;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.protocol.TxType[message.type] : message.type;
                if (message.lastSeenId != null && message.hasOwnProperty("lastSeenId"))
                    if (typeof message.lastSeenId === "number")
                        object.lastSeenId = options.longs === String ? String(message.lastSeenId) : message.lastSeenId;
                    else
                        object.lastSeenId = options.longs === String ? $util.Long.prototype.toString.call(message.lastSeenId) : options.longs === Number ? new $util.LongBits(message.lastSeenId.low >>> 0, message.lastSeenId.high >>> 0).toNumber() : message.lastSeenId;
                return object;
            };
    
            /**
             * Converts this TransactionsRequest to JSON.
             * @function toJSON
             * @memberof protocol.TransactionsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransactionsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TransactionsRequest;
        })();
    
        protocol.TransactionsResponse = (function() {
    
            /**
             * Properties of a TransactionsResponse.
             * @memberof protocol
             * @interface ITransactionsResponse
             * @property {Array.<protocol.ITxRecord>|null} [transactions] TransactionsResponse transactions
             */
    
            /**
             * Constructs a new TransactionsResponse.
             * @memberof protocol
             * @classdesc Represents a TransactionsResponse.
             * @implements ITransactionsResponse
             * @constructor
             * @param {protocol.ITransactionsResponse=} [properties] Properties to set
             */
            function TransactionsResponse(properties) {
                this.transactions = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TransactionsResponse transactions.
             * @member {Array.<protocol.ITxRecord>} transactions
             * @memberof protocol.TransactionsResponse
             * @instance
             */
            TransactionsResponse.prototype.transactions = $util.emptyArray;
    
            /**
             * Creates a new TransactionsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.TransactionsResponse
             * @static
             * @param {protocol.ITransactionsResponse=} [properties] Properties to set
             * @returns {protocol.TransactionsResponse} TransactionsResponse instance
             */
            TransactionsResponse.create = function create(properties) {
                return new TransactionsResponse(properties);
            };
    
            /**
             * Encodes the specified TransactionsResponse message. Does not implicitly {@link protocol.TransactionsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.TransactionsResponse
             * @static
             * @param {protocol.ITransactionsResponse} message TransactionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.transactions != null && message.transactions.length)
                    for (var i = 0; i < message.transactions.length; ++i)
                        $root.protocol.TxRecord.encode(message.transactions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TransactionsResponse message, length delimited. Does not implicitly {@link protocol.TransactionsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TransactionsResponse
             * @static
             * @param {protocol.ITransactionsResponse} message TransactionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TransactionsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TransactionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TransactionsResponse} TransactionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TransactionsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.transactions && message.transactions.length))
                            message.transactions = [];
                        message.transactions.push($root.protocol.TxRecord.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TransactionsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TransactionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TransactionsResponse} TransactionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TransactionsResponse message.
             * @function verify
             * @memberof protocol.TransactionsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TransactionsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.transactions != null && message.hasOwnProperty("transactions")) {
                    if (!Array.isArray(message.transactions))
                        return "transactions: array expected";
                    for (var i = 0; i < message.transactions.length; ++i) {
                        var error = $root.protocol.TxRecord.verify(message.transactions[i]);
                        if (error)
                            return "transactions." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a TransactionsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TransactionsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TransactionsResponse} TransactionsResponse
             */
            TransactionsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TransactionsResponse)
                    return object;
                var message = new $root.protocol.TransactionsResponse();
                if (object.transactions) {
                    if (!Array.isArray(object.transactions))
                        throw TypeError(".protocol.TransactionsResponse.transactions: array expected");
                    message.transactions = [];
                    for (var i = 0; i < object.transactions.length; ++i) {
                        if (typeof object.transactions[i] !== "object")
                            throw TypeError(".protocol.TransactionsResponse.transactions: object expected");
                        message.transactions[i] = $root.protocol.TxRecord.fromObject(object.transactions[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TransactionsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TransactionsResponse
             * @static
             * @param {protocol.TransactionsResponse} message TransactionsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransactionsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.transactions = [];
                if (message.transactions && message.transactions.length) {
                    object.transactions = [];
                    for (var j = 0; j < message.transactions.length; ++j)
                        object.transactions[j] = $root.protocol.TxRecord.toObject(message.transactions[j], options);
                }
                return object;
            };
    
            /**
             * Converts this TransactionsResponse to JSON.
             * @function toJSON
             * @memberof protocol.TransactionsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransactionsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TransactionsResponse;
        })();
    
        protocol.TransactionsPagedRequest = (function() {
    
            /**
             * Properties of a TransactionsPagedRequest.
             * @memberof protocol
             * @interface ITransactionsPagedRequest
             * @property {string|null} [walletId] TransactionsPagedRequest walletId
             * @property {string|null} [address] TransactionsPagedRequest address
             * @property {number|null} [txId] TransactionsPagedRequest txId
             * @property {string|null} [txHash] TransactionsPagedRequest txHash
             * @property {string|null} [clientId] TransactionsPagedRequest clientId
             * @property {protocol.Chain|null} [chain] TransactionsPagedRequest chain
             * @property {protocol.Currency|null} [currency] TransactionsPagedRequest currency
             * @property {protocol.TxStatus|null} [status] TransactionsPagedRequest status
             * @property {protocol.TxDirection|null} [direction] TransactionsPagedRequest direction
             * @property {protocol.TxType|null} [type] TransactionsPagedRequest type
             * @property {Long|null} [limit] TransactionsPagedRequest limit
             * @property {Long|null} [offset] TransactionsPagedRequest offset
             * @property {protocol.SortBy|null} [sortBy] TransactionsPagedRequest sortBy
             * @property {boolean|null} [sortDesc] TransactionsPagedRequest sortDesc
             * @property {Long|null} [updatedAtFrom] TransactionsPagedRequest updatedAtFrom
             * @property {Long|null} [updatedAtTo] TransactionsPagedRequest updatedAtTo
             */
    
            /**
             * Constructs a new TransactionsPagedRequest.
             * @memberof protocol
             * @classdesc Represents a TransactionsPagedRequest.
             * @implements ITransactionsPagedRequest
             * @constructor
             * @param {protocol.ITransactionsPagedRequest=} [properties] Properties to set
             */
            function TransactionsPagedRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TransactionsPagedRequest walletId.
             * @member {string} walletId
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             */
            TransactionsPagedRequest.prototype.walletId = "";
    
            /**
             * TransactionsPagedRequest address.
             * @member {string} address
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             */
            TransactionsPagedRequest.prototype.address = "";
    
            /**
             * TransactionsPagedRequest txId.
             * @member {number} txId
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             */
            TransactionsPagedRequest.prototype.txId = 0;
    
            /**
             * TransactionsPagedRequest txHash.
             * @member {string} txHash
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             */
            TransactionsPagedRequest.prototype.txHash = "";
    
            /**
             * TransactionsPagedRequest clientId.
             * @member {string} clientId
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             */
            TransactionsPagedRequest.prototype.clientId = "";
    
            /**
             * TransactionsPagedRequest chain.
             * @member {protocol.Chain} chain
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             */
            TransactionsPagedRequest.prototype.chain = 0;
    
            /**
             * TransactionsPagedRequest currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             */
            TransactionsPagedRequest.prototype.currency = 0;
    
            /**
             * TransactionsPagedRequest status.
             * @member {protocol.TxStatus} status
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             */
            TransactionsPagedRequest.prototype.status = 0;
    
            /**
             * TransactionsPagedRequest direction.
             * @member {protocol.TxDirection} direction
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             */
            TransactionsPagedRequest.prototype.direction = 0;
    
            /**
             * TransactionsPagedRequest type.
             * @member {protocol.TxType} type
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             */
            TransactionsPagedRequest.prototype.type = 0;
    
            /**
             * TransactionsPagedRequest limit.
             * @member {Long} limit
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             */
            TransactionsPagedRequest.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TransactionsPagedRequest offset.
             * @member {Long} offset
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             */
            TransactionsPagedRequest.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TransactionsPagedRequest sortBy.
             * @member {protocol.SortBy} sortBy
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             */
            TransactionsPagedRequest.prototype.sortBy = 0;
    
            /**
             * TransactionsPagedRequest sortDesc.
             * @member {boolean} sortDesc
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             */
            TransactionsPagedRequest.prototype.sortDesc = false;
    
            /**
             * TransactionsPagedRequest updatedAtFrom.
             * @member {Long} updatedAtFrom
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             */
            TransactionsPagedRequest.prototype.updatedAtFrom = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TransactionsPagedRequest updatedAtTo.
             * @member {Long} updatedAtTo
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             */
            TransactionsPagedRequest.prototype.updatedAtTo = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * TransactionsPagedRequest entity.
             * @member {"walletId"|"address"|"txId"|"txHash"|"clientId"|undefined} entity
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             */
            Object.defineProperty(TransactionsPagedRequest.prototype, "entity", {
                get: $util.oneOfGetter($oneOfFields = ["walletId", "address", "txId", "txHash", "clientId"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new TransactionsPagedRequest instance using the specified properties.
             * @function create
             * @memberof protocol.TransactionsPagedRequest
             * @static
             * @param {protocol.ITransactionsPagedRequest=} [properties] Properties to set
             * @returns {protocol.TransactionsPagedRequest} TransactionsPagedRequest instance
             */
            TransactionsPagedRequest.create = function create(properties) {
                return new TransactionsPagedRequest(properties);
            };
    
            /**
             * Encodes the specified TransactionsPagedRequest message. Does not implicitly {@link protocol.TransactionsPagedRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.TransactionsPagedRequest
             * @static
             * @param {protocol.ITransactionsPagedRequest} message TransactionsPagedRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionsPagedRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.walletId != null && Object.hasOwnProperty.call(message, "walletId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.walletId);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
                if (message.txId != null && Object.hasOwnProperty.call(message, "txId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.txId);
                if (message.txHash != null && Object.hasOwnProperty.call(message, "txHash"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.txHash);
                if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.clientId);
                if (message.chain != null && Object.hasOwnProperty.call(message, "chain"))
                    writer.uint32(/* id 20, wireType 0 =*/160).int32(message.chain);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 21, wireType 0 =*/168).int32(message.currency);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 22, wireType 0 =*/176).int32(message.status);
                if (message.direction != null && Object.hasOwnProperty.call(message, "direction"))
                    writer.uint32(/* id 23, wireType 0 =*/184).int32(message.direction);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 24, wireType 0 =*/192).int32(message.type);
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 25, wireType 0 =*/200).int64(message.limit);
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 26, wireType 0 =*/208).int64(message.offset);
                if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                    writer.uint32(/* id 27, wireType 0 =*/216).int32(message.sortBy);
                if (message.sortDesc != null && Object.hasOwnProperty.call(message, "sortDesc"))
                    writer.uint32(/* id 28, wireType 0 =*/224).bool(message.sortDesc);
                if (message.updatedAtFrom != null && Object.hasOwnProperty.call(message, "updatedAtFrom"))
                    writer.uint32(/* id 29, wireType 0 =*/232).int64(message.updatedAtFrom);
                if (message.updatedAtTo != null && Object.hasOwnProperty.call(message, "updatedAtTo"))
                    writer.uint32(/* id 30, wireType 0 =*/240).int64(message.updatedAtTo);
                return writer;
            };
    
            /**
             * Encodes the specified TransactionsPagedRequest message, length delimited. Does not implicitly {@link protocol.TransactionsPagedRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TransactionsPagedRequest
             * @static
             * @param {protocol.ITransactionsPagedRequest} message TransactionsPagedRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionsPagedRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TransactionsPagedRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TransactionsPagedRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TransactionsPagedRequest} TransactionsPagedRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionsPagedRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TransactionsPagedRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.walletId = reader.string();
                        break;
                    case 2:
                        message.address = reader.string();
                        break;
                    case 3:
                        message.txId = reader.uint32();
                        break;
                    case 4:
                        message.txHash = reader.string();
                        break;
                    case 5:
                        message.clientId = reader.string();
                        break;
                    case 20:
                        message.chain = reader.int32();
                        break;
                    case 21:
                        message.currency = reader.int32();
                        break;
                    case 22:
                        message.status = reader.int32();
                        break;
                    case 23:
                        message.direction = reader.int32();
                        break;
                    case 24:
                        message.type = reader.int32();
                        break;
                    case 25:
                        message.limit = reader.int64();
                        break;
                    case 26:
                        message.offset = reader.int64();
                        break;
                    case 27:
                        message.sortBy = reader.int32();
                        break;
                    case 28:
                        message.sortDesc = reader.bool();
                        break;
                    case 29:
                        message.updatedAtFrom = reader.int64();
                        break;
                    case 30:
                        message.updatedAtTo = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TransactionsPagedRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TransactionsPagedRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TransactionsPagedRequest} TransactionsPagedRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionsPagedRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TransactionsPagedRequest message.
             * @function verify
             * @memberof protocol.TransactionsPagedRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TransactionsPagedRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.walletId != null && message.hasOwnProperty("walletId")) {
                    properties.entity = 1;
                    if (!$util.isString(message.walletId))
                        return "walletId: string expected";
                }
                if (message.address != null && message.hasOwnProperty("address")) {
                    if (properties.entity === 1)
                        return "entity: multiple values";
                    properties.entity = 1;
                    if (!$util.isString(message.address))
                        return "address: string expected";
                }
                if (message.txId != null && message.hasOwnProperty("txId")) {
                    if (properties.entity === 1)
                        return "entity: multiple values";
                    properties.entity = 1;
                    if (!$util.isInteger(message.txId))
                        return "txId: integer expected";
                }
                if (message.txHash != null && message.hasOwnProperty("txHash")) {
                    if (properties.entity === 1)
                        return "entity: multiple values";
                    properties.entity = 1;
                    if (!$util.isString(message.txHash))
                        return "txHash: string expected";
                }
                if (message.clientId != null && message.hasOwnProperty("clientId")) {
                    if (properties.entity === 1)
                        return "entity: multiple values";
                    properties.entity = 1;
                    if (!$util.isString(message.clientId))
                        return "clientId: string expected";
                }
                if (message.chain != null && message.hasOwnProperty("chain"))
                    switch (message.chain) {
                    default:
                        return "chain: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.direction != null && message.hasOwnProperty("direction"))
                    switch (message.direction) {
                    default:
                        return "direction: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                        return "limit: integer|Long expected";
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset) && !(message.offset && $util.isInteger(message.offset.low) && $util.isInteger(message.offset.high)))
                        return "offset: integer|Long expected";
                if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                    switch (message.sortBy) {
                    default:
                        return "sortBy: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                        break;
                    }
                if (message.sortDesc != null && message.hasOwnProperty("sortDesc"))
                    if (typeof message.sortDesc !== "boolean")
                        return "sortDesc: boolean expected";
                if (message.updatedAtFrom != null && message.hasOwnProperty("updatedAtFrom"))
                    if (!$util.isInteger(message.updatedAtFrom) && !(message.updatedAtFrom && $util.isInteger(message.updatedAtFrom.low) && $util.isInteger(message.updatedAtFrom.high)))
                        return "updatedAtFrom: integer|Long expected";
                if (message.updatedAtTo != null && message.hasOwnProperty("updatedAtTo"))
                    if (!$util.isInteger(message.updatedAtTo) && !(message.updatedAtTo && $util.isInteger(message.updatedAtTo.low) && $util.isInteger(message.updatedAtTo.high)))
                        return "updatedAtTo: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a TransactionsPagedRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TransactionsPagedRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TransactionsPagedRequest} TransactionsPagedRequest
             */
            TransactionsPagedRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TransactionsPagedRequest)
                    return object;
                var message = new $root.protocol.TransactionsPagedRequest();
                if (object.walletId != null)
                    message.walletId = String(object.walletId);
                if (object.address != null)
                    message.address = String(object.address);
                if (object.txId != null)
                    message.txId = object.txId >>> 0;
                if (object.txHash != null)
                    message.txHash = String(object.txHash);
                if (object.clientId != null)
                    message.clientId = String(object.clientId);
                switch (object.chain) {
                case "UNK_CHAIN":
                case 0:
                    message.chain = 0;
                    break;
                case "BTC_CHAIN":
                case 1:
                    message.chain = 1;
                    break;
                case "ETH_CHAIN":
                case 2:
                    message.chain = 2;
                    break;
                case "TRX_CHAIN":
                case 3:
                    message.chain = 3;
                    break;
                }
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                switch (object.status) {
                case "UNK_TX_STATUS":
                case 0:
                    message.status = 0;
                    break;
                case "PENDING_TX_STATUS":
                case 1:
                    message.status = 1;
                    break;
                case "CONFIRMED_TX_STATUS":
                case 2:
                    message.status = 2;
                    break;
                }
                switch (object.direction) {
                case "UNK_TX_DIR":
                case 0:
                    message.direction = 0;
                    break;
                case "IN_TX_DIR":
                case 1:
                    message.direction = 1;
                    break;
                case "OUT_TX_DIR":
                case 2:
                    message.direction = 2;
                    break;
                }
                switch (object.type) {
                case "UNK_TX_TYPE":
                case 0:
                    message.type = 0;
                    break;
                case "REGULAR_TX_TYPE":
                case 1:
                    message.type = 1;
                    break;
                case "DEPOSIT_APPROVE_FEE_TX_TYPE":
                case 2:
                    message.type = 2;
                    break;
                case "APPROVE_TOKEN_TRANSFER_TX_TYPE":
                case 3:
                    message.type = 3;
                    break;
                }
                if (object.limit != null)
                    if ($util.Long)
                        (message.limit = $util.Long.fromValue(object.limit)).unsigned = false;
                    else if (typeof object.limit === "string")
                        message.limit = parseInt(object.limit, 10);
                    else if (typeof object.limit === "number")
                        message.limit = object.limit;
                    else if (typeof object.limit === "object")
                        message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber();
                if (object.offset != null)
                    if ($util.Long)
                        (message.offset = $util.Long.fromValue(object.offset)).unsigned = false;
                    else if (typeof object.offset === "string")
                        message.offset = parseInt(object.offset, 10);
                    else if (typeof object.offset === "number")
                        message.offset = object.offset;
                    else if (typeof object.offset === "object")
                        message.offset = new $util.LongBits(object.offset.low >>> 0, object.offset.high >>> 0).toNumber();
                switch (object.sortBy) {
                case "UNK_SORT_BY":
                case 0:
                    message.sortBy = 0;
                    break;
                case "ID_SORT_BY":
                case 1:
                    message.sortBy = 1;
                    break;
                case "DATE_SORT_BY":
                case 2:
                    message.sortBy = 2;
                    break;
                case "CURRENCY_SORT_BY":
                case 3:
                    message.sortBy = 3;
                    break;
                case "TAGS_SORT_BY":
                case 4:
                    message.sortBy = 4;
                    break;
                case "TX_HASH_SORT_BY":
                case 5:
                    message.sortBy = 5;
                    break;
                case "AMOUNT_SORT_BY":
                case 6:
                    message.sortBy = 6;
                    break;
                case "NAME_SORT_BY":
                case 7:
                    message.sortBy = 7;
                    break;
                case "LAST_AMOUNT_SORT_BY":
                case 8:
                    message.sortBy = 8;
                    break;
                case "STATUS_SORT_BY":
                case 9:
                    message.sortBy = 9;
                    break;
                case "ENTITIES_COUNT_SORT_BY":
                case 10:
                    message.sortBy = 10;
                    break;
                case "ADDRESS_SORT_BY":
                case 11:
                    message.sortBy = 11;
                    break;
                case "RISK_SCORE_SORT_BY":
                case 12:
                    message.sortBy = 12;
                    break;
                case "BALANCE_SORT_BY":
                case 13:
                    message.sortBy = 13;
                    break;
                }
                if (object.sortDesc != null)
                    message.sortDesc = Boolean(object.sortDesc);
                if (object.updatedAtFrom != null)
                    if ($util.Long)
                        (message.updatedAtFrom = $util.Long.fromValue(object.updatedAtFrom)).unsigned = false;
                    else if (typeof object.updatedAtFrom === "string")
                        message.updatedAtFrom = parseInt(object.updatedAtFrom, 10);
                    else if (typeof object.updatedAtFrom === "number")
                        message.updatedAtFrom = object.updatedAtFrom;
                    else if (typeof object.updatedAtFrom === "object")
                        message.updatedAtFrom = new $util.LongBits(object.updatedAtFrom.low >>> 0, object.updatedAtFrom.high >>> 0).toNumber();
                if (object.updatedAtTo != null)
                    if ($util.Long)
                        (message.updatedAtTo = $util.Long.fromValue(object.updatedAtTo)).unsigned = false;
                    else if (typeof object.updatedAtTo === "string")
                        message.updatedAtTo = parseInt(object.updatedAtTo, 10);
                    else if (typeof object.updatedAtTo === "number")
                        message.updatedAtTo = object.updatedAtTo;
                    else if (typeof object.updatedAtTo === "object")
                        message.updatedAtTo = new $util.LongBits(object.updatedAtTo.low >>> 0, object.updatedAtTo.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a TransactionsPagedRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TransactionsPagedRequest
             * @static
             * @param {protocol.TransactionsPagedRequest} message TransactionsPagedRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransactionsPagedRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.chain = options.enums === String ? "UNK_CHAIN" : 0;
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.status = options.enums === String ? "UNK_TX_STATUS" : 0;
                    object.direction = options.enums === String ? "UNK_TX_DIR" : 0;
                    object.type = options.enums === String ? "UNK_TX_TYPE" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.limit = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.offset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.offset = options.longs === String ? "0" : 0;
                    object.sortBy = options.enums === String ? "UNK_SORT_BY" : 0;
                    object.sortDesc = false;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.updatedAtFrom = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updatedAtFrom = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.updatedAtTo = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updatedAtTo = options.longs === String ? "0" : 0;
                }
                if (message.walletId != null && message.hasOwnProperty("walletId")) {
                    object.walletId = message.walletId;
                    if (options.oneofs)
                        object.entity = "walletId";
                }
                if (message.address != null && message.hasOwnProperty("address")) {
                    object.address = message.address;
                    if (options.oneofs)
                        object.entity = "address";
                }
                if (message.txId != null && message.hasOwnProperty("txId")) {
                    object.txId = message.txId;
                    if (options.oneofs)
                        object.entity = "txId";
                }
                if (message.txHash != null && message.hasOwnProperty("txHash")) {
                    object.txHash = message.txHash;
                    if (options.oneofs)
                        object.entity = "txHash";
                }
                if (message.clientId != null && message.hasOwnProperty("clientId")) {
                    object.clientId = message.clientId;
                    if (options.oneofs)
                        object.entity = "clientId";
                }
                if (message.chain != null && message.hasOwnProperty("chain"))
                    object.chain = options.enums === String ? $root.protocol.Chain[message.chain] : message.chain;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.protocol.TxStatus[message.status] : message.status;
                if (message.direction != null && message.hasOwnProperty("direction"))
                    object.direction = options.enums === String ? $root.protocol.TxDirection[message.direction] : message.direction;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.protocol.TxType[message.type] : message.type;
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (typeof message.limit === "number")
                        object.limit = options.longs === String ? String(message.limit) : message.limit;
                    else
                        object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber() : message.limit;
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (typeof message.offset === "number")
                        object.offset = options.longs === String ? String(message.offset) : message.offset;
                    else
                        object.offset = options.longs === String ? $util.Long.prototype.toString.call(message.offset) : options.longs === Number ? new $util.LongBits(message.offset.low >>> 0, message.offset.high >>> 0).toNumber() : message.offset;
                if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                    object.sortBy = options.enums === String ? $root.protocol.SortBy[message.sortBy] : message.sortBy;
                if (message.sortDesc != null && message.hasOwnProperty("sortDesc"))
                    object.sortDesc = message.sortDesc;
                if (message.updatedAtFrom != null && message.hasOwnProperty("updatedAtFrom"))
                    if (typeof message.updatedAtFrom === "number")
                        object.updatedAtFrom = options.longs === String ? String(message.updatedAtFrom) : message.updatedAtFrom;
                    else
                        object.updatedAtFrom = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAtFrom) : options.longs === Number ? new $util.LongBits(message.updatedAtFrom.low >>> 0, message.updatedAtFrom.high >>> 0).toNumber() : message.updatedAtFrom;
                if (message.updatedAtTo != null && message.hasOwnProperty("updatedAtTo"))
                    if (typeof message.updatedAtTo === "number")
                        object.updatedAtTo = options.longs === String ? String(message.updatedAtTo) : message.updatedAtTo;
                    else
                        object.updatedAtTo = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAtTo) : options.longs === Number ? new $util.LongBits(message.updatedAtTo.low >>> 0, message.updatedAtTo.high >>> 0).toNumber() : message.updatedAtTo;
                return object;
            };
    
            /**
             * Converts this TransactionsPagedRequest to JSON.
             * @function toJSON
             * @memberof protocol.TransactionsPagedRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransactionsPagedRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TransactionsPagedRequest;
        })();
    
        protocol.TransactionsPagedResponse = (function() {
    
            /**
             * Properties of a TransactionsPagedResponse.
             * @memberof protocol
             * @interface ITransactionsPagedResponse
             * @property {Long|null} [totalCount] TransactionsPagedResponse totalCount
             * @property {Long|null} [totalPages] TransactionsPagedResponse totalPages
             * @property {Array.<protocol.ITxRecord>|null} [transactions] TransactionsPagedResponse transactions
             * @property {Object.<string,string>|null} [totalAmounts] TransactionsPagedResponse totalAmounts
             * @property {Object.<string,string>|null} [totalFees] TransactionsPagedResponse totalFees
             */
    
            /**
             * Constructs a new TransactionsPagedResponse.
             * @memberof protocol
             * @classdesc Represents a TransactionsPagedResponse.
             * @implements ITransactionsPagedResponse
             * @constructor
             * @param {protocol.ITransactionsPagedResponse=} [properties] Properties to set
             */
            function TransactionsPagedResponse(properties) {
                this.transactions = [];
                this.totalAmounts = {};
                this.totalFees = {};
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TransactionsPagedResponse totalCount.
             * @member {Long} totalCount
             * @memberof protocol.TransactionsPagedResponse
             * @instance
             */
            TransactionsPagedResponse.prototype.totalCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TransactionsPagedResponse totalPages.
             * @member {Long} totalPages
             * @memberof protocol.TransactionsPagedResponse
             * @instance
             */
            TransactionsPagedResponse.prototype.totalPages = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TransactionsPagedResponse transactions.
             * @member {Array.<protocol.ITxRecord>} transactions
             * @memberof protocol.TransactionsPagedResponse
             * @instance
             */
            TransactionsPagedResponse.prototype.transactions = $util.emptyArray;
    
            /**
             * TransactionsPagedResponse totalAmounts.
             * @member {Object.<string,string>} totalAmounts
             * @memberof protocol.TransactionsPagedResponse
             * @instance
             */
            TransactionsPagedResponse.prototype.totalAmounts = $util.emptyObject;
    
            /**
             * TransactionsPagedResponse totalFees.
             * @member {Object.<string,string>} totalFees
             * @memberof protocol.TransactionsPagedResponse
             * @instance
             */
            TransactionsPagedResponse.prototype.totalFees = $util.emptyObject;
    
            /**
             * Creates a new TransactionsPagedResponse instance using the specified properties.
             * @function create
             * @memberof protocol.TransactionsPagedResponse
             * @static
             * @param {protocol.ITransactionsPagedResponse=} [properties] Properties to set
             * @returns {protocol.TransactionsPagedResponse} TransactionsPagedResponse instance
             */
            TransactionsPagedResponse.create = function create(properties) {
                return new TransactionsPagedResponse(properties);
            };
    
            /**
             * Encodes the specified TransactionsPagedResponse message. Does not implicitly {@link protocol.TransactionsPagedResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.TransactionsPagedResponse
             * @static
             * @param {protocol.ITransactionsPagedResponse} message TransactionsPagedResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionsPagedResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.totalCount);
                if (message.totalPages != null && Object.hasOwnProperty.call(message, "totalPages"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalPages);
                if (message.transactions != null && message.transactions.length)
                    for (var i = 0; i < message.transactions.length; ++i)
                        $root.protocol.TxRecord.encode(message.transactions[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.totalAmounts != null && Object.hasOwnProperty.call(message, "totalAmounts"))
                    for (var keys = Object.keys(message.totalAmounts), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.totalAmounts[keys[i]]).ldelim();
                if (message.totalFees != null && Object.hasOwnProperty.call(message, "totalFees"))
                    for (var keys = Object.keys(message.totalFees), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 5, wireType 2 =*/42).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.totalFees[keys[i]]).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TransactionsPagedResponse message, length delimited. Does not implicitly {@link protocol.TransactionsPagedResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TransactionsPagedResponse
             * @static
             * @param {protocol.ITransactionsPagedResponse} message TransactionsPagedResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionsPagedResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TransactionsPagedResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TransactionsPagedResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TransactionsPagedResponse} TransactionsPagedResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionsPagedResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TransactionsPagedResponse(), key;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.totalCount = reader.int64();
                        break;
                    case 2:
                        message.totalPages = reader.int64();
                        break;
                    case 3:
                        if (!(message.transactions && message.transactions.length))
                            message.transactions = [];
                        message.transactions.push($root.protocol.TxRecord.decode(reader, reader.uint32()));
                        break;
                    case 4:
                        reader.skip().pos++;
                        if (message.totalAmounts === $util.emptyObject)
                            message.totalAmounts = {};
                        key = reader.string();
                        reader.pos++;
                        message.totalAmounts[key] = reader.string();
                        break;
                    case 5:
                        reader.skip().pos++;
                        if (message.totalFees === $util.emptyObject)
                            message.totalFees = {};
                        key = reader.string();
                        reader.pos++;
                        message.totalFees[key] = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TransactionsPagedResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TransactionsPagedResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TransactionsPagedResponse} TransactionsPagedResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionsPagedResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TransactionsPagedResponse message.
             * @function verify
             * @memberof protocol.TransactionsPagedResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TransactionsPagedResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (!$util.isInteger(message.totalCount) && !(message.totalCount && $util.isInteger(message.totalCount.low) && $util.isInteger(message.totalCount.high)))
                        return "totalCount: integer|Long expected";
                if (message.totalPages != null && message.hasOwnProperty("totalPages"))
                    if (!$util.isInteger(message.totalPages) && !(message.totalPages && $util.isInteger(message.totalPages.low) && $util.isInteger(message.totalPages.high)))
                        return "totalPages: integer|Long expected";
                if (message.transactions != null && message.hasOwnProperty("transactions")) {
                    if (!Array.isArray(message.transactions))
                        return "transactions: array expected";
                    for (var i = 0; i < message.transactions.length; ++i) {
                        var error = $root.protocol.TxRecord.verify(message.transactions[i]);
                        if (error)
                            return "transactions." + error;
                    }
                }
                if (message.totalAmounts != null && message.hasOwnProperty("totalAmounts")) {
                    if (!$util.isObject(message.totalAmounts))
                        return "totalAmounts: object expected";
                    var key = Object.keys(message.totalAmounts);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.totalAmounts[key[i]]))
                            return "totalAmounts: string{k:string} expected";
                }
                if (message.totalFees != null && message.hasOwnProperty("totalFees")) {
                    if (!$util.isObject(message.totalFees))
                        return "totalFees: object expected";
                    var key = Object.keys(message.totalFees);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.totalFees[key[i]]))
                            return "totalFees: string{k:string} expected";
                }
                return null;
            };
    
            /**
             * Creates a TransactionsPagedResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TransactionsPagedResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TransactionsPagedResponse} TransactionsPagedResponse
             */
            TransactionsPagedResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TransactionsPagedResponse)
                    return object;
                var message = new $root.protocol.TransactionsPagedResponse();
                if (object.totalCount != null)
                    if ($util.Long)
                        (message.totalCount = $util.Long.fromValue(object.totalCount)).unsigned = false;
                    else if (typeof object.totalCount === "string")
                        message.totalCount = parseInt(object.totalCount, 10);
                    else if (typeof object.totalCount === "number")
                        message.totalCount = object.totalCount;
                    else if (typeof object.totalCount === "object")
                        message.totalCount = new $util.LongBits(object.totalCount.low >>> 0, object.totalCount.high >>> 0).toNumber();
                if (object.totalPages != null)
                    if ($util.Long)
                        (message.totalPages = $util.Long.fromValue(object.totalPages)).unsigned = false;
                    else if (typeof object.totalPages === "string")
                        message.totalPages = parseInt(object.totalPages, 10);
                    else if (typeof object.totalPages === "number")
                        message.totalPages = object.totalPages;
                    else if (typeof object.totalPages === "object")
                        message.totalPages = new $util.LongBits(object.totalPages.low >>> 0, object.totalPages.high >>> 0).toNumber();
                if (object.transactions) {
                    if (!Array.isArray(object.transactions))
                        throw TypeError(".protocol.TransactionsPagedResponse.transactions: array expected");
                    message.transactions = [];
                    for (var i = 0; i < object.transactions.length; ++i) {
                        if (typeof object.transactions[i] !== "object")
                            throw TypeError(".protocol.TransactionsPagedResponse.transactions: object expected");
                        message.transactions[i] = $root.protocol.TxRecord.fromObject(object.transactions[i]);
                    }
                }
                if (object.totalAmounts) {
                    if (typeof object.totalAmounts !== "object")
                        throw TypeError(".protocol.TransactionsPagedResponse.totalAmounts: object expected");
                    message.totalAmounts = {};
                    for (var keys = Object.keys(object.totalAmounts), i = 0; i < keys.length; ++i)
                        message.totalAmounts[keys[i]] = String(object.totalAmounts[keys[i]]);
                }
                if (object.totalFees) {
                    if (typeof object.totalFees !== "object")
                        throw TypeError(".protocol.TransactionsPagedResponse.totalFees: object expected");
                    message.totalFees = {};
                    for (var keys = Object.keys(object.totalFees), i = 0; i < keys.length; ++i)
                        message.totalFees[keys[i]] = String(object.totalFees[keys[i]]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TransactionsPagedResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TransactionsPagedResponse
             * @static
             * @param {protocol.TransactionsPagedResponse} message TransactionsPagedResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransactionsPagedResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.transactions = [];
                if (options.objects || options.defaults) {
                    object.totalAmounts = {};
                    object.totalFees = {};
                }
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalCount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalPages = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalPages = options.longs === String ? "0" : 0;
                }
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (typeof message.totalCount === "number")
                        object.totalCount = options.longs === String ? String(message.totalCount) : message.totalCount;
                    else
                        object.totalCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalCount) : options.longs === Number ? new $util.LongBits(message.totalCount.low >>> 0, message.totalCount.high >>> 0).toNumber() : message.totalCount;
                if (message.totalPages != null && message.hasOwnProperty("totalPages"))
                    if (typeof message.totalPages === "number")
                        object.totalPages = options.longs === String ? String(message.totalPages) : message.totalPages;
                    else
                        object.totalPages = options.longs === String ? $util.Long.prototype.toString.call(message.totalPages) : options.longs === Number ? new $util.LongBits(message.totalPages.low >>> 0, message.totalPages.high >>> 0).toNumber() : message.totalPages;
                if (message.transactions && message.transactions.length) {
                    object.transactions = [];
                    for (var j = 0; j < message.transactions.length; ++j)
                        object.transactions[j] = $root.protocol.TxRecord.toObject(message.transactions[j], options);
                }
                var keys2;
                if (message.totalAmounts && (keys2 = Object.keys(message.totalAmounts)).length) {
                    object.totalAmounts = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.totalAmounts[keys2[j]] = message.totalAmounts[keys2[j]];
                }
                if (message.totalFees && (keys2 = Object.keys(message.totalFees)).length) {
                    object.totalFees = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.totalFees[keys2[j]] = message.totalFees[keys2[j]];
                }
                return object;
            };
    
            /**
             * Converts this TransactionsPagedResponse to JSON.
             * @function toJSON
             * @memberof protocol.TransactionsPagedResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransactionsPagedResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TransactionsPagedResponse;
        })();
    
        protocol.ClientPeerTransactionsRequest = (function() {
    
            /**
             * Properties of a ClientPeerTransactionsRequest.
             * @memberof protocol
             * @interface IClientPeerTransactionsRequest
             * @property {number|null} [peerId] ClientPeerTransactionsRequest peerId
             * @property {protocol.Chain|null} [chain] ClientPeerTransactionsRequest chain
             * @property {protocol.Currency|null} [currency] ClientPeerTransactionsRequest currency
             * @property {protocol.TxStatus|null} [status] ClientPeerTransactionsRequest status
             * @property {Long|null} [limit] ClientPeerTransactionsRequest limit
             * @property {Long|null} [offset] ClientPeerTransactionsRequest offset
             * @property {protocol.SortBy|null} [sortBy] ClientPeerTransactionsRequest sortBy
             * @property {boolean|null} [sortDesc] ClientPeerTransactionsRequest sortDesc
             * @property {Long|null} [updatedAtFrom] ClientPeerTransactionsRequest updatedAtFrom
             * @property {Long|null} [updatedAtTo] ClientPeerTransactionsRequest updatedAtTo
             * @property {string|null} [peerName] ClientPeerTransactionsRequest peerName
             */
    
            /**
             * Constructs a new ClientPeerTransactionsRequest.
             * @memberof protocol
             * @classdesc Represents a ClientPeerTransactionsRequest.
             * @implements IClientPeerTransactionsRequest
             * @constructor
             * @param {protocol.IClientPeerTransactionsRequest=} [properties] Properties to set
             */
            function ClientPeerTransactionsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientPeerTransactionsRequest peerId.
             * @member {number} peerId
             * @memberof protocol.ClientPeerTransactionsRequest
             * @instance
             */
            ClientPeerTransactionsRequest.prototype.peerId = 0;
    
            /**
             * ClientPeerTransactionsRequest chain.
             * @member {protocol.Chain} chain
             * @memberof protocol.ClientPeerTransactionsRequest
             * @instance
             */
            ClientPeerTransactionsRequest.prototype.chain = 0;
    
            /**
             * ClientPeerTransactionsRequest currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.ClientPeerTransactionsRequest
             * @instance
             */
            ClientPeerTransactionsRequest.prototype.currency = 0;
    
            /**
             * ClientPeerTransactionsRequest status.
             * @member {protocol.TxStatus} status
             * @memberof protocol.ClientPeerTransactionsRequest
             * @instance
             */
            ClientPeerTransactionsRequest.prototype.status = 0;
    
            /**
             * ClientPeerTransactionsRequest limit.
             * @member {Long} limit
             * @memberof protocol.ClientPeerTransactionsRequest
             * @instance
             */
            ClientPeerTransactionsRequest.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeerTransactionsRequest offset.
             * @member {Long} offset
             * @memberof protocol.ClientPeerTransactionsRequest
             * @instance
             */
            ClientPeerTransactionsRequest.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeerTransactionsRequest sortBy.
             * @member {protocol.SortBy} sortBy
             * @memberof protocol.ClientPeerTransactionsRequest
             * @instance
             */
            ClientPeerTransactionsRequest.prototype.sortBy = 0;
    
            /**
             * ClientPeerTransactionsRequest sortDesc.
             * @member {boolean} sortDesc
             * @memberof protocol.ClientPeerTransactionsRequest
             * @instance
             */
            ClientPeerTransactionsRequest.prototype.sortDesc = false;
    
            /**
             * ClientPeerTransactionsRequest updatedAtFrom.
             * @member {Long} updatedAtFrom
             * @memberof protocol.ClientPeerTransactionsRequest
             * @instance
             */
            ClientPeerTransactionsRequest.prototype.updatedAtFrom = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeerTransactionsRequest updatedAtTo.
             * @member {Long} updatedAtTo
             * @memberof protocol.ClientPeerTransactionsRequest
             * @instance
             */
            ClientPeerTransactionsRequest.prototype.updatedAtTo = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeerTransactionsRequest peerName.
             * @member {string} peerName
             * @memberof protocol.ClientPeerTransactionsRequest
             * @instance
             */
            ClientPeerTransactionsRequest.prototype.peerName = "";
    
            /**
             * Creates a new ClientPeerTransactionsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeerTransactionsRequest
             * @static
             * @param {protocol.IClientPeerTransactionsRequest=} [properties] Properties to set
             * @returns {protocol.ClientPeerTransactionsRequest} ClientPeerTransactionsRequest instance
             */
            ClientPeerTransactionsRequest.create = function create(properties) {
                return new ClientPeerTransactionsRequest(properties);
            };
    
            /**
             * Encodes the specified ClientPeerTransactionsRequest message. Does not implicitly {@link protocol.ClientPeerTransactionsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeerTransactionsRequest
             * @static
             * @param {protocol.IClientPeerTransactionsRequest} message ClientPeerTransactionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerTransactionsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.peerId != null && Object.hasOwnProperty.call(message, "peerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.peerId);
                if (message.chain != null && Object.hasOwnProperty.call(message, "chain"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.chain);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.currency);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.status);
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.limit);
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.offset);
                if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.sortBy);
                if (message.sortDesc != null && Object.hasOwnProperty.call(message, "sortDesc"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.sortDesc);
                if (message.updatedAtFrom != null && Object.hasOwnProperty.call(message, "updatedAtFrom"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int64(message.updatedAtFrom);
                if (message.updatedAtTo != null && Object.hasOwnProperty.call(message, "updatedAtTo"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int64(message.updatedAtTo);
                if (message.peerName != null && Object.hasOwnProperty.call(message, "peerName"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.peerName);
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeerTransactionsRequest message, length delimited. Does not implicitly {@link protocol.ClientPeerTransactionsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeerTransactionsRequest
             * @static
             * @param {protocol.IClientPeerTransactionsRequest} message ClientPeerTransactionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerTransactionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeerTransactionsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeerTransactionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeerTransactionsRequest} ClientPeerTransactionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerTransactionsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeerTransactionsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.peerId = reader.uint32();
                        break;
                    case 2:
                        message.chain = reader.int32();
                        break;
                    case 3:
                        message.currency = reader.int32();
                        break;
                    case 4:
                        message.status = reader.int32();
                        break;
                    case 5:
                        message.limit = reader.int64();
                        break;
                    case 6:
                        message.offset = reader.int64();
                        break;
                    case 7:
                        message.sortBy = reader.int32();
                        break;
                    case 8:
                        message.sortDesc = reader.bool();
                        break;
                    case 9:
                        message.updatedAtFrom = reader.int64();
                        break;
                    case 10:
                        message.updatedAtTo = reader.int64();
                        break;
                    case 11:
                        message.peerName = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeerTransactionsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeerTransactionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeerTransactionsRequest} ClientPeerTransactionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerTransactionsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeerTransactionsRequest message.
             * @function verify
             * @memberof protocol.ClientPeerTransactionsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeerTransactionsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.peerId != null && message.hasOwnProperty("peerId"))
                    if (!$util.isInteger(message.peerId))
                        return "peerId: integer expected";
                if (message.chain != null && message.hasOwnProperty("chain"))
                    switch (message.chain) {
                    default:
                        return "chain: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                        return "limit: integer|Long expected";
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset) && !(message.offset && $util.isInteger(message.offset.low) && $util.isInteger(message.offset.high)))
                        return "offset: integer|Long expected";
                if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                    switch (message.sortBy) {
                    default:
                        return "sortBy: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                        break;
                    }
                if (message.sortDesc != null && message.hasOwnProperty("sortDesc"))
                    if (typeof message.sortDesc !== "boolean")
                        return "sortDesc: boolean expected";
                if (message.updatedAtFrom != null && message.hasOwnProperty("updatedAtFrom"))
                    if (!$util.isInteger(message.updatedAtFrom) && !(message.updatedAtFrom && $util.isInteger(message.updatedAtFrom.low) && $util.isInteger(message.updatedAtFrom.high)))
                        return "updatedAtFrom: integer|Long expected";
                if (message.updatedAtTo != null && message.hasOwnProperty("updatedAtTo"))
                    if (!$util.isInteger(message.updatedAtTo) && !(message.updatedAtTo && $util.isInteger(message.updatedAtTo.low) && $util.isInteger(message.updatedAtTo.high)))
                        return "updatedAtTo: integer|Long expected";
                if (message.peerName != null && message.hasOwnProperty("peerName"))
                    if (!$util.isString(message.peerName))
                        return "peerName: string expected";
                return null;
            };
    
            /**
             * Creates a ClientPeerTransactionsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeerTransactionsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeerTransactionsRequest} ClientPeerTransactionsRequest
             */
            ClientPeerTransactionsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeerTransactionsRequest)
                    return object;
                var message = new $root.protocol.ClientPeerTransactionsRequest();
                if (object.peerId != null)
                    message.peerId = object.peerId >>> 0;
                switch (object.chain) {
                case "UNK_CHAIN":
                case 0:
                    message.chain = 0;
                    break;
                case "BTC_CHAIN":
                case 1:
                    message.chain = 1;
                    break;
                case "ETH_CHAIN":
                case 2:
                    message.chain = 2;
                    break;
                case "TRX_CHAIN":
                case 3:
                    message.chain = 3;
                    break;
                }
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                switch (object.status) {
                case "UNK_TX_STATUS":
                case 0:
                    message.status = 0;
                    break;
                case "PENDING_TX_STATUS":
                case 1:
                    message.status = 1;
                    break;
                case "CONFIRMED_TX_STATUS":
                case 2:
                    message.status = 2;
                    break;
                }
                if (object.limit != null)
                    if ($util.Long)
                        (message.limit = $util.Long.fromValue(object.limit)).unsigned = false;
                    else if (typeof object.limit === "string")
                        message.limit = parseInt(object.limit, 10);
                    else if (typeof object.limit === "number")
                        message.limit = object.limit;
                    else if (typeof object.limit === "object")
                        message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber();
                if (object.offset != null)
                    if ($util.Long)
                        (message.offset = $util.Long.fromValue(object.offset)).unsigned = false;
                    else if (typeof object.offset === "string")
                        message.offset = parseInt(object.offset, 10);
                    else if (typeof object.offset === "number")
                        message.offset = object.offset;
                    else if (typeof object.offset === "object")
                        message.offset = new $util.LongBits(object.offset.low >>> 0, object.offset.high >>> 0).toNumber();
                switch (object.sortBy) {
                case "UNK_SORT_BY":
                case 0:
                    message.sortBy = 0;
                    break;
                case "ID_SORT_BY":
                case 1:
                    message.sortBy = 1;
                    break;
                case "DATE_SORT_BY":
                case 2:
                    message.sortBy = 2;
                    break;
                case "CURRENCY_SORT_BY":
                case 3:
                    message.sortBy = 3;
                    break;
                case "TAGS_SORT_BY":
                case 4:
                    message.sortBy = 4;
                    break;
                case "TX_HASH_SORT_BY":
                case 5:
                    message.sortBy = 5;
                    break;
                case "AMOUNT_SORT_BY":
                case 6:
                    message.sortBy = 6;
                    break;
                case "NAME_SORT_BY":
                case 7:
                    message.sortBy = 7;
                    break;
                case "LAST_AMOUNT_SORT_BY":
                case 8:
                    message.sortBy = 8;
                    break;
                case "STATUS_SORT_BY":
                case 9:
                    message.sortBy = 9;
                    break;
                case "ENTITIES_COUNT_SORT_BY":
                case 10:
                    message.sortBy = 10;
                    break;
                case "ADDRESS_SORT_BY":
                case 11:
                    message.sortBy = 11;
                    break;
                case "RISK_SCORE_SORT_BY":
                case 12:
                    message.sortBy = 12;
                    break;
                case "BALANCE_SORT_BY":
                case 13:
                    message.sortBy = 13;
                    break;
                }
                if (object.sortDesc != null)
                    message.sortDesc = Boolean(object.sortDesc);
                if (object.updatedAtFrom != null)
                    if ($util.Long)
                        (message.updatedAtFrom = $util.Long.fromValue(object.updatedAtFrom)).unsigned = false;
                    else if (typeof object.updatedAtFrom === "string")
                        message.updatedAtFrom = parseInt(object.updatedAtFrom, 10);
                    else if (typeof object.updatedAtFrom === "number")
                        message.updatedAtFrom = object.updatedAtFrom;
                    else if (typeof object.updatedAtFrom === "object")
                        message.updatedAtFrom = new $util.LongBits(object.updatedAtFrom.low >>> 0, object.updatedAtFrom.high >>> 0).toNumber();
                if (object.updatedAtTo != null)
                    if ($util.Long)
                        (message.updatedAtTo = $util.Long.fromValue(object.updatedAtTo)).unsigned = false;
                    else if (typeof object.updatedAtTo === "string")
                        message.updatedAtTo = parseInt(object.updatedAtTo, 10);
                    else if (typeof object.updatedAtTo === "number")
                        message.updatedAtTo = object.updatedAtTo;
                    else if (typeof object.updatedAtTo === "object")
                        message.updatedAtTo = new $util.LongBits(object.updatedAtTo.low >>> 0, object.updatedAtTo.high >>> 0).toNumber();
                if (object.peerName != null)
                    message.peerName = String(object.peerName);
                return message;
            };
    
            /**
             * Creates a plain object from a ClientPeerTransactionsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeerTransactionsRequest
             * @static
             * @param {protocol.ClientPeerTransactionsRequest} message ClientPeerTransactionsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeerTransactionsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.peerId = 0;
                    object.chain = options.enums === String ? "UNK_CHAIN" : 0;
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.status = options.enums === String ? "UNK_TX_STATUS" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.limit = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.offset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.offset = options.longs === String ? "0" : 0;
                    object.sortBy = options.enums === String ? "UNK_SORT_BY" : 0;
                    object.sortDesc = false;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.updatedAtFrom = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updatedAtFrom = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.updatedAtTo = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updatedAtTo = options.longs === String ? "0" : 0;
                    object.peerName = "";
                }
                if (message.peerId != null && message.hasOwnProperty("peerId"))
                    object.peerId = message.peerId;
                if (message.chain != null && message.hasOwnProperty("chain"))
                    object.chain = options.enums === String ? $root.protocol.Chain[message.chain] : message.chain;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.protocol.TxStatus[message.status] : message.status;
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (typeof message.limit === "number")
                        object.limit = options.longs === String ? String(message.limit) : message.limit;
                    else
                        object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber() : message.limit;
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (typeof message.offset === "number")
                        object.offset = options.longs === String ? String(message.offset) : message.offset;
                    else
                        object.offset = options.longs === String ? $util.Long.prototype.toString.call(message.offset) : options.longs === Number ? new $util.LongBits(message.offset.low >>> 0, message.offset.high >>> 0).toNumber() : message.offset;
                if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                    object.sortBy = options.enums === String ? $root.protocol.SortBy[message.sortBy] : message.sortBy;
                if (message.sortDesc != null && message.hasOwnProperty("sortDesc"))
                    object.sortDesc = message.sortDesc;
                if (message.updatedAtFrom != null && message.hasOwnProperty("updatedAtFrom"))
                    if (typeof message.updatedAtFrom === "number")
                        object.updatedAtFrom = options.longs === String ? String(message.updatedAtFrom) : message.updatedAtFrom;
                    else
                        object.updatedAtFrom = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAtFrom) : options.longs === Number ? new $util.LongBits(message.updatedAtFrom.low >>> 0, message.updatedAtFrom.high >>> 0).toNumber() : message.updatedAtFrom;
                if (message.updatedAtTo != null && message.hasOwnProperty("updatedAtTo"))
                    if (typeof message.updatedAtTo === "number")
                        object.updatedAtTo = options.longs === String ? String(message.updatedAtTo) : message.updatedAtTo;
                    else
                        object.updatedAtTo = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAtTo) : options.longs === Number ? new $util.LongBits(message.updatedAtTo.low >>> 0, message.updatedAtTo.high >>> 0).toNumber() : message.updatedAtTo;
                if (message.peerName != null && message.hasOwnProperty("peerName"))
                    object.peerName = message.peerName;
                return object;
            };
    
            /**
             * Converts this ClientPeerTransactionsRequest to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeerTransactionsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeerTransactionsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeerTransactionsRequest;
        })();
    
        protocol.ClientPeerTransactionsResponse = (function() {
    
            /**
             * Properties of a ClientPeerTransactionsResponse.
             * @memberof protocol
             * @interface IClientPeerTransactionsResponse
             * @property {Long|null} [totalCount] ClientPeerTransactionsResponse totalCount
             * @property {Long|null} [totalPages] ClientPeerTransactionsResponse totalPages
             * @property {Array.<protocol.ITxRecord>|null} [transactions] ClientPeerTransactionsResponse transactions
             * @property {Object.<string,string>|null} [totalIncomes] ClientPeerTransactionsResponse totalIncomes
             */
    
            /**
             * Constructs a new ClientPeerTransactionsResponse.
             * @memberof protocol
             * @classdesc Represents a ClientPeerTransactionsResponse.
             * @implements IClientPeerTransactionsResponse
             * @constructor
             * @param {protocol.IClientPeerTransactionsResponse=} [properties] Properties to set
             */
            function ClientPeerTransactionsResponse(properties) {
                this.transactions = [];
                this.totalIncomes = {};
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientPeerTransactionsResponse totalCount.
             * @member {Long} totalCount
             * @memberof protocol.ClientPeerTransactionsResponse
             * @instance
             */
            ClientPeerTransactionsResponse.prototype.totalCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeerTransactionsResponse totalPages.
             * @member {Long} totalPages
             * @memberof protocol.ClientPeerTransactionsResponse
             * @instance
             */
            ClientPeerTransactionsResponse.prototype.totalPages = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeerTransactionsResponse transactions.
             * @member {Array.<protocol.ITxRecord>} transactions
             * @memberof protocol.ClientPeerTransactionsResponse
             * @instance
             */
            ClientPeerTransactionsResponse.prototype.transactions = $util.emptyArray;
    
            /**
             * ClientPeerTransactionsResponse totalIncomes.
             * @member {Object.<string,string>} totalIncomes
             * @memberof protocol.ClientPeerTransactionsResponse
             * @instance
             */
            ClientPeerTransactionsResponse.prototype.totalIncomes = $util.emptyObject;
    
            /**
             * Creates a new ClientPeerTransactionsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeerTransactionsResponse
             * @static
             * @param {protocol.IClientPeerTransactionsResponse=} [properties] Properties to set
             * @returns {protocol.ClientPeerTransactionsResponse} ClientPeerTransactionsResponse instance
             */
            ClientPeerTransactionsResponse.create = function create(properties) {
                return new ClientPeerTransactionsResponse(properties);
            };
    
            /**
             * Encodes the specified ClientPeerTransactionsResponse message. Does not implicitly {@link protocol.ClientPeerTransactionsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeerTransactionsResponse
             * @static
             * @param {protocol.IClientPeerTransactionsResponse} message ClientPeerTransactionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerTransactionsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.totalCount);
                if (message.totalPages != null && Object.hasOwnProperty.call(message, "totalPages"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalPages);
                if (message.transactions != null && message.transactions.length)
                    for (var i = 0; i < message.transactions.length; ++i)
                        $root.protocol.TxRecord.encode(message.transactions[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.totalIncomes != null && Object.hasOwnProperty.call(message, "totalIncomes"))
                    for (var keys = Object.keys(message.totalIncomes), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.totalIncomes[keys[i]]).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeerTransactionsResponse message, length delimited. Does not implicitly {@link protocol.ClientPeerTransactionsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeerTransactionsResponse
             * @static
             * @param {protocol.IClientPeerTransactionsResponse} message ClientPeerTransactionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerTransactionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeerTransactionsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeerTransactionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeerTransactionsResponse} ClientPeerTransactionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerTransactionsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeerTransactionsResponse(), key;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.totalCount = reader.int64();
                        break;
                    case 2:
                        message.totalPages = reader.int64();
                        break;
                    case 3:
                        if (!(message.transactions && message.transactions.length))
                            message.transactions = [];
                        message.transactions.push($root.protocol.TxRecord.decode(reader, reader.uint32()));
                        break;
                    case 4:
                        reader.skip().pos++;
                        if (message.totalIncomes === $util.emptyObject)
                            message.totalIncomes = {};
                        key = reader.string();
                        reader.pos++;
                        message.totalIncomes[key] = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeerTransactionsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeerTransactionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeerTransactionsResponse} ClientPeerTransactionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerTransactionsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeerTransactionsResponse message.
             * @function verify
             * @memberof protocol.ClientPeerTransactionsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeerTransactionsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (!$util.isInteger(message.totalCount) && !(message.totalCount && $util.isInteger(message.totalCount.low) && $util.isInteger(message.totalCount.high)))
                        return "totalCount: integer|Long expected";
                if (message.totalPages != null && message.hasOwnProperty("totalPages"))
                    if (!$util.isInteger(message.totalPages) && !(message.totalPages && $util.isInteger(message.totalPages.low) && $util.isInteger(message.totalPages.high)))
                        return "totalPages: integer|Long expected";
                if (message.transactions != null && message.hasOwnProperty("transactions")) {
                    if (!Array.isArray(message.transactions))
                        return "transactions: array expected";
                    for (var i = 0; i < message.transactions.length; ++i) {
                        var error = $root.protocol.TxRecord.verify(message.transactions[i]);
                        if (error)
                            return "transactions." + error;
                    }
                }
                if (message.totalIncomes != null && message.hasOwnProperty("totalIncomes")) {
                    if (!$util.isObject(message.totalIncomes))
                        return "totalIncomes: object expected";
                    var key = Object.keys(message.totalIncomes);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.totalIncomes[key[i]]))
                            return "totalIncomes: string{k:string} expected";
                }
                return null;
            };
    
            /**
             * Creates a ClientPeerTransactionsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeerTransactionsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeerTransactionsResponse} ClientPeerTransactionsResponse
             */
            ClientPeerTransactionsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeerTransactionsResponse)
                    return object;
                var message = new $root.protocol.ClientPeerTransactionsResponse();
                if (object.totalCount != null)
                    if ($util.Long)
                        (message.totalCount = $util.Long.fromValue(object.totalCount)).unsigned = false;
                    else if (typeof object.totalCount === "string")
                        message.totalCount = parseInt(object.totalCount, 10);
                    else if (typeof object.totalCount === "number")
                        message.totalCount = object.totalCount;
                    else if (typeof object.totalCount === "object")
                        message.totalCount = new $util.LongBits(object.totalCount.low >>> 0, object.totalCount.high >>> 0).toNumber();
                if (object.totalPages != null)
                    if ($util.Long)
                        (message.totalPages = $util.Long.fromValue(object.totalPages)).unsigned = false;
                    else if (typeof object.totalPages === "string")
                        message.totalPages = parseInt(object.totalPages, 10);
                    else if (typeof object.totalPages === "number")
                        message.totalPages = object.totalPages;
                    else if (typeof object.totalPages === "object")
                        message.totalPages = new $util.LongBits(object.totalPages.low >>> 0, object.totalPages.high >>> 0).toNumber();
                if (object.transactions) {
                    if (!Array.isArray(object.transactions))
                        throw TypeError(".protocol.ClientPeerTransactionsResponse.transactions: array expected");
                    message.transactions = [];
                    for (var i = 0; i < object.transactions.length; ++i) {
                        if (typeof object.transactions[i] !== "object")
                            throw TypeError(".protocol.ClientPeerTransactionsResponse.transactions: object expected");
                        message.transactions[i] = $root.protocol.TxRecord.fromObject(object.transactions[i]);
                    }
                }
                if (object.totalIncomes) {
                    if (typeof object.totalIncomes !== "object")
                        throw TypeError(".protocol.ClientPeerTransactionsResponse.totalIncomes: object expected");
                    message.totalIncomes = {};
                    for (var keys = Object.keys(object.totalIncomes), i = 0; i < keys.length; ++i)
                        message.totalIncomes[keys[i]] = String(object.totalIncomes[keys[i]]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientPeerTransactionsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeerTransactionsResponse
             * @static
             * @param {protocol.ClientPeerTransactionsResponse} message ClientPeerTransactionsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeerTransactionsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.transactions = [];
                if (options.objects || options.defaults)
                    object.totalIncomes = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalCount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalPages = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalPages = options.longs === String ? "0" : 0;
                }
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (typeof message.totalCount === "number")
                        object.totalCount = options.longs === String ? String(message.totalCount) : message.totalCount;
                    else
                        object.totalCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalCount) : options.longs === Number ? new $util.LongBits(message.totalCount.low >>> 0, message.totalCount.high >>> 0).toNumber() : message.totalCount;
                if (message.totalPages != null && message.hasOwnProperty("totalPages"))
                    if (typeof message.totalPages === "number")
                        object.totalPages = options.longs === String ? String(message.totalPages) : message.totalPages;
                    else
                        object.totalPages = options.longs === String ? $util.Long.prototype.toString.call(message.totalPages) : options.longs === Number ? new $util.LongBits(message.totalPages.low >>> 0, message.totalPages.high >>> 0).toNumber() : message.totalPages;
                if (message.transactions && message.transactions.length) {
                    object.transactions = [];
                    for (var j = 0; j < message.transactions.length; ++j)
                        object.transactions[j] = $root.protocol.TxRecord.toObject(message.transactions[j], options);
                }
                var keys2;
                if (message.totalIncomes && (keys2 = Object.keys(message.totalIncomes)).length) {
                    object.totalIncomes = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.totalIncomes[keys2[j]] = message.totalIncomes[keys2[j]];
                }
                return object;
            };
    
            /**
             * Converts this ClientPeerTransactionsResponse to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeerTransactionsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeerTransactionsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeerTransactionsResponse;
        })();
    
        protocol.ClientPeerTransactionsStatsRequest = (function() {
    
            /**
             * Properties of a ClientPeerTransactionsStatsRequest.
             * @memberof protocol
             * @interface IClientPeerTransactionsStatsRequest
             * @property {Long|null} [updatedAtFrom] ClientPeerTransactionsStatsRequest updatedAtFrom
             * @property {Long|null} [updatedAtTo] ClientPeerTransactionsStatsRequest updatedAtTo
             */
    
            /**
             * Constructs a new ClientPeerTransactionsStatsRequest.
             * @memberof protocol
             * @classdesc Represents a ClientPeerTransactionsStatsRequest.
             * @implements IClientPeerTransactionsStatsRequest
             * @constructor
             * @param {protocol.IClientPeerTransactionsStatsRequest=} [properties] Properties to set
             */
            function ClientPeerTransactionsStatsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientPeerTransactionsStatsRequest updatedAtFrom.
             * @member {Long} updatedAtFrom
             * @memberof protocol.ClientPeerTransactionsStatsRequest
             * @instance
             */
            ClientPeerTransactionsStatsRequest.prototype.updatedAtFrom = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeerTransactionsStatsRequest updatedAtTo.
             * @member {Long} updatedAtTo
             * @memberof protocol.ClientPeerTransactionsStatsRequest
             * @instance
             */
            ClientPeerTransactionsStatsRequest.prototype.updatedAtTo = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new ClientPeerTransactionsStatsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeerTransactionsStatsRequest
             * @static
             * @param {protocol.IClientPeerTransactionsStatsRequest=} [properties] Properties to set
             * @returns {protocol.ClientPeerTransactionsStatsRequest} ClientPeerTransactionsStatsRequest instance
             */
            ClientPeerTransactionsStatsRequest.create = function create(properties) {
                return new ClientPeerTransactionsStatsRequest(properties);
            };
    
            /**
             * Encodes the specified ClientPeerTransactionsStatsRequest message. Does not implicitly {@link protocol.ClientPeerTransactionsStatsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeerTransactionsStatsRequest
             * @static
             * @param {protocol.IClientPeerTransactionsStatsRequest} message ClientPeerTransactionsStatsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerTransactionsStatsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.updatedAtFrom != null && Object.hasOwnProperty.call(message, "updatedAtFrom"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.updatedAtFrom);
                if (message.updatedAtTo != null && Object.hasOwnProperty.call(message, "updatedAtTo"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.updatedAtTo);
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeerTransactionsStatsRequest message, length delimited. Does not implicitly {@link protocol.ClientPeerTransactionsStatsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeerTransactionsStatsRequest
             * @static
             * @param {protocol.IClientPeerTransactionsStatsRequest} message ClientPeerTransactionsStatsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerTransactionsStatsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeerTransactionsStatsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeerTransactionsStatsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeerTransactionsStatsRequest} ClientPeerTransactionsStatsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerTransactionsStatsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeerTransactionsStatsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.updatedAtFrom = reader.int64();
                        break;
                    case 2:
                        message.updatedAtTo = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeerTransactionsStatsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeerTransactionsStatsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeerTransactionsStatsRequest} ClientPeerTransactionsStatsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerTransactionsStatsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeerTransactionsStatsRequest message.
             * @function verify
             * @memberof protocol.ClientPeerTransactionsStatsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeerTransactionsStatsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.updatedAtFrom != null && message.hasOwnProperty("updatedAtFrom"))
                    if (!$util.isInteger(message.updatedAtFrom) && !(message.updatedAtFrom && $util.isInteger(message.updatedAtFrom.low) && $util.isInteger(message.updatedAtFrom.high)))
                        return "updatedAtFrom: integer|Long expected";
                if (message.updatedAtTo != null && message.hasOwnProperty("updatedAtTo"))
                    if (!$util.isInteger(message.updatedAtTo) && !(message.updatedAtTo && $util.isInteger(message.updatedAtTo.low) && $util.isInteger(message.updatedAtTo.high)))
                        return "updatedAtTo: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a ClientPeerTransactionsStatsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeerTransactionsStatsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeerTransactionsStatsRequest} ClientPeerTransactionsStatsRequest
             */
            ClientPeerTransactionsStatsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeerTransactionsStatsRequest)
                    return object;
                var message = new $root.protocol.ClientPeerTransactionsStatsRequest();
                if (object.updatedAtFrom != null)
                    if ($util.Long)
                        (message.updatedAtFrom = $util.Long.fromValue(object.updatedAtFrom)).unsigned = false;
                    else if (typeof object.updatedAtFrom === "string")
                        message.updatedAtFrom = parseInt(object.updatedAtFrom, 10);
                    else if (typeof object.updatedAtFrom === "number")
                        message.updatedAtFrom = object.updatedAtFrom;
                    else if (typeof object.updatedAtFrom === "object")
                        message.updatedAtFrom = new $util.LongBits(object.updatedAtFrom.low >>> 0, object.updatedAtFrom.high >>> 0).toNumber();
                if (object.updatedAtTo != null)
                    if ($util.Long)
                        (message.updatedAtTo = $util.Long.fromValue(object.updatedAtTo)).unsigned = false;
                    else if (typeof object.updatedAtTo === "string")
                        message.updatedAtTo = parseInt(object.updatedAtTo, 10);
                    else if (typeof object.updatedAtTo === "number")
                        message.updatedAtTo = object.updatedAtTo;
                    else if (typeof object.updatedAtTo === "object")
                        message.updatedAtTo = new $util.LongBits(object.updatedAtTo.low >>> 0, object.updatedAtTo.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a ClientPeerTransactionsStatsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeerTransactionsStatsRequest
             * @static
             * @param {protocol.ClientPeerTransactionsStatsRequest} message ClientPeerTransactionsStatsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeerTransactionsStatsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.updatedAtFrom = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updatedAtFrom = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.updatedAtTo = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updatedAtTo = options.longs === String ? "0" : 0;
                }
                if (message.updatedAtFrom != null && message.hasOwnProperty("updatedAtFrom"))
                    if (typeof message.updatedAtFrom === "number")
                        object.updatedAtFrom = options.longs === String ? String(message.updatedAtFrom) : message.updatedAtFrom;
                    else
                        object.updatedAtFrom = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAtFrom) : options.longs === Number ? new $util.LongBits(message.updatedAtFrom.low >>> 0, message.updatedAtFrom.high >>> 0).toNumber() : message.updatedAtFrom;
                if (message.updatedAtTo != null && message.hasOwnProperty("updatedAtTo"))
                    if (typeof message.updatedAtTo === "number")
                        object.updatedAtTo = options.longs === String ? String(message.updatedAtTo) : message.updatedAtTo;
                    else
                        object.updatedAtTo = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAtTo) : options.longs === Number ? new $util.LongBits(message.updatedAtTo.low >>> 0, message.updatedAtTo.high >>> 0).toNumber() : message.updatedAtTo;
                return object;
            };
    
            /**
             * Converts this ClientPeerTransactionsStatsRequest to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeerTransactionsStatsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeerTransactionsStatsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeerTransactionsStatsRequest;
        })();
    
        protocol.ClientPeerTransactionsStatsResponse = (function() {
    
            /**
             * Properties of a ClientPeerTransactionsStatsResponse.
             * @memberof protocol
             * @interface IClientPeerTransactionsStatsResponse
             * @property {Long|null} [totalCount] ClientPeerTransactionsStatsResponse totalCount
             * @property {Object.<string,string>|null} [totalIncomes] ClientPeerTransactionsStatsResponse totalIncomes
             */
    
            /**
             * Constructs a new ClientPeerTransactionsStatsResponse.
             * @memberof protocol
             * @classdesc Represents a ClientPeerTransactionsStatsResponse.
             * @implements IClientPeerTransactionsStatsResponse
             * @constructor
             * @param {protocol.IClientPeerTransactionsStatsResponse=} [properties] Properties to set
             */
            function ClientPeerTransactionsStatsResponse(properties) {
                this.totalIncomes = {};
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientPeerTransactionsStatsResponse totalCount.
             * @member {Long} totalCount
             * @memberof protocol.ClientPeerTransactionsStatsResponse
             * @instance
             */
            ClientPeerTransactionsStatsResponse.prototype.totalCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeerTransactionsStatsResponse totalIncomes.
             * @member {Object.<string,string>} totalIncomes
             * @memberof protocol.ClientPeerTransactionsStatsResponse
             * @instance
             */
            ClientPeerTransactionsStatsResponse.prototype.totalIncomes = $util.emptyObject;
    
            /**
             * Creates a new ClientPeerTransactionsStatsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeerTransactionsStatsResponse
             * @static
             * @param {protocol.IClientPeerTransactionsStatsResponse=} [properties] Properties to set
             * @returns {protocol.ClientPeerTransactionsStatsResponse} ClientPeerTransactionsStatsResponse instance
             */
            ClientPeerTransactionsStatsResponse.create = function create(properties) {
                return new ClientPeerTransactionsStatsResponse(properties);
            };
    
            /**
             * Encodes the specified ClientPeerTransactionsStatsResponse message. Does not implicitly {@link protocol.ClientPeerTransactionsStatsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeerTransactionsStatsResponse
             * @static
             * @param {protocol.IClientPeerTransactionsStatsResponse} message ClientPeerTransactionsStatsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerTransactionsStatsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.totalCount);
                if (message.totalIncomes != null && Object.hasOwnProperty.call(message, "totalIncomes"))
                    for (var keys = Object.keys(message.totalIncomes), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.totalIncomes[keys[i]]).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeerTransactionsStatsResponse message, length delimited. Does not implicitly {@link protocol.ClientPeerTransactionsStatsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeerTransactionsStatsResponse
             * @static
             * @param {protocol.IClientPeerTransactionsStatsResponse} message ClientPeerTransactionsStatsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerTransactionsStatsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeerTransactionsStatsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeerTransactionsStatsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeerTransactionsStatsResponse} ClientPeerTransactionsStatsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerTransactionsStatsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeerTransactionsStatsResponse(), key;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.totalCount = reader.int64();
                        break;
                    case 2:
                        reader.skip().pos++;
                        if (message.totalIncomes === $util.emptyObject)
                            message.totalIncomes = {};
                        key = reader.string();
                        reader.pos++;
                        message.totalIncomes[key] = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeerTransactionsStatsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeerTransactionsStatsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeerTransactionsStatsResponse} ClientPeerTransactionsStatsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerTransactionsStatsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeerTransactionsStatsResponse message.
             * @function verify
             * @memberof protocol.ClientPeerTransactionsStatsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeerTransactionsStatsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (!$util.isInteger(message.totalCount) && !(message.totalCount && $util.isInteger(message.totalCount.low) && $util.isInteger(message.totalCount.high)))
                        return "totalCount: integer|Long expected";
                if (message.totalIncomes != null && message.hasOwnProperty("totalIncomes")) {
                    if (!$util.isObject(message.totalIncomes))
                        return "totalIncomes: object expected";
                    var key = Object.keys(message.totalIncomes);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.totalIncomes[key[i]]))
                            return "totalIncomes: string{k:string} expected";
                }
                return null;
            };
    
            /**
             * Creates a ClientPeerTransactionsStatsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeerTransactionsStatsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeerTransactionsStatsResponse} ClientPeerTransactionsStatsResponse
             */
            ClientPeerTransactionsStatsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeerTransactionsStatsResponse)
                    return object;
                var message = new $root.protocol.ClientPeerTransactionsStatsResponse();
                if (object.totalCount != null)
                    if ($util.Long)
                        (message.totalCount = $util.Long.fromValue(object.totalCount)).unsigned = false;
                    else if (typeof object.totalCount === "string")
                        message.totalCount = parseInt(object.totalCount, 10);
                    else if (typeof object.totalCount === "number")
                        message.totalCount = object.totalCount;
                    else if (typeof object.totalCount === "object")
                        message.totalCount = new $util.LongBits(object.totalCount.low >>> 0, object.totalCount.high >>> 0).toNumber();
                if (object.totalIncomes) {
                    if (typeof object.totalIncomes !== "object")
                        throw TypeError(".protocol.ClientPeerTransactionsStatsResponse.totalIncomes: object expected");
                    message.totalIncomes = {};
                    for (var keys = Object.keys(object.totalIncomes), i = 0; i < keys.length; ++i)
                        message.totalIncomes[keys[i]] = String(object.totalIncomes[keys[i]]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientPeerTransactionsStatsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeerTransactionsStatsResponse
             * @static
             * @param {protocol.ClientPeerTransactionsStatsResponse} message ClientPeerTransactionsStatsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeerTransactionsStatsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.objects || options.defaults)
                    object.totalIncomes = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalCount = options.longs === String ? "0" : 0;
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (typeof message.totalCount === "number")
                        object.totalCount = options.longs === String ? String(message.totalCount) : message.totalCount;
                    else
                        object.totalCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalCount) : options.longs === Number ? new $util.LongBits(message.totalCount.low >>> 0, message.totalCount.high >>> 0).toNumber() : message.totalCount;
                var keys2;
                if (message.totalIncomes && (keys2 = Object.keys(message.totalIncomes)).length) {
                    object.totalIncomes = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.totalIncomes[keys2[j]] = message.totalIncomes[keys2[j]];
                }
                return object;
            };
    
            /**
             * Converts this ClientPeerTransactionsStatsResponse to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeerTransactionsStatsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeerTransactionsStatsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeerTransactionsStatsResponse;
        })();
    
        protocol.TransactionsStatsRequest = (function() {
    
            /**
             * Properties of a TransactionsStatsRequest.
             * @memberof protocol
             * @interface ITransactionsStatsRequest
             * @property {Long|null} [updatedAtFrom] TransactionsStatsRequest updatedAtFrom
             * @property {Long|null} [updatedAtTo] TransactionsStatsRequest updatedAtTo
             */
    
            /**
             * Constructs a new TransactionsStatsRequest.
             * @memberof protocol
             * @classdesc Represents a TransactionsStatsRequest.
             * @implements ITransactionsStatsRequest
             * @constructor
             * @param {protocol.ITransactionsStatsRequest=} [properties] Properties to set
             */
            function TransactionsStatsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TransactionsStatsRequest updatedAtFrom.
             * @member {Long} updatedAtFrom
             * @memberof protocol.TransactionsStatsRequest
             * @instance
             */
            TransactionsStatsRequest.prototype.updatedAtFrom = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TransactionsStatsRequest updatedAtTo.
             * @member {Long} updatedAtTo
             * @memberof protocol.TransactionsStatsRequest
             * @instance
             */
            TransactionsStatsRequest.prototype.updatedAtTo = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new TransactionsStatsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.TransactionsStatsRequest
             * @static
             * @param {protocol.ITransactionsStatsRequest=} [properties] Properties to set
             * @returns {protocol.TransactionsStatsRequest} TransactionsStatsRequest instance
             */
            TransactionsStatsRequest.create = function create(properties) {
                return new TransactionsStatsRequest(properties);
            };
    
            /**
             * Encodes the specified TransactionsStatsRequest message. Does not implicitly {@link protocol.TransactionsStatsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.TransactionsStatsRequest
             * @static
             * @param {protocol.ITransactionsStatsRequest} message TransactionsStatsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionsStatsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.updatedAtFrom != null && Object.hasOwnProperty.call(message, "updatedAtFrom"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.updatedAtFrom);
                if (message.updatedAtTo != null && Object.hasOwnProperty.call(message, "updatedAtTo"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.updatedAtTo);
                return writer;
            };
    
            /**
             * Encodes the specified TransactionsStatsRequest message, length delimited. Does not implicitly {@link protocol.TransactionsStatsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TransactionsStatsRequest
             * @static
             * @param {protocol.ITransactionsStatsRequest} message TransactionsStatsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionsStatsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TransactionsStatsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TransactionsStatsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TransactionsStatsRequest} TransactionsStatsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionsStatsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TransactionsStatsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.updatedAtFrom = reader.int64();
                        break;
                    case 2:
                        message.updatedAtTo = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TransactionsStatsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TransactionsStatsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TransactionsStatsRequest} TransactionsStatsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionsStatsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TransactionsStatsRequest message.
             * @function verify
             * @memberof protocol.TransactionsStatsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TransactionsStatsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.updatedAtFrom != null && message.hasOwnProperty("updatedAtFrom"))
                    if (!$util.isInteger(message.updatedAtFrom) && !(message.updatedAtFrom && $util.isInteger(message.updatedAtFrom.low) && $util.isInteger(message.updatedAtFrom.high)))
                        return "updatedAtFrom: integer|Long expected";
                if (message.updatedAtTo != null && message.hasOwnProperty("updatedAtTo"))
                    if (!$util.isInteger(message.updatedAtTo) && !(message.updatedAtTo && $util.isInteger(message.updatedAtTo.low) && $util.isInteger(message.updatedAtTo.high)))
                        return "updatedAtTo: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a TransactionsStatsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TransactionsStatsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TransactionsStatsRequest} TransactionsStatsRequest
             */
            TransactionsStatsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TransactionsStatsRequest)
                    return object;
                var message = new $root.protocol.TransactionsStatsRequest();
                if (object.updatedAtFrom != null)
                    if ($util.Long)
                        (message.updatedAtFrom = $util.Long.fromValue(object.updatedAtFrom)).unsigned = false;
                    else if (typeof object.updatedAtFrom === "string")
                        message.updatedAtFrom = parseInt(object.updatedAtFrom, 10);
                    else if (typeof object.updatedAtFrom === "number")
                        message.updatedAtFrom = object.updatedAtFrom;
                    else if (typeof object.updatedAtFrom === "object")
                        message.updatedAtFrom = new $util.LongBits(object.updatedAtFrom.low >>> 0, object.updatedAtFrom.high >>> 0).toNumber();
                if (object.updatedAtTo != null)
                    if ($util.Long)
                        (message.updatedAtTo = $util.Long.fromValue(object.updatedAtTo)).unsigned = false;
                    else if (typeof object.updatedAtTo === "string")
                        message.updatedAtTo = parseInt(object.updatedAtTo, 10);
                    else if (typeof object.updatedAtTo === "number")
                        message.updatedAtTo = object.updatedAtTo;
                    else if (typeof object.updatedAtTo === "object")
                        message.updatedAtTo = new $util.LongBits(object.updatedAtTo.low >>> 0, object.updatedAtTo.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a TransactionsStatsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TransactionsStatsRequest
             * @static
             * @param {protocol.TransactionsStatsRequest} message TransactionsStatsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransactionsStatsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.updatedAtFrom = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updatedAtFrom = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.updatedAtTo = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updatedAtTo = options.longs === String ? "0" : 0;
                }
                if (message.updatedAtFrom != null && message.hasOwnProperty("updatedAtFrom"))
                    if (typeof message.updatedAtFrom === "number")
                        object.updatedAtFrom = options.longs === String ? String(message.updatedAtFrom) : message.updatedAtFrom;
                    else
                        object.updatedAtFrom = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAtFrom) : options.longs === Number ? new $util.LongBits(message.updatedAtFrom.low >>> 0, message.updatedAtFrom.high >>> 0).toNumber() : message.updatedAtFrom;
                if (message.updatedAtTo != null && message.hasOwnProperty("updatedAtTo"))
                    if (typeof message.updatedAtTo === "number")
                        object.updatedAtTo = options.longs === String ? String(message.updatedAtTo) : message.updatedAtTo;
                    else
                        object.updatedAtTo = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAtTo) : options.longs === Number ? new $util.LongBits(message.updatedAtTo.low >>> 0, message.updatedAtTo.high >>> 0).toNumber() : message.updatedAtTo;
                return object;
            };
    
            /**
             * Converts this TransactionsStatsRequest to JSON.
             * @function toJSON
             * @memberof protocol.TransactionsStatsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransactionsStatsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TransactionsStatsRequest;
        })();
    
        protocol.TransactionsStatsResponse = (function() {
    
            /**
             * Properties of a TransactionsStatsResponse.
             * @memberof protocol
             * @interface ITransactionsStatsResponse
             * @property {Long|null} [totalCount] TransactionsStatsResponse totalCount
             * @property {Long|null} [totalInCount] TransactionsStatsResponse totalInCount
             * @property {Long|null} [totalOutCount] TransactionsStatsResponse totalOutCount
             * @property {Object.<string,string>|null} [totalIncomes] TransactionsStatsResponse totalIncomes
             * @property {Object.<string,string>|null} [totalOutcomes] TransactionsStatsResponse totalOutcomes
             * @property {Object.<string,string>|null} [totalDeltas] TransactionsStatsResponse totalDeltas
             * @property {Object.<string,string>|null} [totalFees] TransactionsStatsResponse totalFees
             */
    
            /**
             * Constructs a new TransactionsStatsResponse.
             * @memberof protocol
             * @classdesc Represents a TransactionsStatsResponse.
             * @implements ITransactionsStatsResponse
             * @constructor
             * @param {protocol.ITransactionsStatsResponse=} [properties] Properties to set
             */
            function TransactionsStatsResponse(properties) {
                this.totalIncomes = {};
                this.totalOutcomes = {};
                this.totalDeltas = {};
                this.totalFees = {};
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TransactionsStatsResponse totalCount.
             * @member {Long} totalCount
             * @memberof protocol.TransactionsStatsResponse
             * @instance
             */
            TransactionsStatsResponse.prototype.totalCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TransactionsStatsResponse totalInCount.
             * @member {Long} totalInCount
             * @memberof protocol.TransactionsStatsResponse
             * @instance
             */
            TransactionsStatsResponse.prototype.totalInCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TransactionsStatsResponse totalOutCount.
             * @member {Long} totalOutCount
             * @memberof protocol.TransactionsStatsResponse
             * @instance
             */
            TransactionsStatsResponse.prototype.totalOutCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TransactionsStatsResponse totalIncomes.
             * @member {Object.<string,string>} totalIncomes
             * @memberof protocol.TransactionsStatsResponse
             * @instance
             */
            TransactionsStatsResponse.prototype.totalIncomes = $util.emptyObject;
    
            /**
             * TransactionsStatsResponse totalOutcomes.
             * @member {Object.<string,string>} totalOutcomes
             * @memberof protocol.TransactionsStatsResponse
             * @instance
             */
            TransactionsStatsResponse.prototype.totalOutcomes = $util.emptyObject;
    
            /**
             * TransactionsStatsResponse totalDeltas.
             * @member {Object.<string,string>} totalDeltas
             * @memberof protocol.TransactionsStatsResponse
             * @instance
             */
            TransactionsStatsResponse.prototype.totalDeltas = $util.emptyObject;
    
            /**
             * TransactionsStatsResponse totalFees.
             * @member {Object.<string,string>} totalFees
             * @memberof protocol.TransactionsStatsResponse
             * @instance
             */
            TransactionsStatsResponse.prototype.totalFees = $util.emptyObject;
    
            /**
             * Creates a new TransactionsStatsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.TransactionsStatsResponse
             * @static
             * @param {protocol.ITransactionsStatsResponse=} [properties] Properties to set
             * @returns {protocol.TransactionsStatsResponse} TransactionsStatsResponse instance
             */
            TransactionsStatsResponse.create = function create(properties) {
                return new TransactionsStatsResponse(properties);
            };
    
            /**
             * Encodes the specified TransactionsStatsResponse message. Does not implicitly {@link protocol.TransactionsStatsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.TransactionsStatsResponse
             * @static
             * @param {protocol.ITransactionsStatsResponse} message TransactionsStatsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionsStatsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.totalCount);
                if (message.totalInCount != null && Object.hasOwnProperty.call(message, "totalInCount"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalInCount);
                if (message.totalOutCount != null && Object.hasOwnProperty.call(message, "totalOutCount"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalOutCount);
                if (message.totalIncomes != null && Object.hasOwnProperty.call(message, "totalIncomes"))
                    for (var keys = Object.keys(message.totalIncomes), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.totalIncomes[keys[i]]).ldelim();
                if (message.totalOutcomes != null && Object.hasOwnProperty.call(message, "totalOutcomes"))
                    for (var keys = Object.keys(message.totalOutcomes), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 5, wireType 2 =*/42).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.totalOutcomes[keys[i]]).ldelim();
                if (message.totalDeltas != null && Object.hasOwnProperty.call(message, "totalDeltas"))
                    for (var keys = Object.keys(message.totalDeltas), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.totalDeltas[keys[i]]).ldelim();
                if (message.totalFees != null && Object.hasOwnProperty.call(message, "totalFees"))
                    for (var keys = Object.keys(message.totalFees), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 7, wireType 2 =*/58).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.totalFees[keys[i]]).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TransactionsStatsResponse message, length delimited. Does not implicitly {@link protocol.TransactionsStatsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TransactionsStatsResponse
             * @static
             * @param {protocol.ITransactionsStatsResponse} message TransactionsStatsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionsStatsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TransactionsStatsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TransactionsStatsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TransactionsStatsResponse} TransactionsStatsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionsStatsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TransactionsStatsResponse(), key;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.totalCount = reader.int64();
                        break;
                    case 2:
                        message.totalInCount = reader.int64();
                        break;
                    case 3:
                        message.totalOutCount = reader.int64();
                        break;
                    case 4:
                        reader.skip().pos++;
                        if (message.totalIncomes === $util.emptyObject)
                            message.totalIncomes = {};
                        key = reader.string();
                        reader.pos++;
                        message.totalIncomes[key] = reader.string();
                        break;
                    case 5:
                        reader.skip().pos++;
                        if (message.totalOutcomes === $util.emptyObject)
                            message.totalOutcomes = {};
                        key = reader.string();
                        reader.pos++;
                        message.totalOutcomes[key] = reader.string();
                        break;
                    case 6:
                        reader.skip().pos++;
                        if (message.totalDeltas === $util.emptyObject)
                            message.totalDeltas = {};
                        key = reader.string();
                        reader.pos++;
                        message.totalDeltas[key] = reader.string();
                        break;
                    case 7:
                        reader.skip().pos++;
                        if (message.totalFees === $util.emptyObject)
                            message.totalFees = {};
                        key = reader.string();
                        reader.pos++;
                        message.totalFees[key] = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TransactionsStatsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TransactionsStatsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TransactionsStatsResponse} TransactionsStatsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionsStatsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TransactionsStatsResponse message.
             * @function verify
             * @memberof protocol.TransactionsStatsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TransactionsStatsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (!$util.isInteger(message.totalCount) && !(message.totalCount && $util.isInteger(message.totalCount.low) && $util.isInteger(message.totalCount.high)))
                        return "totalCount: integer|Long expected";
                if (message.totalInCount != null && message.hasOwnProperty("totalInCount"))
                    if (!$util.isInteger(message.totalInCount) && !(message.totalInCount && $util.isInteger(message.totalInCount.low) && $util.isInteger(message.totalInCount.high)))
                        return "totalInCount: integer|Long expected";
                if (message.totalOutCount != null && message.hasOwnProperty("totalOutCount"))
                    if (!$util.isInteger(message.totalOutCount) && !(message.totalOutCount && $util.isInteger(message.totalOutCount.low) && $util.isInteger(message.totalOutCount.high)))
                        return "totalOutCount: integer|Long expected";
                if (message.totalIncomes != null && message.hasOwnProperty("totalIncomes")) {
                    if (!$util.isObject(message.totalIncomes))
                        return "totalIncomes: object expected";
                    var key = Object.keys(message.totalIncomes);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.totalIncomes[key[i]]))
                            return "totalIncomes: string{k:string} expected";
                }
                if (message.totalOutcomes != null && message.hasOwnProperty("totalOutcomes")) {
                    if (!$util.isObject(message.totalOutcomes))
                        return "totalOutcomes: object expected";
                    var key = Object.keys(message.totalOutcomes);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.totalOutcomes[key[i]]))
                            return "totalOutcomes: string{k:string} expected";
                }
                if (message.totalDeltas != null && message.hasOwnProperty("totalDeltas")) {
                    if (!$util.isObject(message.totalDeltas))
                        return "totalDeltas: object expected";
                    var key = Object.keys(message.totalDeltas);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.totalDeltas[key[i]]))
                            return "totalDeltas: string{k:string} expected";
                }
                if (message.totalFees != null && message.hasOwnProperty("totalFees")) {
                    if (!$util.isObject(message.totalFees))
                        return "totalFees: object expected";
                    var key = Object.keys(message.totalFees);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.totalFees[key[i]]))
                            return "totalFees: string{k:string} expected";
                }
                return null;
            };
    
            /**
             * Creates a TransactionsStatsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TransactionsStatsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TransactionsStatsResponse} TransactionsStatsResponse
             */
            TransactionsStatsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TransactionsStatsResponse)
                    return object;
                var message = new $root.protocol.TransactionsStatsResponse();
                if (object.totalCount != null)
                    if ($util.Long)
                        (message.totalCount = $util.Long.fromValue(object.totalCount)).unsigned = false;
                    else if (typeof object.totalCount === "string")
                        message.totalCount = parseInt(object.totalCount, 10);
                    else if (typeof object.totalCount === "number")
                        message.totalCount = object.totalCount;
                    else if (typeof object.totalCount === "object")
                        message.totalCount = new $util.LongBits(object.totalCount.low >>> 0, object.totalCount.high >>> 0).toNumber();
                if (object.totalInCount != null)
                    if ($util.Long)
                        (message.totalInCount = $util.Long.fromValue(object.totalInCount)).unsigned = false;
                    else if (typeof object.totalInCount === "string")
                        message.totalInCount = parseInt(object.totalInCount, 10);
                    else if (typeof object.totalInCount === "number")
                        message.totalInCount = object.totalInCount;
                    else if (typeof object.totalInCount === "object")
                        message.totalInCount = new $util.LongBits(object.totalInCount.low >>> 0, object.totalInCount.high >>> 0).toNumber();
                if (object.totalOutCount != null)
                    if ($util.Long)
                        (message.totalOutCount = $util.Long.fromValue(object.totalOutCount)).unsigned = false;
                    else if (typeof object.totalOutCount === "string")
                        message.totalOutCount = parseInt(object.totalOutCount, 10);
                    else if (typeof object.totalOutCount === "number")
                        message.totalOutCount = object.totalOutCount;
                    else if (typeof object.totalOutCount === "object")
                        message.totalOutCount = new $util.LongBits(object.totalOutCount.low >>> 0, object.totalOutCount.high >>> 0).toNumber();
                if (object.totalIncomes) {
                    if (typeof object.totalIncomes !== "object")
                        throw TypeError(".protocol.TransactionsStatsResponse.totalIncomes: object expected");
                    message.totalIncomes = {};
                    for (var keys = Object.keys(object.totalIncomes), i = 0; i < keys.length; ++i)
                        message.totalIncomes[keys[i]] = String(object.totalIncomes[keys[i]]);
                }
                if (object.totalOutcomes) {
                    if (typeof object.totalOutcomes !== "object")
                        throw TypeError(".protocol.TransactionsStatsResponse.totalOutcomes: object expected");
                    message.totalOutcomes = {};
                    for (var keys = Object.keys(object.totalOutcomes), i = 0; i < keys.length; ++i)
                        message.totalOutcomes[keys[i]] = String(object.totalOutcomes[keys[i]]);
                }
                if (object.totalDeltas) {
                    if (typeof object.totalDeltas !== "object")
                        throw TypeError(".protocol.TransactionsStatsResponse.totalDeltas: object expected");
                    message.totalDeltas = {};
                    for (var keys = Object.keys(object.totalDeltas), i = 0; i < keys.length; ++i)
                        message.totalDeltas[keys[i]] = String(object.totalDeltas[keys[i]]);
                }
                if (object.totalFees) {
                    if (typeof object.totalFees !== "object")
                        throw TypeError(".protocol.TransactionsStatsResponse.totalFees: object expected");
                    message.totalFees = {};
                    for (var keys = Object.keys(object.totalFees), i = 0; i < keys.length; ++i)
                        message.totalFees[keys[i]] = String(object.totalFees[keys[i]]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TransactionsStatsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TransactionsStatsResponse
             * @static
             * @param {protocol.TransactionsStatsResponse} message TransactionsStatsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransactionsStatsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.objects || options.defaults) {
                    object.totalIncomes = {};
                    object.totalOutcomes = {};
                    object.totalDeltas = {};
                    object.totalFees = {};
                }
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalCount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalInCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalInCount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalOutCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalOutCount = options.longs === String ? "0" : 0;
                }
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (typeof message.totalCount === "number")
                        object.totalCount = options.longs === String ? String(message.totalCount) : message.totalCount;
                    else
                        object.totalCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalCount) : options.longs === Number ? new $util.LongBits(message.totalCount.low >>> 0, message.totalCount.high >>> 0).toNumber() : message.totalCount;
                if (message.totalInCount != null && message.hasOwnProperty("totalInCount"))
                    if (typeof message.totalInCount === "number")
                        object.totalInCount = options.longs === String ? String(message.totalInCount) : message.totalInCount;
                    else
                        object.totalInCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalInCount) : options.longs === Number ? new $util.LongBits(message.totalInCount.low >>> 0, message.totalInCount.high >>> 0).toNumber() : message.totalInCount;
                if (message.totalOutCount != null && message.hasOwnProperty("totalOutCount"))
                    if (typeof message.totalOutCount === "number")
                        object.totalOutCount = options.longs === String ? String(message.totalOutCount) : message.totalOutCount;
                    else
                        object.totalOutCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalOutCount) : options.longs === Number ? new $util.LongBits(message.totalOutCount.low >>> 0, message.totalOutCount.high >>> 0).toNumber() : message.totalOutCount;
                var keys2;
                if (message.totalIncomes && (keys2 = Object.keys(message.totalIncomes)).length) {
                    object.totalIncomes = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.totalIncomes[keys2[j]] = message.totalIncomes[keys2[j]];
                }
                if (message.totalOutcomes && (keys2 = Object.keys(message.totalOutcomes)).length) {
                    object.totalOutcomes = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.totalOutcomes[keys2[j]] = message.totalOutcomes[keys2[j]];
                }
                if (message.totalDeltas && (keys2 = Object.keys(message.totalDeltas)).length) {
                    object.totalDeltas = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.totalDeltas[keys2[j]] = message.totalDeltas[keys2[j]];
                }
                if (message.totalFees && (keys2 = Object.keys(message.totalFees)).length) {
                    object.totalFees = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.totalFees[keys2[j]] = message.totalFees[keys2[j]];
                }
                return object;
            };
    
            /**
             * Converts this TransactionsStatsResponse to JSON.
             * @function toJSON
             * @memberof protocol.TransactionsStatsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransactionsStatsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TransactionsStatsResponse;
        })();
    
        protocol.ConfirmedClientTransactionsRequest = (function() {
    
            /**
             * Properties of a ConfirmedClientTransactionsRequest.
             * @memberof protocol
             * @interface IConfirmedClientTransactionsRequest
             * @property {string|null} [walletId] ConfirmedClientTransactionsRequest walletId
             * @property {string|null} [address] ConfirmedClientTransactionsRequest address
             * @property {number|null} [txId] ConfirmedClientTransactionsRequest txId
             * @property {string|null} [txHash] ConfirmedClientTransactionsRequest txHash
             * @property {protocol.Chain|null} [chain] ConfirmedClientTransactionsRequest chain
             * @property {protocol.Currency|null} [currency] ConfirmedClientTransactionsRequest currency
             * @property {protocol.TxDirection|null} [direction] ConfirmedClientTransactionsRequest direction
             * @property {protocol.TxType|null} [type] ConfirmedClientTransactionsRequest type
             * @property {Long|null} [lastUpdatedAt] ConfirmedClientTransactionsRequest lastUpdatedAt
             */
    
            /**
             * Constructs a new ConfirmedClientTransactionsRequest.
             * @memberof protocol
             * @classdesc Represents a ConfirmedClientTransactionsRequest.
             * @implements IConfirmedClientTransactionsRequest
             * @constructor
             * @param {protocol.IConfirmedClientTransactionsRequest=} [properties] Properties to set
             */
            function ConfirmedClientTransactionsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ConfirmedClientTransactionsRequest walletId.
             * @member {string} walletId
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @instance
             */
            ConfirmedClientTransactionsRequest.prototype.walletId = "";
    
            /**
             * ConfirmedClientTransactionsRequest address.
             * @member {string} address
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @instance
             */
            ConfirmedClientTransactionsRequest.prototype.address = "";
    
            /**
             * ConfirmedClientTransactionsRequest txId.
             * @member {number} txId
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @instance
             */
            ConfirmedClientTransactionsRequest.prototype.txId = 0;
    
            /**
             * ConfirmedClientTransactionsRequest txHash.
             * @member {string} txHash
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @instance
             */
            ConfirmedClientTransactionsRequest.prototype.txHash = "";
    
            /**
             * ConfirmedClientTransactionsRequest chain.
             * @member {protocol.Chain} chain
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @instance
             */
            ConfirmedClientTransactionsRequest.prototype.chain = 0;
    
            /**
             * ConfirmedClientTransactionsRequest currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @instance
             */
            ConfirmedClientTransactionsRequest.prototype.currency = 0;
    
            /**
             * ConfirmedClientTransactionsRequest direction.
             * @member {protocol.TxDirection} direction
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @instance
             */
            ConfirmedClientTransactionsRequest.prototype.direction = 0;
    
            /**
             * ConfirmedClientTransactionsRequest type.
             * @member {protocol.TxType} type
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @instance
             */
            ConfirmedClientTransactionsRequest.prototype.type = 0;
    
            /**
             * ConfirmedClientTransactionsRequest lastUpdatedAt.
             * @member {Long} lastUpdatedAt
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @instance
             */
            ConfirmedClientTransactionsRequest.prototype.lastUpdatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * ConfirmedClientTransactionsRequest entity.
             * @member {"walletId"|"address"|"txId"|"txHash"|undefined} entity
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @instance
             */
            Object.defineProperty(ConfirmedClientTransactionsRequest.prototype, "entity", {
                get: $util.oneOfGetter($oneOfFields = ["walletId", "address", "txId", "txHash"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new ConfirmedClientTransactionsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @static
             * @param {protocol.IConfirmedClientTransactionsRequest=} [properties] Properties to set
             * @returns {protocol.ConfirmedClientTransactionsRequest} ConfirmedClientTransactionsRequest instance
             */
            ConfirmedClientTransactionsRequest.create = function create(properties) {
                return new ConfirmedClientTransactionsRequest(properties);
            };
    
            /**
             * Encodes the specified ConfirmedClientTransactionsRequest message. Does not implicitly {@link protocol.ConfirmedClientTransactionsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @static
             * @param {protocol.IConfirmedClientTransactionsRequest} message ConfirmedClientTransactionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConfirmedClientTransactionsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.walletId != null && Object.hasOwnProperty.call(message, "walletId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.walletId);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
                if (message.txId != null && Object.hasOwnProperty.call(message, "txId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.txId);
                if (message.txHash != null && Object.hasOwnProperty.call(message, "txHash"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.txHash);
                if (message.chain != null && Object.hasOwnProperty.call(message, "chain"))
                    writer.uint32(/* id 20, wireType 0 =*/160).int32(message.chain);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 21, wireType 0 =*/168).int32(message.currency);
                if (message.direction != null && Object.hasOwnProperty.call(message, "direction"))
                    writer.uint32(/* id 22, wireType 0 =*/176).int32(message.direction);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 23, wireType 0 =*/184).int32(message.type);
                if (message.lastUpdatedAt != null && Object.hasOwnProperty.call(message, "lastUpdatedAt"))
                    writer.uint32(/* id 24, wireType 0 =*/192).int64(message.lastUpdatedAt);
                return writer;
            };
    
            /**
             * Encodes the specified ConfirmedClientTransactionsRequest message, length delimited. Does not implicitly {@link protocol.ConfirmedClientTransactionsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @static
             * @param {protocol.IConfirmedClientTransactionsRequest} message ConfirmedClientTransactionsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConfirmedClientTransactionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ConfirmedClientTransactionsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ConfirmedClientTransactionsRequest} ConfirmedClientTransactionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConfirmedClientTransactionsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ConfirmedClientTransactionsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.walletId = reader.string();
                        break;
                    case 2:
                        message.address = reader.string();
                        break;
                    case 3:
                        message.txId = reader.uint32();
                        break;
                    case 4:
                        message.txHash = reader.string();
                        break;
                    case 20:
                        message.chain = reader.int32();
                        break;
                    case 21:
                        message.currency = reader.int32();
                        break;
                    case 22:
                        message.direction = reader.int32();
                        break;
                    case 23:
                        message.type = reader.int32();
                        break;
                    case 24:
                        message.lastUpdatedAt = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ConfirmedClientTransactionsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ConfirmedClientTransactionsRequest} ConfirmedClientTransactionsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConfirmedClientTransactionsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ConfirmedClientTransactionsRequest message.
             * @function verify
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConfirmedClientTransactionsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.walletId != null && message.hasOwnProperty("walletId")) {
                    properties.entity = 1;
                    if (!$util.isString(message.walletId))
                        return "walletId: string expected";
                }
                if (message.address != null && message.hasOwnProperty("address")) {
                    if (properties.entity === 1)
                        return "entity: multiple values";
                    properties.entity = 1;
                    if (!$util.isString(message.address))
                        return "address: string expected";
                }
                if (message.txId != null && message.hasOwnProperty("txId")) {
                    if (properties.entity === 1)
                        return "entity: multiple values";
                    properties.entity = 1;
                    if (!$util.isInteger(message.txId))
                        return "txId: integer expected";
                }
                if (message.txHash != null && message.hasOwnProperty("txHash")) {
                    if (properties.entity === 1)
                        return "entity: multiple values";
                    properties.entity = 1;
                    if (!$util.isString(message.txHash))
                        return "txHash: string expected";
                }
                if (message.chain != null && message.hasOwnProperty("chain"))
                    switch (message.chain) {
                    default:
                        return "chain: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.direction != null && message.hasOwnProperty("direction"))
                    switch (message.direction) {
                    default:
                        return "direction: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.lastUpdatedAt != null && message.hasOwnProperty("lastUpdatedAt"))
                    if (!$util.isInteger(message.lastUpdatedAt) && !(message.lastUpdatedAt && $util.isInteger(message.lastUpdatedAt.low) && $util.isInteger(message.lastUpdatedAt.high)))
                        return "lastUpdatedAt: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a ConfirmedClientTransactionsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ConfirmedClientTransactionsRequest} ConfirmedClientTransactionsRequest
             */
            ConfirmedClientTransactionsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ConfirmedClientTransactionsRequest)
                    return object;
                var message = new $root.protocol.ConfirmedClientTransactionsRequest();
                if (object.walletId != null)
                    message.walletId = String(object.walletId);
                if (object.address != null)
                    message.address = String(object.address);
                if (object.txId != null)
                    message.txId = object.txId >>> 0;
                if (object.txHash != null)
                    message.txHash = String(object.txHash);
                switch (object.chain) {
                case "UNK_CHAIN":
                case 0:
                    message.chain = 0;
                    break;
                case "BTC_CHAIN":
                case 1:
                    message.chain = 1;
                    break;
                case "ETH_CHAIN":
                case 2:
                    message.chain = 2;
                    break;
                case "TRX_CHAIN":
                case 3:
                    message.chain = 3;
                    break;
                }
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                switch (object.direction) {
                case "UNK_TX_DIR":
                case 0:
                    message.direction = 0;
                    break;
                case "IN_TX_DIR":
                case 1:
                    message.direction = 1;
                    break;
                case "OUT_TX_DIR":
                case 2:
                    message.direction = 2;
                    break;
                }
                switch (object.type) {
                case "UNK_TX_TYPE":
                case 0:
                    message.type = 0;
                    break;
                case "REGULAR_TX_TYPE":
                case 1:
                    message.type = 1;
                    break;
                case "DEPOSIT_APPROVE_FEE_TX_TYPE":
                case 2:
                    message.type = 2;
                    break;
                case "APPROVE_TOKEN_TRANSFER_TX_TYPE":
                case 3:
                    message.type = 3;
                    break;
                }
                if (object.lastUpdatedAt != null)
                    if ($util.Long)
                        (message.lastUpdatedAt = $util.Long.fromValue(object.lastUpdatedAt)).unsigned = false;
                    else if (typeof object.lastUpdatedAt === "string")
                        message.lastUpdatedAt = parseInt(object.lastUpdatedAt, 10);
                    else if (typeof object.lastUpdatedAt === "number")
                        message.lastUpdatedAt = object.lastUpdatedAt;
                    else if (typeof object.lastUpdatedAt === "object")
                        message.lastUpdatedAt = new $util.LongBits(object.lastUpdatedAt.low >>> 0, object.lastUpdatedAt.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a ConfirmedClientTransactionsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @static
             * @param {protocol.ConfirmedClientTransactionsRequest} message ConfirmedClientTransactionsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConfirmedClientTransactionsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.chain = options.enums === String ? "UNK_CHAIN" : 0;
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.direction = options.enums === String ? "UNK_TX_DIR" : 0;
                    object.type = options.enums === String ? "UNK_TX_TYPE" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.lastUpdatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lastUpdatedAt = options.longs === String ? "0" : 0;
                }
                if (message.walletId != null && message.hasOwnProperty("walletId")) {
                    object.walletId = message.walletId;
                    if (options.oneofs)
                        object.entity = "walletId";
                }
                if (message.address != null && message.hasOwnProperty("address")) {
                    object.address = message.address;
                    if (options.oneofs)
                        object.entity = "address";
                }
                if (message.txId != null && message.hasOwnProperty("txId")) {
                    object.txId = message.txId;
                    if (options.oneofs)
                        object.entity = "txId";
                }
                if (message.txHash != null && message.hasOwnProperty("txHash")) {
                    object.txHash = message.txHash;
                    if (options.oneofs)
                        object.entity = "txHash";
                }
                if (message.chain != null && message.hasOwnProperty("chain"))
                    object.chain = options.enums === String ? $root.protocol.Chain[message.chain] : message.chain;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.direction != null && message.hasOwnProperty("direction"))
                    object.direction = options.enums === String ? $root.protocol.TxDirection[message.direction] : message.direction;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.protocol.TxType[message.type] : message.type;
                if (message.lastUpdatedAt != null && message.hasOwnProperty("lastUpdatedAt"))
                    if (typeof message.lastUpdatedAt === "number")
                        object.lastUpdatedAt = options.longs === String ? String(message.lastUpdatedAt) : message.lastUpdatedAt;
                    else
                        object.lastUpdatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdatedAt) : options.longs === Number ? new $util.LongBits(message.lastUpdatedAt.low >>> 0, message.lastUpdatedAt.high >>> 0).toNumber() : message.lastUpdatedAt;
                return object;
            };
    
            /**
             * Converts this ConfirmedClientTransactionsRequest to JSON.
             * @function toJSON
             * @memberof protocol.ConfirmedClientTransactionsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConfirmedClientTransactionsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ConfirmedClientTransactionsRequest;
        })();
    
        protocol.ConfirmedClientTransactionsResponse = (function() {
    
            /**
             * Properties of a ConfirmedClientTransactionsResponse.
             * @memberof protocol
             * @interface IConfirmedClientTransactionsResponse
             * @property {Array.<protocol.ITxRecord>|null} [transactions] ConfirmedClientTransactionsResponse transactions
             */
    
            /**
             * Constructs a new ConfirmedClientTransactionsResponse.
             * @memberof protocol
             * @classdesc Represents a ConfirmedClientTransactionsResponse.
             * @implements IConfirmedClientTransactionsResponse
             * @constructor
             * @param {protocol.IConfirmedClientTransactionsResponse=} [properties] Properties to set
             */
            function ConfirmedClientTransactionsResponse(properties) {
                this.transactions = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ConfirmedClientTransactionsResponse transactions.
             * @member {Array.<protocol.ITxRecord>} transactions
             * @memberof protocol.ConfirmedClientTransactionsResponse
             * @instance
             */
            ConfirmedClientTransactionsResponse.prototype.transactions = $util.emptyArray;
    
            /**
             * Creates a new ConfirmedClientTransactionsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ConfirmedClientTransactionsResponse
             * @static
             * @param {protocol.IConfirmedClientTransactionsResponse=} [properties] Properties to set
             * @returns {protocol.ConfirmedClientTransactionsResponse} ConfirmedClientTransactionsResponse instance
             */
            ConfirmedClientTransactionsResponse.create = function create(properties) {
                return new ConfirmedClientTransactionsResponse(properties);
            };
    
            /**
             * Encodes the specified ConfirmedClientTransactionsResponse message. Does not implicitly {@link protocol.ConfirmedClientTransactionsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ConfirmedClientTransactionsResponse
             * @static
             * @param {protocol.IConfirmedClientTransactionsResponse} message ConfirmedClientTransactionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConfirmedClientTransactionsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.transactions != null && message.transactions.length)
                    for (var i = 0; i < message.transactions.length; ++i)
                        $root.protocol.TxRecord.encode(message.transactions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ConfirmedClientTransactionsResponse message, length delimited. Does not implicitly {@link protocol.ConfirmedClientTransactionsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ConfirmedClientTransactionsResponse
             * @static
             * @param {protocol.IConfirmedClientTransactionsResponse} message ConfirmedClientTransactionsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConfirmedClientTransactionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ConfirmedClientTransactionsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ConfirmedClientTransactionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ConfirmedClientTransactionsResponse} ConfirmedClientTransactionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConfirmedClientTransactionsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ConfirmedClientTransactionsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.transactions && message.transactions.length))
                            message.transactions = [];
                        message.transactions.push($root.protocol.TxRecord.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ConfirmedClientTransactionsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ConfirmedClientTransactionsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ConfirmedClientTransactionsResponse} ConfirmedClientTransactionsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConfirmedClientTransactionsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ConfirmedClientTransactionsResponse message.
             * @function verify
             * @memberof protocol.ConfirmedClientTransactionsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConfirmedClientTransactionsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.transactions != null && message.hasOwnProperty("transactions")) {
                    if (!Array.isArray(message.transactions))
                        return "transactions: array expected";
                    for (var i = 0; i < message.transactions.length; ++i) {
                        var error = $root.protocol.TxRecord.verify(message.transactions[i]);
                        if (error)
                            return "transactions." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ConfirmedClientTransactionsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ConfirmedClientTransactionsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ConfirmedClientTransactionsResponse} ConfirmedClientTransactionsResponse
             */
            ConfirmedClientTransactionsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ConfirmedClientTransactionsResponse)
                    return object;
                var message = new $root.protocol.ConfirmedClientTransactionsResponse();
                if (object.transactions) {
                    if (!Array.isArray(object.transactions))
                        throw TypeError(".protocol.ConfirmedClientTransactionsResponse.transactions: array expected");
                    message.transactions = [];
                    for (var i = 0; i < object.transactions.length; ++i) {
                        if (typeof object.transactions[i] !== "object")
                            throw TypeError(".protocol.ConfirmedClientTransactionsResponse.transactions: object expected");
                        message.transactions[i] = $root.protocol.TxRecord.fromObject(object.transactions[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ConfirmedClientTransactionsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ConfirmedClientTransactionsResponse
             * @static
             * @param {protocol.ConfirmedClientTransactionsResponse} message ConfirmedClientTransactionsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConfirmedClientTransactionsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.transactions = [];
                if (message.transactions && message.transactions.length) {
                    object.transactions = [];
                    for (var j = 0; j < message.transactions.length; ++j)
                        object.transactions[j] = $root.protocol.TxRecord.toObject(message.transactions[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ConfirmedClientTransactionsResponse to JSON.
             * @function toJSON
             * @memberof protocol.ConfirmedClientTransactionsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConfirmedClientTransactionsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ConfirmedClientTransactionsResponse;
        })();
    
        protocol.TxMeta = (function() {
    
            /**
             * Properties of a TxMeta.
             * @memberof protocol
             * @interface ITxMeta
             * @property {string|null} [fee] TxMeta fee
             * @property {Object.<string,string>|null} [amounts] TxMeta amounts
             * @property {Object.<string,string>|null} [fees] TxMeta fees
             * @property {Object.<string,string>|null} [rates] TxMeta rates
             */
    
            /**
             * Constructs a new TxMeta.
             * @memberof protocol
             * @classdesc Represents a TxMeta.
             * @implements ITxMeta
             * @constructor
             * @param {protocol.ITxMeta=} [properties] Properties to set
             */
            function TxMeta(properties) {
                this.amounts = {};
                this.fees = {};
                this.rates = {};
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TxMeta fee.
             * @member {string} fee
             * @memberof protocol.TxMeta
             * @instance
             */
            TxMeta.prototype.fee = "";
    
            /**
             * TxMeta amounts.
             * @member {Object.<string,string>} amounts
             * @memberof protocol.TxMeta
             * @instance
             */
            TxMeta.prototype.amounts = $util.emptyObject;
    
            /**
             * TxMeta fees.
             * @member {Object.<string,string>} fees
             * @memberof protocol.TxMeta
             * @instance
             */
            TxMeta.prototype.fees = $util.emptyObject;
    
            /**
             * TxMeta rates.
             * @member {Object.<string,string>} rates
             * @memberof protocol.TxMeta
             * @instance
             */
            TxMeta.prototype.rates = $util.emptyObject;
    
            /**
             * Creates a new TxMeta instance using the specified properties.
             * @function create
             * @memberof protocol.TxMeta
             * @static
             * @param {protocol.ITxMeta=} [properties] Properties to set
             * @returns {protocol.TxMeta} TxMeta instance
             */
            TxMeta.create = function create(properties) {
                return new TxMeta(properties);
            };
    
            /**
             * Encodes the specified TxMeta message. Does not implicitly {@link protocol.TxMeta.verify|verify} messages.
             * @function encode
             * @memberof protocol.TxMeta
             * @static
             * @param {protocol.ITxMeta} message TxMeta message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TxMeta.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.fee != null && Object.hasOwnProperty.call(message, "fee"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.fee);
                if (message.amounts != null && Object.hasOwnProperty.call(message, "amounts"))
                    for (var keys = Object.keys(message.amounts), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.amounts[keys[i]]).ldelim();
                if (message.fees != null && Object.hasOwnProperty.call(message, "fees"))
                    for (var keys = Object.keys(message.fees), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.fees[keys[i]]).ldelim();
                if (message.rates != null && Object.hasOwnProperty.call(message, "rates"))
                    for (var keys = Object.keys(message.rates), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.rates[keys[i]]).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TxMeta message, length delimited. Does not implicitly {@link protocol.TxMeta.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TxMeta
             * @static
             * @param {protocol.ITxMeta} message TxMeta message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TxMeta.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TxMeta message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TxMeta
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TxMeta} TxMeta
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TxMeta.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TxMeta(), key;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.fee = reader.string();
                        break;
                    case 2:
                        reader.skip().pos++;
                        if (message.amounts === $util.emptyObject)
                            message.amounts = {};
                        key = reader.string();
                        reader.pos++;
                        message.amounts[key] = reader.string();
                        break;
                    case 3:
                        reader.skip().pos++;
                        if (message.fees === $util.emptyObject)
                            message.fees = {};
                        key = reader.string();
                        reader.pos++;
                        message.fees[key] = reader.string();
                        break;
                    case 4:
                        reader.skip().pos++;
                        if (message.rates === $util.emptyObject)
                            message.rates = {};
                        key = reader.string();
                        reader.pos++;
                        message.rates[key] = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TxMeta message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TxMeta
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TxMeta} TxMeta
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TxMeta.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TxMeta message.
             * @function verify
             * @memberof protocol.TxMeta
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TxMeta.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.fee != null && message.hasOwnProperty("fee"))
                    if (!$util.isString(message.fee))
                        return "fee: string expected";
                if (message.amounts != null && message.hasOwnProperty("amounts")) {
                    if (!$util.isObject(message.amounts))
                        return "amounts: object expected";
                    var key = Object.keys(message.amounts);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.amounts[key[i]]))
                            return "amounts: string{k:string} expected";
                }
                if (message.fees != null && message.hasOwnProperty("fees")) {
                    if (!$util.isObject(message.fees))
                        return "fees: object expected";
                    var key = Object.keys(message.fees);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.fees[key[i]]))
                            return "fees: string{k:string} expected";
                }
                if (message.rates != null && message.hasOwnProperty("rates")) {
                    if (!$util.isObject(message.rates))
                        return "rates: object expected";
                    var key = Object.keys(message.rates);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.rates[key[i]]))
                            return "rates: string{k:string} expected";
                }
                return null;
            };
    
            /**
             * Creates a TxMeta message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TxMeta
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TxMeta} TxMeta
             */
            TxMeta.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TxMeta)
                    return object;
                var message = new $root.protocol.TxMeta();
                if (object.fee != null)
                    message.fee = String(object.fee);
                if (object.amounts) {
                    if (typeof object.amounts !== "object")
                        throw TypeError(".protocol.TxMeta.amounts: object expected");
                    message.amounts = {};
                    for (var keys = Object.keys(object.amounts), i = 0; i < keys.length; ++i)
                        message.amounts[keys[i]] = String(object.amounts[keys[i]]);
                }
                if (object.fees) {
                    if (typeof object.fees !== "object")
                        throw TypeError(".protocol.TxMeta.fees: object expected");
                    message.fees = {};
                    for (var keys = Object.keys(object.fees), i = 0; i < keys.length; ++i)
                        message.fees[keys[i]] = String(object.fees[keys[i]]);
                }
                if (object.rates) {
                    if (typeof object.rates !== "object")
                        throw TypeError(".protocol.TxMeta.rates: object expected");
                    message.rates = {};
                    for (var keys = Object.keys(object.rates), i = 0; i < keys.length; ++i)
                        message.rates[keys[i]] = String(object.rates[keys[i]]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TxMeta message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TxMeta
             * @static
             * @param {protocol.TxMeta} message TxMeta
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TxMeta.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.objects || options.defaults) {
                    object.amounts = {};
                    object.fees = {};
                    object.rates = {};
                }
                if (options.defaults)
                    object.fee = "";
                if (message.fee != null && message.hasOwnProperty("fee"))
                    object.fee = message.fee;
                var keys2;
                if (message.amounts && (keys2 = Object.keys(message.amounts)).length) {
                    object.amounts = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.amounts[keys2[j]] = message.amounts[keys2[j]];
                }
                if (message.fees && (keys2 = Object.keys(message.fees)).length) {
                    object.fees = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.fees[keys2[j]] = message.fees[keys2[j]];
                }
                if (message.rates && (keys2 = Object.keys(message.rates)).length) {
                    object.rates = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.rates[keys2[j]] = message.rates[keys2[j]];
                }
                return object;
            };
    
            /**
             * Converts this TxMeta to JSON.
             * @function toJSON
             * @memberof protocol.TxMeta
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TxMeta.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TxMeta;
        })();
    
        protocol.TxRecord = (function() {
    
            /**
             * Properties of a TxRecord.
             * @memberof protocol
             * @interface ITxRecord
             * @property {number|null} [id] TxRecord id
             * @property {string|null} [hash] TxRecord hash
             * @property {string|null} [amount] TxRecord amount
             * @property {string|null} [address] TxRecord address
             * @property {Long|null} [blockHeight] TxRecord blockHeight
             * @property {protocol.Currency|null} [currency] TxRecord currency
             * @property {protocol.TxStatus|null} [status] TxRecord status
             * @property {Long|null} [createdAt] TxRecord createdAt
             * @property {Long|null} [updatedAt] TxRecord updatedAt
             * @property {Long|null} [confirmations] TxRecord confirmations
             * @property {protocol.TxDirection|null} [direction] TxRecord direction
             * @property {protocol.TxType|null} [type] TxRecord type
             * @property {protocol.Chain|null} [chain] TxRecord chain
             * @property {protocol.ITxMeta|null} [meta] TxRecord meta
             * @property {number|null} [payoutRequestId] TxRecord payoutRequestId
             * @property {string|null} [operationId] TxRecord operationId
             * @property {string|null} [clientPeerName] TxRecord clientPeerName
             * @property {Object.<string,string>|null} [customAmounts] TxRecord customAmounts
             * @property {Array.<string>|null} [from] TxRecord from
             * @property {Array.<string>|null} [to] TxRecord to
             * @property {Array.<string>|null} [addressTags] TxRecord addressTags
             * @property {Array.<string>|null} [payoutTags] TxRecord payoutTags
             * @property {Array.<string>|null} [payoutRequestTags] TxRecord payoutRequestTags
             * @property {Array.<string>|null} [payoutAddresses] TxRecord payoutAddresses
             * @property {protocol.IBitcoinTxDetails|null} [btcDetails] TxRecord btcDetails
             * @property {protocol.IEthereumTxDetails|null} [ethDetails] TxRecord ethDetails
             * @property {protocol.ITronTxDetails|null} [trxDetails] TxRecord trxDetails
             */
    
            /**
             * Constructs a new TxRecord.
             * @memberof protocol
             * @classdesc Represents a TxRecord.
             * @implements ITxRecord
             * @constructor
             * @param {protocol.ITxRecord=} [properties] Properties to set
             */
            function TxRecord(properties) {
                this.customAmounts = {};
                this.from = [];
                this.to = [];
                this.addressTags = [];
                this.payoutTags = [];
                this.payoutRequestTags = [];
                this.payoutAddresses = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TxRecord id.
             * @member {number} id
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.id = 0;
    
            /**
             * TxRecord hash.
             * @member {string} hash
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.hash = "";
    
            /**
             * TxRecord amount.
             * @member {string} amount
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.amount = "";
    
            /**
             * TxRecord address.
             * @member {string} address
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.address = "";
    
            /**
             * TxRecord blockHeight.
             * @member {Long} blockHeight
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.blockHeight = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * TxRecord currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.currency = 0;
    
            /**
             * TxRecord status.
             * @member {protocol.TxStatus} status
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.status = 0;
    
            /**
             * TxRecord createdAt.
             * @member {Long} createdAt
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TxRecord updatedAt.
             * @member {Long} updatedAt
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TxRecord confirmations.
             * @member {Long} confirmations
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.confirmations = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * TxRecord direction.
             * @member {protocol.TxDirection} direction
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.direction = 0;
    
            /**
             * TxRecord type.
             * @member {protocol.TxType} type
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.type = 0;
    
            /**
             * TxRecord chain.
             * @member {protocol.Chain} chain
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.chain = 0;
    
            /**
             * TxRecord meta.
             * @member {protocol.ITxMeta|null|undefined} meta
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.meta = null;
    
            /**
             * TxRecord payoutRequestId.
             * @member {number} payoutRequestId
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.payoutRequestId = 0;
    
            /**
             * TxRecord operationId.
             * @member {string} operationId
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.operationId = "";
    
            /**
             * TxRecord clientPeerName.
             * @member {string} clientPeerName
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.clientPeerName = "";
    
            /**
             * TxRecord customAmounts.
             * @member {Object.<string,string>} customAmounts
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.customAmounts = $util.emptyObject;
    
            /**
             * TxRecord from.
             * @member {Array.<string>} from
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.from = $util.emptyArray;
    
            /**
             * TxRecord to.
             * @member {Array.<string>} to
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.to = $util.emptyArray;
    
            /**
             * TxRecord addressTags.
             * @member {Array.<string>} addressTags
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.addressTags = $util.emptyArray;
    
            /**
             * TxRecord payoutTags.
             * @member {Array.<string>} payoutTags
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.payoutTags = $util.emptyArray;
    
            /**
             * TxRecord payoutRequestTags.
             * @member {Array.<string>} payoutRequestTags
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.payoutRequestTags = $util.emptyArray;
    
            /**
             * TxRecord payoutAddresses.
             * @member {Array.<string>} payoutAddresses
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.payoutAddresses = $util.emptyArray;
    
            /**
             * TxRecord btcDetails.
             * @member {protocol.IBitcoinTxDetails|null|undefined} btcDetails
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.btcDetails = null;
    
            /**
             * TxRecord ethDetails.
             * @member {protocol.IEthereumTxDetails|null|undefined} ethDetails
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.ethDetails = null;
    
            /**
             * TxRecord trxDetails.
             * @member {protocol.ITronTxDetails|null|undefined} trxDetails
             * @memberof protocol.TxRecord
             * @instance
             */
            TxRecord.prototype.trxDetails = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * TxRecord chainDetails.
             * @member {"btcDetails"|"ethDetails"|"trxDetails"|undefined} chainDetails
             * @memberof protocol.TxRecord
             * @instance
             */
            Object.defineProperty(TxRecord.prototype, "chainDetails", {
                get: $util.oneOfGetter($oneOfFields = ["btcDetails", "ethDetails", "trxDetails"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new TxRecord instance using the specified properties.
             * @function create
             * @memberof protocol.TxRecord
             * @static
             * @param {protocol.ITxRecord=} [properties] Properties to set
             * @returns {protocol.TxRecord} TxRecord instance
             */
            TxRecord.create = function create(properties) {
                return new TxRecord(properties);
            };
    
            /**
             * Encodes the specified TxRecord message. Does not implicitly {@link protocol.TxRecord.verify|verify} messages.
             * @function encode
             * @memberof protocol.TxRecord
             * @static
             * @param {protocol.ITxRecord} message TxRecord message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TxRecord.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.hash);
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.amount);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.address);
                if (message.blockHeight != null && Object.hasOwnProperty.call(message, "blockHeight"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.blockHeight);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.currency);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.status);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int64(message.createdAt);
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int64(message.updatedAt);
                if (message.confirmations != null && Object.hasOwnProperty.call(message, "confirmations"))
                    writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.confirmations);
                if (message.direction != null && Object.hasOwnProperty.call(message, "direction"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int32(message.direction);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 12, wireType 0 =*/96).int32(message.type);
                if (message.chain != null && Object.hasOwnProperty.call(message, "chain"))
                    writer.uint32(/* id 13, wireType 0 =*/104).int32(message.chain);
                if (message.meta != null && Object.hasOwnProperty.call(message, "meta"))
                    $root.protocol.TxMeta.encode(message.meta, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.payoutRequestId != null && Object.hasOwnProperty.call(message, "payoutRequestId"))
                    writer.uint32(/* id 15, wireType 0 =*/120).uint32(message.payoutRequestId);
                if (message.operationId != null && Object.hasOwnProperty.call(message, "operationId"))
                    writer.uint32(/* id 16, wireType 2 =*/130).string(message.operationId);
                if (message.clientPeerName != null && Object.hasOwnProperty.call(message, "clientPeerName"))
                    writer.uint32(/* id 17, wireType 2 =*/138).string(message.clientPeerName);
                if (message.customAmounts != null && Object.hasOwnProperty.call(message, "customAmounts"))
                    for (var keys = Object.keys(message.customAmounts), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 18, wireType 2 =*/146).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.customAmounts[keys[i]]).ldelim();
                if (message.from != null && message.from.length)
                    for (var i = 0; i < message.from.length; ++i)
                        writer.uint32(/* id 19, wireType 2 =*/154).string(message.from[i]);
                if (message.to != null && message.to.length)
                    for (var i = 0; i < message.to.length; ++i)
                        writer.uint32(/* id 20, wireType 2 =*/162).string(message.to[i]);
                if (message.addressTags != null && message.addressTags.length)
                    for (var i = 0; i < message.addressTags.length; ++i)
                        writer.uint32(/* id 21, wireType 2 =*/170).string(message.addressTags[i]);
                if (message.payoutTags != null && message.payoutTags.length)
                    for (var i = 0; i < message.payoutTags.length; ++i)
                        writer.uint32(/* id 22, wireType 2 =*/178).string(message.payoutTags[i]);
                if (message.payoutRequestTags != null && message.payoutRequestTags.length)
                    for (var i = 0; i < message.payoutRequestTags.length; ++i)
                        writer.uint32(/* id 23, wireType 2 =*/186).string(message.payoutRequestTags[i]);
                if (message.payoutAddresses != null && message.payoutAddresses.length)
                    for (var i = 0; i < message.payoutAddresses.length; ++i)
                        writer.uint32(/* id 24, wireType 2 =*/194).string(message.payoutAddresses[i]);
                if (message.btcDetails != null && Object.hasOwnProperty.call(message, "btcDetails"))
                    $root.protocol.BitcoinTxDetails.encode(message.btcDetails, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
                if (message.ethDetails != null && Object.hasOwnProperty.call(message, "ethDetails"))
                    $root.protocol.EthereumTxDetails.encode(message.ethDetails, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
                if (message.trxDetails != null && Object.hasOwnProperty.call(message, "trxDetails"))
                    $root.protocol.TronTxDetails.encode(message.trxDetails, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TxRecord message, length delimited. Does not implicitly {@link protocol.TxRecord.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TxRecord
             * @static
             * @param {protocol.ITxRecord} message TxRecord message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TxRecord.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TxRecord message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TxRecord
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TxRecord} TxRecord
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TxRecord.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TxRecord(), key;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint32();
                        break;
                    case 2:
                        message.hash = reader.string();
                        break;
                    case 3:
                        message.amount = reader.string();
                        break;
                    case 4:
                        message.address = reader.string();
                        break;
                    case 5:
                        message.blockHeight = reader.uint64();
                        break;
                    case 6:
                        message.currency = reader.int32();
                        break;
                    case 7:
                        message.status = reader.int32();
                        break;
                    case 8:
                        message.createdAt = reader.int64();
                        break;
                    case 9:
                        message.updatedAt = reader.int64();
                        break;
                    case 10:
                        message.confirmations = reader.uint64();
                        break;
                    case 11:
                        message.direction = reader.int32();
                        break;
                    case 12:
                        message.type = reader.int32();
                        break;
                    case 13:
                        message.chain = reader.int32();
                        break;
                    case 14:
                        message.meta = $root.protocol.TxMeta.decode(reader, reader.uint32());
                        break;
                    case 15:
                        message.payoutRequestId = reader.uint32();
                        break;
                    case 16:
                        message.operationId = reader.string();
                        break;
                    case 17:
                        message.clientPeerName = reader.string();
                        break;
                    case 18:
                        reader.skip().pos++;
                        if (message.customAmounts === $util.emptyObject)
                            message.customAmounts = {};
                        key = reader.string();
                        reader.pos++;
                        message.customAmounts[key] = reader.string();
                        break;
                    case 19:
                        if (!(message.from && message.from.length))
                            message.from = [];
                        message.from.push(reader.string());
                        break;
                    case 20:
                        if (!(message.to && message.to.length))
                            message.to = [];
                        message.to.push(reader.string());
                        break;
                    case 21:
                        if (!(message.addressTags && message.addressTags.length))
                            message.addressTags = [];
                        message.addressTags.push(reader.string());
                        break;
                    case 22:
                        if (!(message.payoutTags && message.payoutTags.length))
                            message.payoutTags = [];
                        message.payoutTags.push(reader.string());
                        break;
                    case 23:
                        if (!(message.payoutRequestTags && message.payoutRequestTags.length))
                            message.payoutRequestTags = [];
                        message.payoutRequestTags.push(reader.string());
                        break;
                    case 24:
                        if (!(message.payoutAddresses && message.payoutAddresses.length))
                            message.payoutAddresses = [];
                        message.payoutAddresses.push(reader.string());
                        break;
                    case 50:
                        message.btcDetails = $root.protocol.BitcoinTxDetails.decode(reader, reader.uint32());
                        break;
                    case 51:
                        message.ethDetails = $root.protocol.EthereumTxDetails.decode(reader, reader.uint32());
                        break;
                    case 52:
                        message.trxDetails = $root.protocol.TronTxDetails.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TxRecord message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TxRecord
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TxRecord} TxRecord
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TxRecord.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TxRecord message.
             * @function verify
             * @memberof protocol.TxRecord
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TxRecord.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.hash != null && message.hasOwnProperty("hash"))
                    if (!$util.isString(message.hash))
                        return "hash: string expected";
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (!$util.isString(message.amount))
                        return "amount: string expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                if (message.blockHeight != null && message.hasOwnProperty("blockHeight"))
                    if (!$util.isInteger(message.blockHeight) && !(message.blockHeight && $util.isInteger(message.blockHeight.low) && $util.isInteger(message.blockHeight.high)))
                        return "blockHeight: integer|Long expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                        return "createdAt: integer|Long expected";
                if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                    if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                        return "updatedAt: integer|Long expected";
                if (message.confirmations != null && message.hasOwnProperty("confirmations"))
                    if (!$util.isInteger(message.confirmations) && !(message.confirmations && $util.isInteger(message.confirmations.low) && $util.isInteger(message.confirmations.high)))
                        return "confirmations: integer|Long expected";
                if (message.direction != null && message.hasOwnProperty("direction"))
                    switch (message.direction) {
                    default:
                        return "direction: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.chain != null && message.hasOwnProperty("chain"))
                    switch (message.chain) {
                    default:
                        return "chain: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.meta != null && message.hasOwnProperty("meta")) {
                    var error = $root.protocol.TxMeta.verify(message.meta);
                    if (error)
                        return "meta." + error;
                }
                if (message.payoutRequestId != null && message.hasOwnProperty("payoutRequestId"))
                    if (!$util.isInteger(message.payoutRequestId))
                        return "payoutRequestId: integer expected";
                if (message.operationId != null && message.hasOwnProperty("operationId"))
                    if (!$util.isString(message.operationId))
                        return "operationId: string expected";
                if (message.clientPeerName != null && message.hasOwnProperty("clientPeerName"))
                    if (!$util.isString(message.clientPeerName))
                        return "clientPeerName: string expected";
                if (message.customAmounts != null && message.hasOwnProperty("customAmounts")) {
                    if (!$util.isObject(message.customAmounts))
                        return "customAmounts: object expected";
                    var key = Object.keys(message.customAmounts);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.customAmounts[key[i]]))
                            return "customAmounts: string{k:string} expected";
                }
                if (message.from != null && message.hasOwnProperty("from")) {
                    if (!Array.isArray(message.from))
                        return "from: array expected";
                    for (var i = 0; i < message.from.length; ++i)
                        if (!$util.isString(message.from[i]))
                            return "from: string[] expected";
                }
                if (message.to != null && message.hasOwnProperty("to")) {
                    if (!Array.isArray(message.to))
                        return "to: array expected";
                    for (var i = 0; i < message.to.length; ++i)
                        if (!$util.isString(message.to[i]))
                            return "to: string[] expected";
                }
                if (message.addressTags != null && message.hasOwnProperty("addressTags")) {
                    if (!Array.isArray(message.addressTags))
                        return "addressTags: array expected";
                    for (var i = 0; i < message.addressTags.length; ++i)
                        if (!$util.isString(message.addressTags[i]))
                            return "addressTags: string[] expected";
                }
                if (message.payoutTags != null && message.hasOwnProperty("payoutTags")) {
                    if (!Array.isArray(message.payoutTags))
                        return "payoutTags: array expected";
                    for (var i = 0; i < message.payoutTags.length; ++i)
                        if (!$util.isString(message.payoutTags[i]))
                            return "payoutTags: string[] expected";
                }
                if (message.payoutRequestTags != null && message.hasOwnProperty("payoutRequestTags")) {
                    if (!Array.isArray(message.payoutRequestTags))
                        return "payoutRequestTags: array expected";
                    for (var i = 0; i < message.payoutRequestTags.length; ++i)
                        if (!$util.isString(message.payoutRequestTags[i]))
                            return "payoutRequestTags: string[] expected";
                }
                if (message.payoutAddresses != null && message.hasOwnProperty("payoutAddresses")) {
                    if (!Array.isArray(message.payoutAddresses))
                        return "payoutAddresses: array expected";
                    for (var i = 0; i < message.payoutAddresses.length; ++i)
                        if (!$util.isString(message.payoutAddresses[i]))
                            return "payoutAddresses: string[] expected";
                }
                if (message.btcDetails != null && message.hasOwnProperty("btcDetails")) {
                    properties.chainDetails = 1;
                    {
                        var error = $root.protocol.BitcoinTxDetails.verify(message.btcDetails);
                        if (error)
                            return "btcDetails." + error;
                    }
                }
                if (message.ethDetails != null && message.hasOwnProperty("ethDetails")) {
                    if (properties.chainDetails === 1)
                        return "chainDetails: multiple values";
                    properties.chainDetails = 1;
                    {
                        var error = $root.protocol.EthereumTxDetails.verify(message.ethDetails);
                        if (error)
                            return "ethDetails." + error;
                    }
                }
                if (message.trxDetails != null && message.hasOwnProperty("trxDetails")) {
                    if (properties.chainDetails === 1)
                        return "chainDetails: multiple values";
                    properties.chainDetails = 1;
                    {
                        var error = $root.protocol.TronTxDetails.verify(message.trxDetails);
                        if (error)
                            return "trxDetails." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a TxRecord message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TxRecord
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TxRecord} TxRecord
             */
            TxRecord.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TxRecord)
                    return object;
                var message = new $root.protocol.TxRecord();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.hash != null)
                    message.hash = String(object.hash);
                if (object.amount != null)
                    message.amount = String(object.amount);
                if (object.address != null)
                    message.address = String(object.address);
                if (object.blockHeight != null)
                    if ($util.Long)
                        (message.blockHeight = $util.Long.fromValue(object.blockHeight)).unsigned = true;
                    else if (typeof object.blockHeight === "string")
                        message.blockHeight = parseInt(object.blockHeight, 10);
                    else if (typeof object.blockHeight === "number")
                        message.blockHeight = object.blockHeight;
                    else if (typeof object.blockHeight === "object")
                        message.blockHeight = new $util.LongBits(object.blockHeight.low >>> 0, object.blockHeight.high >>> 0).toNumber(true);
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                switch (object.status) {
                case "UNK_TX_STATUS":
                case 0:
                    message.status = 0;
                    break;
                case "PENDING_TX_STATUS":
                case 1:
                    message.status = 1;
                    break;
                case "CONFIRMED_TX_STATUS":
                case 2:
                    message.status = 2;
                    break;
                }
                if (object.createdAt != null)
                    if ($util.Long)
                        (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                    else if (typeof object.createdAt === "string")
                        message.createdAt = parseInt(object.createdAt, 10);
                    else if (typeof object.createdAt === "number")
                        message.createdAt = object.createdAt;
                    else if (typeof object.createdAt === "object")
                        message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                if (object.updatedAt != null)
                    if ($util.Long)
                        (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                    else if (typeof object.updatedAt === "string")
                        message.updatedAt = parseInt(object.updatedAt, 10);
                    else if (typeof object.updatedAt === "number")
                        message.updatedAt = object.updatedAt;
                    else if (typeof object.updatedAt === "object")
                        message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                if (object.confirmations != null)
                    if ($util.Long)
                        (message.confirmations = $util.Long.fromValue(object.confirmations)).unsigned = true;
                    else if (typeof object.confirmations === "string")
                        message.confirmations = parseInt(object.confirmations, 10);
                    else if (typeof object.confirmations === "number")
                        message.confirmations = object.confirmations;
                    else if (typeof object.confirmations === "object")
                        message.confirmations = new $util.LongBits(object.confirmations.low >>> 0, object.confirmations.high >>> 0).toNumber(true);
                switch (object.direction) {
                case "UNK_TX_DIR":
                case 0:
                    message.direction = 0;
                    break;
                case "IN_TX_DIR":
                case 1:
                    message.direction = 1;
                    break;
                case "OUT_TX_DIR":
                case 2:
                    message.direction = 2;
                    break;
                }
                switch (object.type) {
                case "UNK_TX_TYPE":
                case 0:
                    message.type = 0;
                    break;
                case "REGULAR_TX_TYPE":
                case 1:
                    message.type = 1;
                    break;
                case "DEPOSIT_APPROVE_FEE_TX_TYPE":
                case 2:
                    message.type = 2;
                    break;
                case "APPROVE_TOKEN_TRANSFER_TX_TYPE":
                case 3:
                    message.type = 3;
                    break;
                }
                switch (object.chain) {
                case "UNK_CHAIN":
                case 0:
                    message.chain = 0;
                    break;
                case "BTC_CHAIN":
                case 1:
                    message.chain = 1;
                    break;
                case "ETH_CHAIN":
                case 2:
                    message.chain = 2;
                    break;
                case "TRX_CHAIN":
                case 3:
                    message.chain = 3;
                    break;
                }
                if (object.meta != null) {
                    if (typeof object.meta !== "object")
                        throw TypeError(".protocol.TxRecord.meta: object expected");
                    message.meta = $root.protocol.TxMeta.fromObject(object.meta);
                }
                if (object.payoutRequestId != null)
                    message.payoutRequestId = object.payoutRequestId >>> 0;
                if (object.operationId != null)
                    message.operationId = String(object.operationId);
                if (object.clientPeerName != null)
                    message.clientPeerName = String(object.clientPeerName);
                if (object.customAmounts) {
                    if (typeof object.customAmounts !== "object")
                        throw TypeError(".protocol.TxRecord.customAmounts: object expected");
                    message.customAmounts = {};
                    for (var keys = Object.keys(object.customAmounts), i = 0; i < keys.length; ++i)
                        message.customAmounts[keys[i]] = String(object.customAmounts[keys[i]]);
                }
                if (object.from) {
                    if (!Array.isArray(object.from))
                        throw TypeError(".protocol.TxRecord.from: array expected");
                    message.from = [];
                    for (var i = 0; i < object.from.length; ++i)
                        message.from[i] = String(object.from[i]);
                }
                if (object.to) {
                    if (!Array.isArray(object.to))
                        throw TypeError(".protocol.TxRecord.to: array expected");
                    message.to = [];
                    for (var i = 0; i < object.to.length; ++i)
                        message.to[i] = String(object.to[i]);
                }
                if (object.addressTags) {
                    if (!Array.isArray(object.addressTags))
                        throw TypeError(".protocol.TxRecord.addressTags: array expected");
                    message.addressTags = [];
                    for (var i = 0; i < object.addressTags.length; ++i)
                        message.addressTags[i] = String(object.addressTags[i]);
                }
                if (object.payoutTags) {
                    if (!Array.isArray(object.payoutTags))
                        throw TypeError(".protocol.TxRecord.payoutTags: array expected");
                    message.payoutTags = [];
                    for (var i = 0; i < object.payoutTags.length; ++i)
                        message.payoutTags[i] = String(object.payoutTags[i]);
                }
                if (object.payoutRequestTags) {
                    if (!Array.isArray(object.payoutRequestTags))
                        throw TypeError(".protocol.TxRecord.payoutRequestTags: array expected");
                    message.payoutRequestTags = [];
                    for (var i = 0; i < object.payoutRequestTags.length; ++i)
                        message.payoutRequestTags[i] = String(object.payoutRequestTags[i]);
                }
                if (object.payoutAddresses) {
                    if (!Array.isArray(object.payoutAddresses))
                        throw TypeError(".protocol.TxRecord.payoutAddresses: array expected");
                    message.payoutAddresses = [];
                    for (var i = 0; i < object.payoutAddresses.length; ++i)
                        message.payoutAddresses[i] = String(object.payoutAddresses[i]);
                }
                if (object.btcDetails != null) {
                    if (typeof object.btcDetails !== "object")
                        throw TypeError(".protocol.TxRecord.btcDetails: object expected");
                    message.btcDetails = $root.protocol.BitcoinTxDetails.fromObject(object.btcDetails);
                }
                if (object.ethDetails != null) {
                    if (typeof object.ethDetails !== "object")
                        throw TypeError(".protocol.TxRecord.ethDetails: object expected");
                    message.ethDetails = $root.protocol.EthereumTxDetails.fromObject(object.ethDetails);
                }
                if (object.trxDetails != null) {
                    if (typeof object.trxDetails !== "object")
                        throw TypeError(".protocol.TxRecord.trxDetails: object expected");
                    message.trxDetails = $root.protocol.TronTxDetails.fromObject(object.trxDetails);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TxRecord message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TxRecord
             * @static
             * @param {protocol.TxRecord} message TxRecord
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TxRecord.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.from = [];
                    object.to = [];
                    object.addressTags = [];
                    object.payoutTags = [];
                    object.payoutRequestTags = [];
                    object.payoutAddresses = [];
                }
                if (options.objects || options.defaults)
                    object.customAmounts = {};
                if (options.defaults) {
                    object.id = 0;
                    object.hash = "";
                    object.amount = "";
                    object.address = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.blockHeight = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.blockHeight = options.longs === String ? "0" : 0;
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.status = options.enums === String ? "UNK_TX_STATUS" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAt = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updatedAt = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.confirmations = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.confirmations = options.longs === String ? "0" : 0;
                    object.direction = options.enums === String ? "UNK_TX_DIR" : 0;
                    object.type = options.enums === String ? "UNK_TX_TYPE" : 0;
                    object.chain = options.enums === String ? "UNK_CHAIN" : 0;
                    object.meta = null;
                    object.payoutRequestId = 0;
                    object.operationId = "";
                    object.clientPeerName = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.hash != null && message.hasOwnProperty("hash"))
                    object.hash = message.hash;
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = message.amount;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.blockHeight != null && message.hasOwnProperty("blockHeight"))
                    if (typeof message.blockHeight === "number")
                        object.blockHeight = options.longs === String ? String(message.blockHeight) : message.blockHeight;
                    else
                        object.blockHeight = options.longs === String ? $util.Long.prototype.toString.call(message.blockHeight) : options.longs === Number ? new $util.LongBits(message.blockHeight.low >>> 0, message.blockHeight.high >>> 0).toNumber(true) : message.blockHeight;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.protocol.TxStatus[message.status] : message.status;
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (typeof message.createdAt === "number")
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                    else
                        object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                    if (typeof message.updatedAt === "number")
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                    else
                        object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                if (message.confirmations != null && message.hasOwnProperty("confirmations"))
                    if (typeof message.confirmations === "number")
                        object.confirmations = options.longs === String ? String(message.confirmations) : message.confirmations;
                    else
                        object.confirmations = options.longs === String ? $util.Long.prototype.toString.call(message.confirmations) : options.longs === Number ? new $util.LongBits(message.confirmations.low >>> 0, message.confirmations.high >>> 0).toNumber(true) : message.confirmations;
                if (message.direction != null && message.hasOwnProperty("direction"))
                    object.direction = options.enums === String ? $root.protocol.TxDirection[message.direction] : message.direction;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.protocol.TxType[message.type] : message.type;
                if (message.chain != null && message.hasOwnProperty("chain"))
                    object.chain = options.enums === String ? $root.protocol.Chain[message.chain] : message.chain;
                if (message.meta != null && message.hasOwnProperty("meta"))
                    object.meta = $root.protocol.TxMeta.toObject(message.meta, options);
                if (message.payoutRequestId != null && message.hasOwnProperty("payoutRequestId"))
                    object.payoutRequestId = message.payoutRequestId;
                if (message.operationId != null && message.hasOwnProperty("operationId"))
                    object.operationId = message.operationId;
                if (message.clientPeerName != null && message.hasOwnProperty("clientPeerName"))
                    object.clientPeerName = message.clientPeerName;
                var keys2;
                if (message.customAmounts && (keys2 = Object.keys(message.customAmounts)).length) {
                    object.customAmounts = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.customAmounts[keys2[j]] = message.customAmounts[keys2[j]];
                }
                if (message.from && message.from.length) {
                    object.from = [];
                    for (var j = 0; j < message.from.length; ++j)
                        object.from[j] = message.from[j];
                }
                if (message.to && message.to.length) {
                    object.to = [];
                    for (var j = 0; j < message.to.length; ++j)
                        object.to[j] = message.to[j];
                }
                if (message.addressTags && message.addressTags.length) {
                    object.addressTags = [];
                    for (var j = 0; j < message.addressTags.length; ++j)
                        object.addressTags[j] = message.addressTags[j];
                }
                if (message.payoutTags && message.payoutTags.length) {
                    object.payoutTags = [];
                    for (var j = 0; j < message.payoutTags.length; ++j)
                        object.payoutTags[j] = message.payoutTags[j];
                }
                if (message.payoutRequestTags && message.payoutRequestTags.length) {
                    object.payoutRequestTags = [];
                    for (var j = 0; j < message.payoutRequestTags.length; ++j)
                        object.payoutRequestTags[j] = message.payoutRequestTags[j];
                }
                if (message.payoutAddresses && message.payoutAddresses.length) {
                    object.payoutAddresses = [];
                    for (var j = 0; j < message.payoutAddresses.length; ++j)
                        object.payoutAddresses[j] = message.payoutAddresses[j];
                }
                if (message.btcDetails != null && message.hasOwnProperty("btcDetails")) {
                    object.btcDetails = $root.protocol.BitcoinTxDetails.toObject(message.btcDetails, options);
                    if (options.oneofs)
                        object.chainDetails = "btcDetails";
                }
                if (message.ethDetails != null && message.hasOwnProperty("ethDetails")) {
                    object.ethDetails = $root.protocol.EthereumTxDetails.toObject(message.ethDetails, options);
                    if (options.oneofs)
                        object.chainDetails = "ethDetails";
                }
                if (message.trxDetails != null && message.hasOwnProperty("trxDetails")) {
                    object.trxDetails = $root.protocol.TronTxDetails.toObject(message.trxDetails, options);
                    if (options.oneofs)
                        object.chainDetails = "trxDetails";
                }
                return object;
            };
    
            /**
             * Converts this TxRecord to JSON.
             * @function toJSON
             * @memberof protocol.TxRecord
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TxRecord.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TxRecord;
        })();
    
        protocol.BitcoinTxDetails = (function() {
    
            /**
             * Properties of a BitcoinTxDetails.
             * @memberof protocol
             * @interface IBitcoinTxDetails
             * @property {string|null} [value] BitcoinTxDetails value
             * @property {string|null} [fee] BitcoinTxDetails fee
             * @property {Long|null} [time] BitcoinTxDetails time
             * @property {Long|null} [version] BitcoinTxDetails version
             * @property {Long|null} [size] BitcoinTxDetails size
             * @property {Long|null} [vsize] BitcoinTxDetails vsize
             * @property {Long|null} [weight] BitcoinTxDetails weight
             * @property {Long|null} [locktime] BitcoinTxDetails locktime
             * @property {Array.<protocol.BitcoinTxDetails.IInput>|null} [vin] BitcoinTxDetails vin
             * @property {Array.<protocol.BitcoinTxDetails.IOutput>|null} [vout] BitcoinTxDetails vout
             */
    
            /**
             * Constructs a new BitcoinTxDetails.
             * @memberof protocol
             * @classdesc Represents a BitcoinTxDetails.
             * @implements IBitcoinTxDetails
             * @constructor
             * @param {protocol.IBitcoinTxDetails=} [properties] Properties to set
             */
            function BitcoinTxDetails(properties) {
                this.vin = [];
                this.vout = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BitcoinTxDetails value.
             * @member {string} value
             * @memberof protocol.BitcoinTxDetails
             * @instance
             */
            BitcoinTxDetails.prototype.value = "";
    
            /**
             * BitcoinTxDetails fee.
             * @member {string} fee
             * @memberof protocol.BitcoinTxDetails
             * @instance
             */
            BitcoinTxDetails.prototype.fee = "";
    
            /**
             * BitcoinTxDetails time.
             * @member {Long} time
             * @memberof protocol.BitcoinTxDetails
             * @instance
             */
            BitcoinTxDetails.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * BitcoinTxDetails version.
             * @member {Long} version
             * @memberof protocol.BitcoinTxDetails
             * @instance
             */
            BitcoinTxDetails.prototype.version = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * BitcoinTxDetails size.
             * @member {Long} size
             * @memberof protocol.BitcoinTxDetails
             * @instance
             */
            BitcoinTxDetails.prototype.size = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * BitcoinTxDetails vsize.
             * @member {Long} vsize
             * @memberof protocol.BitcoinTxDetails
             * @instance
             */
            BitcoinTxDetails.prototype.vsize = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * BitcoinTxDetails weight.
             * @member {Long} weight
             * @memberof protocol.BitcoinTxDetails
             * @instance
             */
            BitcoinTxDetails.prototype.weight = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * BitcoinTxDetails locktime.
             * @member {Long} locktime
             * @memberof protocol.BitcoinTxDetails
             * @instance
             */
            BitcoinTxDetails.prototype.locktime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * BitcoinTxDetails vin.
             * @member {Array.<protocol.BitcoinTxDetails.IInput>} vin
             * @memberof protocol.BitcoinTxDetails
             * @instance
             */
            BitcoinTxDetails.prototype.vin = $util.emptyArray;
    
            /**
             * BitcoinTxDetails vout.
             * @member {Array.<protocol.BitcoinTxDetails.IOutput>} vout
             * @memberof protocol.BitcoinTxDetails
             * @instance
             */
            BitcoinTxDetails.prototype.vout = $util.emptyArray;
    
            /**
             * Creates a new BitcoinTxDetails instance using the specified properties.
             * @function create
             * @memberof protocol.BitcoinTxDetails
             * @static
             * @param {protocol.IBitcoinTxDetails=} [properties] Properties to set
             * @returns {protocol.BitcoinTxDetails} BitcoinTxDetails instance
             */
            BitcoinTxDetails.create = function create(properties) {
                return new BitcoinTxDetails(properties);
            };
    
            /**
             * Encodes the specified BitcoinTxDetails message. Does not implicitly {@link protocol.BitcoinTxDetails.verify|verify} messages.
             * @function encode
             * @memberof protocol.BitcoinTxDetails
             * @static
             * @param {protocol.IBitcoinTxDetails} message BitcoinTxDetails message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BitcoinTxDetails.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
                if (message.fee != null && Object.hasOwnProperty.call(message, "fee"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.fee);
                if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.time);
                if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.version);
                if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.size);
                if (message.vsize != null && Object.hasOwnProperty.call(message, "vsize"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.vsize);
                if (message.weight != null && Object.hasOwnProperty.call(message, "weight"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.weight);
                if (message.locktime != null && Object.hasOwnProperty.call(message, "locktime"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int64(message.locktime);
                if (message.vin != null && message.vin.length)
                    for (var i = 0; i < message.vin.length; ++i)
                        $root.protocol.BitcoinTxDetails.Input.encode(message.vin[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.vout != null && message.vout.length)
                    for (var i = 0; i < message.vout.length; ++i)
                        $root.protocol.BitcoinTxDetails.Output.encode(message.vout[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified BitcoinTxDetails message, length delimited. Does not implicitly {@link protocol.BitcoinTxDetails.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.BitcoinTxDetails
             * @static
             * @param {protocol.IBitcoinTxDetails} message BitcoinTxDetails message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BitcoinTxDetails.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BitcoinTxDetails message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.BitcoinTxDetails
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.BitcoinTxDetails} BitcoinTxDetails
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BitcoinTxDetails.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.BitcoinTxDetails();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.value = reader.string();
                        break;
                    case 2:
                        message.fee = reader.string();
                        break;
                    case 3:
                        message.time = reader.int64();
                        break;
                    case 4:
                        message.version = reader.int64();
                        break;
                    case 5:
                        message.size = reader.int64();
                        break;
                    case 6:
                        message.vsize = reader.int64();
                        break;
                    case 7:
                        message.weight = reader.int64();
                        break;
                    case 8:
                        message.locktime = reader.int64();
                        break;
                    case 9:
                        if (!(message.vin && message.vin.length))
                            message.vin = [];
                        message.vin.push($root.protocol.BitcoinTxDetails.Input.decode(reader, reader.uint32()));
                        break;
                    case 10:
                        if (!(message.vout && message.vout.length))
                            message.vout = [];
                        message.vout.push($root.protocol.BitcoinTxDetails.Output.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BitcoinTxDetails message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.BitcoinTxDetails
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.BitcoinTxDetails} BitcoinTxDetails
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BitcoinTxDetails.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BitcoinTxDetails message.
             * @function verify
             * @memberof protocol.BitcoinTxDetails
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BitcoinTxDetails.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isString(message.value))
                        return "value: string expected";
                if (message.fee != null && message.hasOwnProperty("fee"))
                    if (!$util.isString(message.fee))
                        return "fee: string expected";
                if (message.time != null && message.hasOwnProperty("time"))
                    if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                        return "time: integer|Long expected";
                if (message.version != null && message.hasOwnProperty("version"))
                    if (!$util.isInteger(message.version) && !(message.version && $util.isInteger(message.version.low) && $util.isInteger(message.version.high)))
                        return "version: integer|Long expected";
                if (message.size != null && message.hasOwnProperty("size"))
                    if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                        return "size: integer|Long expected";
                if (message.vsize != null && message.hasOwnProperty("vsize"))
                    if (!$util.isInteger(message.vsize) && !(message.vsize && $util.isInteger(message.vsize.low) && $util.isInteger(message.vsize.high)))
                        return "vsize: integer|Long expected";
                if (message.weight != null && message.hasOwnProperty("weight"))
                    if (!$util.isInteger(message.weight) && !(message.weight && $util.isInteger(message.weight.low) && $util.isInteger(message.weight.high)))
                        return "weight: integer|Long expected";
                if (message.locktime != null && message.hasOwnProperty("locktime"))
                    if (!$util.isInteger(message.locktime) && !(message.locktime && $util.isInteger(message.locktime.low) && $util.isInteger(message.locktime.high)))
                        return "locktime: integer|Long expected";
                if (message.vin != null && message.hasOwnProperty("vin")) {
                    if (!Array.isArray(message.vin))
                        return "vin: array expected";
                    for (var i = 0; i < message.vin.length; ++i) {
                        var error = $root.protocol.BitcoinTxDetails.Input.verify(message.vin[i]);
                        if (error)
                            return "vin." + error;
                    }
                }
                if (message.vout != null && message.hasOwnProperty("vout")) {
                    if (!Array.isArray(message.vout))
                        return "vout: array expected";
                    for (var i = 0; i < message.vout.length; ++i) {
                        var error = $root.protocol.BitcoinTxDetails.Output.verify(message.vout[i]);
                        if (error)
                            return "vout." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a BitcoinTxDetails message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.BitcoinTxDetails
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.BitcoinTxDetails} BitcoinTxDetails
             */
            BitcoinTxDetails.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.BitcoinTxDetails)
                    return object;
                var message = new $root.protocol.BitcoinTxDetails();
                if (object.value != null)
                    message.value = String(object.value);
                if (object.fee != null)
                    message.fee = String(object.fee);
                if (object.time != null)
                    if ($util.Long)
                        (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                    else if (typeof object.time === "string")
                        message.time = parseInt(object.time, 10);
                    else if (typeof object.time === "number")
                        message.time = object.time;
                    else if (typeof object.time === "object")
                        message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                if (object.version != null)
                    if ($util.Long)
                        (message.version = $util.Long.fromValue(object.version)).unsigned = false;
                    else if (typeof object.version === "string")
                        message.version = parseInt(object.version, 10);
                    else if (typeof object.version === "number")
                        message.version = object.version;
                    else if (typeof object.version === "object")
                        message.version = new $util.LongBits(object.version.low >>> 0, object.version.high >>> 0).toNumber();
                if (object.size != null)
                    if ($util.Long)
                        (message.size = $util.Long.fromValue(object.size)).unsigned = false;
                    else if (typeof object.size === "string")
                        message.size = parseInt(object.size, 10);
                    else if (typeof object.size === "number")
                        message.size = object.size;
                    else if (typeof object.size === "object")
                        message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber();
                if (object.vsize != null)
                    if ($util.Long)
                        (message.vsize = $util.Long.fromValue(object.vsize)).unsigned = false;
                    else if (typeof object.vsize === "string")
                        message.vsize = parseInt(object.vsize, 10);
                    else if (typeof object.vsize === "number")
                        message.vsize = object.vsize;
                    else if (typeof object.vsize === "object")
                        message.vsize = new $util.LongBits(object.vsize.low >>> 0, object.vsize.high >>> 0).toNumber();
                if (object.weight != null)
                    if ($util.Long)
                        (message.weight = $util.Long.fromValue(object.weight)).unsigned = false;
                    else if (typeof object.weight === "string")
                        message.weight = parseInt(object.weight, 10);
                    else if (typeof object.weight === "number")
                        message.weight = object.weight;
                    else if (typeof object.weight === "object")
                        message.weight = new $util.LongBits(object.weight.low >>> 0, object.weight.high >>> 0).toNumber();
                if (object.locktime != null)
                    if ($util.Long)
                        (message.locktime = $util.Long.fromValue(object.locktime)).unsigned = false;
                    else if (typeof object.locktime === "string")
                        message.locktime = parseInt(object.locktime, 10);
                    else if (typeof object.locktime === "number")
                        message.locktime = object.locktime;
                    else if (typeof object.locktime === "object")
                        message.locktime = new $util.LongBits(object.locktime.low >>> 0, object.locktime.high >>> 0).toNumber();
                if (object.vin) {
                    if (!Array.isArray(object.vin))
                        throw TypeError(".protocol.BitcoinTxDetails.vin: array expected");
                    message.vin = [];
                    for (var i = 0; i < object.vin.length; ++i) {
                        if (typeof object.vin[i] !== "object")
                            throw TypeError(".protocol.BitcoinTxDetails.vin: object expected");
                        message.vin[i] = $root.protocol.BitcoinTxDetails.Input.fromObject(object.vin[i]);
                    }
                }
                if (object.vout) {
                    if (!Array.isArray(object.vout))
                        throw TypeError(".protocol.BitcoinTxDetails.vout: array expected");
                    message.vout = [];
                    for (var i = 0; i < object.vout.length; ++i) {
                        if (typeof object.vout[i] !== "object")
                            throw TypeError(".protocol.BitcoinTxDetails.vout: object expected");
                        message.vout[i] = $root.protocol.BitcoinTxDetails.Output.fromObject(object.vout[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a BitcoinTxDetails message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.BitcoinTxDetails
             * @static
             * @param {protocol.BitcoinTxDetails} message BitcoinTxDetails
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BitcoinTxDetails.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.vin = [];
                    object.vout = [];
                }
                if (options.defaults) {
                    object.value = "";
                    object.fee = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.time = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.version = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.version = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.size = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.vsize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.vsize = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.weight = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.weight = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.locktime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.locktime = options.longs === String ? "0" : 0;
                }
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                if (message.fee != null && message.hasOwnProperty("fee"))
                    object.fee = message.fee;
                if (message.time != null && message.hasOwnProperty("time"))
                    if (typeof message.time === "number")
                        object.time = options.longs === String ? String(message.time) : message.time;
                    else
                        object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                if (message.version != null && message.hasOwnProperty("version"))
                    if (typeof message.version === "number")
                        object.version = options.longs === String ? String(message.version) : message.version;
                    else
                        object.version = options.longs === String ? $util.Long.prototype.toString.call(message.version) : options.longs === Number ? new $util.LongBits(message.version.low >>> 0, message.version.high >>> 0).toNumber() : message.version;
                if (message.size != null && message.hasOwnProperty("size"))
                    if (typeof message.size === "number")
                        object.size = options.longs === String ? String(message.size) : message.size;
                    else
                        object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber() : message.size;
                if (message.vsize != null && message.hasOwnProperty("vsize"))
                    if (typeof message.vsize === "number")
                        object.vsize = options.longs === String ? String(message.vsize) : message.vsize;
                    else
                        object.vsize = options.longs === String ? $util.Long.prototype.toString.call(message.vsize) : options.longs === Number ? new $util.LongBits(message.vsize.low >>> 0, message.vsize.high >>> 0).toNumber() : message.vsize;
                if (message.weight != null && message.hasOwnProperty("weight"))
                    if (typeof message.weight === "number")
                        object.weight = options.longs === String ? String(message.weight) : message.weight;
                    else
                        object.weight = options.longs === String ? $util.Long.prototype.toString.call(message.weight) : options.longs === Number ? new $util.LongBits(message.weight.low >>> 0, message.weight.high >>> 0).toNumber() : message.weight;
                if (message.locktime != null && message.hasOwnProperty("locktime"))
                    if (typeof message.locktime === "number")
                        object.locktime = options.longs === String ? String(message.locktime) : message.locktime;
                    else
                        object.locktime = options.longs === String ? $util.Long.prototype.toString.call(message.locktime) : options.longs === Number ? new $util.LongBits(message.locktime.low >>> 0, message.locktime.high >>> 0).toNumber() : message.locktime;
                if (message.vin && message.vin.length) {
                    object.vin = [];
                    for (var j = 0; j < message.vin.length; ++j)
                        object.vin[j] = $root.protocol.BitcoinTxDetails.Input.toObject(message.vin[j], options);
                }
                if (message.vout && message.vout.length) {
                    object.vout = [];
                    for (var j = 0; j < message.vout.length; ++j)
                        object.vout[j] = $root.protocol.BitcoinTxDetails.Output.toObject(message.vout[j], options);
                }
                return object;
            };
    
            /**
             * Converts this BitcoinTxDetails to JSON.
             * @function toJSON
             * @memberof protocol.BitcoinTxDetails
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BitcoinTxDetails.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            BitcoinTxDetails.ScriptSig = (function() {
    
                /**
                 * Properties of a ScriptSig.
                 * @memberof protocol.BitcoinTxDetails
                 * @interface IScriptSig
                 * @property {string|null} [asm] ScriptSig asm
                 * @property {string|null} [hex] ScriptSig hex
                 */
    
                /**
                 * Constructs a new ScriptSig.
                 * @memberof protocol.BitcoinTxDetails
                 * @classdesc Represents a ScriptSig.
                 * @implements IScriptSig
                 * @constructor
                 * @param {protocol.BitcoinTxDetails.IScriptSig=} [properties] Properties to set
                 */
                function ScriptSig(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ScriptSig asm.
                 * @member {string} asm
                 * @memberof protocol.BitcoinTxDetails.ScriptSig
                 * @instance
                 */
                ScriptSig.prototype.asm = "";
    
                /**
                 * ScriptSig hex.
                 * @member {string} hex
                 * @memberof protocol.BitcoinTxDetails.ScriptSig
                 * @instance
                 */
                ScriptSig.prototype.hex = "";
    
                /**
                 * Creates a new ScriptSig instance using the specified properties.
                 * @function create
                 * @memberof protocol.BitcoinTxDetails.ScriptSig
                 * @static
                 * @param {protocol.BitcoinTxDetails.IScriptSig=} [properties] Properties to set
                 * @returns {protocol.BitcoinTxDetails.ScriptSig} ScriptSig instance
                 */
                ScriptSig.create = function create(properties) {
                    return new ScriptSig(properties);
                };
    
                /**
                 * Encodes the specified ScriptSig message. Does not implicitly {@link protocol.BitcoinTxDetails.ScriptSig.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.BitcoinTxDetails.ScriptSig
                 * @static
                 * @param {protocol.BitcoinTxDetails.IScriptSig} message ScriptSig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ScriptSig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.asm != null && Object.hasOwnProperty.call(message, "asm"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.asm);
                    if (message.hex != null && Object.hasOwnProperty.call(message, "hex"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.hex);
                    return writer;
                };
    
                /**
                 * Encodes the specified ScriptSig message, length delimited. Does not implicitly {@link protocol.BitcoinTxDetails.ScriptSig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.BitcoinTxDetails.ScriptSig
                 * @static
                 * @param {protocol.BitcoinTxDetails.IScriptSig} message ScriptSig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ScriptSig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ScriptSig message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.BitcoinTxDetails.ScriptSig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.BitcoinTxDetails.ScriptSig} ScriptSig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ScriptSig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.BitcoinTxDetails.ScriptSig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.asm = reader.string();
                            break;
                        case 2:
                            message.hex = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ScriptSig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.BitcoinTxDetails.ScriptSig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.BitcoinTxDetails.ScriptSig} ScriptSig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ScriptSig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ScriptSig message.
                 * @function verify
                 * @memberof protocol.BitcoinTxDetails.ScriptSig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ScriptSig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.asm != null && message.hasOwnProperty("asm"))
                        if (!$util.isString(message.asm))
                            return "asm: string expected";
                    if (message.hex != null && message.hasOwnProperty("hex"))
                        if (!$util.isString(message.hex))
                            return "hex: string expected";
                    return null;
                };
    
                /**
                 * Creates a ScriptSig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.BitcoinTxDetails.ScriptSig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.BitcoinTxDetails.ScriptSig} ScriptSig
                 */
                ScriptSig.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.BitcoinTxDetails.ScriptSig)
                        return object;
                    var message = new $root.protocol.BitcoinTxDetails.ScriptSig();
                    if (object.asm != null)
                        message.asm = String(object.asm);
                    if (object.hex != null)
                        message.hex = String(object.hex);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ScriptSig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.BitcoinTxDetails.ScriptSig
                 * @static
                 * @param {protocol.BitcoinTxDetails.ScriptSig} message ScriptSig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ScriptSig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.asm = "";
                        object.hex = "";
                    }
                    if (message.asm != null && message.hasOwnProperty("asm"))
                        object.asm = message.asm;
                    if (message.hex != null && message.hasOwnProperty("hex"))
                        object.hex = message.hex;
                    return object;
                };
    
                /**
                 * Converts this ScriptSig to JSON.
                 * @function toJSON
                 * @memberof protocol.BitcoinTxDetails.ScriptSig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ScriptSig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return ScriptSig;
            })();
    
            BitcoinTxDetails.Input = (function() {
    
                /**
                 * Properties of an Input.
                 * @memberof protocol.BitcoinTxDetails
                 * @interface IInput
                 * @property {string|null} [txid] Input txid
                 * @property {Long|null} [index] Input index
                 * @property {protocol.BitcoinTxDetails.IScriptSig|null} [scriptSig] Input scriptSig
                 * @property {Long|null} [sequence] Input sequence
                 * @property {Array.<string>|null} [witness] Input witness
                 * @property {string|null} [address] Input address
                 */
    
                /**
                 * Constructs a new Input.
                 * @memberof protocol.BitcoinTxDetails
                 * @classdesc Represents an Input.
                 * @implements IInput
                 * @constructor
                 * @param {protocol.BitcoinTxDetails.IInput=} [properties] Properties to set
                 */
                function Input(properties) {
                    this.witness = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Input txid.
                 * @member {string} txid
                 * @memberof protocol.BitcoinTxDetails.Input
                 * @instance
                 */
                Input.prototype.txid = "";
    
                /**
                 * Input index.
                 * @member {Long} index
                 * @memberof protocol.BitcoinTxDetails.Input
                 * @instance
                 */
                Input.prototype.index = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Input scriptSig.
                 * @member {protocol.BitcoinTxDetails.IScriptSig|null|undefined} scriptSig
                 * @memberof protocol.BitcoinTxDetails.Input
                 * @instance
                 */
                Input.prototype.scriptSig = null;
    
                /**
                 * Input sequence.
                 * @member {Long} sequence
                 * @memberof protocol.BitcoinTxDetails.Input
                 * @instance
                 */
                Input.prototype.sequence = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Input witness.
                 * @member {Array.<string>} witness
                 * @memberof protocol.BitcoinTxDetails.Input
                 * @instance
                 */
                Input.prototype.witness = $util.emptyArray;
    
                /**
                 * Input address.
                 * @member {string} address
                 * @memberof protocol.BitcoinTxDetails.Input
                 * @instance
                 */
                Input.prototype.address = "";
    
                /**
                 * Creates a new Input instance using the specified properties.
                 * @function create
                 * @memberof protocol.BitcoinTxDetails.Input
                 * @static
                 * @param {protocol.BitcoinTxDetails.IInput=} [properties] Properties to set
                 * @returns {protocol.BitcoinTxDetails.Input} Input instance
                 */
                Input.create = function create(properties) {
                    return new Input(properties);
                };
    
                /**
                 * Encodes the specified Input message. Does not implicitly {@link protocol.BitcoinTxDetails.Input.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.BitcoinTxDetails.Input
                 * @static
                 * @param {protocol.BitcoinTxDetails.IInput} message Input message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Input.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.txid != null && Object.hasOwnProperty.call(message, "txid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.txid);
                    if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.index);
                    if (message.scriptSig != null && Object.hasOwnProperty.call(message, "scriptSig"))
                        $root.protocol.BitcoinTxDetails.ScriptSig.encode(message.scriptSig, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.sequence != null && Object.hasOwnProperty.call(message, "sequence"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.sequence);
                    if (message.witness != null && message.witness.length)
                        for (var i = 0; i < message.witness.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.witness[i]);
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.address);
                    return writer;
                };
    
                /**
                 * Encodes the specified Input message, length delimited. Does not implicitly {@link protocol.BitcoinTxDetails.Input.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.BitcoinTxDetails.Input
                 * @static
                 * @param {protocol.BitcoinTxDetails.IInput} message Input message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Input.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Input message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.BitcoinTxDetails.Input
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.BitcoinTxDetails.Input} Input
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Input.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.BitcoinTxDetails.Input();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.txid = reader.string();
                            break;
                        case 2:
                            message.index = reader.int64();
                            break;
                        case 3:
                            message.scriptSig = $root.protocol.BitcoinTxDetails.ScriptSig.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.sequence = reader.int64();
                            break;
                        case 5:
                            if (!(message.witness && message.witness.length))
                                message.witness = [];
                            message.witness.push(reader.string());
                            break;
                        case 6:
                            message.address = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Input message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.BitcoinTxDetails.Input
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.BitcoinTxDetails.Input} Input
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Input.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Input message.
                 * @function verify
                 * @memberof protocol.BitcoinTxDetails.Input
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Input.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.txid != null && message.hasOwnProperty("txid"))
                        if (!$util.isString(message.txid))
                            return "txid: string expected";
                    if (message.index != null && message.hasOwnProperty("index"))
                        if (!$util.isInteger(message.index) && !(message.index && $util.isInteger(message.index.low) && $util.isInteger(message.index.high)))
                            return "index: integer|Long expected";
                    if (message.scriptSig != null && message.hasOwnProperty("scriptSig")) {
                        var error = $root.protocol.BitcoinTxDetails.ScriptSig.verify(message.scriptSig);
                        if (error)
                            return "scriptSig." + error;
                    }
                    if (message.sequence != null && message.hasOwnProperty("sequence"))
                        if (!$util.isInteger(message.sequence) && !(message.sequence && $util.isInteger(message.sequence.low) && $util.isInteger(message.sequence.high)))
                            return "sequence: integer|Long expected";
                    if (message.witness != null && message.hasOwnProperty("witness")) {
                        if (!Array.isArray(message.witness))
                            return "witness: array expected";
                        for (var i = 0; i < message.witness.length; ++i)
                            if (!$util.isString(message.witness[i]))
                                return "witness: string[] expected";
                    }
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    return null;
                };
    
                /**
                 * Creates an Input message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.BitcoinTxDetails.Input
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.BitcoinTxDetails.Input} Input
                 */
                Input.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.BitcoinTxDetails.Input)
                        return object;
                    var message = new $root.protocol.BitcoinTxDetails.Input();
                    if (object.txid != null)
                        message.txid = String(object.txid);
                    if (object.index != null)
                        if ($util.Long)
                            (message.index = $util.Long.fromValue(object.index)).unsigned = false;
                        else if (typeof object.index === "string")
                            message.index = parseInt(object.index, 10);
                        else if (typeof object.index === "number")
                            message.index = object.index;
                        else if (typeof object.index === "object")
                            message.index = new $util.LongBits(object.index.low >>> 0, object.index.high >>> 0).toNumber();
                    if (object.scriptSig != null) {
                        if (typeof object.scriptSig !== "object")
                            throw TypeError(".protocol.BitcoinTxDetails.Input.scriptSig: object expected");
                        message.scriptSig = $root.protocol.BitcoinTxDetails.ScriptSig.fromObject(object.scriptSig);
                    }
                    if (object.sequence != null)
                        if ($util.Long)
                            (message.sequence = $util.Long.fromValue(object.sequence)).unsigned = false;
                        else if (typeof object.sequence === "string")
                            message.sequence = parseInt(object.sequence, 10);
                        else if (typeof object.sequence === "number")
                            message.sequence = object.sequence;
                        else if (typeof object.sequence === "object")
                            message.sequence = new $util.LongBits(object.sequence.low >>> 0, object.sequence.high >>> 0).toNumber();
                    if (object.witness) {
                        if (!Array.isArray(object.witness))
                            throw TypeError(".protocol.BitcoinTxDetails.Input.witness: array expected");
                        message.witness = [];
                        for (var i = 0; i < object.witness.length; ++i)
                            message.witness[i] = String(object.witness[i]);
                    }
                    if (object.address != null)
                        message.address = String(object.address);
                    return message;
                };
    
                /**
                 * Creates a plain object from an Input message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.BitcoinTxDetails.Input
                 * @static
                 * @param {protocol.BitcoinTxDetails.Input} message Input
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Input.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.witness = [];
                    if (options.defaults) {
                        object.txid = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.index = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.index = options.longs === String ? "0" : 0;
                        object.scriptSig = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.sequence = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sequence = options.longs === String ? "0" : 0;
                        object.address = "";
                    }
                    if (message.txid != null && message.hasOwnProperty("txid"))
                        object.txid = message.txid;
                    if (message.index != null && message.hasOwnProperty("index"))
                        if (typeof message.index === "number")
                            object.index = options.longs === String ? String(message.index) : message.index;
                        else
                            object.index = options.longs === String ? $util.Long.prototype.toString.call(message.index) : options.longs === Number ? new $util.LongBits(message.index.low >>> 0, message.index.high >>> 0).toNumber() : message.index;
                    if (message.scriptSig != null && message.hasOwnProperty("scriptSig"))
                        object.scriptSig = $root.protocol.BitcoinTxDetails.ScriptSig.toObject(message.scriptSig, options);
                    if (message.sequence != null && message.hasOwnProperty("sequence"))
                        if (typeof message.sequence === "number")
                            object.sequence = options.longs === String ? String(message.sequence) : message.sequence;
                        else
                            object.sequence = options.longs === String ? $util.Long.prototype.toString.call(message.sequence) : options.longs === Number ? new $util.LongBits(message.sequence.low >>> 0, message.sequence.high >>> 0).toNumber() : message.sequence;
                    if (message.witness && message.witness.length) {
                        object.witness = [];
                        for (var j = 0; j < message.witness.length; ++j)
                            object.witness[j] = message.witness[j];
                    }
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    return object;
                };
    
                /**
                 * Converts this Input to JSON.
                 * @function toJSON
                 * @memberof protocol.BitcoinTxDetails.Input
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Input.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Input;
            })();
    
            BitcoinTxDetails.ScriptPubKey = (function() {
    
                /**
                 * Properties of a ScriptPubKey.
                 * @memberof protocol.BitcoinTxDetails
                 * @interface IScriptPubKey
                 * @property {string|null} [asm] ScriptPubKey asm
                 * @property {string|null} [hex] ScriptPubKey hex
                 * @property {string|null} [type] ScriptPubKey type
                 * @property {string|null} [address] ScriptPubKey address
                 */
    
                /**
                 * Constructs a new ScriptPubKey.
                 * @memberof protocol.BitcoinTxDetails
                 * @classdesc Represents a ScriptPubKey.
                 * @implements IScriptPubKey
                 * @constructor
                 * @param {protocol.BitcoinTxDetails.IScriptPubKey=} [properties] Properties to set
                 */
                function ScriptPubKey(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ScriptPubKey asm.
                 * @member {string} asm
                 * @memberof protocol.BitcoinTxDetails.ScriptPubKey
                 * @instance
                 */
                ScriptPubKey.prototype.asm = "";
    
                /**
                 * ScriptPubKey hex.
                 * @member {string} hex
                 * @memberof protocol.BitcoinTxDetails.ScriptPubKey
                 * @instance
                 */
                ScriptPubKey.prototype.hex = "";
    
                /**
                 * ScriptPubKey type.
                 * @member {string} type
                 * @memberof protocol.BitcoinTxDetails.ScriptPubKey
                 * @instance
                 */
                ScriptPubKey.prototype.type = "";
    
                /**
                 * ScriptPubKey address.
                 * @member {string} address
                 * @memberof protocol.BitcoinTxDetails.ScriptPubKey
                 * @instance
                 */
                ScriptPubKey.prototype.address = "";
    
                /**
                 * Creates a new ScriptPubKey instance using the specified properties.
                 * @function create
                 * @memberof protocol.BitcoinTxDetails.ScriptPubKey
                 * @static
                 * @param {protocol.BitcoinTxDetails.IScriptPubKey=} [properties] Properties to set
                 * @returns {protocol.BitcoinTxDetails.ScriptPubKey} ScriptPubKey instance
                 */
                ScriptPubKey.create = function create(properties) {
                    return new ScriptPubKey(properties);
                };
    
                /**
                 * Encodes the specified ScriptPubKey message. Does not implicitly {@link protocol.BitcoinTxDetails.ScriptPubKey.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.BitcoinTxDetails.ScriptPubKey
                 * @static
                 * @param {protocol.BitcoinTxDetails.IScriptPubKey} message ScriptPubKey message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ScriptPubKey.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.asm != null && Object.hasOwnProperty.call(message, "asm"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.asm);
                    if (message.hex != null && Object.hasOwnProperty.call(message, "hex"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.hex);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.address);
                    return writer;
                };
    
                /**
                 * Encodes the specified ScriptPubKey message, length delimited. Does not implicitly {@link protocol.BitcoinTxDetails.ScriptPubKey.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.BitcoinTxDetails.ScriptPubKey
                 * @static
                 * @param {protocol.BitcoinTxDetails.IScriptPubKey} message ScriptPubKey message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ScriptPubKey.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ScriptPubKey message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.BitcoinTxDetails.ScriptPubKey
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.BitcoinTxDetails.ScriptPubKey} ScriptPubKey
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ScriptPubKey.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.BitcoinTxDetails.ScriptPubKey();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.asm = reader.string();
                            break;
                        case 2:
                            message.hex = reader.string();
                            break;
                        case 3:
                            message.type = reader.string();
                            break;
                        case 4:
                            message.address = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ScriptPubKey message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.BitcoinTxDetails.ScriptPubKey
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.BitcoinTxDetails.ScriptPubKey} ScriptPubKey
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ScriptPubKey.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ScriptPubKey message.
                 * @function verify
                 * @memberof protocol.BitcoinTxDetails.ScriptPubKey
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ScriptPubKey.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.asm != null && message.hasOwnProperty("asm"))
                        if (!$util.isString(message.asm))
                            return "asm: string expected";
                    if (message.hex != null && message.hasOwnProperty("hex"))
                        if (!$util.isString(message.hex))
                            return "hex: string expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        if (!$util.isString(message.type))
                            return "type: string expected";
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    return null;
                };
    
                /**
                 * Creates a ScriptPubKey message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.BitcoinTxDetails.ScriptPubKey
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.BitcoinTxDetails.ScriptPubKey} ScriptPubKey
                 */
                ScriptPubKey.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.BitcoinTxDetails.ScriptPubKey)
                        return object;
                    var message = new $root.protocol.BitcoinTxDetails.ScriptPubKey();
                    if (object.asm != null)
                        message.asm = String(object.asm);
                    if (object.hex != null)
                        message.hex = String(object.hex);
                    if (object.type != null)
                        message.type = String(object.type);
                    if (object.address != null)
                        message.address = String(object.address);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ScriptPubKey message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.BitcoinTxDetails.ScriptPubKey
                 * @static
                 * @param {protocol.BitcoinTxDetails.ScriptPubKey} message ScriptPubKey
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ScriptPubKey.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.asm = "";
                        object.hex = "";
                        object.type = "";
                        object.address = "";
                    }
                    if (message.asm != null && message.hasOwnProperty("asm"))
                        object.asm = message.asm;
                    if (message.hex != null && message.hasOwnProperty("hex"))
                        object.hex = message.hex;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = message.type;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    return object;
                };
    
                /**
                 * Converts this ScriptPubKey to JSON.
                 * @function toJSON
                 * @memberof protocol.BitcoinTxDetails.ScriptPubKey
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ScriptPubKey.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return ScriptPubKey;
            })();
    
            BitcoinTxDetails.Output = (function() {
    
                /**
                 * Properties of an Output.
                 * @memberof protocol.BitcoinTxDetails
                 * @interface IOutput
                 * @property {string|null} [value] Output value
                 * @property {Long|null} [n] Output n
                 * @property {protocol.BitcoinTxDetails.IScriptPubKey|null} [scriptPubKey] Output scriptPubKey
                 */
    
                /**
                 * Constructs a new Output.
                 * @memberof protocol.BitcoinTxDetails
                 * @classdesc Represents an Output.
                 * @implements IOutput
                 * @constructor
                 * @param {protocol.BitcoinTxDetails.IOutput=} [properties] Properties to set
                 */
                function Output(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Output value.
                 * @member {string} value
                 * @memberof protocol.BitcoinTxDetails.Output
                 * @instance
                 */
                Output.prototype.value = "";
    
                /**
                 * Output n.
                 * @member {Long} n
                 * @memberof protocol.BitcoinTxDetails.Output
                 * @instance
                 */
                Output.prototype.n = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Output scriptPubKey.
                 * @member {protocol.BitcoinTxDetails.IScriptPubKey|null|undefined} scriptPubKey
                 * @memberof protocol.BitcoinTxDetails.Output
                 * @instance
                 */
                Output.prototype.scriptPubKey = null;
    
                /**
                 * Creates a new Output instance using the specified properties.
                 * @function create
                 * @memberof protocol.BitcoinTxDetails.Output
                 * @static
                 * @param {protocol.BitcoinTxDetails.IOutput=} [properties] Properties to set
                 * @returns {protocol.BitcoinTxDetails.Output} Output instance
                 */
                Output.create = function create(properties) {
                    return new Output(properties);
                };
    
                /**
                 * Encodes the specified Output message. Does not implicitly {@link protocol.BitcoinTxDetails.Output.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.BitcoinTxDetails.Output
                 * @static
                 * @param {protocol.BitcoinTxDetails.IOutput} message Output message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Output.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
                    if (message.n != null && Object.hasOwnProperty.call(message, "n"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.n);
                    if (message.scriptPubKey != null && Object.hasOwnProperty.call(message, "scriptPubKey"))
                        $root.protocol.BitcoinTxDetails.ScriptPubKey.encode(message.scriptPubKey, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Output message, length delimited. Does not implicitly {@link protocol.BitcoinTxDetails.Output.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.BitcoinTxDetails.Output
                 * @static
                 * @param {protocol.BitcoinTxDetails.IOutput} message Output message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Output.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Output message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.BitcoinTxDetails.Output
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.BitcoinTxDetails.Output} Output
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Output.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.BitcoinTxDetails.Output();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.string();
                            break;
                        case 2:
                            message.n = reader.int64();
                            break;
                        case 3:
                            message.scriptPubKey = $root.protocol.BitcoinTxDetails.ScriptPubKey.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Output message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.BitcoinTxDetails.Output
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.BitcoinTxDetails.Output} Output
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Output.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Output message.
                 * @function verify
                 * @memberof protocol.BitcoinTxDetails.Output
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Output.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    if (message.n != null && message.hasOwnProperty("n"))
                        if (!$util.isInteger(message.n) && !(message.n && $util.isInteger(message.n.low) && $util.isInteger(message.n.high)))
                            return "n: integer|Long expected";
                    if (message.scriptPubKey != null && message.hasOwnProperty("scriptPubKey")) {
                        var error = $root.protocol.BitcoinTxDetails.ScriptPubKey.verify(message.scriptPubKey);
                        if (error)
                            return "scriptPubKey." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an Output message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.BitcoinTxDetails.Output
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.BitcoinTxDetails.Output} Output
                 */
                Output.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.BitcoinTxDetails.Output)
                        return object;
                    var message = new $root.protocol.BitcoinTxDetails.Output();
                    if (object.value != null)
                        message.value = String(object.value);
                    if (object.n != null)
                        if ($util.Long)
                            (message.n = $util.Long.fromValue(object.n)).unsigned = false;
                        else if (typeof object.n === "string")
                            message.n = parseInt(object.n, 10);
                        else if (typeof object.n === "number")
                            message.n = object.n;
                        else if (typeof object.n === "object")
                            message.n = new $util.LongBits(object.n.low >>> 0, object.n.high >>> 0).toNumber();
                    if (object.scriptPubKey != null) {
                        if (typeof object.scriptPubKey !== "object")
                            throw TypeError(".protocol.BitcoinTxDetails.Output.scriptPubKey: object expected");
                        message.scriptPubKey = $root.protocol.BitcoinTxDetails.ScriptPubKey.fromObject(object.scriptPubKey);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an Output message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.BitcoinTxDetails.Output
                 * @static
                 * @param {protocol.BitcoinTxDetails.Output} message Output
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Output.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.value = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.n = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.n = options.longs === String ? "0" : 0;
                        object.scriptPubKey = null;
                    }
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    if (message.n != null && message.hasOwnProperty("n"))
                        if (typeof message.n === "number")
                            object.n = options.longs === String ? String(message.n) : message.n;
                        else
                            object.n = options.longs === String ? $util.Long.prototype.toString.call(message.n) : options.longs === Number ? new $util.LongBits(message.n.low >>> 0, message.n.high >>> 0).toNumber() : message.n;
                    if (message.scriptPubKey != null && message.hasOwnProperty("scriptPubKey"))
                        object.scriptPubKey = $root.protocol.BitcoinTxDetails.ScriptPubKey.toObject(message.scriptPubKey, options);
                    return object;
                };
    
                /**
                 * Converts this Output to JSON.
                 * @function toJSON
                 * @memberof protocol.BitcoinTxDetails.Output
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Output.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Output;
            })();
    
            return BitcoinTxDetails;
        })();
    
        protocol.ApprovalLog = (function() {
    
            /**
             * Properties of an ApprovalLog.
             * @memberof protocol
             * @interface IApprovalLog
             * @property {string|null} [owner] ApprovalLog owner
             * @property {string|null} [spender] ApprovalLog spender
             * @property {string|null} [value] ApprovalLog value
             */
    
            /**
             * Constructs a new ApprovalLog.
             * @memberof protocol
             * @classdesc Represents an ApprovalLog.
             * @implements IApprovalLog
             * @constructor
             * @param {protocol.IApprovalLog=} [properties] Properties to set
             */
            function ApprovalLog(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ApprovalLog owner.
             * @member {string} owner
             * @memberof protocol.ApprovalLog
             * @instance
             */
            ApprovalLog.prototype.owner = "";
    
            /**
             * ApprovalLog spender.
             * @member {string} spender
             * @memberof protocol.ApprovalLog
             * @instance
             */
            ApprovalLog.prototype.spender = "";
    
            /**
             * ApprovalLog value.
             * @member {string} value
             * @memberof protocol.ApprovalLog
             * @instance
             */
            ApprovalLog.prototype.value = "";
    
            /**
             * Creates a new ApprovalLog instance using the specified properties.
             * @function create
             * @memberof protocol.ApprovalLog
             * @static
             * @param {protocol.IApprovalLog=} [properties] Properties to set
             * @returns {protocol.ApprovalLog} ApprovalLog instance
             */
            ApprovalLog.create = function create(properties) {
                return new ApprovalLog(properties);
            };
    
            /**
             * Encodes the specified ApprovalLog message. Does not implicitly {@link protocol.ApprovalLog.verify|verify} messages.
             * @function encode
             * @memberof protocol.ApprovalLog
             * @static
             * @param {protocol.IApprovalLog} message ApprovalLog message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ApprovalLog.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.owner != null && Object.hasOwnProperty.call(message, "owner"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.owner);
                if (message.spender != null && Object.hasOwnProperty.call(message, "spender"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.spender);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
                return writer;
            };
    
            /**
             * Encodes the specified ApprovalLog message, length delimited. Does not implicitly {@link protocol.ApprovalLog.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ApprovalLog
             * @static
             * @param {protocol.IApprovalLog} message ApprovalLog message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ApprovalLog.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an ApprovalLog message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ApprovalLog
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ApprovalLog} ApprovalLog
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ApprovalLog.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ApprovalLog();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.owner = reader.string();
                        break;
                    case 2:
                        message.spender = reader.string();
                        break;
                    case 3:
                        message.value = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an ApprovalLog message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ApprovalLog
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ApprovalLog} ApprovalLog
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ApprovalLog.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an ApprovalLog message.
             * @function verify
             * @memberof protocol.ApprovalLog
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ApprovalLog.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.owner != null && message.hasOwnProperty("owner"))
                    if (!$util.isString(message.owner))
                        return "owner: string expected";
                if (message.spender != null && message.hasOwnProperty("spender"))
                    if (!$util.isString(message.spender))
                        return "spender: string expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isString(message.value))
                        return "value: string expected";
                return null;
            };
    
            /**
             * Creates an ApprovalLog message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ApprovalLog
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ApprovalLog} ApprovalLog
             */
            ApprovalLog.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ApprovalLog)
                    return object;
                var message = new $root.protocol.ApprovalLog();
                if (object.owner != null)
                    message.owner = String(object.owner);
                if (object.spender != null)
                    message.spender = String(object.spender);
                if (object.value != null)
                    message.value = String(object.value);
                return message;
            };
    
            /**
             * Creates a plain object from an ApprovalLog message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ApprovalLog
             * @static
             * @param {protocol.ApprovalLog} message ApprovalLog
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ApprovalLog.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.owner = "";
                    object.spender = "";
                    object.value = "";
                }
                if (message.owner != null && message.hasOwnProperty("owner"))
                    object.owner = message.owner;
                if (message.spender != null && message.hasOwnProperty("spender"))
                    object.spender = message.spender;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };
    
            /**
             * Converts this ApprovalLog to JSON.
             * @function toJSON
             * @memberof protocol.ApprovalLog
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ApprovalLog.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ApprovalLog;
        })();
    
        protocol.TransferLog = (function() {
    
            /**
             * Properties of a TransferLog.
             * @memberof protocol
             * @interface ITransferLog
             * @property {string|null} [from] TransferLog from
             * @property {string|null} [to] TransferLog to
             * @property {string|null} [value] TransferLog value
             */
    
            /**
             * Constructs a new TransferLog.
             * @memberof protocol
             * @classdesc Represents a TransferLog.
             * @implements ITransferLog
             * @constructor
             * @param {protocol.ITransferLog=} [properties] Properties to set
             */
            function TransferLog(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TransferLog from.
             * @member {string} from
             * @memberof protocol.TransferLog
             * @instance
             */
            TransferLog.prototype.from = "";
    
            /**
             * TransferLog to.
             * @member {string} to
             * @memberof protocol.TransferLog
             * @instance
             */
            TransferLog.prototype.to = "";
    
            /**
             * TransferLog value.
             * @member {string} value
             * @memberof protocol.TransferLog
             * @instance
             */
            TransferLog.prototype.value = "";
    
            /**
             * Creates a new TransferLog instance using the specified properties.
             * @function create
             * @memberof protocol.TransferLog
             * @static
             * @param {protocol.ITransferLog=} [properties] Properties to set
             * @returns {protocol.TransferLog} TransferLog instance
             */
            TransferLog.create = function create(properties) {
                return new TransferLog(properties);
            };
    
            /**
             * Encodes the specified TransferLog message. Does not implicitly {@link protocol.TransferLog.verify|verify} messages.
             * @function encode
             * @memberof protocol.TransferLog
             * @static
             * @param {protocol.ITransferLog} message TransferLog message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransferLog.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.from);
                if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.to);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
                return writer;
            };
    
            /**
             * Encodes the specified TransferLog message, length delimited. Does not implicitly {@link protocol.TransferLog.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TransferLog
             * @static
             * @param {protocol.ITransferLog} message TransferLog message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransferLog.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TransferLog message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TransferLog
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TransferLog} TransferLog
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransferLog.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TransferLog();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.from = reader.string();
                        break;
                    case 2:
                        message.to = reader.string();
                        break;
                    case 3:
                        message.value = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TransferLog message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TransferLog
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TransferLog} TransferLog
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransferLog.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TransferLog message.
             * @function verify
             * @memberof protocol.TransferLog
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TransferLog.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.from != null && message.hasOwnProperty("from"))
                    if (!$util.isString(message.from))
                        return "from: string expected";
                if (message.to != null && message.hasOwnProperty("to"))
                    if (!$util.isString(message.to))
                        return "to: string expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isString(message.value))
                        return "value: string expected";
                return null;
            };
    
            /**
             * Creates a TransferLog message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TransferLog
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TransferLog} TransferLog
             */
            TransferLog.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TransferLog)
                    return object;
                var message = new $root.protocol.TransferLog();
                if (object.from != null)
                    message.from = String(object.from);
                if (object.to != null)
                    message.to = String(object.to);
                if (object.value != null)
                    message.value = String(object.value);
                return message;
            };
    
            /**
             * Creates a plain object from a TransferLog message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TransferLog
             * @static
             * @param {protocol.TransferLog} message TransferLog
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransferLog.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.from = "";
                    object.to = "";
                    object.value = "";
                }
                if (message.from != null && message.hasOwnProperty("from"))
                    object.from = message.from;
                if (message.to != null && message.hasOwnProperty("to"))
                    object.to = message.to;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };
    
            /**
             * Converts this TransferLog to JSON.
             * @function toJSON
             * @memberof protocol.TransferLog
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransferLog.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TransferLog;
        })();
    
        protocol.TxLog = (function() {
    
            /**
             * Properties of a TxLog.
             * @memberof protocol
             * @interface ITxLog
             * @property {string|null} [contractAddress] TxLog contractAddress
             * @property {Long|null} [index] TxLog index
             * @property {protocol.IApprovalLog|null} [approval] TxLog approval
             * @property {protocol.ITransferLog|null} [transfer] TxLog transfer
             */
    
            /**
             * Constructs a new TxLog.
             * @memberof protocol
             * @classdesc Represents a TxLog.
             * @implements ITxLog
             * @constructor
             * @param {protocol.ITxLog=} [properties] Properties to set
             */
            function TxLog(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TxLog contractAddress.
             * @member {string} contractAddress
             * @memberof protocol.TxLog
             * @instance
             */
            TxLog.prototype.contractAddress = "";
    
            /**
             * TxLog index.
             * @member {Long} index
             * @memberof protocol.TxLog
             * @instance
             */
            TxLog.prototype.index = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * TxLog approval.
             * @member {protocol.IApprovalLog|null|undefined} approval
             * @memberof protocol.TxLog
             * @instance
             */
            TxLog.prototype.approval = null;
    
            /**
             * TxLog transfer.
             * @member {protocol.ITransferLog|null|undefined} transfer
             * @memberof protocol.TxLog
             * @instance
             */
            TxLog.prototype.transfer = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * TxLog log.
             * @member {"approval"|"transfer"|undefined} log
             * @memberof protocol.TxLog
             * @instance
             */
            Object.defineProperty(TxLog.prototype, "log", {
                get: $util.oneOfGetter($oneOfFields = ["approval", "transfer"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new TxLog instance using the specified properties.
             * @function create
             * @memberof protocol.TxLog
             * @static
             * @param {protocol.ITxLog=} [properties] Properties to set
             * @returns {protocol.TxLog} TxLog instance
             */
            TxLog.create = function create(properties) {
                return new TxLog(properties);
            };
    
            /**
             * Encodes the specified TxLog message. Does not implicitly {@link protocol.TxLog.verify|verify} messages.
             * @function encode
             * @memberof protocol.TxLog
             * @static
             * @param {protocol.ITxLog} message TxLog message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TxLog.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.contractAddress != null && Object.hasOwnProperty.call(message, "contractAddress"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.contractAddress);
                if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.index);
                if (message.approval != null && Object.hasOwnProperty.call(message, "approval"))
                    $root.protocol.ApprovalLog.encode(message.approval, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
                if (message.transfer != null && Object.hasOwnProperty.call(message, "transfer"))
                    $root.protocol.TransferLog.encode(message.transfer, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TxLog message, length delimited. Does not implicitly {@link protocol.TxLog.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TxLog
             * @static
             * @param {protocol.ITxLog} message TxLog message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TxLog.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TxLog message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TxLog
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TxLog} TxLog
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TxLog.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TxLog();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.contractAddress = reader.string();
                        break;
                    case 2:
                        message.index = reader.uint64();
                        break;
                    case 50:
                        message.approval = $root.protocol.ApprovalLog.decode(reader, reader.uint32());
                        break;
                    case 51:
                        message.transfer = $root.protocol.TransferLog.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TxLog message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TxLog
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TxLog} TxLog
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TxLog.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TxLog message.
             * @function verify
             * @memberof protocol.TxLog
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TxLog.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.contractAddress != null && message.hasOwnProperty("contractAddress"))
                    if (!$util.isString(message.contractAddress))
                        return "contractAddress: string expected";
                if (message.index != null && message.hasOwnProperty("index"))
                    if (!$util.isInteger(message.index) && !(message.index && $util.isInteger(message.index.low) && $util.isInteger(message.index.high)))
                        return "index: integer|Long expected";
                if (message.approval != null && message.hasOwnProperty("approval")) {
                    properties.log = 1;
                    {
                        var error = $root.protocol.ApprovalLog.verify(message.approval);
                        if (error)
                            return "approval." + error;
                    }
                }
                if (message.transfer != null && message.hasOwnProperty("transfer")) {
                    if (properties.log === 1)
                        return "log: multiple values";
                    properties.log = 1;
                    {
                        var error = $root.protocol.TransferLog.verify(message.transfer);
                        if (error)
                            return "transfer." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a TxLog message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TxLog
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TxLog} TxLog
             */
            TxLog.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TxLog)
                    return object;
                var message = new $root.protocol.TxLog();
                if (object.contractAddress != null)
                    message.contractAddress = String(object.contractAddress);
                if (object.index != null)
                    if ($util.Long)
                        (message.index = $util.Long.fromValue(object.index)).unsigned = true;
                    else if (typeof object.index === "string")
                        message.index = parseInt(object.index, 10);
                    else if (typeof object.index === "number")
                        message.index = object.index;
                    else if (typeof object.index === "object")
                        message.index = new $util.LongBits(object.index.low >>> 0, object.index.high >>> 0).toNumber(true);
                if (object.approval != null) {
                    if (typeof object.approval !== "object")
                        throw TypeError(".protocol.TxLog.approval: object expected");
                    message.approval = $root.protocol.ApprovalLog.fromObject(object.approval);
                }
                if (object.transfer != null) {
                    if (typeof object.transfer !== "object")
                        throw TypeError(".protocol.TxLog.transfer: object expected");
                    message.transfer = $root.protocol.TransferLog.fromObject(object.transfer);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TxLog message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TxLog
             * @static
             * @param {protocol.TxLog} message TxLog
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TxLog.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.contractAddress = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.index = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.index = options.longs === String ? "0" : 0;
                }
                if (message.contractAddress != null && message.hasOwnProperty("contractAddress"))
                    object.contractAddress = message.contractAddress;
                if (message.index != null && message.hasOwnProperty("index"))
                    if (typeof message.index === "number")
                        object.index = options.longs === String ? String(message.index) : message.index;
                    else
                        object.index = options.longs === String ? $util.Long.prototype.toString.call(message.index) : options.longs === Number ? new $util.LongBits(message.index.low >>> 0, message.index.high >>> 0).toNumber(true) : message.index;
                if (message.approval != null && message.hasOwnProperty("approval")) {
                    object.approval = $root.protocol.ApprovalLog.toObject(message.approval, options);
                    if (options.oneofs)
                        object.log = "approval";
                }
                if (message.transfer != null && message.hasOwnProperty("transfer")) {
                    object.transfer = $root.protocol.TransferLog.toObject(message.transfer, options);
                    if (options.oneofs)
                        object.log = "transfer";
                }
                return object;
            };
    
            /**
             * Converts this TxLog to JSON.
             * @function toJSON
             * @memberof protocol.TxLog
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TxLog.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TxLog;
        })();
    
        protocol.EthereumTxDetails = (function() {
    
            /**
             * Properties of an EthereumTxDetails.
             * @memberof protocol
             * @interface IEthereumTxDetails
             * @property {string|null} [from] EthereumTxDetails from
             * @property {string|null} [to] EthereumTxDetails to
             * @property {string|null} [value] EthereumTxDetails value
             * @property {Long|null} [gasLimit] EthereumTxDetails gasLimit
             * @property {Long|null} [gasUsed] EthereumTxDetails gasUsed
             * @property {string|null} [gasPrice] EthereumTxDetails gasPrice
             * @property {string|null} [maxFeePerGas] EthereumTxDetails maxFeePerGas
             * @property {string|null} [maxPriorityFeePerGas] EthereumTxDetails maxPriorityFeePerGas
             * @property {Long|null} [nonce] EthereumTxDetails nonce
             * @property {number|null} [type] EthereumTxDetails type
             * @property {Long|null} [index] EthereumTxDetails index
             * @property {Long|null} [status] EthereumTxDetails status
             * @property {string|null} [effectiveFee] EthereumTxDetails effectiveFee
             * @property {Array.<protocol.ITxLog>|null} [logs] EthereumTxDetails logs
             */
    
            /**
             * Constructs a new EthereumTxDetails.
             * @memberof protocol
             * @classdesc Represents an EthereumTxDetails.
             * @implements IEthereumTxDetails
             * @constructor
             * @param {protocol.IEthereumTxDetails=} [properties] Properties to set
             */
            function EthereumTxDetails(properties) {
                this.logs = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * EthereumTxDetails from.
             * @member {string} from
             * @memberof protocol.EthereumTxDetails
             * @instance
             */
            EthereumTxDetails.prototype.from = "";
    
            /**
             * EthereumTxDetails to.
             * @member {string} to
             * @memberof protocol.EthereumTxDetails
             * @instance
             */
            EthereumTxDetails.prototype.to = "";
    
            /**
             * EthereumTxDetails value.
             * @member {string} value
             * @memberof protocol.EthereumTxDetails
             * @instance
             */
            EthereumTxDetails.prototype.value = "";
    
            /**
             * EthereumTxDetails gasLimit.
             * @member {Long} gasLimit
             * @memberof protocol.EthereumTxDetails
             * @instance
             */
            EthereumTxDetails.prototype.gasLimit = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * EthereumTxDetails gasUsed.
             * @member {Long} gasUsed
             * @memberof protocol.EthereumTxDetails
             * @instance
             */
            EthereumTxDetails.prototype.gasUsed = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * EthereumTxDetails gasPrice.
             * @member {string} gasPrice
             * @memberof protocol.EthereumTxDetails
             * @instance
             */
            EthereumTxDetails.prototype.gasPrice = "";
    
            /**
             * EthereumTxDetails maxFeePerGas.
             * @member {string} maxFeePerGas
             * @memberof protocol.EthereumTxDetails
             * @instance
             */
            EthereumTxDetails.prototype.maxFeePerGas = "";
    
            /**
             * EthereumTxDetails maxPriorityFeePerGas.
             * @member {string} maxPriorityFeePerGas
             * @memberof protocol.EthereumTxDetails
             * @instance
             */
            EthereumTxDetails.prototype.maxPriorityFeePerGas = "";
    
            /**
             * EthereumTxDetails nonce.
             * @member {Long} nonce
             * @memberof protocol.EthereumTxDetails
             * @instance
             */
            EthereumTxDetails.prototype.nonce = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * EthereumTxDetails type.
             * @member {number} type
             * @memberof protocol.EthereumTxDetails
             * @instance
             */
            EthereumTxDetails.prototype.type = 0;
    
            /**
             * EthereumTxDetails index.
             * @member {Long} index
             * @memberof protocol.EthereumTxDetails
             * @instance
             */
            EthereumTxDetails.prototype.index = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * EthereumTxDetails status.
             * @member {Long} status
             * @memberof protocol.EthereumTxDetails
             * @instance
             */
            EthereumTxDetails.prototype.status = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * EthereumTxDetails effectiveFee.
             * @member {string} effectiveFee
             * @memberof protocol.EthereumTxDetails
             * @instance
             */
            EthereumTxDetails.prototype.effectiveFee = "";
    
            /**
             * EthereumTxDetails logs.
             * @member {Array.<protocol.ITxLog>} logs
             * @memberof protocol.EthereumTxDetails
             * @instance
             */
            EthereumTxDetails.prototype.logs = $util.emptyArray;
    
            /**
             * Creates a new EthereumTxDetails instance using the specified properties.
             * @function create
             * @memberof protocol.EthereumTxDetails
             * @static
             * @param {protocol.IEthereumTxDetails=} [properties] Properties to set
             * @returns {protocol.EthereumTxDetails} EthereumTxDetails instance
             */
            EthereumTxDetails.create = function create(properties) {
                return new EthereumTxDetails(properties);
            };
    
            /**
             * Encodes the specified EthereumTxDetails message. Does not implicitly {@link protocol.EthereumTxDetails.verify|verify} messages.
             * @function encode
             * @memberof protocol.EthereumTxDetails
             * @static
             * @param {protocol.IEthereumTxDetails} message EthereumTxDetails message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EthereumTxDetails.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.from);
                if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.to);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
                if (message.gasLimit != null && Object.hasOwnProperty.call(message, "gasLimit"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.gasLimit);
                if (message.gasUsed != null && Object.hasOwnProperty.call(message, "gasUsed"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.gasUsed);
                if (message.gasPrice != null && Object.hasOwnProperty.call(message, "gasPrice"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.gasPrice);
                if (message.maxFeePerGas != null && Object.hasOwnProperty.call(message, "maxFeePerGas"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.maxFeePerGas);
                if (message.maxPriorityFeePerGas != null && Object.hasOwnProperty.call(message, "maxPriorityFeePerGas"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.maxPriorityFeePerGas);
                if (message.nonce != null && Object.hasOwnProperty.call(message, "nonce"))
                    writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.nonce);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.type);
                if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                    writer.uint32(/* id 11, wireType 0 =*/88).uint64(message.index);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 12, wireType 0 =*/96).uint64(message.status);
                if (message.effectiveFee != null && Object.hasOwnProperty.call(message, "effectiveFee"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.effectiveFee);
                if (message.logs != null && message.logs.length)
                    for (var i = 0; i < message.logs.length; ++i)
                        $root.protocol.TxLog.encode(message.logs[i], writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified EthereumTxDetails message, length delimited. Does not implicitly {@link protocol.EthereumTxDetails.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.EthereumTxDetails
             * @static
             * @param {protocol.IEthereumTxDetails} message EthereumTxDetails message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EthereumTxDetails.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EthereumTxDetails message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.EthereumTxDetails
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.EthereumTxDetails} EthereumTxDetails
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EthereumTxDetails.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.EthereumTxDetails();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.from = reader.string();
                        break;
                    case 2:
                        message.to = reader.string();
                        break;
                    case 3:
                        message.value = reader.string();
                        break;
                    case 4:
                        message.gasLimit = reader.uint64();
                        break;
                    case 5:
                        message.gasUsed = reader.uint64();
                        break;
                    case 6:
                        message.gasPrice = reader.string();
                        break;
                    case 7:
                        message.maxFeePerGas = reader.string();
                        break;
                    case 8:
                        message.maxPriorityFeePerGas = reader.string();
                        break;
                    case 9:
                        message.nonce = reader.uint64();
                        break;
                    case 10:
                        message.type = reader.uint32();
                        break;
                    case 11:
                        message.index = reader.uint64();
                        break;
                    case 12:
                        message.status = reader.uint64();
                        break;
                    case 13:
                        message.effectiveFee = reader.string();
                        break;
                    case 50:
                        if (!(message.logs && message.logs.length))
                            message.logs = [];
                        message.logs.push($root.protocol.TxLog.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EthereumTxDetails message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.EthereumTxDetails
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.EthereumTxDetails} EthereumTxDetails
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EthereumTxDetails.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EthereumTxDetails message.
             * @function verify
             * @memberof protocol.EthereumTxDetails
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EthereumTxDetails.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.from != null && message.hasOwnProperty("from"))
                    if (!$util.isString(message.from))
                        return "from: string expected";
                if (message.to != null && message.hasOwnProperty("to"))
                    if (!$util.isString(message.to))
                        return "to: string expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isString(message.value))
                        return "value: string expected";
                if (message.gasLimit != null && message.hasOwnProperty("gasLimit"))
                    if (!$util.isInteger(message.gasLimit) && !(message.gasLimit && $util.isInteger(message.gasLimit.low) && $util.isInteger(message.gasLimit.high)))
                        return "gasLimit: integer|Long expected";
                if (message.gasUsed != null && message.hasOwnProperty("gasUsed"))
                    if (!$util.isInteger(message.gasUsed) && !(message.gasUsed && $util.isInteger(message.gasUsed.low) && $util.isInteger(message.gasUsed.high)))
                        return "gasUsed: integer|Long expected";
                if (message.gasPrice != null && message.hasOwnProperty("gasPrice"))
                    if (!$util.isString(message.gasPrice))
                        return "gasPrice: string expected";
                if (message.maxFeePerGas != null && message.hasOwnProperty("maxFeePerGas"))
                    if (!$util.isString(message.maxFeePerGas))
                        return "maxFeePerGas: string expected";
                if (message.maxPriorityFeePerGas != null && message.hasOwnProperty("maxPriorityFeePerGas"))
                    if (!$util.isString(message.maxPriorityFeePerGas))
                        return "maxPriorityFeePerGas: string expected";
                if (message.nonce != null && message.hasOwnProperty("nonce"))
                    if (!$util.isInteger(message.nonce) && !(message.nonce && $util.isInteger(message.nonce.low) && $util.isInteger(message.nonce.high)))
                        return "nonce: integer|Long expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    if (!$util.isInteger(message.type))
                        return "type: integer expected";
                if (message.index != null && message.hasOwnProperty("index"))
                    if (!$util.isInteger(message.index) && !(message.index && $util.isInteger(message.index.low) && $util.isInteger(message.index.high)))
                        return "index: integer|Long expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    if (!$util.isInteger(message.status) && !(message.status && $util.isInteger(message.status.low) && $util.isInteger(message.status.high)))
                        return "status: integer|Long expected";
                if (message.effectiveFee != null && message.hasOwnProperty("effectiveFee"))
                    if (!$util.isString(message.effectiveFee))
                        return "effectiveFee: string expected";
                if (message.logs != null && message.hasOwnProperty("logs")) {
                    if (!Array.isArray(message.logs))
                        return "logs: array expected";
                    for (var i = 0; i < message.logs.length; ++i) {
                        var error = $root.protocol.TxLog.verify(message.logs[i]);
                        if (error)
                            return "logs." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an EthereumTxDetails message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.EthereumTxDetails
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.EthereumTxDetails} EthereumTxDetails
             */
            EthereumTxDetails.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.EthereumTxDetails)
                    return object;
                var message = new $root.protocol.EthereumTxDetails();
                if (object.from != null)
                    message.from = String(object.from);
                if (object.to != null)
                    message.to = String(object.to);
                if (object.value != null)
                    message.value = String(object.value);
                if (object.gasLimit != null)
                    if ($util.Long)
                        (message.gasLimit = $util.Long.fromValue(object.gasLimit)).unsigned = true;
                    else if (typeof object.gasLimit === "string")
                        message.gasLimit = parseInt(object.gasLimit, 10);
                    else if (typeof object.gasLimit === "number")
                        message.gasLimit = object.gasLimit;
                    else if (typeof object.gasLimit === "object")
                        message.gasLimit = new $util.LongBits(object.gasLimit.low >>> 0, object.gasLimit.high >>> 0).toNumber(true);
                if (object.gasUsed != null)
                    if ($util.Long)
                        (message.gasUsed = $util.Long.fromValue(object.gasUsed)).unsigned = true;
                    else if (typeof object.gasUsed === "string")
                        message.gasUsed = parseInt(object.gasUsed, 10);
                    else if (typeof object.gasUsed === "number")
                        message.gasUsed = object.gasUsed;
                    else if (typeof object.gasUsed === "object")
                        message.gasUsed = new $util.LongBits(object.gasUsed.low >>> 0, object.gasUsed.high >>> 0).toNumber(true);
                if (object.gasPrice != null)
                    message.gasPrice = String(object.gasPrice);
                if (object.maxFeePerGas != null)
                    message.maxFeePerGas = String(object.maxFeePerGas);
                if (object.maxPriorityFeePerGas != null)
                    message.maxPriorityFeePerGas = String(object.maxPriorityFeePerGas);
                if (object.nonce != null)
                    if ($util.Long)
                        (message.nonce = $util.Long.fromValue(object.nonce)).unsigned = true;
                    else if (typeof object.nonce === "string")
                        message.nonce = parseInt(object.nonce, 10);
                    else if (typeof object.nonce === "number")
                        message.nonce = object.nonce;
                    else if (typeof object.nonce === "object")
                        message.nonce = new $util.LongBits(object.nonce.low >>> 0, object.nonce.high >>> 0).toNumber(true);
                if (object.type != null)
                    message.type = object.type >>> 0;
                if (object.index != null)
                    if ($util.Long)
                        (message.index = $util.Long.fromValue(object.index)).unsigned = true;
                    else if (typeof object.index === "string")
                        message.index = parseInt(object.index, 10);
                    else if (typeof object.index === "number")
                        message.index = object.index;
                    else if (typeof object.index === "object")
                        message.index = new $util.LongBits(object.index.low >>> 0, object.index.high >>> 0).toNumber(true);
                if (object.status != null)
                    if ($util.Long)
                        (message.status = $util.Long.fromValue(object.status)).unsigned = true;
                    else if (typeof object.status === "string")
                        message.status = parseInt(object.status, 10);
                    else if (typeof object.status === "number")
                        message.status = object.status;
                    else if (typeof object.status === "object")
                        message.status = new $util.LongBits(object.status.low >>> 0, object.status.high >>> 0).toNumber(true);
                if (object.effectiveFee != null)
                    message.effectiveFee = String(object.effectiveFee);
                if (object.logs) {
                    if (!Array.isArray(object.logs))
                        throw TypeError(".protocol.EthereumTxDetails.logs: array expected");
                    message.logs = [];
                    for (var i = 0; i < object.logs.length; ++i) {
                        if (typeof object.logs[i] !== "object")
                            throw TypeError(".protocol.EthereumTxDetails.logs: object expected");
                        message.logs[i] = $root.protocol.TxLog.fromObject(object.logs[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from an EthereumTxDetails message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.EthereumTxDetails
             * @static
             * @param {protocol.EthereumTxDetails} message EthereumTxDetails
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EthereumTxDetails.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.logs = [];
                if (options.defaults) {
                    object.from = "";
                    object.to = "";
                    object.value = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.gasLimit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.gasLimit = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.gasUsed = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.gasUsed = options.longs === String ? "0" : 0;
                    object.gasPrice = "";
                    object.maxFeePerGas = "";
                    object.maxPriorityFeePerGas = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.nonce = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.nonce = options.longs === String ? "0" : 0;
                    object.type = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.index = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.index = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.status = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.status = options.longs === String ? "0" : 0;
                    object.effectiveFee = "";
                }
                if (message.from != null && message.hasOwnProperty("from"))
                    object.from = message.from;
                if (message.to != null && message.hasOwnProperty("to"))
                    object.to = message.to;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                if (message.gasLimit != null && message.hasOwnProperty("gasLimit"))
                    if (typeof message.gasLimit === "number")
                        object.gasLimit = options.longs === String ? String(message.gasLimit) : message.gasLimit;
                    else
                        object.gasLimit = options.longs === String ? $util.Long.prototype.toString.call(message.gasLimit) : options.longs === Number ? new $util.LongBits(message.gasLimit.low >>> 0, message.gasLimit.high >>> 0).toNumber(true) : message.gasLimit;
                if (message.gasUsed != null && message.hasOwnProperty("gasUsed"))
                    if (typeof message.gasUsed === "number")
                        object.gasUsed = options.longs === String ? String(message.gasUsed) : message.gasUsed;
                    else
                        object.gasUsed = options.longs === String ? $util.Long.prototype.toString.call(message.gasUsed) : options.longs === Number ? new $util.LongBits(message.gasUsed.low >>> 0, message.gasUsed.high >>> 0).toNumber(true) : message.gasUsed;
                if (message.gasPrice != null && message.hasOwnProperty("gasPrice"))
                    object.gasPrice = message.gasPrice;
                if (message.maxFeePerGas != null && message.hasOwnProperty("maxFeePerGas"))
                    object.maxFeePerGas = message.maxFeePerGas;
                if (message.maxPriorityFeePerGas != null && message.hasOwnProperty("maxPriorityFeePerGas"))
                    object.maxPriorityFeePerGas = message.maxPriorityFeePerGas;
                if (message.nonce != null && message.hasOwnProperty("nonce"))
                    if (typeof message.nonce === "number")
                        object.nonce = options.longs === String ? String(message.nonce) : message.nonce;
                    else
                        object.nonce = options.longs === String ? $util.Long.prototype.toString.call(message.nonce) : options.longs === Number ? new $util.LongBits(message.nonce.low >>> 0, message.nonce.high >>> 0).toNumber(true) : message.nonce;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = message.type;
                if (message.index != null && message.hasOwnProperty("index"))
                    if (typeof message.index === "number")
                        object.index = options.longs === String ? String(message.index) : message.index;
                    else
                        object.index = options.longs === String ? $util.Long.prototype.toString.call(message.index) : options.longs === Number ? new $util.LongBits(message.index.low >>> 0, message.index.high >>> 0).toNumber(true) : message.index;
                if (message.status != null && message.hasOwnProperty("status"))
                    if (typeof message.status === "number")
                        object.status = options.longs === String ? String(message.status) : message.status;
                    else
                        object.status = options.longs === String ? $util.Long.prototype.toString.call(message.status) : options.longs === Number ? new $util.LongBits(message.status.low >>> 0, message.status.high >>> 0).toNumber(true) : message.status;
                if (message.effectiveFee != null && message.hasOwnProperty("effectiveFee"))
                    object.effectiveFee = message.effectiveFee;
                if (message.logs && message.logs.length) {
                    object.logs = [];
                    for (var j = 0; j < message.logs.length; ++j)
                        object.logs[j] = $root.protocol.TxLog.toObject(message.logs[j], options);
                }
                return object;
            };
    
            /**
             * Converts this EthereumTxDetails to JSON.
             * @function toJSON
             * @memberof protocol.EthereumTxDetails
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EthereumTxDetails.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return EthereumTxDetails;
        })();
    
        protocol.TronTxDetails = (function() {
    
            /**
             * Properties of a TronTxDetails.
             * @memberof protocol
             * @interface ITronTxDetails
             * @property {string|null} [from] TronTxDetails from
             * @property {string|null} [to] TronTxDetails to
             * @property {string|null} [value] TronTxDetails value
             * @property {Long|null} [fee] TronTxDetails fee
             * @property {Long|null} [feeLimit] TronTxDetails feeLimit
             * @property {Long|null} [timestamp] TronTxDetails timestamp
             * @property {Long|null} [expiration] TronTxDetails expiration
             * @property {string|null} [result] TronTxDetails result
             * @property {protocol.TronTxDetails.IResource|null} [resource] TronTxDetails resource
             * @property {string|null} [contractType] TronTxDetails contractType
             * @property {Array.<protocol.ITxLog>|null} [logs] TronTxDetails logs
             */
    
            /**
             * Constructs a new TronTxDetails.
             * @memberof protocol
             * @classdesc Represents a TronTxDetails.
             * @implements ITronTxDetails
             * @constructor
             * @param {protocol.ITronTxDetails=} [properties] Properties to set
             */
            function TronTxDetails(properties) {
                this.logs = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronTxDetails from.
             * @member {string} from
             * @memberof protocol.TronTxDetails
             * @instance
             */
            TronTxDetails.prototype.from = "";
    
            /**
             * TronTxDetails to.
             * @member {string} to
             * @memberof protocol.TronTxDetails
             * @instance
             */
            TronTxDetails.prototype.to = "";
    
            /**
             * TronTxDetails value.
             * @member {string} value
             * @memberof protocol.TronTxDetails
             * @instance
             */
            TronTxDetails.prototype.value = "";
    
            /**
             * TronTxDetails fee.
             * @member {Long} fee
             * @memberof protocol.TronTxDetails
             * @instance
             */
            TronTxDetails.prototype.fee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTxDetails feeLimit.
             * @member {Long} feeLimit
             * @memberof protocol.TronTxDetails
             * @instance
             */
            TronTxDetails.prototype.feeLimit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTxDetails timestamp.
             * @member {Long} timestamp
             * @memberof protocol.TronTxDetails
             * @instance
             */
            TronTxDetails.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTxDetails expiration.
             * @member {Long} expiration
             * @memberof protocol.TronTxDetails
             * @instance
             */
            TronTxDetails.prototype.expiration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTxDetails result.
             * @member {string} result
             * @memberof protocol.TronTxDetails
             * @instance
             */
            TronTxDetails.prototype.result = "";
    
            /**
             * TronTxDetails resource.
             * @member {protocol.TronTxDetails.IResource|null|undefined} resource
             * @memberof protocol.TronTxDetails
             * @instance
             */
            TronTxDetails.prototype.resource = null;
    
            /**
             * TronTxDetails contractType.
             * @member {string} contractType
             * @memberof protocol.TronTxDetails
             * @instance
             */
            TronTxDetails.prototype.contractType = "";
    
            /**
             * TronTxDetails logs.
             * @member {Array.<protocol.ITxLog>} logs
             * @memberof protocol.TronTxDetails
             * @instance
             */
            TronTxDetails.prototype.logs = $util.emptyArray;
    
            /**
             * Creates a new TronTxDetails instance using the specified properties.
             * @function create
             * @memberof protocol.TronTxDetails
             * @static
             * @param {protocol.ITronTxDetails=} [properties] Properties to set
             * @returns {protocol.TronTxDetails} TronTxDetails instance
             */
            TronTxDetails.create = function create(properties) {
                return new TronTxDetails(properties);
            };
    
            /**
             * Encodes the specified TronTxDetails message. Does not implicitly {@link protocol.TronTxDetails.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronTxDetails
             * @static
             * @param {protocol.ITronTxDetails} message TronTxDetails message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronTxDetails.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.from);
                if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.to);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
                if (message.fee != null && Object.hasOwnProperty.call(message, "fee"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.fee);
                if (message.feeLimit != null && Object.hasOwnProperty.call(message, "feeLimit"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.feeLimit);
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.timestamp);
                if (message.expiration != null && Object.hasOwnProperty.call(message, "expiration"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.expiration);
                if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.result);
                if (message.resource != null && Object.hasOwnProperty.call(message, "resource"))
                    $root.protocol.TronTxDetails.Resource.encode(message.resource, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.contractType != null && Object.hasOwnProperty.call(message, "contractType"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.contractType);
                if (message.logs != null && message.logs.length)
                    for (var i = 0; i < message.logs.length; ++i)
                        $root.protocol.TxLog.encode(message.logs[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TronTxDetails message, length delimited. Does not implicitly {@link protocol.TronTxDetails.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronTxDetails
             * @static
             * @param {protocol.ITronTxDetails} message TronTxDetails message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronTxDetails.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronTxDetails message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronTxDetails
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronTxDetails} TronTxDetails
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronTxDetails.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronTxDetails();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.from = reader.string();
                        break;
                    case 2:
                        message.to = reader.string();
                        break;
                    case 3:
                        message.value = reader.string();
                        break;
                    case 4:
                        message.fee = reader.int64();
                        break;
                    case 5:
                        message.feeLimit = reader.int64();
                        break;
                    case 6:
                        message.timestamp = reader.int64();
                        break;
                    case 7:
                        message.expiration = reader.int64();
                        break;
                    case 8:
                        message.result = reader.string();
                        break;
                    case 9:
                        message.resource = $root.protocol.TronTxDetails.Resource.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.contractType = reader.string();
                        break;
                    case 11:
                        if (!(message.logs && message.logs.length))
                            message.logs = [];
                        message.logs.push($root.protocol.TxLog.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronTxDetails message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronTxDetails
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronTxDetails} TronTxDetails
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronTxDetails.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronTxDetails message.
             * @function verify
             * @memberof protocol.TronTxDetails
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronTxDetails.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.from != null && message.hasOwnProperty("from"))
                    if (!$util.isString(message.from))
                        return "from: string expected";
                if (message.to != null && message.hasOwnProperty("to"))
                    if (!$util.isString(message.to))
                        return "to: string expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isString(message.value))
                        return "value: string expected";
                if (message.fee != null && message.hasOwnProperty("fee"))
                    if (!$util.isInteger(message.fee) && !(message.fee && $util.isInteger(message.fee.low) && $util.isInteger(message.fee.high)))
                        return "fee: integer|Long expected";
                if (message.feeLimit != null && message.hasOwnProperty("feeLimit"))
                    if (!$util.isInteger(message.feeLimit) && !(message.feeLimit && $util.isInteger(message.feeLimit.low) && $util.isInteger(message.feeLimit.high)))
                        return "feeLimit: integer|Long expected";
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                        return "timestamp: integer|Long expected";
                if (message.expiration != null && message.hasOwnProperty("expiration"))
                    if (!$util.isInteger(message.expiration) && !(message.expiration && $util.isInteger(message.expiration.low) && $util.isInteger(message.expiration.high)))
                        return "expiration: integer|Long expected";
                if (message.result != null && message.hasOwnProperty("result"))
                    if (!$util.isString(message.result))
                        return "result: string expected";
                if (message.resource != null && message.hasOwnProperty("resource")) {
                    var error = $root.protocol.TronTxDetails.Resource.verify(message.resource);
                    if (error)
                        return "resource." + error;
                }
                if (message.contractType != null && message.hasOwnProperty("contractType"))
                    if (!$util.isString(message.contractType))
                        return "contractType: string expected";
                if (message.logs != null && message.hasOwnProperty("logs")) {
                    if (!Array.isArray(message.logs))
                        return "logs: array expected";
                    for (var i = 0; i < message.logs.length; ++i) {
                        var error = $root.protocol.TxLog.verify(message.logs[i]);
                        if (error)
                            return "logs." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a TronTxDetails message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronTxDetails
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronTxDetails} TronTxDetails
             */
            TronTxDetails.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronTxDetails)
                    return object;
                var message = new $root.protocol.TronTxDetails();
                if (object.from != null)
                    message.from = String(object.from);
                if (object.to != null)
                    message.to = String(object.to);
                if (object.value != null)
                    message.value = String(object.value);
                if (object.fee != null)
                    if ($util.Long)
                        (message.fee = $util.Long.fromValue(object.fee)).unsigned = false;
                    else if (typeof object.fee === "string")
                        message.fee = parseInt(object.fee, 10);
                    else if (typeof object.fee === "number")
                        message.fee = object.fee;
                    else if (typeof object.fee === "object")
                        message.fee = new $util.LongBits(object.fee.low >>> 0, object.fee.high >>> 0).toNumber();
                if (object.feeLimit != null)
                    if ($util.Long)
                        (message.feeLimit = $util.Long.fromValue(object.feeLimit)).unsigned = false;
                    else if (typeof object.feeLimit === "string")
                        message.feeLimit = parseInt(object.feeLimit, 10);
                    else if (typeof object.feeLimit === "number")
                        message.feeLimit = object.feeLimit;
                    else if (typeof object.feeLimit === "object")
                        message.feeLimit = new $util.LongBits(object.feeLimit.low >>> 0, object.feeLimit.high >>> 0).toNumber();
                if (object.timestamp != null)
                    if ($util.Long)
                        (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                    else if (typeof object.timestamp === "string")
                        message.timestamp = parseInt(object.timestamp, 10);
                    else if (typeof object.timestamp === "number")
                        message.timestamp = object.timestamp;
                    else if (typeof object.timestamp === "object")
                        message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
                if (object.expiration != null)
                    if ($util.Long)
                        (message.expiration = $util.Long.fromValue(object.expiration)).unsigned = false;
                    else if (typeof object.expiration === "string")
                        message.expiration = parseInt(object.expiration, 10);
                    else if (typeof object.expiration === "number")
                        message.expiration = object.expiration;
                    else if (typeof object.expiration === "object")
                        message.expiration = new $util.LongBits(object.expiration.low >>> 0, object.expiration.high >>> 0).toNumber();
                if (object.result != null)
                    message.result = String(object.result);
                if (object.resource != null) {
                    if (typeof object.resource !== "object")
                        throw TypeError(".protocol.TronTxDetails.resource: object expected");
                    message.resource = $root.protocol.TronTxDetails.Resource.fromObject(object.resource);
                }
                if (object.contractType != null)
                    message.contractType = String(object.contractType);
                if (object.logs) {
                    if (!Array.isArray(object.logs))
                        throw TypeError(".protocol.TronTxDetails.logs: array expected");
                    message.logs = [];
                    for (var i = 0; i < object.logs.length; ++i) {
                        if (typeof object.logs[i] !== "object")
                            throw TypeError(".protocol.TronTxDetails.logs: object expected");
                        message.logs[i] = $root.protocol.TxLog.fromObject(object.logs[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TronTxDetails message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronTxDetails
             * @static
             * @param {protocol.TronTxDetails} message TronTxDetails
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronTxDetails.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.logs = [];
                if (options.defaults) {
                    object.from = "";
                    object.to = "";
                    object.value = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.fee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.fee = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.feeLimit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.feeLimit = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestamp = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.expiration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.expiration = options.longs === String ? "0" : 0;
                    object.result = "";
                    object.resource = null;
                    object.contractType = "";
                }
                if (message.from != null && message.hasOwnProperty("from"))
                    object.from = message.from;
                if (message.to != null && message.hasOwnProperty("to"))
                    object.to = message.to;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                if (message.fee != null && message.hasOwnProperty("fee"))
                    if (typeof message.fee === "number")
                        object.fee = options.longs === String ? String(message.fee) : message.fee;
                    else
                        object.fee = options.longs === String ? $util.Long.prototype.toString.call(message.fee) : options.longs === Number ? new $util.LongBits(message.fee.low >>> 0, message.fee.high >>> 0).toNumber() : message.fee;
                if (message.feeLimit != null && message.hasOwnProperty("feeLimit"))
                    if (typeof message.feeLimit === "number")
                        object.feeLimit = options.longs === String ? String(message.feeLimit) : message.feeLimit;
                    else
                        object.feeLimit = options.longs === String ? $util.Long.prototype.toString.call(message.feeLimit) : options.longs === Number ? new $util.LongBits(message.feeLimit.low >>> 0, message.feeLimit.high >>> 0).toNumber() : message.feeLimit;
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (typeof message.timestamp === "number")
                        object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                    else
                        object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
                if (message.expiration != null && message.hasOwnProperty("expiration"))
                    if (typeof message.expiration === "number")
                        object.expiration = options.longs === String ? String(message.expiration) : message.expiration;
                    else
                        object.expiration = options.longs === String ? $util.Long.prototype.toString.call(message.expiration) : options.longs === Number ? new $util.LongBits(message.expiration.low >>> 0, message.expiration.high >>> 0).toNumber() : message.expiration;
                if (message.result != null && message.hasOwnProperty("result"))
                    object.result = message.result;
                if (message.resource != null && message.hasOwnProperty("resource"))
                    object.resource = $root.protocol.TronTxDetails.Resource.toObject(message.resource, options);
                if (message.contractType != null && message.hasOwnProperty("contractType"))
                    object.contractType = message.contractType;
                if (message.logs && message.logs.length) {
                    object.logs = [];
                    for (var j = 0; j < message.logs.length; ++j)
                        object.logs[j] = $root.protocol.TxLog.toObject(message.logs[j], options);
                }
                return object;
            };
    
            /**
             * Converts this TronTxDetails to JSON.
             * @function toJSON
             * @memberof protocol.TronTxDetails
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronTxDetails.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            TronTxDetails.Resource = (function() {
    
                /**
                 * Properties of a Resource.
                 * @memberof protocol.TronTxDetails
                 * @interface IResource
                 * @property {Long|null} [energyUsage] Resource energyUsage
                 * @property {Long|null} [energyFee] Resource energyFee
                 * @property {Long|null} [originEnergyUsage] Resource originEnergyUsage
                 * @property {Long|null} [energyUsageTotal] Resource energyUsageTotal
                 * @property {Long|null} [netUsage] Resource netUsage
                 * @property {Long|null} [netFee] Resource netFee
                 */
    
                /**
                 * Constructs a new Resource.
                 * @memberof protocol.TronTxDetails
                 * @classdesc Represents a Resource.
                 * @implements IResource
                 * @constructor
                 * @param {protocol.TronTxDetails.IResource=} [properties] Properties to set
                 */
                function Resource(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Resource energyUsage.
                 * @member {Long} energyUsage
                 * @memberof protocol.TronTxDetails.Resource
                 * @instance
                 */
                Resource.prototype.energyUsage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Resource energyFee.
                 * @member {Long} energyFee
                 * @memberof protocol.TronTxDetails.Resource
                 * @instance
                 */
                Resource.prototype.energyFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Resource originEnergyUsage.
                 * @member {Long} originEnergyUsage
                 * @memberof protocol.TronTxDetails.Resource
                 * @instance
                 */
                Resource.prototype.originEnergyUsage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Resource energyUsageTotal.
                 * @member {Long} energyUsageTotal
                 * @memberof protocol.TronTxDetails.Resource
                 * @instance
                 */
                Resource.prototype.energyUsageTotal = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Resource netUsage.
                 * @member {Long} netUsage
                 * @memberof protocol.TronTxDetails.Resource
                 * @instance
                 */
                Resource.prototype.netUsage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Resource netFee.
                 * @member {Long} netFee
                 * @memberof protocol.TronTxDetails.Resource
                 * @instance
                 */
                Resource.prototype.netFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new Resource instance using the specified properties.
                 * @function create
                 * @memberof protocol.TronTxDetails.Resource
                 * @static
                 * @param {protocol.TronTxDetails.IResource=} [properties] Properties to set
                 * @returns {protocol.TronTxDetails.Resource} Resource instance
                 */
                Resource.create = function create(properties) {
                    return new Resource(properties);
                };
    
                /**
                 * Encodes the specified Resource message. Does not implicitly {@link protocol.TronTxDetails.Resource.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.TronTxDetails.Resource
                 * @static
                 * @param {protocol.TronTxDetails.IResource} message Resource message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Resource.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.energyUsage != null && Object.hasOwnProperty.call(message, "energyUsage"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.energyUsage);
                    if (message.energyFee != null && Object.hasOwnProperty.call(message, "energyFee"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.energyFee);
                    if (message.originEnergyUsage != null && Object.hasOwnProperty.call(message, "originEnergyUsage"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.originEnergyUsage);
                    if (message.energyUsageTotal != null && Object.hasOwnProperty.call(message, "energyUsageTotal"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.energyUsageTotal);
                    if (message.netUsage != null && Object.hasOwnProperty.call(message, "netUsage"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.netUsage);
                    if (message.netFee != null && Object.hasOwnProperty.call(message, "netFee"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int64(message.netFee);
                    return writer;
                };
    
                /**
                 * Encodes the specified Resource message, length delimited. Does not implicitly {@link protocol.TronTxDetails.Resource.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.TronTxDetails.Resource
                 * @static
                 * @param {protocol.TronTxDetails.IResource} message Resource message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Resource.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Resource message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.TronTxDetails.Resource
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.TronTxDetails.Resource} Resource
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Resource.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronTxDetails.Resource();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.energyUsage = reader.int64();
                            break;
                        case 2:
                            message.energyFee = reader.int64();
                            break;
                        case 3:
                            message.originEnergyUsage = reader.int64();
                            break;
                        case 4:
                            message.energyUsageTotal = reader.int64();
                            break;
                        case 5:
                            message.netUsage = reader.int64();
                            break;
                        case 6:
                            message.netFee = reader.int64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Resource message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.TronTxDetails.Resource
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.TronTxDetails.Resource} Resource
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Resource.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Resource message.
                 * @function verify
                 * @memberof protocol.TronTxDetails.Resource
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Resource.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.energyUsage != null && message.hasOwnProperty("energyUsage"))
                        if (!$util.isInteger(message.energyUsage) && !(message.energyUsage && $util.isInteger(message.energyUsage.low) && $util.isInteger(message.energyUsage.high)))
                            return "energyUsage: integer|Long expected";
                    if (message.energyFee != null && message.hasOwnProperty("energyFee"))
                        if (!$util.isInteger(message.energyFee) && !(message.energyFee && $util.isInteger(message.energyFee.low) && $util.isInteger(message.energyFee.high)))
                            return "energyFee: integer|Long expected";
                    if (message.originEnergyUsage != null && message.hasOwnProperty("originEnergyUsage"))
                        if (!$util.isInteger(message.originEnergyUsage) && !(message.originEnergyUsage && $util.isInteger(message.originEnergyUsage.low) && $util.isInteger(message.originEnergyUsage.high)))
                            return "originEnergyUsage: integer|Long expected";
                    if (message.energyUsageTotal != null && message.hasOwnProperty("energyUsageTotal"))
                        if (!$util.isInteger(message.energyUsageTotal) && !(message.energyUsageTotal && $util.isInteger(message.energyUsageTotal.low) && $util.isInteger(message.energyUsageTotal.high)))
                            return "energyUsageTotal: integer|Long expected";
                    if (message.netUsage != null && message.hasOwnProperty("netUsage"))
                        if (!$util.isInteger(message.netUsage) && !(message.netUsage && $util.isInteger(message.netUsage.low) && $util.isInteger(message.netUsage.high)))
                            return "netUsage: integer|Long expected";
                    if (message.netFee != null && message.hasOwnProperty("netFee"))
                        if (!$util.isInteger(message.netFee) && !(message.netFee && $util.isInteger(message.netFee.low) && $util.isInteger(message.netFee.high)))
                            return "netFee: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a Resource message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.TronTxDetails.Resource
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.TronTxDetails.Resource} Resource
                 */
                Resource.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.TronTxDetails.Resource)
                        return object;
                    var message = new $root.protocol.TronTxDetails.Resource();
                    if (object.energyUsage != null)
                        if ($util.Long)
                            (message.energyUsage = $util.Long.fromValue(object.energyUsage)).unsigned = false;
                        else if (typeof object.energyUsage === "string")
                            message.energyUsage = parseInt(object.energyUsage, 10);
                        else if (typeof object.energyUsage === "number")
                            message.energyUsage = object.energyUsage;
                        else if (typeof object.energyUsage === "object")
                            message.energyUsage = new $util.LongBits(object.energyUsage.low >>> 0, object.energyUsage.high >>> 0).toNumber();
                    if (object.energyFee != null)
                        if ($util.Long)
                            (message.energyFee = $util.Long.fromValue(object.energyFee)).unsigned = false;
                        else if (typeof object.energyFee === "string")
                            message.energyFee = parseInt(object.energyFee, 10);
                        else if (typeof object.energyFee === "number")
                            message.energyFee = object.energyFee;
                        else if (typeof object.energyFee === "object")
                            message.energyFee = new $util.LongBits(object.energyFee.low >>> 0, object.energyFee.high >>> 0).toNumber();
                    if (object.originEnergyUsage != null)
                        if ($util.Long)
                            (message.originEnergyUsage = $util.Long.fromValue(object.originEnergyUsage)).unsigned = false;
                        else if (typeof object.originEnergyUsage === "string")
                            message.originEnergyUsage = parseInt(object.originEnergyUsage, 10);
                        else if (typeof object.originEnergyUsage === "number")
                            message.originEnergyUsage = object.originEnergyUsage;
                        else if (typeof object.originEnergyUsage === "object")
                            message.originEnergyUsage = new $util.LongBits(object.originEnergyUsage.low >>> 0, object.originEnergyUsage.high >>> 0).toNumber();
                    if (object.energyUsageTotal != null)
                        if ($util.Long)
                            (message.energyUsageTotal = $util.Long.fromValue(object.energyUsageTotal)).unsigned = false;
                        else if (typeof object.energyUsageTotal === "string")
                            message.energyUsageTotal = parseInt(object.energyUsageTotal, 10);
                        else if (typeof object.energyUsageTotal === "number")
                            message.energyUsageTotal = object.energyUsageTotal;
                        else if (typeof object.energyUsageTotal === "object")
                            message.energyUsageTotal = new $util.LongBits(object.energyUsageTotal.low >>> 0, object.energyUsageTotal.high >>> 0).toNumber();
                    if (object.netUsage != null)
                        if ($util.Long)
                            (message.netUsage = $util.Long.fromValue(object.netUsage)).unsigned = false;
                        else if (typeof object.netUsage === "string")
                            message.netUsage = parseInt(object.netUsage, 10);
                        else if (typeof object.netUsage === "number")
                            message.netUsage = object.netUsage;
                        else if (typeof object.netUsage === "object")
                            message.netUsage = new $util.LongBits(object.netUsage.low >>> 0, object.netUsage.high >>> 0).toNumber();
                    if (object.netFee != null)
                        if ($util.Long)
                            (message.netFee = $util.Long.fromValue(object.netFee)).unsigned = false;
                        else if (typeof object.netFee === "string")
                            message.netFee = parseInt(object.netFee, 10);
                        else if (typeof object.netFee === "number")
                            message.netFee = object.netFee;
                        else if (typeof object.netFee === "object")
                            message.netFee = new $util.LongBits(object.netFee.low >>> 0, object.netFee.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a Resource message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.TronTxDetails.Resource
                 * @static
                 * @param {protocol.TronTxDetails.Resource} message Resource
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Resource.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.energyUsage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.energyUsage = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.energyFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.energyFee = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.originEnergyUsage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.originEnergyUsage = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.energyUsageTotal = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.energyUsageTotal = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.netUsage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.netUsage = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.netFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.netFee = options.longs === String ? "0" : 0;
                    }
                    if (message.energyUsage != null && message.hasOwnProperty("energyUsage"))
                        if (typeof message.energyUsage === "number")
                            object.energyUsage = options.longs === String ? String(message.energyUsage) : message.energyUsage;
                        else
                            object.energyUsage = options.longs === String ? $util.Long.prototype.toString.call(message.energyUsage) : options.longs === Number ? new $util.LongBits(message.energyUsage.low >>> 0, message.energyUsage.high >>> 0).toNumber() : message.energyUsage;
                    if (message.energyFee != null && message.hasOwnProperty("energyFee"))
                        if (typeof message.energyFee === "number")
                            object.energyFee = options.longs === String ? String(message.energyFee) : message.energyFee;
                        else
                            object.energyFee = options.longs === String ? $util.Long.prototype.toString.call(message.energyFee) : options.longs === Number ? new $util.LongBits(message.energyFee.low >>> 0, message.energyFee.high >>> 0).toNumber() : message.energyFee;
                    if (message.originEnergyUsage != null && message.hasOwnProperty("originEnergyUsage"))
                        if (typeof message.originEnergyUsage === "number")
                            object.originEnergyUsage = options.longs === String ? String(message.originEnergyUsage) : message.originEnergyUsage;
                        else
                            object.originEnergyUsage = options.longs === String ? $util.Long.prototype.toString.call(message.originEnergyUsage) : options.longs === Number ? new $util.LongBits(message.originEnergyUsage.low >>> 0, message.originEnergyUsage.high >>> 0).toNumber() : message.originEnergyUsage;
                    if (message.energyUsageTotal != null && message.hasOwnProperty("energyUsageTotal"))
                        if (typeof message.energyUsageTotal === "number")
                            object.energyUsageTotal = options.longs === String ? String(message.energyUsageTotal) : message.energyUsageTotal;
                        else
                            object.energyUsageTotal = options.longs === String ? $util.Long.prototype.toString.call(message.energyUsageTotal) : options.longs === Number ? new $util.LongBits(message.energyUsageTotal.low >>> 0, message.energyUsageTotal.high >>> 0).toNumber() : message.energyUsageTotal;
                    if (message.netUsage != null && message.hasOwnProperty("netUsage"))
                        if (typeof message.netUsage === "number")
                            object.netUsage = options.longs === String ? String(message.netUsage) : message.netUsage;
                        else
                            object.netUsage = options.longs === String ? $util.Long.prototype.toString.call(message.netUsage) : options.longs === Number ? new $util.LongBits(message.netUsage.low >>> 0, message.netUsage.high >>> 0).toNumber() : message.netUsage;
                    if (message.netFee != null && message.hasOwnProperty("netFee"))
                        if (typeof message.netFee === "number")
                            object.netFee = options.longs === String ? String(message.netFee) : message.netFee;
                        else
                            object.netFee = options.longs === String ? $util.Long.prototype.toString.call(message.netFee) : options.longs === Number ? new $util.LongBits(message.netFee.low >>> 0, message.netFee.high >>> 0).toNumber() : message.netFee;
                    return object;
                };
    
                /**
                 * Converts this Resource to JSON.
                 * @function toJSON
                 * @memberof protocol.TronTxDetails.Resource
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Resource.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Resource;
            })();
    
            return TronTxDetails;
        })();
    
        protocol.ModifyTagsRequest = (function() {
    
            /**
             * Properties of a ModifyTagsRequest.
             * @memberof protocol
             * @interface IModifyTagsRequest
             * @property {Array.<protocol.ModifyTagsRequest.IModifyTag>|null} [modifyTags] ModifyTagsRequest modifyTags
             */
    
            /**
             * Constructs a new ModifyTagsRequest.
             * @memberof protocol
             * @classdesc Represents a ModifyTagsRequest.
             * @implements IModifyTagsRequest
             * @constructor
             * @param {protocol.IModifyTagsRequest=} [properties] Properties to set
             */
            function ModifyTagsRequest(properties) {
                this.modifyTags = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ModifyTagsRequest modifyTags.
             * @member {Array.<protocol.ModifyTagsRequest.IModifyTag>} modifyTags
             * @memberof protocol.ModifyTagsRequest
             * @instance
             */
            ModifyTagsRequest.prototype.modifyTags = $util.emptyArray;
    
            /**
             * Creates a new ModifyTagsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ModifyTagsRequest
             * @static
             * @param {protocol.IModifyTagsRequest=} [properties] Properties to set
             * @returns {protocol.ModifyTagsRequest} ModifyTagsRequest instance
             */
            ModifyTagsRequest.create = function create(properties) {
                return new ModifyTagsRequest(properties);
            };
    
            /**
             * Encodes the specified ModifyTagsRequest message. Does not implicitly {@link protocol.ModifyTagsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ModifyTagsRequest
             * @static
             * @param {protocol.IModifyTagsRequest} message ModifyTagsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ModifyTagsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.modifyTags != null && message.modifyTags.length)
                    for (var i = 0; i < message.modifyTags.length; ++i)
                        $root.protocol.ModifyTagsRequest.ModifyTag.encode(message.modifyTags[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ModifyTagsRequest message, length delimited. Does not implicitly {@link protocol.ModifyTagsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ModifyTagsRequest
             * @static
             * @param {protocol.IModifyTagsRequest} message ModifyTagsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ModifyTagsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ModifyTagsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ModifyTagsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ModifyTagsRequest} ModifyTagsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ModifyTagsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ModifyTagsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.modifyTags && message.modifyTags.length))
                            message.modifyTags = [];
                        message.modifyTags.push($root.protocol.ModifyTagsRequest.ModifyTag.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ModifyTagsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ModifyTagsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ModifyTagsRequest} ModifyTagsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ModifyTagsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ModifyTagsRequest message.
             * @function verify
             * @memberof protocol.ModifyTagsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ModifyTagsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.modifyTags != null && message.hasOwnProperty("modifyTags")) {
                    if (!Array.isArray(message.modifyTags))
                        return "modifyTags: array expected";
                    for (var i = 0; i < message.modifyTags.length; ++i) {
                        var error = $root.protocol.ModifyTagsRequest.ModifyTag.verify(message.modifyTags[i]);
                        if (error)
                            return "modifyTags." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ModifyTagsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ModifyTagsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ModifyTagsRequest} ModifyTagsRequest
             */
            ModifyTagsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ModifyTagsRequest)
                    return object;
                var message = new $root.protocol.ModifyTagsRequest();
                if (object.modifyTags) {
                    if (!Array.isArray(object.modifyTags))
                        throw TypeError(".protocol.ModifyTagsRequest.modifyTags: array expected");
                    message.modifyTags = [];
                    for (var i = 0; i < object.modifyTags.length; ++i) {
                        if (typeof object.modifyTags[i] !== "object")
                            throw TypeError(".protocol.ModifyTagsRequest.modifyTags: object expected");
                        message.modifyTags[i] = $root.protocol.ModifyTagsRequest.ModifyTag.fromObject(object.modifyTags[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ModifyTagsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ModifyTagsRequest
             * @static
             * @param {protocol.ModifyTagsRequest} message ModifyTagsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ModifyTagsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.modifyTags = [];
                if (message.modifyTags && message.modifyTags.length) {
                    object.modifyTags = [];
                    for (var j = 0; j < message.modifyTags.length; ++j)
                        object.modifyTags[j] = $root.protocol.ModifyTagsRequest.ModifyTag.toObject(message.modifyTags[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ModifyTagsRequest to JSON.
             * @function toJSON
             * @memberof protocol.ModifyTagsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ModifyTagsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            ModifyTagsRequest.ModifyTag = (function() {
    
                /**
                 * Properties of a ModifyTag.
                 * @memberof protocol.ModifyTagsRequest
                 * @interface IModifyTag
                 * @property {string|null} [tagName] ModifyTag tagName
                 * @property {string|null} [clientId] ModifyTag clientId
                 * @property {Array.<string>|null} [walletIds] ModifyTag walletIds
                 * @property {Array.<string>|null} [addresses] ModifyTag addresses
                 * @property {boolean|null} ["delete"] ModifyTag delete
                 * @property {Array.<number>|null} [txIds] ModifyTag txIds
                 */
    
                /**
                 * Constructs a new ModifyTag.
                 * @memberof protocol.ModifyTagsRequest
                 * @classdesc Represents a ModifyTag.
                 * @implements IModifyTag
                 * @constructor
                 * @param {protocol.ModifyTagsRequest.IModifyTag=} [properties] Properties to set
                 */
                function ModifyTag(properties) {
                    this.walletIds = [];
                    this.addresses = [];
                    this.txIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ModifyTag tagName.
                 * @member {string} tagName
                 * @memberof protocol.ModifyTagsRequest.ModifyTag
                 * @instance
                 */
                ModifyTag.prototype.tagName = "";
    
                /**
                 * ModifyTag clientId.
                 * @member {string} clientId
                 * @memberof protocol.ModifyTagsRequest.ModifyTag
                 * @instance
                 */
                ModifyTag.prototype.clientId = "";
    
                /**
                 * ModifyTag walletIds.
                 * @member {Array.<string>} walletIds
                 * @memberof protocol.ModifyTagsRequest.ModifyTag
                 * @instance
                 */
                ModifyTag.prototype.walletIds = $util.emptyArray;
    
                /**
                 * ModifyTag addresses.
                 * @member {Array.<string>} addresses
                 * @memberof protocol.ModifyTagsRequest.ModifyTag
                 * @instance
                 */
                ModifyTag.prototype.addresses = $util.emptyArray;
    
                /**
                 * ModifyTag delete.
                 * @member {boolean} delete
                 * @memberof protocol.ModifyTagsRequest.ModifyTag
                 * @instance
                 */
                ModifyTag.prototype["delete"] = false;
    
                /**
                 * ModifyTag txIds.
                 * @member {Array.<number>} txIds
                 * @memberof protocol.ModifyTagsRequest.ModifyTag
                 * @instance
                 */
                ModifyTag.prototype.txIds = $util.emptyArray;
    
                /**
                 * Creates a new ModifyTag instance using the specified properties.
                 * @function create
                 * @memberof protocol.ModifyTagsRequest.ModifyTag
                 * @static
                 * @param {protocol.ModifyTagsRequest.IModifyTag=} [properties] Properties to set
                 * @returns {protocol.ModifyTagsRequest.ModifyTag} ModifyTag instance
                 */
                ModifyTag.create = function create(properties) {
                    return new ModifyTag(properties);
                };
    
                /**
                 * Encodes the specified ModifyTag message. Does not implicitly {@link protocol.ModifyTagsRequest.ModifyTag.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.ModifyTagsRequest.ModifyTag
                 * @static
                 * @param {protocol.ModifyTagsRequest.IModifyTag} message ModifyTag message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ModifyTag.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.tagName != null && Object.hasOwnProperty.call(message, "tagName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.tagName);
                    if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.clientId);
                    if (message.walletIds != null && message.walletIds.length)
                        for (var i = 0; i < message.walletIds.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.walletIds[i]);
                    if (message.addresses != null && message.addresses.length)
                        for (var i = 0; i < message.addresses.length; ++i)
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.addresses[i]);
                    if (message["delete"] != null && Object.hasOwnProperty.call(message, "delete"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message["delete"]);
                    if (message.txIds != null && message.txIds.length) {
                        writer.uint32(/* id 6, wireType 2 =*/50).fork();
                        for (var i = 0; i < message.txIds.length; ++i)
                            writer.uint32(message.txIds[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified ModifyTag message, length delimited. Does not implicitly {@link protocol.ModifyTagsRequest.ModifyTag.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.ModifyTagsRequest.ModifyTag
                 * @static
                 * @param {protocol.ModifyTagsRequest.IModifyTag} message ModifyTag message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ModifyTag.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ModifyTag message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.ModifyTagsRequest.ModifyTag
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.ModifyTagsRequest.ModifyTag} ModifyTag
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ModifyTag.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ModifyTagsRequest.ModifyTag();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.tagName = reader.string();
                            break;
                        case 2:
                            message.clientId = reader.string();
                            break;
                        case 3:
                            if (!(message.walletIds && message.walletIds.length))
                                message.walletIds = [];
                            message.walletIds.push(reader.string());
                            break;
                        case 4:
                            if (!(message.addresses && message.addresses.length))
                                message.addresses = [];
                            message.addresses.push(reader.string());
                            break;
                        case 5:
                            message["delete"] = reader.bool();
                            break;
                        case 6:
                            if (!(message.txIds && message.txIds.length))
                                message.txIds = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.txIds.push(reader.uint32());
                            } else
                                message.txIds.push(reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ModifyTag message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.ModifyTagsRequest.ModifyTag
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.ModifyTagsRequest.ModifyTag} ModifyTag
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ModifyTag.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ModifyTag message.
                 * @function verify
                 * @memberof protocol.ModifyTagsRequest.ModifyTag
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ModifyTag.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tagName != null && message.hasOwnProperty("tagName"))
                        if (!$util.isString(message.tagName))
                            return "tagName: string expected";
                    if (message.clientId != null && message.hasOwnProperty("clientId"))
                        if (!$util.isString(message.clientId))
                            return "clientId: string expected";
                    if (message.walletIds != null && message.hasOwnProperty("walletIds")) {
                        if (!Array.isArray(message.walletIds))
                            return "walletIds: array expected";
                        for (var i = 0; i < message.walletIds.length; ++i)
                            if (!$util.isString(message.walletIds[i]))
                                return "walletIds: string[] expected";
                    }
                    if (message.addresses != null && message.hasOwnProperty("addresses")) {
                        if (!Array.isArray(message.addresses))
                            return "addresses: array expected";
                        for (var i = 0; i < message.addresses.length; ++i)
                            if (!$util.isString(message.addresses[i]))
                                return "addresses: string[] expected";
                    }
                    if (message["delete"] != null && message.hasOwnProperty("delete"))
                        if (typeof message["delete"] !== "boolean")
                            return "delete: boolean expected";
                    if (message.txIds != null && message.hasOwnProperty("txIds")) {
                        if (!Array.isArray(message.txIds))
                            return "txIds: array expected";
                        for (var i = 0; i < message.txIds.length; ++i)
                            if (!$util.isInteger(message.txIds[i]))
                                return "txIds: integer[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a ModifyTag message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.ModifyTagsRequest.ModifyTag
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.ModifyTagsRequest.ModifyTag} ModifyTag
                 */
                ModifyTag.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.ModifyTagsRequest.ModifyTag)
                        return object;
                    var message = new $root.protocol.ModifyTagsRequest.ModifyTag();
                    if (object.tagName != null)
                        message.tagName = String(object.tagName);
                    if (object.clientId != null)
                        message.clientId = String(object.clientId);
                    if (object.walletIds) {
                        if (!Array.isArray(object.walletIds))
                            throw TypeError(".protocol.ModifyTagsRequest.ModifyTag.walletIds: array expected");
                        message.walletIds = [];
                        for (var i = 0; i < object.walletIds.length; ++i)
                            message.walletIds[i] = String(object.walletIds[i]);
                    }
                    if (object.addresses) {
                        if (!Array.isArray(object.addresses))
                            throw TypeError(".protocol.ModifyTagsRequest.ModifyTag.addresses: array expected");
                        message.addresses = [];
                        for (var i = 0; i < object.addresses.length; ++i)
                            message.addresses[i] = String(object.addresses[i]);
                    }
                    if (object["delete"] != null)
                        message["delete"] = Boolean(object["delete"]);
                    if (object.txIds) {
                        if (!Array.isArray(object.txIds))
                            throw TypeError(".protocol.ModifyTagsRequest.ModifyTag.txIds: array expected");
                        message.txIds = [];
                        for (var i = 0; i < object.txIds.length; ++i)
                            message.txIds[i] = object.txIds[i] >>> 0;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ModifyTag message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.ModifyTagsRequest.ModifyTag
                 * @static
                 * @param {protocol.ModifyTagsRequest.ModifyTag} message ModifyTag
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ModifyTag.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.walletIds = [];
                        object.addresses = [];
                        object.txIds = [];
                    }
                    if (options.defaults) {
                        object.tagName = "";
                        object.clientId = "";
                        object["delete"] = false;
                    }
                    if (message.tagName != null && message.hasOwnProperty("tagName"))
                        object.tagName = message.tagName;
                    if (message.clientId != null && message.hasOwnProperty("clientId"))
                        object.clientId = message.clientId;
                    if (message.walletIds && message.walletIds.length) {
                        object.walletIds = [];
                        for (var j = 0; j < message.walletIds.length; ++j)
                            object.walletIds[j] = message.walletIds[j];
                    }
                    if (message.addresses && message.addresses.length) {
                        object.addresses = [];
                        for (var j = 0; j < message.addresses.length; ++j)
                            object.addresses[j] = message.addresses[j];
                    }
                    if (message["delete"] != null && message.hasOwnProperty("delete"))
                        object["delete"] = message["delete"];
                    if (message.txIds && message.txIds.length) {
                        object.txIds = [];
                        for (var j = 0; j < message.txIds.length; ++j)
                            object.txIds[j] = message.txIds[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this ModifyTag to JSON.
                 * @function toJSON
                 * @memberof protocol.ModifyTagsRequest.ModifyTag
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ModifyTag.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return ModifyTag;
            })();
    
            return ModifyTagsRequest;
        })();
    
        protocol.ModifyTagsResponse = (function() {
    
            /**
             * Properties of a ModifyTagsResponse.
             * @memberof protocol
             * @interface IModifyTagsResponse
             */
    
            /**
             * Constructs a new ModifyTagsResponse.
             * @memberof protocol
             * @classdesc Represents a ModifyTagsResponse.
             * @implements IModifyTagsResponse
             * @constructor
             * @param {protocol.IModifyTagsResponse=} [properties] Properties to set
             */
            function ModifyTagsResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new ModifyTagsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ModifyTagsResponse
             * @static
             * @param {protocol.IModifyTagsResponse=} [properties] Properties to set
             * @returns {protocol.ModifyTagsResponse} ModifyTagsResponse instance
             */
            ModifyTagsResponse.create = function create(properties) {
                return new ModifyTagsResponse(properties);
            };
    
            /**
             * Encodes the specified ModifyTagsResponse message. Does not implicitly {@link protocol.ModifyTagsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ModifyTagsResponse
             * @static
             * @param {protocol.IModifyTagsResponse} message ModifyTagsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ModifyTagsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified ModifyTagsResponse message, length delimited. Does not implicitly {@link protocol.ModifyTagsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ModifyTagsResponse
             * @static
             * @param {protocol.IModifyTagsResponse} message ModifyTagsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ModifyTagsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ModifyTagsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ModifyTagsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ModifyTagsResponse} ModifyTagsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ModifyTagsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ModifyTagsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ModifyTagsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ModifyTagsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ModifyTagsResponse} ModifyTagsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ModifyTagsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ModifyTagsResponse message.
             * @function verify
             * @memberof protocol.ModifyTagsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ModifyTagsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a ModifyTagsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ModifyTagsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ModifyTagsResponse} ModifyTagsResponse
             */
            ModifyTagsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ModifyTagsResponse)
                    return object;
                return new $root.protocol.ModifyTagsResponse();
            };
    
            /**
             * Creates a plain object from a ModifyTagsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ModifyTagsResponse
             * @static
             * @param {protocol.ModifyTagsResponse} message ModifyTagsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ModifyTagsResponse.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this ModifyTagsResponse to JSON.
             * @function toJSON
             * @memberof protocol.ModifyTagsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ModifyTagsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ModifyTagsResponse;
        })();
    
        protocol.ListTagsRequest = (function() {
    
            /**
             * Properties of a ListTagsRequest.
             * @memberof protocol
             * @interface IListTagsRequest
             * @property {Array.<string>|null} [filterClientIds] ListTagsRequest filterClientIds
             * @property {Array.<string>|null} [filterWalletIds] ListTagsRequest filterWalletIds
             * @property {Array.<string>|null} [filterAddresses] ListTagsRequest filterAddresses
             * @property {Array.<string>|null} [filterTagNames] ListTagsRequest filterTagNames
             * @property {Array.<number>|null} [filterTxIds] ListTagsRequest filterTxIds
             */
    
            /**
             * Constructs a new ListTagsRequest.
             * @memberof protocol
             * @classdesc Represents a ListTagsRequest.
             * @implements IListTagsRequest
             * @constructor
             * @param {protocol.IListTagsRequest=} [properties] Properties to set
             */
            function ListTagsRequest(properties) {
                this.filterClientIds = [];
                this.filterWalletIds = [];
                this.filterAddresses = [];
                this.filterTagNames = [];
                this.filterTxIds = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ListTagsRequest filterClientIds.
             * @member {Array.<string>} filterClientIds
             * @memberof protocol.ListTagsRequest
             * @instance
             */
            ListTagsRequest.prototype.filterClientIds = $util.emptyArray;
    
            /**
             * ListTagsRequest filterWalletIds.
             * @member {Array.<string>} filterWalletIds
             * @memberof protocol.ListTagsRequest
             * @instance
             */
            ListTagsRequest.prototype.filterWalletIds = $util.emptyArray;
    
            /**
             * ListTagsRequest filterAddresses.
             * @member {Array.<string>} filterAddresses
             * @memberof protocol.ListTagsRequest
             * @instance
             */
            ListTagsRequest.prototype.filterAddresses = $util.emptyArray;
    
            /**
             * ListTagsRequest filterTagNames.
             * @member {Array.<string>} filterTagNames
             * @memberof protocol.ListTagsRequest
             * @instance
             */
            ListTagsRequest.prototype.filterTagNames = $util.emptyArray;
    
            /**
             * ListTagsRequest filterTxIds.
             * @member {Array.<number>} filterTxIds
             * @memberof protocol.ListTagsRequest
             * @instance
             */
            ListTagsRequest.prototype.filterTxIds = $util.emptyArray;
    
            /**
             * Creates a new ListTagsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ListTagsRequest
             * @static
             * @param {protocol.IListTagsRequest=} [properties] Properties to set
             * @returns {protocol.ListTagsRequest} ListTagsRequest instance
             */
            ListTagsRequest.create = function create(properties) {
                return new ListTagsRequest(properties);
            };
    
            /**
             * Encodes the specified ListTagsRequest message. Does not implicitly {@link protocol.ListTagsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ListTagsRequest
             * @static
             * @param {protocol.IListTagsRequest} message ListTagsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListTagsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.filterClientIds != null && message.filterClientIds.length)
                    for (var i = 0; i < message.filterClientIds.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.filterClientIds[i]);
                if (message.filterWalletIds != null && message.filterWalletIds.length)
                    for (var i = 0; i < message.filterWalletIds.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.filterWalletIds[i]);
                if (message.filterAddresses != null && message.filterAddresses.length)
                    for (var i = 0; i < message.filterAddresses.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.filterAddresses[i]);
                if (message.filterTagNames != null && message.filterTagNames.length)
                    for (var i = 0; i < message.filterTagNames.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.filterTagNames[i]);
                if (message.filterTxIds != null && message.filterTxIds.length) {
                    writer.uint32(/* id 5, wireType 2 =*/42).fork();
                    for (var i = 0; i < message.filterTxIds.length; ++i)
                        writer.uint32(message.filterTxIds[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified ListTagsRequest message, length delimited. Does not implicitly {@link protocol.ListTagsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ListTagsRequest
             * @static
             * @param {protocol.IListTagsRequest} message ListTagsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListTagsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ListTagsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ListTagsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ListTagsRequest} ListTagsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListTagsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ListTagsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.filterClientIds && message.filterClientIds.length))
                            message.filterClientIds = [];
                        message.filterClientIds.push(reader.string());
                        break;
                    case 2:
                        if (!(message.filterWalletIds && message.filterWalletIds.length))
                            message.filterWalletIds = [];
                        message.filterWalletIds.push(reader.string());
                        break;
                    case 3:
                        if (!(message.filterAddresses && message.filterAddresses.length))
                            message.filterAddresses = [];
                        message.filterAddresses.push(reader.string());
                        break;
                    case 4:
                        if (!(message.filterTagNames && message.filterTagNames.length))
                            message.filterTagNames = [];
                        message.filterTagNames.push(reader.string());
                        break;
                    case 5:
                        if (!(message.filterTxIds && message.filterTxIds.length))
                            message.filterTxIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.filterTxIds.push(reader.uint32());
                        } else
                            message.filterTxIds.push(reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ListTagsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ListTagsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ListTagsRequest} ListTagsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListTagsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ListTagsRequest message.
             * @function verify
             * @memberof protocol.ListTagsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ListTagsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.filterClientIds != null && message.hasOwnProperty("filterClientIds")) {
                    if (!Array.isArray(message.filterClientIds))
                        return "filterClientIds: array expected";
                    for (var i = 0; i < message.filterClientIds.length; ++i)
                        if (!$util.isString(message.filterClientIds[i]))
                            return "filterClientIds: string[] expected";
                }
                if (message.filterWalletIds != null && message.hasOwnProperty("filterWalletIds")) {
                    if (!Array.isArray(message.filterWalletIds))
                        return "filterWalletIds: array expected";
                    for (var i = 0; i < message.filterWalletIds.length; ++i)
                        if (!$util.isString(message.filterWalletIds[i]))
                            return "filterWalletIds: string[] expected";
                }
                if (message.filterAddresses != null && message.hasOwnProperty("filterAddresses")) {
                    if (!Array.isArray(message.filterAddresses))
                        return "filterAddresses: array expected";
                    for (var i = 0; i < message.filterAddresses.length; ++i)
                        if (!$util.isString(message.filterAddresses[i]))
                            return "filterAddresses: string[] expected";
                }
                if (message.filterTagNames != null && message.hasOwnProperty("filterTagNames")) {
                    if (!Array.isArray(message.filterTagNames))
                        return "filterTagNames: array expected";
                    for (var i = 0; i < message.filterTagNames.length; ++i)
                        if (!$util.isString(message.filterTagNames[i]))
                            return "filterTagNames: string[] expected";
                }
                if (message.filterTxIds != null && message.hasOwnProperty("filterTxIds")) {
                    if (!Array.isArray(message.filterTxIds))
                        return "filterTxIds: array expected";
                    for (var i = 0; i < message.filterTxIds.length; ++i)
                        if (!$util.isInteger(message.filterTxIds[i]))
                            return "filterTxIds: integer[] expected";
                }
                return null;
            };
    
            /**
             * Creates a ListTagsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ListTagsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ListTagsRequest} ListTagsRequest
             */
            ListTagsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ListTagsRequest)
                    return object;
                var message = new $root.protocol.ListTagsRequest();
                if (object.filterClientIds) {
                    if (!Array.isArray(object.filterClientIds))
                        throw TypeError(".protocol.ListTagsRequest.filterClientIds: array expected");
                    message.filterClientIds = [];
                    for (var i = 0; i < object.filterClientIds.length; ++i)
                        message.filterClientIds[i] = String(object.filterClientIds[i]);
                }
                if (object.filterWalletIds) {
                    if (!Array.isArray(object.filterWalletIds))
                        throw TypeError(".protocol.ListTagsRequest.filterWalletIds: array expected");
                    message.filterWalletIds = [];
                    for (var i = 0; i < object.filterWalletIds.length; ++i)
                        message.filterWalletIds[i] = String(object.filterWalletIds[i]);
                }
                if (object.filterAddresses) {
                    if (!Array.isArray(object.filterAddresses))
                        throw TypeError(".protocol.ListTagsRequest.filterAddresses: array expected");
                    message.filterAddresses = [];
                    for (var i = 0; i < object.filterAddresses.length; ++i)
                        message.filterAddresses[i] = String(object.filterAddresses[i]);
                }
                if (object.filterTagNames) {
                    if (!Array.isArray(object.filterTagNames))
                        throw TypeError(".protocol.ListTagsRequest.filterTagNames: array expected");
                    message.filterTagNames = [];
                    for (var i = 0; i < object.filterTagNames.length; ++i)
                        message.filterTagNames[i] = String(object.filterTagNames[i]);
                }
                if (object.filterTxIds) {
                    if (!Array.isArray(object.filterTxIds))
                        throw TypeError(".protocol.ListTagsRequest.filterTxIds: array expected");
                    message.filterTxIds = [];
                    for (var i = 0; i < object.filterTxIds.length; ++i)
                        message.filterTxIds[i] = object.filterTxIds[i] >>> 0;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ListTagsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ListTagsRequest
             * @static
             * @param {protocol.ListTagsRequest} message ListTagsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListTagsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.filterClientIds = [];
                    object.filterWalletIds = [];
                    object.filterAddresses = [];
                    object.filterTagNames = [];
                    object.filterTxIds = [];
                }
                if (message.filterClientIds && message.filterClientIds.length) {
                    object.filterClientIds = [];
                    for (var j = 0; j < message.filterClientIds.length; ++j)
                        object.filterClientIds[j] = message.filterClientIds[j];
                }
                if (message.filterWalletIds && message.filterWalletIds.length) {
                    object.filterWalletIds = [];
                    for (var j = 0; j < message.filterWalletIds.length; ++j)
                        object.filterWalletIds[j] = message.filterWalletIds[j];
                }
                if (message.filterAddresses && message.filterAddresses.length) {
                    object.filterAddresses = [];
                    for (var j = 0; j < message.filterAddresses.length; ++j)
                        object.filterAddresses[j] = message.filterAddresses[j];
                }
                if (message.filterTagNames && message.filterTagNames.length) {
                    object.filterTagNames = [];
                    for (var j = 0; j < message.filterTagNames.length; ++j)
                        object.filterTagNames[j] = message.filterTagNames[j];
                }
                if (message.filterTxIds && message.filterTxIds.length) {
                    object.filterTxIds = [];
                    for (var j = 0; j < message.filterTxIds.length; ++j)
                        object.filterTxIds[j] = message.filterTxIds[j];
                }
                return object;
            };
    
            /**
             * Converts this ListTagsRequest to JSON.
             * @function toJSON
             * @memberof protocol.ListTagsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListTagsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ListTagsRequest;
        })();
    
        protocol.ListTagsResponse = (function() {
    
            /**
             * Properties of a ListTagsResponse.
             * @memberof protocol
             * @interface IListTagsResponse
             * @property {Array.<protocol.ListTagsResponse.ITag>|null} [tags] ListTagsResponse tags
             */
    
            /**
             * Constructs a new ListTagsResponse.
             * @memberof protocol
             * @classdesc Represents a ListTagsResponse.
             * @implements IListTagsResponse
             * @constructor
             * @param {protocol.IListTagsResponse=} [properties] Properties to set
             */
            function ListTagsResponse(properties) {
                this.tags = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ListTagsResponse tags.
             * @member {Array.<protocol.ListTagsResponse.ITag>} tags
             * @memberof protocol.ListTagsResponse
             * @instance
             */
            ListTagsResponse.prototype.tags = $util.emptyArray;
    
            /**
             * Creates a new ListTagsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ListTagsResponse
             * @static
             * @param {protocol.IListTagsResponse=} [properties] Properties to set
             * @returns {protocol.ListTagsResponse} ListTagsResponse instance
             */
            ListTagsResponse.create = function create(properties) {
                return new ListTagsResponse(properties);
            };
    
            /**
             * Encodes the specified ListTagsResponse message. Does not implicitly {@link protocol.ListTagsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ListTagsResponse
             * @static
             * @param {protocol.IListTagsResponse} message ListTagsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListTagsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tags != null && message.tags.length)
                    for (var i = 0; i < message.tags.length; ++i)
                        $root.protocol.ListTagsResponse.Tag.encode(message.tags[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ListTagsResponse message, length delimited. Does not implicitly {@link protocol.ListTagsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ListTagsResponse
             * @static
             * @param {protocol.IListTagsResponse} message ListTagsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListTagsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ListTagsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ListTagsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ListTagsResponse} ListTagsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListTagsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ListTagsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.tags && message.tags.length))
                            message.tags = [];
                        message.tags.push($root.protocol.ListTagsResponse.Tag.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ListTagsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ListTagsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ListTagsResponse} ListTagsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListTagsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ListTagsResponse message.
             * @function verify
             * @memberof protocol.ListTagsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ListTagsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tags != null && message.hasOwnProperty("tags")) {
                    if (!Array.isArray(message.tags))
                        return "tags: array expected";
                    for (var i = 0; i < message.tags.length; ++i) {
                        var error = $root.protocol.ListTagsResponse.Tag.verify(message.tags[i]);
                        if (error)
                            return "tags." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ListTagsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ListTagsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ListTagsResponse} ListTagsResponse
             */
            ListTagsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ListTagsResponse)
                    return object;
                var message = new $root.protocol.ListTagsResponse();
                if (object.tags) {
                    if (!Array.isArray(object.tags))
                        throw TypeError(".protocol.ListTagsResponse.tags: array expected");
                    message.tags = [];
                    for (var i = 0; i < object.tags.length; ++i) {
                        if (typeof object.tags[i] !== "object")
                            throw TypeError(".protocol.ListTagsResponse.tags: object expected");
                        message.tags[i] = $root.protocol.ListTagsResponse.Tag.fromObject(object.tags[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ListTagsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ListTagsResponse
             * @static
             * @param {protocol.ListTagsResponse} message ListTagsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListTagsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.tags = [];
                if (message.tags && message.tags.length) {
                    object.tags = [];
                    for (var j = 0; j < message.tags.length; ++j)
                        object.tags[j] = $root.protocol.ListTagsResponse.Tag.toObject(message.tags[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ListTagsResponse to JSON.
             * @function toJSON
             * @memberof protocol.ListTagsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListTagsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            ListTagsResponse.Tag = (function() {
    
                /**
                 * Properties of a Tag.
                 * @memberof protocol.ListTagsResponse
                 * @interface ITag
                 * @property {string|null} [name] Tag name
                 * @property {string|null} [clientId] Tag clientId
                 * @property {Array.<string>|null} [walletIds] Tag walletIds
                 * @property {Array.<string>|null} [addresses] Tag addresses
                 * @property {Array.<number>|null} [txIds] Tag txIds
                 */
    
                /**
                 * Constructs a new Tag.
                 * @memberof protocol.ListTagsResponse
                 * @classdesc Represents a Tag.
                 * @implements ITag
                 * @constructor
                 * @param {protocol.ListTagsResponse.ITag=} [properties] Properties to set
                 */
                function Tag(properties) {
                    this.walletIds = [];
                    this.addresses = [];
                    this.txIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Tag name.
                 * @member {string} name
                 * @memberof protocol.ListTagsResponse.Tag
                 * @instance
                 */
                Tag.prototype.name = "";
    
                /**
                 * Tag clientId.
                 * @member {string} clientId
                 * @memberof protocol.ListTagsResponse.Tag
                 * @instance
                 */
                Tag.prototype.clientId = "";
    
                /**
                 * Tag walletIds.
                 * @member {Array.<string>} walletIds
                 * @memberof protocol.ListTagsResponse.Tag
                 * @instance
                 */
                Tag.prototype.walletIds = $util.emptyArray;
    
                /**
                 * Tag addresses.
                 * @member {Array.<string>} addresses
                 * @memberof protocol.ListTagsResponse.Tag
                 * @instance
                 */
                Tag.prototype.addresses = $util.emptyArray;
    
                /**
                 * Tag txIds.
                 * @member {Array.<number>} txIds
                 * @memberof protocol.ListTagsResponse.Tag
                 * @instance
                 */
                Tag.prototype.txIds = $util.emptyArray;
    
                /**
                 * Creates a new Tag instance using the specified properties.
                 * @function create
                 * @memberof protocol.ListTagsResponse.Tag
                 * @static
                 * @param {protocol.ListTagsResponse.ITag=} [properties] Properties to set
                 * @returns {protocol.ListTagsResponse.Tag} Tag instance
                 */
                Tag.create = function create(properties) {
                    return new Tag(properties);
                };
    
                /**
                 * Encodes the specified Tag message. Does not implicitly {@link protocol.ListTagsResponse.Tag.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.ListTagsResponse.Tag
                 * @static
                 * @param {protocol.ListTagsResponse.ITag} message Tag message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Tag.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.clientId);
                    if (message.walletIds != null && message.walletIds.length)
                        for (var i = 0; i < message.walletIds.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.walletIds[i]);
                    if (message.addresses != null && message.addresses.length)
                        for (var i = 0; i < message.addresses.length; ++i)
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.addresses[i]);
                    if (message.txIds != null && message.txIds.length) {
                        writer.uint32(/* id 5, wireType 2 =*/42).fork();
                        for (var i = 0; i < message.txIds.length; ++i)
                            writer.uint32(message.txIds[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified Tag message, length delimited. Does not implicitly {@link protocol.ListTagsResponse.Tag.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.ListTagsResponse.Tag
                 * @static
                 * @param {protocol.ListTagsResponse.ITag} message Tag message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Tag.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Tag message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.ListTagsResponse.Tag
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.ListTagsResponse.Tag} Tag
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Tag.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ListTagsResponse.Tag();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            message.clientId = reader.string();
                            break;
                        case 3:
                            if (!(message.walletIds && message.walletIds.length))
                                message.walletIds = [];
                            message.walletIds.push(reader.string());
                            break;
                        case 4:
                            if (!(message.addresses && message.addresses.length))
                                message.addresses = [];
                            message.addresses.push(reader.string());
                            break;
                        case 5:
                            if (!(message.txIds && message.txIds.length))
                                message.txIds = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.txIds.push(reader.uint32());
                            } else
                                message.txIds.push(reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Tag message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.ListTagsResponse.Tag
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.ListTagsResponse.Tag} Tag
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Tag.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Tag message.
                 * @function verify
                 * @memberof protocol.ListTagsResponse.Tag
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Tag.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.clientId != null && message.hasOwnProperty("clientId"))
                        if (!$util.isString(message.clientId))
                            return "clientId: string expected";
                    if (message.walletIds != null && message.hasOwnProperty("walletIds")) {
                        if (!Array.isArray(message.walletIds))
                            return "walletIds: array expected";
                        for (var i = 0; i < message.walletIds.length; ++i)
                            if (!$util.isString(message.walletIds[i]))
                                return "walletIds: string[] expected";
                    }
                    if (message.addresses != null && message.hasOwnProperty("addresses")) {
                        if (!Array.isArray(message.addresses))
                            return "addresses: array expected";
                        for (var i = 0; i < message.addresses.length; ++i)
                            if (!$util.isString(message.addresses[i]))
                                return "addresses: string[] expected";
                    }
                    if (message.txIds != null && message.hasOwnProperty("txIds")) {
                        if (!Array.isArray(message.txIds))
                            return "txIds: array expected";
                        for (var i = 0; i < message.txIds.length; ++i)
                            if (!$util.isInteger(message.txIds[i]))
                                return "txIds: integer[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a Tag message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.ListTagsResponse.Tag
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.ListTagsResponse.Tag} Tag
                 */
                Tag.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.ListTagsResponse.Tag)
                        return object;
                    var message = new $root.protocol.ListTagsResponse.Tag();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.clientId != null)
                        message.clientId = String(object.clientId);
                    if (object.walletIds) {
                        if (!Array.isArray(object.walletIds))
                            throw TypeError(".protocol.ListTagsResponse.Tag.walletIds: array expected");
                        message.walletIds = [];
                        for (var i = 0; i < object.walletIds.length; ++i)
                            message.walletIds[i] = String(object.walletIds[i]);
                    }
                    if (object.addresses) {
                        if (!Array.isArray(object.addresses))
                            throw TypeError(".protocol.ListTagsResponse.Tag.addresses: array expected");
                        message.addresses = [];
                        for (var i = 0; i < object.addresses.length; ++i)
                            message.addresses[i] = String(object.addresses[i]);
                    }
                    if (object.txIds) {
                        if (!Array.isArray(object.txIds))
                            throw TypeError(".protocol.ListTagsResponse.Tag.txIds: array expected");
                        message.txIds = [];
                        for (var i = 0; i < object.txIds.length; ++i)
                            message.txIds[i] = object.txIds[i] >>> 0;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Tag message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.ListTagsResponse.Tag
                 * @static
                 * @param {protocol.ListTagsResponse.Tag} message Tag
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Tag.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.walletIds = [];
                        object.addresses = [];
                        object.txIds = [];
                    }
                    if (options.defaults) {
                        object.name = "";
                        object.clientId = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.clientId != null && message.hasOwnProperty("clientId"))
                        object.clientId = message.clientId;
                    if (message.walletIds && message.walletIds.length) {
                        object.walletIds = [];
                        for (var j = 0; j < message.walletIds.length; ++j)
                            object.walletIds[j] = message.walletIds[j];
                    }
                    if (message.addresses && message.addresses.length) {
                        object.addresses = [];
                        for (var j = 0; j < message.addresses.length; ++j)
                            object.addresses[j] = message.addresses[j];
                    }
                    if (message.txIds && message.txIds.length) {
                        object.txIds = [];
                        for (var j = 0; j < message.txIds.length; ++j)
                            object.txIds[j] = message.txIds[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this Tag to JSON.
                 * @function toJSON
                 * @memberof protocol.ListTagsResponse.Tag
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Tag.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Tag;
            })();
    
            return ListTagsResponse;
        })();
    
        protocol.ClientAddressesTagsListRequest = (function() {
    
            /**
             * Properties of a ClientAddressesTagsListRequest.
             * @memberof protocol
             * @interface IClientAddressesTagsListRequest
             */
    
            /**
             * Constructs a new ClientAddressesTagsListRequest.
             * @memberof protocol
             * @classdesc Represents a ClientAddressesTagsListRequest.
             * @implements IClientAddressesTagsListRequest
             * @constructor
             * @param {protocol.IClientAddressesTagsListRequest=} [properties] Properties to set
             */
            function ClientAddressesTagsListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new ClientAddressesTagsListRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ClientAddressesTagsListRequest
             * @static
             * @param {protocol.IClientAddressesTagsListRequest=} [properties] Properties to set
             * @returns {protocol.ClientAddressesTagsListRequest} ClientAddressesTagsListRequest instance
             */
            ClientAddressesTagsListRequest.create = function create(properties) {
                return new ClientAddressesTagsListRequest(properties);
            };
    
            /**
             * Encodes the specified ClientAddressesTagsListRequest message. Does not implicitly {@link protocol.ClientAddressesTagsListRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientAddressesTagsListRequest
             * @static
             * @param {protocol.IClientAddressesTagsListRequest} message ClientAddressesTagsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientAddressesTagsListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified ClientAddressesTagsListRequest message, length delimited. Does not implicitly {@link protocol.ClientAddressesTagsListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientAddressesTagsListRequest
             * @static
             * @param {protocol.IClientAddressesTagsListRequest} message ClientAddressesTagsListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientAddressesTagsListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientAddressesTagsListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientAddressesTagsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientAddressesTagsListRequest} ClientAddressesTagsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientAddressesTagsListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientAddressesTagsListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientAddressesTagsListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientAddressesTagsListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientAddressesTagsListRequest} ClientAddressesTagsListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientAddressesTagsListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientAddressesTagsListRequest message.
             * @function verify
             * @memberof protocol.ClientAddressesTagsListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientAddressesTagsListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a ClientAddressesTagsListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientAddressesTagsListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientAddressesTagsListRequest} ClientAddressesTagsListRequest
             */
            ClientAddressesTagsListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientAddressesTagsListRequest)
                    return object;
                return new $root.protocol.ClientAddressesTagsListRequest();
            };
    
            /**
             * Creates a plain object from a ClientAddressesTagsListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientAddressesTagsListRequest
             * @static
             * @param {protocol.ClientAddressesTagsListRequest} message ClientAddressesTagsListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientAddressesTagsListRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this ClientAddressesTagsListRequest to JSON.
             * @function toJSON
             * @memberof protocol.ClientAddressesTagsListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientAddressesTagsListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientAddressesTagsListRequest;
        })();
    
        protocol.ClientAddressesTagsListResponse = (function() {
    
            /**
             * Properties of a ClientAddressesTagsListResponse.
             * @memberof protocol
             * @interface IClientAddressesTagsListResponse
             * @property {Array.<string>|null} [tags] ClientAddressesTagsListResponse tags
             */
    
            /**
             * Constructs a new ClientAddressesTagsListResponse.
             * @memberof protocol
             * @classdesc Represents a ClientAddressesTagsListResponse.
             * @implements IClientAddressesTagsListResponse
             * @constructor
             * @param {protocol.IClientAddressesTagsListResponse=} [properties] Properties to set
             */
            function ClientAddressesTagsListResponse(properties) {
                this.tags = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientAddressesTagsListResponse tags.
             * @member {Array.<string>} tags
             * @memberof protocol.ClientAddressesTagsListResponse
             * @instance
             */
            ClientAddressesTagsListResponse.prototype.tags = $util.emptyArray;
    
            /**
             * Creates a new ClientAddressesTagsListResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ClientAddressesTagsListResponse
             * @static
             * @param {protocol.IClientAddressesTagsListResponse=} [properties] Properties to set
             * @returns {protocol.ClientAddressesTagsListResponse} ClientAddressesTagsListResponse instance
             */
            ClientAddressesTagsListResponse.create = function create(properties) {
                return new ClientAddressesTagsListResponse(properties);
            };
    
            /**
             * Encodes the specified ClientAddressesTagsListResponse message. Does not implicitly {@link protocol.ClientAddressesTagsListResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientAddressesTagsListResponse
             * @static
             * @param {protocol.IClientAddressesTagsListResponse} message ClientAddressesTagsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientAddressesTagsListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tags != null && message.tags.length)
                    for (var i = 0; i < message.tags.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.tags[i]);
                return writer;
            };
    
            /**
             * Encodes the specified ClientAddressesTagsListResponse message, length delimited. Does not implicitly {@link protocol.ClientAddressesTagsListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientAddressesTagsListResponse
             * @static
             * @param {protocol.IClientAddressesTagsListResponse} message ClientAddressesTagsListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientAddressesTagsListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientAddressesTagsListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientAddressesTagsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientAddressesTagsListResponse} ClientAddressesTagsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientAddressesTagsListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientAddressesTagsListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.tags && message.tags.length))
                            message.tags = [];
                        message.tags.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientAddressesTagsListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientAddressesTagsListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientAddressesTagsListResponse} ClientAddressesTagsListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientAddressesTagsListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientAddressesTagsListResponse message.
             * @function verify
             * @memberof protocol.ClientAddressesTagsListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientAddressesTagsListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tags != null && message.hasOwnProperty("tags")) {
                    if (!Array.isArray(message.tags))
                        return "tags: array expected";
                    for (var i = 0; i < message.tags.length; ++i)
                        if (!$util.isString(message.tags[i]))
                            return "tags: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a ClientAddressesTagsListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientAddressesTagsListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientAddressesTagsListResponse} ClientAddressesTagsListResponse
             */
            ClientAddressesTagsListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientAddressesTagsListResponse)
                    return object;
                var message = new $root.protocol.ClientAddressesTagsListResponse();
                if (object.tags) {
                    if (!Array.isArray(object.tags))
                        throw TypeError(".protocol.ClientAddressesTagsListResponse.tags: array expected");
                    message.tags = [];
                    for (var i = 0; i < object.tags.length; ++i)
                        message.tags[i] = String(object.tags[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientAddressesTagsListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientAddressesTagsListResponse
             * @static
             * @param {protocol.ClientAddressesTagsListResponse} message ClientAddressesTagsListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientAddressesTagsListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.tags = [];
                if (message.tags && message.tags.length) {
                    object.tags = [];
                    for (var j = 0; j < message.tags.length; ++j)
                        object.tags[j] = message.tags[j];
                }
                return object;
            };
    
            /**
             * Converts this ClientAddressesTagsListResponse to JSON.
             * @function toJSON
             * @memberof protocol.ClientAddressesTagsListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientAddressesTagsListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientAddressesTagsListResponse;
        })();
    
        /**
         * AMLCheckType enum.
         * @name protocol.AMLCheckType
         * @enum {number}
         * @property {number} UNK_AML_CHECK_TYPE=0 UNK_AML_CHECK_TYPE value
         * @property {number} ADDRESS_AML_CHECK_TYPE=1 ADDRESS_AML_CHECK_TYPE value
         * @property {number} TRANSACTION_AML_CHECK_TYPE=2 TRANSACTION_AML_CHECK_TYPE value
         */
        protocol.AMLCheckType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_AML_CHECK_TYPE"] = 0;
            values[valuesById[1] = "ADDRESS_AML_CHECK_TYPE"] = 1;
            values[valuesById[2] = "TRANSACTION_AML_CHECK_TYPE"] = 2;
            return values;
        })();
    
        protocol.AMLCheckReport = (function() {
    
            /**
             * Properties of a AMLCheckReport.
             * @memberof protocol
             * @interface IAMLCheckReport
             * @property {number|null} [amount] AMLCheckReport amount
             * @property {number|null} [fiat] AMLCheckReport fiat
             * @property {string|null} [fiatCodeEffective] AMLCheckReport fiatCodeEffective
             * @property {number|null} [riskscore] AMLCheckReport riskscore
             * @property {number|null} [riskyVolume] AMLCheckReport riskyVolume
             * @property {number|null} [riskyVolumeFiat] AMLCheckReport riskyVolumeFiat
             * @property {Object.<string,protocol.IAMLSource>|null} [sources] AMLCheckReport sources
             */
    
            /**
             * Constructs a new AMLCheckReport.
             * @memberof protocol
             * @classdesc Represents a AMLCheckReport.
             * @implements IAMLCheckReport
             * @constructor
             * @param {protocol.IAMLCheckReport=} [properties] Properties to set
             */
            function AMLCheckReport(properties) {
                this.sources = {};
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLCheckReport amount.
             * @member {number} amount
             * @memberof protocol.AMLCheckReport
             * @instance
             */
            AMLCheckReport.prototype.amount = 0;
    
            /**
             * AMLCheckReport fiat.
             * @member {number} fiat
             * @memberof protocol.AMLCheckReport
             * @instance
             */
            AMLCheckReport.prototype.fiat = 0;
    
            /**
             * AMLCheckReport fiatCodeEffective.
             * @member {string} fiatCodeEffective
             * @memberof protocol.AMLCheckReport
             * @instance
             */
            AMLCheckReport.prototype.fiatCodeEffective = "";
    
            /**
             * AMLCheckReport riskscore.
             * @member {number} riskscore
             * @memberof protocol.AMLCheckReport
             * @instance
             */
            AMLCheckReport.prototype.riskscore = 0;
    
            /**
             * AMLCheckReport riskyVolume.
             * @member {number} riskyVolume
             * @memberof protocol.AMLCheckReport
             * @instance
             */
            AMLCheckReport.prototype.riskyVolume = 0;
    
            /**
             * AMLCheckReport riskyVolumeFiat.
             * @member {number} riskyVolumeFiat
             * @memberof protocol.AMLCheckReport
             * @instance
             */
            AMLCheckReport.prototype.riskyVolumeFiat = 0;
    
            /**
             * AMLCheckReport sources.
             * @member {Object.<string,protocol.IAMLSource>} sources
             * @memberof protocol.AMLCheckReport
             * @instance
             */
            AMLCheckReport.prototype.sources = $util.emptyObject;
    
            /**
             * Creates a new AMLCheckReport instance using the specified properties.
             * @function create
             * @memberof protocol.AMLCheckReport
             * @static
             * @param {protocol.IAMLCheckReport=} [properties] Properties to set
             * @returns {protocol.AMLCheckReport} AMLCheckReport instance
             */
            AMLCheckReport.create = function create(properties) {
                return new AMLCheckReport(properties);
            };
    
            /**
             * Encodes the specified AMLCheckReport message. Does not implicitly {@link protocol.AMLCheckReport.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLCheckReport
             * @static
             * @param {protocol.IAMLCheckReport} message AMLCheckReport message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLCheckReport.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    writer.uint32(/* id 1, wireType 5 =*/13).float(message.amount);
                if (message.fiat != null && Object.hasOwnProperty.call(message, "fiat"))
                    writer.uint32(/* id 2, wireType 5 =*/21).float(message.fiat);
                if (message.fiatCodeEffective != null && Object.hasOwnProperty.call(message, "fiatCodeEffective"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.fiatCodeEffective);
                if (message.riskscore != null && Object.hasOwnProperty.call(message, "riskscore"))
                    writer.uint32(/* id 4, wireType 5 =*/37).float(message.riskscore);
                if (message.riskyVolume != null && Object.hasOwnProperty.call(message, "riskyVolume"))
                    writer.uint32(/* id 5, wireType 5 =*/45).float(message.riskyVolume);
                if (message.riskyVolumeFiat != null && Object.hasOwnProperty.call(message, "riskyVolumeFiat"))
                    writer.uint32(/* id 6, wireType 5 =*/53).float(message.riskyVolumeFiat);
                if (message.sources != null && Object.hasOwnProperty.call(message, "sources"))
                    for (var keys = Object.keys(message.sources), i = 0; i < keys.length; ++i) {
                        writer.uint32(/* id 7, wireType 2 =*/58).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                        $root.protocol.AMLSource.encode(message.sources[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                    }
                return writer;
            };
    
            /**
             * Encodes the specified AMLCheckReport message, length delimited. Does not implicitly {@link protocol.AMLCheckReport.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLCheckReport
             * @static
             * @param {protocol.IAMLCheckReport} message AMLCheckReport message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLCheckReport.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLCheckReport message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLCheckReport
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLCheckReport} AMLCheckReport
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLCheckReport.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLCheckReport(), key;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.amount = reader.float();
                        break;
                    case 2:
                        message.fiat = reader.float();
                        break;
                    case 3:
                        message.fiatCodeEffective = reader.string();
                        break;
                    case 4:
                        message.riskscore = reader.float();
                        break;
                    case 5:
                        message.riskyVolume = reader.float();
                        break;
                    case 6:
                        message.riskyVolumeFiat = reader.float();
                        break;
                    case 7:
                        reader.skip().pos++;
                        if (message.sources === $util.emptyObject)
                            message.sources = {};
                        key = reader.string();
                        reader.pos++;
                        message.sources[key] = $root.protocol.AMLSource.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLCheckReport message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLCheckReport
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLCheckReport} AMLCheckReport
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLCheckReport.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLCheckReport message.
             * @function verify
             * @memberof protocol.AMLCheckReport
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLCheckReport.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (typeof message.amount !== "number")
                        return "amount: number expected";
                if (message.fiat != null && message.hasOwnProperty("fiat"))
                    if (typeof message.fiat !== "number")
                        return "fiat: number expected";
                if (message.fiatCodeEffective != null && message.hasOwnProperty("fiatCodeEffective"))
                    if (!$util.isString(message.fiatCodeEffective))
                        return "fiatCodeEffective: string expected";
                if (message.riskscore != null && message.hasOwnProperty("riskscore"))
                    if (typeof message.riskscore !== "number")
                        return "riskscore: number expected";
                if (message.riskyVolume != null && message.hasOwnProperty("riskyVolume"))
                    if (typeof message.riskyVolume !== "number")
                        return "riskyVolume: number expected";
                if (message.riskyVolumeFiat != null && message.hasOwnProperty("riskyVolumeFiat"))
                    if (typeof message.riskyVolumeFiat !== "number")
                        return "riskyVolumeFiat: number expected";
                if (message.sources != null && message.hasOwnProperty("sources")) {
                    if (!$util.isObject(message.sources))
                        return "sources: object expected";
                    var key = Object.keys(message.sources);
                    for (var i = 0; i < key.length; ++i) {
                        var error = $root.protocol.AMLSource.verify(message.sources[key[i]]);
                        if (error)
                            return "sources." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a AMLCheckReport message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLCheckReport
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLCheckReport} AMLCheckReport
             */
            AMLCheckReport.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLCheckReport)
                    return object;
                var message = new $root.protocol.AMLCheckReport();
                if (object.amount != null)
                    message.amount = Number(object.amount);
                if (object.fiat != null)
                    message.fiat = Number(object.fiat);
                if (object.fiatCodeEffective != null)
                    message.fiatCodeEffective = String(object.fiatCodeEffective);
                if (object.riskscore != null)
                    message.riskscore = Number(object.riskscore);
                if (object.riskyVolume != null)
                    message.riskyVolume = Number(object.riskyVolume);
                if (object.riskyVolumeFiat != null)
                    message.riskyVolumeFiat = Number(object.riskyVolumeFiat);
                if (object.sources) {
                    if (typeof object.sources !== "object")
                        throw TypeError(".protocol.AMLCheckReport.sources: object expected");
                    message.sources = {};
                    for (var keys = Object.keys(object.sources), i = 0; i < keys.length; ++i) {
                        if (typeof object.sources[keys[i]] !== "object")
                            throw TypeError(".protocol.AMLCheckReport.sources: object expected");
                        message.sources[keys[i]] = $root.protocol.AMLSource.fromObject(object.sources[keys[i]]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a AMLCheckReport message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLCheckReport
             * @static
             * @param {protocol.AMLCheckReport} message AMLCheckReport
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLCheckReport.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.objects || options.defaults)
                    object.sources = {};
                if (options.defaults) {
                    object.amount = 0;
                    object.fiat = 0;
                    object.fiatCodeEffective = "";
                    object.riskscore = 0;
                    object.riskyVolume = 0;
                    object.riskyVolumeFiat = 0;
                }
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
                if (message.fiat != null && message.hasOwnProperty("fiat"))
                    object.fiat = options.json && !isFinite(message.fiat) ? String(message.fiat) : message.fiat;
                if (message.fiatCodeEffective != null && message.hasOwnProperty("fiatCodeEffective"))
                    object.fiatCodeEffective = message.fiatCodeEffective;
                if (message.riskscore != null && message.hasOwnProperty("riskscore"))
                    object.riskscore = options.json && !isFinite(message.riskscore) ? String(message.riskscore) : message.riskscore;
                if (message.riskyVolume != null && message.hasOwnProperty("riskyVolume"))
                    object.riskyVolume = options.json && !isFinite(message.riskyVolume) ? String(message.riskyVolume) : message.riskyVolume;
                if (message.riskyVolumeFiat != null && message.hasOwnProperty("riskyVolumeFiat"))
                    object.riskyVolumeFiat = options.json && !isFinite(message.riskyVolumeFiat) ? String(message.riskyVolumeFiat) : message.riskyVolumeFiat;
                var keys2;
                if (message.sources && (keys2 = Object.keys(message.sources)).length) {
                    object.sources = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.sources[keys2[j]] = $root.protocol.AMLSource.toObject(message.sources[keys2[j]], options);
                }
                return object;
            };
    
            /**
             * Converts this AMLCheckReport to JSON.
             * @function toJSON
             * @memberof protocol.AMLCheckReport
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLCheckReport.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLCheckReport;
        })();
    
        protocol.AMLCheck = (function() {
    
            /**
             * Properties of a AMLCheck.
             * @memberof protocol
             * @interface IAMLCheck
             * @property {number|null} [id] AMLCheck id
             * @property {string|null} [address] AMLCheck address
             * @property {string|null} [txHash] AMLCheck txHash
             * @property {protocol.Currency|null} [currency] AMLCheck currency
             * @property {protocol.AMLCheckType|null} [type] AMLCheck type
             * @property {protocol.AMLCheckStatus|null} [status] AMLCheck status
             * @property {string|null} [failReason] AMLCheck failReason
             * @property {protocol.IAMLCheckReport|null} [report] AMLCheck report
             * @property {Long|null} [createdAt] AMLCheck createdAt
             * @property {Long|null} [updatedAt] AMLCheck updatedAt
             * @property {protocol.AMLRiskLevel|null} [riskLevel] AMLCheck riskLevel
             */
    
            /**
             * Constructs a new AMLCheck.
             * @memberof protocol
             * @classdesc Represents a AMLCheck.
             * @implements IAMLCheck
             * @constructor
             * @param {protocol.IAMLCheck=} [properties] Properties to set
             */
            function AMLCheck(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLCheck id.
             * @member {number} id
             * @memberof protocol.AMLCheck
             * @instance
             */
            AMLCheck.prototype.id = 0;
    
            /**
             * AMLCheck address.
             * @member {string} address
             * @memberof protocol.AMLCheck
             * @instance
             */
            AMLCheck.prototype.address = "";
    
            /**
             * AMLCheck txHash.
             * @member {string} txHash
             * @memberof protocol.AMLCheck
             * @instance
             */
            AMLCheck.prototype.txHash = "";
    
            /**
             * AMLCheck currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.AMLCheck
             * @instance
             */
            AMLCheck.prototype.currency = 0;
    
            /**
             * AMLCheck type.
             * @member {protocol.AMLCheckType} type
             * @memberof protocol.AMLCheck
             * @instance
             */
            AMLCheck.prototype.type = 0;
    
            /**
             * AMLCheck status.
             * @member {protocol.AMLCheckStatus} status
             * @memberof protocol.AMLCheck
             * @instance
             */
            AMLCheck.prototype.status = 0;
    
            /**
             * AMLCheck failReason.
             * @member {string} failReason
             * @memberof protocol.AMLCheck
             * @instance
             */
            AMLCheck.prototype.failReason = "";
    
            /**
             * AMLCheck report.
             * @member {protocol.IAMLCheckReport|null|undefined} report
             * @memberof protocol.AMLCheck
             * @instance
             */
            AMLCheck.prototype.report = null;
    
            /**
             * AMLCheck createdAt.
             * @member {Long} createdAt
             * @memberof protocol.AMLCheck
             * @instance
             */
            AMLCheck.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLCheck updatedAt.
             * @member {Long} updatedAt
             * @memberof protocol.AMLCheck
             * @instance
             */
            AMLCheck.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLCheck riskLevel.
             * @member {protocol.AMLRiskLevel} riskLevel
             * @memberof protocol.AMLCheck
             * @instance
             */
            AMLCheck.prototype.riskLevel = 0;
    
            /**
             * Creates a new AMLCheck instance using the specified properties.
             * @function create
             * @memberof protocol.AMLCheck
             * @static
             * @param {protocol.IAMLCheck=} [properties] Properties to set
             * @returns {protocol.AMLCheck} AMLCheck instance
             */
            AMLCheck.create = function create(properties) {
                return new AMLCheck(properties);
            };
    
            /**
             * Encodes the specified AMLCheck message. Does not implicitly {@link protocol.AMLCheck.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLCheck
             * @static
             * @param {protocol.IAMLCheck} message AMLCheck message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLCheck.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
                if (message.txHash != null && Object.hasOwnProperty.call(message, "txHash"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.txHash);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.currency);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.status);
                if (message.failReason != null && Object.hasOwnProperty.call(message, "failReason"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.failReason);
                if (message.report != null && Object.hasOwnProperty.call(message, "report"))
                    $root.protocol.AMLCheckReport.encode(message.report, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int64(message.createdAt);
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int64(message.updatedAt);
                if (message.riskLevel != null && Object.hasOwnProperty.call(message, "riskLevel"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int32(message.riskLevel);
                return writer;
            };
    
            /**
             * Encodes the specified AMLCheck message, length delimited. Does not implicitly {@link protocol.AMLCheck.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLCheck
             * @static
             * @param {protocol.IAMLCheck} message AMLCheck message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLCheck.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLCheck message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLCheck
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLCheck} AMLCheck
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLCheck.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLCheck();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint32();
                        break;
                    case 2:
                        message.address = reader.string();
                        break;
                    case 3:
                        message.txHash = reader.string();
                        break;
                    case 4:
                        message.currency = reader.int32();
                        break;
                    case 5:
                        message.type = reader.int32();
                        break;
                    case 6:
                        message.status = reader.int32();
                        break;
                    case 7:
                        message.failReason = reader.string();
                        break;
                    case 8:
                        message.report = $root.protocol.AMLCheckReport.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.createdAt = reader.int64();
                        break;
                    case 10:
                        message.updatedAt = reader.int64();
                        break;
                    case 11:
                        message.riskLevel = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLCheck message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLCheck
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLCheck} AMLCheck
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLCheck.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLCheck message.
             * @function verify
             * @memberof protocol.AMLCheck
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLCheck.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                if (message.txHash != null && message.hasOwnProperty("txHash"))
                    if (!$util.isString(message.txHash))
                        return "txHash: string expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.failReason != null && message.hasOwnProperty("failReason"))
                    if (!$util.isString(message.failReason))
                        return "failReason: string expected";
                if (message.report != null && message.hasOwnProperty("report")) {
                    var error = $root.protocol.AMLCheckReport.verify(message.report);
                    if (error)
                        return "report." + error;
                }
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                        return "createdAt: integer|Long expected";
                if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                    if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                        return "updatedAt: integer|Long expected";
                if (message.riskLevel != null && message.hasOwnProperty("riskLevel"))
                    switch (message.riskLevel) {
                    default:
                        return "riskLevel: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a AMLCheck message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLCheck
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLCheck} AMLCheck
             */
            AMLCheck.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLCheck)
                    return object;
                var message = new $root.protocol.AMLCheck();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.address != null)
                    message.address = String(object.address);
                if (object.txHash != null)
                    message.txHash = String(object.txHash);
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                switch (object.type) {
                case "UNK_AML_CHECK_TYPE":
                case 0:
                    message.type = 0;
                    break;
                case "ADDRESS_AML_CHECK_TYPE":
                case 1:
                    message.type = 1;
                    break;
                case "TRANSACTION_AML_CHECK_TYPE":
                case 2:
                    message.type = 2;
                    break;
                }
                switch (object.status) {
                case "UNK_AML_CHECK_STATUS":
                case 0:
                    message.status = 0;
                    break;
                case "PENDING_AML_CHECK_STATUS":
                case 1:
                    message.status = 1;
                    break;
                case "PROCESSING_AML_CHECK_STATUS":
                case 2:
                    message.status = 2;
                    break;
                case "COMPLETED_AML_CHECK_STATUS":
                case 3:
                    message.status = 3;
                    break;
                case "FAILED_AML_CHECK_STATUS":
                case 4:
                    message.status = 4;
                    break;
                case "CANCELED_AML_CHECK_STATUS":
                case 5:
                    message.status = 5;
                    break;
                }
                if (object.failReason != null)
                    message.failReason = String(object.failReason);
                if (object.report != null) {
                    if (typeof object.report !== "object")
                        throw TypeError(".protocol.AMLCheck.report: object expected");
                    message.report = $root.protocol.AMLCheckReport.fromObject(object.report);
                }
                if (object.createdAt != null)
                    if ($util.Long)
                        (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                    else if (typeof object.createdAt === "string")
                        message.createdAt = parseInt(object.createdAt, 10);
                    else if (typeof object.createdAt === "number")
                        message.createdAt = object.createdAt;
                    else if (typeof object.createdAt === "object")
                        message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                if (object.updatedAt != null)
                    if ($util.Long)
                        (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                    else if (typeof object.updatedAt === "string")
                        message.updatedAt = parseInt(object.updatedAt, 10);
                    else if (typeof object.updatedAt === "number")
                        message.updatedAt = object.updatedAt;
                    else if (typeof object.updatedAt === "object")
                        message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                switch (object.riskLevel) {
                case "UNK_AML_RISK_LEVEL":
                case 0:
                    message.riskLevel = 0;
                    break;
                case "LOW_AML_RISK_LEVEL":
                case 1:
                    message.riskLevel = 1;
                    break;
                case "MEDIUM_AML_RISK_LEVEL":
                case 2:
                    message.riskLevel = 2;
                    break;
                case "HIGH_AML_RISK_LEVEL":
                case 3:
                    message.riskLevel = 3;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a AMLCheck message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLCheck
             * @static
             * @param {protocol.AMLCheck} message AMLCheck
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLCheck.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.address = "";
                    object.txHash = "";
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.type = options.enums === String ? "UNK_AML_CHECK_TYPE" : 0;
                    object.status = options.enums === String ? "UNK_AML_CHECK_STATUS" : 0;
                    object.failReason = "";
                    object.report = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAt = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updatedAt = options.longs === String ? "0" : 0;
                    object.riskLevel = options.enums === String ? "UNK_AML_RISK_LEVEL" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.txHash != null && message.hasOwnProperty("txHash"))
                    object.txHash = message.txHash;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.protocol.AMLCheckType[message.type] : message.type;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.protocol.AMLCheckStatus[message.status] : message.status;
                if (message.failReason != null && message.hasOwnProperty("failReason"))
                    object.failReason = message.failReason;
                if (message.report != null && message.hasOwnProperty("report"))
                    object.report = $root.protocol.AMLCheckReport.toObject(message.report, options);
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (typeof message.createdAt === "number")
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                    else
                        object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                    if (typeof message.updatedAt === "number")
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                    else
                        object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                if (message.riskLevel != null && message.hasOwnProperty("riskLevel"))
                    object.riskLevel = options.enums === String ? $root.protocol.AMLRiskLevel[message.riskLevel] : message.riskLevel;
                return object;
            };
    
            /**
             * Converts this AMLCheck to JSON.
             * @function toJSON
             * @memberof protocol.AMLCheck
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLCheck.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLCheck;
        })();
    
        /**
         * AMLRiskLevel enum.
         * @name protocol.AMLRiskLevel
         * @enum {number}
         * @property {number} UNK_AML_RISK_LEVEL=0 UNK_AML_RISK_LEVEL value
         * @property {number} LOW_AML_RISK_LEVEL=1 LOW_AML_RISK_LEVEL value
         * @property {number} MEDIUM_AML_RISK_LEVEL=2 MEDIUM_AML_RISK_LEVEL value
         * @property {number} HIGH_AML_RISK_LEVEL=3 HIGH_AML_RISK_LEVEL value
         */
        protocol.AMLRiskLevel = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_AML_RISK_LEVEL"] = 0;
            values[valuesById[1] = "LOW_AML_RISK_LEVEL"] = 1;
            values[valuesById[2] = "MEDIUM_AML_RISK_LEVEL"] = 2;
            values[valuesById[3] = "HIGH_AML_RISK_LEVEL"] = 3;
            return values;
        })();
    
        /**
         * AMLCheckStatus enum.
         * @name protocol.AMLCheckStatus
         * @enum {number}
         * @property {number} UNK_AML_CHECK_STATUS=0 UNK_AML_CHECK_STATUS value
         * @property {number} PENDING_AML_CHECK_STATUS=1 PENDING_AML_CHECK_STATUS value
         * @property {number} PROCESSING_AML_CHECK_STATUS=2 PROCESSING_AML_CHECK_STATUS value
         * @property {number} COMPLETED_AML_CHECK_STATUS=3 COMPLETED_AML_CHECK_STATUS value
         * @property {number} FAILED_AML_CHECK_STATUS=4 FAILED_AML_CHECK_STATUS value
         * @property {number} CANCELED_AML_CHECK_STATUS=5 CANCELED_AML_CHECK_STATUS value
         */
        protocol.AMLCheckStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_AML_CHECK_STATUS"] = 0;
            values[valuesById[1] = "PENDING_AML_CHECK_STATUS"] = 1;
            values[valuesById[2] = "PROCESSING_AML_CHECK_STATUS"] = 2;
            values[valuesById[3] = "COMPLETED_AML_CHECK_STATUS"] = 3;
            values[valuesById[4] = "FAILED_AML_CHECK_STATUS"] = 4;
            values[valuesById[5] = "CANCELED_AML_CHECK_STATUS"] = 5;
            return values;
        })();
    
        /**
         * AMLBatchCheckStatus enum.
         * @name protocol.AMLBatchCheckStatus
         * @enum {number}
         * @property {number} UNK_AML_BATCH_CHECK_STATUS=0 UNK_AML_BATCH_CHECK_STATUS value
         * @property {number} ACTIVE_AML_BATCH_CHECK_STATUS=1 ACTIVE_AML_BATCH_CHECK_STATUS value
         * @property {number} COMPLETED_AML_BATCH_CHECK_STATUS=2 COMPLETED_AML_BATCH_CHECK_STATUS value
         * @property {number} CANCELED_AML_BATCH_CHECK_STATUS=3 CANCELED_AML_BATCH_CHECK_STATUS value
         */
        protocol.AMLBatchCheckStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_AML_BATCH_CHECK_STATUS"] = 0;
            values[valuesById[1] = "ACTIVE_AML_BATCH_CHECK_STATUS"] = 1;
            values[valuesById[2] = "COMPLETED_AML_BATCH_CHECK_STATUS"] = 2;
            values[valuesById[3] = "CANCELED_AML_BATCH_CHECK_STATUS"] = 3;
            return values;
        })();
    
        /**
         * AMLServiceStatus enum.
         * @name protocol.AMLServiceStatus
         * @enum {number}
         * @property {number} UNK_AML_SERVICE_STATUS=0 UNK_AML_SERVICE_STATUS value
         * @property {number} OK_AML_SERVICE_STATUS=1 OK_AML_SERVICE_STATUS value
         * @property {number} ERROR_AML_SERVICE_STATUS=2 ERROR_AML_SERVICE_STATUS value
         */
        protocol.AMLServiceStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_AML_SERVICE_STATUS"] = 0;
            values[valuesById[1] = "OK_AML_SERVICE_STATUS"] = 1;
            values[valuesById[2] = "ERROR_AML_SERVICE_STATUS"] = 2;
            return values;
        })();
    
        /**
         * AMLSourceType enum.
         * @name protocol.AMLSourceType
         * @enum {number}
         * @property {number} UNK_AML_SOURCE_TYPE=0 UNK_AML_SOURCE_TYPE value
         * @property {number} TRUSTED_AML_SOURCE_TYPE=1 TRUSTED_AML_SOURCE_TYPE value
         * @property {number} SUSPICIOUS_AML_SOURCE_TYPE=2 SUSPICIOUS_AML_SOURCE_TYPE value
         * @property {number} DANGEROUS_AML_SOURCE_TYPE=3 DANGEROUS_AML_SOURCE_TYPE value
         */
        protocol.AMLSourceType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_AML_SOURCE_TYPE"] = 0;
            values[valuesById[1] = "TRUSTED_AML_SOURCE_TYPE"] = 1;
            values[valuesById[2] = "SUSPICIOUS_AML_SOURCE_TYPE"] = 2;
            values[valuesById[3] = "DANGEROUS_AML_SOURCE_TYPE"] = 3;
            return values;
        })();
    
        protocol.AMLGroupSource = (function() {
    
            /**
             * Properties of a AMLGroupSource.
             * @memberof protocol
             * @interface IAMLGroupSource
             * @property {protocol.AMLSourceType|null} [type] AMLGroupSource type
             * @property {string|null} [name] AMLGroupSource name
             * @property {number|null} [avg] AMLGroupSource avg
             * @property {Long|null} [count] AMLGroupSource count
             */
    
            /**
             * Constructs a new AMLGroupSource.
             * @memberof protocol
             * @classdesc Represents a AMLGroupSource.
             * @implements IAMLGroupSource
             * @constructor
             * @param {protocol.IAMLGroupSource=} [properties] Properties to set
             */
            function AMLGroupSource(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLGroupSource type.
             * @member {protocol.AMLSourceType} type
             * @memberof protocol.AMLGroupSource
             * @instance
             */
            AMLGroupSource.prototype.type = 0;
    
            /**
             * AMLGroupSource name.
             * @member {string} name
             * @memberof protocol.AMLGroupSource
             * @instance
             */
            AMLGroupSource.prototype.name = "";
    
            /**
             * AMLGroupSource avg.
             * @member {number} avg
             * @memberof protocol.AMLGroupSource
             * @instance
             */
            AMLGroupSource.prototype.avg = 0;
    
            /**
             * AMLGroupSource count.
             * @member {Long} count
             * @memberof protocol.AMLGroupSource
             * @instance
             */
            AMLGroupSource.prototype.count = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new AMLGroupSource instance using the specified properties.
             * @function create
             * @memberof protocol.AMLGroupSource
             * @static
             * @param {protocol.IAMLGroupSource=} [properties] Properties to set
             * @returns {protocol.AMLGroupSource} AMLGroupSource instance
             */
            AMLGroupSource.create = function create(properties) {
                return new AMLGroupSource(properties);
            };
    
            /**
             * Encodes the specified AMLGroupSource message. Does not implicitly {@link protocol.AMLGroupSource.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLGroupSource
             * @static
             * @param {protocol.IAMLGroupSource} message AMLGroupSource message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLGroupSource.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.avg != null && Object.hasOwnProperty.call(message, "avg"))
                    writer.uint32(/* id 3, wireType 5 =*/29).float(message.avg);
                if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.count);
                return writer;
            };
    
            /**
             * Encodes the specified AMLGroupSource message, length delimited. Does not implicitly {@link protocol.AMLGroupSource.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLGroupSource
             * @static
             * @param {protocol.IAMLGroupSource} message AMLGroupSource message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLGroupSource.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLGroupSource message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLGroupSource
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLGroupSource} AMLGroupSource
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLGroupSource.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLGroupSource();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.type = reader.int32();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.avg = reader.float();
                        break;
                    case 4:
                        message.count = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLGroupSource message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLGroupSource
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLGroupSource} AMLGroupSource
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLGroupSource.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLGroupSource message.
             * @function verify
             * @memberof protocol.AMLGroupSource
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLGroupSource.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.avg != null && message.hasOwnProperty("avg"))
                    if (typeof message.avg !== "number")
                        return "avg: number expected";
                if (message.count != null && message.hasOwnProperty("count"))
                    if (!$util.isInteger(message.count) && !(message.count && $util.isInteger(message.count.low) && $util.isInteger(message.count.high)))
                        return "count: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a AMLGroupSource message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLGroupSource
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLGroupSource} AMLGroupSource
             */
            AMLGroupSource.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLGroupSource)
                    return object;
                var message = new $root.protocol.AMLGroupSource();
                switch (object.type) {
                case "UNK_AML_SOURCE_TYPE":
                case 0:
                    message.type = 0;
                    break;
                case "TRUSTED_AML_SOURCE_TYPE":
                case 1:
                    message.type = 1;
                    break;
                case "SUSPICIOUS_AML_SOURCE_TYPE":
                case 2:
                    message.type = 2;
                    break;
                case "DANGEROUS_AML_SOURCE_TYPE":
                case 3:
                    message.type = 3;
                    break;
                }
                if (object.name != null)
                    message.name = String(object.name);
                if (object.avg != null)
                    message.avg = Number(object.avg);
                if (object.count != null)
                    if ($util.Long)
                        (message.count = $util.Long.fromValue(object.count)).unsigned = false;
                    else if (typeof object.count === "string")
                        message.count = parseInt(object.count, 10);
                    else if (typeof object.count === "number")
                        message.count = object.count;
                    else if (typeof object.count === "object")
                        message.count = new $util.LongBits(object.count.low >>> 0, object.count.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a AMLGroupSource message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLGroupSource
             * @static
             * @param {protocol.AMLGroupSource} message AMLGroupSource
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLGroupSource.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.type = options.enums === String ? "UNK_AML_SOURCE_TYPE" : 0;
                    object.name = "";
                    object.avg = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.count = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.count = options.longs === String ? "0" : 0;
                }
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.protocol.AMLSourceType[message.type] : message.type;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.avg != null && message.hasOwnProperty("avg"))
                    object.avg = options.json && !isFinite(message.avg) ? String(message.avg) : message.avg;
                if (message.count != null && message.hasOwnProperty("count"))
                    if (typeof message.count === "number")
                        object.count = options.longs === String ? String(message.count) : message.count;
                    else
                        object.count = options.longs === String ? $util.Long.prototype.toString.call(message.count) : options.longs === Number ? new $util.LongBits(message.count.low >>> 0, message.count.high >>> 0).toNumber() : message.count;
                return object;
            };
    
            /**
             * Converts this AMLGroupSource to JSON.
             * @function toJSON
             * @memberof protocol.AMLGroupSource
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLGroupSource.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLGroupSource;
        })();
    
        protocol.AMLSource = (function() {
    
            /**
             * Properties of a AMLSource.
             * @memberof protocol
             * @interface IAMLSource
             * @property {protocol.AMLSourceType|null} [type] AMLSource type
             * @property {string|null} [name] AMLSource name
             * @property {number|null} [value] AMLSource value
             */
    
            /**
             * Constructs a new AMLSource.
             * @memberof protocol
             * @classdesc Represents a AMLSource.
             * @implements IAMLSource
             * @constructor
             * @param {protocol.IAMLSource=} [properties] Properties to set
             */
            function AMLSource(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLSource type.
             * @member {protocol.AMLSourceType} type
             * @memberof protocol.AMLSource
             * @instance
             */
            AMLSource.prototype.type = 0;
    
            /**
             * AMLSource name.
             * @member {string} name
             * @memberof protocol.AMLSource
             * @instance
             */
            AMLSource.prototype.name = "";
    
            /**
             * AMLSource value.
             * @member {number} value
             * @memberof protocol.AMLSource
             * @instance
             */
            AMLSource.prototype.value = 0;
    
            /**
             * Creates a new AMLSource instance using the specified properties.
             * @function create
             * @memberof protocol.AMLSource
             * @static
             * @param {protocol.IAMLSource=} [properties] Properties to set
             * @returns {protocol.AMLSource} AMLSource instance
             */
            AMLSource.create = function create(properties) {
                return new AMLSource(properties);
            };
    
            /**
             * Encodes the specified AMLSource message. Does not implicitly {@link protocol.AMLSource.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLSource
             * @static
             * @param {protocol.IAMLSource} message AMLSource message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLSource.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 3, wireType 5 =*/29).float(message.value);
                return writer;
            };
    
            /**
             * Encodes the specified AMLSource message, length delimited. Does not implicitly {@link protocol.AMLSource.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLSource
             * @static
             * @param {protocol.IAMLSource} message AMLSource message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLSource.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLSource message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLSource
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLSource} AMLSource
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLSource.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLSource();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.type = reader.int32();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.value = reader.float();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLSource message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLSource
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLSource} AMLSource
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLSource.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLSource message.
             * @function verify
             * @memberof protocol.AMLSource
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLSource.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (typeof message.value !== "number")
                        return "value: number expected";
                return null;
            };
    
            /**
             * Creates a AMLSource message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLSource
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLSource} AMLSource
             */
            AMLSource.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLSource)
                    return object;
                var message = new $root.protocol.AMLSource();
                switch (object.type) {
                case "UNK_AML_SOURCE_TYPE":
                case 0:
                    message.type = 0;
                    break;
                case "TRUSTED_AML_SOURCE_TYPE":
                case 1:
                    message.type = 1;
                    break;
                case "SUSPICIOUS_AML_SOURCE_TYPE":
                case 2:
                    message.type = 2;
                    break;
                case "DANGEROUS_AML_SOURCE_TYPE":
                case 3:
                    message.type = 3;
                    break;
                }
                if (object.name != null)
                    message.name = String(object.name);
                if (object.value != null)
                    message.value = Number(object.value);
                return message;
            };
    
            /**
             * Creates a plain object from a AMLSource message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLSource
             * @static
             * @param {protocol.AMLSource} message AMLSource
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLSource.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.type = options.enums === String ? "UNK_AML_SOURCE_TYPE" : 0;
                    object.name = "";
                    object.value = 0;
                }
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.protocol.AMLSourceType[message.type] : message.type;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
                return object;
            };
    
            /**
             * Converts this AMLSource to JSON.
             * @function toJSON
             * @memberof protocol.AMLSource
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLSource.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLSource;
        })();
    
        protocol.AMLSingleCheckRequest = (function() {
    
            /**
             * Properties of a AMLSingleCheckRequest.
             * @memberof protocol
             * @interface IAMLSingleCheckRequest
             * @property {string|null} [address] AMLSingleCheckRequest address
             * @property {string|null} [txHash] AMLSingleCheckRequest txHash
             * @property {protocol.Currency|null} [currency] AMLSingleCheckRequest currency
             * @property {boolean|null} [forceCheck] AMLSingleCheckRequest forceCheck
             */
    
            /**
             * Constructs a new AMLSingleCheckRequest.
             * @memberof protocol
             * @classdesc Represents a AMLSingleCheckRequest.
             * @implements IAMLSingleCheckRequest
             * @constructor
             * @param {protocol.IAMLSingleCheckRequest=} [properties] Properties to set
             */
            function AMLSingleCheckRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLSingleCheckRequest address.
             * @member {string} address
             * @memberof protocol.AMLSingleCheckRequest
             * @instance
             */
            AMLSingleCheckRequest.prototype.address = "";
    
            /**
             * AMLSingleCheckRequest txHash.
             * @member {string} txHash
             * @memberof protocol.AMLSingleCheckRequest
             * @instance
             */
            AMLSingleCheckRequest.prototype.txHash = "";
    
            /**
             * AMLSingleCheckRequest currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.AMLSingleCheckRequest
             * @instance
             */
            AMLSingleCheckRequest.prototype.currency = 0;
    
            /**
             * AMLSingleCheckRequest forceCheck.
             * @member {boolean} forceCheck
             * @memberof protocol.AMLSingleCheckRequest
             * @instance
             */
            AMLSingleCheckRequest.prototype.forceCheck = false;
    
            /**
             * Creates a new AMLSingleCheckRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AMLSingleCheckRequest
             * @static
             * @param {protocol.IAMLSingleCheckRequest=} [properties] Properties to set
             * @returns {protocol.AMLSingleCheckRequest} AMLSingleCheckRequest instance
             */
            AMLSingleCheckRequest.create = function create(properties) {
                return new AMLSingleCheckRequest(properties);
            };
    
            /**
             * Encodes the specified AMLSingleCheckRequest message. Does not implicitly {@link protocol.AMLSingleCheckRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLSingleCheckRequest
             * @static
             * @param {protocol.IAMLSingleCheckRequest} message AMLSingleCheckRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLSingleCheckRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
                if (message.txHash != null && Object.hasOwnProperty.call(message, "txHash"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.txHash);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.currency);
                if (message.forceCheck != null && Object.hasOwnProperty.call(message, "forceCheck"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.forceCheck);
                return writer;
            };
    
            /**
             * Encodes the specified AMLSingleCheckRequest message, length delimited. Does not implicitly {@link protocol.AMLSingleCheckRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLSingleCheckRequest
             * @static
             * @param {protocol.IAMLSingleCheckRequest} message AMLSingleCheckRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLSingleCheckRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLSingleCheckRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLSingleCheckRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLSingleCheckRequest} AMLSingleCheckRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLSingleCheckRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLSingleCheckRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.address = reader.string();
                        break;
                    case 2:
                        message.txHash = reader.string();
                        break;
                    case 3:
                        message.currency = reader.int32();
                        break;
                    case 4:
                        message.forceCheck = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLSingleCheckRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLSingleCheckRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLSingleCheckRequest} AMLSingleCheckRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLSingleCheckRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLSingleCheckRequest message.
             * @function verify
             * @memberof protocol.AMLSingleCheckRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLSingleCheckRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                if (message.txHash != null && message.hasOwnProperty("txHash"))
                    if (!$util.isString(message.txHash))
                        return "txHash: string expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.forceCheck != null && message.hasOwnProperty("forceCheck"))
                    if (typeof message.forceCheck !== "boolean")
                        return "forceCheck: boolean expected";
                return null;
            };
    
            /**
             * Creates a AMLSingleCheckRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLSingleCheckRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLSingleCheckRequest} AMLSingleCheckRequest
             */
            AMLSingleCheckRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLSingleCheckRequest)
                    return object;
                var message = new $root.protocol.AMLSingleCheckRequest();
                if (object.address != null)
                    message.address = String(object.address);
                if (object.txHash != null)
                    message.txHash = String(object.txHash);
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                if (object.forceCheck != null)
                    message.forceCheck = Boolean(object.forceCheck);
                return message;
            };
    
            /**
             * Creates a plain object from a AMLSingleCheckRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLSingleCheckRequest
             * @static
             * @param {protocol.AMLSingleCheckRequest} message AMLSingleCheckRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLSingleCheckRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.address = "";
                    object.txHash = "";
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.forceCheck = false;
                }
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.txHash != null && message.hasOwnProperty("txHash"))
                    object.txHash = message.txHash;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.forceCheck != null && message.hasOwnProperty("forceCheck"))
                    object.forceCheck = message.forceCheck;
                return object;
            };
    
            /**
             * Converts this AMLSingleCheckRequest to JSON.
             * @function toJSON
             * @memberof protocol.AMLSingleCheckRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLSingleCheckRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLSingleCheckRequest;
        })();
    
        protocol.AMLSingleCheckResponse = (function() {
    
            /**
             * Properties of a AMLSingleCheckResponse.
             * @memberof protocol
             * @interface IAMLSingleCheckResponse
             * @property {number|null} [id] AMLSingleCheckResponse id
             * @property {boolean|null} [existingCheck] AMLSingleCheckResponse existingCheck
             */
    
            /**
             * Constructs a new AMLSingleCheckResponse.
             * @memberof protocol
             * @classdesc Represents a AMLSingleCheckResponse.
             * @implements IAMLSingleCheckResponse
             * @constructor
             * @param {protocol.IAMLSingleCheckResponse=} [properties] Properties to set
             */
            function AMLSingleCheckResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLSingleCheckResponse id.
             * @member {number} id
             * @memberof protocol.AMLSingleCheckResponse
             * @instance
             */
            AMLSingleCheckResponse.prototype.id = 0;
    
            /**
             * AMLSingleCheckResponse existingCheck.
             * @member {boolean} existingCheck
             * @memberof protocol.AMLSingleCheckResponse
             * @instance
             */
            AMLSingleCheckResponse.prototype.existingCheck = false;
    
            /**
             * Creates a new AMLSingleCheckResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AMLSingleCheckResponse
             * @static
             * @param {protocol.IAMLSingleCheckResponse=} [properties] Properties to set
             * @returns {protocol.AMLSingleCheckResponse} AMLSingleCheckResponse instance
             */
            AMLSingleCheckResponse.create = function create(properties) {
                return new AMLSingleCheckResponse(properties);
            };
    
            /**
             * Encodes the specified AMLSingleCheckResponse message. Does not implicitly {@link protocol.AMLSingleCheckResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLSingleCheckResponse
             * @static
             * @param {protocol.IAMLSingleCheckResponse} message AMLSingleCheckResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLSingleCheckResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.existingCheck != null && Object.hasOwnProperty.call(message, "existingCheck"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.existingCheck);
                return writer;
            };
    
            /**
             * Encodes the specified AMLSingleCheckResponse message, length delimited. Does not implicitly {@link protocol.AMLSingleCheckResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLSingleCheckResponse
             * @static
             * @param {protocol.IAMLSingleCheckResponse} message AMLSingleCheckResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLSingleCheckResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLSingleCheckResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLSingleCheckResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLSingleCheckResponse} AMLSingleCheckResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLSingleCheckResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLSingleCheckResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint32();
                        break;
                    case 2:
                        message.existingCheck = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLSingleCheckResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLSingleCheckResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLSingleCheckResponse} AMLSingleCheckResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLSingleCheckResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLSingleCheckResponse message.
             * @function verify
             * @memberof protocol.AMLSingleCheckResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLSingleCheckResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.existingCheck != null && message.hasOwnProperty("existingCheck"))
                    if (typeof message.existingCheck !== "boolean")
                        return "existingCheck: boolean expected";
                return null;
            };
    
            /**
             * Creates a AMLSingleCheckResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLSingleCheckResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLSingleCheckResponse} AMLSingleCheckResponse
             */
            AMLSingleCheckResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLSingleCheckResponse)
                    return object;
                var message = new $root.protocol.AMLSingleCheckResponse();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.existingCheck != null)
                    message.existingCheck = Boolean(object.existingCheck);
                return message;
            };
    
            /**
             * Creates a plain object from a AMLSingleCheckResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLSingleCheckResponse
             * @static
             * @param {protocol.AMLSingleCheckResponse} message AMLSingleCheckResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLSingleCheckResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.existingCheck = false;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.existingCheck != null && message.hasOwnProperty("existingCheck"))
                    object.existingCheck = message.existingCheck;
                return object;
            };
    
            /**
             * Converts this AMLSingleCheckResponse to JSON.
             * @function toJSON
             * @memberof protocol.AMLSingleCheckResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLSingleCheckResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLSingleCheckResponse;
        })();
    
        protocol.AMLCheckResultRequest = (function() {
    
            /**
             * Properties of a AMLCheckResultRequest.
             * @memberof protocol
             * @interface IAMLCheckResultRequest
             * @property {number|null} [id] AMLCheckResultRequest id
             */
    
            /**
             * Constructs a new AMLCheckResultRequest.
             * @memberof protocol
             * @classdesc Represents a AMLCheckResultRequest.
             * @implements IAMLCheckResultRequest
             * @constructor
             * @param {protocol.IAMLCheckResultRequest=} [properties] Properties to set
             */
            function AMLCheckResultRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLCheckResultRequest id.
             * @member {number} id
             * @memberof protocol.AMLCheckResultRequest
             * @instance
             */
            AMLCheckResultRequest.prototype.id = 0;
    
            /**
             * Creates a new AMLCheckResultRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AMLCheckResultRequest
             * @static
             * @param {protocol.IAMLCheckResultRequest=} [properties] Properties to set
             * @returns {protocol.AMLCheckResultRequest} AMLCheckResultRequest instance
             */
            AMLCheckResultRequest.create = function create(properties) {
                return new AMLCheckResultRequest(properties);
            };
    
            /**
             * Encodes the specified AMLCheckResultRequest message. Does not implicitly {@link protocol.AMLCheckResultRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLCheckResultRequest
             * @static
             * @param {protocol.IAMLCheckResultRequest} message AMLCheckResultRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLCheckResultRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                return writer;
            };
    
            /**
             * Encodes the specified AMLCheckResultRequest message, length delimited. Does not implicitly {@link protocol.AMLCheckResultRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLCheckResultRequest
             * @static
             * @param {protocol.IAMLCheckResultRequest} message AMLCheckResultRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLCheckResultRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLCheckResultRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLCheckResultRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLCheckResultRequest} AMLCheckResultRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLCheckResultRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLCheckResultRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLCheckResultRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLCheckResultRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLCheckResultRequest} AMLCheckResultRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLCheckResultRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLCheckResultRequest message.
             * @function verify
             * @memberof protocol.AMLCheckResultRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLCheckResultRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                return null;
            };
    
            /**
             * Creates a AMLCheckResultRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLCheckResultRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLCheckResultRequest} AMLCheckResultRequest
             */
            AMLCheckResultRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLCheckResultRequest)
                    return object;
                var message = new $root.protocol.AMLCheckResultRequest();
                if (object.id != null)
                    message.id = object.id >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a AMLCheckResultRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLCheckResultRequest
             * @static
             * @param {protocol.AMLCheckResultRequest} message AMLCheckResultRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLCheckResultRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.id = 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                return object;
            };
    
            /**
             * Converts this AMLCheckResultRequest to JSON.
             * @function toJSON
             * @memberof protocol.AMLCheckResultRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLCheckResultRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLCheckResultRequest;
        })();
    
        protocol.AMLCheckResultResponse = (function() {
    
            /**
             * Properties of a AMLCheckResultResponse.
             * @memberof protocol
             * @interface IAMLCheckResultResponse
             * @property {protocol.IAMLCheck|null} [check] AMLCheckResultResponse check
             */
    
            /**
             * Constructs a new AMLCheckResultResponse.
             * @memberof protocol
             * @classdesc Represents a AMLCheckResultResponse.
             * @implements IAMLCheckResultResponse
             * @constructor
             * @param {protocol.IAMLCheckResultResponse=} [properties] Properties to set
             */
            function AMLCheckResultResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLCheckResultResponse check.
             * @member {protocol.IAMLCheck|null|undefined} check
             * @memberof protocol.AMLCheckResultResponse
             * @instance
             */
            AMLCheckResultResponse.prototype.check = null;
    
            /**
             * Creates a new AMLCheckResultResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AMLCheckResultResponse
             * @static
             * @param {protocol.IAMLCheckResultResponse=} [properties] Properties to set
             * @returns {protocol.AMLCheckResultResponse} AMLCheckResultResponse instance
             */
            AMLCheckResultResponse.create = function create(properties) {
                return new AMLCheckResultResponse(properties);
            };
    
            /**
             * Encodes the specified AMLCheckResultResponse message. Does not implicitly {@link protocol.AMLCheckResultResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLCheckResultResponse
             * @static
             * @param {protocol.IAMLCheckResultResponse} message AMLCheckResultResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLCheckResultResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.check != null && Object.hasOwnProperty.call(message, "check"))
                    $root.protocol.AMLCheck.encode(message.check, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AMLCheckResultResponse message, length delimited. Does not implicitly {@link protocol.AMLCheckResultResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLCheckResultResponse
             * @static
             * @param {protocol.IAMLCheckResultResponse} message AMLCheckResultResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLCheckResultResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLCheckResultResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLCheckResultResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLCheckResultResponse} AMLCheckResultResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLCheckResultResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLCheckResultResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.check = $root.protocol.AMLCheck.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLCheckResultResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLCheckResultResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLCheckResultResponse} AMLCheckResultResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLCheckResultResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLCheckResultResponse message.
             * @function verify
             * @memberof protocol.AMLCheckResultResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLCheckResultResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.check != null && message.hasOwnProperty("check")) {
                    var error = $root.protocol.AMLCheck.verify(message.check);
                    if (error)
                        return "check." + error;
                }
                return null;
            };
    
            /**
             * Creates a AMLCheckResultResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLCheckResultResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLCheckResultResponse} AMLCheckResultResponse
             */
            AMLCheckResultResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLCheckResultResponse)
                    return object;
                var message = new $root.protocol.AMLCheckResultResponse();
                if (object.check != null) {
                    if (typeof object.check !== "object")
                        throw TypeError(".protocol.AMLCheckResultResponse.check: object expected");
                    message.check = $root.protocol.AMLCheck.fromObject(object.check);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a AMLCheckResultResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLCheckResultResponse
             * @static
             * @param {protocol.AMLCheckResultResponse} message AMLCheckResultResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLCheckResultResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.check = null;
                if (message.check != null && message.hasOwnProperty("check"))
                    object.check = $root.protocol.AMLCheck.toObject(message.check, options);
                return object;
            };
    
            /**
             * Converts this AMLCheckResultResponse to JSON.
             * @function toJSON
             * @memberof protocol.AMLCheckResultResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLCheckResultResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLCheckResultResponse;
        })();
    
        protocol.AMLClientUnboundChecksListRequest = (function() {
    
            /**
             * Properties of a AMLClientUnboundChecksListRequest.
             * @memberof protocol
             * @interface IAMLClientUnboundChecksListRequest
             * @property {Long|null} [limit] AMLClientUnboundChecksListRequest limit
             * @property {Long|null} [offset] AMLClientUnboundChecksListRequest offset
             */
    
            /**
             * Constructs a new AMLClientUnboundChecksListRequest.
             * @memberof protocol
             * @classdesc Represents a AMLClientUnboundChecksListRequest.
             * @implements IAMLClientUnboundChecksListRequest
             * @constructor
             * @param {protocol.IAMLClientUnboundChecksListRequest=} [properties] Properties to set
             */
            function AMLClientUnboundChecksListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientUnboundChecksListRequest limit.
             * @member {Long} limit
             * @memberof protocol.AMLClientUnboundChecksListRequest
             * @instance
             */
            AMLClientUnboundChecksListRequest.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientUnboundChecksListRequest offset.
             * @member {Long} offset
             * @memberof protocol.AMLClientUnboundChecksListRequest
             * @instance
             */
            AMLClientUnboundChecksListRequest.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new AMLClientUnboundChecksListRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientUnboundChecksListRequest
             * @static
             * @param {protocol.IAMLClientUnboundChecksListRequest=} [properties] Properties to set
             * @returns {protocol.AMLClientUnboundChecksListRequest} AMLClientUnboundChecksListRequest instance
             */
            AMLClientUnboundChecksListRequest.create = function create(properties) {
                return new AMLClientUnboundChecksListRequest(properties);
            };
    
            /**
             * Encodes the specified AMLClientUnboundChecksListRequest message. Does not implicitly {@link protocol.AMLClientUnboundChecksListRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientUnboundChecksListRequest
             * @static
             * @param {protocol.IAMLClientUnboundChecksListRequest} message AMLClientUnboundChecksListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientUnboundChecksListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.limit);
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.offset);
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientUnboundChecksListRequest message, length delimited. Does not implicitly {@link protocol.AMLClientUnboundChecksListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientUnboundChecksListRequest
             * @static
             * @param {protocol.IAMLClientUnboundChecksListRequest} message AMLClientUnboundChecksListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientUnboundChecksListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientUnboundChecksListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientUnboundChecksListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientUnboundChecksListRequest} AMLClientUnboundChecksListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientUnboundChecksListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientUnboundChecksListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.limit = reader.int64();
                        break;
                    case 2:
                        message.offset = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientUnboundChecksListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientUnboundChecksListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientUnboundChecksListRequest} AMLClientUnboundChecksListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientUnboundChecksListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientUnboundChecksListRequest message.
             * @function verify
             * @memberof protocol.AMLClientUnboundChecksListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientUnboundChecksListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                        return "limit: integer|Long expected";
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset) && !(message.offset && $util.isInteger(message.offset.low) && $util.isInteger(message.offset.high)))
                        return "offset: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a AMLClientUnboundChecksListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientUnboundChecksListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientUnboundChecksListRequest} AMLClientUnboundChecksListRequest
             */
            AMLClientUnboundChecksListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientUnboundChecksListRequest)
                    return object;
                var message = new $root.protocol.AMLClientUnboundChecksListRequest();
                if (object.limit != null)
                    if ($util.Long)
                        (message.limit = $util.Long.fromValue(object.limit)).unsigned = false;
                    else if (typeof object.limit === "string")
                        message.limit = parseInt(object.limit, 10);
                    else if (typeof object.limit === "number")
                        message.limit = object.limit;
                    else if (typeof object.limit === "object")
                        message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber();
                if (object.offset != null)
                    if ($util.Long)
                        (message.offset = $util.Long.fromValue(object.offset)).unsigned = false;
                    else if (typeof object.offset === "string")
                        message.offset = parseInt(object.offset, 10);
                    else if (typeof object.offset === "number")
                        message.offset = object.offset;
                    else if (typeof object.offset === "object")
                        message.offset = new $util.LongBits(object.offset.low >>> 0, object.offset.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientUnboundChecksListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientUnboundChecksListRequest
             * @static
             * @param {protocol.AMLClientUnboundChecksListRequest} message AMLClientUnboundChecksListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientUnboundChecksListRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.limit = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.offset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.offset = options.longs === String ? "0" : 0;
                }
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (typeof message.limit === "number")
                        object.limit = options.longs === String ? String(message.limit) : message.limit;
                    else
                        object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber() : message.limit;
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (typeof message.offset === "number")
                        object.offset = options.longs === String ? String(message.offset) : message.offset;
                    else
                        object.offset = options.longs === String ? $util.Long.prototype.toString.call(message.offset) : options.longs === Number ? new $util.LongBits(message.offset.low >>> 0, message.offset.high >>> 0).toNumber() : message.offset;
                return object;
            };
    
            /**
             * Converts this AMLClientUnboundChecksListRequest to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientUnboundChecksListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientUnboundChecksListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientUnboundChecksListRequest;
        })();
    
        protocol.AMLClientUnboundChecksListResponse = (function() {
    
            /**
             * Properties of a AMLClientUnboundChecksListResponse.
             * @memberof protocol
             * @interface IAMLClientUnboundChecksListResponse
             * @property {Long|null} [totalCount] AMLClientUnboundChecksListResponse totalCount
             * @property {Long|null} [totalPages] AMLClientUnboundChecksListResponse totalPages
             * @property {Array.<protocol.IAMLCheck>|null} [checks] AMLClientUnboundChecksListResponse checks
             */
    
            /**
             * Constructs a new AMLClientUnboundChecksListResponse.
             * @memberof protocol
             * @classdesc Represents a AMLClientUnboundChecksListResponse.
             * @implements IAMLClientUnboundChecksListResponse
             * @constructor
             * @param {protocol.IAMLClientUnboundChecksListResponse=} [properties] Properties to set
             */
            function AMLClientUnboundChecksListResponse(properties) {
                this.checks = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientUnboundChecksListResponse totalCount.
             * @member {Long} totalCount
             * @memberof protocol.AMLClientUnboundChecksListResponse
             * @instance
             */
            AMLClientUnboundChecksListResponse.prototype.totalCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientUnboundChecksListResponse totalPages.
             * @member {Long} totalPages
             * @memberof protocol.AMLClientUnboundChecksListResponse
             * @instance
             */
            AMLClientUnboundChecksListResponse.prototype.totalPages = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientUnboundChecksListResponse checks.
             * @member {Array.<protocol.IAMLCheck>} checks
             * @memberof protocol.AMLClientUnboundChecksListResponse
             * @instance
             */
            AMLClientUnboundChecksListResponse.prototype.checks = $util.emptyArray;
    
            /**
             * Creates a new AMLClientUnboundChecksListResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientUnboundChecksListResponse
             * @static
             * @param {protocol.IAMLClientUnboundChecksListResponse=} [properties] Properties to set
             * @returns {protocol.AMLClientUnboundChecksListResponse} AMLClientUnboundChecksListResponse instance
             */
            AMLClientUnboundChecksListResponse.create = function create(properties) {
                return new AMLClientUnboundChecksListResponse(properties);
            };
    
            /**
             * Encodes the specified AMLClientUnboundChecksListResponse message. Does not implicitly {@link protocol.AMLClientUnboundChecksListResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientUnboundChecksListResponse
             * @static
             * @param {protocol.IAMLClientUnboundChecksListResponse} message AMLClientUnboundChecksListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientUnboundChecksListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.totalCount);
                if (message.totalPages != null && Object.hasOwnProperty.call(message, "totalPages"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalPages);
                if (message.checks != null && message.checks.length)
                    for (var i = 0; i < message.checks.length; ++i)
                        $root.protocol.AMLCheck.encode(message.checks[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientUnboundChecksListResponse message, length delimited. Does not implicitly {@link protocol.AMLClientUnboundChecksListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientUnboundChecksListResponse
             * @static
             * @param {protocol.IAMLClientUnboundChecksListResponse} message AMLClientUnboundChecksListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientUnboundChecksListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientUnboundChecksListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientUnboundChecksListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientUnboundChecksListResponse} AMLClientUnboundChecksListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientUnboundChecksListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientUnboundChecksListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.totalCount = reader.int64();
                        break;
                    case 2:
                        message.totalPages = reader.int64();
                        break;
                    case 3:
                        if (!(message.checks && message.checks.length))
                            message.checks = [];
                        message.checks.push($root.protocol.AMLCheck.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientUnboundChecksListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientUnboundChecksListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientUnboundChecksListResponse} AMLClientUnboundChecksListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientUnboundChecksListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientUnboundChecksListResponse message.
             * @function verify
             * @memberof protocol.AMLClientUnboundChecksListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientUnboundChecksListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (!$util.isInteger(message.totalCount) && !(message.totalCount && $util.isInteger(message.totalCount.low) && $util.isInteger(message.totalCount.high)))
                        return "totalCount: integer|Long expected";
                if (message.totalPages != null && message.hasOwnProperty("totalPages"))
                    if (!$util.isInteger(message.totalPages) && !(message.totalPages && $util.isInteger(message.totalPages.low) && $util.isInteger(message.totalPages.high)))
                        return "totalPages: integer|Long expected";
                if (message.checks != null && message.hasOwnProperty("checks")) {
                    if (!Array.isArray(message.checks))
                        return "checks: array expected";
                    for (var i = 0; i < message.checks.length; ++i) {
                        var error = $root.protocol.AMLCheck.verify(message.checks[i]);
                        if (error)
                            return "checks." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a AMLClientUnboundChecksListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientUnboundChecksListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientUnboundChecksListResponse} AMLClientUnboundChecksListResponse
             */
            AMLClientUnboundChecksListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientUnboundChecksListResponse)
                    return object;
                var message = new $root.protocol.AMLClientUnboundChecksListResponse();
                if (object.totalCount != null)
                    if ($util.Long)
                        (message.totalCount = $util.Long.fromValue(object.totalCount)).unsigned = false;
                    else if (typeof object.totalCount === "string")
                        message.totalCount = parseInt(object.totalCount, 10);
                    else if (typeof object.totalCount === "number")
                        message.totalCount = object.totalCount;
                    else if (typeof object.totalCount === "object")
                        message.totalCount = new $util.LongBits(object.totalCount.low >>> 0, object.totalCount.high >>> 0).toNumber();
                if (object.totalPages != null)
                    if ($util.Long)
                        (message.totalPages = $util.Long.fromValue(object.totalPages)).unsigned = false;
                    else if (typeof object.totalPages === "string")
                        message.totalPages = parseInt(object.totalPages, 10);
                    else if (typeof object.totalPages === "number")
                        message.totalPages = object.totalPages;
                    else if (typeof object.totalPages === "object")
                        message.totalPages = new $util.LongBits(object.totalPages.low >>> 0, object.totalPages.high >>> 0).toNumber();
                if (object.checks) {
                    if (!Array.isArray(object.checks))
                        throw TypeError(".protocol.AMLClientUnboundChecksListResponse.checks: array expected");
                    message.checks = [];
                    for (var i = 0; i < object.checks.length; ++i) {
                        if (typeof object.checks[i] !== "object")
                            throw TypeError(".protocol.AMLClientUnboundChecksListResponse.checks: object expected");
                        message.checks[i] = $root.protocol.AMLCheck.fromObject(object.checks[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientUnboundChecksListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientUnboundChecksListResponse
             * @static
             * @param {protocol.AMLClientUnboundChecksListResponse} message AMLClientUnboundChecksListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientUnboundChecksListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.checks = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalCount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalPages = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalPages = options.longs === String ? "0" : 0;
                }
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (typeof message.totalCount === "number")
                        object.totalCount = options.longs === String ? String(message.totalCount) : message.totalCount;
                    else
                        object.totalCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalCount) : options.longs === Number ? new $util.LongBits(message.totalCount.low >>> 0, message.totalCount.high >>> 0).toNumber() : message.totalCount;
                if (message.totalPages != null && message.hasOwnProperty("totalPages"))
                    if (typeof message.totalPages === "number")
                        object.totalPages = options.longs === String ? String(message.totalPages) : message.totalPages;
                    else
                        object.totalPages = options.longs === String ? $util.Long.prototype.toString.call(message.totalPages) : options.longs === Number ? new $util.LongBits(message.totalPages.low >>> 0, message.totalPages.high >>> 0).toNumber() : message.totalPages;
                if (message.checks && message.checks.length) {
                    object.checks = [];
                    for (var j = 0; j < message.checks.length; ++j)
                        object.checks[j] = $root.protocol.AMLCheck.toObject(message.checks[j], options);
                }
                return object;
            };
    
            /**
             * Converts this AMLClientUnboundChecksListResponse to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientUnboundChecksListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientUnboundChecksListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientUnboundChecksListResponse;
        })();
    
        protocol.AMLServiceStatusRequest = (function() {
    
            /**
             * Properties of a AMLServiceStatusRequest.
             * @memberof protocol
             * @interface IAMLServiceStatusRequest
             */
    
            /**
             * Constructs a new AMLServiceStatusRequest.
             * @memberof protocol
             * @classdesc Represents a AMLServiceStatusRequest.
             * @implements IAMLServiceStatusRequest
             * @constructor
             * @param {protocol.IAMLServiceStatusRequest=} [properties] Properties to set
             */
            function AMLServiceStatusRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new AMLServiceStatusRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AMLServiceStatusRequest
             * @static
             * @param {protocol.IAMLServiceStatusRequest=} [properties] Properties to set
             * @returns {protocol.AMLServiceStatusRequest} AMLServiceStatusRequest instance
             */
            AMLServiceStatusRequest.create = function create(properties) {
                return new AMLServiceStatusRequest(properties);
            };
    
            /**
             * Encodes the specified AMLServiceStatusRequest message. Does not implicitly {@link protocol.AMLServiceStatusRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLServiceStatusRequest
             * @static
             * @param {protocol.IAMLServiceStatusRequest} message AMLServiceStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLServiceStatusRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified AMLServiceStatusRequest message, length delimited. Does not implicitly {@link protocol.AMLServiceStatusRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLServiceStatusRequest
             * @static
             * @param {protocol.IAMLServiceStatusRequest} message AMLServiceStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLServiceStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLServiceStatusRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLServiceStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLServiceStatusRequest} AMLServiceStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLServiceStatusRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLServiceStatusRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLServiceStatusRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLServiceStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLServiceStatusRequest} AMLServiceStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLServiceStatusRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLServiceStatusRequest message.
             * @function verify
             * @memberof protocol.AMLServiceStatusRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLServiceStatusRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a AMLServiceStatusRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLServiceStatusRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLServiceStatusRequest} AMLServiceStatusRequest
             */
            AMLServiceStatusRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLServiceStatusRequest)
                    return object;
                return new $root.protocol.AMLServiceStatusRequest();
            };
    
            /**
             * Creates a plain object from a AMLServiceStatusRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLServiceStatusRequest
             * @static
             * @param {protocol.AMLServiceStatusRequest} message AMLServiceStatusRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLServiceStatusRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this AMLServiceStatusRequest to JSON.
             * @function toJSON
             * @memberof protocol.AMLServiceStatusRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLServiceStatusRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLServiceStatusRequest;
        })();
    
        protocol.AMLServiceStatusResponse = (function() {
    
            /**
             * Properties of a AMLServiceStatusResponse.
             * @memberof protocol
             * @interface IAMLServiceStatusResponse
             * @property {protocol.AMLServiceStatus|null} [status] AMLServiceStatusResponse status
             * @property {protocol.AMLServiceStatusResponse.IStats|null} [stats] AMLServiceStatusResponse stats
             * @property {protocol.AMLServiceStatusResponse.IError|null} [error] AMLServiceStatusResponse error
             */
    
            /**
             * Constructs a new AMLServiceStatusResponse.
             * @memberof protocol
             * @classdesc Represents a AMLServiceStatusResponse.
             * @implements IAMLServiceStatusResponse
             * @constructor
             * @param {protocol.IAMLServiceStatusResponse=} [properties] Properties to set
             */
            function AMLServiceStatusResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLServiceStatusResponse status.
             * @member {protocol.AMLServiceStatus} status
             * @memberof protocol.AMLServiceStatusResponse
             * @instance
             */
            AMLServiceStatusResponse.prototype.status = 0;
    
            /**
             * AMLServiceStatusResponse stats.
             * @member {protocol.AMLServiceStatusResponse.IStats|null|undefined} stats
             * @memberof protocol.AMLServiceStatusResponse
             * @instance
             */
            AMLServiceStatusResponse.prototype.stats = null;
    
            /**
             * AMLServiceStatusResponse error.
             * @member {protocol.AMLServiceStatusResponse.IError|null|undefined} error
             * @memberof protocol.AMLServiceStatusResponse
             * @instance
             */
            AMLServiceStatusResponse.prototype.error = null;
    
            /**
             * Creates a new AMLServiceStatusResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AMLServiceStatusResponse
             * @static
             * @param {protocol.IAMLServiceStatusResponse=} [properties] Properties to set
             * @returns {protocol.AMLServiceStatusResponse} AMLServiceStatusResponse instance
             */
            AMLServiceStatusResponse.create = function create(properties) {
                return new AMLServiceStatusResponse(properties);
            };
    
            /**
             * Encodes the specified AMLServiceStatusResponse message. Does not implicitly {@link protocol.AMLServiceStatusResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLServiceStatusResponse
             * @static
             * @param {protocol.IAMLServiceStatusResponse} message AMLServiceStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLServiceStatusResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                if (message.stats != null && Object.hasOwnProperty.call(message, "stats"))
                    $root.protocol.AMLServiceStatusResponse.Stats.encode(message.stats, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                    $root.protocol.AMLServiceStatusResponse.Error.encode(message.error, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AMLServiceStatusResponse message, length delimited. Does not implicitly {@link protocol.AMLServiceStatusResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLServiceStatusResponse
             * @static
             * @param {protocol.IAMLServiceStatusResponse} message AMLServiceStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLServiceStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLServiceStatusResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLServiceStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLServiceStatusResponse} AMLServiceStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLServiceStatusResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLServiceStatusResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.status = reader.int32();
                        break;
                    case 2:
                        message.stats = $root.protocol.AMLServiceStatusResponse.Stats.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.error = $root.protocol.AMLServiceStatusResponse.Error.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLServiceStatusResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLServiceStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLServiceStatusResponse} AMLServiceStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLServiceStatusResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLServiceStatusResponse message.
             * @function verify
             * @memberof protocol.AMLServiceStatusResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLServiceStatusResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.stats != null && message.hasOwnProperty("stats")) {
                    var error = $root.protocol.AMLServiceStatusResponse.Stats.verify(message.stats);
                    if (error)
                        return "stats." + error;
                }
                if (message.error != null && message.hasOwnProperty("error")) {
                    var error = $root.protocol.AMLServiceStatusResponse.Error.verify(message.error);
                    if (error)
                        return "error." + error;
                }
                return null;
            };
    
            /**
             * Creates a AMLServiceStatusResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLServiceStatusResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLServiceStatusResponse} AMLServiceStatusResponse
             */
            AMLServiceStatusResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLServiceStatusResponse)
                    return object;
                var message = new $root.protocol.AMLServiceStatusResponse();
                switch (object.status) {
                case "UNK_AML_SERVICE_STATUS":
                case 0:
                    message.status = 0;
                    break;
                case "OK_AML_SERVICE_STATUS":
                case 1:
                    message.status = 1;
                    break;
                case "ERROR_AML_SERVICE_STATUS":
                case 2:
                    message.status = 2;
                    break;
                }
                if (object.stats != null) {
                    if (typeof object.stats !== "object")
                        throw TypeError(".protocol.AMLServiceStatusResponse.stats: object expected");
                    message.stats = $root.protocol.AMLServiceStatusResponse.Stats.fromObject(object.stats);
                }
                if (object.error != null) {
                    if (typeof object.error !== "object")
                        throw TypeError(".protocol.AMLServiceStatusResponse.error: object expected");
                    message.error = $root.protocol.AMLServiceStatusResponse.Error.fromObject(object.error);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a AMLServiceStatusResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLServiceStatusResponse
             * @static
             * @param {protocol.AMLServiceStatusResponse} message AMLServiceStatusResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLServiceStatusResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.status = options.enums === String ? "UNK_AML_SERVICE_STATUS" : 0;
                    object.stats = null;
                    object.error = null;
                }
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.protocol.AMLServiceStatus[message.status] : message.status;
                if (message.stats != null && message.hasOwnProperty("stats"))
                    object.stats = $root.protocol.AMLServiceStatusResponse.Stats.toObject(message.stats, options);
                if (message.error != null && message.hasOwnProperty("error"))
                    object.error = $root.protocol.AMLServiceStatusResponse.Error.toObject(message.error, options);
                return object;
            };
    
            /**
             * Converts this AMLServiceStatusResponse to JSON.
             * @function toJSON
             * @memberof protocol.AMLServiceStatusResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLServiceStatusResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            AMLServiceStatusResponse.Error = (function() {
    
                /**
                 * Properties of an Error.
                 * @memberof protocol.AMLServiceStatusResponse
                 * @interface IError
                 * @property {Long|null} [code] Error code
                 * @property {string|null} [message] Error message
                 */
    
                /**
                 * Constructs a new Error.
                 * @memberof protocol.AMLServiceStatusResponse
                 * @classdesc Represents an Error.
                 * @implements IError
                 * @constructor
                 * @param {protocol.AMLServiceStatusResponse.IError=} [properties] Properties to set
                 */
                function Error(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Error code.
                 * @member {Long} code
                 * @memberof protocol.AMLServiceStatusResponse.Error
                 * @instance
                 */
                Error.prototype.code = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Error message.
                 * @member {string} message
                 * @memberof protocol.AMLServiceStatusResponse.Error
                 * @instance
                 */
                Error.prototype.message = "";
    
                /**
                 * Creates a new Error instance using the specified properties.
                 * @function create
                 * @memberof protocol.AMLServiceStatusResponse.Error
                 * @static
                 * @param {protocol.AMLServiceStatusResponse.IError=} [properties] Properties to set
                 * @returns {protocol.AMLServiceStatusResponse.Error} Error instance
                 */
                Error.create = function create(properties) {
                    return new Error(properties);
                };
    
                /**
                 * Encodes the specified Error message. Does not implicitly {@link protocol.AMLServiceStatusResponse.Error.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.AMLServiceStatusResponse.Error
                 * @static
                 * @param {protocol.AMLServiceStatusResponse.IError} message Error message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Error.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.code);
                    if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
                    return writer;
                };
    
                /**
                 * Encodes the specified Error message, length delimited. Does not implicitly {@link protocol.AMLServiceStatusResponse.Error.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.AMLServiceStatusResponse.Error
                 * @static
                 * @param {protocol.AMLServiceStatusResponse.IError} message Error message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Error.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Error message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.AMLServiceStatusResponse.Error
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.AMLServiceStatusResponse.Error} Error
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Error.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLServiceStatusResponse.Error();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.code = reader.int64();
                            break;
                        case 2:
                            message.message = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Error message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.AMLServiceStatusResponse.Error
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.AMLServiceStatusResponse.Error} Error
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Error.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Error message.
                 * @function verify
                 * @memberof protocol.AMLServiceStatusResponse.Error
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Error.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (!$util.isInteger(message.code) && !(message.code && $util.isInteger(message.code.low) && $util.isInteger(message.code.high)))
                            return "code: integer|Long expected";
                    if (message.message != null && message.hasOwnProperty("message"))
                        if (!$util.isString(message.message))
                            return "message: string expected";
                    return null;
                };
    
                /**
                 * Creates an Error message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.AMLServiceStatusResponse.Error
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.AMLServiceStatusResponse.Error} Error
                 */
                Error.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.AMLServiceStatusResponse.Error)
                        return object;
                    var message = new $root.protocol.AMLServiceStatusResponse.Error();
                    if (object.code != null)
                        if ($util.Long)
                            (message.code = $util.Long.fromValue(object.code)).unsigned = false;
                        else if (typeof object.code === "string")
                            message.code = parseInt(object.code, 10);
                        else if (typeof object.code === "number")
                            message.code = object.code;
                        else if (typeof object.code === "object")
                            message.code = new $util.LongBits(object.code.low >>> 0, object.code.high >>> 0).toNumber();
                    if (object.message != null)
                        message.message = String(object.message);
                    return message;
                };
    
                /**
                 * Creates a plain object from an Error message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.AMLServiceStatusResponse.Error
                 * @static
                 * @param {protocol.AMLServiceStatusResponse.Error} message Error
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Error.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.code = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.code = options.longs === String ? "0" : 0;
                        object.message = "";
                    }
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (typeof message.code === "number")
                            object.code = options.longs === String ? String(message.code) : message.code;
                        else
                            object.code = options.longs === String ? $util.Long.prototype.toString.call(message.code) : options.longs === Number ? new $util.LongBits(message.code.low >>> 0, message.code.high >>> 0).toNumber() : message.code;
                    if (message.message != null && message.hasOwnProperty("message"))
                        object.message = message.message;
                    return object;
                };
    
                /**
                 * Converts this Error to JSON.
                 * @function toJSON
                 * @memberof protocol.AMLServiceStatusResponse.Error
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Error.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Error;
            })();
    
            AMLServiceStatusResponse.Stats = (function() {
    
                /**
                 * Properties of a Stats.
                 * @memberof protocol.AMLServiceStatusResponse
                 * @interface IStats
                 * @property {Long|null} [checksLeft] Stats checksLeft
                 * @property {Long|null} [checksUsed] Stats checksUsed
                 */
    
                /**
                 * Constructs a new Stats.
                 * @memberof protocol.AMLServiceStatusResponse
                 * @classdesc Represents a Stats.
                 * @implements IStats
                 * @constructor
                 * @param {protocol.AMLServiceStatusResponse.IStats=} [properties] Properties to set
                 */
                function Stats(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Stats checksLeft.
                 * @member {Long} checksLeft
                 * @memberof protocol.AMLServiceStatusResponse.Stats
                 * @instance
                 */
                Stats.prototype.checksLeft = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Stats checksUsed.
                 * @member {Long} checksUsed
                 * @memberof protocol.AMLServiceStatusResponse.Stats
                 * @instance
                 */
                Stats.prototype.checksUsed = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new Stats instance using the specified properties.
                 * @function create
                 * @memberof protocol.AMLServiceStatusResponse.Stats
                 * @static
                 * @param {protocol.AMLServiceStatusResponse.IStats=} [properties] Properties to set
                 * @returns {protocol.AMLServiceStatusResponse.Stats} Stats instance
                 */
                Stats.create = function create(properties) {
                    return new Stats(properties);
                };
    
                /**
                 * Encodes the specified Stats message. Does not implicitly {@link protocol.AMLServiceStatusResponse.Stats.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.AMLServiceStatusResponse.Stats
                 * @static
                 * @param {protocol.AMLServiceStatusResponse.IStats} message Stats message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Stats.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.checksLeft != null && Object.hasOwnProperty.call(message, "checksLeft"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.checksLeft);
                    if (message.checksUsed != null && Object.hasOwnProperty.call(message, "checksUsed"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.checksUsed);
                    return writer;
                };
    
                /**
                 * Encodes the specified Stats message, length delimited. Does not implicitly {@link protocol.AMLServiceStatusResponse.Stats.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.AMLServiceStatusResponse.Stats
                 * @static
                 * @param {protocol.AMLServiceStatusResponse.IStats} message Stats message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Stats.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Stats message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.AMLServiceStatusResponse.Stats
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.AMLServiceStatusResponse.Stats} Stats
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Stats.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLServiceStatusResponse.Stats();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.checksLeft = reader.int64();
                            break;
                        case 2:
                            message.checksUsed = reader.int64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Stats message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.AMLServiceStatusResponse.Stats
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.AMLServiceStatusResponse.Stats} Stats
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Stats.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Stats message.
                 * @function verify
                 * @memberof protocol.AMLServiceStatusResponse.Stats
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Stats.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.checksLeft != null && message.hasOwnProperty("checksLeft"))
                        if (!$util.isInteger(message.checksLeft) && !(message.checksLeft && $util.isInteger(message.checksLeft.low) && $util.isInteger(message.checksLeft.high)))
                            return "checksLeft: integer|Long expected";
                    if (message.checksUsed != null && message.hasOwnProperty("checksUsed"))
                        if (!$util.isInteger(message.checksUsed) && !(message.checksUsed && $util.isInteger(message.checksUsed.low) && $util.isInteger(message.checksUsed.high)))
                            return "checksUsed: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a Stats message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.AMLServiceStatusResponse.Stats
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.AMLServiceStatusResponse.Stats} Stats
                 */
                Stats.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.AMLServiceStatusResponse.Stats)
                        return object;
                    var message = new $root.protocol.AMLServiceStatusResponse.Stats();
                    if (object.checksLeft != null)
                        if ($util.Long)
                            (message.checksLeft = $util.Long.fromValue(object.checksLeft)).unsigned = false;
                        else if (typeof object.checksLeft === "string")
                            message.checksLeft = parseInt(object.checksLeft, 10);
                        else if (typeof object.checksLeft === "number")
                            message.checksLeft = object.checksLeft;
                        else if (typeof object.checksLeft === "object")
                            message.checksLeft = new $util.LongBits(object.checksLeft.low >>> 0, object.checksLeft.high >>> 0).toNumber();
                    if (object.checksUsed != null)
                        if ($util.Long)
                            (message.checksUsed = $util.Long.fromValue(object.checksUsed)).unsigned = false;
                        else if (typeof object.checksUsed === "string")
                            message.checksUsed = parseInt(object.checksUsed, 10);
                        else if (typeof object.checksUsed === "number")
                            message.checksUsed = object.checksUsed;
                        else if (typeof object.checksUsed === "object")
                            message.checksUsed = new $util.LongBits(object.checksUsed.low >>> 0, object.checksUsed.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a Stats message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.AMLServiceStatusResponse.Stats
                 * @static
                 * @param {protocol.AMLServiceStatusResponse.Stats} message Stats
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Stats.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.checksLeft = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.checksLeft = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.checksUsed = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.checksUsed = options.longs === String ? "0" : 0;
                    }
                    if (message.checksLeft != null && message.hasOwnProperty("checksLeft"))
                        if (typeof message.checksLeft === "number")
                            object.checksLeft = options.longs === String ? String(message.checksLeft) : message.checksLeft;
                        else
                            object.checksLeft = options.longs === String ? $util.Long.prototype.toString.call(message.checksLeft) : options.longs === Number ? new $util.LongBits(message.checksLeft.low >>> 0, message.checksLeft.high >>> 0).toNumber() : message.checksLeft;
                    if (message.checksUsed != null && message.hasOwnProperty("checksUsed"))
                        if (typeof message.checksUsed === "number")
                            object.checksUsed = options.longs === String ? String(message.checksUsed) : message.checksUsed;
                        else
                            object.checksUsed = options.longs === String ? $util.Long.prototype.toString.call(message.checksUsed) : options.longs === Number ? new $util.LongBits(message.checksUsed.low >>> 0, message.checksUsed.high >>> 0).toNumber() : message.checksUsed;
                    return object;
                };
    
                /**
                 * Converts this Stats to JSON.
                 * @function toJSON
                 * @memberof protocol.AMLServiceStatusResponse.Stats
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Stats.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Stats;
            })();
    
            return AMLServiceStatusResponse;
        })();
    
        protocol.AMLClientOverallStatsRequest = (function() {
    
            /**
             * Properties of a AMLClientOverallStatsRequest.
             * @memberof protocol
             * @interface IAMLClientOverallStatsRequest
             */
    
            /**
             * Constructs a new AMLClientOverallStatsRequest.
             * @memberof protocol
             * @classdesc Represents a AMLClientOverallStatsRequest.
             * @implements IAMLClientOverallStatsRequest
             * @constructor
             * @param {protocol.IAMLClientOverallStatsRequest=} [properties] Properties to set
             */
            function AMLClientOverallStatsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new AMLClientOverallStatsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientOverallStatsRequest
             * @static
             * @param {protocol.IAMLClientOverallStatsRequest=} [properties] Properties to set
             * @returns {protocol.AMLClientOverallStatsRequest} AMLClientOverallStatsRequest instance
             */
            AMLClientOverallStatsRequest.create = function create(properties) {
                return new AMLClientOverallStatsRequest(properties);
            };
    
            /**
             * Encodes the specified AMLClientOverallStatsRequest message. Does not implicitly {@link protocol.AMLClientOverallStatsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientOverallStatsRequest
             * @static
             * @param {protocol.IAMLClientOverallStatsRequest} message AMLClientOverallStatsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientOverallStatsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientOverallStatsRequest message, length delimited. Does not implicitly {@link protocol.AMLClientOverallStatsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientOverallStatsRequest
             * @static
             * @param {protocol.IAMLClientOverallStatsRequest} message AMLClientOverallStatsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientOverallStatsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientOverallStatsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientOverallStatsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientOverallStatsRequest} AMLClientOverallStatsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientOverallStatsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientOverallStatsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientOverallStatsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientOverallStatsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientOverallStatsRequest} AMLClientOverallStatsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientOverallStatsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientOverallStatsRequest message.
             * @function verify
             * @memberof protocol.AMLClientOverallStatsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientOverallStatsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a AMLClientOverallStatsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientOverallStatsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientOverallStatsRequest} AMLClientOverallStatsRequest
             */
            AMLClientOverallStatsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientOverallStatsRequest)
                    return object;
                return new $root.protocol.AMLClientOverallStatsRequest();
            };
    
            /**
             * Creates a plain object from a AMLClientOverallStatsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientOverallStatsRequest
             * @static
             * @param {protocol.AMLClientOverallStatsRequest} message AMLClientOverallStatsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientOverallStatsRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this AMLClientOverallStatsRequest to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientOverallStatsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientOverallStatsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientOverallStatsRequest;
        })();
    
        protocol.AMLClientOverallStatsResponse = (function() {
    
            /**
             * Properties of a AMLClientOverallStatsResponse.
             * @memberof protocol
             * @interface IAMLClientOverallStatsResponse
             * @property {Long|null} [totalAddressesCount] AMLClientOverallStatsResponse totalAddressesCount
             * @property {Long|null} [totalCheckedAddressesCount] AMLClientOverallStatsResponse totalCheckedAddressesCount
             * @property {Object.<string,protocol.IAMLGroupSource>|null} [sources] AMLClientOverallStatsResponse sources
             */
    
            /**
             * Constructs a new AMLClientOverallStatsResponse.
             * @memberof protocol
             * @classdesc Represents a AMLClientOverallStatsResponse.
             * @implements IAMLClientOverallStatsResponse
             * @constructor
             * @param {protocol.IAMLClientOverallStatsResponse=} [properties] Properties to set
             */
            function AMLClientOverallStatsResponse(properties) {
                this.sources = {};
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientOverallStatsResponse totalAddressesCount.
             * @member {Long} totalAddressesCount
             * @memberof protocol.AMLClientOverallStatsResponse
             * @instance
             */
            AMLClientOverallStatsResponse.prototype.totalAddressesCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientOverallStatsResponse totalCheckedAddressesCount.
             * @member {Long} totalCheckedAddressesCount
             * @memberof protocol.AMLClientOverallStatsResponse
             * @instance
             */
            AMLClientOverallStatsResponse.prototype.totalCheckedAddressesCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientOverallStatsResponse sources.
             * @member {Object.<string,protocol.IAMLGroupSource>} sources
             * @memberof protocol.AMLClientOverallStatsResponse
             * @instance
             */
            AMLClientOverallStatsResponse.prototype.sources = $util.emptyObject;
    
            /**
             * Creates a new AMLClientOverallStatsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientOverallStatsResponse
             * @static
             * @param {protocol.IAMLClientOverallStatsResponse=} [properties] Properties to set
             * @returns {protocol.AMLClientOverallStatsResponse} AMLClientOverallStatsResponse instance
             */
            AMLClientOverallStatsResponse.create = function create(properties) {
                return new AMLClientOverallStatsResponse(properties);
            };
    
            /**
             * Encodes the specified AMLClientOverallStatsResponse message. Does not implicitly {@link protocol.AMLClientOverallStatsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientOverallStatsResponse
             * @static
             * @param {protocol.IAMLClientOverallStatsResponse} message AMLClientOverallStatsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientOverallStatsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.totalAddressesCount != null && Object.hasOwnProperty.call(message, "totalAddressesCount"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.totalAddressesCount);
                if (message.totalCheckedAddressesCount != null && Object.hasOwnProperty.call(message, "totalCheckedAddressesCount"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalCheckedAddressesCount);
                if (message.sources != null && Object.hasOwnProperty.call(message, "sources"))
                    for (var keys = Object.keys(message.sources), i = 0; i < keys.length; ++i) {
                        writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                        $root.protocol.AMLGroupSource.encode(message.sources[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                    }
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientOverallStatsResponse message, length delimited. Does not implicitly {@link protocol.AMLClientOverallStatsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientOverallStatsResponse
             * @static
             * @param {protocol.IAMLClientOverallStatsResponse} message AMLClientOverallStatsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientOverallStatsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientOverallStatsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientOverallStatsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientOverallStatsResponse} AMLClientOverallStatsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientOverallStatsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientOverallStatsResponse(), key;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.totalAddressesCount = reader.int64();
                        break;
                    case 2:
                        message.totalCheckedAddressesCount = reader.int64();
                        break;
                    case 3:
                        reader.skip().pos++;
                        if (message.sources === $util.emptyObject)
                            message.sources = {};
                        key = reader.string();
                        reader.pos++;
                        message.sources[key] = $root.protocol.AMLGroupSource.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientOverallStatsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientOverallStatsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientOverallStatsResponse} AMLClientOverallStatsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientOverallStatsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientOverallStatsResponse message.
             * @function verify
             * @memberof protocol.AMLClientOverallStatsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientOverallStatsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.totalAddressesCount != null && message.hasOwnProperty("totalAddressesCount"))
                    if (!$util.isInteger(message.totalAddressesCount) && !(message.totalAddressesCount && $util.isInteger(message.totalAddressesCount.low) && $util.isInteger(message.totalAddressesCount.high)))
                        return "totalAddressesCount: integer|Long expected";
                if (message.totalCheckedAddressesCount != null && message.hasOwnProperty("totalCheckedAddressesCount"))
                    if (!$util.isInteger(message.totalCheckedAddressesCount) && !(message.totalCheckedAddressesCount && $util.isInteger(message.totalCheckedAddressesCount.low) && $util.isInteger(message.totalCheckedAddressesCount.high)))
                        return "totalCheckedAddressesCount: integer|Long expected";
                if (message.sources != null && message.hasOwnProperty("sources")) {
                    if (!$util.isObject(message.sources))
                        return "sources: object expected";
                    var key = Object.keys(message.sources);
                    for (var i = 0; i < key.length; ++i) {
                        var error = $root.protocol.AMLGroupSource.verify(message.sources[key[i]]);
                        if (error)
                            return "sources." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a AMLClientOverallStatsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientOverallStatsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientOverallStatsResponse} AMLClientOverallStatsResponse
             */
            AMLClientOverallStatsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientOverallStatsResponse)
                    return object;
                var message = new $root.protocol.AMLClientOverallStatsResponse();
                if (object.totalAddressesCount != null)
                    if ($util.Long)
                        (message.totalAddressesCount = $util.Long.fromValue(object.totalAddressesCount)).unsigned = false;
                    else if (typeof object.totalAddressesCount === "string")
                        message.totalAddressesCount = parseInt(object.totalAddressesCount, 10);
                    else if (typeof object.totalAddressesCount === "number")
                        message.totalAddressesCount = object.totalAddressesCount;
                    else if (typeof object.totalAddressesCount === "object")
                        message.totalAddressesCount = new $util.LongBits(object.totalAddressesCount.low >>> 0, object.totalAddressesCount.high >>> 0).toNumber();
                if (object.totalCheckedAddressesCount != null)
                    if ($util.Long)
                        (message.totalCheckedAddressesCount = $util.Long.fromValue(object.totalCheckedAddressesCount)).unsigned = false;
                    else if (typeof object.totalCheckedAddressesCount === "string")
                        message.totalCheckedAddressesCount = parseInt(object.totalCheckedAddressesCount, 10);
                    else if (typeof object.totalCheckedAddressesCount === "number")
                        message.totalCheckedAddressesCount = object.totalCheckedAddressesCount;
                    else if (typeof object.totalCheckedAddressesCount === "object")
                        message.totalCheckedAddressesCount = new $util.LongBits(object.totalCheckedAddressesCount.low >>> 0, object.totalCheckedAddressesCount.high >>> 0).toNumber();
                if (object.sources) {
                    if (typeof object.sources !== "object")
                        throw TypeError(".protocol.AMLClientOverallStatsResponse.sources: object expected");
                    message.sources = {};
                    for (var keys = Object.keys(object.sources), i = 0; i < keys.length; ++i) {
                        if (typeof object.sources[keys[i]] !== "object")
                            throw TypeError(".protocol.AMLClientOverallStatsResponse.sources: object expected");
                        message.sources[keys[i]] = $root.protocol.AMLGroupSource.fromObject(object.sources[keys[i]]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientOverallStatsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientOverallStatsResponse
             * @static
             * @param {protocol.AMLClientOverallStatsResponse} message AMLClientOverallStatsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientOverallStatsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.objects || options.defaults)
                    object.sources = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalAddressesCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalAddressesCount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalCheckedAddressesCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalCheckedAddressesCount = options.longs === String ? "0" : 0;
                }
                if (message.totalAddressesCount != null && message.hasOwnProperty("totalAddressesCount"))
                    if (typeof message.totalAddressesCount === "number")
                        object.totalAddressesCount = options.longs === String ? String(message.totalAddressesCount) : message.totalAddressesCount;
                    else
                        object.totalAddressesCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalAddressesCount) : options.longs === Number ? new $util.LongBits(message.totalAddressesCount.low >>> 0, message.totalAddressesCount.high >>> 0).toNumber() : message.totalAddressesCount;
                if (message.totalCheckedAddressesCount != null && message.hasOwnProperty("totalCheckedAddressesCount"))
                    if (typeof message.totalCheckedAddressesCount === "number")
                        object.totalCheckedAddressesCount = options.longs === String ? String(message.totalCheckedAddressesCount) : message.totalCheckedAddressesCount;
                    else
                        object.totalCheckedAddressesCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalCheckedAddressesCount) : options.longs === Number ? new $util.LongBits(message.totalCheckedAddressesCount.low >>> 0, message.totalCheckedAddressesCount.high >>> 0).toNumber() : message.totalCheckedAddressesCount;
                var keys2;
                if (message.sources && (keys2 = Object.keys(message.sources)).length) {
                    object.sources = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.sources[keys2[j]] = $root.protocol.AMLGroupSource.toObject(message.sources[keys2[j]], options);
                }
                return object;
            };
    
            /**
             * Converts this AMLClientOverallStatsResponse to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientOverallStatsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientOverallStatsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientOverallStatsResponse;
        })();
    
        protocol.AMLFilters = (function() {
    
            /**
             * Properties of a AMLFilters.
             * @memberof protocol
             * @interface IAMLFilters
             * @property {Array.<string>|null} [addresses] AMLFilters addresses
             * @property {Array.<string>|null} [excludeTags] AMLFilters excludeTags
             * @property {Array.<string>|null} [includeTags] AMLFilters includeTags
             * @property {Array.<protocol.Currency>|null} [currencies] AMLFilters currencies
             * @property {string|null} [minBalanceUsd] AMLFilters minBalanceUsd
             * @property {string|null} [maxBalanceUsd] AMLFilters maxBalanceUsd
             * @property {Long|null} [checkFraction] AMLFilters checkFraction
             */
    
            /**
             * Constructs a new AMLFilters.
             * @memberof protocol
             * @classdesc Represents a AMLFilters.
             * @implements IAMLFilters
             * @constructor
             * @param {protocol.IAMLFilters=} [properties] Properties to set
             */
            function AMLFilters(properties) {
                this.addresses = [];
                this.excludeTags = [];
                this.includeTags = [];
                this.currencies = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLFilters addresses.
             * @member {Array.<string>} addresses
             * @memberof protocol.AMLFilters
             * @instance
             */
            AMLFilters.prototype.addresses = $util.emptyArray;
    
            /**
             * AMLFilters excludeTags.
             * @member {Array.<string>} excludeTags
             * @memberof protocol.AMLFilters
             * @instance
             */
            AMLFilters.prototype.excludeTags = $util.emptyArray;
    
            /**
             * AMLFilters includeTags.
             * @member {Array.<string>} includeTags
             * @memberof protocol.AMLFilters
             * @instance
             */
            AMLFilters.prototype.includeTags = $util.emptyArray;
    
            /**
             * AMLFilters currencies.
             * @member {Array.<protocol.Currency>} currencies
             * @memberof protocol.AMLFilters
             * @instance
             */
            AMLFilters.prototype.currencies = $util.emptyArray;
    
            /**
             * AMLFilters minBalanceUsd.
             * @member {string} minBalanceUsd
             * @memberof protocol.AMLFilters
             * @instance
             */
            AMLFilters.prototype.minBalanceUsd = "";
    
            /**
             * AMLFilters maxBalanceUsd.
             * @member {string} maxBalanceUsd
             * @memberof protocol.AMLFilters
             * @instance
             */
            AMLFilters.prototype.maxBalanceUsd = "";
    
            /**
             * AMLFilters checkFraction.
             * @member {Long} checkFraction
             * @memberof protocol.AMLFilters
             * @instance
             */
            AMLFilters.prototype.checkFraction = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new AMLFilters instance using the specified properties.
             * @function create
             * @memberof protocol.AMLFilters
             * @static
             * @param {protocol.IAMLFilters=} [properties] Properties to set
             * @returns {protocol.AMLFilters} AMLFilters instance
             */
            AMLFilters.create = function create(properties) {
                return new AMLFilters(properties);
            };
    
            /**
             * Encodes the specified AMLFilters message. Does not implicitly {@link protocol.AMLFilters.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLFilters
             * @static
             * @param {protocol.IAMLFilters} message AMLFilters message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLFilters.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.addresses != null && message.addresses.length)
                    for (var i = 0; i < message.addresses.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.addresses[i]);
                if (message.excludeTags != null && message.excludeTags.length)
                    for (var i = 0; i < message.excludeTags.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.excludeTags[i]);
                if (message.includeTags != null && message.includeTags.length)
                    for (var i = 0; i < message.includeTags.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.includeTags[i]);
                if (message.currencies != null && message.currencies.length) {
                    writer.uint32(/* id 4, wireType 2 =*/34).fork();
                    for (var i = 0; i < message.currencies.length; ++i)
                        writer.int32(message.currencies[i]);
                    writer.ldelim();
                }
                if (message.minBalanceUsd != null && Object.hasOwnProperty.call(message, "minBalanceUsd"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.minBalanceUsd);
                if (message.maxBalanceUsd != null && Object.hasOwnProperty.call(message, "maxBalanceUsd"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.maxBalanceUsd);
                if (message.checkFraction != null && Object.hasOwnProperty.call(message, "checkFraction"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.checkFraction);
                return writer;
            };
    
            /**
             * Encodes the specified AMLFilters message, length delimited. Does not implicitly {@link protocol.AMLFilters.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLFilters
             * @static
             * @param {protocol.IAMLFilters} message AMLFilters message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLFilters.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLFilters message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLFilters
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLFilters} AMLFilters
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLFilters.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLFilters();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.addresses && message.addresses.length))
                            message.addresses = [];
                        message.addresses.push(reader.string());
                        break;
                    case 2:
                        if (!(message.excludeTags && message.excludeTags.length))
                            message.excludeTags = [];
                        message.excludeTags.push(reader.string());
                        break;
                    case 3:
                        if (!(message.includeTags && message.includeTags.length))
                            message.includeTags = [];
                        message.includeTags.push(reader.string());
                        break;
                    case 4:
                        if (!(message.currencies && message.currencies.length))
                            message.currencies = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.currencies.push(reader.int32());
                        } else
                            message.currencies.push(reader.int32());
                        break;
                    case 5:
                        message.minBalanceUsd = reader.string();
                        break;
                    case 6:
                        message.maxBalanceUsd = reader.string();
                        break;
                    case 7:
                        message.checkFraction = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLFilters message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLFilters
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLFilters} AMLFilters
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLFilters.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLFilters message.
             * @function verify
             * @memberof protocol.AMLFilters
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLFilters.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.addresses != null && message.hasOwnProperty("addresses")) {
                    if (!Array.isArray(message.addresses))
                        return "addresses: array expected";
                    for (var i = 0; i < message.addresses.length; ++i)
                        if (!$util.isString(message.addresses[i]))
                            return "addresses: string[] expected";
                }
                if (message.excludeTags != null && message.hasOwnProperty("excludeTags")) {
                    if (!Array.isArray(message.excludeTags))
                        return "excludeTags: array expected";
                    for (var i = 0; i < message.excludeTags.length; ++i)
                        if (!$util.isString(message.excludeTags[i]))
                            return "excludeTags: string[] expected";
                }
                if (message.includeTags != null && message.hasOwnProperty("includeTags")) {
                    if (!Array.isArray(message.includeTags))
                        return "includeTags: array expected";
                    for (var i = 0; i < message.includeTags.length; ++i)
                        if (!$util.isString(message.includeTags[i]))
                            return "includeTags: string[] expected";
                }
                if (message.currencies != null && message.hasOwnProperty("currencies")) {
                    if (!Array.isArray(message.currencies))
                        return "currencies: array expected";
                    for (var i = 0; i < message.currencies.length; ++i)
                        switch (message.currencies[i]) {
                        default:
                            return "currencies: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                }
                if (message.minBalanceUsd != null && message.hasOwnProperty("minBalanceUsd"))
                    if (!$util.isString(message.minBalanceUsd))
                        return "minBalanceUsd: string expected";
                if (message.maxBalanceUsd != null && message.hasOwnProperty("maxBalanceUsd"))
                    if (!$util.isString(message.maxBalanceUsd))
                        return "maxBalanceUsd: string expected";
                if (message.checkFraction != null && message.hasOwnProperty("checkFraction"))
                    if (!$util.isInteger(message.checkFraction) && !(message.checkFraction && $util.isInteger(message.checkFraction.low) && $util.isInteger(message.checkFraction.high)))
                        return "checkFraction: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a AMLFilters message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLFilters
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLFilters} AMLFilters
             */
            AMLFilters.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLFilters)
                    return object;
                var message = new $root.protocol.AMLFilters();
                if (object.addresses) {
                    if (!Array.isArray(object.addresses))
                        throw TypeError(".protocol.AMLFilters.addresses: array expected");
                    message.addresses = [];
                    for (var i = 0; i < object.addresses.length; ++i)
                        message.addresses[i] = String(object.addresses[i]);
                }
                if (object.excludeTags) {
                    if (!Array.isArray(object.excludeTags))
                        throw TypeError(".protocol.AMLFilters.excludeTags: array expected");
                    message.excludeTags = [];
                    for (var i = 0; i < object.excludeTags.length; ++i)
                        message.excludeTags[i] = String(object.excludeTags[i]);
                }
                if (object.includeTags) {
                    if (!Array.isArray(object.includeTags))
                        throw TypeError(".protocol.AMLFilters.includeTags: array expected");
                    message.includeTags = [];
                    for (var i = 0; i < object.includeTags.length; ++i)
                        message.includeTags[i] = String(object.includeTags[i]);
                }
                if (object.currencies) {
                    if (!Array.isArray(object.currencies))
                        throw TypeError(".protocol.AMLFilters.currencies: array expected");
                    message.currencies = [];
                    for (var i = 0; i < object.currencies.length; ++i)
                        switch (object.currencies[i]) {
                        default:
                        case "UNK_CUR":
                        case 0:
                            message.currencies[i] = 0;
                            break;
                        case "BTC_CUR":
                        case 1:
                            message.currencies[i] = 1;
                            break;
                        case "ETH_CUR":
                        case 2:
                            message.currencies[i] = 2;
                            break;
                        case "TRX_CUR":
                        case 3:
                            message.currencies[i] = 3;
                            break;
                        case "ERC20_USDT_CUR":
                        case 4:
                            message.currencies[i] = 4;
                            break;
                        case "TRC20_USDT_CUR":
                        case 5:
                            message.currencies[i] = 5;
                            break;
                        }
                }
                if (object.minBalanceUsd != null)
                    message.minBalanceUsd = String(object.minBalanceUsd);
                if (object.maxBalanceUsd != null)
                    message.maxBalanceUsd = String(object.maxBalanceUsd);
                if (object.checkFraction != null)
                    if ($util.Long)
                        (message.checkFraction = $util.Long.fromValue(object.checkFraction)).unsigned = false;
                    else if (typeof object.checkFraction === "string")
                        message.checkFraction = parseInt(object.checkFraction, 10);
                    else if (typeof object.checkFraction === "number")
                        message.checkFraction = object.checkFraction;
                    else if (typeof object.checkFraction === "object")
                        message.checkFraction = new $util.LongBits(object.checkFraction.low >>> 0, object.checkFraction.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a AMLFilters message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLFilters
             * @static
             * @param {protocol.AMLFilters} message AMLFilters
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLFilters.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.addresses = [];
                    object.excludeTags = [];
                    object.includeTags = [];
                    object.currencies = [];
                }
                if (options.defaults) {
                    object.minBalanceUsd = "";
                    object.maxBalanceUsd = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.checkFraction = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.checkFraction = options.longs === String ? "0" : 0;
                }
                if (message.addresses && message.addresses.length) {
                    object.addresses = [];
                    for (var j = 0; j < message.addresses.length; ++j)
                        object.addresses[j] = message.addresses[j];
                }
                if (message.excludeTags && message.excludeTags.length) {
                    object.excludeTags = [];
                    for (var j = 0; j < message.excludeTags.length; ++j)
                        object.excludeTags[j] = message.excludeTags[j];
                }
                if (message.includeTags && message.includeTags.length) {
                    object.includeTags = [];
                    for (var j = 0; j < message.includeTags.length; ++j)
                        object.includeTags[j] = message.includeTags[j];
                }
                if (message.currencies && message.currencies.length) {
                    object.currencies = [];
                    for (var j = 0; j < message.currencies.length; ++j)
                        object.currencies[j] = options.enums === String ? $root.protocol.Currency[message.currencies[j]] : message.currencies[j];
                }
                if (message.minBalanceUsd != null && message.hasOwnProperty("minBalanceUsd"))
                    object.minBalanceUsd = message.minBalanceUsd;
                if (message.maxBalanceUsd != null && message.hasOwnProperty("maxBalanceUsd"))
                    object.maxBalanceUsd = message.maxBalanceUsd;
                if (message.checkFraction != null && message.hasOwnProperty("checkFraction"))
                    if (typeof message.checkFraction === "number")
                        object.checkFraction = options.longs === String ? String(message.checkFraction) : message.checkFraction;
                    else
                        object.checkFraction = options.longs === String ? $util.Long.prototype.toString.call(message.checkFraction) : options.longs === Number ? new $util.LongBits(message.checkFraction.low >>> 0, message.checkFraction.high >>> 0).toNumber() : message.checkFraction;
                return object;
            };
    
            /**
             * Converts this AMLFilters to JSON.
             * @function toJSON
             * @memberof protocol.AMLFilters
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLFilters.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLFilters;
        })();
    
        protocol.AMLClientAddress = (function() {
    
            /**
             * Properties of a AMLClientAddress.
             * @memberof protocol
             * @interface IAMLClientAddress
             * @property {string|null} [address] AMLClientAddress address
             * @property {number|null} [riskScore] AMLClientAddress riskScore
             * @property {protocol.AMLRiskLevel|null} [riskLevel] AMLClientAddress riskLevel
             * @property {Long|null} [lastCheckedAt] AMLClientAddress lastCheckedAt
             * @property {Object.<string,protocol.IAMLSource>|null} [sources] AMLClientAddress sources
             * @property {protocol.Currency|null} [currency] AMLClientAddress currency
             * @property {Object.<string,protocol.AMLClientAddress.IBalance>|null} [balances] AMLClientAddress balances
             * @property {Array.<string>|null} [tags] AMLClientAddress tags
             */
    
            /**
             * Constructs a new AMLClientAddress.
             * @memberof protocol
             * @classdesc Represents a AMLClientAddress.
             * @implements IAMLClientAddress
             * @constructor
             * @param {protocol.IAMLClientAddress=} [properties] Properties to set
             */
            function AMLClientAddress(properties) {
                this.sources = {};
                this.balances = {};
                this.tags = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientAddress address.
             * @member {string} address
             * @memberof protocol.AMLClientAddress
             * @instance
             */
            AMLClientAddress.prototype.address = "";
    
            /**
             * AMLClientAddress riskScore.
             * @member {number} riskScore
             * @memberof protocol.AMLClientAddress
             * @instance
             */
            AMLClientAddress.prototype.riskScore = 0;
    
            /**
             * AMLClientAddress riskLevel.
             * @member {protocol.AMLRiskLevel} riskLevel
             * @memberof protocol.AMLClientAddress
             * @instance
             */
            AMLClientAddress.prototype.riskLevel = 0;
    
            /**
             * AMLClientAddress lastCheckedAt.
             * @member {Long} lastCheckedAt
             * @memberof protocol.AMLClientAddress
             * @instance
             */
            AMLClientAddress.prototype.lastCheckedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientAddress sources.
             * @member {Object.<string,protocol.IAMLSource>} sources
             * @memberof protocol.AMLClientAddress
             * @instance
             */
            AMLClientAddress.prototype.sources = $util.emptyObject;
    
            /**
             * AMLClientAddress currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.AMLClientAddress
             * @instance
             */
            AMLClientAddress.prototype.currency = 0;
    
            /**
             * AMLClientAddress balances.
             * @member {Object.<string,protocol.AMLClientAddress.IBalance>} balances
             * @memberof protocol.AMLClientAddress
             * @instance
             */
            AMLClientAddress.prototype.balances = $util.emptyObject;
    
            /**
             * AMLClientAddress tags.
             * @member {Array.<string>} tags
             * @memberof protocol.AMLClientAddress
             * @instance
             */
            AMLClientAddress.prototype.tags = $util.emptyArray;
    
            /**
             * Creates a new AMLClientAddress instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientAddress
             * @static
             * @param {protocol.IAMLClientAddress=} [properties] Properties to set
             * @returns {protocol.AMLClientAddress} AMLClientAddress instance
             */
            AMLClientAddress.create = function create(properties) {
                return new AMLClientAddress(properties);
            };
    
            /**
             * Encodes the specified AMLClientAddress message. Does not implicitly {@link protocol.AMLClientAddress.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientAddress
             * @static
             * @param {protocol.IAMLClientAddress} message AMLClientAddress message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientAddress.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
                if (message.riskScore != null && Object.hasOwnProperty.call(message, "riskScore"))
                    writer.uint32(/* id 2, wireType 5 =*/21).float(message.riskScore);
                if (message.riskLevel != null && Object.hasOwnProperty.call(message, "riskLevel"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.riskLevel);
                if (message.lastCheckedAt != null && Object.hasOwnProperty.call(message, "lastCheckedAt"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.lastCheckedAt);
                if (message.sources != null && Object.hasOwnProperty.call(message, "sources"))
                    for (var keys = Object.keys(message.sources), i = 0; i < keys.length; ++i) {
                        writer.uint32(/* id 5, wireType 2 =*/42).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                        $root.protocol.AMLSource.encode(message.sources[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                    }
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.currency);
                if (message.balances != null && Object.hasOwnProperty.call(message, "balances"))
                    for (var keys = Object.keys(message.balances), i = 0; i < keys.length; ++i) {
                        writer.uint32(/* id 7, wireType 2 =*/58).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                        $root.protocol.AMLClientAddress.Balance.encode(message.balances[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                    }
                if (message.tags != null && message.tags.length)
                    for (var i = 0; i < message.tags.length; ++i)
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.tags[i]);
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientAddress message, length delimited. Does not implicitly {@link protocol.AMLClientAddress.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientAddress
             * @static
             * @param {protocol.IAMLClientAddress} message AMLClientAddress message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientAddress.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientAddress message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientAddress
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientAddress} AMLClientAddress
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientAddress.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientAddress(), key;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.address = reader.string();
                        break;
                    case 2:
                        message.riskScore = reader.float();
                        break;
                    case 3:
                        message.riskLevel = reader.int32();
                        break;
                    case 4:
                        message.lastCheckedAt = reader.int64();
                        break;
                    case 5:
                        reader.skip().pos++;
                        if (message.sources === $util.emptyObject)
                            message.sources = {};
                        key = reader.string();
                        reader.pos++;
                        message.sources[key] = $root.protocol.AMLSource.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.currency = reader.int32();
                        break;
                    case 7:
                        reader.skip().pos++;
                        if (message.balances === $util.emptyObject)
                            message.balances = {};
                        key = reader.string();
                        reader.pos++;
                        message.balances[key] = $root.protocol.AMLClientAddress.Balance.decode(reader, reader.uint32());
                        break;
                    case 8:
                        if (!(message.tags && message.tags.length))
                            message.tags = [];
                        message.tags.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientAddress message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientAddress
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientAddress} AMLClientAddress
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientAddress.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientAddress message.
             * @function verify
             * @memberof protocol.AMLClientAddress
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientAddress.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                if (message.riskScore != null && message.hasOwnProperty("riskScore"))
                    if (typeof message.riskScore !== "number")
                        return "riskScore: number expected";
                if (message.riskLevel != null && message.hasOwnProperty("riskLevel"))
                    switch (message.riskLevel) {
                    default:
                        return "riskLevel: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.lastCheckedAt != null && message.hasOwnProperty("lastCheckedAt"))
                    if (!$util.isInteger(message.lastCheckedAt) && !(message.lastCheckedAt && $util.isInteger(message.lastCheckedAt.low) && $util.isInteger(message.lastCheckedAt.high)))
                        return "lastCheckedAt: integer|Long expected";
                if (message.sources != null && message.hasOwnProperty("sources")) {
                    if (!$util.isObject(message.sources))
                        return "sources: object expected";
                    var key = Object.keys(message.sources);
                    for (var i = 0; i < key.length; ++i) {
                        var error = $root.protocol.AMLSource.verify(message.sources[key[i]]);
                        if (error)
                            return "sources." + error;
                    }
                }
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.balances != null && message.hasOwnProperty("balances")) {
                    if (!$util.isObject(message.balances))
                        return "balances: object expected";
                    var key = Object.keys(message.balances);
                    for (var i = 0; i < key.length; ++i) {
                        var error = $root.protocol.AMLClientAddress.Balance.verify(message.balances[key[i]]);
                        if (error)
                            return "balances." + error;
                    }
                }
                if (message.tags != null && message.hasOwnProperty("tags")) {
                    if (!Array.isArray(message.tags))
                        return "tags: array expected";
                    for (var i = 0; i < message.tags.length; ++i)
                        if (!$util.isString(message.tags[i]))
                            return "tags: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a AMLClientAddress message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientAddress
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientAddress} AMLClientAddress
             */
            AMLClientAddress.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientAddress)
                    return object;
                var message = new $root.protocol.AMLClientAddress();
                if (object.address != null)
                    message.address = String(object.address);
                if (object.riskScore != null)
                    message.riskScore = Number(object.riskScore);
                switch (object.riskLevel) {
                case "UNK_AML_RISK_LEVEL":
                case 0:
                    message.riskLevel = 0;
                    break;
                case "LOW_AML_RISK_LEVEL":
                case 1:
                    message.riskLevel = 1;
                    break;
                case "MEDIUM_AML_RISK_LEVEL":
                case 2:
                    message.riskLevel = 2;
                    break;
                case "HIGH_AML_RISK_LEVEL":
                case 3:
                    message.riskLevel = 3;
                    break;
                }
                if (object.lastCheckedAt != null)
                    if ($util.Long)
                        (message.lastCheckedAt = $util.Long.fromValue(object.lastCheckedAt)).unsigned = false;
                    else if (typeof object.lastCheckedAt === "string")
                        message.lastCheckedAt = parseInt(object.lastCheckedAt, 10);
                    else if (typeof object.lastCheckedAt === "number")
                        message.lastCheckedAt = object.lastCheckedAt;
                    else if (typeof object.lastCheckedAt === "object")
                        message.lastCheckedAt = new $util.LongBits(object.lastCheckedAt.low >>> 0, object.lastCheckedAt.high >>> 0).toNumber();
                if (object.sources) {
                    if (typeof object.sources !== "object")
                        throw TypeError(".protocol.AMLClientAddress.sources: object expected");
                    message.sources = {};
                    for (var keys = Object.keys(object.sources), i = 0; i < keys.length; ++i) {
                        if (typeof object.sources[keys[i]] !== "object")
                            throw TypeError(".protocol.AMLClientAddress.sources: object expected");
                        message.sources[keys[i]] = $root.protocol.AMLSource.fromObject(object.sources[keys[i]]);
                    }
                }
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                if (object.balances) {
                    if (typeof object.balances !== "object")
                        throw TypeError(".protocol.AMLClientAddress.balances: object expected");
                    message.balances = {};
                    for (var keys = Object.keys(object.balances), i = 0; i < keys.length; ++i) {
                        if (typeof object.balances[keys[i]] !== "object")
                            throw TypeError(".protocol.AMLClientAddress.balances: object expected");
                        message.balances[keys[i]] = $root.protocol.AMLClientAddress.Balance.fromObject(object.balances[keys[i]]);
                    }
                }
                if (object.tags) {
                    if (!Array.isArray(object.tags))
                        throw TypeError(".protocol.AMLClientAddress.tags: array expected");
                    message.tags = [];
                    for (var i = 0; i < object.tags.length; ++i)
                        message.tags[i] = String(object.tags[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientAddress message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientAddress
             * @static
             * @param {protocol.AMLClientAddress} message AMLClientAddress
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientAddress.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.tags = [];
                if (options.objects || options.defaults) {
                    object.sources = {};
                    object.balances = {};
                }
                if (options.defaults) {
                    object.address = "";
                    object.riskScore = 0;
                    object.riskLevel = options.enums === String ? "UNK_AML_RISK_LEVEL" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.lastCheckedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lastCheckedAt = options.longs === String ? "0" : 0;
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                }
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.riskScore != null && message.hasOwnProperty("riskScore"))
                    object.riskScore = options.json && !isFinite(message.riskScore) ? String(message.riskScore) : message.riskScore;
                if (message.riskLevel != null && message.hasOwnProperty("riskLevel"))
                    object.riskLevel = options.enums === String ? $root.protocol.AMLRiskLevel[message.riskLevel] : message.riskLevel;
                if (message.lastCheckedAt != null && message.hasOwnProperty("lastCheckedAt"))
                    if (typeof message.lastCheckedAt === "number")
                        object.lastCheckedAt = options.longs === String ? String(message.lastCheckedAt) : message.lastCheckedAt;
                    else
                        object.lastCheckedAt = options.longs === String ? $util.Long.prototype.toString.call(message.lastCheckedAt) : options.longs === Number ? new $util.LongBits(message.lastCheckedAt.low >>> 0, message.lastCheckedAt.high >>> 0).toNumber() : message.lastCheckedAt;
                var keys2;
                if (message.sources && (keys2 = Object.keys(message.sources)).length) {
                    object.sources = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.sources[keys2[j]] = $root.protocol.AMLSource.toObject(message.sources[keys2[j]], options);
                }
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.balances && (keys2 = Object.keys(message.balances)).length) {
                    object.balances = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.balances[keys2[j]] = $root.protocol.AMLClientAddress.Balance.toObject(message.balances[keys2[j]], options);
                }
                if (message.tags && message.tags.length) {
                    object.tags = [];
                    for (var j = 0; j < message.tags.length; ++j)
                        object.tags[j] = message.tags[j];
                }
                return object;
            };
    
            /**
             * Converts this AMLClientAddress to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientAddress
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientAddress.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            AMLClientAddress.Balance = (function() {
    
                /**
                 * Properties of a Balance.
                 * @memberof protocol.AMLClientAddress
                 * @interface IBalance
                 * @property {protocol.Currency|null} [currency] Balance currency
                 * @property {string|null} [balance] Balance balance
                 * @property {string|null} [unconfirmedInBalance] Balance unconfirmedInBalance
                 * @property {string|null} [unconfirmedOutBalance] Balance unconfirmedOutBalance
                 */
    
                /**
                 * Constructs a new Balance.
                 * @memberof protocol.AMLClientAddress
                 * @classdesc Represents a Balance.
                 * @implements IBalance
                 * @constructor
                 * @param {protocol.AMLClientAddress.IBalance=} [properties] Properties to set
                 */
                function Balance(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Balance currency.
                 * @member {protocol.Currency} currency
                 * @memberof protocol.AMLClientAddress.Balance
                 * @instance
                 */
                Balance.prototype.currency = 0;
    
                /**
                 * Balance balance.
                 * @member {string} balance
                 * @memberof protocol.AMLClientAddress.Balance
                 * @instance
                 */
                Balance.prototype.balance = "";
    
                /**
                 * Balance unconfirmedInBalance.
                 * @member {string} unconfirmedInBalance
                 * @memberof protocol.AMLClientAddress.Balance
                 * @instance
                 */
                Balance.prototype.unconfirmedInBalance = "";
    
                /**
                 * Balance unconfirmedOutBalance.
                 * @member {string} unconfirmedOutBalance
                 * @memberof protocol.AMLClientAddress.Balance
                 * @instance
                 */
                Balance.prototype.unconfirmedOutBalance = "";
    
                /**
                 * Creates a new Balance instance using the specified properties.
                 * @function create
                 * @memberof protocol.AMLClientAddress.Balance
                 * @static
                 * @param {protocol.AMLClientAddress.IBalance=} [properties] Properties to set
                 * @returns {protocol.AMLClientAddress.Balance} Balance instance
                 */
                Balance.create = function create(properties) {
                    return new Balance(properties);
                };
    
                /**
                 * Encodes the specified Balance message. Does not implicitly {@link protocol.AMLClientAddress.Balance.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.AMLClientAddress.Balance
                 * @static
                 * @param {protocol.AMLClientAddress.IBalance} message Balance message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Balance.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currency);
                    if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.balance);
                    if (message.unconfirmedInBalance != null && Object.hasOwnProperty.call(message, "unconfirmedInBalance"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.unconfirmedInBalance);
                    if (message.unconfirmedOutBalance != null && Object.hasOwnProperty.call(message, "unconfirmedOutBalance"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.unconfirmedOutBalance);
                    return writer;
                };
    
                /**
                 * Encodes the specified Balance message, length delimited. Does not implicitly {@link protocol.AMLClientAddress.Balance.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.AMLClientAddress.Balance
                 * @static
                 * @param {protocol.AMLClientAddress.IBalance} message Balance message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Balance.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Balance message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.AMLClientAddress.Balance
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.AMLClientAddress.Balance} Balance
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Balance.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientAddress.Balance();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.currency = reader.int32();
                            break;
                        case 2:
                            message.balance = reader.string();
                            break;
                        case 3:
                            message.unconfirmedInBalance = reader.string();
                            break;
                        case 4:
                            message.unconfirmedOutBalance = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Balance message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.AMLClientAddress.Balance
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.AMLClientAddress.Balance} Balance
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Balance.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Balance message.
                 * @function verify
                 * @memberof protocol.AMLClientAddress.Balance
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Balance.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.currency != null && message.hasOwnProperty("currency"))
                        switch (message.currency) {
                        default:
                            return "currency: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.balance != null && message.hasOwnProperty("balance"))
                        if (!$util.isString(message.balance))
                            return "balance: string expected";
                    if (message.unconfirmedInBalance != null && message.hasOwnProperty("unconfirmedInBalance"))
                        if (!$util.isString(message.unconfirmedInBalance))
                            return "unconfirmedInBalance: string expected";
                    if (message.unconfirmedOutBalance != null && message.hasOwnProperty("unconfirmedOutBalance"))
                        if (!$util.isString(message.unconfirmedOutBalance))
                            return "unconfirmedOutBalance: string expected";
                    return null;
                };
    
                /**
                 * Creates a Balance message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.AMLClientAddress.Balance
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.AMLClientAddress.Balance} Balance
                 */
                Balance.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.AMLClientAddress.Balance)
                        return object;
                    var message = new $root.protocol.AMLClientAddress.Balance();
                    switch (object.currency) {
                    case "UNK_CUR":
                    case 0:
                        message.currency = 0;
                        break;
                    case "BTC_CUR":
                    case 1:
                        message.currency = 1;
                        break;
                    case "ETH_CUR":
                    case 2:
                        message.currency = 2;
                        break;
                    case "TRX_CUR":
                    case 3:
                        message.currency = 3;
                        break;
                    case "ERC20_USDT_CUR":
                    case 4:
                        message.currency = 4;
                        break;
                    case "TRC20_USDT_CUR":
                    case 5:
                        message.currency = 5;
                        break;
                    }
                    if (object.balance != null)
                        message.balance = String(object.balance);
                    if (object.unconfirmedInBalance != null)
                        message.unconfirmedInBalance = String(object.unconfirmedInBalance);
                    if (object.unconfirmedOutBalance != null)
                        message.unconfirmedOutBalance = String(object.unconfirmedOutBalance);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Balance message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.AMLClientAddress.Balance
                 * @static
                 * @param {protocol.AMLClientAddress.Balance} message Balance
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Balance.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.currency = options.enums === String ? "UNK_CUR" : 0;
                        object.balance = "";
                        object.unconfirmedInBalance = "";
                        object.unconfirmedOutBalance = "";
                    }
                    if (message.currency != null && message.hasOwnProperty("currency"))
                        object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                    if (message.balance != null && message.hasOwnProperty("balance"))
                        object.balance = message.balance;
                    if (message.unconfirmedInBalance != null && message.hasOwnProperty("unconfirmedInBalance"))
                        object.unconfirmedInBalance = message.unconfirmedInBalance;
                    if (message.unconfirmedOutBalance != null && message.hasOwnProperty("unconfirmedOutBalance"))
                        object.unconfirmedOutBalance = message.unconfirmedOutBalance;
                    return object;
                };
    
                /**
                 * Converts this Balance to JSON.
                 * @function toJSON
                 * @memberof protocol.AMLClientAddress.Balance
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Balance.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Balance;
            })();
    
            return AMLClientAddress;
        })();
    
        protocol.AMLClientAddressesListRequest = (function() {
    
            /**
             * Properties of a AMLClientAddressesListRequest.
             * @memberof protocol
             * @interface IAMLClientAddressesListRequest
             * @property {protocol.IAMLFilters|null} [filters] AMLClientAddressesListRequest filters
             * @property {Long|null} [limit] AMLClientAddressesListRequest limit
             * @property {Long|null} [offset] AMLClientAddressesListRequest offset
             * @property {protocol.SortBy|null} [sortBy] AMLClientAddressesListRequest sortBy
             * @property {boolean|null} [sortDesc] AMLClientAddressesListRequest sortDesc
             */
    
            /**
             * Constructs a new AMLClientAddressesListRequest.
             * @memberof protocol
             * @classdesc Represents a AMLClientAddressesListRequest.
             * @implements IAMLClientAddressesListRequest
             * @constructor
             * @param {protocol.IAMLClientAddressesListRequest=} [properties] Properties to set
             */
            function AMLClientAddressesListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientAddressesListRequest filters.
             * @member {protocol.IAMLFilters|null|undefined} filters
             * @memberof protocol.AMLClientAddressesListRequest
             * @instance
             */
            AMLClientAddressesListRequest.prototype.filters = null;
    
            /**
             * AMLClientAddressesListRequest limit.
             * @member {Long} limit
             * @memberof protocol.AMLClientAddressesListRequest
             * @instance
             */
            AMLClientAddressesListRequest.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientAddressesListRequest offset.
             * @member {Long} offset
             * @memberof protocol.AMLClientAddressesListRequest
             * @instance
             */
            AMLClientAddressesListRequest.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientAddressesListRequest sortBy.
             * @member {protocol.SortBy} sortBy
             * @memberof protocol.AMLClientAddressesListRequest
             * @instance
             */
            AMLClientAddressesListRequest.prototype.sortBy = 0;
    
            /**
             * AMLClientAddressesListRequest sortDesc.
             * @member {boolean} sortDesc
             * @memberof protocol.AMLClientAddressesListRequest
             * @instance
             */
            AMLClientAddressesListRequest.prototype.sortDesc = false;
    
            /**
             * Creates a new AMLClientAddressesListRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientAddressesListRequest
             * @static
             * @param {protocol.IAMLClientAddressesListRequest=} [properties] Properties to set
             * @returns {protocol.AMLClientAddressesListRequest} AMLClientAddressesListRequest instance
             */
            AMLClientAddressesListRequest.create = function create(properties) {
                return new AMLClientAddressesListRequest(properties);
            };
    
            /**
             * Encodes the specified AMLClientAddressesListRequest message. Does not implicitly {@link protocol.AMLClientAddressesListRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientAddressesListRequest
             * @static
             * @param {protocol.IAMLClientAddressesListRequest} message AMLClientAddressesListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientAddressesListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.filters != null && Object.hasOwnProperty.call(message, "filters"))
                    $root.protocol.AMLFilters.encode(message.filters, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.limit);
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.offset);
                if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.sortBy);
                if (message.sortDesc != null && Object.hasOwnProperty.call(message, "sortDesc"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.sortDesc);
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientAddressesListRequest message, length delimited. Does not implicitly {@link protocol.AMLClientAddressesListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientAddressesListRequest
             * @static
             * @param {protocol.IAMLClientAddressesListRequest} message AMLClientAddressesListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientAddressesListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientAddressesListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientAddressesListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientAddressesListRequest} AMLClientAddressesListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientAddressesListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientAddressesListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.filters = $root.protocol.AMLFilters.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.limit = reader.int64();
                        break;
                    case 3:
                        message.offset = reader.int64();
                        break;
                    case 4:
                        message.sortBy = reader.int32();
                        break;
                    case 5:
                        message.sortDesc = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientAddressesListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientAddressesListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientAddressesListRequest} AMLClientAddressesListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientAddressesListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientAddressesListRequest message.
             * @function verify
             * @memberof protocol.AMLClientAddressesListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientAddressesListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.filters != null && message.hasOwnProperty("filters")) {
                    var error = $root.protocol.AMLFilters.verify(message.filters);
                    if (error)
                        return "filters." + error;
                }
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                        return "limit: integer|Long expected";
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset) && !(message.offset && $util.isInteger(message.offset.low) && $util.isInteger(message.offset.high)))
                        return "offset: integer|Long expected";
                if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                    switch (message.sortBy) {
                    default:
                        return "sortBy: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                        break;
                    }
                if (message.sortDesc != null && message.hasOwnProperty("sortDesc"))
                    if (typeof message.sortDesc !== "boolean")
                        return "sortDesc: boolean expected";
                return null;
            };
    
            /**
             * Creates a AMLClientAddressesListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientAddressesListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientAddressesListRequest} AMLClientAddressesListRequest
             */
            AMLClientAddressesListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientAddressesListRequest)
                    return object;
                var message = new $root.protocol.AMLClientAddressesListRequest();
                if (object.filters != null) {
                    if (typeof object.filters !== "object")
                        throw TypeError(".protocol.AMLClientAddressesListRequest.filters: object expected");
                    message.filters = $root.protocol.AMLFilters.fromObject(object.filters);
                }
                if (object.limit != null)
                    if ($util.Long)
                        (message.limit = $util.Long.fromValue(object.limit)).unsigned = false;
                    else if (typeof object.limit === "string")
                        message.limit = parseInt(object.limit, 10);
                    else if (typeof object.limit === "number")
                        message.limit = object.limit;
                    else if (typeof object.limit === "object")
                        message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber();
                if (object.offset != null)
                    if ($util.Long)
                        (message.offset = $util.Long.fromValue(object.offset)).unsigned = false;
                    else if (typeof object.offset === "string")
                        message.offset = parseInt(object.offset, 10);
                    else if (typeof object.offset === "number")
                        message.offset = object.offset;
                    else if (typeof object.offset === "object")
                        message.offset = new $util.LongBits(object.offset.low >>> 0, object.offset.high >>> 0).toNumber();
                switch (object.sortBy) {
                case "UNK_SORT_BY":
                case 0:
                    message.sortBy = 0;
                    break;
                case "ID_SORT_BY":
                case 1:
                    message.sortBy = 1;
                    break;
                case "DATE_SORT_BY":
                case 2:
                    message.sortBy = 2;
                    break;
                case "CURRENCY_SORT_BY":
                case 3:
                    message.sortBy = 3;
                    break;
                case "TAGS_SORT_BY":
                case 4:
                    message.sortBy = 4;
                    break;
                case "TX_HASH_SORT_BY":
                case 5:
                    message.sortBy = 5;
                    break;
                case "AMOUNT_SORT_BY":
                case 6:
                    message.sortBy = 6;
                    break;
                case "NAME_SORT_BY":
                case 7:
                    message.sortBy = 7;
                    break;
                case "LAST_AMOUNT_SORT_BY":
                case 8:
                    message.sortBy = 8;
                    break;
                case "STATUS_SORT_BY":
                case 9:
                    message.sortBy = 9;
                    break;
                case "ENTITIES_COUNT_SORT_BY":
                case 10:
                    message.sortBy = 10;
                    break;
                case "ADDRESS_SORT_BY":
                case 11:
                    message.sortBy = 11;
                    break;
                case "RISK_SCORE_SORT_BY":
                case 12:
                    message.sortBy = 12;
                    break;
                case "BALANCE_SORT_BY":
                case 13:
                    message.sortBy = 13;
                    break;
                }
                if (object.sortDesc != null)
                    message.sortDesc = Boolean(object.sortDesc);
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientAddressesListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientAddressesListRequest
             * @static
             * @param {protocol.AMLClientAddressesListRequest} message AMLClientAddressesListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientAddressesListRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.filters = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.limit = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.offset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.offset = options.longs === String ? "0" : 0;
                    object.sortBy = options.enums === String ? "UNK_SORT_BY" : 0;
                    object.sortDesc = false;
                }
                if (message.filters != null && message.hasOwnProperty("filters"))
                    object.filters = $root.protocol.AMLFilters.toObject(message.filters, options);
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (typeof message.limit === "number")
                        object.limit = options.longs === String ? String(message.limit) : message.limit;
                    else
                        object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber() : message.limit;
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (typeof message.offset === "number")
                        object.offset = options.longs === String ? String(message.offset) : message.offset;
                    else
                        object.offset = options.longs === String ? $util.Long.prototype.toString.call(message.offset) : options.longs === Number ? new $util.LongBits(message.offset.low >>> 0, message.offset.high >>> 0).toNumber() : message.offset;
                if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                    object.sortBy = options.enums === String ? $root.protocol.SortBy[message.sortBy] : message.sortBy;
                if (message.sortDesc != null && message.hasOwnProperty("sortDesc"))
                    object.sortDesc = message.sortDesc;
                return object;
            };
    
            /**
             * Converts this AMLClientAddressesListRequest to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientAddressesListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientAddressesListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientAddressesListRequest;
        })();
    
        protocol.AMLClientAddressesListResponse = (function() {
    
            /**
             * Properties of a AMLClientAddressesListResponse.
             * @memberof protocol
             * @interface IAMLClientAddressesListResponse
             * @property {Array.<protocol.IAMLClientAddress>|null} [addresses] AMLClientAddressesListResponse addresses
             * @property {Long|null} [totalCount] AMLClientAddressesListResponse totalCount
             * @property {Long|null} [totalPages] AMLClientAddressesListResponse totalPages
             */
    
            /**
             * Constructs a new AMLClientAddressesListResponse.
             * @memberof protocol
             * @classdesc Represents a AMLClientAddressesListResponse.
             * @implements IAMLClientAddressesListResponse
             * @constructor
             * @param {protocol.IAMLClientAddressesListResponse=} [properties] Properties to set
             */
            function AMLClientAddressesListResponse(properties) {
                this.addresses = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientAddressesListResponse addresses.
             * @member {Array.<protocol.IAMLClientAddress>} addresses
             * @memberof protocol.AMLClientAddressesListResponse
             * @instance
             */
            AMLClientAddressesListResponse.prototype.addresses = $util.emptyArray;
    
            /**
             * AMLClientAddressesListResponse totalCount.
             * @member {Long} totalCount
             * @memberof protocol.AMLClientAddressesListResponse
             * @instance
             */
            AMLClientAddressesListResponse.prototype.totalCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientAddressesListResponse totalPages.
             * @member {Long} totalPages
             * @memberof protocol.AMLClientAddressesListResponse
             * @instance
             */
            AMLClientAddressesListResponse.prototype.totalPages = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new AMLClientAddressesListResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientAddressesListResponse
             * @static
             * @param {protocol.IAMLClientAddressesListResponse=} [properties] Properties to set
             * @returns {protocol.AMLClientAddressesListResponse} AMLClientAddressesListResponse instance
             */
            AMLClientAddressesListResponse.create = function create(properties) {
                return new AMLClientAddressesListResponse(properties);
            };
    
            /**
             * Encodes the specified AMLClientAddressesListResponse message. Does not implicitly {@link protocol.AMLClientAddressesListResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientAddressesListResponse
             * @static
             * @param {protocol.IAMLClientAddressesListResponse} message AMLClientAddressesListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientAddressesListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.addresses != null && message.addresses.length)
                    for (var i = 0; i < message.addresses.length; ++i)
                        $root.protocol.AMLClientAddress.encode(message.addresses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalCount);
                if (message.totalPages != null && Object.hasOwnProperty.call(message, "totalPages"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalPages);
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientAddressesListResponse message, length delimited. Does not implicitly {@link protocol.AMLClientAddressesListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientAddressesListResponse
             * @static
             * @param {protocol.IAMLClientAddressesListResponse} message AMLClientAddressesListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientAddressesListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientAddressesListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientAddressesListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientAddressesListResponse} AMLClientAddressesListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientAddressesListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientAddressesListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.addresses && message.addresses.length))
                            message.addresses = [];
                        message.addresses.push($root.protocol.AMLClientAddress.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        message.totalCount = reader.int64();
                        break;
                    case 3:
                        message.totalPages = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientAddressesListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientAddressesListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientAddressesListResponse} AMLClientAddressesListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientAddressesListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientAddressesListResponse message.
             * @function verify
             * @memberof protocol.AMLClientAddressesListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientAddressesListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.addresses != null && message.hasOwnProperty("addresses")) {
                    if (!Array.isArray(message.addresses))
                        return "addresses: array expected";
                    for (var i = 0; i < message.addresses.length; ++i) {
                        var error = $root.protocol.AMLClientAddress.verify(message.addresses[i]);
                        if (error)
                            return "addresses." + error;
                    }
                }
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (!$util.isInteger(message.totalCount) && !(message.totalCount && $util.isInteger(message.totalCount.low) && $util.isInteger(message.totalCount.high)))
                        return "totalCount: integer|Long expected";
                if (message.totalPages != null && message.hasOwnProperty("totalPages"))
                    if (!$util.isInteger(message.totalPages) && !(message.totalPages && $util.isInteger(message.totalPages.low) && $util.isInteger(message.totalPages.high)))
                        return "totalPages: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a AMLClientAddressesListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientAddressesListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientAddressesListResponse} AMLClientAddressesListResponse
             */
            AMLClientAddressesListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientAddressesListResponse)
                    return object;
                var message = new $root.protocol.AMLClientAddressesListResponse();
                if (object.addresses) {
                    if (!Array.isArray(object.addresses))
                        throw TypeError(".protocol.AMLClientAddressesListResponse.addresses: array expected");
                    message.addresses = [];
                    for (var i = 0; i < object.addresses.length; ++i) {
                        if (typeof object.addresses[i] !== "object")
                            throw TypeError(".protocol.AMLClientAddressesListResponse.addresses: object expected");
                        message.addresses[i] = $root.protocol.AMLClientAddress.fromObject(object.addresses[i]);
                    }
                }
                if (object.totalCount != null)
                    if ($util.Long)
                        (message.totalCount = $util.Long.fromValue(object.totalCount)).unsigned = false;
                    else if (typeof object.totalCount === "string")
                        message.totalCount = parseInt(object.totalCount, 10);
                    else if (typeof object.totalCount === "number")
                        message.totalCount = object.totalCount;
                    else if (typeof object.totalCount === "object")
                        message.totalCount = new $util.LongBits(object.totalCount.low >>> 0, object.totalCount.high >>> 0).toNumber();
                if (object.totalPages != null)
                    if ($util.Long)
                        (message.totalPages = $util.Long.fromValue(object.totalPages)).unsigned = false;
                    else if (typeof object.totalPages === "string")
                        message.totalPages = parseInt(object.totalPages, 10);
                    else if (typeof object.totalPages === "number")
                        message.totalPages = object.totalPages;
                    else if (typeof object.totalPages === "object")
                        message.totalPages = new $util.LongBits(object.totalPages.low >>> 0, object.totalPages.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientAddressesListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientAddressesListResponse
             * @static
             * @param {protocol.AMLClientAddressesListResponse} message AMLClientAddressesListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientAddressesListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.addresses = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalCount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalPages = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalPages = options.longs === String ? "0" : 0;
                }
                if (message.addresses && message.addresses.length) {
                    object.addresses = [];
                    for (var j = 0; j < message.addresses.length; ++j)
                        object.addresses[j] = $root.protocol.AMLClientAddress.toObject(message.addresses[j], options);
                }
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (typeof message.totalCount === "number")
                        object.totalCount = options.longs === String ? String(message.totalCount) : message.totalCount;
                    else
                        object.totalCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalCount) : options.longs === Number ? new $util.LongBits(message.totalCount.low >>> 0, message.totalCount.high >>> 0).toNumber() : message.totalCount;
                if (message.totalPages != null && message.hasOwnProperty("totalPages"))
                    if (typeof message.totalPages === "number")
                        object.totalPages = options.longs === String ? String(message.totalPages) : message.totalPages;
                    else
                        object.totalPages = options.longs === String ? $util.Long.prototype.toString.call(message.totalPages) : options.longs === Number ? new $util.LongBits(message.totalPages.low >>> 0, message.totalPages.high >>> 0).toNumber() : message.totalPages;
                return object;
            };
    
            /**
             * Converts this AMLClientAddressesListResponse to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientAddressesListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientAddressesListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientAddressesListResponse;
        })();
    
        protocol.AMLClientBatchCheckStartRequest = (function() {
    
            /**
             * Properties of a AMLClientBatchCheckStartRequest.
             * @memberof protocol
             * @interface IAMLClientBatchCheckStartRequest
             * @property {protocol.IAMLFilters|null} [filters] AMLClientBatchCheckStartRequest filters
             * @property {boolean|null} [estimate] AMLClientBatchCheckStartRequest estimate
             */
    
            /**
             * Constructs a new AMLClientBatchCheckStartRequest.
             * @memberof protocol
             * @classdesc Represents a AMLClientBatchCheckStartRequest.
             * @implements IAMLClientBatchCheckStartRequest
             * @constructor
             * @param {protocol.IAMLClientBatchCheckStartRequest=} [properties] Properties to set
             */
            function AMLClientBatchCheckStartRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientBatchCheckStartRequest filters.
             * @member {protocol.IAMLFilters|null|undefined} filters
             * @memberof protocol.AMLClientBatchCheckStartRequest
             * @instance
             */
            AMLClientBatchCheckStartRequest.prototype.filters = null;
    
            /**
             * AMLClientBatchCheckStartRequest estimate.
             * @member {boolean} estimate
             * @memberof protocol.AMLClientBatchCheckStartRequest
             * @instance
             */
            AMLClientBatchCheckStartRequest.prototype.estimate = false;
    
            /**
             * Creates a new AMLClientBatchCheckStartRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientBatchCheckStartRequest
             * @static
             * @param {protocol.IAMLClientBatchCheckStartRequest=} [properties] Properties to set
             * @returns {protocol.AMLClientBatchCheckStartRequest} AMLClientBatchCheckStartRequest instance
             */
            AMLClientBatchCheckStartRequest.create = function create(properties) {
                return new AMLClientBatchCheckStartRequest(properties);
            };
    
            /**
             * Encodes the specified AMLClientBatchCheckStartRequest message. Does not implicitly {@link protocol.AMLClientBatchCheckStartRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientBatchCheckStartRequest
             * @static
             * @param {protocol.IAMLClientBatchCheckStartRequest} message AMLClientBatchCheckStartRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchCheckStartRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.filters != null && Object.hasOwnProperty.call(message, "filters"))
                    $root.protocol.AMLFilters.encode(message.filters, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.estimate != null && Object.hasOwnProperty.call(message, "estimate"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.estimate);
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientBatchCheckStartRequest message, length delimited. Does not implicitly {@link protocol.AMLClientBatchCheckStartRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientBatchCheckStartRequest
             * @static
             * @param {protocol.IAMLClientBatchCheckStartRequest} message AMLClientBatchCheckStartRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchCheckStartRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientBatchCheckStartRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientBatchCheckStartRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientBatchCheckStartRequest} AMLClientBatchCheckStartRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchCheckStartRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientBatchCheckStartRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.filters = $root.protocol.AMLFilters.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.estimate = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientBatchCheckStartRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientBatchCheckStartRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientBatchCheckStartRequest} AMLClientBatchCheckStartRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchCheckStartRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientBatchCheckStartRequest message.
             * @function verify
             * @memberof protocol.AMLClientBatchCheckStartRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientBatchCheckStartRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.filters != null && message.hasOwnProperty("filters")) {
                    var error = $root.protocol.AMLFilters.verify(message.filters);
                    if (error)
                        return "filters." + error;
                }
                if (message.estimate != null && message.hasOwnProperty("estimate"))
                    if (typeof message.estimate !== "boolean")
                        return "estimate: boolean expected";
                return null;
            };
    
            /**
             * Creates a AMLClientBatchCheckStartRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientBatchCheckStartRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientBatchCheckStartRequest} AMLClientBatchCheckStartRequest
             */
            AMLClientBatchCheckStartRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientBatchCheckStartRequest)
                    return object;
                var message = new $root.protocol.AMLClientBatchCheckStartRequest();
                if (object.filters != null) {
                    if (typeof object.filters !== "object")
                        throw TypeError(".protocol.AMLClientBatchCheckStartRequest.filters: object expected");
                    message.filters = $root.protocol.AMLFilters.fromObject(object.filters);
                }
                if (object.estimate != null)
                    message.estimate = Boolean(object.estimate);
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientBatchCheckStartRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientBatchCheckStartRequest
             * @static
             * @param {protocol.AMLClientBatchCheckStartRequest} message AMLClientBatchCheckStartRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientBatchCheckStartRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.filters = null;
                    object.estimate = false;
                }
                if (message.filters != null && message.hasOwnProperty("filters"))
                    object.filters = $root.protocol.AMLFilters.toObject(message.filters, options);
                if (message.estimate != null && message.hasOwnProperty("estimate"))
                    object.estimate = message.estimate;
                return object;
            };
    
            /**
             * Converts this AMLClientBatchCheckStartRequest to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientBatchCheckStartRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientBatchCheckStartRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientBatchCheckStartRequest;
        })();
    
        protocol.AMLClientBatchCheckStartResponse = (function() {
    
            /**
             * Properties of a AMLClientBatchCheckStartResponse.
             * @memberof protocol
             * @interface IAMLClientBatchCheckStartResponse
             * @property {number|null} [batchCheckId] AMLClientBatchCheckStartResponse batchCheckId
             * @property {Long|null} [totalChecks] AMLClientBatchCheckStartResponse totalChecks
             */
    
            /**
             * Constructs a new AMLClientBatchCheckStartResponse.
             * @memberof protocol
             * @classdesc Represents a AMLClientBatchCheckStartResponse.
             * @implements IAMLClientBatchCheckStartResponse
             * @constructor
             * @param {protocol.IAMLClientBatchCheckStartResponse=} [properties] Properties to set
             */
            function AMLClientBatchCheckStartResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientBatchCheckStartResponse batchCheckId.
             * @member {number} batchCheckId
             * @memberof protocol.AMLClientBatchCheckStartResponse
             * @instance
             */
            AMLClientBatchCheckStartResponse.prototype.batchCheckId = 0;
    
            /**
             * AMLClientBatchCheckStartResponse totalChecks.
             * @member {Long} totalChecks
             * @memberof protocol.AMLClientBatchCheckStartResponse
             * @instance
             */
            AMLClientBatchCheckStartResponse.prototype.totalChecks = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new AMLClientBatchCheckStartResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientBatchCheckStartResponse
             * @static
             * @param {protocol.IAMLClientBatchCheckStartResponse=} [properties] Properties to set
             * @returns {protocol.AMLClientBatchCheckStartResponse} AMLClientBatchCheckStartResponse instance
             */
            AMLClientBatchCheckStartResponse.create = function create(properties) {
                return new AMLClientBatchCheckStartResponse(properties);
            };
    
            /**
             * Encodes the specified AMLClientBatchCheckStartResponse message. Does not implicitly {@link protocol.AMLClientBatchCheckStartResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientBatchCheckStartResponse
             * @static
             * @param {protocol.IAMLClientBatchCheckStartResponse} message AMLClientBatchCheckStartResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchCheckStartResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.batchCheckId != null && Object.hasOwnProperty.call(message, "batchCheckId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.batchCheckId);
                if (message.totalChecks != null && Object.hasOwnProperty.call(message, "totalChecks"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalChecks);
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientBatchCheckStartResponse message, length delimited. Does not implicitly {@link protocol.AMLClientBatchCheckStartResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientBatchCheckStartResponse
             * @static
             * @param {protocol.IAMLClientBatchCheckStartResponse} message AMLClientBatchCheckStartResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchCheckStartResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientBatchCheckStartResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientBatchCheckStartResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientBatchCheckStartResponse} AMLClientBatchCheckStartResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchCheckStartResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientBatchCheckStartResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.batchCheckId = reader.uint32();
                        break;
                    case 2:
                        message.totalChecks = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientBatchCheckStartResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientBatchCheckStartResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientBatchCheckStartResponse} AMLClientBatchCheckStartResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchCheckStartResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientBatchCheckStartResponse message.
             * @function verify
             * @memberof protocol.AMLClientBatchCheckStartResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientBatchCheckStartResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.batchCheckId != null && message.hasOwnProperty("batchCheckId"))
                    if (!$util.isInteger(message.batchCheckId))
                        return "batchCheckId: integer expected";
                if (message.totalChecks != null && message.hasOwnProperty("totalChecks"))
                    if (!$util.isInteger(message.totalChecks) && !(message.totalChecks && $util.isInteger(message.totalChecks.low) && $util.isInteger(message.totalChecks.high)))
                        return "totalChecks: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a AMLClientBatchCheckStartResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientBatchCheckStartResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientBatchCheckStartResponse} AMLClientBatchCheckStartResponse
             */
            AMLClientBatchCheckStartResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientBatchCheckStartResponse)
                    return object;
                var message = new $root.protocol.AMLClientBatchCheckStartResponse();
                if (object.batchCheckId != null)
                    message.batchCheckId = object.batchCheckId >>> 0;
                if (object.totalChecks != null)
                    if ($util.Long)
                        (message.totalChecks = $util.Long.fromValue(object.totalChecks)).unsigned = false;
                    else if (typeof object.totalChecks === "string")
                        message.totalChecks = parseInt(object.totalChecks, 10);
                    else if (typeof object.totalChecks === "number")
                        message.totalChecks = object.totalChecks;
                    else if (typeof object.totalChecks === "object")
                        message.totalChecks = new $util.LongBits(object.totalChecks.low >>> 0, object.totalChecks.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientBatchCheckStartResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientBatchCheckStartResponse
             * @static
             * @param {protocol.AMLClientBatchCheckStartResponse} message AMLClientBatchCheckStartResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientBatchCheckStartResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.batchCheckId = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalChecks = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalChecks = options.longs === String ? "0" : 0;
                }
                if (message.batchCheckId != null && message.hasOwnProperty("batchCheckId"))
                    object.batchCheckId = message.batchCheckId;
                if (message.totalChecks != null && message.hasOwnProperty("totalChecks"))
                    if (typeof message.totalChecks === "number")
                        object.totalChecks = options.longs === String ? String(message.totalChecks) : message.totalChecks;
                    else
                        object.totalChecks = options.longs === String ? $util.Long.prototype.toString.call(message.totalChecks) : options.longs === Number ? new $util.LongBits(message.totalChecks.low >>> 0, message.totalChecks.high >>> 0).toNumber() : message.totalChecks;
                return object;
            };
    
            /**
             * Converts this AMLClientBatchCheckStartResponse to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientBatchCheckStartResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientBatchCheckStartResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientBatchCheckStartResponse;
        })();
    
        protocol.AMLClientBatchCheckStopRequest = (function() {
    
            /**
             * Properties of a AMLClientBatchCheckStopRequest.
             * @memberof protocol
             * @interface IAMLClientBatchCheckStopRequest
             * @property {number|null} [id] AMLClientBatchCheckStopRequest id
             */
    
            /**
             * Constructs a new AMLClientBatchCheckStopRequest.
             * @memberof protocol
             * @classdesc Represents a AMLClientBatchCheckStopRequest.
             * @implements IAMLClientBatchCheckStopRequest
             * @constructor
             * @param {protocol.IAMLClientBatchCheckStopRequest=} [properties] Properties to set
             */
            function AMLClientBatchCheckStopRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientBatchCheckStopRequest id.
             * @member {number} id
             * @memberof protocol.AMLClientBatchCheckStopRequest
             * @instance
             */
            AMLClientBatchCheckStopRequest.prototype.id = 0;
    
            /**
             * Creates a new AMLClientBatchCheckStopRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientBatchCheckStopRequest
             * @static
             * @param {protocol.IAMLClientBatchCheckStopRequest=} [properties] Properties to set
             * @returns {protocol.AMLClientBatchCheckStopRequest} AMLClientBatchCheckStopRequest instance
             */
            AMLClientBatchCheckStopRequest.create = function create(properties) {
                return new AMLClientBatchCheckStopRequest(properties);
            };
    
            /**
             * Encodes the specified AMLClientBatchCheckStopRequest message. Does not implicitly {@link protocol.AMLClientBatchCheckStopRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientBatchCheckStopRequest
             * @static
             * @param {protocol.IAMLClientBatchCheckStopRequest} message AMLClientBatchCheckStopRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchCheckStopRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientBatchCheckStopRequest message, length delimited. Does not implicitly {@link protocol.AMLClientBatchCheckStopRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientBatchCheckStopRequest
             * @static
             * @param {protocol.IAMLClientBatchCheckStopRequest} message AMLClientBatchCheckStopRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchCheckStopRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientBatchCheckStopRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientBatchCheckStopRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientBatchCheckStopRequest} AMLClientBatchCheckStopRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchCheckStopRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientBatchCheckStopRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientBatchCheckStopRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientBatchCheckStopRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientBatchCheckStopRequest} AMLClientBatchCheckStopRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchCheckStopRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientBatchCheckStopRequest message.
             * @function verify
             * @memberof protocol.AMLClientBatchCheckStopRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientBatchCheckStopRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                return null;
            };
    
            /**
             * Creates a AMLClientBatchCheckStopRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientBatchCheckStopRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientBatchCheckStopRequest} AMLClientBatchCheckStopRequest
             */
            AMLClientBatchCheckStopRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientBatchCheckStopRequest)
                    return object;
                var message = new $root.protocol.AMLClientBatchCheckStopRequest();
                if (object.id != null)
                    message.id = object.id >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientBatchCheckStopRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientBatchCheckStopRequest
             * @static
             * @param {protocol.AMLClientBatchCheckStopRequest} message AMLClientBatchCheckStopRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientBatchCheckStopRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.id = 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                return object;
            };
    
            /**
             * Converts this AMLClientBatchCheckStopRequest to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientBatchCheckStopRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientBatchCheckStopRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientBatchCheckStopRequest;
        })();
    
        protocol.AMLClientBatchCheckStopResponse = (function() {
    
            /**
             * Properties of a AMLClientBatchCheckStopResponse.
             * @memberof protocol
             * @interface IAMLClientBatchCheckStopResponse
             */
    
            /**
             * Constructs a new AMLClientBatchCheckStopResponse.
             * @memberof protocol
             * @classdesc Represents a AMLClientBatchCheckStopResponse.
             * @implements IAMLClientBatchCheckStopResponse
             * @constructor
             * @param {protocol.IAMLClientBatchCheckStopResponse=} [properties] Properties to set
             */
            function AMLClientBatchCheckStopResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new AMLClientBatchCheckStopResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientBatchCheckStopResponse
             * @static
             * @param {protocol.IAMLClientBatchCheckStopResponse=} [properties] Properties to set
             * @returns {protocol.AMLClientBatchCheckStopResponse} AMLClientBatchCheckStopResponse instance
             */
            AMLClientBatchCheckStopResponse.create = function create(properties) {
                return new AMLClientBatchCheckStopResponse(properties);
            };
    
            /**
             * Encodes the specified AMLClientBatchCheckStopResponse message. Does not implicitly {@link protocol.AMLClientBatchCheckStopResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientBatchCheckStopResponse
             * @static
             * @param {protocol.IAMLClientBatchCheckStopResponse} message AMLClientBatchCheckStopResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchCheckStopResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientBatchCheckStopResponse message, length delimited. Does not implicitly {@link protocol.AMLClientBatchCheckStopResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientBatchCheckStopResponse
             * @static
             * @param {protocol.IAMLClientBatchCheckStopResponse} message AMLClientBatchCheckStopResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchCheckStopResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientBatchCheckStopResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientBatchCheckStopResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientBatchCheckStopResponse} AMLClientBatchCheckStopResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchCheckStopResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientBatchCheckStopResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientBatchCheckStopResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientBatchCheckStopResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientBatchCheckStopResponse} AMLClientBatchCheckStopResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchCheckStopResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientBatchCheckStopResponse message.
             * @function verify
             * @memberof protocol.AMLClientBatchCheckStopResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientBatchCheckStopResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a AMLClientBatchCheckStopResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientBatchCheckStopResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientBatchCheckStopResponse} AMLClientBatchCheckStopResponse
             */
            AMLClientBatchCheckStopResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientBatchCheckStopResponse)
                    return object;
                return new $root.protocol.AMLClientBatchCheckStopResponse();
            };
    
            /**
             * Creates a plain object from a AMLClientBatchCheckStopResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientBatchCheckStopResponse
             * @static
             * @param {protocol.AMLClientBatchCheckStopResponse} message AMLClientBatchCheckStopResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientBatchCheckStopResponse.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this AMLClientBatchCheckStopResponse to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientBatchCheckStopResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientBatchCheckStopResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientBatchCheckStopResponse;
        })();
    
        protocol.AMLClientBatch = (function() {
    
            /**
             * Properties of a AMLClientBatch.
             * @memberof protocol
             * @interface IAMLClientBatch
             * @property {number|null} [id] AMLClientBatch id
             * @property {protocol.AMLBatchCheckStatus|null} [status] AMLClientBatch status
             * @property {Long|null} [totalChecks] AMLClientBatch totalChecks
             * @property {Long|null} [activeChecks] AMLClientBatch activeChecks
             * @property {Long|null} [pendingChecks] AMLClientBatch pendingChecks
             * @property {Long|null} [completedChecks] AMLClientBatch completedChecks
             * @property {Long|null} [canceledChecks] AMLClientBatch canceledChecks
             * @property {Long|null} [failedChecks] AMLClientBatch failedChecks
             * @property {Long|null} [createdAt] AMLClientBatch createdAt
             * @property {Long|null} [lastCheckAt] AMLClientBatch lastCheckAt
             * @property {Object.<string,protocol.IAMLGroupSource>|null} [sources] AMLClientBatch sources
             */
    
            /**
             * Constructs a new AMLClientBatch.
             * @memberof protocol
             * @classdesc Represents a AMLClientBatch.
             * @implements IAMLClientBatch
             * @constructor
             * @param {protocol.IAMLClientBatch=} [properties] Properties to set
             */
            function AMLClientBatch(properties) {
                this.sources = {};
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientBatch id.
             * @member {number} id
             * @memberof protocol.AMLClientBatch
             * @instance
             */
            AMLClientBatch.prototype.id = 0;
    
            /**
             * AMLClientBatch status.
             * @member {protocol.AMLBatchCheckStatus} status
             * @memberof protocol.AMLClientBatch
             * @instance
             */
            AMLClientBatch.prototype.status = 0;
    
            /**
             * AMLClientBatch totalChecks.
             * @member {Long} totalChecks
             * @memberof protocol.AMLClientBatch
             * @instance
             */
            AMLClientBatch.prototype.totalChecks = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientBatch activeChecks.
             * @member {Long} activeChecks
             * @memberof protocol.AMLClientBatch
             * @instance
             */
            AMLClientBatch.prototype.activeChecks = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientBatch pendingChecks.
             * @member {Long} pendingChecks
             * @memberof protocol.AMLClientBatch
             * @instance
             */
            AMLClientBatch.prototype.pendingChecks = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientBatch completedChecks.
             * @member {Long} completedChecks
             * @memberof protocol.AMLClientBatch
             * @instance
             */
            AMLClientBatch.prototype.completedChecks = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientBatch canceledChecks.
             * @member {Long} canceledChecks
             * @memberof protocol.AMLClientBatch
             * @instance
             */
            AMLClientBatch.prototype.canceledChecks = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientBatch failedChecks.
             * @member {Long} failedChecks
             * @memberof protocol.AMLClientBatch
             * @instance
             */
            AMLClientBatch.prototype.failedChecks = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientBatch createdAt.
             * @member {Long} createdAt
             * @memberof protocol.AMLClientBatch
             * @instance
             */
            AMLClientBatch.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientBatch lastCheckAt.
             * @member {Long} lastCheckAt
             * @memberof protocol.AMLClientBatch
             * @instance
             */
            AMLClientBatch.prototype.lastCheckAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientBatch sources.
             * @member {Object.<string,protocol.IAMLGroupSource>} sources
             * @memberof protocol.AMLClientBatch
             * @instance
             */
            AMLClientBatch.prototype.sources = $util.emptyObject;
    
            /**
             * Creates a new AMLClientBatch instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientBatch
             * @static
             * @param {protocol.IAMLClientBatch=} [properties] Properties to set
             * @returns {protocol.AMLClientBatch} AMLClientBatch instance
             */
            AMLClientBatch.create = function create(properties) {
                return new AMLClientBatch(properties);
            };
    
            /**
             * Encodes the specified AMLClientBatch message. Does not implicitly {@link protocol.AMLClientBatch.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientBatch
             * @static
             * @param {protocol.IAMLClientBatch} message AMLClientBatch message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatch.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
                if (message.totalChecks != null && Object.hasOwnProperty.call(message, "totalChecks"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalChecks);
                if (message.activeChecks != null && Object.hasOwnProperty.call(message, "activeChecks"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.activeChecks);
                if (message.pendingChecks != null && Object.hasOwnProperty.call(message, "pendingChecks"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.pendingChecks);
                if (message.completedChecks != null && Object.hasOwnProperty.call(message, "completedChecks"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.completedChecks);
                if (message.canceledChecks != null && Object.hasOwnProperty.call(message, "canceledChecks"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.canceledChecks);
                if (message.failedChecks != null && Object.hasOwnProperty.call(message, "failedChecks"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int64(message.failedChecks);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int64(message.createdAt);
                if (message.lastCheckAt != null && Object.hasOwnProperty.call(message, "lastCheckAt"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int64(message.lastCheckAt);
                if (message.sources != null && Object.hasOwnProperty.call(message, "sources"))
                    for (var keys = Object.keys(message.sources), i = 0; i < keys.length; ++i) {
                        writer.uint32(/* id 11, wireType 2 =*/90).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                        $root.protocol.AMLGroupSource.encode(message.sources[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                    }
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientBatch message, length delimited. Does not implicitly {@link protocol.AMLClientBatch.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientBatch
             * @static
             * @param {protocol.IAMLClientBatch} message AMLClientBatch message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatch.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientBatch message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientBatch
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientBatch} AMLClientBatch
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatch.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientBatch(), key;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint32();
                        break;
                    case 2:
                        message.status = reader.int32();
                        break;
                    case 3:
                        message.totalChecks = reader.int64();
                        break;
                    case 4:
                        message.activeChecks = reader.int64();
                        break;
                    case 5:
                        message.pendingChecks = reader.int64();
                        break;
                    case 6:
                        message.completedChecks = reader.int64();
                        break;
                    case 7:
                        message.canceledChecks = reader.int64();
                        break;
                    case 8:
                        message.failedChecks = reader.int64();
                        break;
                    case 9:
                        message.createdAt = reader.int64();
                        break;
                    case 10:
                        message.lastCheckAt = reader.int64();
                        break;
                    case 11:
                        reader.skip().pos++;
                        if (message.sources === $util.emptyObject)
                            message.sources = {};
                        key = reader.string();
                        reader.pos++;
                        message.sources[key] = $root.protocol.AMLGroupSource.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientBatch message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientBatch
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientBatch} AMLClientBatch
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatch.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientBatch message.
             * @function verify
             * @memberof protocol.AMLClientBatch
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientBatch.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.totalChecks != null && message.hasOwnProperty("totalChecks"))
                    if (!$util.isInteger(message.totalChecks) && !(message.totalChecks && $util.isInteger(message.totalChecks.low) && $util.isInteger(message.totalChecks.high)))
                        return "totalChecks: integer|Long expected";
                if (message.activeChecks != null && message.hasOwnProperty("activeChecks"))
                    if (!$util.isInteger(message.activeChecks) && !(message.activeChecks && $util.isInteger(message.activeChecks.low) && $util.isInteger(message.activeChecks.high)))
                        return "activeChecks: integer|Long expected";
                if (message.pendingChecks != null && message.hasOwnProperty("pendingChecks"))
                    if (!$util.isInteger(message.pendingChecks) && !(message.pendingChecks && $util.isInteger(message.pendingChecks.low) && $util.isInteger(message.pendingChecks.high)))
                        return "pendingChecks: integer|Long expected";
                if (message.completedChecks != null && message.hasOwnProperty("completedChecks"))
                    if (!$util.isInteger(message.completedChecks) && !(message.completedChecks && $util.isInteger(message.completedChecks.low) && $util.isInteger(message.completedChecks.high)))
                        return "completedChecks: integer|Long expected";
                if (message.canceledChecks != null && message.hasOwnProperty("canceledChecks"))
                    if (!$util.isInteger(message.canceledChecks) && !(message.canceledChecks && $util.isInteger(message.canceledChecks.low) && $util.isInteger(message.canceledChecks.high)))
                        return "canceledChecks: integer|Long expected";
                if (message.failedChecks != null && message.hasOwnProperty("failedChecks"))
                    if (!$util.isInteger(message.failedChecks) && !(message.failedChecks && $util.isInteger(message.failedChecks.low) && $util.isInteger(message.failedChecks.high)))
                        return "failedChecks: integer|Long expected";
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                        return "createdAt: integer|Long expected";
                if (message.lastCheckAt != null && message.hasOwnProperty("lastCheckAt"))
                    if (!$util.isInteger(message.lastCheckAt) && !(message.lastCheckAt && $util.isInteger(message.lastCheckAt.low) && $util.isInteger(message.lastCheckAt.high)))
                        return "lastCheckAt: integer|Long expected";
                if (message.sources != null && message.hasOwnProperty("sources")) {
                    if (!$util.isObject(message.sources))
                        return "sources: object expected";
                    var key = Object.keys(message.sources);
                    for (var i = 0; i < key.length; ++i) {
                        var error = $root.protocol.AMLGroupSource.verify(message.sources[key[i]]);
                        if (error)
                            return "sources." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a AMLClientBatch message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientBatch
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientBatch} AMLClientBatch
             */
            AMLClientBatch.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientBatch)
                    return object;
                var message = new $root.protocol.AMLClientBatch();
                if (object.id != null)
                    message.id = object.id >>> 0;
                switch (object.status) {
                case "UNK_AML_BATCH_CHECK_STATUS":
                case 0:
                    message.status = 0;
                    break;
                case "ACTIVE_AML_BATCH_CHECK_STATUS":
                case 1:
                    message.status = 1;
                    break;
                case "COMPLETED_AML_BATCH_CHECK_STATUS":
                case 2:
                    message.status = 2;
                    break;
                case "CANCELED_AML_BATCH_CHECK_STATUS":
                case 3:
                    message.status = 3;
                    break;
                }
                if (object.totalChecks != null)
                    if ($util.Long)
                        (message.totalChecks = $util.Long.fromValue(object.totalChecks)).unsigned = false;
                    else if (typeof object.totalChecks === "string")
                        message.totalChecks = parseInt(object.totalChecks, 10);
                    else if (typeof object.totalChecks === "number")
                        message.totalChecks = object.totalChecks;
                    else if (typeof object.totalChecks === "object")
                        message.totalChecks = new $util.LongBits(object.totalChecks.low >>> 0, object.totalChecks.high >>> 0).toNumber();
                if (object.activeChecks != null)
                    if ($util.Long)
                        (message.activeChecks = $util.Long.fromValue(object.activeChecks)).unsigned = false;
                    else if (typeof object.activeChecks === "string")
                        message.activeChecks = parseInt(object.activeChecks, 10);
                    else if (typeof object.activeChecks === "number")
                        message.activeChecks = object.activeChecks;
                    else if (typeof object.activeChecks === "object")
                        message.activeChecks = new $util.LongBits(object.activeChecks.low >>> 0, object.activeChecks.high >>> 0).toNumber();
                if (object.pendingChecks != null)
                    if ($util.Long)
                        (message.pendingChecks = $util.Long.fromValue(object.pendingChecks)).unsigned = false;
                    else if (typeof object.pendingChecks === "string")
                        message.pendingChecks = parseInt(object.pendingChecks, 10);
                    else if (typeof object.pendingChecks === "number")
                        message.pendingChecks = object.pendingChecks;
                    else if (typeof object.pendingChecks === "object")
                        message.pendingChecks = new $util.LongBits(object.pendingChecks.low >>> 0, object.pendingChecks.high >>> 0).toNumber();
                if (object.completedChecks != null)
                    if ($util.Long)
                        (message.completedChecks = $util.Long.fromValue(object.completedChecks)).unsigned = false;
                    else if (typeof object.completedChecks === "string")
                        message.completedChecks = parseInt(object.completedChecks, 10);
                    else if (typeof object.completedChecks === "number")
                        message.completedChecks = object.completedChecks;
                    else if (typeof object.completedChecks === "object")
                        message.completedChecks = new $util.LongBits(object.completedChecks.low >>> 0, object.completedChecks.high >>> 0).toNumber();
                if (object.canceledChecks != null)
                    if ($util.Long)
                        (message.canceledChecks = $util.Long.fromValue(object.canceledChecks)).unsigned = false;
                    else if (typeof object.canceledChecks === "string")
                        message.canceledChecks = parseInt(object.canceledChecks, 10);
                    else if (typeof object.canceledChecks === "number")
                        message.canceledChecks = object.canceledChecks;
                    else if (typeof object.canceledChecks === "object")
                        message.canceledChecks = new $util.LongBits(object.canceledChecks.low >>> 0, object.canceledChecks.high >>> 0).toNumber();
                if (object.failedChecks != null)
                    if ($util.Long)
                        (message.failedChecks = $util.Long.fromValue(object.failedChecks)).unsigned = false;
                    else if (typeof object.failedChecks === "string")
                        message.failedChecks = parseInt(object.failedChecks, 10);
                    else if (typeof object.failedChecks === "number")
                        message.failedChecks = object.failedChecks;
                    else if (typeof object.failedChecks === "object")
                        message.failedChecks = new $util.LongBits(object.failedChecks.low >>> 0, object.failedChecks.high >>> 0).toNumber();
                if (object.createdAt != null)
                    if ($util.Long)
                        (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                    else if (typeof object.createdAt === "string")
                        message.createdAt = parseInt(object.createdAt, 10);
                    else if (typeof object.createdAt === "number")
                        message.createdAt = object.createdAt;
                    else if (typeof object.createdAt === "object")
                        message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                if (object.lastCheckAt != null)
                    if ($util.Long)
                        (message.lastCheckAt = $util.Long.fromValue(object.lastCheckAt)).unsigned = false;
                    else if (typeof object.lastCheckAt === "string")
                        message.lastCheckAt = parseInt(object.lastCheckAt, 10);
                    else if (typeof object.lastCheckAt === "number")
                        message.lastCheckAt = object.lastCheckAt;
                    else if (typeof object.lastCheckAt === "object")
                        message.lastCheckAt = new $util.LongBits(object.lastCheckAt.low >>> 0, object.lastCheckAt.high >>> 0).toNumber();
                if (object.sources) {
                    if (typeof object.sources !== "object")
                        throw TypeError(".protocol.AMLClientBatch.sources: object expected");
                    message.sources = {};
                    for (var keys = Object.keys(object.sources), i = 0; i < keys.length; ++i) {
                        if (typeof object.sources[keys[i]] !== "object")
                            throw TypeError(".protocol.AMLClientBatch.sources: object expected");
                        message.sources[keys[i]] = $root.protocol.AMLGroupSource.fromObject(object.sources[keys[i]]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientBatch message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientBatch
             * @static
             * @param {protocol.AMLClientBatch} message AMLClientBatch
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientBatch.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.objects || options.defaults)
                    object.sources = {};
                if (options.defaults) {
                    object.id = 0;
                    object.status = options.enums === String ? "UNK_AML_BATCH_CHECK_STATUS" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalChecks = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalChecks = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.activeChecks = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.activeChecks = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.pendingChecks = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.pendingChecks = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.completedChecks = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.completedChecks = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.canceledChecks = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.canceledChecks = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.failedChecks = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.failedChecks = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAt = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.lastCheckAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lastCheckAt = options.longs === String ? "0" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.protocol.AMLBatchCheckStatus[message.status] : message.status;
                if (message.totalChecks != null && message.hasOwnProperty("totalChecks"))
                    if (typeof message.totalChecks === "number")
                        object.totalChecks = options.longs === String ? String(message.totalChecks) : message.totalChecks;
                    else
                        object.totalChecks = options.longs === String ? $util.Long.prototype.toString.call(message.totalChecks) : options.longs === Number ? new $util.LongBits(message.totalChecks.low >>> 0, message.totalChecks.high >>> 0).toNumber() : message.totalChecks;
                if (message.activeChecks != null && message.hasOwnProperty("activeChecks"))
                    if (typeof message.activeChecks === "number")
                        object.activeChecks = options.longs === String ? String(message.activeChecks) : message.activeChecks;
                    else
                        object.activeChecks = options.longs === String ? $util.Long.prototype.toString.call(message.activeChecks) : options.longs === Number ? new $util.LongBits(message.activeChecks.low >>> 0, message.activeChecks.high >>> 0).toNumber() : message.activeChecks;
                if (message.pendingChecks != null && message.hasOwnProperty("pendingChecks"))
                    if (typeof message.pendingChecks === "number")
                        object.pendingChecks = options.longs === String ? String(message.pendingChecks) : message.pendingChecks;
                    else
                        object.pendingChecks = options.longs === String ? $util.Long.prototype.toString.call(message.pendingChecks) : options.longs === Number ? new $util.LongBits(message.pendingChecks.low >>> 0, message.pendingChecks.high >>> 0).toNumber() : message.pendingChecks;
                if (message.completedChecks != null && message.hasOwnProperty("completedChecks"))
                    if (typeof message.completedChecks === "number")
                        object.completedChecks = options.longs === String ? String(message.completedChecks) : message.completedChecks;
                    else
                        object.completedChecks = options.longs === String ? $util.Long.prototype.toString.call(message.completedChecks) : options.longs === Number ? new $util.LongBits(message.completedChecks.low >>> 0, message.completedChecks.high >>> 0).toNumber() : message.completedChecks;
                if (message.canceledChecks != null && message.hasOwnProperty("canceledChecks"))
                    if (typeof message.canceledChecks === "number")
                        object.canceledChecks = options.longs === String ? String(message.canceledChecks) : message.canceledChecks;
                    else
                        object.canceledChecks = options.longs === String ? $util.Long.prototype.toString.call(message.canceledChecks) : options.longs === Number ? new $util.LongBits(message.canceledChecks.low >>> 0, message.canceledChecks.high >>> 0).toNumber() : message.canceledChecks;
                if (message.failedChecks != null && message.hasOwnProperty("failedChecks"))
                    if (typeof message.failedChecks === "number")
                        object.failedChecks = options.longs === String ? String(message.failedChecks) : message.failedChecks;
                    else
                        object.failedChecks = options.longs === String ? $util.Long.prototype.toString.call(message.failedChecks) : options.longs === Number ? new $util.LongBits(message.failedChecks.low >>> 0, message.failedChecks.high >>> 0).toNumber() : message.failedChecks;
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (typeof message.createdAt === "number")
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                    else
                        object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                if (message.lastCheckAt != null && message.hasOwnProperty("lastCheckAt"))
                    if (typeof message.lastCheckAt === "number")
                        object.lastCheckAt = options.longs === String ? String(message.lastCheckAt) : message.lastCheckAt;
                    else
                        object.lastCheckAt = options.longs === String ? $util.Long.prototype.toString.call(message.lastCheckAt) : options.longs === Number ? new $util.LongBits(message.lastCheckAt.low >>> 0, message.lastCheckAt.high >>> 0).toNumber() : message.lastCheckAt;
                var keys2;
                if (message.sources && (keys2 = Object.keys(message.sources)).length) {
                    object.sources = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.sources[keys2[j]] = $root.protocol.AMLGroupSource.toObject(message.sources[keys2[j]], options);
                }
                return object;
            };
    
            /**
             * Converts this AMLClientBatch to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientBatch
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientBatch.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientBatch;
        })();
    
        protocol.AMLClientBatchChecksListRequest = (function() {
    
            /**
             * Properties of a AMLClientBatchChecksListRequest.
             * @memberof protocol
             * @interface IAMLClientBatchChecksListRequest
             */
    
            /**
             * Constructs a new AMLClientBatchChecksListRequest.
             * @memberof protocol
             * @classdesc Represents a AMLClientBatchChecksListRequest.
             * @implements IAMLClientBatchChecksListRequest
             * @constructor
             * @param {protocol.IAMLClientBatchChecksListRequest=} [properties] Properties to set
             */
            function AMLClientBatchChecksListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new AMLClientBatchChecksListRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientBatchChecksListRequest
             * @static
             * @param {protocol.IAMLClientBatchChecksListRequest=} [properties] Properties to set
             * @returns {protocol.AMLClientBatchChecksListRequest} AMLClientBatchChecksListRequest instance
             */
            AMLClientBatchChecksListRequest.create = function create(properties) {
                return new AMLClientBatchChecksListRequest(properties);
            };
    
            /**
             * Encodes the specified AMLClientBatchChecksListRequest message. Does not implicitly {@link protocol.AMLClientBatchChecksListRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientBatchChecksListRequest
             * @static
             * @param {protocol.IAMLClientBatchChecksListRequest} message AMLClientBatchChecksListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchChecksListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientBatchChecksListRequest message, length delimited. Does not implicitly {@link protocol.AMLClientBatchChecksListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientBatchChecksListRequest
             * @static
             * @param {protocol.IAMLClientBatchChecksListRequest} message AMLClientBatchChecksListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchChecksListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientBatchChecksListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientBatchChecksListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientBatchChecksListRequest} AMLClientBatchChecksListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchChecksListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientBatchChecksListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientBatchChecksListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientBatchChecksListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientBatchChecksListRequest} AMLClientBatchChecksListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchChecksListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientBatchChecksListRequest message.
             * @function verify
             * @memberof protocol.AMLClientBatchChecksListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientBatchChecksListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a AMLClientBatchChecksListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientBatchChecksListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientBatchChecksListRequest} AMLClientBatchChecksListRequest
             */
            AMLClientBatchChecksListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientBatchChecksListRequest)
                    return object;
                return new $root.protocol.AMLClientBatchChecksListRequest();
            };
    
            /**
             * Creates a plain object from a AMLClientBatchChecksListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientBatchChecksListRequest
             * @static
             * @param {protocol.AMLClientBatchChecksListRequest} message AMLClientBatchChecksListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientBatchChecksListRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this AMLClientBatchChecksListRequest to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientBatchChecksListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientBatchChecksListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientBatchChecksListRequest;
        })();
    
        protocol.AMLClientBatchChecksListResponse = (function() {
    
            /**
             * Properties of a AMLClientBatchChecksListResponse.
             * @memberof protocol
             * @interface IAMLClientBatchChecksListResponse
             * @property {Array.<protocol.IAMLClientBatch>|null} [batches] AMLClientBatchChecksListResponse batches
             */
    
            /**
             * Constructs a new AMLClientBatchChecksListResponse.
             * @memberof protocol
             * @classdesc Represents a AMLClientBatchChecksListResponse.
             * @implements IAMLClientBatchChecksListResponse
             * @constructor
             * @param {protocol.IAMLClientBatchChecksListResponse=} [properties] Properties to set
             */
            function AMLClientBatchChecksListResponse(properties) {
                this.batches = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientBatchChecksListResponse batches.
             * @member {Array.<protocol.IAMLClientBatch>} batches
             * @memberof protocol.AMLClientBatchChecksListResponse
             * @instance
             */
            AMLClientBatchChecksListResponse.prototype.batches = $util.emptyArray;
    
            /**
             * Creates a new AMLClientBatchChecksListResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientBatchChecksListResponse
             * @static
             * @param {protocol.IAMLClientBatchChecksListResponse=} [properties] Properties to set
             * @returns {protocol.AMLClientBatchChecksListResponse} AMLClientBatchChecksListResponse instance
             */
            AMLClientBatchChecksListResponse.create = function create(properties) {
                return new AMLClientBatchChecksListResponse(properties);
            };
    
            /**
             * Encodes the specified AMLClientBatchChecksListResponse message. Does not implicitly {@link protocol.AMLClientBatchChecksListResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientBatchChecksListResponse
             * @static
             * @param {protocol.IAMLClientBatchChecksListResponse} message AMLClientBatchChecksListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchChecksListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.batches != null && message.batches.length)
                    for (var i = 0; i < message.batches.length; ++i)
                        $root.protocol.AMLClientBatch.encode(message.batches[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientBatchChecksListResponse message, length delimited. Does not implicitly {@link protocol.AMLClientBatchChecksListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientBatchChecksListResponse
             * @static
             * @param {protocol.IAMLClientBatchChecksListResponse} message AMLClientBatchChecksListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchChecksListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientBatchChecksListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientBatchChecksListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientBatchChecksListResponse} AMLClientBatchChecksListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchChecksListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientBatchChecksListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.batches && message.batches.length))
                            message.batches = [];
                        message.batches.push($root.protocol.AMLClientBatch.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientBatchChecksListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientBatchChecksListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientBatchChecksListResponse} AMLClientBatchChecksListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchChecksListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientBatchChecksListResponse message.
             * @function verify
             * @memberof protocol.AMLClientBatchChecksListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientBatchChecksListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.batches != null && message.hasOwnProperty("batches")) {
                    if (!Array.isArray(message.batches))
                        return "batches: array expected";
                    for (var i = 0; i < message.batches.length; ++i) {
                        var error = $root.protocol.AMLClientBatch.verify(message.batches[i]);
                        if (error)
                            return "batches." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a AMLClientBatchChecksListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientBatchChecksListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientBatchChecksListResponse} AMLClientBatchChecksListResponse
             */
            AMLClientBatchChecksListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientBatchChecksListResponse)
                    return object;
                var message = new $root.protocol.AMLClientBatchChecksListResponse();
                if (object.batches) {
                    if (!Array.isArray(object.batches))
                        throw TypeError(".protocol.AMLClientBatchChecksListResponse.batches: array expected");
                    message.batches = [];
                    for (var i = 0; i < object.batches.length; ++i) {
                        if (typeof object.batches[i] !== "object")
                            throw TypeError(".protocol.AMLClientBatchChecksListResponse.batches: object expected");
                        message.batches[i] = $root.protocol.AMLClientBatch.fromObject(object.batches[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientBatchChecksListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientBatchChecksListResponse
             * @static
             * @param {protocol.AMLClientBatchChecksListResponse} message AMLClientBatchChecksListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientBatchChecksListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.batches = [];
                if (message.batches && message.batches.length) {
                    object.batches = [];
                    for (var j = 0; j < message.batches.length; ++j)
                        object.batches[j] = $root.protocol.AMLClientBatch.toObject(message.batches[j], options);
                }
                return object;
            };
    
            /**
             * Converts this AMLClientBatchChecksListResponse to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientBatchChecksListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientBatchChecksListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientBatchChecksListResponse;
        })();
    
        protocol.AMLClientBatchStatsRequest = (function() {
    
            /**
             * Properties of a AMLClientBatchStatsRequest.
             * @memberof protocol
             * @interface IAMLClientBatchStatsRequest
             * @property {number|null} [batchId] AMLClientBatchStatsRequest batchId
             */
    
            /**
             * Constructs a new AMLClientBatchStatsRequest.
             * @memberof protocol
             * @classdesc Represents a AMLClientBatchStatsRequest.
             * @implements IAMLClientBatchStatsRequest
             * @constructor
             * @param {protocol.IAMLClientBatchStatsRequest=} [properties] Properties to set
             */
            function AMLClientBatchStatsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientBatchStatsRequest batchId.
             * @member {number} batchId
             * @memberof protocol.AMLClientBatchStatsRequest
             * @instance
             */
            AMLClientBatchStatsRequest.prototype.batchId = 0;
    
            /**
             * Creates a new AMLClientBatchStatsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientBatchStatsRequest
             * @static
             * @param {protocol.IAMLClientBatchStatsRequest=} [properties] Properties to set
             * @returns {protocol.AMLClientBatchStatsRequest} AMLClientBatchStatsRequest instance
             */
            AMLClientBatchStatsRequest.create = function create(properties) {
                return new AMLClientBatchStatsRequest(properties);
            };
    
            /**
             * Encodes the specified AMLClientBatchStatsRequest message. Does not implicitly {@link protocol.AMLClientBatchStatsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientBatchStatsRequest
             * @static
             * @param {protocol.IAMLClientBatchStatsRequest} message AMLClientBatchStatsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchStatsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.batchId != null && Object.hasOwnProperty.call(message, "batchId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.batchId);
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientBatchStatsRequest message, length delimited. Does not implicitly {@link protocol.AMLClientBatchStatsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientBatchStatsRequest
             * @static
             * @param {protocol.IAMLClientBatchStatsRequest} message AMLClientBatchStatsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchStatsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientBatchStatsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientBatchStatsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientBatchStatsRequest} AMLClientBatchStatsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchStatsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientBatchStatsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.batchId = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientBatchStatsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientBatchStatsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientBatchStatsRequest} AMLClientBatchStatsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchStatsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientBatchStatsRequest message.
             * @function verify
             * @memberof protocol.AMLClientBatchStatsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientBatchStatsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.batchId != null && message.hasOwnProperty("batchId"))
                    if (!$util.isInteger(message.batchId))
                        return "batchId: integer expected";
                return null;
            };
    
            /**
             * Creates a AMLClientBatchStatsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientBatchStatsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientBatchStatsRequest} AMLClientBatchStatsRequest
             */
            AMLClientBatchStatsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientBatchStatsRequest)
                    return object;
                var message = new $root.protocol.AMLClientBatchStatsRequest();
                if (object.batchId != null)
                    message.batchId = object.batchId >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientBatchStatsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientBatchStatsRequest
             * @static
             * @param {protocol.AMLClientBatchStatsRequest} message AMLClientBatchStatsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientBatchStatsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.batchId = 0;
                if (message.batchId != null && message.hasOwnProperty("batchId"))
                    object.batchId = message.batchId;
                return object;
            };
    
            /**
             * Converts this AMLClientBatchStatsRequest to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientBatchStatsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientBatchStatsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientBatchStatsRequest;
        })();
    
        protocol.AMLClientBatchStatsResponse = (function() {
    
            /**
             * Properties of a AMLClientBatchStatsResponse.
             * @memberof protocol
             * @interface IAMLClientBatchStatsResponse
             * @property {protocol.IAMLClientBatch|null} [stats] AMLClientBatchStatsResponse stats
             */
    
            /**
             * Constructs a new AMLClientBatchStatsResponse.
             * @memberof protocol
             * @classdesc Represents a AMLClientBatchStatsResponse.
             * @implements IAMLClientBatchStatsResponse
             * @constructor
             * @param {protocol.IAMLClientBatchStatsResponse=} [properties] Properties to set
             */
            function AMLClientBatchStatsResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientBatchStatsResponse stats.
             * @member {protocol.IAMLClientBatch|null|undefined} stats
             * @memberof protocol.AMLClientBatchStatsResponse
             * @instance
             */
            AMLClientBatchStatsResponse.prototype.stats = null;
    
            /**
             * Creates a new AMLClientBatchStatsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientBatchStatsResponse
             * @static
             * @param {protocol.IAMLClientBatchStatsResponse=} [properties] Properties to set
             * @returns {protocol.AMLClientBatchStatsResponse} AMLClientBatchStatsResponse instance
             */
            AMLClientBatchStatsResponse.create = function create(properties) {
                return new AMLClientBatchStatsResponse(properties);
            };
    
            /**
             * Encodes the specified AMLClientBatchStatsResponse message. Does not implicitly {@link protocol.AMLClientBatchStatsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientBatchStatsResponse
             * @static
             * @param {protocol.IAMLClientBatchStatsResponse} message AMLClientBatchStatsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchStatsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.stats != null && Object.hasOwnProperty.call(message, "stats"))
                    $root.protocol.AMLClientBatch.encode(message.stats, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientBatchStatsResponse message, length delimited. Does not implicitly {@link protocol.AMLClientBatchStatsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientBatchStatsResponse
             * @static
             * @param {protocol.IAMLClientBatchStatsResponse} message AMLClientBatchStatsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchStatsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientBatchStatsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientBatchStatsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientBatchStatsResponse} AMLClientBatchStatsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchStatsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientBatchStatsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.stats = $root.protocol.AMLClientBatch.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientBatchStatsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientBatchStatsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientBatchStatsResponse} AMLClientBatchStatsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchStatsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientBatchStatsResponse message.
             * @function verify
             * @memberof protocol.AMLClientBatchStatsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientBatchStatsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.stats != null && message.hasOwnProperty("stats")) {
                    var error = $root.protocol.AMLClientBatch.verify(message.stats);
                    if (error)
                        return "stats." + error;
                }
                return null;
            };
    
            /**
             * Creates a AMLClientBatchStatsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientBatchStatsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientBatchStatsResponse} AMLClientBatchStatsResponse
             */
            AMLClientBatchStatsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientBatchStatsResponse)
                    return object;
                var message = new $root.protocol.AMLClientBatchStatsResponse();
                if (object.stats != null) {
                    if (typeof object.stats !== "object")
                        throw TypeError(".protocol.AMLClientBatchStatsResponse.stats: object expected");
                    message.stats = $root.protocol.AMLClientBatch.fromObject(object.stats);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientBatchStatsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientBatchStatsResponse
             * @static
             * @param {protocol.AMLClientBatchStatsResponse} message AMLClientBatchStatsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientBatchStatsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.stats = null;
                if (message.stats != null && message.hasOwnProperty("stats"))
                    object.stats = $root.protocol.AMLClientBatch.toObject(message.stats, options);
                return object;
            };
    
            /**
             * Converts this AMLClientBatchStatsResponse to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientBatchStatsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientBatchStatsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientBatchStatsResponse;
        })();
    
        protocol.AMLClientBatchFilters = (function() {
    
            /**
             * Properties of a AMLClientBatchFilters.
             * @memberof protocol
             * @interface IAMLClientBatchFilters
             * @property {Array.<string>|null} [filterSources] AMLClientBatchFilters filterSources
             * @property {number|null} [fromRiskscore] AMLClientBatchFilters fromRiskscore
             * @property {number|null} [toRiskscore] AMLClientBatchFilters toRiskscore
             */
    
            /**
             * Constructs a new AMLClientBatchFilters.
             * @memberof protocol
             * @classdesc Represents a AMLClientBatchFilters.
             * @implements IAMLClientBatchFilters
             * @constructor
             * @param {protocol.IAMLClientBatchFilters=} [properties] Properties to set
             */
            function AMLClientBatchFilters(properties) {
                this.filterSources = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientBatchFilters filterSources.
             * @member {Array.<string>} filterSources
             * @memberof protocol.AMLClientBatchFilters
             * @instance
             */
            AMLClientBatchFilters.prototype.filterSources = $util.emptyArray;
    
            /**
             * AMLClientBatchFilters fromRiskscore.
             * @member {number} fromRiskscore
             * @memberof protocol.AMLClientBatchFilters
             * @instance
             */
            AMLClientBatchFilters.prototype.fromRiskscore = 0;
    
            /**
             * AMLClientBatchFilters toRiskscore.
             * @member {number} toRiskscore
             * @memberof protocol.AMLClientBatchFilters
             * @instance
             */
            AMLClientBatchFilters.prototype.toRiskscore = 0;
    
            /**
             * Creates a new AMLClientBatchFilters instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientBatchFilters
             * @static
             * @param {protocol.IAMLClientBatchFilters=} [properties] Properties to set
             * @returns {protocol.AMLClientBatchFilters} AMLClientBatchFilters instance
             */
            AMLClientBatchFilters.create = function create(properties) {
                return new AMLClientBatchFilters(properties);
            };
    
            /**
             * Encodes the specified AMLClientBatchFilters message. Does not implicitly {@link protocol.AMLClientBatchFilters.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientBatchFilters
             * @static
             * @param {protocol.IAMLClientBatchFilters} message AMLClientBatchFilters message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchFilters.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.filterSources != null && message.filterSources.length)
                    for (var i = 0; i < message.filterSources.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.filterSources[i]);
                if (message.fromRiskscore != null && Object.hasOwnProperty.call(message, "fromRiskscore"))
                    writer.uint32(/* id 2, wireType 5 =*/21).float(message.fromRiskscore);
                if (message.toRiskscore != null && Object.hasOwnProperty.call(message, "toRiskscore"))
                    writer.uint32(/* id 3, wireType 5 =*/29).float(message.toRiskscore);
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientBatchFilters message, length delimited. Does not implicitly {@link protocol.AMLClientBatchFilters.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientBatchFilters
             * @static
             * @param {protocol.IAMLClientBatchFilters} message AMLClientBatchFilters message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchFilters.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientBatchFilters message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientBatchFilters
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientBatchFilters} AMLClientBatchFilters
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchFilters.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientBatchFilters();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.filterSources && message.filterSources.length))
                            message.filterSources = [];
                        message.filterSources.push(reader.string());
                        break;
                    case 2:
                        message.fromRiskscore = reader.float();
                        break;
                    case 3:
                        message.toRiskscore = reader.float();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientBatchFilters message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientBatchFilters
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientBatchFilters} AMLClientBatchFilters
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchFilters.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientBatchFilters message.
             * @function verify
             * @memberof protocol.AMLClientBatchFilters
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientBatchFilters.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.filterSources != null && message.hasOwnProperty("filterSources")) {
                    if (!Array.isArray(message.filterSources))
                        return "filterSources: array expected";
                    for (var i = 0; i < message.filterSources.length; ++i)
                        if (!$util.isString(message.filterSources[i]))
                            return "filterSources: string[] expected";
                }
                if (message.fromRiskscore != null && message.hasOwnProperty("fromRiskscore"))
                    if (typeof message.fromRiskscore !== "number")
                        return "fromRiskscore: number expected";
                if (message.toRiskscore != null && message.hasOwnProperty("toRiskscore"))
                    if (typeof message.toRiskscore !== "number")
                        return "toRiskscore: number expected";
                return null;
            };
    
            /**
             * Creates a AMLClientBatchFilters message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientBatchFilters
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientBatchFilters} AMLClientBatchFilters
             */
            AMLClientBatchFilters.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientBatchFilters)
                    return object;
                var message = new $root.protocol.AMLClientBatchFilters();
                if (object.filterSources) {
                    if (!Array.isArray(object.filterSources))
                        throw TypeError(".protocol.AMLClientBatchFilters.filterSources: array expected");
                    message.filterSources = [];
                    for (var i = 0; i < object.filterSources.length; ++i)
                        message.filterSources[i] = String(object.filterSources[i]);
                }
                if (object.fromRiskscore != null)
                    message.fromRiskscore = Number(object.fromRiskscore);
                if (object.toRiskscore != null)
                    message.toRiskscore = Number(object.toRiskscore);
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientBatchFilters message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientBatchFilters
             * @static
             * @param {protocol.AMLClientBatchFilters} message AMLClientBatchFilters
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientBatchFilters.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.filterSources = [];
                if (options.defaults) {
                    object.fromRiskscore = 0;
                    object.toRiskscore = 0;
                }
                if (message.filterSources && message.filterSources.length) {
                    object.filterSources = [];
                    for (var j = 0; j < message.filterSources.length; ++j)
                        object.filterSources[j] = message.filterSources[j];
                }
                if (message.fromRiskscore != null && message.hasOwnProperty("fromRiskscore"))
                    object.fromRiskscore = options.json && !isFinite(message.fromRiskscore) ? String(message.fromRiskscore) : message.fromRiskscore;
                if (message.toRiskscore != null && message.hasOwnProperty("toRiskscore"))
                    object.toRiskscore = options.json && !isFinite(message.toRiskscore) ? String(message.toRiskscore) : message.toRiskscore;
                return object;
            };
    
            /**
             * Converts this AMLClientBatchFilters to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientBatchFilters
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientBatchFilters.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientBatchFilters;
        })();
    
        protocol.AMLClientBatchAddressesListRequest = (function() {
    
            /**
             * Properties of a AMLClientBatchAddressesListRequest.
             * @memberof protocol
             * @interface IAMLClientBatchAddressesListRequest
             * @property {number|null} [batchId] AMLClientBatchAddressesListRequest batchId
             * @property {protocol.IAMLClientBatchFilters|null} [filters] AMLClientBatchAddressesListRequest filters
             * @property {Long|null} [limit] AMLClientBatchAddressesListRequest limit
             * @property {Long|null} [offset] AMLClientBatchAddressesListRequest offset
             * @property {protocol.SortBy|null} [sortBy] AMLClientBatchAddressesListRequest sortBy
             * @property {boolean|null} [sortDesc] AMLClientBatchAddressesListRequest sortDesc
             */
    
            /**
             * Constructs a new AMLClientBatchAddressesListRequest.
             * @memberof protocol
             * @classdesc Represents a AMLClientBatchAddressesListRequest.
             * @implements IAMLClientBatchAddressesListRequest
             * @constructor
             * @param {protocol.IAMLClientBatchAddressesListRequest=} [properties] Properties to set
             */
            function AMLClientBatchAddressesListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientBatchAddressesListRequest batchId.
             * @member {number} batchId
             * @memberof protocol.AMLClientBatchAddressesListRequest
             * @instance
             */
            AMLClientBatchAddressesListRequest.prototype.batchId = 0;
    
            /**
             * AMLClientBatchAddressesListRequest filters.
             * @member {protocol.IAMLClientBatchFilters|null|undefined} filters
             * @memberof protocol.AMLClientBatchAddressesListRequest
             * @instance
             */
            AMLClientBatchAddressesListRequest.prototype.filters = null;
    
            /**
             * AMLClientBatchAddressesListRequest limit.
             * @member {Long} limit
             * @memberof protocol.AMLClientBatchAddressesListRequest
             * @instance
             */
            AMLClientBatchAddressesListRequest.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientBatchAddressesListRequest offset.
             * @member {Long} offset
             * @memberof protocol.AMLClientBatchAddressesListRequest
             * @instance
             */
            AMLClientBatchAddressesListRequest.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientBatchAddressesListRequest sortBy.
             * @member {protocol.SortBy} sortBy
             * @memberof protocol.AMLClientBatchAddressesListRequest
             * @instance
             */
            AMLClientBatchAddressesListRequest.prototype.sortBy = 0;
    
            /**
             * AMLClientBatchAddressesListRequest sortDesc.
             * @member {boolean} sortDesc
             * @memberof protocol.AMLClientBatchAddressesListRequest
             * @instance
             */
            AMLClientBatchAddressesListRequest.prototype.sortDesc = false;
    
            /**
             * Creates a new AMLClientBatchAddressesListRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientBatchAddressesListRequest
             * @static
             * @param {protocol.IAMLClientBatchAddressesListRequest=} [properties] Properties to set
             * @returns {protocol.AMLClientBatchAddressesListRequest} AMLClientBatchAddressesListRequest instance
             */
            AMLClientBatchAddressesListRequest.create = function create(properties) {
                return new AMLClientBatchAddressesListRequest(properties);
            };
    
            /**
             * Encodes the specified AMLClientBatchAddressesListRequest message. Does not implicitly {@link protocol.AMLClientBatchAddressesListRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientBatchAddressesListRequest
             * @static
             * @param {protocol.IAMLClientBatchAddressesListRequest} message AMLClientBatchAddressesListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchAddressesListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.batchId != null && Object.hasOwnProperty.call(message, "batchId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.batchId);
                if (message.filters != null && Object.hasOwnProperty.call(message, "filters"))
                    $root.protocol.AMLClientBatchFilters.encode(message.filters, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.limit);
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.offset);
                if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.sortBy);
                if (message.sortDesc != null && Object.hasOwnProperty.call(message, "sortDesc"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.sortDesc);
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientBatchAddressesListRequest message, length delimited. Does not implicitly {@link protocol.AMLClientBatchAddressesListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientBatchAddressesListRequest
             * @static
             * @param {protocol.IAMLClientBatchAddressesListRequest} message AMLClientBatchAddressesListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchAddressesListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientBatchAddressesListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientBatchAddressesListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientBatchAddressesListRequest} AMLClientBatchAddressesListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchAddressesListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientBatchAddressesListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.batchId = reader.uint32();
                        break;
                    case 2:
                        message.filters = $root.protocol.AMLClientBatchFilters.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.limit = reader.int64();
                        break;
                    case 4:
                        message.offset = reader.int64();
                        break;
                    case 5:
                        message.sortBy = reader.int32();
                        break;
                    case 6:
                        message.sortDesc = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientBatchAddressesListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientBatchAddressesListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientBatchAddressesListRequest} AMLClientBatchAddressesListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchAddressesListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientBatchAddressesListRequest message.
             * @function verify
             * @memberof protocol.AMLClientBatchAddressesListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientBatchAddressesListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.batchId != null && message.hasOwnProperty("batchId"))
                    if (!$util.isInteger(message.batchId))
                        return "batchId: integer expected";
                if (message.filters != null && message.hasOwnProperty("filters")) {
                    var error = $root.protocol.AMLClientBatchFilters.verify(message.filters);
                    if (error)
                        return "filters." + error;
                }
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                        return "limit: integer|Long expected";
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset) && !(message.offset && $util.isInteger(message.offset.low) && $util.isInteger(message.offset.high)))
                        return "offset: integer|Long expected";
                if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                    switch (message.sortBy) {
                    default:
                        return "sortBy: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                        break;
                    }
                if (message.sortDesc != null && message.hasOwnProperty("sortDesc"))
                    if (typeof message.sortDesc !== "boolean")
                        return "sortDesc: boolean expected";
                return null;
            };
    
            /**
             * Creates a AMLClientBatchAddressesListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientBatchAddressesListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientBatchAddressesListRequest} AMLClientBatchAddressesListRequest
             */
            AMLClientBatchAddressesListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientBatchAddressesListRequest)
                    return object;
                var message = new $root.protocol.AMLClientBatchAddressesListRequest();
                if (object.batchId != null)
                    message.batchId = object.batchId >>> 0;
                if (object.filters != null) {
                    if (typeof object.filters !== "object")
                        throw TypeError(".protocol.AMLClientBatchAddressesListRequest.filters: object expected");
                    message.filters = $root.protocol.AMLClientBatchFilters.fromObject(object.filters);
                }
                if (object.limit != null)
                    if ($util.Long)
                        (message.limit = $util.Long.fromValue(object.limit)).unsigned = false;
                    else if (typeof object.limit === "string")
                        message.limit = parseInt(object.limit, 10);
                    else if (typeof object.limit === "number")
                        message.limit = object.limit;
                    else if (typeof object.limit === "object")
                        message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber();
                if (object.offset != null)
                    if ($util.Long)
                        (message.offset = $util.Long.fromValue(object.offset)).unsigned = false;
                    else if (typeof object.offset === "string")
                        message.offset = parseInt(object.offset, 10);
                    else if (typeof object.offset === "number")
                        message.offset = object.offset;
                    else if (typeof object.offset === "object")
                        message.offset = new $util.LongBits(object.offset.low >>> 0, object.offset.high >>> 0).toNumber();
                switch (object.sortBy) {
                case "UNK_SORT_BY":
                case 0:
                    message.sortBy = 0;
                    break;
                case "ID_SORT_BY":
                case 1:
                    message.sortBy = 1;
                    break;
                case "DATE_SORT_BY":
                case 2:
                    message.sortBy = 2;
                    break;
                case "CURRENCY_SORT_BY":
                case 3:
                    message.sortBy = 3;
                    break;
                case "TAGS_SORT_BY":
                case 4:
                    message.sortBy = 4;
                    break;
                case "TX_HASH_SORT_BY":
                case 5:
                    message.sortBy = 5;
                    break;
                case "AMOUNT_SORT_BY":
                case 6:
                    message.sortBy = 6;
                    break;
                case "NAME_SORT_BY":
                case 7:
                    message.sortBy = 7;
                    break;
                case "LAST_AMOUNT_SORT_BY":
                case 8:
                    message.sortBy = 8;
                    break;
                case "STATUS_SORT_BY":
                case 9:
                    message.sortBy = 9;
                    break;
                case "ENTITIES_COUNT_SORT_BY":
                case 10:
                    message.sortBy = 10;
                    break;
                case "ADDRESS_SORT_BY":
                case 11:
                    message.sortBy = 11;
                    break;
                case "RISK_SCORE_SORT_BY":
                case 12:
                    message.sortBy = 12;
                    break;
                case "BALANCE_SORT_BY":
                case 13:
                    message.sortBy = 13;
                    break;
                }
                if (object.sortDesc != null)
                    message.sortDesc = Boolean(object.sortDesc);
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientBatchAddressesListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientBatchAddressesListRequest
             * @static
             * @param {protocol.AMLClientBatchAddressesListRequest} message AMLClientBatchAddressesListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientBatchAddressesListRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.batchId = 0;
                    object.filters = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.limit = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.offset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.offset = options.longs === String ? "0" : 0;
                    object.sortBy = options.enums === String ? "UNK_SORT_BY" : 0;
                    object.sortDesc = false;
                }
                if (message.batchId != null && message.hasOwnProperty("batchId"))
                    object.batchId = message.batchId;
                if (message.filters != null && message.hasOwnProperty("filters"))
                    object.filters = $root.protocol.AMLClientBatchFilters.toObject(message.filters, options);
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (typeof message.limit === "number")
                        object.limit = options.longs === String ? String(message.limit) : message.limit;
                    else
                        object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber() : message.limit;
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (typeof message.offset === "number")
                        object.offset = options.longs === String ? String(message.offset) : message.offset;
                    else
                        object.offset = options.longs === String ? $util.Long.prototype.toString.call(message.offset) : options.longs === Number ? new $util.LongBits(message.offset.low >>> 0, message.offset.high >>> 0).toNumber() : message.offset;
                if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                    object.sortBy = options.enums === String ? $root.protocol.SortBy[message.sortBy] : message.sortBy;
                if (message.sortDesc != null && message.hasOwnProperty("sortDesc"))
                    object.sortDesc = message.sortDesc;
                return object;
            };
    
            /**
             * Converts this AMLClientBatchAddressesListRequest to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientBatchAddressesListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientBatchAddressesListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientBatchAddressesListRequest;
        })();
    
        protocol.AMLClientBatchAddressesListResponse = (function() {
    
            /**
             * Properties of a AMLClientBatchAddressesListResponse.
             * @memberof protocol
             * @interface IAMLClientBatchAddressesListResponse
             * @property {Array.<protocol.IAMLClientAddress>|null} [addresses] AMLClientBatchAddressesListResponse addresses
             * @property {Long|null} [totalCount] AMLClientBatchAddressesListResponse totalCount
             * @property {Long|null} [totalPages] AMLClientBatchAddressesListResponse totalPages
             */
    
            /**
             * Constructs a new AMLClientBatchAddressesListResponse.
             * @memberof protocol
             * @classdesc Represents a AMLClientBatchAddressesListResponse.
             * @implements IAMLClientBatchAddressesListResponse
             * @constructor
             * @param {protocol.IAMLClientBatchAddressesListResponse=} [properties] Properties to set
             */
            function AMLClientBatchAddressesListResponse(properties) {
                this.addresses = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientBatchAddressesListResponse addresses.
             * @member {Array.<protocol.IAMLClientAddress>} addresses
             * @memberof protocol.AMLClientBatchAddressesListResponse
             * @instance
             */
            AMLClientBatchAddressesListResponse.prototype.addresses = $util.emptyArray;
    
            /**
             * AMLClientBatchAddressesListResponse totalCount.
             * @member {Long} totalCount
             * @memberof protocol.AMLClientBatchAddressesListResponse
             * @instance
             */
            AMLClientBatchAddressesListResponse.prototype.totalCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLClientBatchAddressesListResponse totalPages.
             * @member {Long} totalPages
             * @memberof protocol.AMLClientBatchAddressesListResponse
             * @instance
             */
            AMLClientBatchAddressesListResponse.prototype.totalPages = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new AMLClientBatchAddressesListResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientBatchAddressesListResponse
             * @static
             * @param {protocol.IAMLClientBatchAddressesListResponse=} [properties] Properties to set
             * @returns {protocol.AMLClientBatchAddressesListResponse} AMLClientBatchAddressesListResponse instance
             */
            AMLClientBatchAddressesListResponse.create = function create(properties) {
                return new AMLClientBatchAddressesListResponse(properties);
            };
    
            /**
             * Encodes the specified AMLClientBatchAddressesListResponse message. Does not implicitly {@link protocol.AMLClientBatchAddressesListResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientBatchAddressesListResponse
             * @static
             * @param {protocol.IAMLClientBatchAddressesListResponse} message AMLClientBatchAddressesListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchAddressesListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.addresses != null && message.addresses.length)
                    for (var i = 0; i < message.addresses.length; ++i)
                        $root.protocol.AMLClientAddress.encode(message.addresses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalCount);
                if (message.totalPages != null && Object.hasOwnProperty.call(message, "totalPages"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalPages);
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientBatchAddressesListResponse message, length delimited. Does not implicitly {@link protocol.AMLClientBatchAddressesListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientBatchAddressesListResponse
             * @static
             * @param {protocol.IAMLClientBatchAddressesListResponse} message AMLClientBatchAddressesListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchAddressesListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientBatchAddressesListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientBatchAddressesListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientBatchAddressesListResponse} AMLClientBatchAddressesListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchAddressesListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientBatchAddressesListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.addresses && message.addresses.length))
                            message.addresses = [];
                        message.addresses.push($root.protocol.AMLClientAddress.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        message.totalCount = reader.int64();
                        break;
                    case 3:
                        message.totalPages = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientBatchAddressesListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientBatchAddressesListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientBatchAddressesListResponse} AMLClientBatchAddressesListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchAddressesListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientBatchAddressesListResponse message.
             * @function verify
             * @memberof protocol.AMLClientBatchAddressesListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientBatchAddressesListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.addresses != null && message.hasOwnProperty("addresses")) {
                    if (!Array.isArray(message.addresses))
                        return "addresses: array expected";
                    for (var i = 0; i < message.addresses.length; ++i) {
                        var error = $root.protocol.AMLClientAddress.verify(message.addresses[i]);
                        if (error)
                            return "addresses." + error;
                    }
                }
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (!$util.isInteger(message.totalCount) && !(message.totalCount && $util.isInteger(message.totalCount.low) && $util.isInteger(message.totalCount.high)))
                        return "totalCount: integer|Long expected";
                if (message.totalPages != null && message.hasOwnProperty("totalPages"))
                    if (!$util.isInteger(message.totalPages) && !(message.totalPages && $util.isInteger(message.totalPages.low) && $util.isInteger(message.totalPages.high)))
                        return "totalPages: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a AMLClientBatchAddressesListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientBatchAddressesListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientBatchAddressesListResponse} AMLClientBatchAddressesListResponse
             */
            AMLClientBatchAddressesListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientBatchAddressesListResponse)
                    return object;
                var message = new $root.protocol.AMLClientBatchAddressesListResponse();
                if (object.addresses) {
                    if (!Array.isArray(object.addresses))
                        throw TypeError(".protocol.AMLClientBatchAddressesListResponse.addresses: array expected");
                    message.addresses = [];
                    for (var i = 0; i < object.addresses.length; ++i) {
                        if (typeof object.addresses[i] !== "object")
                            throw TypeError(".protocol.AMLClientBatchAddressesListResponse.addresses: object expected");
                        message.addresses[i] = $root.protocol.AMLClientAddress.fromObject(object.addresses[i]);
                    }
                }
                if (object.totalCount != null)
                    if ($util.Long)
                        (message.totalCount = $util.Long.fromValue(object.totalCount)).unsigned = false;
                    else if (typeof object.totalCount === "string")
                        message.totalCount = parseInt(object.totalCount, 10);
                    else if (typeof object.totalCount === "number")
                        message.totalCount = object.totalCount;
                    else if (typeof object.totalCount === "object")
                        message.totalCount = new $util.LongBits(object.totalCount.low >>> 0, object.totalCount.high >>> 0).toNumber();
                if (object.totalPages != null)
                    if ($util.Long)
                        (message.totalPages = $util.Long.fromValue(object.totalPages)).unsigned = false;
                    else if (typeof object.totalPages === "string")
                        message.totalPages = parseInt(object.totalPages, 10);
                    else if (typeof object.totalPages === "number")
                        message.totalPages = object.totalPages;
                    else if (typeof object.totalPages === "object")
                        message.totalPages = new $util.LongBits(object.totalPages.low >>> 0, object.totalPages.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientBatchAddressesListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientBatchAddressesListResponse
             * @static
             * @param {protocol.AMLClientBatchAddressesListResponse} message AMLClientBatchAddressesListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientBatchAddressesListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.addresses = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalCount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalPages = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalPages = options.longs === String ? "0" : 0;
                }
                if (message.addresses && message.addresses.length) {
                    object.addresses = [];
                    for (var j = 0; j < message.addresses.length; ++j)
                        object.addresses[j] = $root.protocol.AMLClientAddress.toObject(message.addresses[j], options);
                }
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (typeof message.totalCount === "number")
                        object.totalCount = options.longs === String ? String(message.totalCount) : message.totalCount;
                    else
                        object.totalCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalCount) : options.longs === Number ? new $util.LongBits(message.totalCount.low >>> 0, message.totalCount.high >>> 0).toNumber() : message.totalCount;
                if (message.totalPages != null && message.hasOwnProperty("totalPages"))
                    if (typeof message.totalPages === "number")
                        object.totalPages = options.longs === String ? String(message.totalPages) : message.totalPages;
                    else
                        object.totalPages = options.longs === String ? $util.Long.prototype.toString.call(message.totalPages) : options.longs === Number ? new $util.LongBits(message.totalPages.low >>> 0, message.totalPages.high >>> 0).toNumber() : message.totalPages;
                return object;
            };
    
            /**
             * Converts this AMLClientBatchAddressesListResponse to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientBatchAddressesListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientBatchAddressesListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientBatchAddressesListResponse;
        })();
    
        protocol.AMLClientBatchAddressRequest = (function() {
    
            /**
             * Properties of a AMLClientBatchAddressRequest.
             * @memberof protocol
             * @interface IAMLClientBatchAddressRequest
             * @property {number|null} [batchId] AMLClientBatchAddressRequest batchId
             * @property {string|null} [address] AMLClientBatchAddressRequest address
             */
    
            /**
             * Constructs a new AMLClientBatchAddressRequest.
             * @memberof protocol
             * @classdesc Represents a AMLClientBatchAddressRequest.
             * @implements IAMLClientBatchAddressRequest
             * @constructor
             * @param {protocol.IAMLClientBatchAddressRequest=} [properties] Properties to set
             */
            function AMLClientBatchAddressRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientBatchAddressRequest batchId.
             * @member {number} batchId
             * @memberof protocol.AMLClientBatchAddressRequest
             * @instance
             */
            AMLClientBatchAddressRequest.prototype.batchId = 0;
    
            /**
             * AMLClientBatchAddressRequest address.
             * @member {string} address
             * @memberof protocol.AMLClientBatchAddressRequest
             * @instance
             */
            AMLClientBatchAddressRequest.prototype.address = "";
    
            /**
             * Creates a new AMLClientBatchAddressRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientBatchAddressRequest
             * @static
             * @param {protocol.IAMLClientBatchAddressRequest=} [properties] Properties to set
             * @returns {protocol.AMLClientBatchAddressRequest} AMLClientBatchAddressRequest instance
             */
            AMLClientBatchAddressRequest.create = function create(properties) {
                return new AMLClientBatchAddressRequest(properties);
            };
    
            /**
             * Encodes the specified AMLClientBatchAddressRequest message. Does not implicitly {@link protocol.AMLClientBatchAddressRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientBatchAddressRequest
             * @static
             * @param {protocol.IAMLClientBatchAddressRequest} message AMLClientBatchAddressRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchAddressRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.batchId != null && Object.hasOwnProperty.call(message, "batchId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.batchId);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientBatchAddressRequest message, length delimited. Does not implicitly {@link protocol.AMLClientBatchAddressRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientBatchAddressRequest
             * @static
             * @param {protocol.IAMLClientBatchAddressRequest} message AMLClientBatchAddressRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchAddressRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientBatchAddressRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientBatchAddressRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientBatchAddressRequest} AMLClientBatchAddressRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchAddressRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientBatchAddressRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.batchId = reader.uint32();
                        break;
                    case 2:
                        message.address = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientBatchAddressRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientBatchAddressRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientBatchAddressRequest} AMLClientBatchAddressRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchAddressRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientBatchAddressRequest message.
             * @function verify
             * @memberof protocol.AMLClientBatchAddressRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientBatchAddressRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.batchId != null && message.hasOwnProperty("batchId"))
                    if (!$util.isInteger(message.batchId))
                        return "batchId: integer expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                return null;
            };
    
            /**
             * Creates a AMLClientBatchAddressRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientBatchAddressRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientBatchAddressRequest} AMLClientBatchAddressRequest
             */
            AMLClientBatchAddressRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientBatchAddressRequest)
                    return object;
                var message = new $root.protocol.AMLClientBatchAddressRequest();
                if (object.batchId != null)
                    message.batchId = object.batchId >>> 0;
                if (object.address != null)
                    message.address = String(object.address);
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientBatchAddressRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientBatchAddressRequest
             * @static
             * @param {protocol.AMLClientBatchAddressRequest} message AMLClientBatchAddressRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientBatchAddressRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.batchId = 0;
                    object.address = "";
                }
                if (message.batchId != null && message.hasOwnProperty("batchId"))
                    object.batchId = message.batchId;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                return object;
            };
    
            /**
             * Converts this AMLClientBatchAddressRequest to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientBatchAddressRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientBatchAddressRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientBatchAddressRequest;
        })();
    
        protocol.AMLClientBatchAddressResponse = (function() {
    
            /**
             * Properties of a AMLClientBatchAddressResponse.
             * @memberof protocol
             * @interface IAMLClientBatchAddressResponse
             * @property {protocol.IAMLClientAddress|null} [address] AMLClientBatchAddressResponse address
             */
    
            /**
             * Constructs a new AMLClientBatchAddressResponse.
             * @memberof protocol
             * @classdesc Represents a AMLClientBatchAddressResponse.
             * @implements IAMLClientBatchAddressResponse
             * @constructor
             * @param {protocol.IAMLClientBatchAddressResponse=} [properties] Properties to set
             */
            function AMLClientBatchAddressResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLClientBatchAddressResponse address.
             * @member {protocol.IAMLClientAddress|null|undefined} address
             * @memberof protocol.AMLClientBatchAddressResponse
             * @instance
             */
            AMLClientBatchAddressResponse.prototype.address = null;
    
            /**
             * Creates a new AMLClientBatchAddressResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AMLClientBatchAddressResponse
             * @static
             * @param {protocol.IAMLClientBatchAddressResponse=} [properties] Properties to set
             * @returns {protocol.AMLClientBatchAddressResponse} AMLClientBatchAddressResponse instance
             */
            AMLClientBatchAddressResponse.create = function create(properties) {
                return new AMLClientBatchAddressResponse(properties);
            };
    
            /**
             * Encodes the specified AMLClientBatchAddressResponse message. Does not implicitly {@link protocol.AMLClientBatchAddressResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLClientBatchAddressResponse
             * @static
             * @param {protocol.IAMLClientBatchAddressResponse} message AMLClientBatchAddressResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchAddressResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    $root.protocol.AMLClientAddress.encode(message.address, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AMLClientBatchAddressResponse message, length delimited. Does not implicitly {@link protocol.AMLClientBatchAddressResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLClientBatchAddressResponse
             * @static
             * @param {protocol.IAMLClientBatchAddressResponse} message AMLClientBatchAddressResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLClientBatchAddressResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLClientBatchAddressResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLClientBatchAddressResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLClientBatchAddressResponse} AMLClientBatchAddressResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchAddressResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLClientBatchAddressResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.address = $root.protocol.AMLClientAddress.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLClientBatchAddressResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLClientBatchAddressResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLClientBatchAddressResponse} AMLClientBatchAddressResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLClientBatchAddressResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLClientBatchAddressResponse message.
             * @function verify
             * @memberof protocol.AMLClientBatchAddressResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLClientBatchAddressResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address")) {
                    var error = $root.protocol.AMLClientAddress.verify(message.address);
                    if (error)
                        return "address." + error;
                }
                return null;
            };
    
            /**
             * Creates a AMLClientBatchAddressResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLClientBatchAddressResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLClientBatchAddressResponse} AMLClientBatchAddressResponse
             */
            AMLClientBatchAddressResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLClientBatchAddressResponse)
                    return object;
                var message = new $root.protocol.AMLClientBatchAddressResponse();
                if (object.address != null) {
                    if (typeof object.address !== "object")
                        throw TypeError(".protocol.AMLClientBatchAddressResponse.address: object expected");
                    message.address = $root.protocol.AMLClientAddress.fromObject(object.address);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a AMLClientBatchAddressResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLClientBatchAddressResponse
             * @static
             * @param {protocol.AMLClientBatchAddressResponse} message AMLClientBatchAddressResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLClientBatchAddressResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.address = null;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = $root.protocol.AMLClientAddress.toObject(message.address, options);
                return object;
            };
    
            /**
             * Converts this AMLClientBatchAddressResponse to JSON.
             * @function toJSON
             * @memberof protocol.AMLClientBatchAddressResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLClientBatchAddressResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLClientBatchAddressResponse;
        })();
    
        protocol.AMLBatchCheck = (function() {
    
            /**
             * Properties of a AMLBatchCheck.
             * @memberof protocol
             * @interface IAMLBatchCheck
             * @property {number|null} [id] AMLBatchCheck id
             * @property {protocol.AMLBatchCheckStatus|null} [status] AMLBatchCheck status
             * @property {Long|null} [totalChecks] AMLBatchCheck totalChecks
             * @property {Long|null} [activeChecks] AMLBatchCheck activeChecks
             * @property {Long|null} [pendingChecks] AMLBatchCheck pendingChecks
             * @property {Long|null} [completedChecks] AMLBatchCheck completedChecks
             * @property {Long|null} [canceledChecks] AMLBatchCheck canceledChecks
             * @property {Long|null} [failedChecks] AMLBatchCheck failedChecks
             * @property {Long|null} [createdAt] AMLBatchCheck createdAt
             * @property {Long|null} [updatedAt] AMLBatchCheck updatedAt
             * @property {Array.<protocol.IAMLCheck>|null} [checks] AMLBatchCheck checks
             */
    
            /**
             * Constructs a new AMLBatchCheck.
             * @memberof protocol
             * @classdesc Represents a AMLBatchCheck.
             * @implements IAMLBatchCheck
             * @constructor
             * @param {protocol.IAMLBatchCheck=} [properties] Properties to set
             */
            function AMLBatchCheck(properties) {
                this.checks = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLBatchCheck id.
             * @member {number} id
             * @memberof protocol.AMLBatchCheck
             * @instance
             */
            AMLBatchCheck.prototype.id = 0;
    
            /**
             * AMLBatchCheck status.
             * @member {protocol.AMLBatchCheckStatus} status
             * @memberof protocol.AMLBatchCheck
             * @instance
             */
            AMLBatchCheck.prototype.status = 0;
    
            /**
             * AMLBatchCheck totalChecks.
             * @member {Long} totalChecks
             * @memberof protocol.AMLBatchCheck
             * @instance
             */
            AMLBatchCheck.prototype.totalChecks = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLBatchCheck activeChecks.
             * @member {Long} activeChecks
             * @memberof protocol.AMLBatchCheck
             * @instance
             */
            AMLBatchCheck.prototype.activeChecks = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLBatchCheck pendingChecks.
             * @member {Long} pendingChecks
             * @memberof protocol.AMLBatchCheck
             * @instance
             */
            AMLBatchCheck.prototype.pendingChecks = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLBatchCheck completedChecks.
             * @member {Long} completedChecks
             * @memberof protocol.AMLBatchCheck
             * @instance
             */
            AMLBatchCheck.prototype.completedChecks = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLBatchCheck canceledChecks.
             * @member {Long} canceledChecks
             * @memberof protocol.AMLBatchCheck
             * @instance
             */
            AMLBatchCheck.prototype.canceledChecks = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLBatchCheck failedChecks.
             * @member {Long} failedChecks
             * @memberof protocol.AMLBatchCheck
             * @instance
             */
            AMLBatchCheck.prototype.failedChecks = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLBatchCheck createdAt.
             * @member {Long} createdAt
             * @memberof protocol.AMLBatchCheck
             * @instance
             */
            AMLBatchCheck.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLBatchCheck updatedAt.
             * @member {Long} updatedAt
             * @memberof protocol.AMLBatchCheck
             * @instance
             */
            AMLBatchCheck.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * AMLBatchCheck checks.
             * @member {Array.<protocol.IAMLCheck>} checks
             * @memberof protocol.AMLBatchCheck
             * @instance
             */
            AMLBatchCheck.prototype.checks = $util.emptyArray;
    
            /**
             * Creates a new AMLBatchCheck instance using the specified properties.
             * @function create
             * @memberof protocol.AMLBatchCheck
             * @static
             * @param {protocol.IAMLBatchCheck=} [properties] Properties to set
             * @returns {protocol.AMLBatchCheck} AMLBatchCheck instance
             */
            AMLBatchCheck.create = function create(properties) {
                return new AMLBatchCheck(properties);
            };
    
            /**
             * Encodes the specified AMLBatchCheck message. Does not implicitly {@link protocol.AMLBatchCheck.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLBatchCheck
             * @static
             * @param {protocol.IAMLBatchCheck} message AMLBatchCheck message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLBatchCheck.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
                if (message.totalChecks != null && Object.hasOwnProperty.call(message, "totalChecks"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalChecks);
                if (message.activeChecks != null && Object.hasOwnProperty.call(message, "activeChecks"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.activeChecks);
                if (message.pendingChecks != null && Object.hasOwnProperty.call(message, "pendingChecks"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.pendingChecks);
                if (message.completedChecks != null && Object.hasOwnProperty.call(message, "completedChecks"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.completedChecks);
                if (message.canceledChecks != null && Object.hasOwnProperty.call(message, "canceledChecks"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.canceledChecks);
                if (message.failedChecks != null && Object.hasOwnProperty.call(message, "failedChecks"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int64(message.failedChecks);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int64(message.createdAt);
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int64(message.updatedAt);
                if (message.checks != null && message.checks.length)
                    for (var i = 0; i < message.checks.length; ++i)
                        $root.protocol.AMLCheck.encode(message.checks[i], writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AMLBatchCheck message, length delimited. Does not implicitly {@link protocol.AMLBatchCheck.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLBatchCheck
             * @static
             * @param {protocol.IAMLBatchCheck} message AMLBatchCheck message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLBatchCheck.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLBatchCheck message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLBatchCheck
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLBatchCheck} AMLBatchCheck
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLBatchCheck.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLBatchCheck();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint32();
                        break;
                    case 2:
                        message.status = reader.int32();
                        break;
                    case 3:
                        message.totalChecks = reader.int64();
                        break;
                    case 4:
                        message.activeChecks = reader.int64();
                        break;
                    case 5:
                        message.pendingChecks = reader.int64();
                        break;
                    case 6:
                        message.completedChecks = reader.int64();
                        break;
                    case 7:
                        message.canceledChecks = reader.int64();
                        break;
                    case 8:
                        message.failedChecks = reader.int64();
                        break;
                    case 9:
                        message.createdAt = reader.int64();
                        break;
                    case 10:
                        message.updatedAt = reader.int64();
                        break;
                    case 50:
                        if (!(message.checks && message.checks.length))
                            message.checks = [];
                        message.checks.push($root.protocol.AMLCheck.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLBatchCheck message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLBatchCheck
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLBatchCheck} AMLBatchCheck
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLBatchCheck.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLBatchCheck message.
             * @function verify
             * @memberof protocol.AMLBatchCheck
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLBatchCheck.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.totalChecks != null && message.hasOwnProperty("totalChecks"))
                    if (!$util.isInteger(message.totalChecks) && !(message.totalChecks && $util.isInteger(message.totalChecks.low) && $util.isInteger(message.totalChecks.high)))
                        return "totalChecks: integer|Long expected";
                if (message.activeChecks != null && message.hasOwnProperty("activeChecks"))
                    if (!$util.isInteger(message.activeChecks) && !(message.activeChecks && $util.isInteger(message.activeChecks.low) && $util.isInteger(message.activeChecks.high)))
                        return "activeChecks: integer|Long expected";
                if (message.pendingChecks != null && message.hasOwnProperty("pendingChecks"))
                    if (!$util.isInteger(message.pendingChecks) && !(message.pendingChecks && $util.isInteger(message.pendingChecks.low) && $util.isInteger(message.pendingChecks.high)))
                        return "pendingChecks: integer|Long expected";
                if (message.completedChecks != null && message.hasOwnProperty("completedChecks"))
                    if (!$util.isInteger(message.completedChecks) && !(message.completedChecks && $util.isInteger(message.completedChecks.low) && $util.isInteger(message.completedChecks.high)))
                        return "completedChecks: integer|Long expected";
                if (message.canceledChecks != null && message.hasOwnProperty("canceledChecks"))
                    if (!$util.isInteger(message.canceledChecks) && !(message.canceledChecks && $util.isInteger(message.canceledChecks.low) && $util.isInteger(message.canceledChecks.high)))
                        return "canceledChecks: integer|Long expected";
                if (message.failedChecks != null && message.hasOwnProperty("failedChecks"))
                    if (!$util.isInteger(message.failedChecks) && !(message.failedChecks && $util.isInteger(message.failedChecks.low) && $util.isInteger(message.failedChecks.high)))
                        return "failedChecks: integer|Long expected";
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                        return "createdAt: integer|Long expected";
                if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                    if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                        return "updatedAt: integer|Long expected";
                if (message.checks != null && message.hasOwnProperty("checks")) {
                    if (!Array.isArray(message.checks))
                        return "checks: array expected";
                    for (var i = 0; i < message.checks.length; ++i) {
                        var error = $root.protocol.AMLCheck.verify(message.checks[i]);
                        if (error)
                            return "checks." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a AMLBatchCheck message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLBatchCheck
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLBatchCheck} AMLBatchCheck
             */
            AMLBatchCheck.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLBatchCheck)
                    return object;
                var message = new $root.protocol.AMLBatchCheck();
                if (object.id != null)
                    message.id = object.id >>> 0;
                switch (object.status) {
                case "UNK_AML_BATCH_CHECK_STATUS":
                case 0:
                    message.status = 0;
                    break;
                case "ACTIVE_AML_BATCH_CHECK_STATUS":
                case 1:
                    message.status = 1;
                    break;
                case "COMPLETED_AML_BATCH_CHECK_STATUS":
                case 2:
                    message.status = 2;
                    break;
                case "CANCELED_AML_BATCH_CHECK_STATUS":
                case 3:
                    message.status = 3;
                    break;
                }
                if (object.totalChecks != null)
                    if ($util.Long)
                        (message.totalChecks = $util.Long.fromValue(object.totalChecks)).unsigned = false;
                    else if (typeof object.totalChecks === "string")
                        message.totalChecks = parseInt(object.totalChecks, 10);
                    else if (typeof object.totalChecks === "number")
                        message.totalChecks = object.totalChecks;
                    else if (typeof object.totalChecks === "object")
                        message.totalChecks = new $util.LongBits(object.totalChecks.low >>> 0, object.totalChecks.high >>> 0).toNumber();
                if (object.activeChecks != null)
                    if ($util.Long)
                        (message.activeChecks = $util.Long.fromValue(object.activeChecks)).unsigned = false;
                    else if (typeof object.activeChecks === "string")
                        message.activeChecks = parseInt(object.activeChecks, 10);
                    else if (typeof object.activeChecks === "number")
                        message.activeChecks = object.activeChecks;
                    else if (typeof object.activeChecks === "object")
                        message.activeChecks = new $util.LongBits(object.activeChecks.low >>> 0, object.activeChecks.high >>> 0).toNumber();
                if (object.pendingChecks != null)
                    if ($util.Long)
                        (message.pendingChecks = $util.Long.fromValue(object.pendingChecks)).unsigned = false;
                    else if (typeof object.pendingChecks === "string")
                        message.pendingChecks = parseInt(object.pendingChecks, 10);
                    else if (typeof object.pendingChecks === "number")
                        message.pendingChecks = object.pendingChecks;
                    else if (typeof object.pendingChecks === "object")
                        message.pendingChecks = new $util.LongBits(object.pendingChecks.low >>> 0, object.pendingChecks.high >>> 0).toNumber();
                if (object.completedChecks != null)
                    if ($util.Long)
                        (message.completedChecks = $util.Long.fromValue(object.completedChecks)).unsigned = false;
                    else if (typeof object.completedChecks === "string")
                        message.completedChecks = parseInt(object.completedChecks, 10);
                    else if (typeof object.completedChecks === "number")
                        message.completedChecks = object.completedChecks;
                    else if (typeof object.completedChecks === "object")
                        message.completedChecks = new $util.LongBits(object.completedChecks.low >>> 0, object.completedChecks.high >>> 0).toNumber();
                if (object.canceledChecks != null)
                    if ($util.Long)
                        (message.canceledChecks = $util.Long.fromValue(object.canceledChecks)).unsigned = false;
                    else if (typeof object.canceledChecks === "string")
                        message.canceledChecks = parseInt(object.canceledChecks, 10);
                    else if (typeof object.canceledChecks === "number")
                        message.canceledChecks = object.canceledChecks;
                    else if (typeof object.canceledChecks === "object")
                        message.canceledChecks = new $util.LongBits(object.canceledChecks.low >>> 0, object.canceledChecks.high >>> 0).toNumber();
                if (object.failedChecks != null)
                    if ($util.Long)
                        (message.failedChecks = $util.Long.fromValue(object.failedChecks)).unsigned = false;
                    else if (typeof object.failedChecks === "string")
                        message.failedChecks = parseInt(object.failedChecks, 10);
                    else if (typeof object.failedChecks === "number")
                        message.failedChecks = object.failedChecks;
                    else if (typeof object.failedChecks === "object")
                        message.failedChecks = new $util.LongBits(object.failedChecks.low >>> 0, object.failedChecks.high >>> 0).toNumber();
                if (object.createdAt != null)
                    if ($util.Long)
                        (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                    else if (typeof object.createdAt === "string")
                        message.createdAt = parseInt(object.createdAt, 10);
                    else if (typeof object.createdAt === "number")
                        message.createdAt = object.createdAt;
                    else if (typeof object.createdAt === "object")
                        message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                if (object.updatedAt != null)
                    if ($util.Long)
                        (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                    else if (typeof object.updatedAt === "string")
                        message.updatedAt = parseInt(object.updatedAt, 10);
                    else if (typeof object.updatedAt === "number")
                        message.updatedAt = object.updatedAt;
                    else if (typeof object.updatedAt === "object")
                        message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                if (object.checks) {
                    if (!Array.isArray(object.checks))
                        throw TypeError(".protocol.AMLBatchCheck.checks: array expected");
                    message.checks = [];
                    for (var i = 0; i < object.checks.length; ++i) {
                        if (typeof object.checks[i] !== "object")
                            throw TypeError(".protocol.AMLBatchCheck.checks: object expected");
                        message.checks[i] = $root.protocol.AMLCheck.fromObject(object.checks[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a AMLBatchCheck message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLBatchCheck
             * @static
             * @param {protocol.AMLBatchCheck} message AMLBatchCheck
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLBatchCheck.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.checks = [];
                if (options.defaults) {
                    object.id = 0;
                    object.status = options.enums === String ? "UNK_AML_BATCH_CHECK_STATUS" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalChecks = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalChecks = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.activeChecks = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.activeChecks = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.pendingChecks = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.pendingChecks = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.completedChecks = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.completedChecks = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.canceledChecks = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.canceledChecks = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.failedChecks = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.failedChecks = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAt = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updatedAt = options.longs === String ? "0" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.protocol.AMLBatchCheckStatus[message.status] : message.status;
                if (message.totalChecks != null && message.hasOwnProperty("totalChecks"))
                    if (typeof message.totalChecks === "number")
                        object.totalChecks = options.longs === String ? String(message.totalChecks) : message.totalChecks;
                    else
                        object.totalChecks = options.longs === String ? $util.Long.prototype.toString.call(message.totalChecks) : options.longs === Number ? new $util.LongBits(message.totalChecks.low >>> 0, message.totalChecks.high >>> 0).toNumber() : message.totalChecks;
                if (message.activeChecks != null && message.hasOwnProperty("activeChecks"))
                    if (typeof message.activeChecks === "number")
                        object.activeChecks = options.longs === String ? String(message.activeChecks) : message.activeChecks;
                    else
                        object.activeChecks = options.longs === String ? $util.Long.prototype.toString.call(message.activeChecks) : options.longs === Number ? new $util.LongBits(message.activeChecks.low >>> 0, message.activeChecks.high >>> 0).toNumber() : message.activeChecks;
                if (message.pendingChecks != null && message.hasOwnProperty("pendingChecks"))
                    if (typeof message.pendingChecks === "number")
                        object.pendingChecks = options.longs === String ? String(message.pendingChecks) : message.pendingChecks;
                    else
                        object.pendingChecks = options.longs === String ? $util.Long.prototype.toString.call(message.pendingChecks) : options.longs === Number ? new $util.LongBits(message.pendingChecks.low >>> 0, message.pendingChecks.high >>> 0).toNumber() : message.pendingChecks;
                if (message.completedChecks != null && message.hasOwnProperty("completedChecks"))
                    if (typeof message.completedChecks === "number")
                        object.completedChecks = options.longs === String ? String(message.completedChecks) : message.completedChecks;
                    else
                        object.completedChecks = options.longs === String ? $util.Long.prototype.toString.call(message.completedChecks) : options.longs === Number ? new $util.LongBits(message.completedChecks.low >>> 0, message.completedChecks.high >>> 0).toNumber() : message.completedChecks;
                if (message.canceledChecks != null && message.hasOwnProperty("canceledChecks"))
                    if (typeof message.canceledChecks === "number")
                        object.canceledChecks = options.longs === String ? String(message.canceledChecks) : message.canceledChecks;
                    else
                        object.canceledChecks = options.longs === String ? $util.Long.prototype.toString.call(message.canceledChecks) : options.longs === Number ? new $util.LongBits(message.canceledChecks.low >>> 0, message.canceledChecks.high >>> 0).toNumber() : message.canceledChecks;
                if (message.failedChecks != null && message.hasOwnProperty("failedChecks"))
                    if (typeof message.failedChecks === "number")
                        object.failedChecks = options.longs === String ? String(message.failedChecks) : message.failedChecks;
                    else
                        object.failedChecks = options.longs === String ? $util.Long.prototype.toString.call(message.failedChecks) : options.longs === Number ? new $util.LongBits(message.failedChecks.low >>> 0, message.failedChecks.high >>> 0).toNumber() : message.failedChecks;
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (typeof message.createdAt === "number")
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                    else
                        object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                    if (typeof message.updatedAt === "number")
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                    else
                        object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                if (message.checks && message.checks.length) {
                    object.checks = [];
                    for (var j = 0; j < message.checks.length; ++j)
                        object.checks[j] = $root.protocol.AMLCheck.toObject(message.checks[j], options);
                }
                return object;
            };
    
            /**
             * Converts this AMLBatchCheck to JSON.
             * @function toJSON
             * @memberof protocol.AMLBatchCheck
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLBatchCheck.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLBatchCheck;
        })();
    
        protocol.AMLBatchCheckResultsRequest = (function() {
    
            /**
             * Properties of a AMLBatchCheckResultsRequest.
             * @memberof protocol
             * @interface IAMLBatchCheckResultsRequest
             * @property {Array.<number>|null} [ids] AMLBatchCheckResultsRequest ids
             */
    
            /**
             * Constructs a new AMLBatchCheckResultsRequest.
             * @memberof protocol
             * @classdesc Represents a AMLBatchCheckResultsRequest.
             * @implements IAMLBatchCheckResultsRequest
             * @constructor
             * @param {protocol.IAMLBatchCheckResultsRequest=} [properties] Properties to set
             */
            function AMLBatchCheckResultsRequest(properties) {
                this.ids = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLBatchCheckResultsRequest ids.
             * @member {Array.<number>} ids
             * @memberof protocol.AMLBatchCheckResultsRequest
             * @instance
             */
            AMLBatchCheckResultsRequest.prototype.ids = $util.emptyArray;
    
            /**
             * Creates a new AMLBatchCheckResultsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AMLBatchCheckResultsRequest
             * @static
             * @param {protocol.IAMLBatchCheckResultsRequest=} [properties] Properties to set
             * @returns {protocol.AMLBatchCheckResultsRequest} AMLBatchCheckResultsRequest instance
             */
            AMLBatchCheckResultsRequest.create = function create(properties) {
                return new AMLBatchCheckResultsRequest(properties);
            };
    
            /**
             * Encodes the specified AMLBatchCheckResultsRequest message. Does not implicitly {@link protocol.AMLBatchCheckResultsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLBatchCheckResultsRequest
             * @static
             * @param {protocol.IAMLBatchCheckResultsRequest} message AMLBatchCheckResultsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLBatchCheckResultsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.ids != null && message.ids.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.ids.length; ++i)
                        writer.uint32(message.ids[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified AMLBatchCheckResultsRequest message, length delimited. Does not implicitly {@link protocol.AMLBatchCheckResultsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLBatchCheckResultsRequest
             * @static
             * @param {protocol.IAMLBatchCheckResultsRequest} message AMLBatchCheckResultsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLBatchCheckResultsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLBatchCheckResultsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLBatchCheckResultsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLBatchCheckResultsRequest} AMLBatchCheckResultsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLBatchCheckResultsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLBatchCheckResultsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.ids && message.ids.length))
                            message.ids = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.ids.push(reader.uint32());
                        } else
                            message.ids.push(reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLBatchCheckResultsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLBatchCheckResultsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLBatchCheckResultsRequest} AMLBatchCheckResultsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLBatchCheckResultsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLBatchCheckResultsRequest message.
             * @function verify
             * @memberof protocol.AMLBatchCheckResultsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLBatchCheckResultsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.ids != null && message.hasOwnProperty("ids")) {
                    if (!Array.isArray(message.ids))
                        return "ids: array expected";
                    for (var i = 0; i < message.ids.length; ++i)
                        if (!$util.isInteger(message.ids[i]))
                            return "ids: integer[] expected";
                }
                return null;
            };
    
            /**
             * Creates a AMLBatchCheckResultsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLBatchCheckResultsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLBatchCheckResultsRequest} AMLBatchCheckResultsRequest
             */
            AMLBatchCheckResultsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLBatchCheckResultsRequest)
                    return object;
                var message = new $root.protocol.AMLBatchCheckResultsRequest();
                if (object.ids) {
                    if (!Array.isArray(object.ids))
                        throw TypeError(".protocol.AMLBatchCheckResultsRequest.ids: array expected");
                    message.ids = [];
                    for (var i = 0; i < object.ids.length; ++i)
                        message.ids[i] = object.ids[i] >>> 0;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a AMLBatchCheckResultsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLBatchCheckResultsRequest
             * @static
             * @param {protocol.AMLBatchCheckResultsRequest} message AMLBatchCheckResultsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLBatchCheckResultsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.ids = [];
                if (message.ids && message.ids.length) {
                    object.ids = [];
                    for (var j = 0; j < message.ids.length; ++j)
                        object.ids[j] = message.ids[j];
                }
                return object;
            };
    
            /**
             * Converts this AMLBatchCheckResultsRequest to JSON.
             * @function toJSON
             * @memberof protocol.AMLBatchCheckResultsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLBatchCheckResultsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLBatchCheckResultsRequest;
        })();
    
        protocol.AMLBatchCheckResultsResponse = (function() {
    
            /**
             * Properties of a AMLBatchCheckResultsResponse.
             * @memberof protocol
             * @interface IAMLBatchCheckResultsResponse
             * @property {Array.<protocol.IAMLBatchCheck>|null} [batchChecks] AMLBatchCheckResultsResponse batchChecks
             */
    
            /**
             * Constructs a new AMLBatchCheckResultsResponse.
             * @memberof protocol
             * @classdesc Represents a AMLBatchCheckResultsResponse.
             * @implements IAMLBatchCheckResultsResponse
             * @constructor
             * @param {protocol.IAMLBatchCheckResultsResponse=} [properties] Properties to set
             */
            function AMLBatchCheckResultsResponse(properties) {
                this.batchChecks = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AMLBatchCheckResultsResponse batchChecks.
             * @member {Array.<protocol.IAMLBatchCheck>} batchChecks
             * @memberof protocol.AMLBatchCheckResultsResponse
             * @instance
             */
            AMLBatchCheckResultsResponse.prototype.batchChecks = $util.emptyArray;
    
            /**
             * Creates a new AMLBatchCheckResultsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AMLBatchCheckResultsResponse
             * @static
             * @param {protocol.IAMLBatchCheckResultsResponse=} [properties] Properties to set
             * @returns {protocol.AMLBatchCheckResultsResponse} AMLBatchCheckResultsResponse instance
             */
            AMLBatchCheckResultsResponse.create = function create(properties) {
                return new AMLBatchCheckResultsResponse(properties);
            };
    
            /**
             * Encodes the specified AMLBatchCheckResultsResponse message. Does not implicitly {@link protocol.AMLBatchCheckResultsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AMLBatchCheckResultsResponse
             * @static
             * @param {protocol.IAMLBatchCheckResultsResponse} message AMLBatchCheckResultsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLBatchCheckResultsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.batchChecks != null && message.batchChecks.length)
                    for (var i = 0; i < message.batchChecks.length; ++i)
                        $root.protocol.AMLBatchCheck.encode(message.batchChecks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AMLBatchCheckResultsResponse message, length delimited. Does not implicitly {@link protocol.AMLBatchCheckResultsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AMLBatchCheckResultsResponse
             * @static
             * @param {protocol.IAMLBatchCheckResultsResponse} message AMLBatchCheckResultsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AMLBatchCheckResultsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a AMLBatchCheckResultsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AMLBatchCheckResultsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AMLBatchCheckResultsResponse} AMLBatchCheckResultsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLBatchCheckResultsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AMLBatchCheckResultsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.batchChecks && message.batchChecks.length))
                            message.batchChecks = [];
                        message.batchChecks.push($root.protocol.AMLBatchCheck.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a AMLBatchCheckResultsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AMLBatchCheckResultsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AMLBatchCheckResultsResponse} AMLBatchCheckResultsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AMLBatchCheckResultsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a AMLBatchCheckResultsResponse message.
             * @function verify
             * @memberof protocol.AMLBatchCheckResultsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AMLBatchCheckResultsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.batchChecks != null && message.hasOwnProperty("batchChecks")) {
                    if (!Array.isArray(message.batchChecks))
                        return "batchChecks: array expected";
                    for (var i = 0; i < message.batchChecks.length; ++i) {
                        var error = $root.protocol.AMLBatchCheck.verify(message.batchChecks[i]);
                        if (error)
                            return "batchChecks." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a AMLBatchCheckResultsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AMLBatchCheckResultsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AMLBatchCheckResultsResponse} AMLBatchCheckResultsResponse
             */
            AMLBatchCheckResultsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AMLBatchCheckResultsResponse)
                    return object;
                var message = new $root.protocol.AMLBatchCheckResultsResponse();
                if (object.batchChecks) {
                    if (!Array.isArray(object.batchChecks))
                        throw TypeError(".protocol.AMLBatchCheckResultsResponse.batchChecks: array expected");
                    message.batchChecks = [];
                    for (var i = 0; i < object.batchChecks.length; ++i) {
                        if (typeof object.batchChecks[i] !== "object")
                            throw TypeError(".protocol.AMLBatchCheckResultsResponse.batchChecks: object expected");
                        message.batchChecks[i] = $root.protocol.AMLBatchCheck.fromObject(object.batchChecks[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a AMLBatchCheckResultsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AMLBatchCheckResultsResponse
             * @static
             * @param {protocol.AMLBatchCheckResultsResponse} message AMLBatchCheckResultsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AMLBatchCheckResultsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.batchChecks = [];
                if (message.batchChecks && message.batchChecks.length) {
                    object.batchChecks = [];
                    for (var j = 0; j < message.batchChecks.length; ++j)
                        object.batchChecks[j] = $root.protocol.AMLBatchCheck.toObject(message.batchChecks[j], options);
                }
                return object;
            };
    
            /**
             * Converts this AMLBatchCheckResultsResponse to JSON.
             * @function toJSON
             * @memberof protocol.AMLBatchCheckResultsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AMLBatchCheckResultsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AMLBatchCheckResultsResponse;
        })();
    
        /**
         * ClientPeerStatus enum.
         * @name protocol.ClientPeerStatus
         * @enum {number}
         * @property {number} UNK_CLIENT_PEER_STATUS=0 UNK_CLIENT_PEER_STATUS value
         * @property {number} PENDING_CLIENT_PEER_STATUS=1 PENDING_CLIENT_PEER_STATUS value
         * @property {number} COMPLETED_CLIENT_PEER_STATUS=2 COMPLETED_CLIENT_PEER_STATUS value
         */
        protocol.ClientPeerStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_CLIENT_PEER_STATUS"] = 0;
            values[valuesById[1] = "PENDING_CLIENT_PEER_STATUS"] = 1;
            values[valuesById[2] = "COMPLETED_CLIENT_PEER_STATUS"] = 2;
            return values;
        })();
    
        protocol.ClientPeer = (function() {
    
            /**
             * Properties of a ClientPeer.
             * @memberof protocol
             * @interface IClientPeer
             * @property {number|null} [id] ClientPeer id
             * @property {string|null} [name] ClientPeer name
             * @property {Array.<protocol.IClientPeerAddress>|null} [addresses] ClientPeer addresses
             */
    
            /**
             * Constructs a new ClientPeer.
             * @memberof protocol
             * @classdesc Represents a ClientPeer.
             * @implements IClientPeer
             * @constructor
             * @param {protocol.IClientPeer=} [properties] Properties to set
             */
            function ClientPeer(properties) {
                this.addresses = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientPeer id.
             * @member {number} id
             * @memberof protocol.ClientPeer
             * @instance
             */
            ClientPeer.prototype.id = 0;
    
            /**
             * ClientPeer name.
             * @member {string} name
             * @memberof protocol.ClientPeer
             * @instance
             */
            ClientPeer.prototype.name = "";
    
            /**
             * ClientPeer addresses.
             * @member {Array.<protocol.IClientPeerAddress>} addresses
             * @memberof protocol.ClientPeer
             * @instance
             */
            ClientPeer.prototype.addresses = $util.emptyArray;
    
            /**
             * Creates a new ClientPeer instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeer
             * @static
             * @param {protocol.IClientPeer=} [properties] Properties to set
             * @returns {protocol.ClientPeer} ClientPeer instance
             */
            ClientPeer.create = function create(properties) {
                return new ClientPeer(properties);
            };
    
            /**
             * Encodes the specified ClientPeer message. Does not implicitly {@link protocol.ClientPeer.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeer
             * @static
             * @param {protocol.IClientPeer} message ClientPeer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeer.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.addresses != null && message.addresses.length)
                    for (var i = 0; i < message.addresses.length; ++i)
                        $root.protocol.ClientPeerAddress.encode(message.addresses[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeer message, length delimited. Does not implicitly {@link protocol.ClientPeer.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeer
             * @static
             * @param {protocol.IClientPeer} message ClientPeer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeer.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeer message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeer} ClientPeer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeer.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeer();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint32();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        if (!(message.addresses && message.addresses.length))
                            message.addresses = [];
                        message.addresses.push($root.protocol.ClientPeerAddress.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeer message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeer} ClientPeer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeer.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeer message.
             * @function verify
             * @memberof protocol.ClientPeer
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeer.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.addresses != null && message.hasOwnProperty("addresses")) {
                    if (!Array.isArray(message.addresses))
                        return "addresses: array expected";
                    for (var i = 0; i < message.addresses.length; ++i) {
                        var error = $root.protocol.ClientPeerAddress.verify(message.addresses[i]);
                        if (error)
                            return "addresses." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ClientPeer message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeer
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeer} ClientPeer
             */
            ClientPeer.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeer)
                    return object;
                var message = new $root.protocol.ClientPeer();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.addresses) {
                    if (!Array.isArray(object.addresses))
                        throw TypeError(".protocol.ClientPeer.addresses: array expected");
                    message.addresses = [];
                    for (var i = 0; i < object.addresses.length; ++i) {
                        if (typeof object.addresses[i] !== "object")
                            throw TypeError(".protocol.ClientPeer.addresses: object expected");
                        message.addresses[i] = $root.protocol.ClientPeerAddress.fromObject(object.addresses[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientPeer message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeer
             * @static
             * @param {protocol.ClientPeer} message ClientPeer
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeer.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.addresses = [];
                if (options.defaults) {
                    object.id = 0;
                    object.name = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.addresses && message.addresses.length) {
                    object.addresses = [];
                    for (var j = 0; j < message.addresses.length; ++j)
                        object.addresses[j] = $root.protocol.ClientPeerAddress.toObject(message.addresses[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ClientPeer to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeer
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeer.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeer;
        })();
    
        protocol.ClientPeerDetails = (function() {
    
            /**
             * Properties of a ClientPeerDetails.
             * @memberof protocol
             * @interface IClientPeerDetails
             * @property {number|null} [id] ClientPeerDetails id
             * @property {string|null} [name] ClientPeerDetails name
             * @property {Long|null} [addressesCount] ClientPeerDetails addressesCount
             * @property {Object.<string,string>|null} [totalExpectedAmounts] ClientPeerDetails totalExpectedAmounts
             * @property {Object.<string,string>|null} [totalReceivedAmounts] ClientPeerDetails totalReceivedAmounts
             * @property {Object.<string,string>|null} [lastReceivedAmounts] ClientPeerDetails lastReceivedAmounts
             * @property {Long|null} [lastTxDate] ClientPeerDetails lastTxDate
             * @property {protocol.ClientPeerStatus|null} [status] ClientPeerDetails status
             * @property {Array.<protocol.FiatCurrency>|null} [additionalCurrencies] ClientPeerDetails additionalCurrencies
             */
    
            /**
             * Constructs a new ClientPeerDetails.
             * @memberof protocol
             * @classdesc Represents a ClientPeerDetails.
             * @implements IClientPeerDetails
             * @constructor
             * @param {protocol.IClientPeerDetails=} [properties] Properties to set
             */
            function ClientPeerDetails(properties) {
                this.totalExpectedAmounts = {};
                this.totalReceivedAmounts = {};
                this.lastReceivedAmounts = {};
                this.additionalCurrencies = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientPeerDetails id.
             * @member {number} id
             * @memberof protocol.ClientPeerDetails
             * @instance
             */
            ClientPeerDetails.prototype.id = 0;
    
            /**
             * ClientPeerDetails name.
             * @member {string} name
             * @memberof protocol.ClientPeerDetails
             * @instance
             */
            ClientPeerDetails.prototype.name = "";
    
            /**
             * ClientPeerDetails addressesCount.
             * @member {Long} addressesCount
             * @memberof protocol.ClientPeerDetails
             * @instance
             */
            ClientPeerDetails.prototype.addressesCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeerDetails totalExpectedAmounts.
             * @member {Object.<string,string>} totalExpectedAmounts
             * @memberof protocol.ClientPeerDetails
             * @instance
             */
            ClientPeerDetails.prototype.totalExpectedAmounts = $util.emptyObject;
    
            /**
             * ClientPeerDetails totalReceivedAmounts.
             * @member {Object.<string,string>} totalReceivedAmounts
             * @memberof protocol.ClientPeerDetails
             * @instance
             */
            ClientPeerDetails.prototype.totalReceivedAmounts = $util.emptyObject;
    
            /**
             * ClientPeerDetails lastReceivedAmounts.
             * @member {Object.<string,string>} lastReceivedAmounts
             * @memberof protocol.ClientPeerDetails
             * @instance
             */
            ClientPeerDetails.prototype.lastReceivedAmounts = $util.emptyObject;
    
            /**
             * ClientPeerDetails lastTxDate.
             * @member {Long} lastTxDate
             * @memberof protocol.ClientPeerDetails
             * @instance
             */
            ClientPeerDetails.prototype.lastTxDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeerDetails status.
             * @member {protocol.ClientPeerStatus} status
             * @memberof protocol.ClientPeerDetails
             * @instance
             */
            ClientPeerDetails.prototype.status = 0;
    
            /**
             * ClientPeerDetails additionalCurrencies.
             * @member {Array.<protocol.FiatCurrency>} additionalCurrencies
             * @memberof protocol.ClientPeerDetails
             * @instance
             */
            ClientPeerDetails.prototype.additionalCurrencies = $util.emptyArray;
    
            /**
             * Creates a new ClientPeerDetails instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeerDetails
             * @static
             * @param {protocol.IClientPeerDetails=} [properties] Properties to set
             * @returns {protocol.ClientPeerDetails} ClientPeerDetails instance
             */
            ClientPeerDetails.create = function create(properties) {
                return new ClientPeerDetails(properties);
            };
    
            /**
             * Encodes the specified ClientPeerDetails message. Does not implicitly {@link protocol.ClientPeerDetails.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeerDetails
             * @static
             * @param {protocol.IClientPeerDetails} message ClientPeerDetails message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerDetails.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.addressesCount != null && Object.hasOwnProperty.call(message, "addressesCount"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.addressesCount);
                if (message.totalExpectedAmounts != null && Object.hasOwnProperty.call(message, "totalExpectedAmounts"))
                    for (var keys = Object.keys(message.totalExpectedAmounts), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.totalExpectedAmounts[keys[i]]).ldelim();
                if (message.totalReceivedAmounts != null && Object.hasOwnProperty.call(message, "totalReceivedAmounts"))
                    for (var keys = Object.keys(message.totalReceivedAmounts), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 5, wireType 2 =*/42).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.totalReceivedAmounts[keys[i]]).ldelim();
                if (message.lastReceivedAmounts != null && Object.hasOwnProperty.call(message, "lastReceivedAmounts"))
                    for (var keys = Object.keys(message.lastReceivedAmounts), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.lastReceivedAmounts[keys[i]]).ldelim();
                if (message.lastTxDate != null && Object.hasOwnProperty.call(message, "lastTxDate"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.lastTxDate);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.status);
                if (message.additionalCurrencies != null && message.additionalCurrencies.length) {
                    writer.uint32(/* id 9, wireType 2 =*/74).fork();
                    for (var i = 0; i < message.additionalCurrencies.length; ++i)
                        writer.int32(message.additionalCurrencies[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeerDetails message, length delimited. Does not implicitly {@link protocol.ClientPeerDetails.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeerDetails
             * @static
             * @param {protocol.IClientPeerDetails} message ClientPeerDetails message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerDetails.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeerDetails message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeerDetails
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeerDetails} ClientPeerDetails
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerDetails.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeerDetails(), key;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint32();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.addressesCount = reader.int64();
                        break;
                    case 4:
                        reader.skip().pos++;
                        if (message.totalExpectedAmounts === $util.emptyObject)
                            message.totalExpectedAmounts = {};
                        key = reader.string();
                        reader.pos++;
                        message.totalExpectedAmounts[key] = reader.string();
                        break;
                    case 5:
                        reader.skip().pos++;
                        if (message.totalReceivedAmounts === $util.emptyObject)
                            message.totalReceivedAmounts = {};
                        key = reader.string();
                        reader.pos++;
                        message.totalReceivedAmounts[key] = reader.string();
                        break;
                    case 6:
                        reader.skip().pos++;
                        if (message.lastReceivedAmounts === $util.emptyObject)
                            message.lastReceivedAmounts = {};
                        key = reader.string();
                        reader.pos++;
                        message.lastReceivedAmounts[key] = reader.string();
                        break;
                    case 7:
                        message.lastTxDate = reader.int64();
                        break;
                    case 8:
                        message.status = reader.int32();
                        break;
                    case 9:
                        if (!(message.additionalCurrencies && message.additionalCurrencies.length))
                            message.additionalCurrencies = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.additionalCurrencies.push(reader.int32());
                        } else
                            message.additionalCurrencies.push(reader.int32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeerDetails message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeerDetails
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeerDetails} ClientPeerDetails
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerDetails.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeerDetails message.
             * @function verify
             * @memberof protocol.ClientPeerDetails
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeerDetails.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.addressesCount != null && message.hasOwnProperty("addressesCount"))
                    if (!$util.isInteger(message.addressesCount) && !(message.addressesCount && $util.isInteger(message.addressesCount.low) && $util.isInteger(message.addressesCount.high)))
                        return "addressesCount: integer|Long expected";
                if (message.totalExpectedAmounts != null && message.hasOwnProperty("totalExpectedAmounts")) {
                    if (!$util.isObject(message.totalExpectedAmounts))
                        return "totalExpectedAmounts: object expected";
                    var key = Object.keys(message.totalExpectedAmounts);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.totalExpectedAmounts[key[i]]))
                            return "totalExpectedAmounts: string{k:string} expected";
                }
                if (message.totalReceivedAmounts != null && message.hasOwnProperty("totalReceivedAmounts")) {
                    if (!$util.isObject(message.totalReceivedAmounts))
                        return "totalReceivedAmounts: object expected";
                    var key = Object.keys(message.totalReceivedAmounts);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.totalReceivedAmounts[key[i]]))
                            return "totalReceivedAmounts: string{k:string} expected";
                }
                if (message.lastReceivedAmounts != null && message.hasOwnProperty("lastReceivedAmounts")) {
                    if (!$util.isObject(message.lastReceivedAmounts))
                        return "lastReceivedAmounts: object expected";
                    var key = Object.keys(message.lastReceivedAmounts);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.lastReceivedAmounts[key[i]]))
                            return "lastReceivedAmounts: string{k:string} expected";
                }
                if (message.lastTxDate != null && message.hasOwnProperty("lastTxDate"))
                    if (!$util.isInteger(message.lastTxDate) && !(message.lastTxDate && $util.isInteger(message.lastTxDate.low) && $util.isInteger(message.lastTxDate.high)))
                        return "lastTxDate: integer|Long expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.additionalCurrencies != null && message.hasOwnProperty("additionalCurrencies")) {
                    if (!Array.isArray(message.additionalCurrencies))
                        return "additionalCurrencies: array expected";
                    for (var i = 0; i < message.additionalCurrencies.length; ++i)
                        switch (message.additionalCurrencies[i]) {
                        default:
                            return "additionalCurrencies: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                            break;
                        }
                }
                return null;
            };
    
            /**
             * Creates a ClientPeerDetails message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeerDetails
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeerDetails} ClientPeerDetails
             */
            ClientPeerDetails.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeerDetails)
                    return object;
                var message = new $root.protocol.ClientPeerDetails();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.addressesCount != null)
                    if ($util.Long)
                        (message.addressesCount = $util.Long.fromValue(object.addressesCount)).unsigned = false;
                    else if (typeof object.addressesCount === "string")
                        message.addressesCount = parseInt(object.addressesCount, 10);
                    else if (typeof object.addressesCount === "number")
                        message.addressesCount = object.addressesCount;
                    else if (typeof object.addressesCount === "object")
                        message.addressesCount = new $util.LongBits(object.addressesCount.low >>> 0, object.addressesCount.high >>> 0).toNumber();
                if (object.totalExpectedAmounts) {
                    if (typeof object.totalExpectedAmounts !== "object")
                        throw TypeError(".protocol.ClientPeerDetails.totalExpectedAmounts: object expected");
                    message.totalExpectedAmounts = {};
                    for (var keys = Object.keys(object.totalExpectedAmounts), i = 0; i < keys.length; ++i)
                        message.totalExpectedAmounts[keys[i]] = String(object.totalExpectedAmounts[keys[i]]);
                }
                if (object.totalReceivedAmounts) {
                    if (typeof object.totalReceivedAmounts !== "object")
                        throw TypeError(".protocol.ClientPeerDetails.totalReceivedAmounts: object expected");
                    message.totalReceivedAmounts = {};
                    for (var keys = Object.keys(object.totalReceivedAmounts), i = 0; i < keys.length; ++i)
                        message.totalReceivedAmounts[keys[i]] = String(object.totalReceivedAmounts[keys[i]]);
                }
                if (object.lastReceivedAmounts) {
                    if (typeof object.lastReceivedAmounts !== "object")
                        throw TypeError(".protocol.ClientPeerDetails.lastReceivedAmounts: object expected");
                    message.lastReceivedAmounts = {};
                    for (var keys = Object.keys(object.lastReceivedAmounts), i = 0; i < keys.length; ++i)
                        message.lastReceivedAmounts[keys[i]] = String(object.lastReceivedAmounts[keys[i]]);
                }
                if (object.lastTxDate != null)
                    if ($util.Long)
                        (message.lastTxDate = $util.Long.fromValue(object.lastTxDate)).unsigned = false;
                    else if (typeof object.lastTxDate === "string")
                        message.lastTxDate = parseInt(object.lastTxDate, 10);
                    else if (typeof object.lastTxDate === "number")
                        message.lastTxDate = object.lastTxDate;
                    else if (typeof object.lastTxDate === "object")
                        message.lastTxDate = new $util.LongBits(object.lastTxDate.low >>> 0, object.lastTxDate.high >>> 0).toNumber();
                switch (object.status) {
                case "UNK_CLIENT_PEER_STATUS":
                case 0:
                    message.status = 0;
                    break;
                case "PENDING_CLIENT_PEER_STATUS":
                case 1:
                    message.status = 1;
                    break;
                case "COMPLETED_CLIENT_PEER_STATUS":
                case 2:
                    message.status = 2;
                    break;
                }
                if (object.additionalCurrencies) {
                    if (!Array.isArray(object.additionalCurrencies))
                        throw TypeError(".protocol.ClientPeerDetails.additionalCurrencies: array expected");
                    message.additionalCurrencies = [];
                    for (var i = 0; i < object.additionalCurrencies.length; ++i)
                        switch (object.additionalCurrencies[i]) {
                        default:
                        case "UNK_FIAT_CUR":
                        case 0:
                            message.additionalCurrencies[i] = 0;
                            break;
                        case "USD_FIAT_CUR":
                        case 1:
                            message.additionalCurrencies[i] = 1;
                            break;
                        case "EUR_FIAT_CUR":
                        case 2:
                            message.additionalCurrencies[i] = 2;
                            break;
                        case "ARS_FIAT_CUR":
                        case 3:
                            message.additionalCurrencies[i] = 3;
                            break;
                        case "PEN_FIAT_CUR":
                        case 4:
                            message.additionalCurrencies[i] = 4;
                            break;
                        case "BOB_FIAT_CUR":
                        case 5:
                            message.additionalCurrencies[i] = 5;
                            break;
                        case "CLP_FIAT_CUR":
                        case 6:
                            message.additionalCurrencies[i] = 6;
                            break;
                        case "BRL_FIAT_CUR":
                        case 7:
                            message.additionalCurrencies[i] = 7;
                            break;
                        }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientPeerDetails message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeerDetails
             * @static
             * @param {protocol.ClientPeerDetails} message ClientPeerDetails
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeerDetails.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.additionalCurrencies = [];
                if (options.objects || options.defaults) {
                    object.totalExpectedAmounts = {};
                    object.totalReceivedAmounts = {};
                    object.lastReceivedAmounts = {};
                }
                if (options.defaults) {
                    object.id = 0;
                    object.name = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.addressesCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.addressesCount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.lastTxDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lastTxDate = options.longs === String ? "0" : 0;
                    object.status = options.enums === String ? "UNK_CLIENT_PEER_STATUS" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.addressesCount != null && message.hasOwnProperty("addressesCount"))
                    if (typeof message.addressesCount === "number")
                        object.addressesCount = options.longs === String ? String(message.addressesCount) : message.addressesCount;
                    else
                        object.addressesCount = options.longs === String ? $util.Long.prototype.toString.call(message.addressesCount) : options.longs === Number ? new $util.LongBits(message.addressesCount.low >>> 0, message.addressesCount.high >>> 0).toNumber() : message.addressesCount;
                var keys2;
                if (message.totalExpectedAmounts && (keys2 = Object.keys(message.totalExpectedAmounts)).length) {
                    object.totalExpectedAmounts = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.totalExpectedAmounts[keys2[j]] = message.totalExpectedAmounts[keys2[j]];
                }
                if (message.totalReceivedAmounts && (keys2 = Object.keys(message.totalReceivedAmounts)).length) {
                    object.totalReceivedAmounts = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.totalReceivedAmounts[keys2[j]] = message.totalReceivedAmounts[keys2[j]];
                }
                if (message.lastReceivedAmounts && (keys2 = Object.keys(message.lastReceivedAmounts)).length) {
                    object.lastReceivedAmounts = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.lastReceivedAmounts[keys2[j]] = message.lastReceivedAmounts[keys2[j]];
                }
                if (message.lastTxDate != null && message.hasOwnProperty("lastTxDate"))
                    if (typeof message.lastTxDate === "number")
                        object.lastTxDate = options.longs === String ? String(message.lastTxDate) : message.lastTxDate;
                    else
                        object.lastTxDate = options.longs === String ? $util.Long.prototype.toString.call(message.lastTxDate) : options.longs === Number ? new $util.LongBits(message.lastTxDate.low >>> 0, message.lastTxDate.high >>> 0).toNumber() : message.lastTxDate;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.protocol.ClientPeerStatus[message.status] : message.status;
                if (message.additionalCurrencies && message.additionalCurrencies.length) {
                    object.additionalCurrencies = [];
                    for (var j = 0; j < message.additionalCurrencies.length; ++j)
                        object.additionalCurrencies[j] = options.enums === String ? $root.protocol.FiatCurrency[message.additionalCurrencies[j]] : message.additionalCurrencies[j];
                }
                return object;
            };
    
            /**
             * Converts this ClientPeerDetails to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeerDetails
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeerDetails.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeerDetails;
        })();
    
        /**
         * ClientPeerAddressStatus enum.
         * @name protocol.ClientPeerAddressStatus
         * @enum {number}
         * @property {number} UNK_CLIENT_PEER_ADDRESS_STATUS=0 UNK_CLIENT_PEER_ADDRESS_STATUS value
         * @property {number} WAITING_PAYMENT_CLIENT_PEER_ADDRESS_STATUS=1 WAITING_PAYMENT_CLIENT_PEER_ADDRESS_STATUS value
         * @property {number} PAYMENT_RECEIVED_CLIENT_PEER_ADDRESS_STATUS=2 PAYMENT_RECEIVED_CLIENT_PEER_ADDRESS_STATUS value
         * @property {number} EXPECTED_AMOUNT_PAID_CLIENT_PEER_ADDRESS_STATUS=3 EXPECTED_AMOUNT_PAID_CLIENT_PEER_ADDRESS_STATUS value
         */
        protocol.ClientPeerAddressStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNK_CLIENT_PEER_ADDRESS_STATUS"] = 0;
            values[valuesById[1] = "WAITING_PAYMENT_CLIENT_PEER_ADDRESS_STATUS"] = 1;
            values[valuesById[2] = "PAYMENT_RECEIVED_CLIENT_PEER_ADDRESS_STATUS"] = 2;
            values[valuesById[3] = "EXPECTED_AMOUNT_PAID_CLIENT_PEER_ADDRESS_STATUS"] = 3;
            return values;
        })();
    
        protocol.ClientPeerAddress = (function() {
    
            /**
             * Properties of a ClientPeerAddress.
             * @memberof protocol
             * @interface IClientPeerAddress
             * @property {string|null} [address] ClientPeerAddress address
             * @property {protocol.Currency|null} [currency] ClientPeerAddress currency
             * @property {Object.<string,string>|null} [expectedAmounts] ClientPeerAddress expectedAmounts
             * @property {Object.<string,string>|null} [receivedAmounts] ClientPeerAddress receivedAmounts
             * @property {string|null} [email] ClientPeerAddress email
             * @property {Long|null} [createdAt] ClientPeerAddress createdAt
             * @property {protocol.ClientPeerAddressStatus|null} [status] ClientPeerAddress status
             * @property {boolean|null} [isActive] ClientPeerAddress isActive
             */
    
            /**
             * Constructs a new ClientPeerAddress.
             * @memberof protocol
             * @classdesc Represents a ClientPeerAddress.
             * @implements IClientPeerAddress
             * @constructor
             * @param {protocol.IClientPeerAddress=} [properties] Properties to set
             */
            function ClientPeerAddress(properties) {
                this.expectedAmounts = {};
                this.receivedAmounts = {};
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientPeerAddress address.
             * @member {string} address
             * @memberof protocol.ClientPeerAddress
             * @instance
             */
            ClientPeerAddress.prototype.address = "";
    
            /**
             * ClientPeerAddress currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.ClientPeerAddress
             * @instance
             */
            ClientPeerAddress.prototype.currency = 0;
    
            /**
             * ClientPeerAddress expectedAmounts.
             * @member {Object.<string,string>} expectedAmounts
             * @memberof protocol.ClientPeerAddress
             * @instance
             */
            ClientPeerAddress.prototype.expectedAmounts = $util.emptyObject;
    
            /**
             * ClientPeerAddress receivedAmounts.
             * @member {Object.<string,string>} receivedAmounts
             * @memberof protocol.ClientPeerAddress
             * @instance
             */
            ClientPeerAddress.prototype.receivedAmounts = $util.emptyObject;
    
            /**
             * ClientPeerAddress email.
             * @member {string} email
             * @memberof protocol.ClientPeerAddress
             * @instance
             */
            ClientPeerAddress.prototype.email = "";
    
            /**
             * ClientPeerAddress createdAt.
             * @member {Long} createdAt
             * @memberof protocol.ClientPeerAddress
             * @instance
             */
            ClientPeerAddress.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeerAddress status.
             * @member {protocol.ClientPeerAddressStatus} status
             * @memberof protocol.ClientPeerAddress
             * @instance
             */
            ClientPeerAddress.prototype.status = 0;
    
            /**
             * ClientPeerAddress isActive.
             * @member {boolean} isActive
             * @memberof protocol.ClientPeerAddress
             * @instance
             */
            ClientPeerAddress.prototype.isActive = false;
    
            /**
             * Creates a new ClientPeerAddress instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeerAddress
             * @static
             * @param {protocol.IClientPeerAddress=} [properties] Properties to set
             * @returns {protocol.ClientPeerAddress} ClientPeerAddress instance
             */
            ClientPeerAddress.create = function create(properties) {
                return new ClientPeerAddress(properties);
            };
    
            /**
             * Encodes the specified ClientPeerAddress message. Does not implicitly {@link protocol.ClientPeerAddress.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeerAddress
             * @static
             * @param {protocol.IClientPeerAddress} message ClientPeerAddress message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerAddress.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currency);
                if (message.expectedAmounts != null && Object.hasOwnProperty.call(message, "expectedAmounts"))
                    for (var keys = Object.keys(message.expectedAmounts), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.expectedAmounts[keys[i]]).ldelim();
                if (message.receivedAmounts != null && Object.hasOwnProperty.call(message, "receivedAmounts"))
                    for (var keys = Object.keys(message.receivedAmounts), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.receivedAmounts[keys[i]]).ldelim();
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.email);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.createdAt);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.status);
                if (message.isActive != null && Object.hasOwnProperty.call(message, "isActive"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isActive);
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeerAddress message, length delimited. Does not implicitly {@link protocol.ClientPeerAddress.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeerAddress
             * @static
             * @param {protocol.IClientPeerAddress} message ClientPeerAddress message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerAddress.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeerAddress message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeerAddress
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeerAddress} ClientPeerAddress
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerAddress.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeerAddress(), key;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.address = reader.string();
                        break;
                    case 2:
                        message.currency = reader.int32();
                        break;
                    case 3:
                        reader.skip().pos++;
                        if (message.expectedAmounts === $util.emptyObject)
                            message.expectedAmounts = {};
                        key = reader.string();
                        reader.pos++;
                        message.expectedAmounts[key] = reader.string();
                        break;
                    case 4:
                        reader.skip().pos++;
                        if (message.receivedAmounts === $util.emptyObject)
                            message.receivedAmounts = {};
                        key = reader.string();
                        reader.pos++;
                        message.receivedAmounts[key] = reader.string();
                        break;
                    case 5:
                        message.email = reader.string();
                        break;
                    case 6:
                        message.createdAt = reader.int64();
                        break;
                    case 7:
                        message.status = reader.int32();
                        break;
                    case 8:
                        message.isActive = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeerAddress message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeerAddress
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeerAddress} ClientPeerAddress
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerAddress.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeerAddress message.
             * @function verify
             * @memberof protocol.ClientPeerAddress
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeerAddress.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.expectedAmounts != null && message.hasOwnProperty("expectedAmounts")) {
                    if (!$util.isObject(message.expectedAmounts))
                        return "expectedAmounts: object expected";
                    var key = Object.keys(message.expectedAmounts);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.expectedAmounts[key[i]]))
                            return "expectedAmounts: string{k:string} expected";
                }
                if (message.receivedAmounts != null && message.hasOwnProperty("receivedAmounts")) {
                    if (!$util.isObject(message.receivedAmounts))
                        return "receivedAmounts: object expected";
                    var key = Object.keys(message.receivedAmounts);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.receivedAmounts[key[i]]))
                            return "receivedAmounts: string{k:string} expected";
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                        return "createdAt: integer|Long expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.isActive != null && message.hasOwnProperty("isActive"))
                    if (typeof message.isActive !== "boolean")
                        return "isActive: boolean expected";
                return null;
            };
    
            /**
             * Creates a ClientPeerAddress message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeerAddress
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeerAddress} ClientPeerAddress
             */
            ClientPeerAddress.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeerAddress)
                    return object;
                var message = new $root.protocol.ClientPeerAddress();
                if (object.address != null)
                    message.address = String(object.address);
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                if (object.expectedAmounts) {
                    if (typeof object.expectedAmounts !== "object")
                        throw TypeError(".protocol.ClientPeerAddress.expectedAmounts: object expected");
                    message.expectedAmounts = {};
                    for (var keys = Object.keys(object.expectedAmounts), i = 0; i < keys.length; ++i)
                        message.expectedAmounts[keys[i]] = String(object.expectedAmounts[keys[i]]);
                }
                if (object.receivedAmounts) {
                    if (typeof object.receivedAmounts !== "object")
                        throw TypeError(".protocol.ClientPeerAddress.receivedAmounts: object expected");
                    message.receivedAmounts = {};
                    for (var keys = Object.keys(object.receivedAmounts), i = 0; i < keys.length; ++i)
                        message.receivedAmounts[keys[i]] = String(object.receivedAmounts[keys[i]]);
                }
                if (object.email != null)
                    message.email = String(object.email);
                if (object.createdAt != null)
                    if ($util.Long)
                        (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                    else if (typeof object.createdAt === "string")
                        message.createdAt = parseInt(object.createdAt, 10);
                    else if (typeof object.createdAt === "number")
                        message.createdAt = object.createdAt;
                    else if (typeof object.createdAt === "object")
                        message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                switch (object.status) {
                case "UNK_CLIENT_PEER_ADDRESS_STATUS":
                case 0:
                    message.status = 0;
                    break;
                case "WAITING_PAYMENT_CLIENT_PEER_ADDRESS_STATUS":
                case 1:
                    message.status = 1;
                    break;
                case "PAYMENT_RECEIVED_CLIENT_PEER_ADDRESS_STATUS":
                case 2:
                    message.status = 2;
                    break;
                case "EXPECTED_AMOUNT_PAID_CLIENT_PEER_ADDRESS_STATUS":
                case 3:
                    message.status = 3;
                    break;
                }
                if (object.isActive != null)
                    message.isActive = Boolean(object.isActive);
                return message;
            };
    
            /**
             * Creates a plain object from a ClientPeerAddress message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeerAddress
             * @static
             * @param {protocol.ClientPeerAddress} message ClientPeerAddress
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeerAddress.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.objects || options.defaults) {
                    object.expectedAmounts = {};
                    object.receivedAmounts = {};
                }
                if (options.defaults) {
                    object.address = "";
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.email = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAt = options.longs === String ? "0" : 0;
                    object.status = options.enums === String ? "UNK_CLIENT_PEER_ADDRESS_STATUS" : 0;
                    object.isActive = false;
                }
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                var keys2;
                if (message.expectedAmounts && (keys2 = Object.keys(message.expectedAmounts)).length) {
                    object.expectedAmounts = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.expectedAmounts[keys2[j]] = message.expectedAmounts[keys2[j]];
                }
                if (message.receivedAmounts && (keys2 = Object.keys(message.receivedAmounts)).length) {
                    object.receivedAmounts = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.receivedAmounts[keys2[j]] = message.receivedAmounts[keys2[j]];
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                    if (typeof message.createdAt === "number")
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                    else
                        object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.protocol.ClientPeerAddressStatus[message.status] : message.status;
                if (message.isActive != null && message.hasOwnProperty("isActive"))
                    object.isActive = message.isActive;
                return object;
            };
    
            /**
             * Converts this ClientPeerAddress to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeerAddress
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeerAddress.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeerAddress;
        })();
    
        protocol.CreateClientPeerRequest = (function() {
    
            /**
             * Properties of a CreateClientPeerRequest.
             * @memberof protocol
             * @interface ICreateClientPeerRequest
             * @property {string|null} [name] CreateClientPeerRequest name
             * @property {protocol.Currency|null} [currency] CreateClientPeerRequest currency
             * @property {string|null} [amountUsd] CreateClientPeerRequest amountUsd
             * @property {string|null} [email] CreateClientPeerRequest email
             * @property {Array.<protocol.FiatCurrency>|null} [additionalCurrencies] CreateClientPeerRequest additionalCurrencies
             */
    
            /**
             * Constructs a new CreateClientPeerRequest.
             * @memberof protocol
             * @classdesc Represents a CreateClientPeerRequest.
             * @implements ICreateClientPeerRequest
             * @constructor
             * @param {protocol.ICreateClientPeerRequest=} [properties] Properties to set
             */
            function CreateClientPeerRequest(properties) {
                this.additionalCurrencies = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CreateClientPeerRequest name.
             * @member {string} name
             * @memberof protocol.CreateClientPeerRequest
             * @instance
             */
            CreateClientPeerRequest.prototype.name = "";
    
            /**
             * CreateClientPeerRequest currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.CreateClientPeerRequest
             * @instance
             */
            CreateClientPeerRequest.prototype.currency = 0;
    
            /**
             * CreateClientPeerRequest amountUsd.
             * @member {string} amountUsd
             * @memberof protocol.CreateClientPeerRequest
             * @instance
             */
            CreateClientPeerRequest.prototype.amountUsd = "";
    
            /**
             * CreateClientPeerRequest email.
             * @member {string} email
             * @memberof protocol.CreateClientPeerRequest
             * @instance
             */
            CreateClientPeerRequest.prototype.email = "";
    
            /**
             * CreateClientPeerRequest additionalCurrencies.
             * @member {Array.<protocol.FiatCurrency>} additionalCurrencies
             * @memberof protocol.CreateClientPeerRequest
             * @instance
             */
            CreateClientPeerRequest.prototype.additionalCurrencies = $util.emptyArray;
    
            /**
             * Creates a new CreateClientPeerRequest instance using the specified properties.
             * @function create
             * @memberof protocol.CreateClientPeerRequest
             * @static
             * @param {protocol.ICreateClientPeerRequest=} [properties] Properties to set
             * @returns {protocol.CreateClientPeerRequest} CreateClientPeerRequest instance
             */
            CreateClientPeerRequest.create = function create(properties) {
                return new CreateClientPeerRequest(properties);
            };
    
            /**
             * Encodes the specified CreateClientPeerRequest message. Does not implicitly {@link protocol.CreateClientPeerRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.CreateClientPeerRequest
             * @static
             * @param {protocol.ICreateClientPeerRequest} message CreateClientPeerRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateClientPeerRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currency);
                if (message.amountUsd != null && Object.hasOwnProperty.call(message, "amountUsd"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.amountUsd);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.email);
                if (message.additionalCurrencies != null && message.additionalCurrencies.length) {
                    writer.uint32(/* id 5, wireType 2 =*/42).fork();
                    for (var i = 0; i < message.additionalCurrencies.length; ++i)
                        writer.int32(message.additionalCurrencies[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified CreateClientPeerRequest message, length delimited. Does not implicitly {@link protocol.CreateClientPeerRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.CreateClientPeerRequest
             * @static
             * @param {protocol.ICreateClientPeerRequest} message CreateClientPeerRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateClientPeerRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateClientPeerRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.CreateClientPeerRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.CreateClientPeerRequest} CreateClientPeerRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateClientPeerRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.CreateClientPeerRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.currency = reader.int32();
                        break;
                    case 3:
                        message.amountUsd = reader.string();
                        break;
                    case 4:
                        message.email = reader.string();
                        break;
                    case 5:
                        if (!(message.additionalCurrencies && message.additionalCurrencies.length))
                            message.additionalCurrencies = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.additionalCurrencies.push(reader.int32());
                        } else
                            message.additionalCurrencies.push(reader.int32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CreateClientPeerRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.CreateClientPeerRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.CreateClientPeerRequest} CreateClientPeerRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateClientPeerRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateClientPeerRequest message.
             * @function verify
             * @memberof protocol.CreateClientPeerRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateClientPeerRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.amountUsd != null && message.hasOwnProperty("amountUsd"))
                    if (!$util.isString(message.amountUsd))
                        return "amountUsd: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.additionalCurrencies != null && message.hasOwnProperty("additionalCurrencies")) {
                    if (!Array.isArray(message.additionalCurrencies))
                        return "additionalCurrencies: array expected";
                    for (var i = 0; i < message.additionalCurrencies.length; ++i)
                        switch (message.additionalCurrencies[i]) {
                        default:
                            return "additionalCurrencies: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                            break;
                        }
                }
                return null;
            };
    
            /**
             * Creates a CreateClientPeerRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.CreateClientPeerRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.CreateClientPeerRequest} CreateClientPeerRequest
             */
            CreateClientPeerRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.CreateClientPeerRequest)
                    return object;
                var message = new $root.protocol.CreateClientPeerRequest();
                if (object.name != null)
                    message.name = String(object.name);
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                if (object.amountUsd != null)
                    message.amountUsd = String(object.amountUsd);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.additionalCurrencies) {
                    if (!Array.isArray(object.additionalCurrencies))
                        throw TypeError(".protocol.CreateClientPeerRequest.additionalCurrencies: array expected");
                    message.additionalCurrencies = [];
                    for (var i = 0; i < object.additionalCurrencies.length; ++i)
                        switch (object.additionalCurrencies[i]) {
                        default:
                        case "UNK_FIAT_CUR":
                        case 0:
                            message.additionalCurrencies[i] = 0;
                            break;
                        case "USD_FIAT_CUR":
                        case 1:
                            message.additionalCurrencies[i] = 1;
                            break;
                        case "EUR_FIAT_CUR":
                        case 2:
                            message.additionalCurrencies[i] = 2;
                            break;
                        case "ARS_FIAT_CUR":
                        case 3:
                            message.additionalCurrencies[i] = 3;
                            break;
                        case "PEN_FIAT_CUR":
                        case 4:
                            message.additionalCurrencies[i] = 4;
                            break;
                        case "BOB_FIAT_CUR":
                        case 5:
                            message.additionalCurrencies[i] = 5;
                            break;
                        case "CLP_FIAT_CUR":
                        case 6:
                            message.additionalCurrencies[i] = 6;
                            break;
                        case "BRL_FIAT_CUR":
                        case 7:
                            message.additionalCurrencies[i] = 7;
                            break;
                        }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CreateClientPeerRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.CreateClientPeerRequest
             * @static
             * @param {protocol.CreateClientPeerRequest} message CreateClientPeerRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateClientPeerRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.additionalCurrencies = [];
                if (options.defaults) {
                    object.name = "";
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.amountUsd = "";
                    object.email = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.amountUsd != null && message.hasOwnProperty("amountUsd"))
                    object.amountUsd = message.amountUsd;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.additionalCurrencies && message.additionalCurrencies.length) {
                    object.additionalCurrencies = [];
                    for (var j = 0; j < message.additionalCurrencies.length; ++j)
                        object.additionalCurrencies[j] = options.enums === String ? $root.protocol.FiatCurrency[message.additionalCurrencies[j]] : message.additionalCurrencies[j];
                }
                return object;
            };
    
            /**
             * Converts this CreateClientPeerRequest to JSON.
             * @function toJSON
             * @memberof protocol.CreateClientPeerRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateClientPeerRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return CreateClientPeerRequest;
        })();
    
        protocol.CreateClientPeerResponse = (function() {
    
            /**
             * Properties of a CreateClientPeerResponse.
             * @memberof protocol
             * @interface ICreateClientPeerResponse
             * @property {protocol.IClientPeer|null} [peer] CreateClientPeerResponse peer
             */
    
            /**
             * Constructs a new CreateClientPeerResponse.
             * @memberof protocol
             * @classdesc Represents a CreateClientPeerResponse.
             * @implements ICreateClientPeerResponse
             * @constructor
             * @param {protocol.ICreateClientPeerResponse=} [properties] Properties to set
             */
            function CreateClientPeerResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CreateClientPeerResponse peer.
             * @member {protocol.IClientPeer|null|undefined} peer
             * @memberof protocol.CreateClientPeerResponse
             * @instance
             */
            CreateClientPeerResponse.prototype.peer = null;
    
            /**
             * Creates a new CreateClientPeerResponse instance using the specified properties.
             * @function create
             * @memberof protocol.CreateClientPeerResponse
             * @static
             * @param {protocol.ICreateClientPeerResponse=} [properties] Properties to set
             * @returns {protocol.CreateClientPeerResponse} CreateClientPeerResponse instance
             */
            CreateClientPeerResponse.create = function create(properties) {
                return new CreateClientPeerResponse(properties);
            };
    
            /**
             * Encodes the specified CreateClientPeerResponse message. Does not implicitly {@link protocol.CreateClientPeerResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.CreateClientPeerResponse
             * @static
             * @param {protocol.ICreateClientPeerResponse} message CreateClientPeerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateClientPeerResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.peer != null && Object.hasOwnProperty.call(message, "peer"))
                    $root.protocol.ClientPeer.encode(message.peer, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified CreateClientPeerResponse message, length delimited. Does not implicitly {@link protocol.CreateClientPeerResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.CreateClientPeerResponse
             * @static
             * @param {protocol.ICreateClientPeerResponse} message CreateClientPeerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateClientPeerResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateClientPeerResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.CreateClientPeerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.CreateClientPeerResponse} CreateClientPeerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateClientPeerResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.CreateClientPeerResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.peer = $root.protocol.ClientPeer.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CreateClientPeerResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.CreateClientPeerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.CreateClientPeerResponse} CreateClientPeerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateClientPeerResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateClientPeerResponse message.
             * @function verify
             * @memberof protocol.CreateClientPeerResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateClientPeerResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.peer != null && message.hasOwnProperty("peer")) {
                    var error = $root.protocol.ClientPeer.verify(message.peer);
                    if (error)
                        return "peer." + error;
                }
                return null;
            };
    
            /**
             * Creates a CreateClientPeerResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.CreateClientPeerResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.CreateClientPeerResponse} CreateClientPeerResponse
             */
            CreateClientPeerResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.CreateClientPeerResponse)
                    return object;
                var message = new $root.protocol.CreateClientPeerResponse();
                if (object.peer != null) {
                    if (typeof object.peer !== "object")
                        throw TypeError(".protocol.CreateClientPeerResponse.peer: object expected");
                    message.peer = $root.protocol.ClientPeer.fromObject(object.peer);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CreateClientPeerResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.CreateClientPeerResponse
             * @static
             * @param {protocol.CreateClientPeerResponse} message CreateClientPeerResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateClientPeerResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.peer = null;
                if (message.peer != null && message.hasOwnProperty("peer"))
                    object.peer = $root.protocol.ClientPeer.toObject(message.peer, options);
                return object;
            };
    
            /**
             * Converts this CreateClientPeerResponse to JSON.
             * @function toJSON
             * @memberof protocol.CreateClientPeerResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateClientPeerResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return CreateClientPeerResponse;
        })();
    
        protocol.ClientPeersShortListRequest = (function() {
    
            /**
             * Properties of a ClientPeersShortListRequest.
             * @memberof protocol
             * @interface IClientPeersShortListRequest
             */
    
            /**
             * Constructs a new ClientPeersShortListRequest.
             * @memberof protocol
             * @classdesc Represents a ClientPeersShortListRequest.
             * @implements IClientPeersShortListRequest
             * @constructor
             * @param {protocol.IClientPeersShortListRequest=} [properties] Properties to set
             */
            function ClientPeersShortListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new ClientPeersShortListRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeersShortListRequest
             * @static
             * @param {protocol.IClientPeersShortListRequest=} [properties] Properties to set
             * @returns {protocol.ClientPeersShortListRequest} ClientPeersShortListRequest instance
             */
            ClientPeersShortListRequest.create = function create(properties) {
                return new ClientPeersShortListRequest(properties);
            };
    
            /**
             * Encodes the specified ClientPeersShortListRequest message. Does not implicitly {@link protocol.ClientPeersShortListRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeersShortListRequest
             * @static
             * @param {protocol.IClientPeersShortListRequest} message ClientPeersShortListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeersShortListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeersShortListRequest message, length delimited. Does not implicitly {@link protocol.ClientPeersShortListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeersShortListRequest
             * @static
             * @param {protocol.IClientPeersShortListRequest} message ClientPeersShortListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeersShortListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeersShortListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeersShortListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeersShortListRequest} ClientPeersShortListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeersShortListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeersShortListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeersShortListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeersShortListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeersShortListRequest} ClientPeersShortListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeersShortListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeersShortListRequest message.
             * @function verify
             * @memberof protocol.ClientPeersShortListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeersShortListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a ClientPeersShortListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeersShortListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeersShortListRequest} ClientPeersShortListRequest
             */
            ClientPeersShortListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeersShortListRequest)
                    return object;
                return new $root.protocol.ClientPeersShortListRequest();
            };
    
            /**
             * Creates a plain object from a ClientPeersShortListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeersShortListRequest
             * @static
             * @param {protocol.ClientPeersShortListRequest} message ClientPeersShortListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeersShortListRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this ClientPeersShortListRequest to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeersShortListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeersShortListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeersShortListRequest;
        })();
    
        protocol.ClientPeersShortListResponse = (function() {
    
            /**
             * Properties of a ClientPeersShortListResponse.
             * @memberof protocol
             * @interface IClientPeersShortListResponse
             * @property {Array.<protocol.IClientPeer>|null} [peers] ClientPeersShortListResponse peers
             */
    
            /**
             * Constructs a new ClientPeersShortListResponse.
             * @memberof protocol
             * @classdesc Represents a ClientPeersShortListResponse.
             * @implements IClientPeersShortListResponse
             * @constructor
             * @param {protocol.IClientPeersShortListResponse=} [properties] Properties to set
             */
            function ClientPeersShortListResponse(properties) {
                this.peers = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientPeersShortListResponse peers.
             * @member {Array.<protocol.IClientPeer>} peers
             * @memberof protocol.ClientPeersShortListResponse
             * @instance
             */
            ClientPeersShortListResponse.prototype.peers = $util.emptyArray;
    
            /**
             * Creates a new ClientPeersShortListResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeersShortListResponse
             * @static
             * @param {protocol.IClientPeersShortListResponse=} [properties] Properties to set
             * @returns {protocol.ClientPeersShortListResponse} ClientPeersShortListResponse instance
             */
            ClientPeersShortListResponse.create = function create(properties) {
                return new ClientPeersShortListResponse(properties);
            };
    
            /**
             * Encodes the specified ClientPeersShortListResponse message. Does not implicitly {@link protocol.ClientPeersShortListResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeersShortListResponse
             * @static
             * @param {protocol.IClientPeersShortListResponse} message ClientPeersShortListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeersShortListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.peers != null && message.peers.length)
                    for (var i = 0; i < message.peers.length; ++i)
                        $root.protocol.ClientPeer.encode(message.peers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeersShortListResponse message, length delimited. Does not implicitly {@link protocol.ClientPeersShortListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeersShortListResponse
             * @static
             * @param {protocol.IClientPeersShortListResponse} message ClientPeersShortListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeersShortListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeersShortListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeersShortListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeersShortListResponse} ClientPeersShortListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeersShortListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeersShortListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.peers && message.peers.length))
                            message.peers = [];
                        message.peers.push($root.protocol.ClientPeer.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeersShortListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeersShortListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeersShortListResponse} ClientPeersShortListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeersShortListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeersShortListResponse message.
             * @function verify
             * @memberof protocol.ClientPeersShortListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeersShortListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.peers != null && message.hasOwnProperty("peers")) {
                    if (!Array.isArray(message.peers))
                        return "peers: array expected";
                    for (var i = 0; i < message.peers.length; ++i) {
                        var error = $root.protocol.ClientPeer.verify(message.peers[i]);
                        if (error)
                            return "peers." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ClientPeersShortListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeersShortListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeersShortListResponse} ClientPeersShortListResponse
             */
            ClientPeersShortListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeersShortListResponse)
                    return object;
                var message = new $root.protocol.ClientPeersShortListResponse();
                if (object.peers) {
                    if (!Array.isArray(object.peers))
                        throw TypeError(".protocol.ClientPeersShortListResponse.peers: array expected");
                    message.peers = [];
                    for (var i = 0; i < object.peers.length; ++i) {
                        if (typeof object.peers[i] !== "object")
                            throw TypeError(".protocol.ClientPeersShortListResponse.peers: object expected");
                        message.peers[i] = $root.protocol.ClientPeer.fromObject(object.peers[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientPeersShortListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeersShortListResponse
             * @static
             * @param {protocol.ClientPeersShortListResponse} message ClientPeersShortListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeersShortListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.peers = [];
                if (message.peers && message.peers.length) {
                    object.peers = [];
                    for (var j = 0; j < message.peers.length; ++j)
                        object.peers[j] = $root.protocol.ClientPeer.toObject(message.peers[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ClientPeersShortListResponse to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeersShortListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeersShortListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeersShortListResponse;
        })();
    
        protocol.GenerateNewClientPeerAddressRequest = (function() {
    
            /**
             * Properties of a GenerateNewClientPeerAddressRequest.
             * @memberof protocol
             * @interface IGenerateNewClientPeerAddressRequest
             * @property {number|null} [peerId] GenerateNewClientPeerAddressRequest peerId
             * @property {protocol.Currency|null} [currency] GenerateNewClientPeerAddressRequest currency
             * @property {string|null} [amountUsd] GenerateNewClientPeerAddressRequest amountUsd
             * @property {string|null} [email] GenerateNewClientPeerAddressRequest email
             */
    
            /**
             * Constructs a new GenerateNewClientPeerAddressRequest.
             * @memberof protocol
             * @classdesc Represents a GenerateNewClientPeerAddressRequest.
             * @implements IGenerateNewClientPeerAddressRequest
             * @constructor
             * @param {protocol.IGenerateNewClientPeerAddressRequest=} [properties] Properties to set
             */
            function GenerateNewClientPeerAddressRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * GenerateNewClientPeerAddressRequest peerId.
             * @member {number} peerId
             * @memberof protocol.GenerateNewClientPeerAddressRequest
             * @instance
             */
            GenerateNewClientPeerAddressRequest.prototype.peerId = 0;
    
            /**
             * GenerateNewClientPeerAddressRequest currency.
             * @member {protocol.Currency} currency
             * @memberof protocol.GenerateNewClientPeerAddressRequest
             * @instance
             */
            GenerateNewClientPeerAddressRequest.prototype.currency = 0;
    
            /**
             * GenerateNewClientPeerAddressRequest amountUsd.
             * @member {string} amountUsd
             * @memberof protocol.GenerateNewClientPeerAddressRequest
             * @instance
             */
            GenerateNewClientPeerAddressRequest.prototype.amountUsd = "";
    
            /**
             * GenerateNewClientPeerAddressRequest email.
             * @member {string} email
             * @memberof protocol.GenerateNewClientPeerAddressRequest
             * @instance
             */
            GenerateNewClientPeerAddressRequest.prototype.email = "";
    
            /**
             * Creates a new GenerateNewClientPeerAddressRequest instance using the specified properties.
             * @function create
             * @memberof protocol.GenerateNewClientPeerAddressRequest
             * @static
             * @param {protocol.IGenerateNewClientPeerAddressRequest=} [properties] Properties to set
             * @returns {protocol.GenerateNewClientPeerAddressRequest} GenerateNewClientPeerAddressRequest instance
             */
            GenerateNewClientPeerAddressRequest.create = function create(properties) {
                return new GenerateNewClientPeerAddressRequest(properties);
            };
    
            /**
             * Encodes the specified GenerateNewClientPeerAddressRequest message. Does not implicitly {@link protocol.GenerateNewClientPeerAddressRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.GenerateNewClientPeerAddressRequest
             * @static
             * @param {protocol.IGenerateNewClientPeerAddressRequest} message GenerateNewClientPeerAddressRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GenerateNewClientPeerAddressRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.peerId != null && Object.hasOwnProperty.call(message, "peerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.peerId);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currency);
                if (message.amountUsd != null && Object.hasOwnProperty.call(message, "amountUsd"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.amountUsd);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.email);
                return writer;
            };
    
            /**
             * Encodes the specified GenerateNewClientPeerAddressRequest message, length delimited. Does not implicitly {@link protocol.GenerateNewClientPeerAddressRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.GenerateNewClientPeerAddressRequest
             * @static
             * @param {protocol.IGenerateNewClientPeerAddressRequest} message GenerateNewClientPeerAddressRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GenerateNewClientPeerAddressRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a GenerateNewClientPeerAddressRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.GenerateNewClientPeerAddressRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.GenerateNewClientPeerAddressRequest} GenerateNewClientPeerAddressRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GenerateNewClientPeerAddressRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.GenerateNewClientPeerAddressRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.peerId = reader.uint32();
                        break;
                    case 2:
                        message.currency = reader.int32();
                        break;
                    case 3:
                        message.amountUsd = reader.string();
                        break;
                    case 4:
                        message.email = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a GenerateNewClientPeerAddressRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.GenerateNewClientPeerAddressRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.GenerateNewClientPeerAddressRequest} GenerateNewClientPeerAddressRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GenerateNewClientPeerAddressRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a GenerateNewClientPeerAddressRequest message.
             * @function verify
             * @memberof protocol.GenerateNewClientPeerAddressRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GenerateNewClientPeerAddressRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.peerId != null && message.hasOwnProperty("peerId"))
                    if (!$util.isInteger(message.peerId))
                        return "peerId: integer expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.amountUsd != null && message.hasOwnProperty("amountUsd"))
                    if (!$util.isString(message.amountUsd))
                        return "amountUsd: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                return null;
            };
    
            /**
             * Creates a GenerateNewClientPeerAddressRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.GenerateNewClientPeerAddressRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.GenerateNewClientPeerAddressRequest} GenerateNewClientPeerAddressRequest
             */
            GenerateNewClientPeerAddressRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.GenerateNewClientPeerAddressRequest)
                    return object;
                var message = new $root.protocol.GenerateNewClientPeerAddressRequest();
                if (object.peerId != null)
                    message.peerId = object.peerId >>> 0;
                switch (object.currency) {
                case "UNK_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "BTC_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "ETH_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "TRX_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "ERC20_USDT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "TRC20_USDT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                }
                if (object.amountUsd != null)
                    message.amountUsd = String(object.amountUsd);
                if (object.email != null)
                    message.email = String(object.email);
                return message;
            };
    
            /**
             * Creates a plain object from a GenerateNewClientPeerAddressRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.GenerateNewClientPeerAddressRequest
             * @static
             * @param {protocol.GenerateNewClientPeerAddressRequest} message GenerateNewClientPeerAddressRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GenerateNewClientPeerAddressRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.peerId = 0;
                    object.currency = options.enums === String ? "UNK_CUR" : 0;
                    object.amountUsd = "";
                    object.email = "";
                }
                if (message.peerId != null && message.hasOwnProperty("peerId"))
                    object.peerId = message.peerId;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.Currency[message.currency] : message.currency;
                if (message.amountUsd != null && message.hasOwnProperty("amountUsd"))
                    object.amountUsd = message.amountUsd;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                return object;
            };
    
            /**
             * Converts this GenerateNewClientPeerAddressRequest to JSON.
             * @function toJSON
             * @memberof protocol.GenerateNewClientPeerAddressRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GenerateNewClientPeerAddressRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return GenerateNewClientPeerAddressRequest;
        })();
    
        protocol.GenerateNewClientPeerAddressResponse = (function() {
    
            /**
             * Properties of a GenerateNewClientPeerAddressResponse.
             * @memberof protocol
             * @interface IGenerateNewClientPeerAddressResponse
             * @property {protocol.IClientPeerAddress|null} [address] GenerateNewClientPeerAddressResponse address
             */
    
            /**
             * Constructs a new GenerateNewClientPeerAddressResponse.
             * @memberof protocol
             * @classdesc Represents a GenerateNewClientPeerAddressResponse.
             * @implements IGenerateNewClientPeerAddressResponse
             * @constructor
             * @param {protocol.IGenerateNewClientPeerAddressResponse=} [properties] Properties to set
             */
            function GenerateNewClientPeerAddressResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * GenerateNewClientPeerAddressResponse address.
             * @member {protocol.IClientPeerAddress|null|undefined} address
             * @memberof protocol.GenerateNewClientPeerAddressResponse
             * @instance
             */
            GenerateNewClientPeerAddressResponse.prototype.address = null;
    
            /**
             * Creates a new GenerateNewClientPeerAddressResponse instance using the specified properties.
             * @function create
             * @memberof protocol.GenerateNewClientPeerAddressResponse
             * @static
             * @param {protocol.IGenerateNewClientPeerAddressResponse=} [properties] Properties to set
             * @returns {protocol.GenerateNewClientPeerAddressResponse} GenerateNewClientPeerAddressResponse instance
             */
            GenerateNewClientPeerAddressResponse.create = function create(properties) {
                return new GenerateNewClientPeerAddressResponse(properties);
            };
    
            /**
             * Encodes the specified GenerateNewClientPeerAddressResponse message. Does not implicitly {@link protocol.GenerateNewClientPeerAddressResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.GenerateNewClientPeerAddressResponse
             * @static
             * @param {protocol.IGenerateNewClientPeerAddressResponse} message GenerateNewClientPeerAddressResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GenerateNewClientPeerAddressResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    $root.protocol.ClientPeerAddress.encode(message.address, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified GenerateNewClientPeerAddressResponse message, length delimited. Does not implicitly {@link protocol.GenerateNewClientPeerAddressResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.GenerateNewClientPeerAddressResponse
             * @static
             * @param {protocol.IGenerateNewClientPeerAddressResponse} message GenerateNewClientPeerAddressResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GenerateNewClientPeerAddressResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a GenerateNewClientPeerAddressResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.GenerateNewClientPeerAddressResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.GenerateNewClientPeerAddressResponse} GenerateNewClientPeerAddressResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GenerateNewClientPeerAddressResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.GenerateNewClientPeerAddressResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.address = $root.protocol.ClientPeerAddress.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a GenerateNewClientPeerAddressResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.GenerateNewClientPeerAddressResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.GenerateNewClientPeerAddressResponse} GenerateNewClientPeerAddressResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GenerateNewClientPeerAddressResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a GenerateNewClientPeerAddressResponse message.
             * @function verify
             * @memberof protocol.GenerateNewClientPeerAddressResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GenerateNewClientPeerAddressResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address")) {
                    var error = $root.protocol.ClientPeerAddress.verify(message.address);
                    if (error)
                        return "address." + error;
                }
                return null;
            };
    
            /**
             * Creates a GenerateNewClientPeerAddressResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.GenerateNewClientPeerAddressResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.GenerateNewClientPeerAddressResponse} GenerateNewClientPeerAddressResponse
             */
            GenerateNewClientPeerAddressResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.GenerateNewClientPeerAddressResponse)
                    return object;
                var message = new $root.protocol.GenerateNewClientPeerAddressResponse();
                if (object.address != null) {
                    if (typeof object.address !== "object")
                        throw TypeError(".protocol.GenerateNewClientPeerAddressResponse.address: object expected");
                    message.address = $root.protocol.ClientPeerAddress.fromObject(object.address);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a GenerateNewClientPeerAddressResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.GenerateNewClientPeerAddressResponse
             * @static
             * @param {protocol.GenerateNewClientPeerAddressResponse} message GenerateNewClientPeerAddressResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GenerateNewClientPeerAddressResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.address = null;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = $root.protocol.ClientPeerAddress.toObject(message.address, options);
                return object;
            };
    
            /**
             * Converts this GenerateNewClientPeerAddressResponse to JSON.
             * @function toJSON
             * @memberof protocol.GenerateNewClientPeerAddressResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GenerateNewClientPeerAddressResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return GenerateNewClientPeerAddressResponse;
        })();
    
        protocol.RegenerateClientPeerAddressRequest = (function() {
    
            /**
             * Properties of a RegenerateClientPeerAddressRequest.
             * @memberof protocol
             * @interface IRegenerateClientPeerAddressRequest
             * @property {number|null} [peerId] RegenerateClientPeerAddressRequest peerId
             * @property {string|null} [oldAddress] RegenerateClientPeerAddressRequest oldAddress
             * @property {string|null} [amountUsd] RegenerateClientPeerAddressRequest amountUsd
             * @property {string|null} [email] RegenerateClientPeerAddressRequest email
             */
    
            /**
             * Constructs a new RegenerateClientPeerAddressRequest.
             * @memberof protocol
             * @classdesc Represents a RegenerateClientPeerAddressRequest.
             * @implements IRegenerateClientPeerAddressRequest
             * @constructor
             * @param {protocol.IRegenerateClientPeerAddressRequest=} [properties] Properties to set
             */
            function RegenerateClientPeerAddressRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RegenerateClientPeerAddressRequest peerId.
             * @member {number} peerId
             * @memberof protocol.RegenerateClientPeerAddressRequest
             * @instance
             */
            RegenerateClientPeerAddressRequest.prototype.peerId = 0;
    
            /**
             * RegenerateClientPeerAddressRequest oldAddress.
             * @member {string} oldAddress
             * @memberof protocol.RegenerateClientPeerAddressRequest
             * @instance
             */
            RegenerateClientPeerAddressRequest.prototype.oldAddress = "";
    
            /**
             * RegenerateClientPeerAddressRequest amountUsd.
             * @member {string} amountUsd
             * @memberof protocol.RegenerateClientPeerAddressRequest
             * @instance
             */
            RegenerateClientPeerAddressRequest.prototype.amountUsd = "";
    
            /**
             * RegenerateClientPeerAddressRequest email.
             * @member {string} email
             * @memberof protocol.RegenerateClientPeerAddressRequest
             * @instance
             */
            RegenerateClientPeerAddressRequest.prototype.email = "";
    
            /**
             * Creates a new RegenerateClientPeerAddressRequest instance using the specified properties.
             * @function create
             * @memberof protocol.RegenerateClientPeerAddressRequest
             * @static
             * @param {protocol.IRegenerateClientPeerAddressRequest=} [properties] Properties to set
             * @returns {protocol.RegenerateClientPeerAddressRequest} RegenerateClientPeerAddressRequest instance
             */
            RegenerateClientPeerAddressRequest.create = function create(properties) {
                return new RegenerateClientPeerAddressRequest(properties);
            };
    
            /**
             * Encodes the specified RegenerateClientPeerAddressRequest message. Does not implicitly {@link protocol.RegenerateClientPeerAddressRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.RegenerateClientPeerAddressRequest
             * @static
             * @param {protocol.IRegenerateClientPeerAddressRequest} message RegenerateClientPeerAddressRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegenerateClientPeerAddressRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.peerId != null && Object.hasOwnProperty.call(message, "peerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.peerId);
                if (message.oldAddress != null && Object.hasOwnProperty.call(message, "oldAddress"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.oldAddress);
                if (message.amountUsd != null && Object.hasOwnProperty.call(message, "amountUsd"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.amountUsd);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.email);
                return writer;
            };
    
            /**
             * Encodes the specified RegenerateClientPeerAddressRequest message, length delimited. Does not implicitly {@link protocol.RegenerateClientPeerAddressRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.RegenerateClientPeerAddressRequest
             * @static
             * @param {protocol.IRegenerateClientPeerAddressRequest} message RegenerateClientPeerAddressRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegenerateClientPeerAddressRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RegenerateClientPeerAddressRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.RegenerateClientPeerAddressRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.RegenerateClientPeerAddressRequest} RegenerateClientPeerAddressRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegenerateClientPeerAddressRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.RegenerateClientPeerAddressRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.peerId = reader.uint32();
                        break;
                    case 2:
                        message.oldAddress = reader.string();
                        break;
                    case 3:
                        message.amountUsd = reader.string();
                        break;
                    case 4:
                        message.email = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RegenerateClientPeerAddressRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.RegenerateClientPeerAddressRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.RegenerateClientPeerAddressRequest} RegenerateClientPeerAddressRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegenerateClientPeerAddressRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RegenerateClientPeerAddressRequest message.
             * @function verify
             * @memberof protocol.RegenerateClientPeerAddressRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RegenerateClientPeerAddressRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.peerId != null && message.hasOwnProperty("peerId"))
                    if (!$util.isInteger(message.peerId))
                        return "peerId: integer expected";
                if (message.oldAddress != null && message.hasOwnProperty("oldAddress"))
                    if (!$util.isString(message.oldAddress))
                        return "oldAddress: string expected";
                if (message.amountUsd != null && message.hasOwnProperty("amountUsd"))
                    if (!$util.isString(message.amountUsd))
                        return "amountUsd: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                return null;
            };
    
            /**
             * Creates a RegenerateClientPeerAddressRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.RegenerateClientPeerAddressRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.RegenerateClientPeerAddressRequest} RegenerateClientPeerAddressRequest
             */
            RegenerateClientPeerAddressRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.RegenerateClientPeerAddressRequest)
                    return object;
                var message = new $root.protocol.RegenerateClientPeerAddressRequest();
                if (object.peerId != null)
                    message.peerId = object.peerId >>> 0;
                if (object.oldAddress != null)
                    message.oldAddress = String(object.oldAddress);
                if (object.amountUsd != null)
                    message.amountUsd = String(object.amountUsd);
                if (object.email != null)
                    message.email = String(object.email);
                return message;
            };
    
            /**
             * Creates a plain object from a RegenerateClientPeerAddressRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.RegenerateClientPeerAddressRequest
             * @static
             * @param {protocol.RegenerateClientPeerAddressRequest} message RegenerateClientPeerAddressRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RegenerateClientPeerAddressRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.peerId = 0;
                    object.oldAddress = "";
                    object.amountUsd = "";
                    object.email = "";
                }
                if (message.peerId != null && message.hasOwnProperty("peerId"))
                    object.peerId = message.peerId;
                if (message.oldAddress != null && message.hasOwnProperty("oldAddress"))
                    object.oldAddress = message.oldAddress;
                if (message.amountUsd != null && message.hasOwnProperty("amountUsd"))
                    object.amountUsd = message.amountUsd;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                return object;
            };
    
            /**
             * Converts this RegenerateClientPeerAddressRequest to JSON.
             * @function toJSON
             * @memberof protocol.RegenerateClientPeerAddressRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RegenerateClientPeerAddressRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return RegenerateClientPeerAddressRequest;
        })();
    
        protocol.RegenerateClientPeerAddressResponse = (function() {
    
            /**
             * Properties of a RegenerateClientPeerAddressResponse.
             * @memberof protocol
             * @interface IRegenerateClientPeerAddressResponse
             * @property {protocol.IClientPeerAddress|null} [address] RegenerateClientPeerAddressResponse address
             */
    
            /**
             * Constructs a new RegenerateClientPeerAddressResponse.
             * @memberof protocol
             * @classdesc Represents a RegenerateClientPeerAddressResponse.
             * @implements IRegenerateClientPeerAddressResponse
             * @constructor
             * @param {protocol.IRegenerateClientPeerAddressResponse=} [properties] Properties to set
             */
            function RegenerateClientPeerAddressResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RegenerateClientPeerAddressResponse address.
             * @member {protocol.IClientPeerAddress|null|undefined} address
             * @memberof protocol.RegenerateClientPeerAddressResponse
             * @instance
             */
            RegenerateClientPeerAddressResponse.prototype.address = null;
    
            /**
             * Creates a new RegenerateClientPeerAddressResponse instance using the specified properties.
             * @function create
             * @memberof protocol.RegenerateClientPeerAddressResponse
             * @static
             * @param {protocol.IRegenerateClientPeerAddressResponse=} [properties] Properties to set
             * @returns {protocol.RegenerateClientPeerAddressResponse} RegenerateClientPeerAddressResponse instance
             */
            RegenerateClientPeerAddressResponse.create = function create(properties) {
                return new RegenerateClientPeerAddressResponse(properties);
            };
    
            /**
             * Encodes the specified RegenerateClientPeerAddressResponse message. Does not implicitly {@link protocol.RegenerateClientPeerAddressResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.RegenerateClientPeerAddressResponse
             * @static
             * @param {protocol.IRegenerateClientPeerAddressResponse} message RegenerateClientPeerAddressResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegenerateClientPeerAddressResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    $root.protocol.ClientPeerAddress.encode(message.address, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified RegenerateClientPeerAddressResponse message, length delimited. Does not implicitly {@link protocol.RegenerateClientPeerAddressResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.RegenerateClientPeerAddressResponse
             * @static
             * @param {protocol.IRegenerateClientPeerAddressResponse} message RegenerateClientPeerAddressResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegenerateClientPeerAddressResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RegenerateClientPeerAddressResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.RegenerateClientPeerAddressResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.RegenerateClientPeerAddressResponse} RegenerateClientPeerAddressResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegenerateClientPeerAddressResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.RegenerateClientPeerAddressResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.address = $root.protocol.ClientPeerAddress.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RegenerateClientPeerAddressResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.RegenerateClientPeerAddressResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.RegenerateClientPeerAddressResponse} RegenerateClientPeerAddressResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegenerateClientPeerAddressResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RegenerateClientPeerAddressResponse message.
             * @function verify
             * @memberof protocol.RegenerateClientPeerAddressResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RegenerateClientPeerAddressResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address")) {
                    var error = $root.protocol.ClientPeerAddress.verify(message.address);
                    if (error)
                        return "address." + error;
                }
                return null;
            };
    
            /**
             * Creates a RegenerateClientPeerAddressResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.RegenerateClientPeerAddressResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.RegenerateClientPeerAddressResponse} RegenerateClientPeerAddressResponse
             */
            RegenerateClientPeerAddressResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.RegenerateClientPeerAddressResponse)
                    return object;
                var message = new $root.protocol.RegenerateClientPeerAddressResponse();
                if (object.address != null) {
                    if (typeof object.address !== "object")
                        throw TypeError(".protocol.RegenerateClientPeerAddressResponse.address: object expected");
                    message.address = $root.protocol.ClientPeerAddress.fromObject(object.address);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a RegenerateClientPeerAddressResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.RegenerateClientPeerAddressResponse
             * @static
             * @param {protocol.RegenerateClientPeerAddressResponse} message RegenerateClientPeerAddressResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RegenerateClientPeerAddressResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.address = null;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = $root.protocol.ClientPeerAddress.toObject(message.address, options);
                return object;
            };
    
            /**
             * Converts this RegenerateClientPeerAddressResponse to JSON.
             * @function toJSON
             * @memberof protocol.RegenerateClientPeerAddressResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RegenerateClientPeerAddressResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return RegenerateClientPeerAddressResponse;
        })();
    
        protocol.ClientPeersListRequest = (function() {
    
            /**
             * Properties of a ClientPeersListRequest.
             * @memberof protocol
             * @interface IClientPeersListRequest
             * @property {string|null} [filterName] ClientPeersListRequest filterName
             * @property {protocol.SortBy|null} [sortBy] ClientPeersListRequest sortBy
             * @property {boolean|null} [sortDesc] ClientPeersListRequest sortDesc
             * @property {Long|null} [createdAtFrom] ClientPeersListRequest createdAtFrom
             * @property {Long|null} [createdAtTo] ClientPeersListRequest createdAtTo
             * @property {Long|null} [limit] ClientPeersListRequest limit
             * @property {Long|null} [offset] ClientPeersListRequest offset
             */
    
            /**
             * Constructs a new ClientPeersListRequest.
             * @memberof protocol
             * @classdesc Represents a ClientPeersListRequest.
             * @implements IClientPeersListRequest
             * @constructor
             * @param {protocol.IClientPeersListRequest=} [properties] Properties to set
             */
            function ClientPeersListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientPeersListRequest filterName.
             * @member {string} filterName
             * @memberof protocol.ClientPeersListRequest
             * @instance
             */
            ClientPeersListRequest.prototype.filterName = "";
    
            /**
             * ClientPeersListRequest sortBy.
             * @member {protocol.SortBy} sortBy
             * @memberof protocol.ClientPeersListRequest
             * @instance
             */
            ClientPeersListRequest.prototype.sortBy = 0;
    
            /**
             * ClientPeersListRequest sortDesc.
             * @member {boolean} sortDesc
             * @memberof protocol.ClientPeersListRequest
             * @instance
             */
            ClientPeersListRequest.prototype.sortDesc = false;
    
            /**
             * ClientPeersListRequest createdAtFrom.
             * @member {Long} createdAtFrom
             * @memberof protocol.ClientPeersListRequest
             * @instance
             */
            ClientPeersListRequest.prototype.createdAtFrom = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeersListRequest createdAtTo.
             * @member {Long} createdAtTo
             * @memberof protocol.ClientPeersListRequest
             * @instance
             */
            ClientPeersListRequest.prototype.createdAtTo = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeersListRequest limit.
             * @member {Long} limit
             * @memberof protocol.ClientPeersListRequest
             * @instance
             */
            ClientPeersListRequest.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeersListRequest offset.
             * @member {Long} offset
             * @memberof protocol.ClientPeersListRequest
             * @instance
             */
            ClientPeersListRequest.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new ClientPeersListRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeersListRequest
             * @static
             * @param {protocol.IClientPeersListRequest=} [properties] Properties to set
             * @returns {protocol.ClientPeersListRequest} ClientPeersListRequest instance
             */
            ClientPeersListRequest.create = function create(properties) {
                return new ClientPeersListRequest(properties);
            };
    
            /**
             * Encodes the specified ClientPeersListRequest message. Does not implicitly {@link protocol.ClientPeersListRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeersListRequest
             * @static
             * @param {protocol.IClientPeersListRequest} message ClientPeersListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeersListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.filterName != null && Object.hasOwnProperty.call(message, "filterName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.filterName);
                if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.sortBy);
                if (message.sortDesc != null && Object.hasOwnProperty.call(message, "sortDesc"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.sortDesc);
                if (message.createdAtFrom != null && Object.hasOwnProperty.call(message, "createdAtFrom"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.createdAtFrom);
                if (message.createdAtTo != null && Object.hasOwnProperty.call(message, "createdAtTo"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.createdAtTo);
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.limit);
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.offset);
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeersListRequest message, length delimited. Does not implicitly {@link protocol.ClientPeersListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeersListRequest
             * @static
             * @param {protocol.IClientPeersListRequest} message ClientPeersListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeersListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeersListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeersListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeersListRequest} ClientPeersListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeersListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeersListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.filterName = reader.string();
                        break;
                    case 2:
                        message.sortBy = reader.int32();
                        break;
                    case 3:
                        message.sortDesc = reader.bool();
                        break;
                    case 4:
                        message.createdAtFrom = reader.int64();
                        break;
                    case 5:
                        message.createdAtTo = reader.int64();
                        break;
                    case 6:
                        message.limit = reader.int64();
                        break;
                    case 7:
                        message.offset = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeersListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeersListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeersListRequest} ClientPeersListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeersListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeersListRequest message.
             * @function verify
             * @memberof protocol.ClientPeersListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeersListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.filterName != null && message.hasOwnProperty("filterName"))
                    if (!$util.isString(message.filterName))
                        return "filterName: string expected";
                if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                    switch (message.sortBy) {
                    default:
                        return "sortBy: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                        break;
                    }
                if (message.sortDesc != null && message.hasOwnProperty("sortDesc"))
                    if (typeof message.sortDesc !== "boolean")
                        return "sortDesc: boolean expected";
                if (message.createdAtFrom != null && message.hasOwnProperty("createdAtFrom"))
                    if (!$util.isInteger(message.createdAtFrom) && !(message.createdAtFrom && $util.isInteger(message.createdAtFrom.low) && $util.isInteger(message.createdAtFrom.high)))
                        return "createdAtFrom: integer|Long expected";
                if (message.createdAtTo != null && message.hasOwnProperty("createdAtTo"))
                    if (!$util.isInteger(message.createdAtTo) && !(message.createdAtTo && $util.isInteger(message.createdAtTo.low) && $util.isInteger(message.createdAtTo.high)))
                        return "createdAtTo: integer|Long expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                        return "limit: integer|Long expected";
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset) && !(message.offset && $util.isInteger(message.offset.low) && $util.isInteger(message.offset.high)))
                        return "offset: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a ClientPeersListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeersListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeersListRequest} ClientPeersListRequest
             */
            ClientPeersListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeersListRequest)
                    return object;
                var message = new $root.protocol.ClientPeersListRequest();
                if (object.filterName != null)
                    message.filterName = String(object.filterName);
                switch (object.sortBy) {
                case "UNK_SORT_BY":
                case 0:
                    message.sortBy = 0;
                    break;
                case "ID_SORT_BY":
                case 1:
                    message.sortBy = 1;
                    break;
                case "DATE_SORT_BY":
                case 2:
                    message.sortBy = 2;
                    break;
                case "CURRENCY_SORT_BY":
                case 3:
                    message.sortBy = 3;
                    break;
                case "TAGS_SORT_BY":
                case 4:
                    message.sortBy = 4;
                    break;
                case "TX_HASH_SORT_BY":
                case 5:
                    message.sortBy = 5;
                    break;
                case "AMOUNT_SORT_BY":
                case 6:
                    message.sortBy = 6;
                    break;
                case "NAME_SORT_BY":
                case 7:
                    message.sortBy = 7;
                    break;
                case "LAST_AMOUNT_SORT_BY":
                case 8:
                    message.sortBy = 8;
                    break;
                case "STATUS_SORT_BY":
                case 9:
                    message.sortBy = 9;
                    break;
                case "ENTITIES_COUNT_SORT_BY":
                case 10:
                    message.sortBy = 10;
                    break;
                case "ADDRESS_SORT_BY":
                case 11:
                    message.sortBy = 11;
                    break;
                case "RISK_SCORE_SORT_BY":
                case 12:
                    message.sortBy = 12;
                    break;
                case "BALANCE_SORT_BY":
                case 13:
                    message.sortBy = 13;
                    break;
                }
                if (object.sortDesc != null)
                    message.sortDesc = Boolean(object.sortDesc);
                if (object.createdAtFrom != null)
                    if ($util.Long)
                        (message.createdAtFrom = $util.Long.fromValue(object.createdAtFrom)).unsigned = false;
                    else if (typeof object.createdAtFrom === "string")
                        message.createdAtFrom = parseInt(object.createdAtFrom, 10);
                    else if (typeof object.createdAtFrom === "number")
                        message.createdAtFrom = object.createdAtFrom;
                    else if (typeof object.createdAtFrom === "object")
                        message.createdAtFrom = new $util.LongBits(object.createdAtFrom.low >>> 0, object.createdAtFrom.high >>> 0).toNumber();
                if (object.createdAtTo != null)
                    if ($util.Long)
                        (message.createdAtTo = $util.Long.fromValue(object.createdAtTo)).unsigned = false;
                    else if (typeof object.createdAtTo === "string")
                        message.createdAtTo = parseInt(object.createdAtTo, 10);
                    else if (typeof object.createdAtTo === "number")
                        message.createdAtTo = object.createdAtTo;
                    else if (typeof object.createdAtTo === "object")
                        message.createdAtTo = new $util.LongBits(object.createdAtTo.low >>> 0, object.createdAtTo.high >>> 0).toNumber();
                if (object.limit != null)
                    if ($util.Long)
                        (message.limit = $util.Long.fromValue(object.limit)).unsigned = false;
                    else if (typeof object.limit === "string")
                        message.limit = parseInt(object.limit, 10);
                    else if (typeof object.limit === "number")
                        message.limit = object.limit;
                    else if (typeof object.limit === "object")
                        message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber();
                if (object.offset != null)
                    if ($util.Long)
                        (message.offset = $util.Long.fromValue(object.offset)).unsigned = false;
                    else if (typeof object.offset === "string")
                        message.offset = parseInt(object.offset, 10);
                    else if (typeof object.offset === "number")
                        message.offset = object.offset;
                    else if (typeof object.offset === "object")
                        message.offset = new $util.LongBits(object.offset.low >>> 0, object.offset.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a ClientPeersListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeersListRequest
             * @static
             * @param {protocol.ClientPeersListRequest} message ClientPeersListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeersListRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.filterName = "";
                    object.sortBy = options.enums === String ? "UNK_SORT_BY" : 0;
                    object.sortDesc = false;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.createdAtFrom = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAtFrom = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.createdAtTo = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAtTo = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.limit = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.offset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.offset = options.longs === String ? "0" : 0;
                }
                if (message.filterName != null && message.hasOwnProperty("filterName"))
                    object.filterName = message.filterName;
                if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                    object.sortBy = options.enums === String ? $root.protocol.SortBy[message.sortBy] : message.sortBy;
                if (message.sortDesc != null && message.hasOwnProperty("sortDesc"))
                    object.sortDesc = message.sortDesc;
                if (message.createdAtFrom != null && message.hasOwnProperty("createdAtFrom"))
                    if (typeof message.createdAtFrom === "number")
                        object.createdAtFrom = options.longs === String ? String(message.createdAtFrom) : message.createdAtFrom;
                    else
                        object.createdAtFrom = options.longs === String ? $util.Long.prototype.toString.call(message.createdAtFrom) : options.longs === Number ? new $util.LongBits(message.createdAtFrom.low >>> 0, message.createdAtFrom.high >>> 0).toNumber() : message.createdAtFrom;
                if (message.createdAtTo != null && message.hasOwnProperty("createdAtTo"))
                    if (typeof message.createdAtTo === "number")
                        object.createdAtTo = options.longs === String ? String(message.createdAtTo) : message.createdAtTo;
                    else
                        object.createdAtTo = options.longs === String ? $util.Long.prototype.toString.call(message.createdAtTo) : options.longs === Number ? new $util.LongBits(message.createdAtTo.low >>> 0, message.createdAtTo.high >>> 0).toNumber() : message.createdAtTo;
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (typeof message.limit === "number")
                        object.limit = options.longs === String ? String(message.limit) : message.limit;
                    else
                        object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber() : message.limit;
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (typeof message.offset === "number")
                        object.offset = options.longs === String ? String(message.offset) : message.offset;
                    else
                        object.offset = options.longs === String ? $util.Long.prototype.toString.call(message.offset) : options.longs === Number ? new $util.LongBits(message.offset.low >>> 0, message.offset.high >>> 0).toNumber() : message.offset;
                return object;
            };
    
            /**
             * Converts this ClientPeersListRequest to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeersListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeersListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeersListRequest;
        })();
    
        protocol.ClientPeersListResponse = (function() {
    
            /**
             * Properties of a ClientPeersListResponse.
             * @memberof protocol
             * @interface IClientPeersListResponse
             * @property {Long|null} [totalCount] ClientPeersListResponse totalCount
             * @property {Long|null} [totalPages] ClientPeersListResponse totalPages
             * @property {Array.<protocol.IClientPeerDetails>|null} [peers] ClientPeersListResponse peers
             * @property {string|null} [totalExpectedAmountUsd] ClientPeersListResponse totalExpectedAmountUsd
             * @property {string|null} [totalReceivedAmountUsd] ClientPeersListResponse totalReceivedAmountUsd
             */
    
            /**
             * Constructs a new ClientPeersListResponse.
             * @memberof protocol
             * @classdesc Represents a ClientPeersListResponse.
             * @implements IClientPeersListResponse
             * @constructor
             * @param {protocol.IClientPeersListResponse=} [properties] Properties to set
             */
            function ClientPeersListResponse(properties) {
                this.peers = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientPeersListResponse totalCount.
             * @member {Long} totalCount
             * @memberof protocol.ClientPeersListResponse
             * @instance
             */
            ClientPeersListResponse.prototype.totalCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeersListResponse totalPages.
             * @member {Long} totalPages
             * @memberof protocol.ClientPeersListResponse
             * @instance
             */
            ClientPeersListResponse.prototype.totalPages = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeersListResponse peers.
             * @member {Array.<protocol.IClientPeerDetails>} peers
             * @memberof protocol.ClientPeersListResponse
             * @instance
             */
            ClientPeersListResponse.prototype.peers = $util.emptyArray;
    
            /**
             * ClientPeersListResponse totalExpectedAmountUsd.
             * @member {string} totalExpectedAmountUsd
             * @memberof protocol.ClientPeersListResponse
             * @instance
             */
            ClientPeersListResponse.prototype.totalExpectedAmountUsd = "";
    
            /**
             * ClientPeersListResponse totalReceivedAmountUsd.
             * @member {string} totalReceivedAmountUsd
             * @memberof protocol.ClientPeersListResponse
             * @instance
             */
            ClientPeersListResponse.prototype.totalReceivedAmountUsd = "";
    
            /**
             * Creates a new ClientPeersListResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeersListResponse
             * @static
             * @param {protocol.IClientPeersListResponse=} [properties] Properties to set
             * @returns {protocol.ClientPeersListResponse} ClientPeersListResponse instance
             */
            ClientPeersListResponse.create = function create(properties) {
                return new ClientPeersListResponse(properties);
            };
    
            /**
             * Encodes the specified ClientPeersListResponse message. Does not implicitly {@link protocol.ClientPeersListResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeersListResponse
             * @static
             * @param {protocol.IClientPeersListResponse} message ClientPeersListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeersListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.totalCount);
                if (message.totalPages != null && Object.hasOwnProperty.call(message, "totalPages"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalPages);
                if (message.peers != null && message.peers.length)
                    for (var i = 0; i < message.peers.length; ++i)
                        $root.protocol.ClientPeerDetails.encode(message.peers[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.totalExpectedAmountUsd != null && Object.hasOwnProperty.call(message, "totalExpectedAmountUsd"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.totalExpectedAmountUsd);
                if (message.totalReceivedAmountUsd != null && Object.hasOwnProperty.call(message, "totalReceivedAmountUsd"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.totalReceivedAmountUsd);
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeersListResponse message, length delimited. Does not implicitly {@link protocol.ClientPeersListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeersListResponse
             * @static
             * @param {protocol.IClientPeersListResponse} message ClientPeersListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeersListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeersListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeersListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeersListResponse} ClientPeersListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeersListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeersListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.totalCount = reader.int64();
                        break;
                    case 2:
                        message.totalPages = reader.int64();
                        break;
                    case 3:
                        if (!(message.peers && message.peers.length))
                            message.peers = [];
                        message.peers.push($root.protocol.ClientPeerDetails.decode(reader, reader.uint32()));
                        break;
                    case 4:
                        message.totalExpectedAmountUsd = reader.string();
                        break;
                    case 5:
                        message.totalReceivedAmountUsd = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeersListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeersListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeersListResponse} ClientPeersListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeersListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeersListResponse message.
             * @function verify
             * @memberof protocol.ClientPeersListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeersListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (!$util.isInteger(message.totalCount) && !(message.totalCount && $util.isInteger(message.totalCount.low) && $util.isInteger(message.totalCount.high)))
                        return "totalCount: integer|Long expected";
                if (message.totalPages != null && message.hasOwnProperty("totalPages"))
                    if (!$util.isInteger(message.totalPages) && !(message.totalPages && $util.isInteger(message.totalPages.low) && $util.isInteger(message.totalPages.high)))
                        return "totalPages: integer|Long expected";
                if (message.peers != null && message.hasOwnProperty("peers")) {
                    if (!Array.isArray(message.peers))
                        return "peers: array expected";
                    for (var i = 0; i < message.peers.length; ++i) {
                        var error = $root.protocol.ClientPeerDetails.verify(message.peers[i]);
                        if (error)
                            return "peers." + error;
                    }
                }
                if (message.totalExpectedAmountUsd != null && message.hasOwnProperty("totalExpectedAmountUsd"))
                    if (!$util.isString(message.totalExpectedAmountUsd))
                        return "totalExpectedAmountUsd: string expected";
                if (message.totalReceivedAmountUsd != null && message.hasOwnProperty("totalReceivedAmountUsd"))
                    if (!$util.isString(message.totalReceivedAmountUsd))
                        return "totalReceivedAmountUsd: string expected";
                return null;
            };
    
            /**
             * Creates a ClientPeersListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeersListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeersListResponse} ClientPeersListResponse
             */
            ClientPeersListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeersListResponse)
                    return object;
                var message = new $root.protocol.ClientPeersListResponse();
                if (object.totalCount != null)
                    if ($util.Long)
                        (message.totalCount = $util.Long.fromValue(object.totalCount)).unsigned = false;
                    else if (typeof object.totalCount === "string")
                        message.totalCount = parseInt(object.totalCount, 10);
                    else if (typeof object.totalCount === "number")
                        message.totalCount = object.totalCount;
                    else if (typeof object.totalCount === "object")
                        message.totalCount = new $util.LongBits(object.totalCount.low >>> 0, object.totalCount.high >>> 0).toNumber();
                if (object.totalPages != null)
                    if ($util.Long)
                        (message.totalPages = $util.Long.fromValue(object.totalPages)).unsigned = false;
                    else if (typeof object.totalPages === "string")
                        message.totalPages = parseInt(object.totalPages, 10);
                    else if (typeof object.totalPages === "number")
                        message.totalPages = object.totalPages;
                    else if (typeof object.totalPages === "object")
                        message.totalPages = new $util.LongBits(object.totalPages.low >>> 0, object.totalPages.high >>> 0).toNumber();
                if (object.peers) {
                    if (!Array.isArray(object.peers))
                        throw TypeError(".protocol.ClientPeersListResponse.peers: array expected");
                    message.peers = [];
                    for (var i = 0; i < object.peers.length; ++i) {
                        if (typeof object.peers[i] !== "object")
                            throw TypeError(".protocol.ClientPeersListResponse.peers: object expected");
                        message.peers[i] = $root.protocol.ClientPeerDetails.fromObject(object.peers[i]);
                    }
                }
                if (object.totalExpectedAmountUsd != null)
                    message.totalExpectedAmountUsd = String(object.totalExpectedAmountUsd);
                if (object.totalReceivedAmountUsd != null)
                    message.totalReceivedAmountUsd = String(object.totalReceivedAmountUsd);
                return message;
            };
    
            /**
             * Creates a plain object from a ClientPeersListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeersListResponse
             * @static
             * @param {protocol.ClientPeersListResponse} message ClientPeersListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeersListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.peers = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalCount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalPages = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalPages = options.longs === String ? "0" : 0;
                    object.totalExpectedAmountUsd = "";
                    object.totalReceivedAmountUsd = "";
                }
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (typeof message.totalCount === "number")
                        object.totalCount = options.longs === String ? String(message.totalCount) : message.totalCount;
                    else
                        object.totalCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalCount) : options.longs === Number ? new $util.LongBits(message.totalCount.low >>> 0, message.totalCount.high >>> 0).toNumber() : message.totalCount;
                if (message.totalPages != null && message.hasOwnProperty("totalPages"))
                    if (typeof message.totalPages === "number")
                        object.totalPages = options.longs === String ? String(message.totalPages) : message.totalPages;
                    else
                        object.totalPages = options.longs === String ? $util.Long.prototype.toString.call(message.totalPages) : options.longs === Number ? new $util.LongBits(message.totalPages.low >>> 0, message.totalPages.high >>> 0).toNumber() : message.totalPages;
                if (message.peers && message.peers.length) {
                    object.peers = [];
                    for (var j = 0; j < message.peers.length; ++j)
                        object.peers[j] = $root.protocol.ClientPeerDetails.toObject(message.peers[j], options);
                }
                if (message.totalExpectedAmountUsd != null && message.hasOwnProperty("totalExpectedAmountUsd"))
                    object.totalExpectedAmountUsd = message.totalExpectedAmountUsd;
                if (message.totalReceivedAmountUsd != null && message.hasOwnProperty("totalReceivedAmountUsd"))
                    object.totalReceivedAmountUsd = message.totalReceivedAmountUsd;
                return object;
            };
    
            /**
             * Converts this ClientPeersListResponse to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeersListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeersListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeersListResponse;
        })();
    
        protocol.ClientPeerDetailsRequest = (function() {
    
            /**
             * Properties of a ClientPeerDetailsRequest.
             * @memberof protocol
             * @interface IClientPeerDetailsRequest
             * @property {number|null} [id] ClientPeerDetailsRequest id
             */
    
            /**
             * Constructs a new ClientPeerDetailsRequest.
             * @memberof protocol
             * @classdesc Represents a ClientPeerDetailsRequest.
             * @implements IClientPeerDetailsRequest
             * @constructor
             * @param {protocol.IClientPeerDetailsRequest=} [properties] Properties to set
             */
            function ClientPeerDetailsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientPeerDetailsRequest id.
             * @member {number} id
             * @memberof protocol.ClientPeerDetailsRequest
             * @instance
             */
            ClientPeerDetailsRequest.prototype.id = 0;
    
            /**
             * Creates a new ClientPeerDetailsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeerDetailsRequest
             * @static
             * @param {protocol.IClientPeerDetailsRequest=} [properties] Properties to set
             * @returns {protocol.ClientPeerDetailsRequest} ClientPeerDetailsRequest instance
             */
            ClientPeerDetailsRequest.create = function create(properties) {
                return new ClientPeerDetailsRequest(properties);
            };
    
            /**
             * Encodes the specified ClientPeerDetailsRequest message. Does not implicitly {@link protocol.ClientPeerDetailsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeerDetailsRequest
             * @static
             * @param {protocol.IClientPeerDetailsRequest} message ClientPeerDetailsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerDetailsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeerDetailsRequest message, length delimited. Does not implicitly {@link protocol.ClientPeerDetailsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeerDetailsRequest
             * @static
             * @param {protocol.IClientPeerDetailsRequest} message ClientPeerDetailsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerDetailsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeerDetailsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeerDetailsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeerDetailsRequest} ClientPeerDetailsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerDetailsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeerDetailsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeerDetailsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeerDetailsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeerDetailsRequest} ClientPeerDetailsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerDetailsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeerDetailsRequest message.
             * @function verify
             * @memberof protocol.ClientPeerDetailsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeerDetailsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                return null;
            };
    
            /**
             * Creates a ClientPeerDetailsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeerDetailsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeerDetailsRequest} ClientPeerDetailsRequest
             */
            ClientPeerDetailsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeerDetailsRequest)
                    return object;
                var message = new $root.protocol.ClientPeerDetailsRequest();
                if (object.id != null)
                    message.id = object.id >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a ClientPeerDetailsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeerDetailsRequest
             * @static
             * @param {protocol.ClientPeerDetailsRequest} message ClientPeerDetailsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeerDetailsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.id = 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                return object;
            };
    
            /**
             * Converts this ClientPeerDetailsRequest to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeerDetailsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeerDetailsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeerDetailsRequest;
        })();
    
        protocol.ClientPeerDetailsResponse = (function() {
    
            /**
             * Properties of a ClientPeerDetailsResponse.
             * @memberof protocol
             * @interface IClientPeerDetailsResponse
             * @property {protocol.IClientPeerDetails|null} [peer] ClientPeerDetailsResponse peer
             * @property {Array.<protocol.IClientPeerAddress>|null} [addresses] ClientPeerDetailsResponse addresses
             */
    
            /**
             * Constructs a new ClientPeerDetailsResponse.
             * @memberof protocol
             * @classdesc Represents a ClientPeerDetailsResponse.
             * @implements IClientPeerDetailsResponse
             * @constructor
             * @param {protocol.IClientPeerDetailsResponse=} [properties] Properties to set
             */
            function ClientPeerDetailsResponse(properties) {
                this.addresses = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientPeerDetailsResponse peer.
             * @member {protocol.IClientPeerDetails|null|undefined} peer
             * @memberof protocol.ClientPeerDetailsResponse
             * @instance
             */
            ClientPeerDetailsResponse.prototype.peer = null;
    
            /**
             * ClientPeerDetailsResponse addresses.
             * @member {Array.<protocol.IClientPeerAddress>} addresses
             * @memberof protocol.ClientPeerDetailsResponse
             * @instance
             */
            ClientPeerDetailsResponse.prototype.addresses = $util.emptyArray;
    
            /**
             * Creates a new ClientPeerDetailsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeerDetailsResponse
             * @static
             * @param {protocol.IClientPeerDetailsResponse=} [properties] Properties to set
             * @returns {protocol.ClientPeerDetailsResponse} ClientPeerDetailsResponse instance
             */
            ClientPeerDetailsResponse.create = function create(properties) {
                return new ClientPeerDetailsResponse(properties);
            };
    
            /**
             * Encodes the specified ClientPeerDetailsResponse message. Does not implicitly {@link protocol.ClientPeerDetailsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeerDetailsResponse
             * @static
             * @param {protocol.IClientPeerDetailsResponse} message ClientPeerDetailsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerDetailsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.peer != null && Object.hasOwnProperty.call(message, "peer"))
                    $root.protocol.ClientPeerDetails.encode(message.peer, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.addresses != null && message.addresses.length)
                    for (var i = 0; i < message.addresses.length; ++i)
                        $root.protocol.ClientPeerAddress.encode(message.addresses[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeerDetailsResponse message, length delimited. Does not implicitly {@link protocol.ClientPeerDetailsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeerDetailsResponse
             * @static
             * @param {protocol.IClientPeerDetailsResponse} message ClientPeerDetailsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerDetailsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeerDetailsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeerDetailsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeerDetailsResponse} ClientPeerDetailsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerDetailsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeerDetailsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.peer = $root.protocol.ClientPeerDetails.decode(reader, reader.uint32());
                        break;
                    case 2:
                        if (!(message.addresses && message.addresses.length))
                            message.addresses = [];
                        message.addresses.push($root.protocol.ClientPeerAddress.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeerDetailsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeerDetailsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeerDetailsResponse} ClientPeerDetailsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerDetailsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeerDetailsResponse message.
             * @function verify
             * @memberof protocol.ClientPeerDetailsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeerDetailsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.peer != null && message.hasOwnProperty("peer")) {
                    var error = $root.protocol.ClientPeerDetails.verify(message.peer);
                    if (error)
                        return "peer." + error;
                }
                if (message.addresses != null && message.hasOwnProperty("addresses")) {
                    if (!Array.isArray(message.addresses))
                        return "addresses: array expected";
                    for (var i = 0; i < message.addresses.length; ++i) {
                        var error = $root.protocol.ClientPeerAddress.verify(message.addresses[i]);
                        if (error)
                            return "addresses." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ClientPeerDetailsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeerDetailsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeerDetailsResponse} ClientPeerDetailsResponse
             */
            ClientPeerDetailsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeerDetailsResponse)
                    return object;
                var message = new $root.protocol.ClientPeerDetailsResponse();
                if (object.peer != null) {
                    if (typeof object.peer !== "object")
                        throw TypeError(".protocol.ClientPeerDetailsResponse.peer: object expected");
                    message.peer = $root.protocol.ClientPeerDetails.fromObject(object.peer);
                }
                if (object.addresses) {
                    if (!Array.isArray(object.addresses))
                        throw TypeError(".protocol.ClientPeerDetailsResponse.addresses: array expected");
                    message.addresses = [];
                    for (var i = 0; i < object.addresses.length; ++i) {
                        if (typeof object.addresses[i] !== "object")
                            throw TypeError(".protocol.ClientPeerDetailsResponse.addresses: object expected");
                        message.addresses[i] = $root.protocol.ClientPeerAddress.fromObject(object.addresses[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientPeerDetailsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeerDetailsResponse
             * @static
             * @param {protocol.ClientPeerDetailsResponse} message ClientPeerDetailsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeerDetailsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.addresses = [];
                if (options.defaults)
                    object.peer = null;
                if (message.peer != null && message.hasOwnProperty("peer"))
                    object.peer = $root.protocol.ClientPeerDetails.toObject(message.peer, options);
                if (message.addresses && message.addresses.length) {
                    object.addresses = [];
                    for (var j = 0; j < message.addresses.length; ++j)
                        object.addresses[j] = $root.protocol.ClientPeerAddress.toObject(message.addresses[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ClientPeerDetailsResponse to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeerDetailsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeerDetailsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeerDetailsResponse;
        })();
    
        protocol.ClientPeerAddressesListRequest = (function() {
    
            /**
             * Properties of a ClientPeerAddressesListRequest.
             * @memberof protocol
             * @interface IClientPeerAddressesListRequest
             * @property {number|null} [peerId] ClientPeerAddressesListRequest peerId
             */
    
            /**
             * Constructs a new ClientPeerAddressesListRequest.
             * @memberof protocol
             * @classdesc Represents a ClientPeerAddressesListRequest.
             * @implements IClientPeerAddressesListRequest
             * @constructor
             * @param {protocol.IClientPeerAddressesListRequest=} [properties] Properties to set
             */
            function ClientPeerAddressesListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientPeerAddressesListRequest peerId.
             * @member {number} peerId
             * @memberof protocol.ClientPeerAddressesListRequest
             * @instance
             */
            ClientPeerAddressesListRequest.prototype.peerId = 0;
    
            /**
             * Creates a new ClientPeerAddressesListRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeerAddressesListRequest
             * @static
             * @param {protocol.IClientPeerAddressesListRequest=} [properties] Properties to set
             * @returns {protocol.ClientPeerAddressesListRequest} ClientPeerAddressesListRequest instance
             */
            ClientPeerAddressesListRequest.create = function create(properties) {
                return new ClientPeerAddressesListRequest(properties);
            };
    
            /**
             * Encodes the specified ClientPeerAddressesListRequest message. Does not implicitly {@link protocol.ClientPeerAddressesListRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeerAddressesListRequest
             * @static
             * @param {protocol.IClientPeerAddressesListRequest} message ClientPeerAddressesListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerAddressesListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.peerId != null && Object.hasOwnProperty.call(message, "peerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.peerId);
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeerAddressesListRequest message, length delimited. Does not implicitly {@link protocol.ClientPeerAddressesListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeerAddressesListRequest
             * @static
             * @param {protocol.IClientPeerAddressesListRequest} message ClientPeerAddressesListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerAddressesListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeerAddressesListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeerAddressesListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeerAddressesListRequest} ClientPeerAddressesListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerAddressesListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeerAddressesListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.peerId = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeerAddressesListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeerAddressesListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeerAddressesListRequest} ClientPeerAddressesListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerAddressesListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeerAddressesListRequest message.
             * @function verify
             * @memberof protocol.ClientPeerAddressesListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeerAddressesListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.peerId != null && message.hasOwnProperty("peerId"))
                    if (!$util.isInteger(message.peerId))
                        return "peerId: integer expected";
                return null;
            };
    
            /**
             * Creates a ClientPeerAddressesListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeerAddressesListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeerAddressesListRequest} ClientPeerAddressesListRequest
             */
            ClientPeerAddressesListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeerAddressesListRequest)
                    return object;
                var message = new $root.protocol.ClientPeerAddressesListRequest();
                if (object.peerId != null)
                    message.peerId = object.peerId >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a ClientPeerAddressesListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeerAddressesListRequest
             * @static
             * @param {protocol.ClientPeerAddressesListRequest} message ClientPeerAddressesListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeerAddressesListRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.peerId = 0;
                if (message.peerId != null && message.hasOwnProperty("peerId"))
                    object.peerId = message.peerId;
                return object;
            };
    
            /**
             * Converts this ClientPeerAddressesListRequest to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeerAddressesListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeerAddressesListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeerAddressesListRequest;
        })();
    
        protocol.ClientPeerAddressesListResponse = (function() {
    
            /**
             * Properties of a ClientPeerAddressesListResponse.
             * @memberof protocol
             * @interface IClientPeerAddressesListResponse
             * @property {Array.<protocol.IClientPeerAddress>|null} [addresses] ClientPeerAddressesListResponse addresses
             */
    
            /**
             * Constructs a new ClientPeerAddressesListResponse.
             * @memberof protocol
             * @classdesc Represents a ClientPeerAddressesListResponse.
             * @implements IClientPeerAddressesListResponse
             * @constructor
             * @param {protocol.IClientPeerAddressesListResponse=} [properties] Properties to set
             */
            function ClientPeerAddressesListResponse(properties) {
                this.addresses = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientPeerAddressesListResponse addresses.
             * @member {Array.<protocol.IClientPeerAddress>} addresses
             * @memberof protocol.ClientPeerAddressesListResponse
             * @instance
             */
            ClientPeerAddressesListResponse.prototype.addresses = $util.emptyArray;
    
            /**
             * Creates a new ClientPeerAddressesListResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeerAddressesListResponse
             * @static
             * @param {protocol.IClientPeerAddressesListResponse=} [properties] Properties to set
             * @returns {protocol.ClientPeerAddressesListResponse} ClientPeerAddressesListResponse instance
             */
            ClientPeerAddressesListResponse.create = function create(properties) {
                return new ClientPeerAddressesListResponse(properties);
            };
    
            /**
             * Encodes the specified ClientPeerAddressesListResponse message. Does not implicitly {@link protocol.ClientPeerAddressesListResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeerAddressesListResponse
             * @static
             * @param {protocol.IClientPeerAddressesListResponse} message ClientPeerAddressesListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerAddressesListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.addresses != null && message.addresses.length)
                    for (var i = 0; i < message.addresses.length; ++i)
                        $root.protocol.ClientPeerAddress.encode(message.addresses[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeerAddressesListResponse message, length delimited. Does not implicitly {@link protocol.ClientPeerAddressesListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeerAddressesListResponse
             * @static
             * @param {protocol.IClientPeerAddressesListResponse} message ClientPeerAddressesListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerAddressesListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeerAddressesListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeerAddressesListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeerAddressesListResponse} ClientPeerAddressesListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerAddressesListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeerAddressesListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 3:
                        if (!(message.addresses && message.addresses.length))
                            message.addresses = [];
                        message.addresses.push($root.protocol.ClientPeerAddress.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeerAddressesListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeerAddressesListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeerAddressesListResponse} ClientPeerAddressesListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerAddressesListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeerAddressesListResponse message.
             * @function verify
             * @memberof protocol.ClientPeerAddressesListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeerAddressesListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.addresses != null && message.hasOwnProperty("addresses")) {
                    if (!Array.isArray(message.addresses))
                        return "addresses: array expected";
                    for (var i = 0; i < message.addresses.length; ++i) {
                        var error = $root.protocol.ClientPeerAddress.verify(message.addresses[i]);
                        if (error)
                            return "addresses." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ClientPeerAddressesListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeerAddressesListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeerAddressesListResponse} ClientPeerAddressesListResponse
             */
            ClientPeerAddressesListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeerAddressesListResponse)
                    return object;
                var message = new $root.protocol.ClientPeerAddressesListResponse();
                if (object.addresses) {
                    if (!Array.isArray(object.addresses))
                        throw TypeError(".protocol.ClientPeerAddressesListResponse.addresses: array expected");
                    message.addresses = [];
                    for (var i = 0; i < object.addresses.length; ++i) {
                        if (typeof object.addresses[i] !== "object")
                            throw TypeError(".protocol.ClientPeerAddressesListResponse.addresses: object expected");
                        message.addresses[i] = $root.protocol.ClientPeerAddress.fromObject(object.addresses[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientPeerAddressesListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeerAddressesListResponse
             * @static
             * @param {protocol.ClientPeerAddressesListResponse} message ClientPeerAddressesListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeerAddressesListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.addresses = [];
                if (message.addresses && message.addresses.length) {
                    object.addresses = [];
                    for (var j = 0; j < message.addresses.length; ++j)
                        object.addresses[j] = $root.protocol.ClientPeerAddress.toObject(message.addresses[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ClientPeerAddressesListResponse to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeerAddressesListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeerAddressesListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeerAddressesListResponse;
        })();
    
        protocol.DeleteClientPeerAddressRequest = (function() {
    
            /**
             * Properties of a DeleteClientPeerAddressRequest.
             * @memberof protocol
             * @interface IDeleteClientPeerAddressRequest
             * @property {number|null} [peerId] DeleteClientPeerAddressRequest peerId
             * @property {string|null} [address] DeleteClientPeerAddressRequest address
             */
    
            /**
             * Constructs a new DeleteClientPeerAddressRequest.
             * @memberof protocol
             * @classdesc Represents a DeleteClientPeerAddressRequest.
             * @implements IDeleteClientPeerAddressRequest
             * @constructor
             * @param {protocol.IDeleteClientPeerAddressRequest=} [properties] Properties to set
             */
            function DeleteClientPeerAddressRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DeleteClientPeerAddressRequest peerId.
             * @member {number} peerId
             * @memberof protocol.DeleteClientPeerAddressRequest
             * @instance
             */
            DeleteClientPeerAddressRequest.prototype.peerId = 0;
    
            /**
             * DeleteClientPeerAddressRequest address.
             * @member {string} address
             * @memberof protocol.DeleteClientPeerAddressRequest
             * @instance
             */
            DeleteClientPeerAddressRequest.prototype.address = "";
    
            /**
             * Creates a new DeleteClientPeerAddressRequest instance using the specified properties.
             * @function create
             * @memberof protocol.DeleteClientPeerAddressRequest
             * @static
             * @param {protocol.IDeleteClientPeerAddressRequest=} [properties] Properties to set
             * @returns {protocol.DeleteClientPeerAddressRequest} DeleteClientPeerAddressRequest instance
             */
            DeleteClientPeerAddressRequest.create = function create(properties) {
                return new DeleteClientPeerAddressRequest(properties);
            };
    
            /**
             * Encodes the specified DeleteClientPeerAddressRequest message. Does not implicitly {@link protocol.DeleteClientPeerAddressRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.DeleteClientPeerAddressRequest
             * @static
             * @param {protocol.IDeleteClientPeerAddressRequest} message DeleteClientPeerAddressRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteClientPeerAddressRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.peerId != null && Object.hasOwnProperty.call(message, "peerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.peerId);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
                return writer;
            };
    
            /**
             * Encodes the specified DeleteClientPeerAddressRequest message, length delimited. Does not implicitly {@link protocol.DeleteClientPeerAddressRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.DeleteClientPeerAddressRequest
             * @static
             * @param {protocol.IDeleteClientPeerAddressRequest} message DeleteClientPeerAddressRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteClientPeerAddressRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DeleteClientPeerAddressRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.DeleteClientPeerAddressRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.DeleteClientPeerAddressRequest} DeleteClientPeerAddressRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteClientPeerAddressRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.DeleteClientPeerAddressRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.peerId = reader.uint32();
                        break;
                    case 2:
                        message.address = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DeleteClientPeerAddressRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.DeleteClientPeerAddressRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.DeleteClientPeerAddressRequest} DeleteClientPeerAddressRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteClientPeerAddressRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DeleteClientPeerAddressRequest message.
             * @function verify
             * @memberof protocol.DeleteClientPeerAddressRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteClientPeerAddressRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.peerId != null && message.hasOwnProperty("peerId"))
                    if (!$util.isInteger(message.peerId))
                        return "peerId: integer expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                return null;
            };
    
            /**
             * Creates a DeleteClientPeerAddressRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.DeleteClientPeerAddressRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.DeleteClientPeerAddressRequest} DeleteClientPeerAddressRequest
             */
            DeleteClientPeerAddressRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.DeleteClientPeerAddressRequest)
                    return object;
                var message = new $root.protocol.DeleteClientPeerAddressRequest();
                if (object.peerId != null)
                    message.peerId = object.peerId >>> 0;
                if (object.address != null)
                    message.address = String(object.address);
                return message;
            };
    
            /**
             * Creates a plain object from a DeleteClientPeerAddressRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.DeleteClientPeerAddressRequest
             * @static
             * @param {protocol.DeleteClientPeerAddressRequest} message DeleteClientPeerAddressRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteClientPeerAddressRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.peerId = 0;
                    object.address = "";
                }
                if (message.peerId != null && message.hasOwnProperty("peerId"))
                    object.peerId = message.peerId;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                return object;
            };
    
            /**
             * Converts this DeleteClientPeerAddressRequest to JSON.
             * @function toJSON
             * @memberof protocol.DeleteClientPeerAddressRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteClientPeerAddressRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return DeleteClientPeerAddressRequest;
        })();
    
        protocol.DeleteClientPeerAddressResponse = (function() {
    
            /**
             * Properties of a DeleteClientPeerAddressResponse.
             * @memberof protocol
             * @interface IDeleteClientPeerAddressResponse
             */
    
            /**
             * Constructs a new DeleteClientPeerAddressResponse.
             * @memberof protocol
             * @classdesc Represents a DeleteClientPeerAddressResponse.
             * @implements IDeleteClientPeerAddressResponse
             * @constructor
             * @param {protocol.IDeleteClientPeerAddressResponse=} [properties] Properties to set
             */
            function DeleteClientPeerAddressResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new DeleteClientPeerAddressResponse instance using the specified properties.
             * @function create
             * @memberof protocol.DeleteClientPeerAddressResponse
             * @static
             * @param {protocol.IDeleteClientPeerAddressResponse=} [properties] Properties to set
             * @returns {protocol.DeleteClientPeerAddressResponse} DeleteClientPeerAddressResponse instance
             */
            DeleteClientPeerAddressResponse.create = function create(properties) {
                return new DeleteClientPeerAddressResponse(properties);
            };
    
            /**
             * Encodes the specified DeleteClientPeerAddressResponse message. Does not implicitly {@link protocol.DeleteClientPeerAddressResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.DeleteClientPeerAddressResponse
             * @static
             * @param {protocol.IDeleteClientPeerAddressResponse} message DeleteClientPeerAddressResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteClientPeerAddressResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified DeleteClientPeerAddressResponse message, length delimited. Does not implicitly {@link protocol.DeleteClientPeerAddressResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.DeleteClientPeerAddressResponse
             * @static
             * @param {protocol.IDeleteClientPeerAddressResponse} message DeleteClientPeerAddressResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteClientPeerAddressResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DeleteClientPeerAddressResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.DeleteClientPeerAddressResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.DeleteClientPeerAddressResponse} DeleteClientPeerAddressResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteClientPeerAddressResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.DeleteClientPeerAddressResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DeleteClientPeerAddressResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.DeleteClientPeerAddressResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.DeleteClientPeerAddressResponse} DeleteClientPeerAddressResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteClientPeerAddressResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DeleteClientPeerAddressResponse message.
             * @function verify
             * @memberof protocol.DeleteClientPeerAddressResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteClientPeerAddressResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a DeleteClientPeerAddressResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.DeleteClientPeerAddressResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.DeleteClientPeerAddressResponse} DeleteClientPeerAddressResponse
             */
            DeleteClientPeerAddressResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.DeleteClientPeerAddressResponse)
                    return object;
                return new $root.protocol.DeleteClientPeerAddressResponse();
            };
    
            /**
             * Creates a plain object from a DeleteClientPeerAddressResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.DeleteClientPeerAddressResponse
             * @static
             * @param {protocol.DeleteClientPeerAddressResponse} message DeleteClientPeerAddressResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteClientPeerAddressResponse.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this DeleteClientPeerAddressResponse to JSON.
             * @function toJSON
             * @memberof protocol.DeleteClientPeerAddressResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteClientPeerAddressResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return DeleteClientPeerAddressResponse;
        })();
    
        protocol.ClientPeersStatsRequest = (function() {
    
            /**
             * Properties of a ClientPeersStatsRequest.
             * @memberof protocol
             * @interface IClientPeersStatsRequest
             * @property {Long|null} [createdAtFrom] ClientPeersStatsRequest createdAtFrom
             * @property {Long|null} [createdAtTo] ClientPeersStatsRequest createdAtTo
             */
    
            /**
             * Constructs a new ClientPeersStatsRequest.
             * @memberof protocol
             * @classdesc Represents a ClientPeersStatsRequest.
             * @implements IClientPeersStatsRequest
             * @constructor
             * @param {protocol.IClientPeersStatsRequest=} [properties] Properties to set
             */
            function ClientPeersStatsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientPeersStatsRequest createdAtFrom.
             * @member {Long} createdAtFrom
             * @memberof protocol.ClientPeersStatsRequest
             * @instance
             */
            ClientPeersStatsRequest.prototype.createdAtFrom = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeersStatsRequest createdAtTo.
             * @member {Long} createdAtTo
             * @memberof protocol.ClientPeersStatsRequest
             * @instance
             */
            ClientPeersStatsRequest.prototype.createdAtTo = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new ClientPeersStatsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeersStatsRequest
             * @static
             * @param {protocol.IClientPeersStatsRequest=} [properties] Properties to set
             * @returns {protocol.ClientPeersStatsRequest} ClientPeersStatsRequest instance
             */
            ClientPeersStatsRequest.create = function create(properties) {
                return new ClientPeersStatsRequest(properties);
            };
    
            /**
             * Encodes the specified ClientPeersStatsRequest message. Does not implicitly {@link protocol.ClientPeersStatsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeersStatsRequest
             * @static
             * @param {protocol.IClientPeersStatsRequest} message ClientPeersStatsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeersStatsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.createdAtFrom != null && Object.hasOwnProperty.call(message, "createdAtFrom"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.createdAtFrom);
                if (message.createdAtTo != null && Object.hasOwnProperty.call(message, "createdAtTo"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.createdAtTo);
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeersStatsRequest message, length delimited. Does not implicitly {@link protocol.ClientPeersStatsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeersStatsRequest
             * @static
             * @param {protocol.IClientPeersStatsRequest} message ClientPeersStatsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeersStatsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeersStatsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeersStatsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeersStatsRequest} ClientPeersStatsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeersStatsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeersStatsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.createdAtFrom = reader.int64();
                        break;
                    case 2:
                        message.createdAtTo = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeersStatsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeersStatsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeersStatsRequest} ClientPeersStatsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeersStatsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeersStatsRequest message.
             * @function verify
             * @memberof protocol.ClientPeersStatsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeersStatsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.createdAtFrom != null && message.hasOwnProperty("createdAtFrom"))
                    if (!$util.isInteger(message.createdAtFrom) && !(message.createdAtFrom && $util.isInteger(message.createdAtFrom.low) && $util.isInteger(message.createdAtFrom.high)))
                        return "createdAtFrom: integer|Long expected";
                if (message.createdAtTo != null && message.hasOwnProperty("createdAtTo"))
                    if (!$util.isInteger(message.createdAtTo) && !(message.createdAtTo && $util.isInteger(message.createdAtTo.low) && $util.isInteger(message.createdAtTo.high)))
                        return "createdAtTo: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a ClientPeersStatsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeersStatsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeersStatsRequest} ClientPeersStatsRequest
             */
            ClientPeersStatsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeersStatsRequest)
                    return object;
                var message = new $root.protocol.ClientPeersStatsRequest();
                if (object.createdAtFrom != null)
                    if ($util.Long)
                        (message.createdAtFrom = $util.Long.fromValue(object.createdAtFrom)).unsigned = false;
                    else if (typeof object.createdAtFrom === "string")
                        message.createdAtFrom = parseInt(object.createdAtFrom, 10);
                    else if (typeof object.createdAtFrom === "number")
                        message.createdAtFrom = object.createdAtFrom;
                    else if (typeof object.createdAtFrom === "object")
                        message.createdAtFrom = new $util.LongBits(object.createdAtFrom.low >>> 0, object.createdAtFrom.high >>> 0).toNumber();
                if (object.createdAtTo != null)
                    if ($util.Long)
                        (message.createdAtTo = $util.Long.fromValue(object.createdAtTo)).unsigned = false;
                    else if (typeof object.createdAtTo === "string")
                        message.createdAtTo = parseInt(object.createdAtTo, 10);
                    else if (typeof object.createdAtTo === "number")
                        message.createdAtTo = object.createdAtTo;
                    else if (typeof object.createdAtTo === "object")
                        message.createdAtTo = new $util.LongBits(object.createdAtTo.low >>> 0, object.createdAtTo.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a ClientPeersStatsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeersStatsRequest
             * @static
             * @param {protocol.ClientPeersStatsRequest} message ClientPeersStatsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeersStatsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.createdAtFrom = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAtFrom = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.createdAtTo = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.createdAtTo = options.longs === String ? "0" : 0;
                }
                if (message.createdAtFrom != null && message.hasOwnProperty("createdAtFrom"))
                    if (typeof message.createdAtFrom === "number")
                        object.createdAtFrom = options.longs === String ? String(message.createdAtFrom) : message.createdAtFrom;
                    else
                        object.createdAtFrom = options.longs === String ? $util.Long.prototype.toString.call(message.createdAtFrom) : options.longs === Number ? new $util.LongBits(message.createdAtFrom.low >>> 0, message.createdAtFrom.high >>> 0).toNumber() : message.createdAtFrom;
                if (message.createdAtTo != null && message.hasOwnProperty("createdAtTo"))
                    if (typeof message.createdAtTo === "number")
                        object.createdAtTo = options.longs === String ? String(message.createdAtTo) : message.createdAtTo;
                    else
                        object.createdAtTo = options.longs === String ? $util.Long.prototype.toString.call(message.createdAtTo) : options.longs === Number ? new $util.LongBits(message.createdAtTo.low >>> 0, message.createdAtTo.high >>> 0).toNumber() : message.createdAtTo;
                return object;
            };
    
            /**
             * Converts this ClientPeersStatsRequest to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeersStatsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeersStatsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeersStatsRequest;
        })();
    
        protocol.ClientPeersStatsResponse = (function() {
    
            /**
             * Properties of a ClientPeersStatsResponse.
             * @memberof protocol
             * @interface IClientPeersStatsResponse
             * @property {Long|null} [totalPeersCount] ClientPeersStatsResponse totalPeersCount
             * @property {string|null} [totalExpectedAmountUsd] ClientPeersStatsResponse totalExpectedAmountUsd
             * @property {string|null} [totalReceivedAmountUsd] ClientPeersStatsResponse totalReceivedAmountUsd
             */
    
            /**
             * Constructs a new ClientPeersStatsResponse.
             * @memberof protocol
             * @classdesc Represents a ClientPeersStatsResponse.
             * @implements IClientPeersStatsResponse
             * @constructor
             * @param {protocol.IClientPeersStatsResponse=} [properties] Properties to set
             */
            function ClientPeersStatsResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientPeersStatsResponse totalPeersCount.
             * @member {Long} totalPeersCount
             * @memberof protocol.ClientPeersStatsResponse
             * @instance
             */
            ClientPeersStatsResponse.prototype.totalPeersCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ClientPeersStatsResponse totalExpectedAmountUsd.
             * @member {string} totalExpectedAmountUsd
             * @memberof protocol.ClientPeersStatsResponse
             * @instance
             */
            ClientPeersStatsResponse.prototype.totalExpectedAmountUsd = "";
    
            /**
             * ClientPeersStatsResponse totalReceivedAmountUsd.
             * @member {string} totalReceivedAmountUsd
             * @memberof protocol.ClientPeersStatsResponse
             * @instance
             */
            ClientPeersStatsResponse.prototype.totalReceivedAmountUsd = "";
    
            /**
             * Creates a new ClientPeersStatsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeersStatsResponse
             * @static
             * @param {protocol.IClientPeersStatsResponse=} [properties] Properties to set
             * @returns {protocol.ClientPeersStatsResponse} ClientPeersStatsResponse instance
             */
            ClientPeersStatsResponse.create = function create(properties) {
                return new ClientPeersStatsResponse(properties);
            };
    
            /**
             * Encodes the specified ClientPeersStatsResponse message. Does not implicitly {@link protocol.ClientPeersStatsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeersStatsResponse
             * @static
             * @param {protocol.IClientPeersStatsResponse} message ClientPeersStatsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeersStatsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.totalPeersCount != null && Object.hasOwnProperty.call(message, "totalPeersCount"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.totalPeersCount);
                if (message.totalExpectedAmountUsd != null && Object.hasOwnProperty.call(message, "totalExpectedAmountUsd"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.totalExpectedAmountUsd);
                if (message.totalReceivedAmountUsd != null && Object.hasOwnProperty.call(message, "totalReceivedAmountUsd"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.totalReceivedAmountUsd);
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeersStatsResponse message, length delimited. Does not implicitly {@link protocol.ClientPeersStatsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeersStatsResponse
             * @static
             * @param {protocol.IClientPeersStatsResponse} message ClientPeersStatsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeersStatsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeersStatsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeersStatsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeersStatsResponse} ClientPeersStatsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeersStatsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeersStatsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.totalPeersCount = reader.int64();
                        break;
                    case 2:
                        message.totalExpectedAmountUsd = reader.string();
                        break;
                    case 3:
                        message.totalReceivedAmountUsd = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeersStatsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeersStatsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeersStatsResponse} ClientPeersStatsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeersStatsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeersStatsResponse message.
             * @function verify
             * @memberof protocol.ClientPeersStatsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeersStatsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.totalPeersCount != null && message.hasOwnProperty("totalPeersCount"))
                    if (!$util.isInteger(message.totalPeersCount) && !(message.totalPeersCount && $util.isInteger(message.totalPeersCount.low) && $util.isInteger(message.totalPeersCount.high)))
                        return "totalPeersCount: integer|Long expected";
                if (message.totalExpectedAmountUsd != null && message.hasOwnProperty("totalExpectedAmountUsd"))
                    if (!$util.isString(message.totalExpectedAmountUsd))
                        return "totalExpectedAmountUsd: string expected";
                if (message.totalReceivedAmountUsd != null && message.hasOwnProperty("totalReceivedAmountUsd"))
                    if (!$util.isString(message.totalReceivedAmountUsd))
                        return "totalReceivedAmountUsd: string expected";
                return null;
            };
    
            /**
             * Creates a ClientPeersStatsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeersStatsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeersStatsResponse} ClientPeersStatsResponse
             */
            ClientPeersStatsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeersStatsResponse)
                    return object;
                var message = new $root.protocol.ClientPeersStatsResponse();
                if (object.totalPeersCount != null)
                    if ($util.Long)
                        (message.totalPeersCount = $util.Long.fromValue(object.totalPeersCount)).unsigned = false;
                    else if (typeof object.totalPeersCount === "string")
                        message.totalPeersCount = parseInt(object.totalPeersCount, 10);
                    else if (typeof object.totalPeersCount === "number")
                        message.totalPeersCount = object.totalPeersCount;
                    else if (typeof object.totalPeersCount === "object")
                        message.totalPeersCount = new $util.LongBits(object.totalPeersCount.low >>> 0, object.totalPeersCount.high >>> 0).toNumber();
                if (object.totalExpectedAmountUsd != null)
                    message.totalExpectedAmountUsd = String(object.totalExpectedAmountUsd);
                if (object.totalReceivedAmountUsd != null)
                    message.totalReceivedAmountUsd = String(object.totalReceivedAmountUsd);
                return message;
            };
    
            /**
             * Creates a plain object from a ClientPeersStatsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeersStatsResponse
             * @static
             * @param {protocol.ClientPeersStatsResponse} message ClientPeersStatsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeersStatsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalPeersCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalPeersCount = options.longs === String ? "0" : 0;
                    object.totalExpectedAmountUsd = "";
                    object.totalReceivedAmountUsd = "";
                }
                if (message.totalPeersCount != null && message.hasOwnProperty("totalPeersCount"))
                    if (typeof message.totalPeersCount === "number")
                        object.totalPeersCount = options.longs === String ? String(message.totalPeersCount) : message.totalPeersCount;
                    else
                        object.totalPeersCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalPeersCount) : options.longs === Number ? new $util.LongBits(message.totalPeersCount.low >>> 0, message.totalPeersCount.high >>> 0).toNumber() : message.totalPeersCount;
                if (message.totalExpectedAmountUsd != null && message.hasOwnProperty("totalExpectedAmountUsd"))
                    object.totalExpectedAmountUsd = message.totalExpectedAmountUsd;
                if (message.totalReceivedAmountUsd != null && message.hasOwnProperty("totalReceivedAmountUsd"))
                    object.totalReceivedAmountUsd = message.totalReceivedAmountUsd;
                return object;
            };
    
            /**
             * Converts this ClientPeersStatsResponse to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeersStatsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeersStatsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeersStatsResponse;
        })();
    
        protocol.ClientPeerChangeTransactionAmountRequest = (function() {
    
            /**
             * Properties of a ClientPeerChangeTransactionAmountRequest.
             * @memberof protocol
             * @interface IClientPeerChangeTransactionAmountRequest
             * @property {number|null} [transactionId] ClientPeerChangeTransactionAmountRequest transactionId
             * @property {protocol.FiatCurrency|null} [currency] ClientPeerChangeTransactionAmountRequest currency
             * @property {string|null} [amount] ClientPeerChangeTransactionAmountRequest amount
             */
    
            /**
             * Constructs a new ClientPeerChangeTransactionAmountRequest.
             * @memberof protocol
             * @classdesc Represents a ClientPeerChangeTransactionAmountRequest.
             * @implements IClientPeerChangeTransactionAmountRequest
             * @constructor
             * @param {protocol.IClientPeerChangeTransactionAmountRequest=} [properties] Properties to set
             */
            function ClientPeerChangeTransactionAmountRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientPeerChangeTransactionAmountRequest transactionId.
             * @member {number} transactionId
             * @memberof protocol.ClientPeerChangeTransactionAmountRequest
             * @instance
             */
            ClientPeerChangeTransactionAmountRequest.prototype.transactionId = 0;
    
            /**
             * ClientPeerChangeTransactionAmountRequest currency.
             * @member {protocol.FiatCurrency} currency
             * @memberof protocol.ClientPeerChangeTransactionAmountRequest
             * @instance
             */
            ClientPeerChangeTransactionAmountRequest.prototype.currency = 0;
    
            /**
             * ClientPeerChangeTransactionAmountRequest amount.
             * @member {string} amount
             * @memberof protocol.ClientPeerChangeTransactionAmountRequest
             * @instance
             */
            ClientPeerChangeTransactionAmountRequest.prototype.amount = "";
    
            /**
             * Creates a new ClientPeerChangeTransactionAmountRequest instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeerChangeTransactionAmountRequest
             * @static
             * @param {protocol.IClientPeerChangeTransactionAmountRequest=} [properties] Properties to set
             * @returns {protocol.ClientPeerChangeTransactionAmountRequest} ClientPeerChangeTransactionAmountRequest instance
             */
            ClientPeerChangeTransactionAmountRequest.create = function create(properties) {
                return new ClientPeerChangeTransactionAmountRequest(properties);
            };
    
            /**
             * Encodes the specified ClientPeerChangeTransactionAmountRequest message. Does not implicitly {@link protocol.ClientPeerChangeTransactionAmountRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeerChangeTransactionAmountRequest
             * @static
             * @param {protocol.IClientPeerChangeTransactionAmountRequest} message ClientPeerChangeTransactionAmountRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerChangeTransactionAmountRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.transactionId != null && Object.hasOwnProperty.call(message, "transactionId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.transactionId);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currency);
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.amount);
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeerChangeTransactionAmountRequest message, length delimited. Does not implicitly {@link protocol.ClientPeerChangeTransactionAmountRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeerChangeTransactionAmountRequest
             * @static
             * @param {protocol.IClientPeerChangeTransactionAmountRequest} message ClientPeerChangeTransactionAmountRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerChangeTransactionAmountRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeerChangeTransactionAmountRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeerChangeTransactionAmountRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeerChangeTransactionAmountRequest} ClientPeerChangeTransactionAmountRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerChangeTransactionAmountRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeerChangeTransactionAmountRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.transactionId = reader.uint32();
                        break;
                    case 2:
                        message.currency = reader.int32();
                        break;
                    case 3:
                        message.amount = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeerChangeTransactionAmountRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeerChangeTransactionAmountRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeerChangeTransactionAmountRequest} ClientPeerChangeTransactionAmountRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerChangeTransactionAmountRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeerChangeTransactionAmountRequest message.
             * @function verify
             * @memberof protocol.ClientPeerChangeTransactionAmountRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeerChangeTransactionAmountRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                    if (!$util.isInteger(message.transactionId))
                        return "transactionId: integer expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    switch (message.currency) {
                    default:
                        return "currency: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (!$util.isString(message.amount))
                        return "amount: string expected";
                return null;
            };
    
            /**
             * Creates a ClientPeerChangeTransactionAmountRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeerChangeTransactionAmountRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeerChangeTransactionAmountRequest} ClientPeerChangeTransactionAmountRequest
             */
            ClientPeerChangeTransactionAmountRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeerChangeTransactionAmountRequest)
                    return object;
                var message = new $root.protocol.ClientPeerChangeTransactionAmountRequest();
                if (object.transactionId != null)
                    message.transactionId = object.transactionId >>> 0;
                switch (object.currency) {
                case "UNK_FIAT_CUR":
                case 0:
                    message.currency = 0;
                    break;
                case "USD_FIAT_CUR":
                case 1:
                    message.currency = 1;
                    break;
                case "EUR_FIAT_CUR":
                case 2:
                    message.currency = 2;
                    break;
                case "ARS_FIAT_CUR":
                case 3:
                    message.currency = 3;
                    break;
                case "PEN_FIAT_CUR":
                case 4:
                    message.currency = 4;
                    break;
                case "BOB_FIAT_CUR":
                case 5:
                    message.currency = 5;
                    break;
                case "CLP_FIAT_CUR":
                case 6:
                    message.currency = 6;
                    break;
                case "BRL_FIAT_CUR":
                case 7:
                    message.currency = 7;
                    break;
                }
                if (object.amount != null)
                    message.amount = String(object.amount);
                return message;
            };
    
            /**
             * Creates a plain object from a ClientPeerChangeTransactionAmountRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeerChangeTransactionAmountRequest
             * @static
             * @param {protocol.ClientPeerChangeTransactionAmountRequest} message ClientPeerChangeTransactionAmountRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeerChangeTransactionAmountRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.transactionId = 0;
                    object.currency = options.enums === String ? "UNK_FIAT_CUR" : 0;
                    object.amount = "";
                }
                if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                    object.transactionId = message.transactionId;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = options.enums === String ? $root.protocol.FiatCurrency[message.currency] : message.currency;
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = message.amount;
                return object;
            };
    
            /**
             * Converts this ClientPeerChangeTransactionAmountRequest to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeerChangeTransactionAmountRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeerChangeTransactionAmountRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeerChangeTransactionAmountRequest;
        })();
    
        protocol.ClientPeerChangeTransactionAmountResponse = (function() {
    
            /**
             * Properties of a ClientPeerChangeTransactionAmountResponse.
             * @memberof protocol
             * @interface IClientPeerChangeTransactionAmountResponse
             */
    
            /**
             * Constructs a new ClientPeerChangeTransactionAmountResponse.
             * @memberof protocol
             * @classdesc Represents a ClientPeerChangeTransactionAmountResponse.
             * @implements IClientPeerChangeTransactionAmountResponse
             * @constructor
             * @param {protocol.IClientPeerChangeTransactionAmountResponse=} [properties] Properties to set
             */
            function ClientPeerChangeTransactionAmountResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new ClientPeerChangeTransactionAmountResponse instance using the specified properties.
             * @function create
             * @memberof protocol.ClientPeerChangeTransactionAmountResponse
             * @static
             * @param {protocol.IClientPeerChangeTransactionAmountResponse=} [properties] Properties to set
             * @returns {protocol.ClientPeerChangeTransactionAmountResponse} ClientPeerChangeTransactionAmountResponse instance
             */
            ClientPeerChangeTransactionAmountResponse.create = function create(properties) {
                return new ClientPeerChangeTransactionAmountResponse(properties);
            };
    
            /**
             * Encodes the specified ClientPeerChangeTransactionAmountResponse message. Does not implicitly {@link protocol.ClientPeerChangeTransactionAmountResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientPeerChangeTransactionAmountResponse
             * @static
             * @param {protocol.IClientPeerChangeTransactionAmountResponse} message ClientPeerChangeTransactionAmountResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerChangeTransactionAmountResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified ClientPeerChangeTransactionAmountResponse message, length delimited. Does not implicitly {@link protocol.ClientPeerChangeTransactionAmountResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientPeerChangeTransactionAmountResponse
             * @static
             * @param {protocol.IClientPeerChangeTransactionAmountResponse} message ClientPeerChangeTransactionAmountResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPeerChangeTransactionAmountResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientPeerChangeTransactionAmountResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientPeerChangeTransactionAmountResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientPeerChangeTransactionAmountResponse} ClientPeerChangeTransactionAmountResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerChangeTransactionAmountResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientPeerChangeTransactionAmountResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientPeerChangeTransactionAmountResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientPeerChangeTransactionAmountResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientPeerChangeTransactionAmountResponse} ClientPeerChangeTransactionAmountResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPeerChangeTransactionAmountResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientPeerChangeTransactionAmountResponse message.
             * @function verify
             * @memberof protocol.ClientPeerChangeTransactionAmountResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPeerChangeTransactionAmountResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a ClientPeerChangeTransactionAmountResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientPeerChangeTransactionAmountResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientPeerChangeTransactionAmountResponse} ClientPeerChangeTransactionAmountResponse
             */
            ClientPeerChangeTransactionAmountResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientPeerChangeTransactionAmountResponse)
                    return object;
                return new $root.protocol.ClientPeerChangeTransactionAmountResponse();
            };
    
            /**
             * Creates a plain object from a ClientPeerChangeTransactionAmountResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientPeerChangeTransactionAmountResponse
             * @static
             * @param {protocol.ClientPeerChangeTransactionAmountResponse} message ClientPeerChangeTransactionAmountResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPeerChangeTransactionAmountResponse.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this ClientPeerChangeTransactionAmountResponse to JSON.
             * @function toJSON
             * @memberof protocol.ClientPeerChangeTransactionAmountResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPeerChangeTransactionAmountResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientPeerChangeTransactionAmountResponse;
        })();
    
        protocol.AuthUserRequest = (function() {
    
            /**
             * Properties of an AuthUserRequest.
             * @memberof protocol
             * @interface IAuthUserRequest
             * @property {string|null} [login] AuthUserRequest login
             * @property {string|null} [password] AuthUserRequest password
             */
    
            /**
             * Constructs a new AuthUserRequest.
             * @memberof protocol
             * @classdesc Represents an AuthUserRequest.
             * @implements IAuthUserRequest
             * @constructor
             * @param {protocol.IAuthUserRequest=} [properties] Properties to set
             */
            function AuthUserRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AuthUserRequest login.
             * @member {string} login
             * @memberof protocol.AuthUserRequest
             * @instance
             */
            AuthUserRequest.prototype.login = "";
    
            /**
             * AuthUserRequest password.
             * @member {string} password
             * @memberof protocol.AuthUserRequest
             * @instance
             */
            AuthUserRequest.prototype.password = "";
    
            /**
             * Creates a new AuthUserRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AuthUserRequest
             * @static
             * @param {protocol.IAuthUserRequest=} [properties] Properties to set
             * @returns {protocol.AuthUserRequest} AuthUserRequest instance
             */
            AuthUserRequest.create = function create(properties) {
                return new AuthUserRequest(properties);
            };
    
            /**
             * Encodes the specified AuthUserRequest message. Does not implicitly {@link protocol.AuthUserRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AuthUserRequest
             * @static
             * @param {protocol.IAuthUserRequest} message AuthUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthUserRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.login);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                return writer;
            };
    
            /**
             * Encodes the specified AuthUserRequest message, length delimited. Does not implicitly {@link protocol.AuthUserRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AuthUserRequest
             * @static
             * @param {protocol.IAuthUserRequest} message AuthUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AuthUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AuthUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AuthUserRequest} AuthUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthUserRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AuthUserRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.login = reader.string();
                        break;
                    case 2:
                        message.password = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AuthUserRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AuthUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AuthUserRequest} AuthUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthUserRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AuthUserRequest message.
             * @function verify
             * @memberof protocol.AuthUserRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AuthUserRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.login != null && message.hasOwnProperty("login"))
                    if (!$util.isString(message.login))
                        return "login: string expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                return null;
            };
    
            /**
             * Creates an AuthUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AuthUserRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AuthUserRequest} AuthUserRequest
             */
            AuthUserRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AuthUserRequest)
                    return object;
                var message = new $root.protocol.AuthUserRequest();
                if (object.login != null)
                    message.login = String(object.login);
                if (object.password != null)
                    message.password = String(object.password);
                return message;
            };
    
            /**
             * Creates a plain object from an AuthUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AuthUserRequest
             * @static
             * @param {protocol.AuthUserRequest} message AuthUserRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AuthUserRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.login = "";
                    object.password = "";
                }
                if (message.login != null && message.hasOwnProperty("login"))
                    object.login = message.login;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                return object;
            };
    
            /**
             * Converts this AuthUserRequest to JSON.
             * @function toJSON
             * @memberof protocol.AuthUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AuthUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AuthUserRequest;
        })();
    
        protocol.AuthUserResponse = (function() {
    
            /**
             * Properties of an AuthUserResponse.
             * @memberof protocol
             * @interface IAuthUserResponse
             * @property {string|null} [token] AuthUserResponse token
             * @property {protocol.IClientUser|null} [user] AuthUserResponse user
             */
    
            /**
             * Constructs a new AuthUserResponse.
             * @memberof protocol
             * @classdesc Represents an AuthUserResponse.
             * @implements IAuthUserResponse
             * @constructor
             * @param {protocol.IAuthUserResponse=} [properties] Properties to set
             */
            function AuthUserResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AuthUserResponse token.
             * @member {string} token
             * @memberof protocol.AuthUserResponse
             * @instance
             */
            AuthUserResponse.prototype.token = "";
    
            /**
             * AuthUserResponse user.
             * @member {protocol.IClientUser|null|undefined} user
             * @memberof protocol.AuthUserResponse
             * @instance
             */
            AuthUserResponse.prototype.user = null;
    
            /**
             * Creates a new AuthUserResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AuthUserResponse
             * @static
             * @param {protocol.IAuthUserResponse=} [properties] Properties to set
             * @returns {protocol.AuthUserResponse} AuthUserResponse instance
             */
            AuthUserResponse.create = function create(properties) {
                return new AuthUserResponse(properties);
            };
    
            /**
             * Encodes the specified AuthUserResponse message. Does not implicitly {@link protocol.AuthUserResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AuthUserResponse
             * @static
             * @param {protocol.IAuthUserResponse} message AuthUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthUserResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                    $root.protocol.ClientUser.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AuthUserResponse message, length delimited. Does not implicitly {@link protocol.AuthUserResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AuthUserResponse
             * @static
             * @param {protocol.IAuthUserResponse} message AuthUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AuthUserResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AuthUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AuthUserResponse} AuthUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthUserResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AuthUserResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.token = reader.string();
                        break;
                    case 2:
                        message.user = $root.protocol.ClientUser.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AuthUserResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AuthUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AuthUserResponse} AuthUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthUserResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AuthUserResponse message.
             * @function verify
             * @memberof protocol.AuthUserResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AuthUserResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                if (message.user != null && message.hasOwnProperty("user")) {
                    var error = $root.protocol.ClientUser.verify(message.user);
                    if (error)
                        return "user." + error;
                }
                return null;
            };
    
            /**
             * Creates an AuthUserResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AuthUserResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AuthUserResponse} AuthUserResponse
             */
            AuthUserResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AuthUserResponse)
                    return object;
                var message = new $root.protocol.AuthUserResponse();
                if (object.token != null)
                    message.token = String(object.token);
                if (object.user != null) {
                    if (typeof object.user !== "object")
                        throw TypeError(".protocol.AuthUserResponse.user: object expected");
                    message.user = $root.protocol.ClientUser.fromObject(object.user);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an AuthUserResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AuthUserResponse
             * @static
             * @param {protocol.AuthUserResponse} message AuthUserResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AuthUserResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.token = "";
                    object.user = null;
                }
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                if (message.user != null && message.hasOwnProperty("user"))
                    object.user = $root.protocol.ClientUser.toObject(message.user, options);
                return object;
            };
    
            /**
             * Converts this AuthUserResponse to JSON.
             * @function toJSON
             * @memberof protocol.AuthUserResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AuthUserResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AuthUserResponse;
        })();
    
        protocol.CheckSessionRequest = (function() {
    
            /**
             * Properties of a CheckSessionRequest.
             * @memberof protocol
             * @interface ICheckSessionRequest
             * @property {string|null} [token] CheckSessionRequest token
             */
    
            /**
             * Constructs a new CheckSessionRequest.
             * @memberof protocol
             * @classdesc Represents a CheckSessionRequest.
             * @implements ICheckSessionRequest
             * @constructor
             * @param {protocol.ICheckSessionRequest=} [properties] Properties to set
             */
            function CheckSessionRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CheckSessionRequest token.
             * @member {string} token
             * @memberof protocol.CheckSessionRequest
             * @instance
             */
            CheckSessionRequest.prototype.token = "";
    
            /**
             * Creates a new CheckSessionRequest instance using the specified properties.
             * @function create
             * @memberof protocol.CheckSessionRequest
             * @static
             * @param {protocol.ICheckSessionRequest=} [properties] Properties to set
             * @returns {protocol.CheckSessionRequest} CheckSessionRequest instance
             */
            CheckSessionRequest.create = function create(properties) {
                return new CheckSessionRequest(properties);
            };
    
            /**
             * Encodes the specified CheckSessionRequest message. Does not implicitly {@link protocol.CheckSessionRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.CheckSessionRequest
             * @static
             * @param {protocol.ICheckSessionRequest} message CheckSessionRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckSessionRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                return writer;
            };
    
            /**
             * Encodes the specified CheckSessionRequest message, length delimited. Does not implicitly {@link protocol.CheckSessionRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.CheckSessionRequest
             * @static
             * @param {protocol.ICheckSessionRequest} message CheckSessionRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckSessionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CheckSessionRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.CheckSessionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.CheckSessionRequest} CheckSessionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckSessionRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.CheckSessionRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.token = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CheckSessionRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.CheckSessionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.CheckSessionRequest} CheckSessionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckSessionRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CheckSessionRequest message.
             * @function verify
             * @memberof protocol.CheckSessionRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CheckSessionRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                return null;
            };
    
            /**
             * Creates a CheckSessionRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.CheckSessionRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.CheckSessionRequest} CheckSessionRequest
             */
            CheckSessionRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.CheckSessionRequest)
                    return object;
                var message = new $root.protocol.CheckSessionRequest();
                if (object.token != null)
                    message.token = String(object.token);
                return message;
            };
    
            /**
             * Creates a plain object from a CheckSessionRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.CheckSessionRequest
             * @static
             * @param {protocol.CheckSessionRequest} message CheckSessionRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CheckSessionRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.token = "";
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                return object;
            };
    
            /**
             * Converts this CheckSessionRequest to JSON.
             * @function toJSON
             * @memberof protocol.CheckSessionRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CheckSessionRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return CheckSessionRequest;
        })();
    
        protocol.CheckSessionResponse = (function() {
    
            /**
             * Properties of a CheckSessionResponse.
             * @memberof protocol
             * @interface ICheckSessionResponse
             * @property {protocol.IClientUser|null} [user] CheckSessionResponse user
             */
    
            /**
             * Constructs a new CheckSessionResponse.
             * @memberof protocol
             * @classdesc Represents a CheckSessionResponse.
             * @implements ICheckSessionResponse
             * @constructor
             * @param {protocol.ICheckSessionResponse=} [properties] Properties to set
             */
            function CheckSessionResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CheckSessionResponse user.
             * @member {protocol.IClientUser|null|undefined} user
             * @memberof protocol.CheckSessionResponse
             * @instance
             */
            CheckSessionResponse.prototype.user = null;
    
            /**
             * Creates a new CheckSessionResponse instance using the specified properties.
             * @function create
             * @memberof protocol.CheckSessionResponse
             * @static
             * @param {protocol.ICheckSessionResponse=} [properties] Properties to set
             * @returns {protocol.CheckSessionResponse} CheckSessionResponse instance
             */
            CheckSessionResponse.create = function create(properties) {
                return new CheckSessionResponse(properties);
            };
    
            /**
             * Encodes the specified CheckSessionResponse message. Does not implicitly {@link protocol.CheckSessionResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.CheckSessionResponse
             * @static
             * @param {protocol.ICheckSessionResponse} message CheckSessionResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckSessionResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                    $root.protocol.ClientUser.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified CheckSessionResponse message, length delimited. Does not implicitly {@link protocol.CheckSessionResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.CheckSessionResponse
             * @static
             * @param {protocol.ICheckSessionResponse} message CheckSessionResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckSessionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CheckSessionResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.CheckSessionResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.CheckSessionResponse} CheckSessionResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckSessionResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.CheckSessionResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.user = $root.protocol.ClientUser.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CheckSessionResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.CheckSessionResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.CheckSessionResponse} CheckSessionResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckSessionResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CheckSessionResponse message.
             * @function verify
             * @memberof protocol.CheckSessionResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CheckSessionResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.user != null && message.hasOwnProperty("user")) {
                    var error = $root.protocol.ClientUser.verify(message.user);
                    if (error)
                        return "user." + error;
                }
                return null;
            };
    
            /**
             * Creates a CheckSessionResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.CheckSessionResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.CheckSessionResponse} CheckSessionResponse
             */
            CheckSessionResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.CheckSessionResponse)
                    return object;
                var message = new $root.protocol.CheckSessionResponse();
                if (object.user != null) {
                    if (typeof object.user !== "object")
                        throw TypeError(".protocol.CheckSessionResponse.user: object expected");
                    message.user = $root.protocol.ClientUser.fromObject(object.user);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CheckSessionResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.CheckSessionResponse
             * @static
             * @param {protocol.CheckSessionResponse} message CheckSessionResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CheckSessionResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.user = null;
                if (message.user != null && message.hasOwnProperty("user"))
                    object.user = $root.protocol.ClientUser.toObject(message.user, options);
                return object;
            };
    
            /**
             * Converts this CheckSessionResponse to JSON.
             * @function toJSON
             * @memberof protocol.CheckSessionResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CheckSessionResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return CheckSessionResponse;
        })();
    
        protocol.ClientUser = (function() {
    
            /**
             * Properties of a ClientUser.
             * @memberof protocol
             * @interface IClientUser
             * @property {number|null} [id] ClientUser id
             * @property {string|null} [login] ClientUser login
             * @property {boolean|null} [isRoot] ClientUser isRoot
             * @property {Array.<string>|null} [accessGroups] ClientUser accessGroups
             * @property {Long|null} [lastLoginAt] ClientUser lastLoginAt
             */
    
            /**
             * Constructs a new ClientUser.
             * @memberof protocol
             * @classdesc Represents a ClientUser.
             * @implements IClientUser
             * @constructor
             * @param {protocol.IClientUser=} [properties] Properties to set
             */
            function ClientUser(properties) {
                this.accessGroups = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientUser id.
             * @member {number} id
             * @memberof protocol.ClientUser
             * @instance
             */
            ClientUser.prototype.id = 0;
    
            /**
             * ClientUser login.
             * @member {string} login
             * @memberof protocol.ClientUser
             * @instance
             */
            ClientUser.prototype.login = "";
    
            /**
             * ClientUser isRoot.
             * @member {boolean} isRoot
             * @memberof protocol.ClientUser
             * @instance
             */
            ClientUser.prototype.isRoot = false;
    
            /**
             * ClientUser accessGroups.
             * @member {Array.<string>} accessGroups
             * @memberof protocol.ClientUser
             * @instance
             */
            ClientUser.prototype.accessGroups = $util.emptyArray;
    
            /**
             * ClientUser lastLoginAt.
             * @member {Long} lastLoginAt
             * @memberof protocol.ClientUser
             * @instance
             */
            ClientUser.prototype.lastLoginAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new ClientUser instance using the specified properties.
             * @function create
             * @memberof protocol.ClientUser
             * @static
             * @param {protocol.IClientUser=} [properties] Properties to set
             * @returns {protocol.ClientUser} ClientUser instance
             */
            ClientUser.create = function create(properties) {
                return new ClientUser(properties);
            };
    
            /**
             * Encodes the specified ClientUser message. Does not implicitly {@link protocol.ClientUser.verify|verify} messages.
             * @function encode
             * @memberof protocol.ClientUser
             * @static
             * @param {protocol.IClientUser} message ClientUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientUser.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.login);
                if (message.isRoot != null && Object.hasOwnProperty.call(message, "isRoot"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isRoot);
                if (message.accessGroups != null && message.accessGroups.length)
                    for (var i = 0; i < message.accessGroups.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.accessGroups[i]);
                if (message.lastLoginAt != null && Object.hasOwnProperty.call(message, "lastLoginAt"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.lastLoginAt);
                return writer;
            };
    
            /**
             * Encodes the specified ClientUser message, length delimited. Does not implicitly {@link protocol.ClientUser.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ClientUser
             * @static
             * @param {protocol.IClientUser} message ClientUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientUser.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientUser message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ClientUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ClientUser} ClientUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientUser.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ClientUser();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint32();
                        break;
                    case 2:
                        message.login = reader.string();
                        break;
                    case 3:
                        message.isRoot = reader.bool();
                        break;
                    case 4:
                        if (!(message.accessGroups && message.accessGroups.length))
                            message.accessGroups = [];
                        message.accessGroups.push(reader.string());
                        break;
                    case 5:
                        message.lastLoginAt = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientUser message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ClientUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ClientUser} ClientUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientUser.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientUser message.
             * @function verify
             * @memberof protocol.ClientUser
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientUser.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.login != null && message.hasOwnProperty("login"))
                    if (!$util.isString(message.login))
                        return "login: string expected";
                if (message.isRoot != null && message.hasOwnProperty("isRoot"))
                    if (typeof message.isRoot !== "boolean")
                        return "isRoot: boolean expected";
                if (message.accessGroups != null && message.hasOwnProperty("accessGroups")) {
                    if (!Array.isArray(message.accessGroups))
                        return "accessGroups: array expected";
                    for (var i = 0; i < message.accessGroups.length; ++i)
                        if (!$util.isString(message.accessGroups[i]))
                            return "accessGroups: string[] expected";
                }
                if (message.lastLoginAt != null && message.hasOwnProperty("lastLoginAt"))
                    if (!$util.isInteger(message.lastLoginAt) && !(message.lastLoginAt && $util.isInteger(message.lastLoginAt.low) && $util.isInteger(message.lastLoginAt.high)))
                        return "lastLoginAt: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a ClientUser message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ClientUser
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ClientUser} ClientUser
             */
            ClientUser.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ClientUser)
                    return object;
                var message = new $root.protocol.ClientUser();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.login != null)
                    message.login = String(object.login);
                if (object.isRoot != null)
                    message.isRoot = Boolean(object.isRoot);
                if (object.accessGroups) {
                    if (!Array.isArray(object.accessGroups))
                        throw TypeError(".protocol.ClientUser.accessGroups: array expected");
                    message.accessGroups = [];
                    for (var i = 0; i < object.accessGroups.length; ++i)
                        message.accessGroups[i] = String(object.accessGroups[i]);
                }
                if (object.lastLoginAt != null)
                    if ($util.Long)
                        (message.lastLoginAt = $util.Long.fromValue(object.lastLoginAt)).unsigned = false;
                    else if (typeof object.lastLoginAt === "string")
                        message.lastLoginAt = parseInt(object.lastLoginAt, 10);
                    else if (typeof object.lastLoginAt === "number")
                        message.lastLoginAt = object.lastLoginAt;
                    else if (typeof object.lastLoginAt === "object")
                        message.lastLoginAt = new $util.LongBits(object.lastLoginAt.low >>> 0, object.lastLoginAt.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a ClientUser message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ClientUser
             * @static
             * @param {protocol.ClientUser} message ClientUser
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientUser.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.accessGroups = [];
                if (options.defaults) {
                    object.id = 0;
                    object.login = "";
                    object.isRoot = false;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.lastLoginAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lastLoginAt = options.longs === String ? "0" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.login != null && message.hasOwnProperty("login"))
                    object.login = message.login;
                if (message.isRoot != null && message.hasOwnProperty("isRoot"))
                    object.isRoot = message.isRoot;
                if (message.accessGroups && message.accessGroups.length) {
                    object.accessGroups = [];
                    for (var j = 0; j < message.accessGroups.length; ++j)
                        object.accessGroups[j] = message.accessGroups[j];
                }
                if (message.lastLoginAt != null && message.hasOwnProperty("lastLoginAt"))
                    if (typeof message.lastLoginAt === "number")
                        object.lastLoginAt = options.longs === String ? String(message.lastLoginAt) : message.lastLoginAt;
                    else
                        object.lastLoginAt = options.longs === String ? $util.Long.prototype.toString.call(message.lastLoginAt) : options.longs === Number ? new $util.LongBits(message.lastLoginAt.low >>> 0, message.lastLoginAt.high >>> 0).toNumber() : message.lastLoginAt;
                return object;
            };
    
            /**
             * Converts this ClientUser to JSON.
             * @function toJSON
             * @memberof protocol.ClientUser
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientUser.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ClientUser;
        })();
    
        protocol.AdminCreateClientUserRequest = (function() {
    
            /**
             * Properties of an AdminCreateClientUserRequest.
             * @memberof protocol
             * @interface IAdminCreateClientUserRequest
             * @property {string|null} [login] AdminCreateClientUserRequest login
             * @property {string|null} [password] AdminCreateClientUserRequest password
             * @property {Array.<string>|null} [accessGroups] AdminCreateClientUserRequest accessGroups
             */
    
            /**
             * Constructs a new AdminCreateClientUserRequest.
             * @memberof protocol
             * @classdesc Represents an AdminCreateClientUserRequest.
             * @implements IAdminCreateClientUserRequest
             * @constructor
             * @param {protocol.IAdminCreateClientUserRequest=} [properties] Properties to set
             */
            function AdminCreateClientUserRequest(properties) {
                this.accessGroups = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AdminCreateClientUserRequest login.
             * @member {string} login
             * @memberof protocol.AdminCreateClientUserRequest
             * @instance
             */
            AdminCreateClientUserRequest.prototype.login = "";
    
            /**
             * AdminCreateClientUserRequest password.
             * @member {string} password
             * @memberof protocol.AdminCreateClientUserRequest
             * @instance
             */
            AdminCreateClientUserRequest.prototype.password = "";
    
            /**
             * AdminCreateClientUserRequest accessGroups.
             * @member {Array.<string>} accessGroups
             * @memberof protocol.AdminCreateClientUserRequest
             * @instance
             */
            AdminCreateClientUserRequest.prototype.accessGroups = $util.emptyArray;
    
            /**
             * Creates a new AdminCreateClientUserRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AdminCreateClientUserRequest
             * @static
             * @param {protocol.IAdminCreateClientUserRequest=} [properties] Properties to set
             * @returns {protocol.AdminCreateClientUserRequest} AdminCreateClientUserRequest instance
             */
            AdminCreateClientUserRequest.create = function create(properties) {
                return new AdminCreateClientUserRequest(properties);
            };
    
            /**
             * Encodes the specified AdminCreateClientUserRequest message. Does not implicitly {@link protocol.AdminCreateClientUserRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AdminCreateClientUserRequest
             * @static
             * @param {protocol.IAdminCreateClientUserRequest} message AdminCreateClientUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminCreateClientUserRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.login);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                if (message.accessGroups != null && message.accessGroups.length)
                    for (var i = 0; i < message.accessGroups.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.accessGroups[i]);
                return writer;
            };
    
            /**
             * Encodes the specified AdminCreateClientUserRequest message, length delimited. Does not implicitly {@link protocol.AdminCreateClientUserRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AdminCreateClientUserRequest
             * @static
             * @param {protocol.IAdminCreateClientUserRequest} message AdminCreateClientUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminCreateClientUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AdminCreateClientUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AdminCreateClientUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AdminCreateClientUserRequest} AdminCreateClientUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminCreateClientUserRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AdminCreateClientUserRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.login = reader.string();
                        break;
                    case 2:
                        message.password = reader.string();
                        break;
                    case 3:
                        if (!(message.accessGroups && message.accessGroups.length))
                            message.accessGroups = [];
                        message.accessGroups.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AdminCreateClientUserRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AdminCreateClientUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AdminCreateClientUserRequest} AdminCreateClientUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminCreateClientUserRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AdminCreateClientUserRequest message.
             * @function verify
             * @memberof protocol.AdminCreateClientUserRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdminCreateClientUserRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.login != null && message.hasOwnProperty("login"))
                    if (!$util.isString(message.login))
                        return "login: string expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.accessGroups != null && message.hasOwnProperty("accessGroups")) {
                    if (!Array.isArray(message.accessGroups))
                        return "accessGroups: array expected";
                    for (var i = 0; i < message.accessGroups.length; ++i)
                        if (!$util.isString(message.accessGroups[i]))
                            return "accessGroups: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates an AdminCreateClientUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AdminCreateClientUserRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AdminCreateClientUserRequest} AdminCreateClientUserRequest
             */
            AdminCreateClientUserRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AdminCreateClientUserRequest)
                    return object;
                var message = new $root.protocol.AdminCreateClientUserRequest();
                if (object.login != null)
                    message.login = String(object.login);
                if (object.password != null)
                    message.password = String(object.password);
                if (object.accessGroups) {
                    if (!Array.isArray(object.accessGroups))
                        throw TypeError(".protocol.AdminCreateClientUserRequest.accessGroups: array expected");
                    message.accessGroups = [];
                    for (var i = 0; i < object.accessGroups.length; ++i)
                        message.accessGroups[i] = String(object.accessGroups[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an AdminCreateClientUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AdminCreateClientUserRequest
             * @static
             * @param {protocol.AdminCreateClientUserRequest} message AdminCreateClientUserRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdminCreateClientUserRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.accessGroups = [];
                if (options.defaults) {
                    object.login = "";
                    object.password = "";
                }
                if (message.login != null && message.hasOwnProperty("login"))
                    object.login = message.login;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.accessGroups && message.accessGroups.length) {
                    object.accessGroups = [];
                    for (var j = 0; j < message.accessGroups.length; ++j)
                        object.accessGroups[j] = message.accessGroups[j];
                }
                return object;
            };
    
            /**
             * Converts this AdminCreateClientUserRequest to JSON.
             * @function toJSON
             * @memberof protocol.AdminCreateClientUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdminCreateClientUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AdminCreateClientUserRequest;
        })();
    
        protocol.AdminCreateClientUserResponse = (function() {
    
            /**
             * Properties of an AdminCreateClientUserResponse.
             * @memberof protocol
             * @interface IAdminCreateClientUserResponse
             * @property {protocol.IClientUser|null} [user] AdminCreateClientUserResponse user
             */
    
            /**
             * Constructs a new AdminCreateClientUserResponse.
             * @memberof protocol
             * @classdesc Represents an AdminCreateClientUserResponse.
             * @implements IAdminCreateClientUserResponse
             * @constructor
             * @param {protocol.IAdminCreateClientUserResponse=} [properties] Properties to set
             */
            function AdminCreateClientUserResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AdminCreateClientUserResponse user.
             * @member {protocol.IClientUser|null|undefined} user
             * @memberof protocol.AdminCreateClientUserResponse
             * @instance
             */
            AdminCreateClientUserResponse.prototype.user = null;
    
            /**
             * Creates a new AdminCreateClientUserResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AdminCreateClientUserResponse
             * @static
             * @param {protocol.IAdminCreateClientUserResponse=} [properties] Properties to set
             * @returns {protocol.AdminCreateClientUserResponse} AdminCreateClientUserResponse instance
             */
            AdminCreateClientUserResponse.create = function create(properties) {
                return new AdminCreateClientUserResponse(properties);
            };
    
            /**
             * Encodes the specified AdminCreateClientUserResponse message. Does not implicitly {@link protocol.AdminCreateClientUserResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AdminCreateClientUserResponse
             * @static
             * @param {protocol.IAdminCreateClientUserResponse} message AdminCreateClientUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminCreateClientUserResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                    $root.protocol.ClientUser.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AdminCreateClientUserResponse message, length delimited. Does not implicitly {@link protocol.AdminCreateClientUserResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AdminCreateClientUserResponse
             * @static
             * @param {protocol.IAdminCreateClientUserResponse} message AdminCreateClientUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminCreateClientUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AdminCreateClientUserResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AdminCreateClientUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AdminCreateClientUserResponse} AdminCreateClientUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminCreateClientUserResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AdminCreateClientUserResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.user = $root.protocol.ClientUser.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AdminCreateClientUserResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AdminCreateClientUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AdminCreateClientUserResponse} AdminCreateClientUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminCreateClientUserResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AdminCreateClientUserResponse message.
             * @function verify
             * @memberof protocol.AdminCreateClientUserResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdminCreateClientUserResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.user != null && message.hasOwnProperty("user")) {
                    var error = $root.protocol.ClientUser.verify(message.user);
                    if (error)
                        return "user." + error;
                }
                return null;
            };
    
            /**
             * Creates an AdminCreateClientUserResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AdminCreateClientUserResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AdminCreateClientUserResponse} AdminCreateClientUserResponse
             */
            AdminCreateClientUserResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AdminCreateClientUserResponse)
                    return object;
                var message = new $root.protocol.AdminCreateClientUserResponse();
                if (object.user != null) {
                    if (typeof object.user !== "object")
                        throw TypeError(".protocol.AdminCreateClientUserResponse.user: object expected");
                    message.user = $root.protocol.ClientUser.fromObject(object.user);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an AdminCreateClientUserResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AdminCreateClientUserResponse
             * @static
             * @param {protocol.AdminCreateClientUserResponse} message AdminCreateClientUserResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdminCreateClientUserResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.user = null;
                if (message.user != null && message.hasOwnProperty("user"))
                    object.user = $root.protocol.ClientUser.toObject(message.user, options);
                return object;
            };
    
            /**
             * Converts this AdminCreateClientUserResponse to JSON.
             * @function toJSON
             * @memberof protocol.AdminCreateClientUserResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdminCreateClientUserResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AdminCreateClientUserResponse;
        })();
    
        protocol.AdminClientUsersListRequest = (function() {
    
            /**
             * Properties of an AdminClientUsersListRequest.
             * @memberof protocol
             * @interface IAdminClientUsersListRequest
             */
    
            /**
             * Constructs a new AdminClientUsersListRequest.
             * @memberof protocol
             * @classdesc Represents an AdminClientUsersListRequest.
             * @implements IAdminClientUsersListRequest
             * @constructor
             * @param {protocol.IAdminClientUsersListRequest=} [properties] Properties to set
             */
            function AdminClientUsersListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new AdminClientUsersListRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AdminClientUsersListRequest
             * @static
             * @param {protocol.IAdminClientUsersListRequest=} [properties] Properties to set
             * @returns {protocol.AdminClientUsersListRequest} AdminClientUsersListRequest instance
             */
            AdminClientUsersListRequest.create = function create(properties) {
                return new AdminClientUsersListRequest(properties);
            };
    
            /**
             * Encodes the specified AdminClientUsersListRequest message. Does not implicitly {@link protocol.AdminClientUsersListRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AdminClientUsersListRequest
             * @static
             * @param {protocol.IAdminClientUsersListRequest} message AdminClientUsersListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminClientUsersListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified AdminClientUsersListRequest message, length delimited. Does not implicitly {@link protocol.AdminClientUsersListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AdminClientUsersListRequest
             * @static
             * @param {protocol.IAdminClientUsersListRequest} message AdminClientUsersListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminClientUsersListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AdminClientUsersListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AdminClientUsersListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AdminClientUsersListRequest} AdminClientUsersListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminClientUsersListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AdminClientUsersListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AdminClientUsersListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AdminClientUsersListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AdminClientUsersListRequest} AdminClientUsersListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminClientUsersListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AdminClientUsersListRequest message.
             * @function verify
             * @memberof protocol.AdminClientUsersListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdminClientUsersListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates an AdminClientUsersListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AdminClientUsersListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AdminClientUsersListRequest} AdminClientUsersListRequest
             */
            AdminClientUsersListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AdminClientUsersListRequest)
                    return object;
                return new $root.protocol.AdminClientUsersListRequest();
            };
    
            /**
             * Creates a plain object from an AdminClientUsersListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AdminClientUsersListRequest
             * @static
             * @param {protocol.AdminClientUsersListRequest} message AdminClientUsersListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdminClientUsersListRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this AdminClientUsersListRequest to JSON.
             * @function toJSON
             * @memberof protocol.AdminClientUsersListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdminClientUsersListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AdminClientUsersListRequest;
        })();
    
        protocol.AdminClientUsersListResponse = (function() {
    
            /**
             * Properties of an AdminClientUsersListResponse.
             * @memberof protocol
             * @interface IAdminClientUsersListResponse
             * @property {Array.<protocol.IClientUser>|null} [users] AdminClientUsersListResponse users
             */
    
            /**
             * Constructs a new AdminClientUsersListResponse.
             * @memberof protocol
             * @classdesc Represents an AdminClientUsersListResponse.
             * @implements IAdminClientUsersListResponse
             * @constructor
             * @param {protocol.IAdminClientUsersListResponse=} [properties] Properties to set
             */
            function AdminClientUsersListResponse(properties) {
                this.users = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AdminClientUsersListResponse users.
             * @member {Array.<protocol.IClientUser>} users
             * @memberof protocol.AdminClientUsersListResponse
             * @instance
             */
            AdminClientUsersListResponse.prototype.users = $util.emptyArray;
    
            /**
             * Creates a new AdminClientUsersListResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AdminClientUsersListResponse
             * @static
             * @param {protocol.IAdminClientUsersListResponse=} [properties] Properties to set
             * @returns {protocol.AdminClientUsersListResponse} AdminClientUsersListResponse instance
             */
            AdminClientUsersListResponse.create = function create(properties) {
                return new AdminClientUsersListResponse(properties);
            };
    
            /**
             * Encodes the specified AdminClientUsersListResponse message. Does not implicitly {@link protocol.AdminClientUsersListResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AdminClientUsersListResponse
             * @static
             * @param {protocol.IAdminClientUsersListResponse} message AdminClientUsersListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminClientUsersListResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.users != null && message.users.length)
                    for (var i = 0; i < message.users.length; ++i)
                        $root.protocol.ClientUser.encode(message.users[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AdminClientUsersListResponse message, length delimited. Does not implicitly {@link protocol.AdminClientUsersListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AdminClientUsersListResponse
             * @static
             * @param {protocol.IAdminClientUsersListResponse} message AdminClientUsersListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminClientUsersListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AdminClientUsersListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AdminClientUsersListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AdminClientUsersListResponse} AdminClientUsersListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminClientUsersListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AdminClientUsersListResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.users && message.users.length))
                            message.users = [];
                        message.users.push($root.protocol.ClientUser.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AdminClientUsersListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AdminClientUsersListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AdminClientUsersListResponse} AdminClientUsersListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminClientUsersListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AdminClientUsersListResponse message.
             * @function verify
             * @memberof protocol.AdminClientUsersListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdminClientUsersListResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.users != null && message.hasOwnProperty("users")) {
                    if (!Array.isArray(message.users))
                        return "users: array expected";
                    for (var i = 0; i < message.users.length; ++i) {
                        var error = $root.protocol.ClientUser.verify(message.users[i]);
                        if (error)
                            return "users." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an AdminClientUsersListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AdminClientUsersListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AdminClientUsersListResponse} AdminClientUsersListResponse
             */
            AdminClientUsersListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AdminClientUsersListResponse)
                    return object;
                var message = new $root.protocol.AdminClientUsersListResponse();
                if (object.users) {
                    if (!Array.isArray(object.users))
                        throw TypeError(".protocol.AdminClientUsersListResponse.users: array expected");
                    message.users = [];
                    for (var i = 0; i < object.users.length; ++i) {
                        if (typeof object.users[i] !== "object")
                            throw TypeError(".protocol.AdminClientUsersListResponse.users: object expected");
                        message.users[i] = $root.protocol.ClientUser.fromObject(object.users[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from an AdminClientUsersListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AdminClientUsersListResponse
             * @static
             * @param {protocol.AdminClientUsersListResponse} message AdminClientUsersListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdminClientUsersListResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.users = [];
                if (message.users && message.users.length) {
                    object.users = [];
                    for (var j = 0; j < message.users.length; ++j)
                        object.users[j] = $root.protocol.ClientUser.toObject(message.users[j], options);
                }
                return object;
            };
    
            /**
             * Converts this AdminClientUsersListResponse to JSON.
             * @function toJSON
             * @memberof protocol.AdminClientUsersListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdminClientUsersListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AdminClientUsersListResponse;
        })();
    
        protocol.AdminUpdateClientUserAccessGroupsRequest = (function() {
    
            /**
             * Properties of an AdminUpdateClientUserAccessGroupsRequest.
             * @memberof protocol
             * @interface IAdminUpdateClientUserAccessGroupsRequest
             * @property {number|null} [userId] AdminUpdateClientUserAccessGroupsRequest userId
             * @property {Array.<string>|null} [accessGroups] AdminUpdateClientUserAccessGroupsRequest accessGroups
             */
    
            /**
             * Constructs a new AdminUpdateClientUserAccessGroupsRequest.
             * @memberof protocol
             * @classdesc Represents an AdminUpdateClientUserAccessGroupsRequest.
             * @implements IAdminUpdateClientUserAccessGroupsRequest
             * @constructor
             * @param {protocol.IAdminUpdateClientUserAccessGroupsRequest=} [properties] Properties to set
             */
            function AdminUpdateClientUserAccessGroupsRequest(properties) {
                this.accessGroups = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AdminUpdateClientUserAccessGroupsRequest userId.
             * @member {number} userId
             * @memberof protocol.AdminUpdateClientUserAccessGroupsRequest
             * @instance
             */
            AdminUpdateClientUserAccessGroupsRequest.prototype.userId = 0;
    
            /**
             * AdminUpdateClientUserAccessGroupsRequest accessGroups.
             * @member {Array.<string>} accessGroups
             * @memberof protocol.AdminUpdateClientUserAccessGroupsRequest
             * @instance
             */
            AdminUpdateClientUserAccessGroupsRequest.prototype.accessGroups = $util.emptyArray;
    
            /**
             * Creates a new AdminUpdateClientUserAccessGroupsRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AdminUpdateClientUserAccessGroupsRequest
             * @static
             * @param {protocol.IAdminUpdateClientUserAccessGroupsRequest=} [properties] Properties to set
             * @returns {protocol.AdminUpdateClientUserAccessGroupsRequest} AdminUpdateClientUserAccessGroupsRequest instance
             */
            AdminUpdateClientUserAccessGroupsRequest.create = function create(properties) {
                return new AdminUpdateClientUserAccessGroupsRequest(properties);
            };
    
            /**
             * Encodes the specified AdminUpdateClientUserAccessGroupsRequest message. Does not implicitly {@link protocol.AdminUpdateClientUserAccessGroupsRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AdminUpdateClientUserAccessGroupsRequest
             * @static
             * @param {protocol.IAdminUpdateClientUserAccessGroupsRequest} message AdminUpdateClientUserAccessGroupsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminUpdateClientUserAccessGroupsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.userId);
                if (message.accessGroups != null && message.accessGroups.length)
                    for (var i = 0; i < message.accessGroups.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.accessGroups[i]);
                return writer;
            };
    
            /**
             * Encodes the specified AdminUpdateClientUserAccessGroupsRequest message, length delimited. Does not implicitly {@link protocol.AdminUpdateClientUserAccessGroupsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AdminUpdateClientUserAccessGroupsRequest
             * @static
             * @param {protocol.IAdminUpdateClientUserAccessGroupsRequest} message AdminUpdateClientUserAccessGroupsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminUpdateClientUserAccessGroupsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AdminUpdateClientUserAccessGroupsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AdminUpdateClientUserAccessGroupsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AdminUpdateClientUserAccessGroupsRequest} AdminUpdateClientUserAccessGroupsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminUpdateClientUserAccessGroupsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AdminUpdateClientUserAccessGroupsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.userId = reader.uint32();
                        break;
                    case 2:
                        if (!(message.accessGroups && message.accessGroups.length))
                            message.accessGroups = [];
                        message.accessGroups.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AdminUpdateClientUserAccessGroupsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AdminUpdateClientUserAccessGroupsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AdminUpdateClientUserAccessGroupsRequest} AdminUpdateClientUserAccessGroupsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminUpdateClientUserAccessGroupsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AdminUpdateClientUserAccessGroupsRequest message.
             * @function verify
             * @memberof protocol.AdminUpdateClientUserAccessGroupsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdminUpdateClientUserAccessGroupsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isInteger(message.userId))
                        return "userId: integer expected";
                if (message.accessGroups != null && message.hasOwnProperty("accessGroups")) {
                    if (!Array.isArray(message.accessGroups))
                        return "accessGroups: array expected";
                    for (var i = 0; i < message.accessGroups.length; ++i)
                        if (!$util.isString(message.accessGroups[i]))
                            return "accessGroups: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates an AdminUpdateClientUserAccessGroupsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AdminUpdateClientUserAccessGroupsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AdminUpdateClientUserAccessGroupsRequest} AdminUpdateClientUserAccessGroupsRequest
             */
            AdminUpdateClientUserAccessGroupsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AdminUpdateClientUserAccessGroupsRequest)
                    return object;
                var message = new $root.protocol.AdminUpdateClientUserAccessGroupsRequest();
                if (object.userId != null)
                    message.userId = object.userId >>> 0;
                if (object.accessGroups) {
                    if (!Array.isArray(object.accessGroups))
                        throw TypeError(".protocol.AdminUpdateClientUserAccessGroupsRequest.accessGroups: array expected");
                    message.accessGroups = [];
                    for (var i = 0; i < object.accessGroups.length; ++i)
                        message.accessGroups[i] = String(object.accessGroups[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an AdminUpdateClientUserAccessGroupsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AdminUpdateClientUserAccessGroupsRequest
             * @static
             * @param {protocol.AdminUpdateClientUserAccessGroupsRequest} message AdminUpdateClientUserAccessGroupsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdminUpdateClientUserAccessGroupsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.accessGroups = [];
                if (options.defaults)
                    object.userId = 0;
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.accessGroups && message.accessGroups.length) {
                    object.accessGroups = [];
                    for (var j = 0; j < message.accessGroups.length; ++j)
                        object.accessGroups[j] = message.accessGroups[j];
                }
                return object;
            };
    
            /**
             * Converts this AdminUpdateClientUserAccessGroupsRequest to JSON.
             * @function toJSON
             * @memberof protocol.AdminUpdateClientUserAccessGroupsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdminUpdateClientUserAccessGroupsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AdminUpdateClientUserAccessGroupsRequest;
        })();
    
        protocol.AdminUpdateClientUserAccessGroupsResponse = (function() {
    
            /**
             * Properties of an AdminUpdateClientUserAccessGroupsResponse.
             * @memberof protocol
             * @interface IAdminUpdateClientUserAccessGroupsResponse
             */
    
            /**
             * Constructs a new AdminUpdateClientUserAccessGroupsResponse.
             * @memberof protocol
             * @classdesc Represents an AdminUpdateClientUserAccessGroupsResponse.
             * @implements IAdminUpdateClientUserAccessGroupsResponse
             * @constructor
             * @param {protocol.IAdminUpdateClientUserAccessGroupsResponse=} [properties] Properties to set
             */
            function AdminUpdateClientUserAccessGroupsResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new AdminUpdateClientUserAccessGroupsResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AdminUpdateClientUserAccessGroupsResponse
             * @static
             * @param {protocol.IAdminUpdateClientUserAccessGroupsResponse=} [properties] Properties to set
             * @returns {protocol.AdminUpdateClientUserAccessGroupsResponse} AdminUpdateClientUserAccessGroupsResponse instance
             */
            AdminUpdateClientUserAccessGroupsResponse.create = function create(properties) {
                return new AdminUpdateClientUserAccessGroupsResponse(properties);
            };
    
            /**
             * Encodes the specified AdminUpdateClientUserAccessGroupsResponse message. Does not implicitly {@link protocol.AdminUpdateClientUserAccessGroupsResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AdminUpdateClientUserAccessGroupsResponse
             * @static
             * @param {protocol.IAdminUpdateClientUserAccessGroupsResponse} message AdminUpdateClientUserAccessGroupsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminUpdateClientUserAccessGroupsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified AdminUpdateClientUserAccessGroupsResponse message, length delimited. Does not implicitly {@link protocol.AdminUpdateClientUserAccessGroupsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AdminUpdateClientUserAccessGroupsResponse
             * @static
             * @param {protocol.IAdminUpdateClientUserAccessGroupsResponse} message AdminUpdateClientUserAccessGroupsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminUpdateClientUserAccessGroupsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AdminUpdateClientUserAccessGroupsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AdminUpdateClientUserAccessGroupsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AdminUpdateClientUserAccessGroupsResponse} AdminUpdateClientUserAccessGroupsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminUpdateClientUserAccessGroupsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AdminUpdateClientUserAccessGroupsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AdminUpdateClientUserAccessGroupsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AdminUpdateClientUserAccessGroupsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AdminUpdateClientUserAccessGroupsResponse} AdminUpdateClientUserAccessGroupsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminUpdateClientUserAccessGroupsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AdminUpdateClientUserAccessGroupsResponse message.
             * @function verify
             * @memberof protocol.AdminUpdateClientUserAccessGroupsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdminUpdateClientUserAccessGroupsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates an AdminUpdateClientUserAccessGroupsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AdminUpdateClientUserAccessGroupsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AdminUpdateClientUserAccessGroupsResponse} AdminUpdateClientUserAccessGroupsResponse
             */
            AdminUpdateClientUserAccessGroupsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AdminUpdateClientUserAccessGroupsResponse)
                    return object;
                return new $root.protocol.AdminUpdateClientUserAccessGroupsResponse();
            };
    
            /**
             * Creates a plain object from an AdminUpdateClientUserAccessGroupsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AdminUpdateClientUserAccessGroupsResponse
             * @static
             * @param {protocol.AdminUpdateClientUserAccessGroupsResponse} message AdminUpdateClientUserAccessGroupsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdminUpdateClientUserAccessGroupsResponse.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this AdminUpdateClientUserAccessGroupsResponse to JSON.
             * @function toJSON
             * @memberof protocol.AdminUpdateClientUserAccessGroupsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdminUpdateClientUserAccessGroupsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AdminUpdateClientUserAccessGroupsResponse;
        })();
    
        protocol.AdminUpdateClientUserPasswordRequest = (function() {
    
            /**
             * Properties of an AdminUpdateClientUserPasswordRequest.
             * @memberof protocol
             * @interface IAdminUpdateClientUserPasswordRequest
             * @property {number|null} [userId] AdminUpdateClientUserPasswordRequest userId
             * @property {string|null} [newPassword] AdminUpdateClientUserPasswordRequest newPassword
             */
    
            /**
             * Constructs a new AdminUpdateClientUserPasswordRequest.
             * @memberof protocol
             * @classdesc Represents an AdminUpdateClientUserPasswordRequest.
             * @implements IAdminUpdateClientUserPasswordRequest
             * @constructor
             * @param {protocol.IAdminUpdateClientUserPasswordRequest=} [properties] Properties to set
             */
            function AdminUpdateClientUserPasswordRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AdminUpdateClientUserPasswordRequest userId.
             * @member {number} userId
             * @memberof protocol.AdminUpdateClientUserPasswordRequest
             * @instance
             */
            AdminUpdateClientUserPasswordRequest.prototype.userId = 0;
    
            /**
             * AdminUpdateClientUserPasswordRequest newPassword.
             * @member {string} newPassword
             * @memberof protocol.AdminUpdateClientUserPasswordRequest
             * @instance
             */
            AdminUpdateClientUserPasswordRequest.prototype.newPassword = "";
    
            /**
             * Creates a new AdminUpdateClientUserPasswordRequest instance using the specified properties.
             * @function create
             * @memberof protocol.AdminUpdateClientUserPasswordRequest
             * @static
             * @param {protocol.IAdminUpdateClientUserPasswordRequest=} [properties] Properties to set
             * @returns {protocol.AdminUpdateClientUserPasswordRequest} AdminUpdateClientUserPasswordRequest instance
             */
            AdminUpdateClientUserPasswordRequest.create = function create(properties) {
                return new AdminUpdateClientUserPasswordRequest(properties);
            };
    
            /**
             * Encodes the specified AdminUpdateClientUserPasswordRequest message. Does not implicitly {@link protocol.AdminUpdateClientUserPasswordRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.AdminUpdateClientUserPasswordRequest
             * @static
             * @param {protocol.IAdminUpdateClientUserPasswordRequest} message AdminUpdateClientUserPasswordRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminUpdateClientUserPasswordRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.userId);
                if (message.newPassword != null && Object.hasOwnProperty.call(message, "newPassword"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.newPassword);
                return writer;
            };
    
            /**
             * Encodes the specified AdminUpdateClientUserPasswordRequest message, length delimited. Does not implicitly {@link protocol.AdminUpdateClientUserPasswordRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AdminUpdateClientUserPasswordRequest
             * @static
             * @param {protocol.IAdminUpdateClientUserPasswordRequest} message AdminUpdateClientUserPasswordRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminUpdateClientUserPasswordRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AdminUpdateClientUserPasswordRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AdminUpdateClientUserPasswordRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AdminUpdateClientUserPasswordRequest} AdminUpdateClientUserPasswordRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminUpdateClientUserPasswordRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AdminUpdateClientUserPasswordRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.userId = reader.uint32();
                        break;
                    case 2:
                        message.newPassword = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AdminUpdateClientUserPasswordRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AdminUpdateClientUserPasswordRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AdminUpdateClientUserPasswordRequest} AdminUpdateClientUserPasswordRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminUpdateClientUserPasswordRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AdminUpdateClientUserPasswordRequest message.
             * @function verify
             * @memberof protocol.AdminUpdateClientUserPasswordRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdminUpdateClientUserPasswordRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isInteger(message.userId))
                        return "userId: integer expected";
                if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                    if (!$util.isString(message.newPassword))
                        return "newPassword: string expected";
                return null;
            };
    
            /**
             * Creates an AdminUpdateClientUserPasswordRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AdminUpdateClientUserPasswordRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AdminUpdateClientUserPasswordRequest} AdminUpdateClientUserPasswordRequest
             */
            AdminUpdateClientUserPasswordRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AdminUpdateClientUserPasswordRequest)
                    return object;
                var message = new $root.protocol.AdminUpdateClientUserPasswordRequest();
                if (object.userId != null)
                    message.userId = object.userId >>> 0;
                if (object.newPassword != null)
                    message.newPassword = String(object.newPassword);
                return message;
            };
    
            /**
             * Creates a plain object from an AdminUpdateClientUserPasswordRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AdminUpdateClientUserPasswordRequest
             * @static
             * @param {protocol.AdminUpdateClientUserPasswordRequest} message AdminUpdateClientUserPasswordRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdminUpdateClientUserPasswordRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.userId = 0;
                    object.newPassword = "";
                }
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                    object.newPassword = message.newPassword;
                return object;
            };
    
            /**
             * Converts this AdminUpdateClientUserPasswordRequest to JSON.
             * @function toJSON
             * @memberof protocol.AdminUpdateClientUserPasswordRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdminUpdateClientUserPasswordRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AdminUpdateClientUserPasswordRequest;
        })();
    
        protocol.AdminUpdateClientUserPasswordResponse = (function() {
    
            /**
             * Properties of an AdminUpdateClientUserPasswordResponse.
             * @memberof protocol
             * @interface IAdminUpdateClientUserPasswordResponse
             */
    
            /**
             * Constructs a new AdminUpdateClientUserPasswordResponse.
             * @memberof protocol
             * @classdesc Represents an AdminUpdateClientUserPasswordResponse.
             * @implements IAdminUpdateClientUserPasswordResponse
             * @constructor
             * @param {protocol.IAdminUpdateClientUserPasswordResponse=} [properties] Properties to set
             */
            function AdminUpdateClientUserPasswordResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new AdminUpdateClientUserPasswordResponse instance using the specified properties.
             * @function create
             * @memberof protocol.AdminUpdateClientUserPasswordResponse
             * @static
             * @param {protocol.IAdminUpdateClientUserPasswordResponse=} [properties] Properties to set
             * @returns {protocol.AdminUpdateClientUserPasswordResponse} AdminUpdateClientUserPasswordResponse instance
             */
            AdminUpdateClientUserPasswordResponse.create = function create(properties) {
                return new AdminUpdateClientUserPasswordResponse(properties);
            };
    
            /**
             * Encodes the specified AdminUpdateClientUserPasswordResponse message. Does not implicitly {@link protocol.AdminUpdateClientUserPasswordResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.AdminUpdateClientUserPasswordResponse
             * @static
             * @param {protocol.IAdminUpdateClientUserPasswordResponse} message AdminUpdateClientUserPasswordResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminUpdateClientUserPasswordResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified AdminUpdateClientUserPasswordResponse message, length delimited. Does not implicitly {@link protocol.AdminUpdateClientUserPasswordResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.AdminUpdateClientUserPasswordResponse
             * @static
             * @param {protocol.IAdminUpdateClientUserPasswordResponse} message AdminUpdateClientUserPasswordResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminUpdateClientUserPasswordResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AdminUpdateClientUserPasswordResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.AdminUpdateClientUserPasswordResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.AdminUpdateClientUserPasswordResponse} AdminUpdateClientUserPasswordResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminUpdateClientUserPasswordResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.AdminUpdateClientUserPasswordResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AdminUpdateClientUserPasswordResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.AdminUpdateClientUserPasswordResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.AdminUpdateClientUserPasswordResponse} AdminUpdateClientUserPasswordResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminUpdateClientUserPasswordResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AdminUpdateClientUserPasswordResponse message.
             * @function verify
             * @memberof protocol.AdminUpdateClientUserPasswordResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdminUpdateClientUserPasswordResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates an AdminUpdateClientUserPasswordResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.AdminUpdateClientUserPasswordResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.AdminUpdateClientUserPasswordResponse} AdminUpdateClientUserPasswordResponse
             */
            AdminUpdateClientUserPasswordResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.AdminUpdateClientUserPasswordResponse)
                    return object;
                return new $root.protocol.AdminUpdateClientUserPasswordResponse();
            };
    
            /**
             * Creates a plain object from an AdminUpdateClientUserPasswordResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.AdminUpdateClientUserPasswordResponse
             * @static
             * @param {protocol.AdminUpdateClientUserPasswordResponse} message AdminUpdateClientUserPasswordResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdminUpdateClientUserPasswordResponse.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this AdminUpdateClientUserPasswordResponse to JSON.
             * @function toJSON
             * @memberof protocol.AdminUpdateClientUserPasswordResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdminUpdateClientUserPasswordResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AdminUpdateClientUserPasswordResponse;
        })();
    
        protocol.HasChangesDashboardRequest = (function() {
    
            /**
             * Properties of a HasChangesDashboardRequest.
             * @memberof protocol
             * @interface IHasChangesDashboardRequest
             * @property {Long|null} [lastCheckTime] HasChangesDashboardRequest lastCheckTime
             */
    
            /**
             * Constructs a new HasChangesDashboardRequest.
             * @memberof protocol
             * @classdesc Represents a HasChangesDashboardRequest.
             * @implements IHasChangesDashboardRequest
             * @constructor
             * @param {protocol.IHasChangesDashboardRequest=} [properties] Properties to set
             */
            function HasChangesDashboardRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * HasChangesDashboardRequest lastCheckTime.
             * @member {Long} lastCheckTime
             * @memberof protocol.HasChangesDashboardRequest
             * @instance
             */
            HasChangesDashboardRequest.prototype.lastCheckTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new HasChangesDashboardRequest instance using the specified properties.
             * @function create
             * @memberof protocol.HasChangesDashboardRequest
             * @static
             * @param {protocol.IHasChangesDashboardRequest=} [properties] Properties to set
             * @returns {protocol.HasChangesDashboardRequest} HasChangesDashboardRequest instance
             */
            HasChangesDashboardRequest.create = function create(properties) {
                return new HasChangesDashboardRequest(properties);
            };
    
            /**
             * Encodes the specified HasChangesDashboardRequest message. Does not implicitly {@link protocol.HasChangesDashboardRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.HasChangesDashboardRequest
             * @static
             * @param {protocol.IHasChangesDashboardRequest} message HasChangesDashboardRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HasChangesDashboardRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.lastCheckTime != null && Object.hasOwnProperty.call(message, "lastCheckTime"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.lastCheckTime);
                return writer;
            };
    
            /**
             * Encodes the specified HasChangesDashboardRequest message, length delimited. Does not implicitly {@link protocol.HasChangesDashboardRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.HasChangesDashboardRequest
             * @static
             * @param {protocol.IHasChangesDashboardRequest} message HasChangesDashboardRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HasChangesDashboardRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a HasChangesDashboardRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.HasChangesDashboardRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.HasChangesDashboardRequest} HasChangesDashboardRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HasChangesDashboardRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.HasChangesDashboardRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.lastCheckTime = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a HasChangesDashboardRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.HasChangesDashboardRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.HasChangesDashboardRequest} HasChangesDashboardRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HasChangesDashboardRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a HasChangesDashboardRequest message.
             * @function verify
             * @memberof protocol.HasChangesDashboardRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            HasChangesDashboardRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.lastCheckTime != null && message.hasOwnProperty("lastCheckTime"))
                    if (!$util.isInteger(message.lastCheckTime) && !(message.lastCheckTime && $util.isInteger(message.lastCheckTime.low) && $util.isInteger(message.lastCheckTime.high)))
                        return "lastCheckTime: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a HasChangesDashboardRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.HasChangesDashboardRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.HasChangesDashboardRequest} HasChangesDashboardRequest
             */
            HasChangesDashboardRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.HasChangesDashboardRequest)
                    return object;
                var message = new $root.protocol.HasChangesDashboardRequest();
                if (object.lastCheckTime != null)
                    if ($util.Long)
                        (message.lastCheckTime = $util.Long.fromValue(object.lastCheckTime)).unsigned = false;
                    else if (typeof object.lastCheckTime === "string")
                        message.lastCheckTime = parseInt(object.lastCheckTime, 10);
                    else if (typeof object.lastCheckTime === "number")
                        message.lastCheckTime = object.lastCheckTime;
                    else if (typeof object.lastCheckTime === "object")
                        message.lastCheckTime = new $util.LongBits(object.lastCheckTime.low >>> 0, object.lastCheckTime.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a HasChangesDashboardRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.HasChangesDashboardRequest
             * @static
             * @param {protocol.HasChangesDashboardRequest} message HasChangesDashboardRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            HasChangesDashboardRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.lastCheckTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lastCheckTime = options.longs === String ? "0" : 0;
                if (message.lastCheckTime != null && message.hasOwnProperty("lastCheckTime"))
                    if (typeof message.lastCheckTime === "number")
                        object.lastCheckTime = options.longs === String ? String(message.lastCheckTime) : message.lastCheckTime;
                    else
                        object.lastCheckTime = options.longs === String ? $util.Long.prototype.toString.call(message.lastCheckTime) : options.longs === Number ? new $util.LongBits(message.lastCheckTime.low >>> 0, message.lastCheckTime.high >>> 0).toNumber() : message.lastCheckTime;
                return object;
            };
    
            /**
             * Converts this HasChangesDashboardRequest to JSON.
             * @function toJSON
             * @memberof protocol.HasChangesDashboardRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            HasChangesDashboardRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return HasChangesDashboardRequest;
        })();
    
        protocol.HasChangesDashboardResponse = (function() {
    
            /**
             * Properties of a HasChangesDashboardResponse.
             * @memberof protocol
             * @interface IHasChangesDashboardResponse
             * @property {boolean|null} [hasChanges] HasChangesDashboardResponse hasChanges
             */
    
            /**
             * Constructs a new HasChangesDashboardResponse.
             * @memberof protocol
             * @classdesc Represents a HasChangesDashboardResponse.
             * @implements IHasChangesDashboardResponse
             * @constructor
             * @param {protocol.IHasChangesDashboardResponse=} [properties] Properties to set
             */
            function HasChangesDashboardResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * HasChangesDashboardResponse hasChanges.
             * @member {boolean} hasChanges
             * @memberof protocol.HasChangesDashboardResponse
             * @instance
             */
            HasChangesDashboardResponse.prototype.hasChanges = false;
    
            /**
             * Creates a new HasChangesDashboardResponse instance using the specified properties.
             * @function create
             * @memberof protocol.HasChangesDashboardResponse
             * @static
             * @param {protocol.IHasChangesDashboardResponse=} [properties] Properties to set
             * @returns {protocol.HasChangesDashboardResponse} HasChangesDashboardResponse instance
             */
            HasChangesDashboardResponse.create = function create(properties) {
                return new HasChangesDashboardResponse(properties);
            };
    
            /**
             * Encodes the specified HasChangesDashboardResponse message. Does not implicitly {@link protocol.HasChangesDashboardResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.HasChangesDashboardResponse
             * @static
             * @param {protocol.IHasChangesDashboardResponse} message HasChangesDashboardResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HasChangesDashboardResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.hasChanges != null && Object.hasOwnProperty.call(message, "hasChanges"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.hasChanges);
                return writer;
            };
    
            /**
             * Encodes the specified HasChangesDashboardResponse message, length delimited. Does not implicitly {@link protocol.HasChangesDashboardResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.HasChangesDashboardResponse
             * @static
             * @param {protocol.IHasChangesDashboardResponse} message HasChangesDashboardResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HasChangesDashboardResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a HasChangesDashboardResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.HasChangesDashboardResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.HasChangesDashboardResponse} HasChangesDashboardResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HasChangesDashboardResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.HasChangesDashboardResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.hasChanges = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a HasChangesDashboardResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.HasChangesDashboardResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.HasChangesDashboardResponse} HasChangesDashboardResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HasChangesDashboardResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a HasChangesDashboardResponse message.
             * @function verify
             * @memberof protocol.HasChangesDashboardResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            HasChangesDashboardResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.hasChanges != null && message.hasOwnProperty("hasChanges"))
                    if (typeof message.hasChanges !== "boolean")
                        return "hasChanges: boolean expected";
                return null;
            };
    
            /**
             * Creates a HasChangesDashboardResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.HasChangesDashboardResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.HasChangesDashboardResponse} HasChangesDashboardResponse
             */
            HasChangesDashboardResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.HasChangesDashboardResponse)
                    return object;
                var message = new $root.protocol.HasChangesDashboardResponse();
                if (object.hasChanges != null)
                    message.hasChanges = Boolean(object.hasChanges);
                return message;
            };
    
            /**
             * Creates a plain object from a HasChangesDashboardResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.HasChangesDashboardResponse
             * @static
             * @param {protocol.HasChangesDashboardResponse} message HasChangesDashboardResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            HasChangesDashboardResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.hasChanges = false;
                if (message.hasChanges != null && message.hasOwnProperty("hasChanges"))
                    object.hasChanges = message.hasChanges;
                return object;
            };
    
            /**
             * Converts this HasChangesDashboardResponse to JSON.
             * @function toJSON
             * @memberof protocol.HasChangesDashboardResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            HasChangesDashboardResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return HasChangesDashboardResponse;
        })();
    
        protocol.LatestChangesDashboardRequest = (function() {
    
            /**
             * Properties of a LatestChangesDashboardRequest.
             * @memberof protocol
             * @interface ILatestChangesDashboardRequest
             * @property {Long|null} [lastCheckTime] LatestChangesDashboardRequest lastCheckTime
             */
    
            /**
             * Constructs a new LatestChangesDashboardRequest.
             * @memberof protocol
             * @classdesc Represents a LatestChangesDashboardRequest.
             * @implements ILatestChangesDashboardRequest
             * @constructor
             * @param {protocol.ILatestChangesDashboardRequest=} [properties] Properties to set
             */
            function LatestChangesDashboardRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * LatestChangesDashboardRequest lastCheckTime.
             * @member {Long} lastCheckTime
             * @memberof protocol.LatestChangesDashboardRequest
             * @instance
             */
            LatestChangesDashboardRequest.prototype.lastCheckTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new LatestChangesDashboardRequest instance using the specified properties.
             * @function create
             * @memberof protocol.LatestChangesDashboardRequest
             * @static
             * @param {protocol.ILatestChangesDashboardRequest=} [properties] Properties to set
             * @returns {protocol.LatestChangesDashboardRequest} LatestChangesDashboardRequest instance
             */
            LatestChangesDashboardRequest.create = function create(properties) {
                return new LatestChangesDashboardRequest(properties);
            };
    
            /**
             * Encodes the specified LatestChangesDashboardRequest message. Does not implicitly {@link protocol.LatestChangesDashboardRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.LatestChangesDashboardRequest
             * @static
             * @param {protocol.ILatestChangesDashboardRequest} message LatestChangesDashboardRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LatestChangesDashboardRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.lastCheckTime != null && Object.hasOwnProperty.call(message, "lastCheckTime"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.lastCheckTime);
                return writer;
            };
    
            /**
             * Encodes the specified LatestChangesDashboardRequest message, length delimited. Does not implicitly {@link protocol.LatestChangesDashboardRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.LatestChangesDashboardRequest
             * @static
             * @param {protocol.ILatestChangesDashboardRequest} message LatestChangesDashboardRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LatestChangesDashboardRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a LatestChangesDashboardRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.LatestChangesDashboardRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.LatestChangesDashboardRequest} LatestChangesDashboardRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LatestChangesDashboardRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.LatestChangesDashboardRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.lastCheckTime = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a LatestChangesDashboardRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.LatestChangesDashboardRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.LatestChangesDashboardRequest} LatestChangesDashboardRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LatestChangesDashboardRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a LatestChangesDashboardRequest message.
             * @function verify
             * @memberof protocol.LatestChangesDashboardRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LatestChangesDashboardRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.lastCheckTime != null && message.hasOwnProperty("lastCheckTime"))
                    if (!$util.isInteger(message.lastCheckTime) && !(message.lastCheckTime && $util.isInteger(message.lastCheckTime.low) && $util.isInteger(message.lastCheckTime.high)))
                        return "lastCheckTime: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a LatestChangesDashboardRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.LatestChangesDashboardRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.LatestChangesDashboardRequest} LatestChangesDashboardRequest
             */
            LatestChangesDashboardRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.LatestChangesDashboardRequest)
                    return object;
                var message = new $root.protocol.LatestChangesDashboardRequest();
                if (object.lastCheckTime != null)
                    if ($util.Long)
                        (message.lastCheckTime = $util.Long.fromValue(object.lastCheckTime)).unsigned = false;
                    else if (typeof object.lastCheckTime === "string")
                        message.lastCheckTime = parseInt(object.lastCheckTime, 10);
                    else if (typeof object.lastCheckTime === "number")
                        message.lastCheckTime = object.lastCheckTime;
                    else if (typeof object.lastCheckTime === "object")
                        message.lastCheckTime = new $util.LongBits(object.lastCheckTime.low >>> 0, object.lastCheckTime.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a LatestChangesDashboardRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.LatestChangesDashboardRequest
             * @static
             * @param {protocol.LatestChangesDashboardRequest} message LatestChangesDashboardRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LatestChangesDashboardRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.lastCheckTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lastCheckTime = options.longs === String ? "0" : 0;
                if (message.lastCheckTime != null && message.hasOwnProperty("lastCheckTime"))
                    if (typeof message.lastCheckTime === "number")
                        object.lastCheckTime = options.longs === String ? String(message.lastCheckTime) : message.lastCheckTime;
                    else
                        object.lastCheckTime = options.longs === String ? $util.Long.prototype.toString.call(message.lastCheckTime) : options.longs === Number ? new $util.LongBits(message.lastCheckTime.low >>> 0, message.lastCheckTime.high >>> 0).toNumber() : message.lastCheckTime;
                return object;
            };
    
            /**
             * Converts this LatestChangesDashboardRequest to JSON.
             * @function toJSON
             * @memberof protocol.LatestChangesDashboardRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LatestChangesDashboardRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return LatestChangesDashboardRequest;
        })();
    
        protocol.LatestChangesDashboardResponse = (function() {
    
            /**
             * Properties of a LatestChangesDashboardResponse.
             * @memberof protocol
             * @interface ILatestChangesDashboardResponse
             * @property {protocol.ITransactionsStats|null} [transactionsStats] LatestChangesDashboardResponse transactionsStats
             * @property {Array.<protocol.ITotalBalance>|null} [totalBalances] LatestChangesDashboardResponse totalBalances
             * @property {Array.<protocol.ITxRecord>|null} [transactions] LatestChangesDashboardResponse transactions
             */
    
            /**
             * Constructs a new LatestChangesDashboardResponse.
             * @memberof protocol
             * @classdesc Represents a LatestChangesDashboardResponse.
             * @implements ILatestChangesDashboardResponse
             * @constructor
             * @param {protocol.ILatestChangesDashboardResponse=} [properties] Properties to set
             */
            function LatestChangesDashboardResponse(properties) {
                this.totalBalances = [];
                this.transactions = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * LatestChangesDashboardResponse transactionsStats.
             * @member {protocol.ITransactionsStats|null|undefined} transactionsStats
             * @memberof protocol.LatestChangesDashboardResponse
             * @instance
             */
            LatestChangesDashboardResponse.prototype.transactionsStats = null;
    
            /**
             * LatestChangesDashboardResponse totalBalances.
             * @member {Array.<protocol.ITotalBalance>} totalBalances
             * @memberof protocol.LatestChangesDashboardResponse
             * @instance
             */
            LatestChangesDashboardResponse.prototype.totalBalances = $util.emptyArray;
    
            /**
             * LatestChangesDashboardResponse transactions.
             * @member {Array.<protocol.ITxRecord>} transactions
             * @memberof protocol.LatestChangesDashboardResponse
             * @instance
             */
            LatestChangesDashboardResponse.prototype.transactions = $util.emptyArray;
    
            /**
             * Creates a new LatestChangesDashboardResponse instance using the specified properties.
             * @function create
             * @memberof protocol.LatestChangesDashboardResponse
             * @static
             * @param {protocol.ILatestChangesDashboardResponse=} [properties] Properties to set
             * @returns {protocol.LatestChangesDashboardResponse} LatestChangesDashboardResponse instance
             */
            LatestChangesDashboardResponse.create = function create(properties) {
                return new LatestChangesDashboardResponse(properties);
            };
    
            /**
             * Encodes the specified LatestChangesDashboardResponse message. Does not implicitly {@link protocol.LatestChangesDashboardResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.LatestChangesDashboardResponse
             * @static
             * @param {protocol.ILatestChangesDashboardResponse} message LatestChangesDashboardResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LatestChangesDashboardResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.transactionsStats != null && Object.hasOwnProperty.call(message, "transactionsStats"))
                    $root.protocol.TransactionsStats.encode(message.transactionsStats, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.totalBalances != null && message.totalBalances.length)
                    for (var i = 0; i < message.totalBalances.length; ++i)
                        $root.protocol.TotalBalance.encode(message.totalBalances[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.transactions != null && message.transactions.length)
                    for (var i = 0; i < message.transactions.length; ++i)
                        $root.protocol.TxRecord.encode(message.transactions[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified LatestChangesDashboardResponse message, length delimited. Does not implicitly {@link protocol.LatestChangesDashboardResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.LatestChangesDashboardResponse
             * @static
             * @param {protocol.ILatestChangesDashboardResponse} message LatestChangesDashboardResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LatestChangesDashboardResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a LatestChangesDashboardResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.LatestChangesDashboardResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.LatestChangesDashboardResponse} LatestChangesDashboardResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LatestChangesDashboardResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.LatestChangesDashboardResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.transactionsStats = $root.protocol.TransactionsStats.decode(reader, reader.uint32());
                        break;
                    case 2:
                        if (!(message.totalBalances && message.totalBalances.length))
                            message.totalBalances = [];
                        message.totalBalances.push($root.protocol.TotalBalance.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        if (!(message.transactions && message.transactions.length))
                            message.transactions = [];
                        message.transactions.push($root.protocol.TxRecord.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a LatestChangesDashboardResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.LatestChangesDashboardResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.LatestChangesDashboardResponse} LatestChangesDashboardResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LatestChangesDashboardResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a LatestChangesDashboardResponse message.
             * @function verify
             * @memberof protocol.LatestChangesDashboardResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LatestChangesDashboardResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.transactionsStats != null && message.hasOwnProperty("transactionsStats")) {
                    var error = $root.protocol.TransactionsStats.verify(message.transactionsStats);
                    if (error)
                        return "transactionsStats." + error;
                }
                if (message.totalBalances != null && message.hasOwnProperty("totalBalances")) {
                    if (!Array.isArray(message.totalBalances))
                        return "totalBalances: array expected";
                    for (var i = 0; i < message.totalBalances.length; ++i) {
                        var error = $root.protocol.TotalBalance.verify(message.totalBalances[i]);
                        if (error)
                            return "totalBalances." + error;
                    }
                }
                if (message.transactions != null && message.hasOwnProperty("transactions")) {
                    if (!Array.isArray(message.transactions))
                        return "transactions: array expected";
                    for (var i = 0; i < message.transactions.length; ++i) {
                        var error = $root.protocol.TxRecord.verify(message.transactions[i]);
                        if (error)
                            return "transactions." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a LatestChangesDashboardResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.LatestChangesDashboardResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.LatestChangesDashboardResponse} LatestChangesDashboardResponse
             */
            LatestChangesDashboardResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.LatestChangesDashboardResponse)
                    return object;
                var message = new $root.protocol.LatestChangesDashboardResponse();
                if (object.transactionsStats != null) {
                    if (typeof object.transactionsStats !== "object")
                        throw TypeError(".protocol.LatestChangesDashboardResponse.transactionsStats: object expected");
                    message.transactionsStats = $root.protocol.TransactionsStats.fromObject(object.transactionsStats);
                }
                if (object.totalBalances) {
                    if (!Array.isArray(object.totalBalances))
                        throw TypeError(".protocol.LatestChangesDashboardResponse.totalBalances: array expected");
                    message.totalBalances = [];
                    for (var i = 0; i < object.totalBalances.length; ++i) {
                        if (typeof object.totalBalances[i] !== "object")
                            throw TypeError(".protocol.LatestChangesDashboardResponse.totalBalances: object expected");
                        message.totalBalances[i] = $root.protocol.TotalBalance.fromObject(object.totalBalances[i]);
                    }
                }
                if (object.transactions) {
                    if (!Array.isArray(object.transactions))
                        throw TypeError(".protocol.LatestChangesDashboardResponse.transactions: array expected");
                    message.transactions = [];
                    for (var i = 0; i < object.transactions.length; ++i) {
                        if (typeof object.transactions[i] !== "object")
                            throw TypeError(".protocol.LatestChangesDashboardResponse.transactions: object expected");
                        message.transactions[i] = $root.protocol.TxRecord.fromObject(object.transactions[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a LatestChangesDashboardResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.LatestChangesDashboardResponse
             * @static
             * @param {protocol.LatestChangesDashboardResponse} message LatestChangesDashboardResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LatestChangesDashboardResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.totalBalances = [];
                    object.transactions = [];
                }
                if (options.defaults)
                    object.transactionsStats = null;
                if (message.transactionsStats != null && message.hasOwnProperty("transactionsStats"))
                    object.transactionsStats = $root.protocol.TransactionsStats.toObject(message.transactionsStats, options);
                if (message.totalBalances && message.totalBalances.length) {
                    object.totalBalances = [];
                    for (var j = 0; j < message.totalBalances.length; ++j)
                        object.totalBalances[j] = $root.protocol.TotalBalance.toObject(message.totalBalances[j], options);
                }
                if (message.transactions && message.transactions.length) {
                    object.transactions = [];
                    for (var j = 0; j < message.transactions.length; ++j)
                        object.transactions[j] = $root.protocol.TxRecord.toObject(message.transactions[j], options);
                }
                return object;
            };
    
            /**
             * Converts this LatestChangesDashboardResponse to JSON.
             * @function toJSON
             * @memberof protocol.LatestChangesDashboardResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LatestChangesDashboardResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return LatestChangesDashboardResponse;
        })();
    
        return protocol;
    })();
    
    $root.currencies = (function() {
    
        /**
         * Namespace currencies.
         * @exports currencies
         * @namespace
         */
        var currencies = {};
    
        /**
         * WLCurrency enum.
         * @name currencies.WLCurrency
         * @enum {number}
         * @property {number} WLC_FUN=0 WLC_FUN value
         * @property {number} WLC_ALL=8 WLC_ALL value
         * @property {number} WLC_DZD=12 WLC_DZD value
         * @property {number} WLC_ARS=32 WLC_ARS value
         * @property {number} WLC_AUD=36 WLC_AUD value
         * @property {number} WLC_BSD=44 WLC_BSD value
         * @property {number} WLC_BHD=48 WLC_BHD value
         * @property {number} WLC_BDT=50 WLC_BDT value
         * @property {number} WLC_AMD=51 WLC_AMD value
         * @property {number} WLC_BBD=52 WLC_BBD value
         * @property {number} WLC_BMD=60 WLC_BMD value
         * @property {number} WLC_BTN=64 WLC_BTN value
         * @property {number} WLC_BOB=68 WLC_BOB value
         * @property {number} WLC_BWP=72 WLC_BWP value
         * @property {number} WLC_BZD=84 WLC_BZD value
         * @property {number} WLC_SBD=90 WLC_SBD value
         * @property {number} WLC_BND=96 WLC_BND value
         * @property {number} WLC_MMK=104 WLC_MMK value
         * @property {number} WLC_BIF=108 WLC_BIF value
         * @property {number} WLC_KHR=116 WLC_KHR value
         * @property {number} WLC_CAD=124 WLC_CAD value
         * @property {number} WLC_CVE=132 WLC_CVE value
         * @property {number} WLC_KYD=136 WLC_KYD value
         * @property {number} WLC_LKR=144 WLC_LKR value
         * @property {number} WLC_CLP=152 WLC_CLP value
         * @property {number} WLC_CNY=156 WLC_CNY value
         * @property {number} WLC_COP=170 WLC_COP value
         * @property {number} WLC_KMF=174 WLC_KMF value
         * @property {number} WLC_CRC=188 WLC_CRC value
         * @property {number} WLC_HRK=191 WLC_HRK value
         * @property {number} WLC_CUP=192 WLC_CUP value
         * @property {number} WLC_CYP=196 WLC_CYP value
         * @property {number} WLC_CZK=203 WLC_CZK value
         * @property {number} WLC_DKK=208 WLC_DKK value
         * @property {number} WLC_DOP=214 WLC_DOP value
         * @property {number} WLC_SVC=222 WLC_SVC value
         * @property {number} WLC_ETB=230 WLC_ETB value
         * @property {number} WLC_ERN=232 WLC_ERN value
         * @property {number} WLC_EEK=233 WLC_EEK value
         * @property {number} WLC_FKP=238 WLC_FKP value
         * @property {number} WLC_FJD=242 WLC_FJD value
         * @property {number} WLC_DJF=262 WLC_DJF value
         * @property {number} WLC_GMD=270 WLC_GMD value
         * @property {number} WLC_GIP=292 WLC_GIP value
         * @property {number} WLC_GTQ=320 WLC_GTQ value
         * @property {number} WLC_GNF=324 WLC_GNF value
         * @property {number} WLC_GYD=328 WLC_GYD value
         * @property {number} WLC_HTG=332 WLC_HTG value
         * @property {number} WLC_HNL=340 WLC_HNL value
         * @property {number} WLC_HKD=344 WLC_HKD value
         * @property {number} WLC_HUF=348 WLC_HUF value
         * @property {number} WLC_ISK=352 WLC_ISK value
         * @property {number} WLC_INR=356 WLC_INR value
         * @property {number} WLC_IDR=360 WLC_IDR value
         * @property {number} WLC_IRR=364 WLC_IRR value
         * @property {number} WLC_IQD=368 WLC_IQD value
         * @property {number} WLC_ILS=376 WLC_ILS value
         * @property {number} WLC_JMD=388 WLC_JMD value
         * @property {number} WLC_JPY=392 WLC_JPY value
         * @property {number} WLC_KZT=398 WLC_KZT value
         * @property {number} WLC_JOD=400 WLC_JOD value
         * @property {number} WLC_KES=404 WLC_KES value
         * @property {number} WLC_KPW=408 WLC_KPW value
         * @property {number} WLC_KRW=410 WLC_KRW value
         * @property {number} WLC_KWD=414 WLC_KWD value
         * @property {number} WLC_KGS=417 WLC_KGS value
         * @property {number} WLC_LAK=418 WLC_LAK value
         * @property {number} WLC_LBP=422 WLC_LBP value
         * @property {number} WLC_LSL=426 WLC_LSL value
         * @property {number} WLC_LVL=428 WLC_LVL value
         * @property {number} WLC_LRD=430 WLC_LRD value
         * @property {number} WLC_LYD=434 WLC_LYD value
         * @property {number} WLC_LTL=440 WLC_LTL value
         * @property {number} WLC_MOP=446 WLC_MOP value
         * @property {number} WLC_MWK=454 WLC_MWK value
         * @property {number} WLC_MYR=458 WLC_MYR value
         * @property {number} WLC_MVR=462 WLC_MVR value
         * @property {number} WLC_MTL=470 WLC_MTL value
         * @property {number} WLC_MRO=478 WLC_MRO value
         * @property {number} WLC_MUR=480 WLC_MUR value
         * @property {number} WLC_MXN=484 WLC_MXN value
         * @property {number} WLC_MNT=496 WLC_MNT value
         * @property {number} WLC_MDL=498 WLC_MDL value
         * @property {number} WLC_MAD=504 WLC_MAD value
         * @property {number} WLC_OMR=512 WLC_OMR value
         * @property {number} WLC_NAD=516 WLC_NAD value
         * @property {number} WLC_NPR=524 WLC_NPR value
         * @property {number} WLC_ANG=532 WLC_ANG value
         * @property {number} WLC_AWG=533 WLC_AWG value
         * @property {number} WLC_VUV=548 WLC_VUV value
         * @property {number} WLC_NZD=554 WLC_NZD value
         * @property {number} WLC_NIO=558 WLC_NIO value
         * @property {number} WLC_NGN=566 WLC_NGN value
         * @property {number} WLC_NOK=578 WLC_NOK value
         * @property {number} WLC_PKR=586 WLC_PKR value
         * @property {number} WLC_PAB=590 WLC_PAB value
         * @property {number} WLC_PGK=598 WLC_PGK value
         * @property {number} WLC_PYG=600 WLC_PYG value
         * @property {number} WLC_PEN=604 WLC_PEN value
         * @property {number} WLC_PHP=608 WLC_PHP value
         * @property {number} WLC_QAR=634 WLC_QAR value
         * @property {number} WLC_RUB=643 WLC_RUB value
         * @property {number} WLC_RWF=646 WLC_RWF value
         * @property {number} WLC_SHP=654 WLC_SHP value
         * @property {number} WLC_STD=678 WLC_STD value
         * @property {number} WLC_SAR=682 WLC_SAR value
         * @property {number} WLC_SCR=690 WLC_SCR value
         * @property {number} WLC_SLL=694 WLC_SLL value
         * @property {number} WLC_SGD=702 WLC_SGD value
         * @property {number} WLC_SKK=703 WLC_SKK value
         * @property {number} WLC_VND=704 WLC_VND value
         * @property {number} WLC_SOS=706 WLC_SOS value
         * @property {number} WLC_ZAR=710 WLC_ZAR value
         * @property {number} WLC_ZWD=716 WLC_ZWD value
         * @property {number} WLC_SSP=728 WLC_SSP value
         * @property {number} WLC_SZL=748 WLC_SZL value
         * @property {number} WLC_SEK=752 WLC_SEK value
         * @property {number} WLC_CHF=756 WLC_CHF value
         * @property {number} WLC_SYP=760 WLC_SYP value
         * @property {number} WLC_THB=764 WLC_THB value
         * @property {number} WLC_TOP=776 WLC_TOP value
         * @property {number} WLC_TTD=780 WLC_TTD value
         * @property {number} WLC_AED=784 WLC_AED value
         * @property {number} WLC_TND=788 WLC_TND value
         * @property {number} WLC_TMM=795 WLC_TMM value
         * @property {number} WLC_UGX=800 WLC_UGX value
         * @property {number} WLC_MKD=807 WLC_MKD value
         * @property {number} WLC_RUR=810 WLC_RUR value
         * @property {number} WLC_EGP=818 WLC_EGP value
         * @property {number} WLC_GBP=826 WLC_GBP value
         * @property {number} WLC_TZS=834 WLC_TZS value
         * @property {number} WLC_USD=840 WLC_USD value
         * @property {number} WLC_UYU=858 WLC_UYU value
         * @property {number} WLC_UZS=860 WLC_UZS value
         * @property {number} WLC_WST=882 WLC_WST value
         * @property {number} WLC_YER=886 WLC_YER value
         * @property {number} WLC_ZMK=894 WLC_ZMK value
         * @property {number} WLC_TWD=901 WLC_TWD value
         * @property {number} WLC_CUC=931 WLC_CUC value
         * @property {number} WLC_ZWL=932 WLC_ZWL value
         * @property {number} WLC_BYN=933 WLC_BYN value
         * @property {number} WLC_TMT=934 WLC_TMT value
         * @property {number} WLC_GHC=936 WLC_GHC value
         * @property {number} WLC_VEF=937 WLC_VEF value
         * @property {number} WLC_SDG=938 WLC_SDG value
         * @property {number} WLC_RSD=941 WLC_RSD value
         * @property {number} WLC_MZN=943 WLC_MZN value
         * @property {number} WLC_AZN=944 WLC_AZN value
         * @property {number} WLC_RON=946 WLC_RON value
         * @property {number} WLC_TRY=949 WLC_TRY value
         * @property {number} WLC_XAF=950 WLC_XAF value
         * @property {number} WLC_XCD=951 WLC_XCD value
         * @property {number} WLC_XOF=952 WLC_XOF value
         * @property {number} WLC_XPF=953 WLC_XPF value
         * @property {number} WLC_XAU=959 WLC_XAU value
         * @property {number} WLC_XDR=960 WLC_XDR value
         * @property {number} WLC_XAG=961 WLC_XAG value
         * @property {number} WLC_ZMW=967 WLC_ZMW value
         * @property {number} WLC_SRD=968 WLC_SRD value
         * @property {number} WLC_MGA=969 WLC_MGA value
         * @property {number} WLC_AFN=971 WLC_AFN value
         * @property {number} WLC_TJS=972 WLC_TJS value
         * @property {number} WLC_AOA=973 WLC_AOA value
         * @property {number} WLC_BGN=975 WLC_BGN value
         * @property {number} WLC_CDF=976 WLC_CDF value
         * @property {number} WLC_BAM=977 WLC_BAM value
         * @property {number} WLC_EUR=978 WLC_EUR value
         * @property {number} WLC_UAH=980 WLC_UAH value
         * @property {number} WLC_GEL=981 WLC_GEL value
         * @property {number} WLC_PLN=985 WLC_PLN value
         * @property {number} WLC_BRL=986 WLC_BRL value
         * @property {number} WLC_CLF=990 WLC_CLF value
         * @property {number} WLC_ENS=6004 WLC_ENS value
         * @property {number} WLC_SSC=6005 WLC_SSC value
         * @property {number} WLC_GOC=6006 WLC_GOC value
         * @property {number} WLC_GGP=80001 WLC_GGP value
         * @property {number} WLC_IMP=80002 WLC_IMP value
         * @property {number} WLC_JEP=80003 WLC_JEP value
         * @property {number} WLC_LTCT=90001 WLC_LTCT value
         * @property {number} WLC_BTC=90002 WLC_BTC value
         * @property {number} WLC_ETH=90003 WLC_ETH value
         * @property {number} WLC_DASH=90004 WLC_DASH value
         * @property {number} WLC_ZEC=90005 WLC_ZEC value
         * @property {number} WLC_BCH=90006 WLC_BCH value
         * @property {number} WLC_LTC=90007 WLC_LTC value
         * @property {number} WLC_XRP=90008 WLC_XRP value
         * @property {number} WLC_ETN=90009 WLC_ETN value
         * @property {number} WLC_USDT=90010 WLC_USDT value
         * @property {number} WLC_USDTERC20=90011 WLC_USDTERC20 value
         * @property {number} WLC_USDTTRC20=90012 WLC_USDTTRC20 value
         * @property {number} WLC_TRX=90013 WLC_TRX value
         */
        currencies.WLCurrency = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "WLC_FUN"] = 0;
            values[valuesById[8] = "WLC_ALL"] = 8;
            values[valuesById[12] = "WLC_DZD"] = 12;
            values[valuesById[32] = "WLC_ARS"] = 32;
            values[valuesById[36] = "WLC_AUD"] = 36;
            values[valuesById[44] = "WLC_BSD"] = 44;
            values[valuesById[48] = "WLC_BHD"] = 48;
            values[valuesById[50] = "WLC_BDT"] = 50;
            values[valuesById[51] = "WLC_AMD"] = 51;
            values[valuesById[52] = "WLC_BBD"] = 52;
            values[valuesById[60] = "WLC_BMD"] = 60;
            values[valuesById[64] = "WLC_BTN"] = 64;
            values[valuesById[68] = "WLC_BOB"] = 68;
            values[valuesById[72] = "WLC_BWP"] = 72;
            values[valuesById[84] = "WLC_BZD"] = 84;
            values[valuesById[90] = "WLC_SBD"] = 90;
            values[valuesById[96] = "WLC_BND"] = 96;
            values[valuesById[104] = "WLC_MMK"] = 104;
            values[valuesById[108] = "WLC_BIF"] = 108;
            values[valuesById[116] = "WLC_KHR"] = 116;
            values[valuesById[124] = "WLC_CAD"] = 124;
            values[valuesById[132] = "WLC_CVE"] = 132;
            values[valuesById[136] = "WLC_KYD"] = 136;
            values[valuesById[144] = "WLC_LKR"] = 144;
            values[valuesById[152] = "WLC_CLP"] = 152;
            values[valuesById[156] = "WLC_CNY"] = 156;
            values[valuesById[170] = "WLC_COP"] = 170;
            values[valuesById[174] = "WLC_KMF"] = 174;
            values[valuesById[188] = "WLC_CRC"] = 188;
            values[valuesById[191] = "WLC_HRK"] = 191;
            values[valuesById[192] = "WLC_CUP"] = 192;
            values[valuesById[196] = "WLC_CYP"] = 196;
            values[valuesById[203] = "WLC_CZK"] = 203;
            values[valuesById[208] = "WLC_DKK"] = 208;
            values[valuesById[214] = "WLC_DOP"] = 214;
            values[valuesById[222] = "WLC_SVC"] = 222;
            values[valuesById[230] = "WLC_ETB"] = 230;
            values[valuesById[232] = "WLC_ERN"] = 232;
            values[valuesById[233] = "WLC_EEK"] = 233;
            values[valuesById[238] = "WLC_FKP"] = 238;
            values[valuesById[242] = "WLC_FJD"] = 242;
            values[valuesById[262] = "WLC_DJF"] = 262;
            values[valuesById[270] = "WLC_GMD"] = 270;
            values[valuesById[292] = "WLC_GIP"] = 292;
            values[valuesById[320] = "WLC_GTQ"] = 320;
            values[valuesById[324] = "WLC_GNF"] = 324;
            values[valuesById[328] = "WLC_GYD"] = 328;
            values[valuesById[332] = "WLC_HTG"] = 332;
            values[valuesById[340] = "WLC_HNL"] = 340;
            values[valuesById[344] = "WLC_HKD"] = 344;
            values[valuesById[348] = "WLC_HUF"] = 348;
            values[valuesById[352] = "WLC_ISK"] = 352;
            values[valuesById[356] = "WLC_INR"] = 356;
            values[valuesById[360] = "WLC_IDR"] = 360;
            values[valuesById[364] = "WLC_IRR"] = 364;
            values[valuesById[368] = "WLC_IQD"] = 368;
            values[valuesById[376] = "WLC_ILS"] = 376;
            values[valuesById[388] = "WLC_JMD"] = 388;
            values[valuesById[392] = "WLC_JPY"] = 392;
            values[valuesById[398] = "WLC_KZT"] = 398;
            values[valuesById[400] = "WLC_JOD"] = 400;
            values[valuesById[404] = "WLC_KES"] = 404;
            values[valuesById[408] = "WLC_KPW"] = 408;
            values[valuesById[410] = "WLC_KRW"] = 410;
            values[valuesById[414] = "WLC_KWD"] = 414;
            values[valuesById[417] = "WLC_KGS"] = 417;
            values[valuesById[418] = "WLC_LAK"] = 418;
            values[valuesById[422] = "WLC_LBP"] = 422;
            values[valuesById[426] = "WLC_LSL"] = 426;
            values[valuesById[428] = "WLC_LVL"] = 428;
            values[valuesById[430] = "WLC_LRD"] = 430;
            values[valuesById[434] = "WLC_LYD"] = 434;
            values[valuesById[440] = "WLC_LTL"] = 440;
            values[valuesById[446] = "WLC_MOP"] = 446;
            values[valuesById[454] = "WLC_MWK"] = 454;
            values[valuesById[458] = "WLC_MYR"] = 458;
            values[valuesById[462] = "WLC_MVR"] = 462;
            values[valuesById[470] = "WLC_MTL"] = 470;
            values[valuesById[478] = "WLC_MRO"] = 478;
            values[valuesById[480] = "WLC_MUR"] = 480;
            values[valuesById[484] = "WLC_MXN"] = 484;
            values[valuesById[496] = "WLC_MNT"] = 496;
            values[valuesById[498] = "WLC_MDL"] = 498;
            values[valuesById[504] = "WLC_MAD"] = 504;
            values[valuesById[512] = "WLC_OMR"] = 512;
            values[valuesById[516] = "WLC_NAD"] = 516;
            values[valuesById[524] = "WLC_NPR"] = 524;
            values[valuesById[532] = "WLC_ANG"] = 532;
            values[valuesById[533] = "WLC_AWG"] = 533;
            values[valuesById[548] = "WLC_VUV"] = 548;
            values[valuesById[554] = "WLC_NZD"] = 554;
            values[valuesById[558] = "WLC_NIO"] = 558;
            values[valuesById[566] = "WLC_NGN"] = 566;
            values[valuesById[578] = "WLC_NOK"] = 578;
            values[valuesById[586] = "WLC_PKR"] = 586;
            values[valuesById[590] = "WLC_PAB"] = 590;
            values[valuesById[598] = "WLC_PGK"] = 598;
            values[valuesById[600] = "WLC_PYG"] = 600;
            values[valuesById[604] = "WLC_PEN"] = 604;
            values[valuesById[608] = "WLC_PHP"] = 608;
            values[valuesById[634] = "WLC_QAR"] = 634;
            values[valuesById[643] = "WLC_RUB"] = 643;
            values[valuesById[646] = "WLC_RWF"] = 646;
            values[valuesById[654] = "WLC_SHP"] = 654;
            values[valuesById[678] = "WLC_STD"] = 678;
            values[valuesById[682] = "WLC_SAR"] = 682;
            values[valuesById[690] = "WLC_SCR"] = 690;
            values[valuesById[694] = "WLC_SLL"] = 694;
            values[valuesById[702] = "WLC_SGD"] = 702;
            values[valuesById[703] = "WLC_SKK"] = 703;
            values[valuesById[704] = "WLC_VND"] = 704;
            values[valuesById[706] = "WLC_SOS"] = 706;
            values[valuesById[710] = "WLC_ZAR"] = 710;
            values[valuesById[716] = "WLC_ZWD"] = 716;
            values[valuesById[728] = "WLC_SSP"] = 728;
            values[valuesById[748] = "WLC_SZL"] = 748;
            values[valuesById[752] = "WLC_SEK"] = 752;
            values[valuesById[756] = "WLC_CHF"] = 756;
            values[valuesById[760] = "WLC_SYP"] = 760;
            values[valuesById[764] = "WLC_THB"] = 764;
            values[valuesById[776] = "WLC_TOP"] = 776;
            values[valuesById[780] = "WLC_TTD"] = 780;
            values[valuesById[784] = "WLC_AED"] = 784;
            values[valuesById[788] = "WLC_TND"] = 788;
            values[valuesById[795] = "WLC_TMM"] = 795;
            values[valuesById[800] = "WLC_UGX"] = 800;
            values[valuesById[807] = "WLC_MKD"] = 807;
            values[valuesById[810] = "WLC_RUR"] = 810;
            values[valuesById[818] = "WLC_EGP"] = 818;
            values[valuesById[826] = "WLC_GBP"] = 826;
            values[valuesById[834] = "WLC_TZS"] = 834;
            values[valuesById[840] = "WLC_USD"] = 840;
            values[valuesById[858] = "WLC_UYU"] = 858;
            values[valuesById[860] = "WLC_UZS"] = 860;
            values[valuesById[882] = "WLC_WST"] = 882;
            values[valuesById[886] = "WLC_YER"] = 886;
            values[valuesById[894] = "WLC_ZMK"] = 894;
            values[valuesById[901] = "WLC_TWD"] = 901;
            values[valuesById[931] = "WLC_CUC"] = 931;
            values[valuesById[932] = "WLC_ZWL"] = 932;
            values[valuesById[933] = "WLC_BYN"] = 933;
            values[valuesById[934] = "WLC_TMT"] = 934;
            values[valuesById[936] = "WLC_GHC"] = 936;
            values[valuesById[937] = "WLC_VEF"] = 937;
            values[valuesById[938] = "WLC_SDG"] = 938;
            values[valuesById[941] = "WLC_RSD"] = 941;
            values[valuesById[943] = "WLC_MZN"] = 943;
            values[valuesById[944] = "WLC_AZN"] = 944;
            values[valuesById[946] = "WLC_RON"] = 946;
            values[valuesById[949] = "WLC_TRY"] = 949;
            values[valuesById[950] = "WLC_XAF"] = 950;
            values[valuesById[951] = "WLC_XCD"] = 951;
            values[valuesById[952] = "WLC_XOF"] = 952;
            values[valuesById[953] = "WLC_XPF"] = 953;
            values[valuesById[959] = "WLC_XAU"] = 959;
            values[valuesById[960] = "WLC_XDR"] = 960;
            values[valuesById[961] = "WLC_XAG"] = 961;
            values[valuesById[967] = "WLC_ZMW"] = 967;
            values[valuesById[968] = "WLC_SRD"] = 968;
            values[valuesById[969] = "WLC_MGA"] = 969;
            values[valuesById[971] = "WLC_AFN"] = 971;
            values[valuesById[972] = "WLC_TJS"] = 972;
            values[valuesById[973] = "WLC_AOA"] = 973;
            values[valuesById[975] = "WLC_BGN"] = 975;
            values[valuesById[976] = "WLC_CDF"] = 976;
            values[valuesById[977] = "WLC_BAM"] = 977;
            values[valuesById[978] = "WLC_EUR"] = 978;
            values[valuesById[980] = "WLC_UAH"] = 980;
            values[valuesById[981] = "WLC_GEL"] = 981;
            values[valuesById[985] = "WLC_PLN"] = 985;
            values[valuesById[986] = "WLC_BRL"] = 986;
            values[valuesById[990] = "WLC_CLF"] = 990;
            values[valuesById[6004] = "WLC_ENS"] = 6004;
            values[valuesById[6005] = "WLC_SSC"] = 6005;
            values[valuesById[6006] = "WLC_GOC"] = 6006;
            values[valuesById[80001] = "WLC_GGP"] = 80001;
            values[valuesById[80002] = "WLC_IMP"] = 80002;
            values[valuesById[80003] = "WLC_JEP"] = 80003;
            values[valuesById[90001] = "WLC_LTCT"] = 90001;
            values[valuesById[90002] = "WLC_BTC"] = 90002;
            values[valuesById[90003] = "WLC_ETH"] = 90003;
            values[valuesById[90004] = "WLC_DASH"] = 90004;
            values[valuesById[90005] = "WLC_ZEC"] = 90005;
            values[valuesById[90006] = "WLC_BCH"] = 90006;
            values[valuesById[90007] = "WLC_LTC"] = 90007;
            values[valuesById[90008] = "WLC_XRP"] = 90008;
            values[valuesById[90009] = "WLC_ETN"] = 90009;
            values[valuesById[90010] = "WLC_USDT"] = 90010;
            values[valuesById[90011] = "WLC_USDTERC20"] = 90011;
            values[valuesById[90012] = "WLC_USDTTRC20"] = 90012;
            values[valuesById[90013] = "WLC_TRX"] = 90013;
            return values;
        })();
    
        currencies.ExchangeRate = (function() {
    
            /**
             * Properties of an ExchangeRate.
             * @memberof currencies
             * @interface IExchangeRate
             * @property {currencies.WLCurrency|null} [currencyFrom] ExchangeRate currencyFrom
             * @property {currencies.WLCurrency|null} [currencyTo] ExchangeRate currencyTo
             * @property {string|null} [rate] ExchangeRate rate
             */
    
            /**
             * Constructs a new ExchangeRate.
             * @memberof currencies
             * @classdesc Represents an ExchangeRate.
             * @implements IExchangeRate
             * @constructor
             * @param {currencies.IExchangeRate=} [properties] Properties to set
             */
            function ExchangeRate(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ExchangeRate currencyFrom.
             * @member {currencies.WLCurrency} currencyFrom
             * @memberof currencies.ExchangeRate
             * @instance
             */
            ExchangeRate.prototype.currencyFrom = 0;
    
            /**
             * ExchangeRate currencyTo.
             * @member {currencies.WLCurrency} currencyTo
             * @memberof currencies.ExchangeRate
             * @instance
             */
            ExchangeRate.prototype.currencyTo = 0;
    
            /**
             * ExchangeRate rate.
             * @member {string} rate
             * @memberof currencies.ExchangeRate
             * @instance
             */
            ExchangeRate.prototype.rate = "";
    
            /**
             * Creates a new ExchangeRate instance using the specified properties.
             * @function create
             * @memberof currencies.ExchangeRate
             * @static
             * @param {currencies.IExchangeRate=} [properties] Properties to set
             * @returns {currencies.ExchangeRate} ExchangeRate instance
             */
            ExchangeRate.create = function create(properties) {
                return new ExchangeRate(properties);
            };
    
            /**
             * Encodes the specified ExchangeRate message. Does not implicitly {@link currencies.ExchangeRate.verify|verify} messages.
             * @function encode
             * @memberof currencies.ExchangeRate
             * @static
             * @param {currencies.IExchangeRate} message ExchangeRate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExchangeRate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.currencyFrom != null && Object.hasOwnProperty.call(message, "currencyFrom"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyFrom);
                if (message.currencyTo != null && Object.hasOwnProperty.call(message, "currencyTo"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currencyTo);
                if (message.rate != null && Object.hasOwnProperty.call(message, "rate"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.rate);
                return writer;
            };
    
            /**
             * Encodes the specified ExchangeRate message, length delimited. Does not implicitly {@link currencies.ExchangeRate.verify|verify} messages.
             * @function encodeDelimited
             * @memberof currencies.ExchangeRate
             * @static
             * @param {currencies.IExchangeRate} message ExchangeRate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExchangeRate.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an ExchangeRate message from the specified reader or buffer.
             * @function decode
             * @memberof currencies.ExchangeRate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {currencies.ExchangeRate} ExchangeRate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExchangeRate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.currencies.ExchangeRate();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.currencyFrom = reader.int32();
                        break;
                    case 2:
                        message.currencyTo = reader.int32();
                        break;
                    case 3:
                        message.rate = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an ExchangeRate message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof currencies.ExchangeRate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {currencies.ExchangeRate} ExchangeRate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExchangeRate.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an ExchangeRate message.
             * @function verify
             * @memberof currencies.ExchangeRate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ExchangeRate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.currencyFrom != null && message.hasOwnProperty("currencyFrom"))
                    switch (message.currencyFrom) {
                    default:
                        return "currencyFrom: enum value expected";
                    case 0:
                    case 8:
                    case 12:
                    case 32:
                    case 36:
                    case 44:
                    case 48:
                    case 50:
                    case 51:
                    case 52:
                    case 60:
                    case 64:
                    case 68:
                    case 72:
                    case 84:
                    case 90:
                    case 96:
                    case 104:
                    case 108:
                    case 116:
                    case 124:
                    case 132:
                    case 136:
                    case 144:
                    case 152:
                    case 156:
                    case 170:
                    case 174:
                    case 188:
                    case 191:
                    case 192:
                    case 196:
                    case 203:
                    case 208:
                    case 214:
                    case 222:
                    case 230:
                    case 232:
                    case 233:
                    case 238:
                    case 242:
                    case 262:
                    case 270:
                    case 292:
                    case 320:
                    case 324:
                    case 328:
                    case 332:
                    case 340:
                    case 344:
                    case 348:
                    case 352:
                    case 356:
                    case 360:
                    case 364:
                    case 368:
                    case 376:
                    case 388:
                    case 392:
                    case 398:
                    case 400:
                    case 404:
                    case 408:
                    case 410:
                    case 414:
                    case 417:
                    case 418:
                    case 422:
                    case 426:
                    case 428:
                    case 430:
                    case 434:
                    case 440:
                    case 446:
                    case 454:
                    case 458:
                    case 462:
                    case 470:
                    case 478:
                    case 480:
                    case 484:
                    case 496:
                    case 498:
                    case 504:
                    case 512:
                    case 516:
                    case 524:
                    case 532:
                    case 533:
                    case 548:
                    case 554:
                    case 558:
                    case 566:
                    case 578:
                    case 586:
                    case 590:
                    case 598:
                    case 600:
                    case 604:
                    case 608:
                    case 634:
                    case 643:
                    case 646:
                    case 654:
                    case 678:
                    case 682:
                    case 690:
                    case 694:
                    case 702:
                    case 703:
                    case 704:
                    case 706:
                    case 710:
                    case 716:
                    case 728:
                    case 748:
                    case 752:
                    case 756:
                    case 760:
                    case 764:
                    case 776:
                    case 780:
                    case 784:
                    case 788:
                    case 795:
                    case 800:
                    case 807:
                    case 810:
                    case 818:
                    case 826:
                    case 834:
                    case 840:
                    case 858:
                    case 860:
                    case 882:
                    case 886:
                    case 894:
                    case 901:
                    case 931:
                    case 932:
                    case 933:
                    case 934:
                    case 936:
                    case 937:
                    case 938:
                    case 941:
                    case 943:
                    case 944:
                    case 946:
                    case 949:
                    case 950:
                    case 951:
                    case 952:
                    case 953:
                    case 959:
                    case 960:
                    case 961:
                    case 967:
                    case 968:
                    case 969:
                    case 971:
                    case 972:
                    case 973:
                    case 975:
                    case 976:
                    case 977:
                    case 978:
                    case 980:
                    case 981:
                    case 985:
                    case 986:
                    case 990:
                    case 6004:
                    case 6005:
                    case 6006:
                    case 80001:
                    case 80002:
                    case 80003:
                    case 90001:
                    case 90002:
                    case 90003:
                    case 90004:
                    case 90005:
                    case 90006:
                    case 90007:
                    case 90008:
                    case 90009:
                    case 90010:
                    case 90011:
                    case 90012:
                    case 90013:
                        break;
                    }
                if (message.currencyTo != null && message.hasOwnProperty("currencyTo"))
                    switch (message.currencyTo) {
                    default:
                        return "currencyTo: enum value expected";
                    case 0:
                    case 8:
                    case 12:
                    case 32:
                    case 36:
                    case 44:
                    case 48:
                    case 50:
                    case 51:
                    case 52:
                    case 60:
                    case 64:
                    case 68:
                    case 72:
                    case 84:
                    case 90:
                    case 96:
                    case 104:
                    case 108:
                    case 116:
                    case 124:
                    case 132:
                    case 136:
                    case 144:
                    case 152:
                    case 156:
                    case 170:
                    case 174:
                    case 188:
                    case 191:
                    case 192:
                    case 196:
                    case 203:
                    case 208:
                    case 214:
                    case 222:
                    case 230:
                    case 232:
                    case 233:
                    case 238:
                    case 242:
                    case 262:
                    case 270:
                    case 292:
                    case 320:
                    case 324:
                    case 328:
                    case 332:
                    case 340:
                    case 344:
                    case 348:
                    case 352:
                    case 356:
                    case 360:
                    case 364:
                    case 368:
                    case 376:
                    case 388:
                    case 392:
                    case 398:
                    case 400:
                    case 404:
                    case 408:
                    case 410:
                    case 414:
                    case 417:
                    case 418:
                    case 422:
                    case 426:
                    case 428:
                    case 430:
                    case 434:
                    case 440:
                    case 446:
                    case 454:
                    case 458:
                    case 462:
                    case 470:
                    case 478:
                    case 480:
                    case 484:
                    case 496:
                    case 498:
                    case 504:
                    case 512:
                    case 516:
                    case 524:
                    case 532:
                    case 533:
                    case 548:
                    case 554:
                    case 558:
                    case 566:
                    case 578:
                    case 586:
                    case 590:
                    case 598:
                    case 600:
                    case 604:
                    case 608:
                    case 634:
                    case 643:
                    case 646:
                    case 654:
                    case 678:
                    case 682:
                    case 690:
                    case 694:
                    case 702:
                    case 703:
                    case 704:
                    case 706:
                    case 710:
                    case 716:
                    case 728:
                    case 748:
                    case 752:
                    case 756:
                    case 760:
                    case 764:
                    case 776:
                    case 780:
                    case 784:
                    case 788:
                    case 795:
                    case 800:
                    case 807:
                    case 810:
                    case 818:
                    case 826:
                    case 834:
                    case 840:
                    case 858:
                    case 860:
                    case 882:
                    case 886:
                    case 894:
                    case 901:
                    case 931:
                    case 932:
                    case 933:
                    case 934:
                    case 936:
                    case 937:
                    case 938:
                    case 941:
                    case 943:
                    case 944:
                    case 946:
                    case 949:
                    case 950:
                    case 951:
                    case 952:
                    case 953:
                    case 959:
                    case 960:
                    case 961:
                    case 967:
                    case 968:
                    case 969:
                    case 971:
                    case 972:
                    case 973:
                    case 975:
                    case 976:
                    case 977:
                    case 978:
                    case 980:
                    case 981:
                    case 985:
                    case 986:
                    case 990:
                    case 6004:
                    case 6005:
                    case 6006:
                    case 80001:
                    case 80002:
                    case 80003:
                    case 90001:
                    case 90002:
                    case 90003:
                    case 90004:
                    case 90005:
                    case 90006:
                    case 90007:
                    case 90008:
                    case 90009:
                    case 90010:
                    case 90011:
                    case 90012:
                    case 90013:
                        break;
                    }
                if (message.rate != null && message.hasOwnProperty("rate"))
                    if (!$util.isString(message.rate))
                        return "rate: string expected";
                return null;
            };
    
            /**
             * Creates an ExchangeRate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof currencies.ExchangeRate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {currencies.ExchangeRate} ExchangeRate
             */
            ExchangeRate.fromObject = function fromObject(object) {
                if (object instanceof $root.currencies.ExchangeRate)
                    return object;
                var message = new $root.currencies.ExchangeRate();
                switch (object.currencyFrom) {
                case "WLC_FUN":
                case 0:
                    message.currencyFrom = 0;
                    break;
                case "WLC_ALL":
                case 8:
                    message.currencyFrom = 8;
                    break;
                case "WLC_DZD":
                case 12:
                    message.currencyFrom = 12;
                    break;
                case "WLC_ARS":
                case 32:
                    message.currencyFrom = 32;
                    break;
                case "WLC_AUD":
                case 36:
                    message.currencyFrom = 36;
                    break;
                case "WLC_BSD":
                case 44:
                    message.currencyFrom = 44;
                    break;
                case "WLC_BHD":
                case 48:
                    message.currencyFrom = 48;
                    break;
                case "WLC_BDT":
                case 50:
                    message.currencyFrom = 50;
                    break;
                case "WLC_AMD":
                case 51:
                    message.currencyFrom = 51;
                    break;
                case "WLC_BBD":
                case 52:
                    message.currencyFrom = 52;
                    break;
                case "WLC_BMD":
                case 60:
                    message.currencyFrom = 60;
                    break;
                case "WLC_BTN":
                case 64:
                    message.currencyFrom = 64;
                    break;
                case "WLC_BOB":
                case 68:
                    message.currencyFrom = 68;
                    break;
                case "WLC_BWP":
                case 72:
                    message.currencyFrom = 72;
                    break;
                case "WLC_BZD":
                case 84:
                    message.currencyFrom = 84;
                    break;
                case "WLC_SBD":
                case 90:
                    message.currencyFrom = 90;
                    break;
                case "WLC_BND":
                case 96:
                    message.currencyFrom = 96;
                    break;
                case "WLC_MMK":
                case 104:
                    message.currencyFrom = 104;
                    break;
                case "WLC_BIF":
                case 108:
                    message.currencyFrom = 108;
                    break;
                case "WLC_KHR":
                case 116:
                    message.currencyFrom = 116;
                    break;
                case "WLC_CAD":
                case 124:
                    message.currencyFrom = 124;
                    break;
                case "WLC_CVE":
                case 132:
                    message.currencyFrom = 132;
                    break;
                case "WLC_KYD":
                case 136:
                    message.currencyFrom = 136;
                    break;
                case "WLC_LKR":
                case 144:
                    message.currencyFrom = 144;
                    break;
                case "WLC_CLP":
                case 152:
                    message.currencyFrom = 152;
                    break;
                case "WLC_CNY":
                case 156:
                    message.currencyFrom = 156;
                    break;
                case "WLC_COP":
                case 170:
                    message.currencyFrom = 170;
                    break;
                case "WLC_KMF":
                case 174:
                    message.currencyFrom = 174;
                    break;
                case "WLC_CRC":
                case 188:
                    message.currencyFrom = 188;
                    break;
                case "WLC_HRK":
                case 191:
                    message.currencyFrom = 191;
                    break;
                case "WLC_CUP":
                case 192:
                    message.currencyFrom = 192;
                    break;
                case "WLC_CYP":
                case 196:
                    message.currencyFrom = 196;
                    break;
                case "WLC_CZK":
                case 203:
                    message.currencyFrom = 203;
                    break;
                case "WLC_DKK":
                case 208:
                    message.currencyFrom = 208;
                    break;
                case "WLC_DOP":
                case 214:
                    message.currencyFrom = 214;
                    break;
                case "WLC_SVC":
                case 222:
                    message.currencyFrom = 222;
                    break;
                case "WLC_ETB":
                case 230:
                    message.currencyFrom = 230;
                    break;
                case "WLC_ERN":
                case 232:
                    message.currencyFrom = 232;
                    break;
                case "WLC_EEK":
                case 233:
                    message.currencyFrom = 233;
                    break;
                case "WLC_FKP":
                case 238:
                    message.currencyFrom = 238;
                    break;
                case "WLC_FJD":
                case 242:
                    message.currencyFrom = 242;
                    break;
                case "WLC_DJF":
                case 262:
                    message.currencyFrom = 262;
                    break;
                case "WLC_GMD":
                case 270:
                    message.currencyFrom = 270;
                    break;
                case "WLC_GIP":
                case 292:
                    message.currencyFrom = 292;
                    break;
                case "WLC_GTQ":
                case 320:
                    message.currencyFrom = 320;
                    break;
                case "WLC_GNF":
                case 324:
                    message.currencyFrom = 324;
                    break;
                case "WLC_GYD":
                case 328:
                    message.currencyFrom = 328;
                    break;
                case "WLC_HTG":
                case 332:
                    message.currencyFrom = 332;
                    break;
                case "WLC_HNL":
                case 340:
                    message.currencyFrom = 340;
                    break;
                case "WLC_HKD":
                case 344:
                    message.currencyFrom = 344;
                    break;
                case "WLC_HUF":
                case 348:
                    message.currencyFrom = 348;
                    break;
                case "WLC_ISK":
                case 352:
                    message.currencyFrom = 352;
                    break;
                case "WLC_INR":
                case 356:
                    message.currencyFrom = 356;
                    break;
                case "WLC_IDR":
                case 360:
                    message.currencyFrom = 360;
                    break;
                case "WLC_IRR":
                case 364:
                    message.currencyFrom = 364;
                    break;
                case "WLC_IQD":
                case 368:
                    message.currencyFrom = 368;
                    break;
                case "WLC_ILS":
                case 376:
                    message.currencyFrom = 376;
                    break;
                case "WLC_JMD":
                case 388:
                    message.currencyFrom = 388;
                    break;
                case "WLC_JPY":
                case 392:
                    message.currencyFrom = 392;
                    break;
                case "WLC_KZT":
                case 398:
                    message.currencyFrom = 398;
                    break;
                case "WLC_JOD":
                case 400:
                    message.currencyFrom = 400;
                    break;
                case "WLC_KES":
                case 404:
                    message.currencyFrom = 404;
                    break;
                case "WLC_KPW":
                case 408:
                    message.currencyFrom = 408;
                    break;
                case "WLC_KRW":
                case 410:
                    message.currencyFrom = 410;
                    break;
                case "WLC_KWD":
                case 414:
                    message.currencyFrom = 414;
                    break;
                case "WLC_KGS":
                case 417:
                    message.currencyFrom = 417;
                    break;
                case "WLC_LAK":
                case 418:
                    message.currencyFrom = 418;
                    break;
                case "WLC_LBP":
                case 422:
                    message.currencyFrom = 422;
                    break;
                case "WLC_LSL":
                case 426:
                    message.currencyFrom = 426;
                    break;
                case "WLC_LVL":
                case 428:
                    message.currencyFrom = 428;
                    break;
                case "WLC_LRD":
                case 430:
                    message.currencyFrom = 430;
                    break;
                case "WLC_LYD":
                case 434:
                    message.currencyFrom = 434;
                    break;
                case "WLC_LTL":
                case 440:
                    message.currencyFrom = 440;
                    break;
                case "WLC_MOP":
                case 446:
                    message.currencyFrom = 446;
                    break;
                case "WLC_MWK":
                case 454:
                    message.currencyFrom = 454;
                    break;
                case "WLC_MYR":
                case 458:
                    message.currencyFrom = 458;
                    break;
                case "WLC_MVR":
                case 462:
                    message.currencyFrom = 462;
                    break;
                case "WLC_MTL":
                case 470:
                    message.currencyFrom = 470;
                    break;
                case "WLC_MRO":
                case 478:
                    message.currencyFrom = 478;
                    break;
                case "WLC_MUR":
                case 480:
                    message.currencyFrom = 480;
                    break;
                case "WLC_MXN":
                case 484:
                    message.currencyFrom = 484;
                    break;
                case "WLC_MNT":
                case 496:
                    message.currencyFrom = 496;
                    break;
                case "WLC_MDL":
                case 498:
                    message.currencyFrom = 498;
                    break;
                case "WLC_MAD":
                case 504:
                    message.currencyFrom = 504;
                    break;
                case "WLC_OMR":
                case 512:
                    message.currencyFrom = 512;
                    break;
                case "WLC_NAD":
                case 516:
                    message.currencyFrom = 516;
                    break;
                case "WLC_NPR":
                case 524:
                    message.currencyFrom = 524;
                    break;
                case "WLC_ANG":
                case 532:
                    message.currencyFrom = 532;
                    break;
                case "WLC_AWG":
                case 533:
                    message.currencyFrom = 533;
                    break;
                case "WLC_VUV":
                case 548:
                    message.currencyFrom = 548;
                    break;
                case "WLC_NZD":
                case 554:
                    message.currencyFrom = 554;
                    break;
                case "WLC_NIO":
                case 558:
                    message.currencyFrom = 558;
                    break;
                case "WLC_NGN":
                case 566:
                    message.currencyFrom = 566;
                    break;
                case "WLC_NOK":
                case 578:
                    message.currencyFrom = 578;
                    break;
                case "WLC_PKR":
                case 586:
                    message.currencyFrom = 586;
                    break;
                case "WLC_PAB":
                case 590:
                    message.currencyFrom = 590;
                    break;
                case "WLC_PGK":
                case 598:
                    message.currencyFrom = 598;
                    break;
                case "WLC_PYG":
                case 600:
                    message.currencyFrom = 600;
                    break;
                case "WLC_PEN":
                case 604:
                    message.currencyFrom = 604;
                    break;
                case "WLC_PHP":
                case 608:
                    message.currencyFrom = 608;
                    break;
                case "WLC_QAR":
                case 634:
                    message.currencyFrom = 634;
                    break;
                case "WLC_RUB":
                case 643:
                    message.currencyFrom = 643;
                    break;
                case "WLC_RWF":
                case 646:
                    message.currencyFrom = 646;
                    break;
                case "WLC_SHP":
                case 654:
                    message.currencyFrom = 654;
                    break;
                case "WLC_STD":
                case 678:
                    message.currencyFrom = 678;
                    break;
                case "WLC_SAR":
                case 682:
                    message.currencyFrom = 682;
                    break;
                case "WLC_SCR":
                case 690:
                    message.currencyFrom = 690;
                    break;
                case "WLC_SLL":
                case 694:
                    message.currencyFrom = 694;
                    break;
                case "WLC_SGD":
                case 702:
                    message.currencyFrom = 702;
                    break;
                case "WLC_SKK":
                case 703:
                    message.currencyFrom = 703;
                    break;
                case "WLC_VND":
                case 704:
                    message.currencyFrom = 704;
                    break;
                case "WLC_SOS":
                case 706:
                    message.currencyFrom = 706;
                    break;
                case "WLC_ZAR":
                case 710:
                    message.currencyFrom = 710;
                    break;
                case "WLC_ZWD":
                case 716:
                    message.currencyFrom = 716;
                    break;
                case "WLC_SSP":
                case 728:
                    message.currencyFrom = 728;
                    break;
                case "WLC_SZL":
                case 748:
                    message.currencyFrom = 748;
                    break;
                case "WLC_SEK":
                case 752:
                    message.currencyFrom = 752;
                    break;
                case "WLC_CHF":
                case 756:
                    message.currencyFrom = 756;
                    break;
                case "WLC_SYP":
                case 760:
                    message.currencyFrom = 760;
                    break;
                case "WLC_THB":
                case 764:
                    message.currencyFrom = 764;
                    break;
                case "WLC_TOP":
                case 776:
                    message.currencyFrom = 776;
                    break;
                case "WLC_TTD":
                case 780:
                    message.currencyFrom = 780;
                    break;
                case "WLC_AED":
                case 784:
                    message.currencyFrom = 784;
                    break;
                case "WLC_TND":
                case 788:
                    message.currencyFrom = 788;
                    break;
                case "WLC_TMM":
                case 795:
                    message.currencyFrom = 795;
                    break;
                case "WLC_UGX":
                case 800:
                    message.currencyFrom = 800;
                    break;
                case "WLC_MKD":
                case 807:
                    message.currencyFrom = 807;
                    break;
                case "WLC_RUR":
                case 810:
                    message.currencyFrom = 810;
                    break;
                case "WLC_EGP":
                case 818:
                    message.currencyFrom = 818;
                    break;
                case "WLC_GBP":
                case 826:
                    message.currencyFrom = 826;
                    break;
                case "WLC_TZS":
                case 834:
                    message.currencyFrom = 834;
                    break;
                case "WLC_USD":
                case 840:
                    message.currencyFrom = 840;
                    break;
                case "WLC_UYU":
                case 858:
                    message.currencyFrom = 858;
                    break;
                case "WLC_UZS":
                case 860:
                    message.currencyFrom = 860;
                    break;
                case "WLC_WST":
                case 882:
                    message.currencyFrom = 882;
                    break;
                case "WLC_YER":
                case 886:
                    message.currencyFrom = 886;
                    break;
                case "WLC_ZMK":
                case 894:
                    message.currencyFrom = 894;
                    break;
                case "WLC_TWD":
                case 901:
                    message.currencyFrom = 901;
                    break;
                case "WLC_CUC":
                case 931:
                    message.currencyFrom = 931;
                    break;
                case "WLC_ZWL":
                case 932:
                    message.currencyFrom = 932;
                    break;
                case "WLC_BYN":
                case 933:
                    message.currencyFrom = 933;
                    break;
                case "WLC_TMT":
                case 934:
                    message.currencyFrom = 934;
                    break;
                case "WLC_GHC":
                case 936:
                    message.currencyFrom = 936;
                    break;
                case "WLC_VEF":
                case 937:
                    message.currencyFrom = 937;
                    break;
                case "WLC_SDG":
                case 938:
                    message.currencyFrom = 938;
                    break;
                case "WLC_RSD":
                case 941:
                    message.currencyFrom = 941;
                    break;
                case "WLC_MZN":
                case 943:
                    message.currencyFrom = 943;
                    break;
                case "WLC_AZN":
                case 944:
                    message.currencyFrom = 944;
                    break;
                case "WLC_RON":
                case 946:
                    message.currencyFrom = 946;
                    break;
                case "WLC_TRY":
                case 949:
                    message.currencyFrom = 949;
                    break;
                case "WLC_XAF":
                case 950:
                    message.currencyFrom = 950;
                    break;
                case "WLC_XCD":
                case 951:
                    message.currencyFrom = 951;
                    break;
                case "WLC_XOF":
                case 952:
                    message.currencyFrom = 952;
                    break;
                case "WLC_XPF":
                case 953:
                    message.currencyFrom = 953;
                    break;
                case "WLC_XAU":
                case 959:
                    message.currencyFrom = 959;
                    break;
                case "WLC_XDR":
                case 960:
                    message.currencyFrom = 960;
                    break;
                case "WLC_XAG":
                case 961:
                    message.currencyFrom = 961;
                    break;
                case "WLC_ZMW":
                case 967:
                    message.currencyFrom = 967;
                    break;
                case "WLC_SRD":
                case 968:
                    message.currencyFrom = 968;
                    break;
                case "WLC_MGA":
                case 969:
                    message.currencyFrom = 969;
                    break;
                case "WLC_AFN":
                case 971:
                    message.currencyFrom = 971;
                    break;
                case "WLC_TJS":
                case 972:
                    message.currencyFrom = 972;
                    break;
                case "WLC_AOA":
                case 973:
                    message.currencyFrom = 973;
                    break;
                case "WLC_BGN":
                case 975:
                    message.currencyFrom = 975;
                    break;
                case "WLC_CDF":
                case 976:
                    message.currencyFrom = 976;
                    break;
                case "WLC_BAM":
                case 977:
                    message.currencyFrom = 977;
                    break;
                case "WLC_EUR":
                case 978:
                    message.currencyFrom = 978;
                    break;
                case "WLC_UAH":
                case 980:
                    message.currencyFrom = 980;
                    break;
                case "WLC_GEL":
                case 981:
                    message.currencyFrom = 981;
                    break;
                case "WLC_PLN":
                case 985:
                    message.currencyFrom = 985;
                    break;
                case "WLC_BRL":
                case 986:
                    message.currencyFrom = 986;
                    break;
                case "WLC_CLF":
                case 990:
                    message.currencyFrom = 990;
                    break;
                case "WLC_ENS":
                case 6004:
                    message.currencyFrom = 6004;
                    break;
                case "WLC_SSC":
                case 6005:
                    message.currencyFrom = 6005;
                    break;
                case "WLC_GOC":
                case 6006:
                    message.currencyFrom = 6006;
                    break;
                case "WLC_GGP":
                case 80001:
                    message.currencyFrom = 80001;
                    break;
                case "WLC_IMP":
                case 80002:
                    message.currencyFrom = 80002;
                    break;
                case "WLC_JEP":
                case 80003:
                    message.currencyFrom = 80003;
                    break;
                case "WLC_LTCT":
                case 90001:
                    message.currencyFrom = 90001;
                    break;
                case "WLC_BTC":
                case 90002:
                    message.currencyFrom = 90002;
                    break;
                case "WLC_ETH":
                case 90003:
                    message.currencyFrom = 90003;
                    break;
                case "WLC_DASH":
                case 90004:
                    message.currencyFrom = 90004;
                    break;
                case "WLC_ZEC":
                case 90005:
                    message.currencyFrom = 90005;
                    break;
                case "WLC_BCH":
                case 90006:
                    message.currencyFrom = 90006;
                    break;
                case "WLC_LTC":
                case 90007:
                    message.currencyFrom = 90007;
                    break;
                case "WLC_XRP":
                case 90008:
                    message.currencyFrom = 90008;
                    break;
                case "WLC_ETN":
                case 90009:
                    message.currencyFrom = 90009;
                    break;
                case "WLC_USDT":
                case 90010:
                    message.currencyFrom = 90010;
                    break;
                case "WLC_USDTERC20":
                case 90011:
                    message.currencyFrom = 90011;
                    break;
                case "WLC_USDTTRC20":
                case 90012:
                    message.currencyFrom = 90012;
                    break;
                case "WLC_TRX":
                case 90013:
                    message.currencyFrom = 90013;
                    break;
                }
                switch (object.currencyTo) {
                case "WLC_FUN":
                case 0:
                    message.currencyTo = 0;
                    break;
                case "WLC_ALL":
                case 8:
                    message.currencyTo = 8;
                    break;
                case "WLC_DZD":
                case 12:
                    message.currencyTo = 12;
                    break;
                case "WLC_ARS":
                case 32:
                    message.currencyTo = 32;
                    break;
                case "WLC_AUD":
                case 36:
                    message.currencyTo = 36;
                    break;
                case "WLC_BSD":
                case 44:
                    message.currencyTo = 44;
                    break;
                case "WLC_BHD":
                case 48:
                    message.currencyTo = 48;
                    break;
                case "WLC_BDT":
                case 50:
                    message.currencyTo = 50;
                    break;
                case "WLC_AMD":
                case 51:
                    message.currencyTo = 51;
                    break;
                case "WLC_BBD":
                case 52:
                    message.currencyTo = 52;
                    break;
                case "WLC_BMD":
                case 60:
                    message.currencyTo = 60;
                    break;
                case "WLC_BTN":
                case 64:
                    message.currencyTo = 64;
                    break;
                case "WLC_BOB":
                case 68:
                    message.currencyTo = 68;
                    break;
                case "WLC_BWP":
                case 72:
                    message.currencyTo = 72;
                    break;
                case "WLC_BZD":
                case 84:
                    message.currencyTo = 84;
                    break;
                case "WLC_SBD":
                case 90:
                    message.currencyTo = 90;
                    break;
                case "WLC_BND":
                case 96:
                    message.currencyTo = 96;
                    break;
                case "WLC_MMK":
                case 104:
                    message.currencyTo = 104;
                    break;
                case "WLC_BIF":
                case 108:
                    message.currencyTo = 108;
                    break;
                case "WLC_KHR":
                case 116:
                    message.currencyTo = 116;
                    break;
                case "WLC_CAD":
                case 124:
                    message.currencyTo = 124;
                    break;
                case "WLC_CVE":
                case 132:
                    message.currencyTo = 132;
                    break;
                case "WLC_KYD":
                case 136:
                    message.currencyTo = 136;
                    break;
                case "WLC_LKR":
                case 144:
                    message.currencyTo = 144;
                    break;
                case "WLC_CLP":
                case 152:
                    message.currencyTo = 152;
                    break;
                case "WLC_CNY":
                case 156:
                    message.currencyTo = 156;
                    break;
                case "WLC_COP":
                case 170:
                    message.currencyTo = 170;
                    break;
                case "WLC_KMF":
                case 174:
                    message.currencyTo = 174;
                    break;
                case "WLC_CRC":
                case 188:
                    message.currencyTo = 188;
                    break;
                case "WLC_HRK":
                case 191:
                    message.currencyTo = 191;
                    break;
                case "WLC_CUP":
                case 192:
                    message.currencyTo = 192;
                    break;
                case "WLC_CYP":
                case 196:
                    message.currencyTo = 196;
                    break;
                case "WLC_CZK":
                case 203:
                    message.currencyTo = 203;
                    break;
                case "WLC_DKK":
                case 208:
                    message.currencyTo = 208;
                    break;
                case "WLC_DOP":
                case 214:
                    message.currencyTo = 214;
                    break;
                case "WLC_SVC":
                case 222:
                    message.currencyTo = 222;
                    break;
                case "WLC_ETB":
                case 230:
                    message.currencyTo = 230;
                    break;
                case "WLC_ERN":
                case 232:
                    message.currencyTo = 232;
                    break;
                case "WLC_EEK":
                case 233:
                    message.currencyTo = 233;
                    break;
                case "WLC_FKP":
                case 238:
                    message.currencyTo = 238;
                    break;
                case "WLC_FJD":
                case 242:
                    message.currencyTo = 242;
                    break;
                case "WLC_DJF":
                case 262:
                    message.currencyTo = 262;
                    break;
                case "WLC_GMD":
                case 270:
                    message.currencyTo = 270;
                    break;
                case "WLC_GIP":
                case 292:
                    message.currencyTo = 292;
                    break;
                case "WLC_GTQ":
                case 320:
                    message.currencyTo = 320;
                    break;
                case "WLC_GNF":
                case 324:
                    message.currencyTo = 324;
                    break;
                case "WLC_GYD":
                case 328:
                    message.currencyTo = 328;
                    break;
                case "WLC_HTG":
                case 332:
                    message.currencyTo = 332;
                    break;
                case "WLC_HNL":
                case 340:
                    message.currencyTo = 340;
                    break;
                case "WLC_HKD":
                case 344:
                    message.currencyTo = 344;
                    break;
                case "WLC_HUF":
                case 348:
                    message.currencyTo = 348;
                    break;
                case "WLC_ISK":
                case 352:
                    message.currencyTo = 352;
                    break;
                case "WLC_INR":
                case 356:
                    message.currencyTo = 356;
                    break;
                case "WLC_IDR":
                case 360:
                    message.currencyTo = 360;
                    break;
                case "WLC_IRR":
                case 364:
                    message.currencyTo = 364;
                    break;
                case "WLC_IQD":
                case 368:
                    message.currencyTo = 368;
                    break;
                case "WLC_ILS":
                case 376:
                    message.currencyTo = 376;
                    break;
                case "WLC_JMD":
                case 388:
                    message.currencyTo = 388;
                    break;
                case "WLC_JPY":
                case 392:
                    message.currencyTo = 392;
                    break;
                case "WLC_KZT":
                case 398:
                    message.currencyTo = 398;
                    break;
                case "WLC_JOD":
                case 400:
                    message.currencyTo = 400;
                    break;
                case "WLC_KES":
                case 404:
                    message.currencyTo = 404;
                    break;
                case "WLC_KPW":
                case 408:
                    message.currencyTo = 408;
                    break;
                case "WLC_KRW":
                case 410:
                    message.currencyTo = 410;
                    break;
                case "WLC_KWD":
                case 414:
                    message.currencyTo = 414;
                    break;
                case "WLC_KGS":
                case 417:
                    message.currencyTo = 417;
                    break;
                case "WLC_LAK":
                case 418:
                    message.currencyTo = 418;
                    break;
                case "WLC_LBP":
                case 422:
                    message.currencyTo = 422;
                    break;
                case "WLC_LSL":
                case 426:
                    message.currencyTo = 426;
                    break;
                case "WLC_LVL":
                case 428:
                    message.currencyTo = 428;
                    break;
                case "WLC_LRD":
                case 430:
                    message.currencyTo = 430;
                    break;
                case "WLC_LYD":
                case 434:
                    message.currencyTo = 434;
                    break;
                case "WLC_LTL":
                case 440:
                    message.currencyTo = 440;
                    break;
                case "WLC_MOP":
                case 446:
                    message.currencyTo = 446;
                    break;
                case "WLC_MWK":
                case 454:
                    message.currencyTo = 454;
                    break;
                case "WLC_MYR":
                case 458:
                    message.currencyTo = 458;
                    break;
                case "WLC_MVR":
                case 462:
                    message.currencyTo = 462;
                    break;
                case "WLC_MTL":
                case 470:
                    message.currencyTo = 470;
                    break;
                case "WLC_MRO":
                case 478:
                    message.currencyTo = 478;
                    break;
                case "WLC_MUR":
                case 480:
                    message.currencyTo = 480;
                    break;
                case "WLC_MXN":
                case 484:
                    message.currencyTo = 484;
                    break;
                case "WLC_MNT":
                case 496:
                    message.currencyTo = 496;
                    break;
                case "WLC_MDL":
                case 498:
                    message.currencyTo = 498;
                    break;
                case "WLC_MAD":
                case 504:
                    message.currencyTo = 504;
                    break;
                case "WLC_OMR":
                case 512:
                    message.currencyTo = 512;
                    break;
                case "WLC_NAD":
                case 516:
                    message.currencyTo = 516;
                    break;
                case "WLC_NPR":
                case 524:
                    message.currencyTo = 524;
                    break;
                case "WLC_ANG":
                case 532:
                    message.currencyTo = 532;
                    break;
                case "WLC_AWG":
                case 533:
                    message.currencyTo = 533;
                    break;
                case "WLC_VUV":
                case 548:
                    message.currencyTo = 548;
                    break;
                case "WLC_NZD":
                case 554:
                    message.currencyTo = 554;
                    break;
                case "WLC_NIO":
                case 558:
                    message.currencyTo = 558;
                    break;
                case "WLC_NGN":
                case 566:
                    message.currencyTo = 566;
                    break;
                case "WLC_NOK":
                case 578:
                    message.currencyTo = 578;
                    break;
                case "WLC_PKR":
                case 586:
                    message.currencyTo = 586;
                    break;
                case "WLC_PAB":
                case 590:
                    message.currencyTo = 590;
                    break;
                case "WLC_PGK":
                case 598:
                    message.currencyTo = 598;
                    break;
                case "WLC_PYG":
                case 600:
                    message.currencyTo = 600;
                    break;
                case "WLC_PEN":
                case 604:
                    message.currencyTo = 604;
                    break;
                case "WLC_PHP":
                case 608:
                    message.currencyTo = 608;
                    break;
                case "WLC_QAR":
                case 634:
                    message.currencyTo = 634;
                    break;
                case "WLC_RUB":
                case 643:
                    message.currencyTo = 643;
                    break;
                case "WLC_RWF":
                case 646:
                    message.currencyTo = 646;
                    break;
                case "WLC_SHP":
                case 654:
                    message.currencyTo = 654;
                    break;
                case "WLC_STD":
                case 678:
                    message.currencyTo = 678;
                    break;
                case "WLC_SAR":
                case 682:
                    message.currencyTo = 682;
                    break;
                case "WLC_SCR":
                case 690:
                    message.currencyTo = 690;
                    break;
                case "WLC_SLL":
                case 694:
                    message.currencyTo = 694;
                    break;
                case "WLC_SGD":
                case 702:
                    message.currencyTo = 702;
                    break;
                case "WLC_SKK":
                case 703:
                    message.currencyTo = 703;
                    break;
                case "WLC_VND":
                case 704:
                    message.currencyTo = 704;
                    break;
                case "WLC_SOS":
                case 706:
                    message.currencyTo = 706;
                    break;
                case "WLC_ZAR":
                case 710:
                    message.currencyTo = 710;
                    break;
                case "WLC_ZWD":
                case 716:
                    message.currencyTo = 716;
                    break;
                case "WLC_SSP":
                case 728:
                    message.currencyTo = 728;
                    break;
                case "WLC_SZL":
                case 748:
                    message.currencyTo = 748;
                    break;
                case "WLC_SEK":
                case 752:
                    message.currencyTo = 752;
                    break;
                case "WLC_CHF":
                case 756:
                    message.currencyTo = 756;
                    break;
                case "WLC_SYP":
                case 760:
                    message.currencyTo = 760;
                    break;
                case "WLC_THB":
                case 764:
                    message.currencyTo = 764;
                    break;
                case "WLC_TOP":
                case 776:
                    message.currencyTo = 776;
                    break;
                case "WLC_TTD":
                case 780:
                    message.currencyTo = 780;
                    break;
                case "WLC_AED":
                case 784:
                    message.currencyTo = 784;
                    break;
                case "WLC_TND":
                case 788:
                    message.currencyTo = 788;
                    break;
                case "WLC_TMM":
                case 795:
                    message.currencyTo = 795;
                    break;
                case "WLC_UGX":
                case 800:
                    message.currencyTo = 800;
                    break;
                case "WLC_MKD":
                case 807:
                    message.currencyTo = 807;
                    break;
                case "WLC_RUR":
                case 810:
                    message.currencyTo = 810;
                    break;
                case "WLC_EGP":
                case 818:
                    message.currencyTo = 818;
                    break;
                case "WLC_GBP":
                case 826:
                    message.currencyTo = 826;
                    break;
                case "WLC_TZS":
                case 834:
                    message.currencyTo = 834;
                    break;
                case "WLC_USD":
                case 840:
                    message.currencyTo = 840;
                    break;
                case "WLC_UYU":
                case 858:
                    message.currencyTo = 858;
                    break;
                case "WLC_UZS":
                case 860:
                    message.currencyTo = 860;
                    break;
                case "WLC_WST":
                case 882:
                    message.currencyTo = 882;
                    break;
                case "WLC_YER":
                case 886:
                    message.currencyTo = 886;
                    break;
                case "WLC_ZMK":
                case 894:
                    message.currencyTo = 894;
                    break;
                case "WLC_TWD":
                case 901:
                    message.currencyTo = 901;
                    break;
                case "WLC_CUC":
                case 931:
                    message.currencyTo = 931;
                    break;
                case "WLC_ZWL":
                case 932:
                    message.currencyTo = 932;
                    break;
                case "WLC_BYN":
                case 933:
                    message.currencyTo = 933;
                    break;
                case "WLC_TMT":
                case 934:
                    message.currencyTo = 934;
                    break;
                case "WLC_GHC":
                case 936:
                    message.currencyTo = 936;
                    break;
                case "WLC_VEF":
                case 937:
                    message.currencyTo = 937;
                    break;
                case "WLC_SDG":
                case 938:
                    message.currencyTo = 938;
                    break;
                case "WLC_RSD":
                case 941:
                    message.currencyTo = 941;
                    break;
                case "WLC_MZN":
                case 943:
                    message.currencyTo = 943;
                    break;
                case "WLC_AZN":
                case 944:
                    message.currencyTo = 944;
                    break;
                case "WLC_RON":
                case 946:
                    message.currencyTo = 946;
                    break;
                case "WLC_TRY":
                case 949:
                    message.currencyTo = 949;
                    break;
                case "WLC_XAF":
                case 950:
                    message.currencyTo = 950;
                    break;
                case "WLC_XCD":
                case 951:
                    message.currencyTo = 951;
                    break;
                case "WLC_XOF":
                case 952:
                    message.currencyTo = 952;
                    break;
                case "WLC_XPF":
                case 953:
                    message.currencyTo = 953;
                    break;
                case "WLC_XAU":
                case 959:
                    message.currencyTo = 959;
                    break;
                case "WLC_XDR":
                case 960:
                    message.currencyTo = 960;
                    break;
                case "WLC_XAG":
                case 961:
                    message.currencyTo = 961;
                    break;
                case "WLC_ZMW":
                case 967:
                    message.currencyTo = 967;
                    break;
                case "WLC_SRD":
                case 968:
                    message.currencyTo = 968;
                    break;
                case "WLC_MGA":
                case 969:
                    message.currencyTo = 969;
                    break;
                case "WLC_AFN":
                case 971:
                    message.currencyTo = 971;
                    break;
                case "WLC_TJS":
                case 972:
                    message.currencyTo = 972;
                    break;
                case "WLC_AOA":
                case 973:
                    message.currencyTo = 973;
                    break;
                case "WLC_BGN":
                case 975:
                    message.currencyTo = 975;
                    break;
                case "WLC_CDF":
                case 976:
                    message.currencyTo = 976;
                    break;
                case "WLC_BAM":
                case 977:
                    message.currencyTo = 977;
                    break;
                case "WLC_EUR":
                case 978:
                    message.currencyTo = 978;
                    break;
                case "WLC_UAH":
                case 980:
                    message.currencyTo = 980;
                    break;
                case "WLC_GEL":
                case 981:
                    message.currencyTo = 981;
                    break;
                case "WLC_PLN":
                case 985:
                    message.currencyTo = 985;
                    break;
                case "WLC_BRL":
                case 986:
                    message.currencyTo = 986;
                    break;
                case "WLC_CLF":
                case 990:
                    message.currencyTo = 990;
                    break;
                case "WLC_ENS":
                case 6004:
                    message.currencyTo = 6004;
                    break;
                case "WLC_SSC":
                case 6005:
                    message.currencyTo = 6005;
                    break;
                case "WLC_GOC":
                case 6006:
                    message.currencyTo = 6006;
                    break;
                case "WLC_GGP":
                case 80001:
                    message.currencyTo = 80001;
                    break;
                case "WLC_IMP":
                case 80002:
                    message.currencyTo = 80002;
                    break;
                case "WLC_JEP":
                case 80003:
                    message.currencyTo = 80003;
                    break;
                case "WLC_LTCT":
                case 90001:
                    message.currencyTo = 90001;
                    break;
                case "WLC_BTC":
                case 90002:
                    message.currencyTo = 90002;
                    break;
                case "WLC_ETH":
                case 90003:
                    message.currencyTo = 90003;
                    break;
                case "WLC_DASH":
                case 90004:
                    message.currencyTo = 90004;
                    break;
                case "WLC_ZEC":
                case 90005:
                    message.currencyTo = 90005;
                    break;
                case "WLC_BCH":
                case 90006:
                    message.currencyTo = 90006;
                    break;
                case "WLC_LTC":
                case 90007:
                    message.currencyTo = 90007;
                    break;
                case "WLC_XRP":
                case 90008:
                    message.currencyTo = 90008;
                    break;
                case "WLC_ETN":
                case 90009:
                    message.currencyTo = 90009;
                    break;
                case "WLC_USDT":
                case 90010:
                    message.currencyTo = 90010;
                    break;
                case "WLC_USDTERC20":
                case 90011:
                    message.currencyTo = 90011;
                    break;
                case "WLC_USDTTRC20":
                case 90012:
                    message.currencyTo = 90012;
                    break;
                case "WLC_TRX":
                case 90013:
                    message.currencyTo = 90013;
                    break;
                }
                if (object.rate != null)
                    message.rate = String(object.rate);
                return message;
            };
    
            /**
             * Creates a plain object from an ExchangeRate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof currencies.ExchangeRate
             * @static
             * @param {currencies.ExchangeRate} message ExchangeRate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ExchangeRate.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.currencyFrom = options.enums === String ? "WLC_FUN" : 0;
                    object.currencyTo = options.enums === String ? "WLC_FUN" : 0;
                    object.rate = "";
                }
                if (message.currencyFrom != null && message.hasOwnProperty("currencyFrom"))
                    object.currencyFrom = options.enums === String ? $root.currencies.WLCurrency[message.currencyFrom] : message.currencyFrom;
                if (message.currencyTo != null && message.hasOwnProperty("currencyTo"))
                    object.currencyTo = options.enums === String ? $root.currencies.WLCurrency[message.currencyTo] : message.currencyTo;
                if (message.rate != null && message.hasOwnProperty("rate"))
                    object.rate = message.rate;
                return object;
            };
    
            /**
             * Converts this ExchangeRate to JSON.
             * @function toJSON
             * @memberof currencies.ExchangeRate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ExchangeRate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ExchangeRate;
        })();
    
        currencies.ExchangeRates = (function() {
    
            /**
             * Properties of an ExchangeRates.
             * @memberof currencies
             * @interface IExchangeRates
             * @property {Array.<currencies.IExchangeRate>|null} [rates] ExchangeRates rates
             */
    
            /**
             * Constructs a new ExchangeRates.
             * @memberof currencies
             * @classdesc Represents an ExchangeRates.
             * @implements IExchangeRates
             * @constructor
             * @param {currencies.IExchangeRates=} [properties] Properties to set
             */
            function ExchangeRates(properties) {
                this.rates = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ExchangeRates rates.
             * @member {Array.<currencies.IExchangeRate>} rates
             * @memberof currencies.ExchangeRates
             * @instance
             */
            ExchangeRates.prototype.rates = $util.emptyArray;
    
            /**
             * Creates a new ExchangeRates instance using the specified properties.
             * @function create
             * @memberof currencies.ExchangeRates
             * @static
             * @param {currencies.IExchangeRates=} [properties] Properties to set
             * @returns {currencies.ExchangeRates} ExchangeRates instance
             */
            ExchangeRates.create = function create(properties) {
                return new ExchangeRates(properties);
            };
    
            /**
             * Encodes the specified ExchangeRates message. Does not implicitly {@link currencies.ExchangeRates.verify|verify} messages.
             * @function encode
             * @memberof currencies.ExchangeRates
             * @static
             * @param {currencies.IExchangeRates} message ExchangeRates message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExchangeRates.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.rates != null && message.rates.length)
                    for (var i = 0; i < message.rates.length; ++i)
                        $root.currencies.ExchangeRate.encode(message.rates[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ExchangeRates message, length delimited. Does not implicitly {@link currencies.ExchangeRates.verify|verify} messages.
             * @function encodeDelimited
             * @memberof currencies.ExchangeRates
             * @static
             * @param {currencies.IExchangeRates} message ExchangeRates message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExchangeRates.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an ExchangeRates message from the specified reader or buffer.
             * @function decode
             * @memberof currencies.ExchangeRates
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {currencies.ExchangeRates} ExchangeRates
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExchangeRates.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.currencies.ExchangeRates();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.rates && message.rates.length))
                            message.rates = [];
                        message.rates.push($root.currencies.ExchangeRate.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an ExchangeRates message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof currencies.ExchangeRates
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {currencies.ExchangeRates} ExchangeRates
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExchangeRates.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an ExchangeRates message.
             * @function verify
             * @memberof currencies.ExchangeRates
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ExchangeRates.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.rates != null && message.hasOwnProperty("rates")) {
                    if (!Array.isArray(message.rates))
                        return "rates: array expected";
                    for (var i = 0; i < message.rates.length; ++i) {
                        var error = $root.currencies.ExchangeRate.verify(message.rates[i]);
                        if (error)
                            return "rates." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an ExchangeRates message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof currencies.ExchangeRates
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {currencies.ExchangeRates} ExchangeRates
             */
            ExchangeRates.fromObject = function fromObject(object) {
                if (object instanceof $root.currencies.ExchangeRates)
                    return object;
                var message = new $root.currencies.ExchangeRates();
                if (object.rates) {
                    if (!Array.isArray(object.rates))
                        throw TypeError(".currencies.ExchangeRates.rates: array expected");
                    message.rates = [];
                    for (var i = 0; i < object.rates.length; ++i) {
                        if (typeof object.rates[i] !== "object")
                            throw TypeError(".currencies.ExchangeRates.rates: object expected");
                        message.rates[i] = $root.currencies.ExchangeRate.fromObject(object.rates[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from an ExchangeRates message. Also converts values to other types if specified.
             * @function toObject
             * @memberof currencies.ExchangeRates
             * @static
             * @param {currencies.ExchangeRates} message ExchangeRates
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ExchangeRates.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.rates = [];
                if (message.rates && message.rates.length) {
                    object.rates = [];
                    for (var j = 0; j < message.rates.length; ++j)
                        object.rates[j] = $root.currencies.ExchangeRate.toObject(message.rates[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ExchangeRates to JSON.
             * @function toJSON
             * @memberof currencies.ExchangeRates
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ExchangeRates.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ExchangeRates;
        })();
    
        return currencies;
    })();
    
    $root.google = (function() {
    
        /**
         * Namespace google.
         * @exports google
         * @namespace
         */
        var google = {};
    
        google.protobuf = (function() {
    
            /**
             * Namespace protobuf.
             * @memberof google
             * @namespace
             */
            var protobuf = {};
    
            protobuf.FileDescriptorSet = (function() {
    
                /**
                 * Properties of a FileDescriptorSet.
                 * @memberof google.protobuf
                 * @interface IFileDescriptorSet
                 * @property {Array.<google.protobuf.IFileDescriptorProto>|null} [file] FileDescriptorSet file
                 */
    
                /**
                 * Constructs a new FileDescriptorSet.
                 * @memberof google.protobuf
                 * @classdesc Represents a FileDescriptorSet.
                 * @implements IFileDescriptorSet
                 * @constructor
                 * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
                 */
                function FileDescriptorSet(properties) {
                    this.file = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FileDescriptorSet file.
                 * @member {Array.<google.protobuf.IFileDescriptorProto>} file
                 * @memberof google.protobuf.FileDescriptorSet
                 * @instance
                 */
                FileDescriptorSet.prototype.file = $util.emptyArray;
    
                /**
                 * Creates a new FileDescriptorSet instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
                 * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet instance
                 */
                FileDescriptorSet.create = function create(properties) {
                    return new FileDescriptorSet(properties);
                };
    
                /**
                 * Encodes the specified FileDescriptorSet message. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileDescriptorSet.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.file != null && message.file.length)
                        for (var i = 0; i < message.file.length; ++i)
                            $root.google.protobuf.FileDescriptorProto.encode(message.file[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FileDescriptorSet message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileDescriptorSet.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FileDescriptorSet message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileDescriptorSet.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorSet();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.file && message.file.length))
                                message.file = [];
                            message.file.push($root.google.protobuf.FileDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FileDescriptorSet message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileDescriptorSet.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FileDescriptorSet message.
                 * @function verify
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FileDescriptorSet.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.file != null && message.hasOwnProperty("file")) {
                        if (!Array.isArray(message.file))
                            return "file: array expected";
                        for (var i = 0; i < message.file.length; ++i) {
                            var error = $root.google.protobuf.FileDescriptorProto.verify(message.file[i]);
                            if (error)
                                return "file." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a FileDescriptorSet message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
                 */
                FileDescriptorSet.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FileDescriptorSet)
                        return object;
                    var message = new $root.google.protobuf.FileDescriptorSet();
                    if (object.file) {
                        if (!Array.isArray(object.file))
                            throw TypeError(".google.protobuf.FileDescriptorSet.file: array expected");
                        message.file = [];
                        for (var i = 0; i < object.file.length; ++i) {
                            if (typeof object.file[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorSet.file: object expected");
                            message.file[i] = $root.google.protobuf.FileDescriptorProto.fromObject(object.file[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FileDescriptorSet message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {google.protobuf.FileDescriptorSet} message FileDescriptorSet
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FileDescriptorSet.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.file = [];
                    if (message.file && message.file.length) {
                        object.file = [];
                        for (var j = 0; j < message.file.length; ++j)
                            object.file[j] = $root.google.protobuf.FileDescriptorProto.toObject(message.file[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this FileDescriptorSet to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FileDescriptorSet
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FileDescriptorSet.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return FileDescriptorSet;
            })();
    
            protobuf.FileDescriptorProto = (function() {
    
                /**
                 * Properties of a FileDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IFileDescriptorProto
                 * @property {string|null} [name] FileDescriptorProto name
                 * @property {string|null} ["package"] FileDescriptorProto package
                 * @property {Array.<string>|null} [dependency] FileDescriptorProto dependency
                 * @property {Array.<number>|null} [publicDependency] FileDescriptorProto publicDependency
                 * @property {Array.<number>|null} [weakDependency] FileDescriptorProto weakDependency
                 * @property {Array.<google.protobuf.IDescriptorProto>|null} [messageType] FileDescriptorProto messageType
                 * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] FileDescriptorProto enumType
                 * @property {Array.<google.protobuf.IServiceDescriptorProto>|null} [service] FileDescriptorProto service
                 * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] FileDescriptorProto extension
                 * @property {google.protobuf.IFileOptions|null} [options] FileDescriptorProto options
                 * @property {google.protobuf.ISourceCodeInfo|null} [sourceCodeInfo] FileDescriptorProto sourceCodeInfo
                 * @property {string|null} [syntax] FileDescriptorProto syntax
                 */
    
                /**
                 * Constructs a new FileDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a FileDescriptorProto.
                 * @implements IFileDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
                 */
                function FileDescriptorProto(properties) {
                    this.dependency = [];
                    this.publicDependency = [];
                    this.weakDependency = [];
                    this.messageType = [];
                    this.enumType = [];
                    this.service = [];
                    this.extension = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FileDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.name = "";
    
                /**
                 * FileDescriptorProto package.
                 * @member {string} package
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype["package"] = "";
    
                /**
                 * FileDescriptorProto dependency.
                 * @member {Array.<string>} dependency
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.dependency = $util.emptyArray;
    
                /**
                 * FileDescriptorProto publicDependency.
                 * @member {Array.<number>} publicDependency
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.publicDependency = $util.emptyArray;
    
                /**
                 * FileDescriptorProto weakDependency.
                 * @member {Array.<number>} weakDependency
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.weakDependency = $util.emptyArray;
    
                /**
                 * FileDescriptorProto messageType.
                 * @member {Array.<google.protobuf.IDescriptorProto>} messageType
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.messageType = $util.emptyArray;
    
                /**
                 * FileDescriptorProto enumType.
                 * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.enumType = $util.emptyArray;
    
                /**
                 * FileDescriptorProto service.
                 * @member {Array.<google.protobuf.IServiceDescriptorProto>} service
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.service = $util.emptyArray;
    
                /**
                 * FileDescriptorProto extension.
                 * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.extension = $util.emptyArray;
    
                /**
                 * FileDescriptorProto options.
                 * @member {google.protobuf.IFileOptions|null|undefined} options
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.options = null;
    
                /**
                 * FileDescriptorProto sourceCodeInfo.
                 * @member {google.protobuf.ISourceCodeInfo|null|undefined} sourceCodeInfo
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.sourceCodeInfo = null;
    
                /**
                 * FileDescriptorProto syntax.
                 * @member {string} syntax
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.syntax = "";
    
                /**
                 * Creates a new FileDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto instance
                 */
                FileDescriptorProto.create = function create(properties) {
                    return new FileDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified FileDescriptorProto message. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message["package"] != null && Object.hasOwnProperty.call(message, "package"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message["package"]);
                    if (message.dependency != null && message.dependency.length)
                        for (var i = 0; i < message.dependency.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.dependency[i]);
                    if (message.messageType != null && message.messageType.length)
                        for (var i = 0; i < message.messageType.length; ++i)
                            $root.google.protobuf.DescriptorProto.encode(message.messageType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.enumType != null && message.enumType.length)
                        for (var i = 0; i < message.enumType.length; ++i)
                            $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.service != null && message.service.length)
                        for (var i = 0; i < message.service.length; ++i)
                            $root.google.protobuf.ServiceDescriptorProto.encode(message.service[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.extension != null && message.extension.length)
                        for (var i = 0; i < message.extension.length; ++i)
                            $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.FileOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.sourceCodeInfo != null && Object.hasOwnProperty.call(message, "sourceCodeInfo"))
                        $root.google.protobuf.SourceCodeInfo.encode(message.sourceCodeInfo, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.publicDependency != null && message.publicDependency.length)
                        for (var i = 0; i < message.publicDependency.length; ++i)
                            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.publicDependency[i]);
                    if (message.weakDependency != null && message.weakDependency.length)
                        for (var i = 0; i < message.weakDependency.length; ++i)
                            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.weakDependency[i]);
                    if (message.syntax != null && Object.hasOwnProperty.call(message, "syntax"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.syntax);
                    return writer;
                };
    
                /**
                 * Encodes the specified FileDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FileDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            message["package"] = reader.string();
                            break;
                        case 3:
                            if (!(message.dependency && message.dependency.length))
                                message.dependency = [];
                            message.dependency.push(reader.string());
                            break;
                        case 10:
                            if (!(message.publicDependency && message.publicDependency.length))
                                message.publicDependency = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.publicDependency.push(reader.int32());
                            } else
                                message.publicDependency.push(reader.int32());
                            break;
                        case 11:
                            if (!(message.weakDependency && message.weakDependency.length))
                                message.weakDependency = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.weakDependency.push(reader.int32());
                            } else
                                message.weakDependency.push(reader.int32());
                            break;
                        case 4:
                            if (!(message.messageType && message.messageType.length))
                                message.messageType = [];
                            message.messageType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 5:
                            if (!(message.enumType && message.enumType.length))
                                message.enumType = [];
                            message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 6:
                            if (!(message.service && message.service.length))
                                message.service = [];
                            message.service.push($root.google.protobuf.ServiceDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 7:
                            if (!(message.extension && message.extension.length))
                                message.extension = [];
                            message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 8:
                            message.options = $root.google.protobuf.FileOptions.decode(reader, reader.uint32());
                            break;
                        case 9:
                            message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.decode(reader, reader.uint32());
                            break;
                        case 12:
                            message.syntax = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FileDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FileDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FileDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message["package"] != null && message.hasOwnProperty("package"))
                        if (!$util.isString(message["package"]))
                            return "package: string expected";
                    if (message.dependency != null && message.hasOwnProperty("dependency")) {
                        if (!Array.isArray(message.dependency))
                            return "dependency: array expected";
                        for (var i = 0; i < message.dependency.length; ++i)
                            if (!$util.isString(message.dependency[i]))
                                return "dependency: string[] expected";
                    }
                    if (message.publicDependency != null && message.hasOwnProperty("publicDependency")) {
                        if (!Array.isArray(message.publicDependency))
                            return "publicDependency: array expected";
                        for (var i = 0; i < message.publicDependency.length; ++i)
                            if (!$util.isInteger(message.publicDependency[i]))
                                return "publicDependency: integer[] expected";
                    }
                    if (message.weakDependency != null && message.hasOwnProperty("weakDependency")) {
                        if (!Array.isArray(message.weakDependency))
                            return "weakDependency: array expected";
                        for (var i = 0; i < message.weakDependency.length; ++i)
                            if (!$util.isInteger(message.weakDependency[i]))
                                return "weakDependency: integer[] expected";
                    }
                    if (message.messageType != null && message.hasOwnProperty("messageType")) {
                        if (!Array.isArray(message.messageType))
                            return "messageType: array expected";
                        for (var i = 0; i < message.messageType.length; ++i) {
                            var error = $root.google.protobuf.DescriptorProto.verify(message.messageType[i]);
                            if (error)
                                return "messageType." + error;
                        }
                    }
                    if (message.enumType != null && message.hasOwnProperty("enumType")) {
                        if (!Array.isArray(message.enumType))
                            return "enumType: array expected";
                        for (var i = 0; i < message.enumType.length; ++i) {
                            var error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                            if (error)
                                return "enumType." + error;
                        }
                    }
                    if (message.service != null && message.hasOwnProperty("service")) {
                        if (!Array.isArray(message.service))
                            return "service: array expected";
                        for (var i = 0; i < message.service.length; ++i) {
                            var error = $root.google.protobuf.ServiceDescriptorProto.verify(message.service[i]);
                            if (error)
                                return "service." + error;
                        }
                    }
                    if (message.extension != null && message.hasOwnProperty("extension")) {
                        if (!Array.isArray(message.extension))
                            return "extension: array expected";
                        for (var i = 0; i < message.extension.length; ++i) {
                            var error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                            if (error)
                                return "extension." + error;
                        }
                    }
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.FileOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo")) {
                        var error = $root.google.protobuf.SourceCodeInfo.verify(message.sourceCodeInfo);
                        if (error)
                            return "sourceCodeInfo." + error;
                    }
                    if (message.syntax != null && message.hasOwnProperty("syntax"))
                        if (!$util.isString(message.syntax))
                            return "syntax: string expected";
                    return null;
                };
    
                /**
                 * Creates a FileDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
                 */
                FileDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FileDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.FileDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object["package"] != null)
                        message["package"] = String(object["package"]);
                    if (object.dependency) {
                        if (!Array.isArray(object.dependency))
                            throw TypeError(".google.protobuf.FileDescriptorProto.dependency: array expected");
                        message.dependency = [];
                        for (var i = 0; i < object.dependency.length; ++i)
                            message.dependency[i] = String(object.dependency[i]);
                    }
                    if (object.publicDependency) {
                        if (!Array.isArray(object.publicDependency))
                            throw TypeError(".google.protobuf.FileDescriptorProto.publicDependency: array expected");
                        message.publicDependency = [];
                        for (var i = 0; i < object.publicDependency.length; ++i)
                            message.publicDependency[i] = object.publicDependency[i] | 0;
                    }
                    if (object.weakDependency) {
                        if (!Array.isArray(object.weakDependency))
                            throw TypeError(".google.protobuf.FileDescriptorProto.weakDependency: array expected");
                        message.weakDependency = [];
                        for (var i = 0; i < object.weakDependency.length; ++i)
                            message.weakDependency[i] = object.weakDependency[i] | 0;
                    }
                    if (object.messageType) {
                        if (!Array.isArray(object.messageType))
                            throw TypeError(".google.protobuf.FileDescriptorProto.messageType: array expected");
                        message.messageType = [];
                        for (var i = 0; i < object.messageType.length; ++i) {
                            if (typeof object.messageType[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorProto.messageType: object expected");
                            message.messageType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.messageType[i]);
                        }
                    }
                    if (object.enumType) {
                        if (!Array.isArray(object.enumType))
                            throw TypeError(".google.protobuf.FileDescriptorProto.enumType: array expected");
                        message.enumType = [];
                        for (var i = 0; i < object.enumType.length; ++i) {
                            if (typeof object.enumType[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorProto.enumType: object expected");
                            message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                        }
                    }
                    if (object.service) {
                        if (!Array.isArray(object.service))
                            throw TypeError(".google.protobuf.FileDescriptorProto.service: array expected");
                        message.service = [];
                        for (var i = 0; i < object.service.length; ++i) {
                            if (typeof object.service[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorProto.service: object expected");
                            message.service[i] = $root.google.protobuf.ServiceDescriptorProto.fromObject(object.service[i]);
                        }
                    }
                    if (object.extension) {
                        if (!Array.isArray(object.extension))
                            throw TypeError(".google.protobuf.FileDescriptorProto.extension: array expected");
                        message.extension = [];
                        for (var i = 0; i < object.extension.length; ++i) {
                            if (typeof object.extension[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorProto.extension: object expected");
                            message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                        }
                    }
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.FileOptions.fromObject(object.options);
                    }
                    if (object.sourceCodeInfo != null) {
                        if (typeof object.sourceCodeInfo !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.sourceCodeInfo: object expected");
                        message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.fromObject(object.sourceCodeInfo);
                    }
                    if (object.syntax != null)
                        message.syntax = String(object.syntax);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FileDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {google.protobuf.FileDescriptorProto} message FileDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FileDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.dependency = [];
                        object.messageType = [];
                        object.enumType = [];
                        object.service = [];
                        object.extension = [];
                        object.publicDependency = [];
                        object.weakDependency = [];
                    }
                    if (options.defaults) {
                        object.name = "";
                        object["package"] = "";
                        object.options = null;
                        object.sourceCodeInfo = null;
                        object.syntax = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message["package"] != null && message.hasOwnProperty("package"))
                        object["package"] = message["package"];
                    if (message.dependency && message.dependency.length) {
                        object.dependency = [];
                        for (var j = 0; j < message.dependency.length; ++j)
                            object.dependency[j] = message.dependency[j];
                    }
                    if (message.messageType && message.messageType.length) {
                        object.messageType = [];
                        for (var j = 0; j < message.messageType.length; ++j)
                            object.messageType[j] = $root.google.protobuf.DescriptorProto.toObject(message.messageType[j], options);
                    }
                    if (message.enumType && message.enumType.length) {
                        object.enumType = [];
                        for (var j = 0; j < message.enumType.length; ++j)
                            object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                    }
                    if (message.service && message.service.length) {
                        object.service = [];
                        for (var j = 0; j < message.service.length; ++j)
                            object.service[j] = $root.google.protobuf.ServiceDescriptorProto.toObject(message.service[j], options);
                    }
                    if (message.extension && message.extension.length) {
                        object.extension = [];
                        for (var j = 0; j < message.extension.length; ++j)
                            object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                    }
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.FileOptions.toObject(message.options, options);
                    if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo"))
                        object.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.toObject(message.sourceCodeInfo, options);
                    if (message.publicDependency && message.publicDependency.length) {
                        object.publicDependency = [];
                        for (var j = 0; j < message.publicDependency.length; ++j)
                            object.publicDependency[j] = message.publicDependency[j];
                    }
                    if (message.weakDependency && message.weakDependency.length) {
                        object.weakDependency = [];
                        for (var j = 0; j < message.weakDependency.length; ++j)
                            object.weakDependency[j] = message.weakDependency[j];
                    }
                    if (message.syntax != null && message.hasOwnProperty("syntax"))
                        object.syntax = message.syntax;
                    return object;
                };
    
                /**
                 * Converts this FileDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FileDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return FileDescriptorProto;
            })();
    
            protobuf.DescriptorProto = (function() {
    
                /**
                 * Properties of a DescriptorProto.
                 * @memberof google.protobuf
                 * @interface IDescriptorProto
                 * @property {string|null} [name] DescriptorProto name
                 * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [field] DescriptorProto field
                 * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] DescriptorProto extension
                 * @property {Array.<google.protobuf.IDescriptorProto>|null} [nestedType] DescriptorProto nestedType
                 * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] DescriptorProto enumType
                 * @property {Array.<google.protobuf.DescriptorProto.IExtensionRange>|null} [extensionRange] DescriptorProto extensionRange
                 * @property {Array.<google.protobuf.IOneofDescriptorProto>|null} [oneofDecl] DescriptorProto oneofDecl
                 * @property {google.protobuf.IMessageOptions|null} [options] DescriptorProto options
                 * @property {Array.<google.protobuf.DescriptorProto.IReservedRange>|null} [reservedRange] DescriptorProto reservedRange
                 * @property {Array.<string>|null} [reservedName] DescriptorProto reservedName
                 */
    
                /**
                 * Constructs a new DescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a DescriptorProto.
                 * @implements IDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
                 */
                function DescriptorProto(properties) {
                    this.field = [];
                    this.extension = [];
                    this.nestedType = [];
                    this.enumType = [];
                    this.extensionRange = [];
                    this.oneofDecl = [];
                    this.reservedRange = [];
                    this.reservedName = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.name = "";
    
                /**
                 * DescriptorProto field.
                 * @member {Array.<google.protobuf.IFieldDescriptorProto>} field
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.field = $util.emptyArray;
    
                /**
                 * DescriptorProto extension.
                 * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.extension = $util.emptyArray;
    
                /**
                 * DescriptorProto nestedType.
                 * @member {Array.<google.protobuf.IDescriptorProto>} nestedType
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.nestedType = $util.emptyArray;
    
                /**
                 * DescriptorProto enumType.
                 * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.enumType = $util.emptyArray;
    
                /**
                 * DescriptorProto extensionRange.
                 * @member {Array.<google.protobuf.DescriptorProto.IExtensionRange>} extensionRange
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.extensionRange = $util.emptyArray;
    
                /**
                 * DescriptorProto oneofDecl.
                 * @member {Array.<google.protobuf.IOneofDescriptorProto>} oneofDecl
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.oneofDecl = $util.emptyArray;
    
                /**
                 * DescriptorProto options.
                 * @member {google.protobuf.IMessageOptions|null|undefined} options
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.options = null;
    
                /**
                 * DescriptorProto reservedRange.
                 * @member {Array.<google.protobuf.DescriptorProto.IReservedRange>} reservedRange
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.reservedRange = $util.emptyArray;
    
                /**
                 * DescriptorProto reservedName.
                 * @member {Array.<string>} reservedName
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.reservedName = $util.emptyArray;
    
                /**
                 * Creates a new DescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto} DescriptorProto instance
                 */
                DescriptorProto.create = function create(properties) {
                    return new DescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified DescriptorProto message. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.field != null && message.field.length)
                        for (var i = 0; i < message.field.length; ++i)
                            $root.google.protobuf.FieldDescriptorProto.encode(message.field[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.nestedType != null && message.nestedType.length)
                        for (var i = 0; i < message.nestedType.length; ++i)
                            $root.google.protobuf.DescriptorProto.encode(message.nestedType[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.enumType != null && message.enumType.length)
                        for (var i = 0; i < message.enumType.length; ++i)
                            $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.extensionRange != null && message.extensionRange.length)
                        for (var i = 0; i < message.extensionRange.length; ++i)
                            $root.google.protobuf.DescriptorProto.ExtensionRange.encode(message.extensionRange[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.extension != null && message.extension.length)
                        for (var i = 0; i < message.extension.length; ++i)
                            $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.MessageOptions.encode(message.options, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.oneofDecl != null && message.oneofDecl.length)
                        for (var i = 0; i < message.oneofDecl.length; ++i)
                            $root.google.protobuf.OneofDescriptorProto.encode(message.oneofDecl[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.reservedRange != null && message.reservedRange.length)
                        for (var i = 0; i < message.reservedRange.length; ++i)
                            $root.google.protobuf.DescriptorProto.ReservedRange.encode(message.reservedRange[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.reservedName != null && message.reservedName.length)
                        for (var i = 0; i < message.reservedName.length; ++i)
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.reservedName[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified DescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto} DescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            if (!(message.field && message.field.length))
                                message.field = [];
                            message.field.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 6:
                            if (!(message.extension && message.extension.length))
                                message.extension = [];
                            message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 3:
                            if (!(message.nestedType && message.nestedType.length))
                                message.nestedType = [];
                            message.nestedType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 4:
                            if (!(message.enumType && message.enumType.length))
                                message.enumType = [];
                            message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 5:
                            if (!(message.extensionRange && message.extensionRange.length))
                                message.extensionRange = [];
                            message.extensionRange.push($root.google.protobuf.DescriptorProto.ExtensionRange.decode(reader, reader.uint32()));
                            break;
                        case 8:
                            if (!(message.oneofDecl && message.oneofDecl.length))
                                message.oneofDecl = [];
                            message.oneofDecl.push($root.google.protobuf.OneofDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 7:
                            message.options = $root.google.protobuf.MessageOptions.decode(reader, reader.uint32());
                            break;
                        case 9:
                            if (!(message.reservedRange && message.reservedRange.length))
                                message.reservedRange = [];
                            message.reservedRange.push($root.google.protobuf.DescriptorProto.ReservedRange.decode(reader, reader.uint32()));
                            break;
                        case 10:
                            if (!(message.reservedName && message.reservedName.length))
                                message.reservedName = [];
                            message.reservedName.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.DescriptorProto} DescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.field != null && message.hasOwnProperty("field")) {
                        if (!Array.isArray(message.field))
                            return "field: array expected";
                        for (var i = 0; i < message.field.length; ++i) {
                            var error = $root.google.protobuf.FieldDescriptorProto.verify(message.field[i]);
                            if (error)
                                return "field." + error;
                        }
                    }
                    if (message.extension != null && message.hasOwnProperty("extension")) {
                        if (!Array.isArray(message.extension))
                            return "extension: array expected";
                        for (var i = 0; i < message.extension.length; ++i) {
                            var error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                            if (error)
                                return "extension." + error;
                        }
                    }
                    if (message.nestedType != null && message.hasOwnProperty("nestedType")) {
                        if (!Array.isArray(message.nestedType))
                            return "nestedType: array expected";
                        for (var i = 0; i < message.nestedType.length; ++i) {
                            var error = $root.google.protobuf.DescriptorProto.verify(message.nestedType[i]);
                            if (error)
                                return "nestedType." + error;
                        }
                    }
                    if (message.enumType != null && message.hasOwnProperty("enumType")) {
                        if (!Array.isArray(message.enumType))
                            return "enumType: array expected";
                        for (var i = 0; i < message.enumType.length; ++i) {
                            var error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                            if (error)
                                return "enumType." + error;
                        }
                    }
                    if (message.extensionRange != null && message.hasOwnProperty("extensionRange")) {
                        if (!Array.isArray(message.extensionRange))
                            return "extensionRange: array expected";
                        for (var i = 0; i < message.extensionRange.length; ++i) {
                            var error = $root.google.protobuf.DescriptorProto.ExtensionRange.verify(message.extensionRange[i]);
                            if (error)
                                return "extensionRange." + error;
                        }
                    }
                    if (message.oneofDecl != null && message.hasOwnProperty("oneofDecl")) {
                        if (!Array.isArray(message.oneofDecl))
                            return "oneofDecl: array expected";
                        for (var i = 0; i < message.oneofDecl.length; ++i) {
                            var error = $root.google.protobuf.OneofDescriptorProto.verify(message.oneofDecl[i]);
                            if (error)
                                return "oneofDecl." + error;
                        }
                    }
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.MessageOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    if (message.reservedRange != null && message.hasOwnProperty("reservedRange")) {
                        if (!Array.isArray(message.reservedRange))
                            return "reservedRange: array expected";
                        for (var i = 0; i < message.reservedRange.length; ++i) {
                            var error = $root.google.protobuf.DescriptorProto.ReservedRange.verify(message.reservedRange[i]);
                            if (error)
                                return "reservedRange." + error;
                        }
                    }
                    if (message.reservedName != null && message.hasOwnProperty("reservedName")) {
                        if (!Array.isArray(message.reservedName))
                            return "reservedName: array expected";
                        for (var i = 0; i < message.reservedName.length; ++i)
                            if (!$util.isString(message.reservedName[i]))
                                return "reservedName: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a DescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto} DescriptorProto
                 */
                DescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.DescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.field) {
                        if (!Array.isArray(object.field))
                            throw TypeError(".google.protobuf.DescriptorProto.field: array expected");
                        message.field = [];
                        for (var i = 0; i < object.field.length; ++i) {
                            if (typeof object.field[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.field: object expected");
                            message.field[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.field[i]);
                        }
                    }
                    if (object.extension) {
                        if (!Array.isArray(object.extension))
                            throw TypeError(".google.protobuf.DescriptorProto.extension: array expected");
                        message.extension = [];
                        for (var i = 0; i < object.extension.length; ++i) {
                            if (typeof object.extension[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.extension: object expected");
                            message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                        }
                    }
                    if (object.nestedType) {
                        if (!Array.isArray(object.nestedType))
                            throw TypeError(".google.protobuf.DescriptorProto.nestedType: array expected");
                        message.nestedType = [];
                        for (var i = 0; i < object.nestedType.length; ++i) {
                            if (typeof object.nestedType[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.nestedType: object expected");
                            message.nestedType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.nestedType[i]);
                        }
                    }
                    if (object.enumType) {
                        if (!Array.isArray(object.enumType))
                            throw TypeError(".google.protobuf.DescriptorProto.enumType: array expected");
                        message.enumType = [];
                        for (var i = 0; i < object.enumType.length; ++i) {
                            if (typeof object.enumType[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.enumType: object expected");
                            message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                        }
                    }
                    if (object.extensionRange) {
                        if (!Array.isArray(object.extensionRange))
                            throw TypeError(".google.protobuf.DescriptorProto.extensionRange: array expected");
                        message.extensionRange = [];
                        for (var i = 0; i < object.extensionRange.length; ++i) {
                            if (typeof object.extensionRange[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.extensionRange: object expected");
                            message.extensionRange[i] = $root.google.protobuf.DescriptorProto.ExtensionRange.fromObject(object.extensionRange[i]);
                        }
                    }
                    if (object.oneofDecl) {
                        if (!Array.isArray(object.oneofDecl))
                            throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: array expected");
                        message.oneofDecl = [];
                        for (var i = 0; i < object.oneofDecl.length; ++i) {
                            if (typeof object.oneofDecl[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: object expected");
                            message.oneofDecl[i] = $root.google.protobuf.OneofDescriptorProto.fromObject(object.oneofDecl[i]);
                        }
                    }
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.MessageOptions.fromObject(object.options);
                    }
                    if (object.reservedRange) {
                        if (!Array.isArray(object.reservedRange))
                            throw TypeError(".google.protobuf.DescriptorProto.reservedRange: array expected");
                        message.reservedRange = [];
                        for (var i = 0; i < object.reservedRange.length; ++i) {
                            if (typeof object.reservedRange[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.reservedRange: object expected");
                            message.reservedRange[i] = $root.google.protobuf.DescriptorProto.ReservedRange.fromObject(object.reservedRange[i]);
                        }
                    }
                    if (object.reservedName) {
                        if (!Array.isArray(object.reservedName))
                            throw TypeError(".google.protobuf.DescriptorProto.reservedName: array expected");
                        message.reservedName = [];
                        for (var i = 0; i < object.reservedName.length; ++i)
                            message.reservedName[i] = String(object.reservedName[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a DescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {google.protobuf.DescriptorProto} message DescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.field = [];
                        object.nestedType = [];
                        object.enumType = [];
                        object.extensionRange = [];
                        object.extension = [];
                        object.oneofDecl = [];
                        object.reservedRange = [];
                        object.reservedName = [];
                    }
                    if (options.defaults) {
                        object.name = "";
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.field && message.field.length) {
                        object.field = [];
                        for (var j = 0; j < message.field.length; ++j)
                            object.field[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.field[j], options);
                    }
                    if (message.nestedType && message.nestedType.length) {
                        object.nestedType = [];
                        for (var j = 0; j < message.nestedType.length; ++j)
                            object.nestedType[j] = $root.google.protobuf.DescriptorProto.toObject(message.nestedType[j], options);
                    }
                    if (message.enumType && message.enumType.length) {
                        object.enumType = [];
                        for (var j = 0; j < message.enumType.length; ++j)
                            object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                    }
                    if (message.extensionRange && message.extensionRange.length) {
                        object.extensionRange = [];
                        for (var j = 0; j < message.extensionRange.length; ++j)
                            object.extensionRange[j] = $root.google.protobuf.DescriptorProto.ExtensionRange.toObject(message.extensionRange[j], options);
                    }
                    if (message.extension && message.extension.length) {
                        object.extension = [];
                        for (var j = 0; j < message.extension.length; ++j)
                            object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                    }
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.MessageOptions.toObject(message.options, options);
                    if (message.oneofDecl && message.oneofDecl.length) {
                        object.oneofDecl = [];
                        for (var j = 0; j < message.oneofDecl.length; ++j)
                            object.oneofDecl[j] = $root.google.protobuf.OneofDescriptorProto.toObject(message.oneofDecl[j], options);
                    }
                    if (message.reservedRange && message.reservedRange.length) {
                        object.reservedRange = [];
                        for (var j = 0; j < message.reservedRange.length; ++j)
                            object.reservedRange[j] = $root.google.protobuf.DescriptorProto.ReservedRange.toObject(message.reservedRange[j], options);
                    }
                    if (message.reservedName && message.reservedName.length) {
                        object.reservedName = [];
                        for (var j = 0; j < message.reservedName.length; ++j)
                            object.reservedName[j] = message.reservedName[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this DescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                DescriptorProto.ExtensionRange = (function() {
    
                    /**
                     * Properties of an ExtensionRange.
                     * @memberof google.protobuf.DescriptorProto
                     * @interface IExtensionRange
                     * @property {number|null} [start] ExtensionRange start
                     * @property {number|null} [end] ExtensionRange end
                     */
    
                    /**
                     * Constructs a new ExtensionRange.
                     * @memberof google.protobuf.DescriptorProto
                     * @classdesc Represents an ExtensionRange.
                     * @implements IExtensionRange
                     * @constructor
                     * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                     */
                    function ExtensionRange(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExtensionRange start.
                     * @member {number} start
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @instance
                     */
                    ExtensionRange.prototype.start = 0;
    
                    /**
                     * ExtensionRange end.
                     * @member {number} end
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @instance
                     */
                    ExtensionRange.prototype.end = 0;
    
                    /**
                     * Creates a new ExtensionRange instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                     * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange instance
                     */
                    ExtensionRange.create = function create(properties) {
                        return new ExtensionRange(properties);
                    };
    
                    /**
                     * Encodes the specified ExtensionRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExtensionRange.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                        if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExtensionRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExtensionRange.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExtensionRange message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExtensionRange.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.start = reader.int32();
                                break;
                            case 2:
                                message.end = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExtensionRange message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExtensionRange.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExtensionRange message.
                     * @function verify
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExtensionRange.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.start != null && message.hasOwnProperty("start"))
                            if (!$util.isInteger(message.start))
                                return "start: integer expected";
                        if (message.end != null && message.hasOwnProperty("end"))
                            if (!$util.isInteger(message.end))
                                return "end: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates an ExtensionRange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                     */
                    ExtensionRange.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.DescriptorProto.ExtensionRange)
                            return object;
                        var message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                        if (object.start != null)
                            message.start = object.start | 0;
                        if (object.end != null)
                            message.end = object.end | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExtensionRange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.ExtensionRange} message ExtensionRange
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExtensionRange.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.start = 0;
                            object.end = 0;
                        }
                        if (message.start != null && message.hasOwnProperty("start"))
                            object.start = message.start;
                        if (message.end != null && message.hasOwnProperty("end"))
                            object.end = message.end;
                        return object;
                    };
    
                    /**
                     * Converts this ExtensionRange to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExtensionRange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ExtensionRange;
                })();
    
                DescriptorProto.ReservedRange = (function() {
    
                    /**
                     * Properties of a ReservedRange.
                     * @memberof google.protobuf.DescriptorProto
                     * @interface IReservedRange
                     * @property {number|null} [start] ReservedRange start
                     * @property {number|null} [end] ReservedRange end
                     */
    
                    /**
                     * Constructs a new ReservedRange.
                     * @memberof google.protobuf.DescriptorProto
                     * @classdesc Represents a ReservedRange.
                     * @implements IReservedRange
                     * @constructor
                     * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                     */
                    function ReservedRange(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ReservedRange start.
                     * @member {number} start
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @instance
                     */
                    ReservedRange.prototype.start = 0;
    
                    /**
                     * ReservedRange end.
                     * @member {number} end
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @instance
                     */
                    ReservedRange.prototype.end = 0;
    
                    /**
                     * Creates a new ReservedRange instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                     * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange instance
                     */
                    ReservedRange.create = function create(properties) {
                        return new ReservedRange(properties);
                    };
    
                    /**
                     * Encodes the specified ReservedRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReservedRange.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                        if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ReservedRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReservedRange.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ReservedRange message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReservedRange.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.start = reader.int32();
                                break;
                            case 2:
                                message.end = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ReservedRange message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReservedRange.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ReservedRange message.
                     * @function verify
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ReservedRange.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.start != null && message.hasOwnProperty("start"))
                            if (!$util.isInteger(message.start))
                                return "start: integer expected";
                        if (message.end != null && message.hasOwnProperty("end"))
                            if (!$util.isInteger(message.end))
                                return "end: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ReservedRange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                     */
                    ReservedRange.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.DescriptorProto.ReservedRange)
                            return object;
                        var message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                        if (object.start != null)
                            message.start = object.start | 0;
                        if (object.end != null)
                            message.end = object.end | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ReservedRange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.ReservedRange} message ReservedRange
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ReservedRange.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.start = 0;
                            object.end = 0;
                        }
                        if (message.start != null && message.hasOwnProperty("start"))
                            object.start = message.start;
                        if (message.end != null && message.hasOwnProperty("end"))
                            object.end = message.end;
                        return object;
                    };
    
                    /**
                     * Converts this ReservedRange to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ReservedRange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ReservedRange;
                })();
    
                return DescriptorProto;
            })();
    
            protobuf.FieldDescriptorProto = (function() {
    
                /**
                 * Properties of a FieldDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IFieldDescriptorProto
                 * @property {string|null} [name] FieldDescriptorProto name
                 * @property {number|null} [number] FieldDescriptorProto number
                 * @property {google.protobuf.FieldDescriptorProto.Label|null} [label] FieldDescriptorProto label
                 * @property {google.protobuf.FieldDescriptorProto.Type|null} [type] FieldDescriptorProto type
                 * @property {string|null} [typeName] FieldDescriptorProto typeName
                 * @property {string|null} [extendee] FieldDescriptorProto extendee
                 * @property {string|null} [defaultValue] FieldDescriptorProto defaultValue
                 * @property {number|null} [oneofIndex] FieldDescriptorProto oneofIndex
                 * @property {string|null} [jsonName] FieldDescriptorProto jsonName
                 * @property {google.protobuf.IFieldOptions|null} [options] FieldDescriptorProto options
                 */
    
                /**
                 * Constructs a new FieldDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a FieldDescriptorProto.
                 * @implements IFieldDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
                 */
                function FieldDescriptorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FieldDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.name = "";
    
                /**
                 * FieldDescriptorProto number.
                 * @member {number} number
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.number = 0;
    
                /**
                 * FieldDescriptorProto label.
                 * @member {google.protobuf.FieldDescriptorProto.Label} label
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.label = 1;
    
                /**
                 * FieldDescriptorProto type.
                 * @member {google.protobuf.FieldDescriptorProto.Type} type
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.type = 1;
    
                /**
                 * FieldDescriptorProto typeName.
                 * @member {string} typeName
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.typeName = "";
    
                /**
                 * FieldDescriptorProto extendee.
                 * @member {string} extendee
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.extendee = "";
    
                /**
                 * FieldDescriptorProto defaultValue.
                 * @member {string} defaultValue
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.defaultValue = "";
    
                /**
                 * FieldDescriptorProto oneofIndex.
                 * @member {number} oneofIndex
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.oneofIndex = 0;
    
                /**
                 * FieldDescriptorProto jsonName.
                 * @member {string} jsonName
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.jsonName = "";
    
                /**
                 * FieldDescriptorProto options.
                 * @member {google.protobuf.IFieldOptions|null|undefined} options
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new FieldDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto instance
                 */
                FieldDescriptorProto.create = function create(properties) {
                    return new FieldDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified FieldDescriptorProto message. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.extendee != null && Object.hasOwnProperty.call(message, "extendee"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.extendee);
                    if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.number);
                    if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.label);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                    if (message.typeName != null && Object.hasOwnProperty.call(message, "typeName"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.typeName);
                    if (message.defaultValue != null && Object.hasOwnProperty.call(message, "defaultValue"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.defaultValue);
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.FieldOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.oneofIndex != null && Object.hasOwnProperty.call(message, "oneofIndex"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.oneofIndex);
                    if (message.jsonName != null && Object.hasOwnProperty.call(message, "jsonName"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.jsonName);
                    return writer;
                };
    
                /**
                 * Encodes the specified FieldDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FieldDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 3:
                            message.number = reader.int32();
                            break;
                        case 4:
                            message.label = reader.int32();
                            break;
                        case 5:
                            message.type = reader.int32();
                            break;
                        case 6:
                            message.typeName = reader.string();
                            break;
                        case 2:
                            message.extendee = reader.string();
                            break;
                        case 7:
                            message.defaultValue = reader.string();
                            break;
                        case 9:
                            message.oneofIndex = reader.int32();
                            break;
                        case 10:
                            message.jsonName = reader.string();
                            break;
                        case 8:
                            message.options = $root.google.protobuf.FieldOptions.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FieldDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FieldDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FieldDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.number != null && message.hasOwnProperty("number"))
                        if (!$util.isInteger(message.number))
                            return "number: integer expected";
                    if (message.label != null && message.hasOwnProperty("label"))
                        switch (message.label) {
                        default:
                            return "label: enum value expected";
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                            break;
                        }
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.extendee != null && message.hasOwnProperty("extendee"))
                        if (!$util.isString(message.extendee))
                            return "extendee: string expected";
                    if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                        if (!$util.isString(message.defaultValue))
                            return "defaultValue: string expected";
                    if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                        if (!$util.isInteger(message.oneofIndex))
                            return "oneofIndex: integer expected";
                    if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                        if (!$util.isString(message.jsonName))
                            return "jsonName: string expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.FieldOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a FieldDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
                 */
                FieldDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FieldDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.FieldDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.number != null)
                        message.number = object.number | 0;
                    switch (object.label) {
                    case "LABEL_OPTIONAL":
                    case 1:
                        message.label = 1;
                        break;
                    case "LABEL_REQUIRED":
                    case 2:
                        message.label = 2;
                        break;
                    case "LABEL_REPEATED":
                    case 3:
                        message.label = 3;
                        break;
                    }
                    switch (object.type) {
                    case "TYPE_DOUBLE":
                    case 1:
                        message.type = 1;
                        break;
                    case "TYPE_FLOAT":
                    case 2:
                        message.type = 2;
                        break;
                    case "TYPE_INT64":
                    case 3:
                        message.type = 3;
                        break;
                    case "TYPE_UINT64":
                    case 4:
                        message.type = 4;
                        break;
                    case "TYPE_INT32":
                    case 5:
                        message.type = 5;
                        break;
                    case "TYPE_FIXED64":
                    case 6:
                        message.type = 6;
                        break;
                    case "TYPE_FIXED32":
                    case 7:
                        message.type = 7;
                        break;
                    case "TYPE_BOOL":
                    case 8:
                        message.type = 8;
                        break;
                    case "TYPE_STRING":
                    case 9:
                        message.type = 9;
                        break;
                    case "TYPE_GROUP":
                    case 10:
                        message.type = 10;
                        break;
                    case "TYPE_MESSAGE":
                    case 11:
                        message.type = 11;
                        break;
                    case "TYPE_BYTES":
                    case 12:
                        message.type = 12;
                        break;
                    case "TYPE_UINT32":
                    case 13:
                        message.type = 13;
                        break;
                    case "TYPE_ENUM":
                    case 14:
                        message.type = 14;
                        break;
                    case "TYPE_SFIXED32":
                    case 15:
                        message.type = 15;
                        break;
                    case "TYPE_SFIXED64":
                    case 16:
                        message.type = 16;
                        break;
                    case "TYPE_SINT32":
                    case 17:
                        message.type = 17;
                        break;
                    case "TYPE_SINT64":
                    case 18:
                        message.type = 18;
                        break;
                    }
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.extendee != null)
                        message.extendee = String(object.extendee);
                    if (object.defaultValue != null)
                        message.defaultValue = String(object.defaultValue);
                    if (object.oneofIndex != null)
                        message.oneofIndex = object.oneofIndex | 0;
                    if (object.jsonName != null)
                        message.jsonName = String(object.jsonName);
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.FieldDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.FieldOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FieldDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {google.protobuf.FieldDescriptorProto} message FieldDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FieldDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.extendee = "";
                        object.number = 0;
                        object.label = options.enums === String ? "LABEL_OPTIONAL" : 1;
                        object.type = options.enums === String ? "TYPE_DOUBLE" : 1;
                        object.typeName = "";
                        object.defaultValue = "";
                        object.options = null;
                        object.oneofIndex = 0;
                        object.jsonName = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.extendee != null && message.hasOwnProperty("extendee"))
                        object.extendee = message.extendee;
                    if (message.number != null && message.hasOwnProperty("number"))
                        object.number = message.number;
                    if (message.label != null && message.hasOwnProperty("label"))
                        object.label = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Label[message.label] : message.label;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Type[message.type] : message.type;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                        object.defaultValue = message.defaultValue;
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.FieldOptions.toObject(message.options, options);
                    if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                        object.oneofIndex = message.oneofIndex;
                    if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                        object.jsonName = message.jsonName;
                    return object;
                };
    
                /**
                 * Converts this FieldDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FieldDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Type enum.
                 * @name google.protobuf.FieldDescriptorProto.Type
                 * @enum {number}
                 * @property {number} TYPE_DOUBLE=1 TYPE_DOUBLE value
                 * @property {number} TYPE_FLOAT=2 TYPE_FLOAT value
                 * @property {number} TYPE_INT64=3 TYPE_INT64 value
                 * @property {number} TYPE_UINT64=4 TYPE_UINT64 value
                 * @property {number} TYPE_INT32=5 TYPE_INT32 value
                 * @property {number} TYPE_FIXED64=6 TYPE_FIXED64 value
                 * @property {number} TYPE_FIXED32=7 TYPE_FIXED32 value
                 * @property {number} TYPE_BOOL=8 TYPE_BOOL value
                 * @property {number} TYPE_STRING=9 TYPE_STRING value
                 * @property {number} TYPE_GROUP=10 TYPE_GROUP value
                 * @property {number} TYPE_MESSAGE=11 TYPE_MESSAGE value
                 * @property {number} TYPE_BYTES=12 TYPE_BYTES value
                 * @property {number} TYPE_UINT32=13 TYPE_UINT32 value
                 * @property {number} TYPE_ENUM=14 TYPE_ENUM value
                 * @property {number} TYPE_SFIXED32=15 TYPE_SFIXED32 value
                 * @property {number} TYPE_SFIXED64=16 TYPE_SFIXED64 value
                 * @property {number} TYPE_SINT32=17 TYPE_SINT32 value
                 * @property {number} TYPE_SINT64=18 TYPE_SINT64 value
                 */
                FieldDescriptorProto.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[1] = "TYPE_DOUBLE"] = 1;
                    values[valuesById[2] = "TYPE_FLOAT"] = 2;
                    values[valuesById[3] = "TYPE_INT64"] = 3;
                    values[valuesById[4] = "TYPE_UINT64"] = 4;
                    values[valuesById[5] = "TYPE_INT32"] = 5;
                    values[valuesById[6] = "TYPE_FIXED64"] = 6;
                    values[valuesById[7] = "TYPE_FIXED32"] = 7;
                    values[valuesById[8] = "TYPE_BOOL"] = 8;
                    values[valuesById[9] = "TYPE_STRING"] = 9;
                    values[valuesById[10] = "TYPE_GROUP"] = 10;
                    values[valuesById[11] = "TYPE_MESSAGE"] = 11;
                    values[valuesById[12] = "TYPE_BYTES"] = 12;
                    values[valuesById[13] = "TYPE_UINT32"] = 13;
                    values[valuesById[14] = "TYPE_ENUM"] = 14;
                    values[valuesById[15] = "TYPE_SFIXED32"] = 15;
                    values[valuesById[16] = "TYPE_SFIXED64"] = 16;
                    values[valuesById[17] = "TYPE_SINT32"] = 17;
                    values[valuesById[18] = "TYPE_SINT64"] = 18;
                    return values;
                })();
    
                /**
                 * Label enum.
                 * @name google.protobuf.FieldDescriptorProto.Label
                 * @enum {number}
                 * @property {number} LABEL_OPTIONAL=1 LABEL_OPTIONAL value
                 * @property {number} LABEL_REQUIRED=2 LABEL_REQUIRED value
                 * @property {number} LABEL_REPEATED=3 LABEL_REPEATED value
                 */
                FieldDescriptorProto.Label = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[1] = "LABEL_OPTIONAL"] = 1;
                    values[valuesById[2] = "LABEL_REQUIRED"] = 2;
                    values[valuesById[3] = "LABEL_REPEATED"] = 3;
                    return values;
                })();
    
                return FieldDescriptorProto;
            })();
    
            protobuf.OneofDescriptorProto = (function() {
    
                /**
                 * Properties of an OneofDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IOneofDescriptorProto
                 * @property {string|null} [name] OneofDescriptorProto name
                 * @property {google.protobuf.IOneofOptions|null} [options] OneofDescriptorProto options
                 */
    
                /**
                 * Constructs a new OneofDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents an OneofDescriptorProto.
                 * @implements IOneofDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
                 */
                function OneofDescriptorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OneofDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @instance
                 */
                OneofDescriptorProto.prototype.name = "";
    
                /**
                 * OneofDescriptorProto options.
                 * @member {google.protobuf.IOneofOptions|null|undefined} options
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @instance
                 */
                OneofDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new OneofDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto instance
                 */
                OneofDescriptorProto.create = function create(properties) {
                    return new OneofDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified OneofDescriptorProto message. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OneofDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.OneofOptions.encode(message.options, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified OneofDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OneofDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OneofDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OneofDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            message.options = $root.google.protobuf.OneofOptions.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OneofDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OneofDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OneofDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OneofDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.OneofOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an OneofDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
                 */
                OneofDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.OneofDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.OneofDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.OneofDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.OneofOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OneofDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {google.protobuf.OneofDescriptorProto} message OneofDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OneofDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.OneofOptions.toObject(message.options, options);
                    return object;
                };
    
                /**
                 * Converts this OneofDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OneofDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return OneofDescriptorProto;
            })();
    
            protobuf.EnumDescriptorProto = (function() {
    
                /**
                 * Properties of an EnumDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IEnumDescriptorProto
                 * @property {string|null} [name] EnumDescriptorProto name
                 * @property {Array.<google.protobuf.IEnumValueDescriptorProto>|null} [value] EnumDescriptorProto value
                 * @property {google.protobuf.IEnumOptions|null} [options] EnumDescriptorProto options
                 */
    
                /**
                 * Constructs a new EnumDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents an EnumDescriptorProto.
                 * @implements IEnumDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
                 */
                function EnumDescriptorProto(properties) {
                    this.value = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EnumDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 */
                EnumDescriptorProto.prototype.name = "";
    
                /**
                 * EnumDescriptorProto value.
                 * @member {Array.<google.protobuf.IEnumValueDescriptorProto>} value
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 */
                EnumDescriptorProto.prototype.value = $util.emptyArray;
    
                /**
                 * EnumDescriptorProto options.
                 * @member {google.protobuf.IEnumOptions|null|undefined} options
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 */
                EnumDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new EnumDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto instance
                 */
                EnumDescriptorProto.create = function create(properties) {
                    return new EnumDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified EnumDescriptorProto message. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.value != null && message.value.length)
                        for (var i = 0; i < message.value.length; ++i)
                            $root.google.protobuf.EnumValueDescriptorProto.encode(message.value[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.EnumOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified EnumDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnumDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            if (!(message.value && message.value.length))
                                message.value = [];
                            message.value.push($root.google.protobuf.EnumValueDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 3:
                            message.options = $root.google.protobuf.EnumOptions.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnumDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnumDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnumDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.value != null && message.hasOwnProperty("value")) {
                        if (!Array.isArray(message.value))
                            return "value: array expected";
                        for (var i = 0; i < message.value.length; ++i) {
                            var error = $root.google.protobuf.EnumValueDescriptorProto.verify(message.value[i]);
                            if (error)
                                return "value." + error;
                        }
                    }
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.EnumOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an EnumDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
                 */
                EnumDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.EnumDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.EnumDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.value) {
                        if (!Array.isArray(object.value))
                            throw TypeError(".google.protobuf.EnumDescriptorProto.value: array expected");
                        message.value = [];
                        for (var i = 0; i < object.value.length; ++i) {
                            if (typeof object.value[i] !== "object")
                                throw TypeError(".google.protobuf.EnumDescriptorProto.value: object expected");
                            message.value[i] = $root.google.protobuf.EnumValueDescriptorProto.fromObject(object.value[i]);
                        }
                    }
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.EnumDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.EnumOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EnumDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {google.protobuf.EnumDescriptorProto} message EnumDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnumDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.value = [];
                    if (options.defaults) {
                        object.name = "";
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.value && message.value.length) {
                        object.value = [];
                        for (var j = 0; j < message.value.length; ++j)
                            object.value[j] = $root.google.protobuf.EnumValueDescriptorProto.toObject(message.value[j], options);
                    }
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.EnumOptions.toObject(message.options, options);
                    return object;
                };
    
                /**
                 * Converts this EnumDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnumDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return EnumDescriptorProto;
            })();
    
            protobuf.EnumValueDescriptorProto = (function() {
    
                /**
                 * Properties of an EnumValueDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IEnumValueDescriptorProto
                 * @property {string|null} [name] EnumValueDescriptorProto name
                 * @property {number|null} [number] EnumValueDescriptorProto number
                 * @property {google.protobuf.IEnumValueOptions|null} [options] EnumValueDescriptorProto options
                 */
    
                /**
                 * Constructs a new EnumValueDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents an EnumValueDescriptorProto.
                 * @implements IEnumValueDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
                 */
                function EnumValueDescriptorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EnumValueDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @instance
                 */
                EnumValueDescriptorProto.prototype.name = "";
    
                /**
                 * EnumValueDescriptorProto number.
                 * @member {number} number
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @instance
                 */
                EnumValueDescriptorProto.prototype.number = 0;
    
                /**
                 * EnumValueDescriptorProto options.
                 * @member {google.protobuf.IEnumValueOptions|null|undefined} options
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @instance
                 */
                EnumValueDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new EnumValueDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto instance
                 */
                EnumValueDescriptorProto.create = function create(properties) {
                    return new EnumValueDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified EnumValueDescriptorProto message. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumValueDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.number);
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.EnumValueOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified EnumValueDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumValueDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnumValueDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumValueDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            message.number = reader.int32();
                            break;
                        case 3:
                            message.options = $root.google.protobuf.EnumValueOptions.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnumValueDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumValueDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnumValueDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnumValueDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.number != null && message.hasOwnProperty("number"))
                        if (!$util.isInteger(message.number))
                            return "number: integer expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.EnumValueOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an EnumValueDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
                 */
                EnumValueDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.EnumValueDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.EnumValueDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.number != null)
                        message.number = object.number | 0;
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.EnumValueDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.EnumValueOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EnumValueDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {google.protobuf.EnumValueDescriptorProto} message EnumValueDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnumValueDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.number = 0;
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.number != null && message.hasOwnProperty("number"))
                        object.number = message.number;
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.EnumValueOptions.toObject(message.options, options);
                    return object;
                };
    
                /**
                 * Converts this EnumValueDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnumValueDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return EnumValueDescriptorProto;
            })();
    
            protobuf.ServiceDescriptorProto = (function() {
    
                /**
                 * Properties of a ServiceDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IServiceDescriptorProto
                 * @property {string|null} [name] ServiceDescriptorProto name
                 * @property {Array.<google.protobuf.IMethodDescriptorProto>|null} [method] ServiceDescriptorProto method
                 * @property {google.protobuf.IServiceOptions|null} [options] ServiceDescriptorProto options
                 */
    
                /**
                 * Constructs a new ServiceDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a ServiceDescriptorProto.
                 * @implements IServiceDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
                 */
                function ServiceDescriptorProto(properties) {
                    this.method = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ServiceDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @instance
                 */
                ServiceDescriptorProto.prototype.name = "";
    
                /**
                 * ServiceDescriptorProto method.
                 * @member {Array.<google.protobuf.IMethodDescriptorProto>} method
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @instance
                 */
                ServiceDescriptorProto.prototype.method = $util.emptyArray;
    
                /**
                 * ServiceDescriptorProto options.
                 * @member {google.protobuf.IServiceOptions|null|undefined} options
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @instance
                 */
                ServiceDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new ServiceDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto instance
                 */
                ServiceDescriptorProto.create = function create(properties) {
                    return new ServiceDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified ServiceDescriptorProto message. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.method != null && message.method.length)
                        for (var i = 0; i < message.method.length; ++i)
                            $root.google.protobuf.MethodDescriptorProto.encode(message.method[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.ServiceOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ServiceDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ServiceDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            if (!(message.method && message.method.length))
                                message.method = [];
                            message.method.push($root.google.protobuf.MethodDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 3:
                            message.options = $root.google.protobuf.ServiceOptions.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ServiceDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ServiceDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.method != null && message.hasOwnProperty("method")) {
                        if (!Array.isArray(message.method))
                            return "method: array expected";
                        for (var i = 0; i < message.method.length; ++i) {
                            var error = $root.google.protobuf.MethodDescriptorProto.verify(message.method[i]);
                            if (error)
                                return "method." + error;
                        }
                    }
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.ServiceOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a ServiceDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
                 */
                ServiceDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.ServiceDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.ServiceDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.method) {
                        if (!Array.isArray(object.method))
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.method: array expected");
                        message.method = [];
                        for (var i = 0; i < object.method.length; ++i) {
                            if (typeof object.method[i] !== "object")
                                throw TypeError(".google.protobuf.ServiceDescriptorProto.method: object expected");
                            message.method[i] = $root.google.protobuf.MethodDescriptorProto.fromObject(object.method[i]);
                        }
                    }
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.ServiceOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ServiceDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {google.protobuf.ServiceDescriptorProto} message ServiceDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.method = [];
                    if (options.defaults) {
                        object.name = "";
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.method && message.method.length) {
                        object.method = [];
                        for (var j = 0; j < message.method.length; ++j)
                            object.method[j] = $root.google.protobuf.MethodDescriptorProto.toObject(message.method[j], options);
                    }
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.ServiceOptions.toObject(message.options, options);
                    return object;
                };
    
                /**
                 * Converts this ServiceDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return ServiceDescriptorProto;
            })();
    
            protobuf.MethodDescriptorProto = (function() {
    
                /**
                 * Properties of a MethodDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IMethodDescriptorProto
                 * @property {string|null} [name] MethodDescriptorProto name
                 * @property {string|null} [inputType] MethodDescriptorProto inputType
                 * @property {string|null} [outputType] MethodDescriptorProto outputType
                 * @property {google.protobuf.IMethodOptions|null} [options] MethodDescriptorProto options
                 * @property {boolean|null} [clientStreaming] MethodDescriptorProto clientStreaming
                 * @property {boolean|null} [serverStreaming] MethodDescriptorProto serverStreaming
                 */
    
                /**
                 * Constructs a new MethodDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a MethodDescriptorProto.
                 * @implements IMethodDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
                 */
                function MethodDescriptorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MethodDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.name = "";
    
                /**
                 * MethodDescriptorProto inputType.
                 * @member {string} inputType
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.inputType = "";
    
                /**
                 * MethodDescriptorProto outputType.
                 * @member {string} outputType
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.outputType = "";
    
                /**
                 * MethodDescriptorProto options.
                 * @member {google.protobuf.IMethodOptions|null|undefined} options
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.options = null;
    
                /**
                 * MethodDescriptorProto clientStreaming.
                 * @member {boolean} clientStreaming
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.clientStreaming = false;
    
                /**
                 * MethodDescriptorProto serverStreaming.
                 * @member {boolean} serverStreaming
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.serverStreaming = false;
    
                /**
                 * Creates a new MethodDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto instance
                 */
                MethodDescriptorProto.create = function create(properties) {
                    return new MethodDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified MethodDescriptorProto message. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MethodDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.inputType != null && Object.hasOwnProperty.call(message, "inputType"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.inputType);
                    if (message.outputType != null && Object.hasOwnProperty.call(message, "outputType"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.outputType);
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.MethodOptions.encode(message.options, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.clientStreaming != null && Object.hasOwnProperty.call(message, "clientStreaming"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.clientStreaming);
                    if (message.serverStreaming != null && Object.hasOwnProperty.call(message, "serverStreaming"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.serverStreaming);
                    return writer;
                };
    
                /**
                 * Encodes the specified MethodDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MethodDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MethodDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MethodDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            message.inputType = reader.string();
                            break;
                        case 3:
                            message.outputType = reader.string();
                            break;
                        case 4:
                            message.options = $root.google.protobuf.MethodOptions.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.clientStreaming = reader.bool();
                            break;
                        case 6:
                            message.serverStreaming = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MethodDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MethodDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MethodDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MethodDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.inputType != null && message.hasOwnProperty("inputType"))
                        if (!$util.isString(message.inputType))
                            return "inputType: string expected";
                    if (message.outputType != null && message.hasOwnProperty("outputType"))
                        if (!$util.isString(message.outputType))
                            return "outputType: string expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.MethodOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                        if (typeof message.clientStreaming !== "boolean")
                            return "clientStreaming: boolean expected";
                    if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                        if (typeof message.serverStreaming !== "boolean")
                            return "serverStreaming: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a MethodDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
                 */
                MethodDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.MethodDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.MethodDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.inputType != null)
                        message.inputType = String(object.inputType);
                    if (object.outputType != null)
                        message.outputType = String(object.outputType);
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.MethodDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.MethodOptions.fromObject(object.options);
                    }
                    if (object.clientStreaming != null)
                        message.clientStreaming = Boolean(object.clientStreaming);
                    if (object.serverStreaming != null)
                        message.serverStreaming = Boolean(object.serverStreaming);
                    return message;
                };
    
                /**
                 * Creates a plain object from a MethodDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {google.protobuf.MethodDescriptorProto} message MethodDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MethodDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.inputType = "";
                        object.outputType = "";
                        object.options = null;
                        object.clientStreaming = false;
                        object.serverStreaming = false;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.inputType != null && message.hasOwnProperty("inputType"))
                        object.inputType = message.inputType;
                    if (message.outputType != null && message.hasOwnProperty("outputType"))
                        object.outputType = message.outputType;
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.MethodOptions.toObject(message.options, options);
                    if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                        object.clientStreaming = message.clientStreaming;
                    if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                        object.serverStreaming = message.serverStreaming;
                    return object;
                };
    
                /**
                 * Converts this MethodDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MethodDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return MethodDescriptorProto;
            })();
    
            protobuf.FileOptions = (function() {
    
                /**
                 * Properties of a FileOptions.
                 * @memberof google.protobuf
                 * @interface IFileOptions
                 * @property {string|null} [javaPackage] FileOptions javaPackage
                 * @property {string|null} [javaOuterClassname] FileOptions javaOuterClassname
                 * @property {boolean|null} [javaMultipleFiles] FileOptions javaMultipleFiles
                 * @property {boolean|null} [javaGenerateEqualsAndHash] FileOptions javaGenerateEqualsAndHash
                 * @property {boolean|null} [javaStringCheckUtf8] FileOptions javaStringCheckUtf8
                 * @property {google.protobuf.FileOptions.OptimizeMode|null} [optimizeFor] FileOptions optimizeFor
                 * @property {string|null} [goPackage] FileOptions goPackage
                 * @property {boolean|null} [ccGenericServices] FileOptions ccGenericServices
                 * @property {boolean|null} [javaGenericServices] FileOptions javaGenericServices
                 * @property {boolean|null} [pyGenericServices] FileOptions pyGenericServices
                 * @property {boolean|null} [deprecated] FileOptions deprecated
                 * @property {boolean|null} [ccEnableArenas] FileOptions ccEnableArenas
                 * @property {string|null} [objcClassPrefix] FileOptions objcClassPrefix
                 * @property {string|null} [csharpNamespace] FileOptions csharpNamespace
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FileOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new FileOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a FileOptions.
                 * @implements IFileOptions
                 * @constructor
                 * @param {google.protobuf.IFileOptions=} [properties] Properties to set
                 */
                function FileOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FileOptions javaPackage.
                 * @member {string} javaPackage
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaPackage = "";
    
                /**
                 * FileOptions javaOuterClassname.
                 * @member {string} javaOuterClassname
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaOuterClassname = "";
    
                /**
                 * FileOptions javaMultipleFiles.
                 * @member {boolean} javaMultipleFiles
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaMultipleFiles = false;
    
                /**
                 * FileOptions javaGenerateEqualsAndHash.
                 * @member {boolean} javaGenerateEqualsAndHash
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaGenerateEqualsAndHash = false;
    
                /**
                 * FileOptions javaStringCheckUtf8.
                 * @member {boolean} javaStringCheckUtf8
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaStringCheckUtf8 = false;
    
                /**
                 * FileOptions optimizeFor.
                 * @member {google.protobuf.FileOptions.OptimizeMode} optimizeFor
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.optimizeFor = 1;
    
                /**
                 * FileOptions goPackage.
                 * @member {string} goPackage
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.goPackage = "";
    
                /**
                 * FileOptions ccGenericServices.
                 * @member {boolean} ccGenericServices
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.ccGenericServices = false;
    
                /**
                 * FileOptions javaGenericServices.
                 * @member {boolean} javaGenericServices
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaGenericServices = false;
    
                /**
                 * FileOptions pyGenericServices.
                 * @member {boolean} pyGenericServices
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.pyGenericServices = false;
    
                /**
                 * FileOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.deprecated = false;
    
                /**
                 * FileOptions ccEnableArenas.
                 * @member {boolean} ccEnableArenas
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.ccEnableArenas = false;
    
                /**
                 * FileOptions objcClassPrefix.
                 * @member {string} objcClassPrefix
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.objcClassPrefix = "";
    
                /**
                 * FileOptions csharpNamespace.
                 * @member {string} csharpNamespace
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.csharpNamespace = "";
    
                /**
                 * FileOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new FileOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {google.protobuf.IFileOptions=} [properties] Properties to set
                 * @returns {google.protobuf.FileOptions} FileOptions instance
                 */
                FileOptions.create = function create(properties) {
                    return new FileOptions(properties);
                };
    
                /**
                 * Encodes the specified FileOptions message. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.javaPackage != null && Object.hasOwnProperty.call(message, "javaPackage"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.javaPackage);
                    if (message.javaOuterClassname != null && Object.hasOwnProperty.call(message, "javaOuterClassname"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.javaOuterClassname);
                    if (message.optimizeFor != null && Object.hasOwnProperty.call(message, "optimizeFor"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.optimizeFor);
                    if (message.javaMultipleFiles != null && Object.hasOwnProperty.call(message, "javaMultipleFiles"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.javaMultipleFiles);
                    if (message.goPackage != null && Object.hasOwnProperty.call(message, "goPackage"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.goPackage);
                    if (message.ccGenericServices != null && Object.hasOwnProperty.call(message, "ccGenericServices"))
                        writer.uint32(/* id 16, wireType 0 =*/128).bool(message.ccGenericServices);
                    if (message.javaGenericServices != null && Object.hasOwnProperty.call(message, "javaGenericServices"))
                        writer.uint32(/* id 17, wireType 0 =*/136).bool(message.javaGenericServices);
                    if (message.pyGenericServices != null && Object.hasOwnProperty.call(message, "pyGenericServices"))
                        writer.uint32(/* id 18, wireType 0 =*/144).bool(message.pyGenericServices);
                    if (message.javaGenerateEqualsAndHash != null && Object.hasOwnProperty.call(message, "javaGenerateEqualsAndHash"))
                        writer.uint32(/* id 20, wireType 0 =*/160).bool(message.javaGenerateEqualsAndHash);
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 23, wireType 0 =*/184).bool(message.deprecated);
                    if (message.javaStringCheckUtf8 != null && Object.hasOwnProperty.call(message, "javaStringCheckUtf8"))
                        writer.uint32(/* id 27, wireType 0 =*/216).bool(message.javaStringCheckUtf8);
                    if (message.ccEnableArenas != null && Object.hasOwnProperty.call(message, "ccEnableArenas"))
                        writer.uint32(/* id 31, wireType 0 =*/248).bool(message.ccEnableArenas);
                    if (message.objcClassPrefix != null && Object.hasOwnProperty.call(message, "objcClassPrefix"))
                        writer.uint32(/* id 36, wireType 2 =*/290).string(message.objcClassPrefix);
                    if (message.csharpNamespace != null && Object.hasOwnProperty.call(message, "csharpNamespace"))
                        writer.uint32(/* id 37, wireType 2 =*/298).string(message.csharpNamespace);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FileOptions message, length delimited. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FileOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FileOptions} FileOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.javaPackage = reader.string();
                            break;
                        case 8:
                            message.javaOuterClassname = reader.string();
                            break;
                        case 10:
                            message.javaMultipleFiles = reader.bool();
                            break;
                        case 20:
                            message.javaGenerateEqualsAndHash = reader.bool();
                            break;
                        case 27:
                            message.javaStringCheckUtf8 = reader.bool();
                            break;
                        case 9:
                            message.optimizeFor = reader.int32();
                            break;
                        case 11:
                            message.goPackage = reader.string();
                            break;
                        case 16:
                            message.ccGenericServices = reader.bool();
                            break;
                        case 17:
                            message.javaGenericServices = reader.bool();
                            break;
                        case 18:
                            message.pyGenericServices = reader.bool();
                            break;
                        case 23:
                            message.deprecated = reader.bool();
                            break;
                        case 31:
                            message.ccEnableArenas = reader.bool();
                            break;
                        case 36:
                            message.objcClassPrefix = reader.string();
                            break;
                        case 37:
                            message.csharpNamespace = reader.string();
                            break;
                        case 999:
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FileOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FileOptions} FileOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FileOptions message.
                 * @function verify
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FileOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                        if (!$util.isString(message.javaPackage))
                            return "javaPackage: string expected";
                    if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                        if (!$util.isString(message.javaOuterClassname))
                            return "javaOuterClassname: string expected";
                    if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                        if (typeof message.javaMultipleFiles !== "boolean")
                            return "javaMultipleFiles: boolean expected";
                    if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                        if (typeof message.javaGenerateEqualsAndHash !== "boolean")
                            return "javaGenerateEqualsAndHash: boolean expected";
                    if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                        if (typeof message.javaStringCheckUtf8 !== "boolean")
                            return "javaStringCheckUtf8: boolean expected";
                    if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                        switch (message.optimizeFor) {
                        default:
                            return "optimizeFor: enum value expected";
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                        if (!$util.isString(message.goPackage))
                            return "goPackage: string expected";
                    if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                        if (typeof message.ccGenericServices !== "boolean")
                            return "ccGenericServices: boolean expected";
                    if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                        if (typeof message.javaGenericServices !== "boolean")
                            return "javaGenericServices: boolean expected";
                    if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                        if (typeof message.pyGenericServices !== "boolean")
                            return "pyGenericServices: boolean expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                        if (typeof message.ccEnableArenas !== "boolean")
                            return "ccEnableArenas: boolean expected";
                    if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                        if (!$util.isString(message.objcClassPrefix))
                            return "objcClassPrefix: string expected";
                    if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                        if (!$util.isString(message.csharpNamespace))
                            return "csharpNamespace: string expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a FileOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FileOptions} FileOptions
                 */
                FileOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FileOptions)
                        return object;
                    var message = new $root.google.protobuf.FileOptions();
                    if (object.javaPackage != null)
                        message.javaPackage = String(object.javaPackage);
                    if (object.javaOuterClassname != null)
                        message.javaOuterClassname = String(object.javaOuterClassname);
                    if (object.javaMultipleFiles != null)
                        message.javaMultipleFiles = Boolean(object.javaMultipleFiles);
                    if (object.javaGenerateEqualsAndHash != null)
                        message.javaGenerateEqualsAndHash = Boolean(object.javaGenerateEqualsAndHash);
                    if (object.javaStringCheckUtf8 != null)
                        message.javaStringCheckUtf8 = Boolean(object.javaStringCheckUtf8);
                    switch (object.optimizeFor) {
                    case "SPEED":
                    case 1:
                        message.optimizeFor = 1;
                        break;
                    case "CODE_SIZE":
                    case 2:
                        message.optimizeFor = 2;
                        break;
                    case "LITE_RUNTIME":
                    case 3:
                        message.optimizeFor = 3;
                        break;
                    }
                    if (object.goPackage != null)
                        message.goPackage = String(object.goPackage);
                    if (object.ccGenericServices != null)
                        message.ccGenericServices = Boolean(object.ccGenericServices);
                    if (object.javaGenericServices != null)
                        message.javaGenericServices = Boolean(object.javaGenericServices);
                    if (object.pyGenericServices != null)
                        message.pyGenericServices = Boolean(object.pyGenericServices);
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.ccEnableArenas != null)
                        message.ccEnableArenas = Boolean(object.ccEnableArenas);
                    if (object.objcClassPrefix != null)
                        message.objcClassPrefix = String(object.objcClassPrefix);
                    if (object.csharpNamespace != null)
                        message.csharpNamespace = String(object.csharpNamespace);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FileOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {google.protobuf.FileOptions} message FileOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FileOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.javaPackage = "";
                        object.javaOuterClassname = "";
                        object.optimizeFor = options.enums === String ? "SPEED" : 1;
                        object.javaMultipleFiles = false;
                        object.goPackage = "";
                        object.ccGenericServices = false;
                        object.javaGenericServices = false;
                        object.pyGenericServices = false;
                        object.javaGenerateEqualsAndHash = false;
                        object.deprecated = false;
                        object.javaStringCheckUtf8 = false;
                        object.ccEnableArenas = false;
                        object.objcClassPrefix = "";
                        object.csharpNamespace = "";
                    }
                    if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                        object.javaPackage = message.javaPackage;
                    if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                        object.javaOuterClassname = message.javaOuterClassname;
                    if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                        object.optimizeFor = options.enums === String ? $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor] : message.optimizeFor;
                    if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                        object.javaMultipleFiles = message.javaMultipleFiles;
                    if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                        object.goPackage = message.goPackage;
                    if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                        object.ccGenericServices = message.ccGenericServices;
                    if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                        object.javaGenericServices = message.javaGenericServices;
                    if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                        object.pyGenericServices = message.pyGenericServices;
                    if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                        object.javaGenerateEqualsAndHash = message.javaGenerateEqualsAndHash;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                        object.javaStringCheckUtf8 = message.javaStringCheckUtf8;
                    if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                        object.ccEnableArenas = message.ccEnableArenas;
                    if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                        object.objcClassPrefix = message.objcClassPrefix;
                    if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                        object.csharpNamespace = message.csharpNamespace;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this FileOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FileOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * OptimizeMode enum.
                 * @name google.protobuf.FileOptions.OptimizeMode
                 * @enum {number}
                 * @property {number} SPEED=1 SPEED value
                 * @property {number} CODE_SIZE=2 CODE_SIZE value
                 * @property {number} LITE_RUNTIME=3 LITE_RUNTIME value
                 */
                FileOptions.OptimizeMode = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[1] = "SPEED"] = 1;
                    values[valuesById[2] = "CODE_SIZE"] = 2;
                    values[valuesById[3] = "LITE_RUNTIME"] = 3;
                    return values;
                })();
    
                return FileOptions;
            })();
    
            protobuf.MessageOptions = (function() {
    
                /**
                 * Properties of a MessageOptions.
                 * @memberof google.protobuf
                 * @interface IMessageOptions
                 * @property {boolean|null} [messageSetWireFormat] MessageOptions messageSetWireFormat
                 * @property {boolean|null} [noStandardDescriptorAccessor] MessageOptions noStandardDescriptorAccessor
                 * @property {boolean|null} [deprecated] MessageOptions deprecated
                 * @property {boolean|null} [mapEntry] MessageOptions mapEntry
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MessageOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new MessageOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a MessageOptions.
                 * @implements IMessageOptions
                 * @constructor
                 * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
                 */
                function MessageOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MessageOptions messageSetWireFormat.
                 * @member {boolean} messageSetWireFormat
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.messageSetWireFormat = false;
    
                /**
                 * MessageOptions noStandardDescriptorAccessor.
                 * @member {boolean} noStandardDescriptorAccessor
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.noStandardDescriptorAccessor = false;
    
                /**
                 * MessageOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.deprecated = false;
    
                /**
                 * MessageOptions mapEntry.
                 * @member {boolean} mapEntry
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.mapEntry = false;
    
                /**
                 * MessageOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new MessageOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
                 * @returns {google.protobuf.MessageOptions} MessageOptions instance
                 */
                MessageOptions.create = function create(properties) {
                    return new MessageOptions(properties);
                };
    
                /**
                 * Encodes the specified MessageOptions message. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MessageOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.messageSetWireFormat != null && Object.hasOwnProperty.call(message, "messageSetWireFormat"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.messageSetWireFormat);
                    if (message.noStandardDescriptorAccessor != null && Object.hasOwnProperty.call(message, "noStandardDescriptorAccessor"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.noStandardDescriptorAccessor);
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                    if (message.mapEntry != null && Object.hasOwnProperty.call(message, "mapEntry"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.mapEntry);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified MessageOptions message, length delimited. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MessageOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MessageOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.MessageOptions} MessageOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MessageOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MessageOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.messageSetWireFormat = reader.bool();
                            break;
                        case 2:
                            message.noStandardDescriptorAccessor = reader.bool();
                            break;
                        case 3:
                            message.deprecated = reader.bool();
                            break;
                        case 7:
                            message.mapEntry = reader.bool();
                            break;
                        case 999:
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MessageOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.MessageOptions} MessageOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MessageOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MessageOptions message.
                 * @function verify
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MessageOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                        if (typeof message.messageSetWireFormat !== "boolean")
                            return "messageSetWireFormat: boolean expected";
                    if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                        if (typeof message.noStandardDescriptorAccessor !== "boolean")
                            return "noStandardDescriptorAccessor: boolean expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                        if (typeof message.mapEntry !== "boolean")
                            return "mapEntry: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a MessageOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.MessageOptions} MessageOptions
                 */
                MessageOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.MessageOptions)
                        return object;
                    var message = new $root.google.protobuf.MessageOptions();
                    if (object.messageSetWireFormat != null)
                        message.messageSetWireFormat = Boolean(object.messageSetWireFormat);
                    if (object.noStandardDescriptorAccessor != null)
                        message.noStandardDescriptorAccessor = Boolean(object.noStandardDescriptorAccessor);
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.mapEntry != null)
                        message.mapEntry = Boolean(object.mapEntry);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a MessageOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {google.protobuf.MessageOptions} message MessageOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MessageOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.messageSetWireFormat = false;
                        object.noStandardDescriptorAccessor = false;
                        object.deprecated = false;
                        object.mapEntry = false;
                    }
                    if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                        object.messageSetWireFormat = message.messageSetWireFormat;
                    if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                        object.noStandardDescriptorAccessor = message.noStandardDescriptorAccessor;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                        object.mapEntry = message.mapEntry;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this MessageOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MessageOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return MessageOptions;
            })();
    
            protobuf.FieldOptions = (function() {
    
                /**
                 * Properties of a FieldOptions.
                 * @memberof google.protobuf
                 * @interface IFieldOptions
                 * @property {google.protobuf.FieldOptions.CType|null} [ctype] FieldOptions ctype
                 * @property {boolean|null} [packed] FieldOptions packed
                 * @property {google.protobuf.FieldOptions.JSType|null} [jstype] FieldOptions jstype
                 * @property {boolean|null} [lazy] FieldOptions lazy
                 * @property {boolean|null} [deprecated] FieldOptions deprecated
                 * @property {boolean|null} [weak] FieldOptions weak
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FieldOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new FieldOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a FieldOptions.
                 * @implements IFieldOptions
                 * @constructor
                 * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
                 */
                function FieldOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FieldOptions ctype.
                 * @member {google.protobuf.FieldOptions.CType} ctype
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.ctype = 0;
    
                /**
                 * FieldOptions packed.
                 * @member {boolean} packed
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.packed = false;
    
                /**
                 * FieldOptions jstype.
                 * @member {google.protobuf.FieldOptions.JSType} jstype
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.jstype = 0;
    
                /**
                 * FieldOptions lazy.
                 * @member {boolean} lazy
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.lazy = false;
    
                /**
                 * FieldOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.deprecated = false;
    
                /**
                 * FieldOptions weak.
                 * @member {boolean} weak
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.weak = false;
    
                /**
                 * FieldOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new FieldOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
                 * @returns {google.protobuf.FieldOptions} FieldOptions instance
                 */
                FieldOptions.create = function create(properties) {
                    return new FieldOptions(properties);
                };
    
                /**
                 * Encodes the specified FieldOptions message. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ctype != null && Object.hasOwnProperty.call(message, "ctype"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ctype);
                    if (message.packed != null && Object.hasOwnProperty.call(message, "packed"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.packed);
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                    if (message.lazy != null && Object.hasOwnProperty.call(message, "lazy"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.lazy);
                    if (message.jstype != null && Object.hasOwnProperty.call(message, "jstype"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.jstype);
                    if (message.weak != null && Object.hasOwnProperty.call(message, "weak"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.weak);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FieldOptions message, length delimited. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FieldOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FieldOptions} FieldOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.ctype = reader.int32();
                            break;
                        case 2:
                            message.packed = reader.bool();
                            break;
                        case 6:
                            message.jstype = reader.int32();
                            break;
                        case 5:
                            message.lazy = reader.bool();
                            break;
                        case 3:
                            message.deprecated = reader.bool();
                            break;
                        case 10:
                            message.weak = reader.bool();
                            break;
                        case 999:
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FieldOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FieldOptions} FieldOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FieldOptions message.
                 * @function verify
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FieldOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ctype != null && message.hasOwnProperty("ctype"))
                        switch (message.ctype) {
                        default:
                            return "ctype: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.packed != null && message.hasOwnProperty("packed"))
                        if (typeof message.packed !== "boolean")
                            return "packed: boolean expected";
                    if (message.jstype != null && message.hasOwnProperty("jstype"))
                        switch (message.jstype) {
                        default:
                            return "jstype: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.lazy != null && message.hasOwnProperty("lazy"))
                        if (typeof message.lazy !== "boolean")
                            return "lazy: boolean expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.weak != null && message.hasOwnProperty("weak"))
                        if (typeof message.weak !== "boolean")
                            return "weak: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a FieldOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FieldOptions} FieldOptions
                 */
                FieldOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FieldOptions)
                        return object;
                    var message = new $root.google.protobuf.FieldOptions();
                    switch (object.ctype) {
                    case "STRING":
                    case 0:
                        message.ctype = 0;
                        break;
                    case "CORD":
                    case 1:
                        message.ctype = 1;
                        break;
                    case "STRING_PIECE":
                    case 2:
                        message.ctype = 2;
                        break;
                    }
                    if (object.packed != null)
                        message.packed = Boolean(object.packed);
                    switch (object.jstype) {
                    case "JS_NORMAL":
                    case 0:
                        message.jstype = 0;
                        break;
                    case "JS_STRING":
                    case 1:
                        message.jstype = 1;
                        break;
                    case "JS_NUMBER":
                    case 2:
                        message.jstype = 2;
                        break;
                    }
                    if (object.lazy != null)
                        message.lazy = Boolean(object.lazy);
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.weak != null)
                        message.weak = Boolean(object.weak);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FieldOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {google.protobuf.FieldOptions} message FieldOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FieldOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.ctype = options.enums === String ? "STRING" : 0;
                        object.packed = false;
                        object.deprecated = false;
                        object.lazy = false;
                        object.jstype = options.enums === String ? "JS_NORMAL" : 0;
                        object.weak = false;
                    }
                    if (message.ctype != null && message.hasOwnProperty("ctype"))
                        object.ctype = options.enums === String ? $root.google.protobuf.FieldOptions.CType[message.ctype] : message.ctype;
                    if (message.packed != null && message.hasOwnProperty("packed"))
                        object.packed = message.packed;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.lazy != null && message.hasOwnProperty("lazy"))
                        object.lazy = message.lazy;
                    if (message.jstype != null && message.hasOwnProperty("jstype"))
                        object.jstype = options.enums === String ? $root.google.protobuf.FieldOptions.JSType[message.jstype] : message.jstype;
                    if (message.weak != null && message.hasOwnProperty("weak"))
                        object.weak = message.weak;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this FieldOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FieldOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * CType enum.
                 * @name google.protobuf.FieldOptions.CType
                 * @enum {number}
                 * @property {number} STRING=0 STRING value
                 * @property {number} CORD=1 CORD value
                 * @property {number} STRING_PIECE=2 STRING_PIECE value
                 */
                FieldOptions.CType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STRING"] = 0;
                    values[valuesById[1] = "CORD"] = 1;
                    values[valuesById[2] = "STRING_PIECE"] = 2;
                    return values;
                })();
    
                /**
                 * JSType enum.
                 * @name google.protobuf.FieldOptions.JSType
                 * @enum {number}
                 * @property {number} JS_NORMAL=0 JS_NORMAL value
                 * @property {number} JS_STRING=1 JS_STRING value
                 * @property {number} JS_NUMBER=2 JS_NUMBER value
                 */
                FieldOptions.JSType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "JS_NORMAL"] = 0;
                    values[valuesById[1] = "JS_STRING"] = 1;
                    values[valuesById[2] = "JS_NUMBER"] = 2;
                    return values;
                })();
    
                return FieldOptions;
            })();
    
            protobuf.OneofOptions = (function() {
    
                /**
                 * Properties of an OneofOptions.
                 * @memberof google.protobuf
                 * @interface IOneofOptions
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] OneofOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new OneofOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents an OneofOptions.
                 * @implements IOneofOptions
                 * @constructor
                 * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
                 */
                function OneofOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OneofOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.OneofOptions
                 * @instance
                 */
                OneofOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new OneofOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
                 * @returns {google.protobuf.OneofOptions} OneofOptions instance
                 */
                OneofOptions.create = function create(properties) {
                    return new OneofOptions(properties);
                };
    
                /**
                 * Encodes the specified OneofOptions message. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OneofOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified OneofOptions message, length delimited. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OneofOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OneofOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.OneofOptions} OneofOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OneofOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 999:
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OneofOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.OneofOptions} OneofOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OneofOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OneofOptions message.
                 * @function verify
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OneofOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an OneofOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.OneofOptions} OneofOptions
                 */
                OneofOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.OneofOptions)
                        return object;
                    var message = new $root.google.protobuf.OneofOptions();
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OneofOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {google.protobuf.OneofOptions} message OneofOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OneofOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this OneofOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.OneofOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OneofOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return OneofOptions;
            })();
    
            protobuf.EnumOptions = (function() {
    
                /**
                 * Properties of an EnumOptions.
                 * @memberof google.protobuf
                 * @interface IEnumOptions
                 * @property {boolean|null} [allowAlias] EnumOptions allowAlias
                 * @property {boolean|null} [deprecated] EnumOptions deprecated
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new EnumOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents an EnumOptions.
                 * @implements IEnumOptions
                 * @constructor
                 * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
                 */
                function EnumOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EnumOptions allowAlias.
                 * @member {boolean} allowAlias
                 * @memberof google.protobuf.EnumOptions
                 * @instance
                 */
                EnumOptions.prototype.allowAlias = false;
    
                /**
                 * EnumOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.EnumOptions
                 * @instance
                 */
                EnumOptions.prototype.deprecated = false;
    
                /**
                 * EnumOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.EnumOptions
                 * @instance
                 */
                EnumOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new EnumOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
                 * @returns {google.protobuf.EnumOptions} EnumOptions instance
                 */
                EnumOptions.create = function create(properties) {
                    return new EnumOptions(properties);
                };
    
                /**
                 * Encodes the specified EnumOptions message. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.allowAlias != null && Object.hasOwnProperty.call(message, "allowAlias"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.allowAlias);
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified EnumOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnumOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.EnumOptions} EnumOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2:
                            message.allowAlias = reader.bool();
                            break;
                        case 3:
                            message.deprecated = reader.bool();
                            break;
                        case 999:
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnumOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.EnumOptions} EnumOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnumOptions message.
                 * @function verify
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnumOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                        if (typeof message.allowAlias !== "boolean")
                            return "allowAlias: boolean expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an EnumOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.EnumOptions} EnumOptions
                 */
                EnumOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.EnumOptions)
                        return object;
                    var message = new $root.google.protobuf.EnumOptions();
                    if (object.allowAlias != null)
                        message.allowAlias = Boolean(object.allowAlias);
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EnumOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {google.protobuf.EnumOptions} message EnumOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnumOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.allowAlias = false;
                        object.deprecated = false;
                    }
                    if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                        object.allowAlias = message.allowAlias;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this EnumOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.EnumOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnumOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return EnumOptions;
            })();
    
            protobuf.EnumValueOptions = (function() {
    
                /**
                 * Properties of an EnumValueOptions.
                 * @memberof google.protobuf
                 * @interface IEnumValueOptions
                 * @property {boolean|null} [deprecated] EnumValueOptions deprecated
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumValueOptions uninterpretedOption
                 * @property {Array.<string>|null} [".currencies.country"] EnumValueOptions .currencies.country
                 * @property {Array.<string>|null} [".currencies.symbol"] EnumValueOptions .currencies.symbol
                 * @property {Array.<string>|null} [".currencies.code"] EnumValueOptions .currencies.code
                 * @property {string|null} [".currencies.name"] EnumValueOptions .currencies.name
                 * @property {number|null} [".currencies.num"] EnumValueOptions .currencies.num
                 * @property {number|null} [".currencies.unit"] EnumValueOptions .currencies.unit
                 * @property {boolean|null} [".currencies.crypto"] EnumValueOptions .currencies.crypto
                 * @property {boolean|null} [".currencies.unofficial"] EnumValueOptions .currencies.unofficial
                 * @property {number|null} [".currencies.denom"] EnumValueOptions .currencies.denom
                 * @property {boolean|null} [".currencies.stable"] EnumValueOptions .currencies.stable
                 * @property {boolean|null} [".currencies.testnet"] EnumValueOptions .currencies.testnet
                 */
    
                /**
                 * Constructs a new EnumValueOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents an EnumValueOptions.
                 * @implements IEnumValueOptions
                 * @constructor
                 * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
                 */
                function EnumValueOptions(properties) {
                    this.uninterpretedOption = [];
                    this[".currencies.country"] = [];
                    this[".currencies.symbol"] = [];
                    this[".currencies.code"] = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EnumValueOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype.deprecated = false;
    
                /**
                 * EnumValueOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * EnumValueOptions .currencies.country.
                 * @member {Array.<string>} .currencies.country
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".currencies.country"] = $util.emptyArray;
    
                /**
                 * EnumValueOptions .currencies.symbol.
                 * @member {Array.<string>} .currencies.symbol
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".currencies.symbol"] = $util.emptyArray;
    
                /**
                 * EnumValueOptions .currencies.code.
                 * @member {Array.<string>} .currencies.code
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".currencies.code"] = $util.emptyArray;
    
                /**
                 * EnumValueOptions .currencies.name.
                 * @member {string} .currencies.name
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".currencies.name"] = "";
    
                /**
                 * EnumValueOptions .currencies.num.
                 * @member {number} .currencies.num
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".currencies.num"] = 0;
    
                /**
                 * EnumValueOptions .currencies.unit.
                 * @member {number} .currencies.unit
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".currencies.unit"] = 0;
    
                /**
                 * EnumValueOptions .currencies.crypto.
                 * @member {boolean} .currencies.crypto
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".currencies.crypto"] = false;
    
                /**
                 * EnumValueOptions .currencies.unofficial.
                 * @member {boolean} .currencies.unofficial
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".currencies.unofficial"] = false;
    
                /**
                 * EnumValueOptions .currencies.denom.
                 * @member {number} .currencies.denom
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".currencies.denom"] = 0;
    
                /**
                 * EnumValueOptions .currencies.stable.
                 * @member {boolean} .currencies.stable
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".currencies.stable"] = false;
    
                /**
                 * EnumValueOptions .currencies.testnet.
                 * @member {boolean} .currencies.testnet
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".currencies.testnet"] = false;
    
                /**
                 * Creates a new EnumValueOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
                 * @returns {google.protobuf.EnumValueOptions} EnumValueOptions instance
                 */
                EnumValueOptions.create = function create(properties) {
                    return new EnumValueOptions(properties);
                };
    
                /**
                 * Encodes the specified EnumValueOptions message. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumValueOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.deprecated);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    if (message[".currencies.country"] != null && message[".currencies.country"].length)
                        for (var i = 0; i < message[".currencies.country"].length; ++i)
                            writer.uint32(/* id 50000, wireType 2 =*/400002).string(message[".currencies.country"][i]);
                    if (message[".currencies.symbol"] != null && message[".currencies.symbol"].length)
                        for (var i = 0; i < message[".currencies.symbol"].length; ++i)
                            writer.uint32(/* id 50001, wireType 2 =*/400010).string(message[".currencies.symbol"][i]);
                    if (message[".currencies.code"] != null && message[".currencies.code"].length)
                        for (var i = 0; i < message[".currencies.code"].length; ++i)
                            writer.uint32(/* id 50002, wireType 2 =*/400018).string(message[".currencies.code"][i]);
                    if (message[".currencies.name"] != null && Object.hasOwnProperty.call(message, ".currencies.name"))
                        writer.uint32(/* id 50003, wireType 2 =*/400026).string(message[".currencies.name"]);
                    if (message[".currencies.num"] != null && Object.hasOwnProperty.call(message, ".currencies.num"))
                        writer.uint32(/* id 50004, wireType 0 =*/400032).uint32(message[".currencies.num"]);
                    if (message[".currencies.unit"] != null && Object.hasOwnProperty.call(message, ".currencies.unit"))
                        writer.uint32(/* id 50005, wireType 0 =*/400040).uint32(message[".currencies.unit"]);
                    if (message[".currencies.crypto"] != null && Object.hasOwnProperty.call(message, ".currencies.crypto"))
                        writer.uint32(/* id 50006, wireType 0 =*/400048).bool(message[".currencies.crypto"]);
                    if (message[".currencies.unofficial"] != null && Object.hasOwnProperty.call(message, ".currencies.unofficial"))
                        writer.uint32(/* id 50007, wireType 0 =*/400056).bool(message[".currencies.unofficial"]);
                    if (message[".currencies.denom"] != null && Object.hasOwnProperty.call(message, ".currencies.denom"))
                        writer.uint32(/* id 50008, wireType 0 =*/400064).uint32(message[".currencies.denom"]);
                    if (message[".currencies.stable"] != null && Object.hasOwnProperty.call(message, ".currencies.stable"))
                        writer.uint32(/* id 50009, wireType 0 =*/400072).bool(message[".currencies.stable"]);
                    if (message[".currencies.testnet"] != null && Object.hasOwnProperty.call(message, ".currencies.testnet"))
                        writer.uint32(/* id 50010, wireType 0 =*/400080).bool(message[".currencies.testnet"]);
                    return writer;
                };
    
                /**
                 * Encodes the specified EnumValueOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumValueOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnumValueOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumValueOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.deprecated = reader.bool();
                            break;
                        case 999:
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        case 50000:
                            if (!(message[".currencies.country"] && message[".currencies.country"].length))
                                message[".currencies.country"] = [];
                            message[".currencies.country"].push(reader.string());
                            break;
                        case 50001:
                            if (!(message[".currencies.symbol"] && message[".currencies.symbol"].length))
                                message[".currencies.symbol"] = [];
                            message[".currencies.symbol"].push(reader.string());
                            break;
                        case 50002:
                            if (!(message[".currencies.code"] && message[".currencies.code"].length))
                                message[".currencies.code"] = [];
                            message[".currencies.code"].push(reader.string());
                            break;
                        case 50003:
                            message[".currencies.name"] = reader.string();
                            break;
                        case 50004:
                            message[".currencies.num"] = reader.uint32();
                            break;
                        case 50005:
                            message[".currencies.unit"] = reader.uint32();
                            break;
                        case 50006:
                            message[".currencies.crypto"] = reader.bool();
                            break;
                        case 50007:
                            message[".currencies.unofficial"] = reader.bool();
                            break;
                        case 50008:
                            message[".currencies.denom"] = reader.uint32();
                            break;
                        case 50009:
                            message[".currencies.stable"] = reader.bool();
                            break;
                        case 50010:
                            message[".currencies.testnet"] = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnumValueOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumValueOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnumValueOptions message.
                 * @function verify
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnumValueOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    if (message[".currencies.country"] != null && message.hasOwnProperty(".currencies.country")) {
                        if (!Array.isArray(message[".currencies.country"]))
                            return ".currencies.country: array expected";
                        for (var i = 0; i < message[".currencies.country"].length; ++i)
                            if (!$util.isString(message[".currencies.country"][i]))
                                return ".currencies.country: string[] expected";
                    }
                    if (message[".currencies.symbol"] != null && message.hasOwnProperty(".currencies.symbol")) {
                        if (!Array.isArray(message[".currencies.symbol"]))
                            return ".currencies.symbol: array expected";
                        for (var i = 0; i < message[".currencies.symbol"].length; ++i)
                            if (!$util.isString(message[".currencies.symbol"][i]))
                                return ".currencies.symbol: string[] expected";
                    }
                    if (message[".currencies.code"] != null && message.hasOwnProperty(".currencies.code")) {
                        if (!Array.isArray(message[".currencies.code"]))
                            return ".currencies.code: array expected";
                        for (var i = 0; i < message[".currencies.code"].length; ++i)
                            if (!$util.isString(message[".currencies.code"][i]))
                                return ".currencies.code: string[] expected";
                    }
                    if (message[".currencies.name"] != null && message.hasOwnProperty(".currencies.name"))
                        if (!$util.isString(message[".currencies.name"]))
                            return ".currencies.name: string expected";
                    if (message[".currencies.num"] != null && message.hasOwnProperty(".currencies.num"))
                        if (!$util.isInteger(message[".currencies.num"]))
                            return ".currencies.num: integer expected";
                    if (message[".currencies.unit"] != null && message.hasOwnProperty(".currencies.unit"))
                        if (!$util.isInteger(message[".currencies.unit"]))
                            return ".currencies.unit: integer expected";
                    if (message[".currencies.crypto"] != null && message.hasOwnProperty(".currencies.crypto"))
                        if (typeof message[".currencies.crypto"] !== "boolean")
                            return ".currencies.crypto: boolean expected";
                    if (message[".currencies.unofficial"] != null && message.hasOwnProperty(".currencies.unofficial"))
                        if (typeof message[".currencies.unofficial"] !== "boolean")
                            return ".currencies.unofficial: boolean expected";
                    if (message[".currencies.denom"] != null && message.hasOwnProperty(".currencies.denom"))
                        if (!$util.isInteger(message[".currencies.denom"]))
                            return ".currencies.denom: integer expected";
                    if (message[".currencies.stable"] != null && message.hasOwnProperty(".currencies.stable"))
                        if (typeof message[".currencies.stable"] !== "boolean")
                            return ".currencies.stable: boolean expected";
                    if (message[".currencies.testnet"] != null && message.hasOwnProperty(".currencies.testnet"))
                        if (typeof message[".currencies.testnet"] !== "boolean")
                            return ".currencies.testnet: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an EnumValueOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
                 */
                EnumValueOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.EnumValueOptions)
                        return object;
                    var message = new $root.google.protobuf.EnumValueOptions();
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    if (object[".currencies.country"]) {
                        if (!Array.isArray(object[".currencies.country"]))
                            throw TypeError(".google.protobuf.EnumValueOptions..currencies.country: array expected");
                        message[".currencies.country"] = [];
                        for (var i = 0; i < object[".currencies.country"].length; ++i)
                            message[".currencies.country"][i] = String(object[".currencies.country"][i]);
                    }
                    if (object[".currencies.symbol"]) {
                        if (!Array.isArray(object[".currencies.symbol"]))
                            throw TypeError(".google.protobuf.EnumValueOptions..currencies.symbol: array expected");
                        message[".currencies.symbol"] = [];
                        for (var i = 0; i < object[".currencies.symbol"].length; ++i)
                            message[".currencies.symbol"][i] = String(object[".currencies.symbol"][i]);
                    }
                    if (object[".currencies.code"]) {
                        if (!Array.isArray(object[".currencies.code"]))
                            throw TypeError(".google.protobuf.EnumValueOptions..currencies.code: array expected");
                        message[".currencies.code"] = [];
                        for (var i = 0; i < object[".currencies.code"].length; ++i)
                            message[".currencies.code"][i] = String(object[".currencies.code"][i]);
                    }
                    if (object[".currencies.name"] != null)
                        message[".currencies.name"] = String(object[".currencies.name"]);
                    if (object[".currencies.num"] != null)
                        message[".currencies.num"] = object[".currencies.num"] >>> 0;
                    if (object[".currencies.unit"] != null)
                        message[".currencies.unit"] = object[".currencies.unit"] >>> 0;
                    if (object[".currencies.crypto"] != null)
                        message[".currencies.crypto"] = Boolean(object[".currencies.crypto"]);
                    if (object[".currencies.unofficial"] != null)
                        message[".currencies.unofficial"] = Boolean(object[".currencies.unofficial"]);
                    if (object[".currencies.denom"] != null)
                        message[".currencies.denom"] = object[".currencies.denom"] >>> 0;
                    if (object[".currencies.stable"] != null)
                        message[".currencies.stable"] = Boolean(object[".currencies.stable"]);
                    if (object[".currencies.testnet"] != null)
                        message[".currencies.testnet"] = Boolean(object[".currencies.testnet"]);
                    return message;
                };
    
                /**
                 * Creates a plain object from an EnumValueOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {google.protobuf.EnumValueOptions} message EnumValueOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnumValueOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.uninterpretedOption = [];
                        object[".currencies.country"] = [];
                        object[".currencies.symbol"] = [];
                        object[".currencies.code"] = [];
                    }
                    if (options.defaults) {
                        object.deprecated = false;
                        object[".currencies.name"] = "";
                        object[".currencies.num"] = 0;
                        object[".currencies.unit"] = 0;
                        object[".currencies.crypto"] = false;
                        object[".currencies.unofficial"] = false;
                        object[".currencies.denom"] = 0;
                        object[".currencies.stable"] = false;
                        object[".currencies.testnet"] = false;
                    }
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    if (message[".currencies.country"] && message[".currencies.country"].length) {
                        object[".currencies.country"] = [];
                        for (var j = 0; j < message[".currencies.country"].length; ++j)
                            object[".currencies.country"][j] = message[".currencies.country"][j];
                    }
                    if (message[".currencies.symbol"] && message[".currencies.symbol"].length) {
                        object[".currencies.symbol"] = [];
                        for (var j = 0; j < message[".currencies.symbol"].length; ++j)
                            object[".currencies.symbol"][j] = message[".currencies.symbol"][j];
                    }
                    if (message[".currencies.code"] && message[".currencies.code"].length) {
                        object[".currencies.code"] = [];
                        for (var j = 0; j < message[".currencies.code"].length; ++j)
                            object[".currencies.code"][j] = message[".currencies.code"][j];
                    }
                    if (message[".currencies.name"] != null && message.hasOwnProperty(".currencies.name"))
                        object[".currencies.name"] = message[".currencies.name"];
                    if (message[".currencies.num"] != null && message.hasOwnProperty(".currencies.num"))
                        object[".currencies.num"] = message[".currencies.num"];
                    if (message[".currencies.unit"] != null && message.hasOwnProperty(".currencies.unit"))
                        object[".currencies.unit"] = message[".currencies.unit"];
                    if (message[".currencies.crypto"] != null && message.hasOwnProperty(".currencies.crypto"))
                        object[".currencies.crypto"] = message[".currencies.crypto"];
                    if (message[".currencies.unofficial"] != null && message.hasOwnProperty(".currencies.unofficial"))
                        object[".currencies.unofficial"] = message[".currencies.unofficial"];
                    if (message[".currencies.denom"] != null && message.hasOwnProperty(".currencies.denom"))
                        object[".currencies.denom"] = message[".currencies.denom"];
                    if (message[".currencies.stable"] != null && message.hasOwnProperty(".currencies.stable"))
                        object[".currencies.stable"] = message[".currencies.stable"];
                    if (message[".currencies.testnet"] != null && message.hasOwnProperty(".currencies.testnet"))
                        object[".currencies.testnet"] = message[".currencies.testnet"];
                    return object;
                };
    
                /**
                 * Converts this EnumValueOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnumValueOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return EnumValueOptions;
            })();
    
            protobuf.ServiceOptions = (function() {
    
                /**
                 * Properties of a ServiceOptions.
                 * @memberof google.protobuf
                 * @interface IServiceOptions
                 * @property {boolean|null} [deprecated] ServiceOptions deprecated
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ServiceOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new ServiceOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a ServiceOptions.
                 * @implements IServiceOptions
                 * @constructor
                 * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
                 */
                function ServiceOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ServiceOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.ServiceOptions
                 * @instance
                 */
                ServiceOptions.prototype.deprecated = false;
    
                /**
                 * ServiceOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.ServiceOptions
                 * @instance
                 */
                ServiceOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new ServiceOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
                 * @returns {google.protobuf.ServiceOptions} ServiceOptions instance
                 */
                ServiceOptions.create = function create(properties) {
                    return new ServiceOptions(properties);
                };
    
                /**
                 * Encodes the specified ServiceOptions message. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ServiceOptions message, length delimited. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ServiceOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.ServiceOptions} ServiceOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 33:
                            message.deprecated = reader.bool();
                            break;
                        case 999:
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ServiceOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.ServiceOptions} ServiceOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ServiceOptions message.
                 * @function verify
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a ServiceOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.ServiceOptions} ServiceOptions
                 */
                ServiceOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.ServiceOptions)
                        return object;
                    var message = new $root.google.protobuf.ServiceOptions();
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ServiceOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {google.protobuf.ServiceOptions} message ServiceOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults)
                        object.deprecated = false;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this ServiceOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.ServiceOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return ServiceOptions;
            })();
    
            protobuf.MethodOptions = (function() {
    
                /**
                 * Properties of a MethodOptions.
                 * @memberof google.protobuf
                 * @interface IMethodOptions
                 * @property {boolean|null} [deprecated] MethodOptions deprecated
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MethodOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new MethodOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a MethodOptions.
                 * @implements IMethodOptions
                 * @constructor
                 * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
                 */
                function MethodOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MethodOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.MethodOptions
                 * @instance
                 */
                MethodOptions.prototype.deprecated = false;
    
                /**
                 * MethodOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.MethodOptions
                 * @instance
                 */
                MethodOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new MethodOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
                 * @returns {google.protobuf.MethodOptions} MethodOptions instance
                 */
                MethodOptions.create = function create(properties) {
                    return new MethodOptions(properties);
                };
    
                /**
                 * Encodes the specified MethodOptions message. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MethodOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified MethodOptions message, length delimited. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MethodOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MethodOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.MethodOptions} MethodOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MethodOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 33:
                            message.deprecated = reader.bool();
                            break;
                        case 999:
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MethodOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.MethodOptions} MethodOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MethodOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MethodOptions message.
                 * @function verify
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MethodOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a MethodOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.MethodOptions} MethodOptions
                 */
                MethodOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.MethodOptions)
                        return object;
                    var message = new $root.google.protobuf.MethodOptions();
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a MethodOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {google.protobuf.MethodOptions} message MethodOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MethodOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults)
                        object.deprecated = false;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this MethodOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.MethodOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MethodOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return MethodOptions;
            })();
    
            protobuf.UninterpretedOption = (function() {
    
                /**
                 * Properties of an UninterpretedOption.
                 * @memberof google.protobuf
                 * @interface IUninterpretedOption
                 * @property {Array.<google.protobuf.UninterpretedOption.INamePart>|null} [name] UninterpretedOption name
                 * @property {string|null} [identifierValue] UninterpretedOption identifierValue
                 * @property {Long|null} [positiveIntValue] UninterpretedOption positiveIntValue
                 * @property {Long|null} [negativeIntValue] UninterpretedOption negativeIntValue
                 * @property {number|null} [doubleValue] UninterpretedOption doubleValue
                 * @property {Uint8Array|null} [stringValue] UninterpretedOption stringValue
                 * @property {string|null} [aggregateValue] UninterpretedOption aggregateValue
                 */
    
                /**
                 * Constructs a new UninterpretedOption.
                 * @memberof google.protobuf
                 * @classdesc Represents an UninterpretedOption.
                 * @implements IUninterpretedOption
                 * @constructor
                 * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
                 */
                function UninterpretedOption(properties) {
                    this.name = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UninterpretedOption name.
                 * @member {Array.<google.protobuf.UninterpretedOption.INamePart>} name
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.name = $util.emptyArray;
    
                /**
                 * UninterpretedOption identifierValue.
                 * @member {string} identifierValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.identifierValue = "";
    
                /**
                 * UninterpretedOption positiveIntValue.
                 * @member {Long} positiveIntValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.positiveIntValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
                /**
                 * UninterpretedOption negativeIntValue.
                 * @member {Long} negativeIntValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.negativeIntValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * UninterpretedOption doubleValue.
                 * @member {number} doubleValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.doubleValue = 0;
    
                /**
                 * UninterpretedOption stringValue.
                 * @member {Uint8Array} stringValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.stringValue = $util.newBuffer([]);
    
                /**
                 * UninterpretedOption aggregateValue.
                 * @member {string} aggregateValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.aggregateValue = "";
    
                /**
                 * Creates a new UninterpretedOption instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
                 * @returns {google.protobuf.UninterpretedOption} UninterpretedOption instance
                 */
                UninterpretedOption.create = function create(properties) {
                    return new UninterpretedOption(properties);
                };
    
                /**
                 * Encodes the specified UninterpretedOption message. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UninterpretedOption.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && message.name.length)
                        for (var i = 0; i < message.name.length; ++i)
                            $root.google.protobuf.UninterpretedOption.NamePart.encode(message.name[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.identifierValue != null && Object.hasOwnProperty.call(message, "identifierValue"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.identifierValue);
                    if (message.positiveIntValue != null && Object.hasOwnProperty.call(message, "positiveIntValue"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.positiveIntValue);
                    if (message.negativeIntValue != null && Object.hasOwnProperty.call(message, "negativeIntValue"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.negativeIntValue);
                    if (message.doubleValue != null && Object.hasOwnProperty.call(message, "doubleValue"))
                        writer.uint32(/* id 6, wireType 1 =*/49).double(message.doubleValue);
                    if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
                        writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.stringValue);
                    if (message.aggregateValue != null && Object.hasOwnProperty.call(message, "aggregateValue"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.aggregateValue);
                    return writer;
                };
    
                /**
                 * Encodes the specified UninterpretedOption message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UninterpretedOption.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UninterpretedOption message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UninterpretedOption.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2:
                            if (!(message.name && message.name.length))
                                message.name = [];
                            message.name.push($root.google.protobuf.UninterpretedOption.NamePart.decode(reader, reader.uint32()));
                            break;
                        case 3:
                            message.identifierValue = reader.string();
                            break;
                        case 4:
                            message.positiveIntValue = reader.uint64();
                            break;
                        case 5:
                            message.negativeIntValue = reader.int64();
                            break;
                        case 6:
                            message.doubleValue = reader.double();
                            break;
                        case 7:
                            message.stringValue = reader.bytes();
                            break;
                        case 8:
                            message.aggregateValue = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UninterpretedOption message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UninterpretedOption.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UninterpretedOption message.
                 * @function verify
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UninterpretedOption.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name")) {
                        if (!Array.isArray(message.name))
                            return "name: array expected";
                        for (var i = 0; i < message.name.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.NamePart.verify(message.name[i]);
                            if (error)
                                return "name." + error;
                        }
                    }
                    if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                        if (!$util.isString(message.identifierValue))
                            return "identifierValue: string expected";
                    if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                        if (!$util.isInteger(message.positiveIntValue) && !(message.positiveIntValue && $util.isInteger(message.positiveIntValue.low) && $util.isInteger(message.positiveIntValue.high)))
                            return "positiveIntValue: integer|Long expected";
                    if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                        if (!$util.isInteger(message.negativeIntValue) && !(message.negativeIntValue && $util.isInteger(message.negativeIntValue.low) && $util.isInteger(message.negativeIntValue.high)))
                            return "negativeIntValue: integer|Long expected";
                    if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                        if (typeof message.doubleValue !== "number")
                            return "doubleValue: number expected";
                    if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                        if (!(message.stringValue && typeof message.stringValue.length === "number" || $util.isString(message.stringValue)))
                            return "stringValue: buffer expected";
                    if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                        if (!$util.isString(message.aggregateValue))
                            return "aggregateValue: string expected";
                    return null;
                };
    
                /**
                 * Creates an UninterpretedOption message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
                 */
                UninterpretedOption.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UninterpretedOption)
                        return object;
                    var message = new $root.google.protobuf.UninterpretedOption();
                    if (object.name) {
                        if (!Array.isArray(object.name))
                            throw TypeError(".google.protobuf.UninterpretedOption.name: array expected");
                        message.name = [];
                        for (var i = 0; i < object.name.length; ++i) {
                            if (typeof object.name[i] !== "object")
                                throw TypeError(".google.protobuf.UninterpretedOption.name: object expected");
                            message.name[i] = $root.google.protobuf.UninterpretedOption.NamePart.fromObject(object.name[i]);
                        }
                    }
                    if (object.identifierValue != null)
                        message.identifierValue = String(object.identifierValue);
                    if (object.positiveIntValue != null)
                        if ($util.Long)
                            (message.positiveIntValue = $util.Long.fromValue(object.positiveIntValue)).unsigned = true;
                        else if (typeof object.positiveIntValue === "string")
                            message.positiveIntValue = parseInt(object.positiveIntValue, 10);
                        else if (typeof object.positiveIntValue === "number")
                            message.positiveIntValue = object.positiveIntValue;
                        else if (typeof object.positiveIntValue === "object")
                            message.positiveIntValue = new $util.LongBits(object.positiveIntValue.low >>> 0, object.positiveIntValue.high >>> 0).toNumber(true);
                    if (object.negativeIntValue != null)
                        if ($util.Long)
                            (message.negativeIntValue = $util.Long.fromValue(object.negativeIntValue)).unsigned = false;
                        else if (typeof object.negativeIntValue === "string")
                            message.negativeIntValue = parseInt(object.negativeIntValue, 10);
                        else if (typeof object.negativeIntValue === "number")
                            message.negativeIntValue = object.negativeIntValue;
                        else if (typeof object.negativeIntValue === "object")
                            message.negativeIntValue = new $util.LongBits(object.negativeIntValue.low >>> 0, object.negativeIntValue.high >>> 0).toNumber();
                    if (object.doubleValue != null)
                        message.doubleValue = Number(object.doubleValue);
                    if (object.stringValue != null)
                        if (typeof object.stringValue === "string")
                            $util.base64.decode(object.stringValue, message.stringValue = $util.newBuffer($util.base64.length(object.stringValue)), 0);
                        else if (object.stringValue.length)
                            message.stringValue = object.stringValue;
                    if (object.aggregateValue != null)
                        message.aggregateValue = String(object.aggregateValue);
                    return message;
                };
    
                /**
                 * Creates a plain object from an UninterpretedOption message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {google.protobuf.UninterpretedOption} message UninterpretedOption
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UninterpretedOption.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.name = [];
                    if (options.defaults) {
                        object.identifierValue = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.positiveIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.positiveIntValue = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.negativeIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.negativeIntValue = options.longs === String ? "0" : 0;
                        object.doubleValue = 0;
                        if (options.bytes === String)
                            object.stringValue = "";
                        else {
                            object.stringValue = [];
                            if (options.bytes !== Array)
                                object.stringValue = $util.newBuffer(object.stringValue);
                        }
                        object.aggregateValue = "";
                    }
                    if (message.name && message.name.length) {
                        object.name = [];
                        for (var j = 0; j < message.name.length; ++j)
                            object.name[j] = $root.google.protobuf.UninterpretedOption.NamePart.toObject(message.name[j], options);
                    }
                    if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                        object.identifierValue = message.identifierValue;
                    if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                        if (typeof message.positiveIntValue === "number")
                            object.positiveIntValue = options.longs === String ? String(message.positiveIntValue) : message.positiveIntValue;
                        else
                            object.positiveIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.positiveIntValue) : options.longs === Number ? new $util.LongBits(message.positiveIntValue.low >>> 0, message.positiveIntValue.high >>> 0).toNumber(true) : message.positiveIntValue;
                    if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                        if (typeof message.negativeIntValue === "number")
                            object.negativeIntValue = options.longs === String ? String(message.negativeIntValue) : message.negativeIntValue;
                        else
                            object.negativeIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.negativeIntValue) : options.longs === Number ? new $util.LongBits(message.negativeIntValue.low >>> 0, message.negativeIntValue.high >>> 0).toNumber() : message.negativeIntValue;
                    if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                        object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
                    if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                        object.stringValue = options.bytes === String ? $util.base64.encode(message.stringValue, 0, message.stringValue.length) : options.bytes === Array ? Array.prototype.slice.call(message.stringValue) : message.stringValue;
                    if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                        object.aggregateValue = message.aggregateValue;
                    return object;
                };
    
                /**
                 * Converts this UninterpretedOption to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UninterpretedOption.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                UninterpretedOption.NamePart = (function() {
    
                    /**
                     * Properties of a NamePart.
                     * @memberof google.protobuf.UninterpretedOption
                     * @interface INamePart
                     * @property {string} namePart NamePart namePart
                     * @property {boolean} isExtension NamePart isExtension
                     */
    
                    /**
                     * Constructs a new NamePart.
                     * @memberof google.protobuf.UninterpretedOption
                     * @classdesc Represents a NamePart.
                     * @implements INamePart
                     * @constructor
                     * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                     */
                    function NamePart(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * NamePart namePart.
                     * @member {string} namePart
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @instance
                     */
                    NamePart.prototype.namePart = "";
    
                    /**
                     * NamePart isExtension.
                     * @member {boolean} isExtension
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @instance
                     */
                    NamePart.prototype.isExtension = false;
    
                    /**
                     * Creates a new NamePart instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                     * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart instance
                     */
                    NamePart.create = function create(properties) {
                        return new NamePart(properties);
                    };
    
                    /**
                     * Encodes the specified NamePart message. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NamePart.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.namePart);
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isExtension);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified NamePart message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NamePart.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a NamePart message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NamePart.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption.NamePart();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.namePart = reader.string();
                                break;
                            case 2:
                                message.isExtension = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        if (!message.hasOwnProperty("namePart"))
                            throw $util.ProtocolError("missing required 'namePart'", { instance: message });
                        if (!message.hasOwnProperty("isExtension"))
                            throw $util.ProtocolError("missing required 'isExtension'", { instance: message });
                        return message;
                    };
    
                    /**
                     * Decodes a NamePart message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NamePart.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a NamePart message.
                     * @function verify
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    NamePart.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (!$util.isString(message.namePart))
                            return "namePart: string expected";
                        if (typeof message.isExtension !== "boolean")
                            return "isExtension: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a NamePart message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                     */
                    NamePart.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.UninterpretedOption.NamePart)
                            return object;
                        var message = new $root.google.protobuf.UninterpretedOption.NamePart();
                        if (object.namePart != null)
                            message.namePart = String(object.namePart);
                        if (object.isExtension != null)
                            message.isExtension = Boolean(object.isExtension);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a NamePart message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {google.protobuf.UninterpretedOption.NamePart} message NamePart
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    NamePart.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.namePart = "";
                            object.isExtension = false;
                        }
                        if (message.namePart != null && message.hasOwnProperty("namePart"))
                            object.namePart = message.namePart;
                        if (message.isExtension != null && message.hasOwnProperty("isExtension"))
                            object.isExtension = message.isExtension;
                        return object;
                    };
    
                    /**
                     * Converts this NamePart to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    NamePart.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return NamePart;
                })();
    
                return UninterpretedOption;
            })();
    
            protobuf.SourceCodeInfo = (function() {
    
                /**
                 * Properties of a SourceCodeInfo.
                 * @memberof google.protobuf
                 * @interface ISourceCodeInfo
                 * @property {Array.<google.protobuf.SourceCodeInfo.ILocation>|null} [location] SourceCodeInfo location
                 */
    
                /**
                 * Constructs a new SourceCodeInfo.
                 * @memberof google.protobuf
                 * @classdesc Represents a SourceCodeInfo.
                 * @implements ISourceCodeInfo
                 * @constructor
                 * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
                 */
                function SourceCodeInfo(properties) {
                    this.location = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SourceCodeInfo location.
                 * @member {Array.<google.protobuf.SourceCodeInfo.ILocation>} location
                 * @memberof google.protobuf.SourceCodeInfo
                 * @instance
                 */
                SourceCodeInfo.prototype.location = $util.emptyArray;
    
                /**
                 * Creates a new SourceCodeInfo instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
                 * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo instance
                 */
                SourceCodeInfo.create = function create(properties) {
                    return new SourceCodeInfo(properties);
                };
    
                /**
                 * Encodes the specified SourceCodeInfo message. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SourceCodeInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.location != null && message.location.length)
                        for (var i = 0; i < message.location.length; ++i)
                            $root.google.protobuf.SourceCodeInfo.Location.encode(message.location[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified SourceCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SourceCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SourceCodeInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SourceCodeInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.location && message.location.length))
                                message.location = [];
                            message.location.push($root.google.protobuf.SourceCodeInfo.Location.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SourceCodeInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SourceCodeInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SourceCodeInfo message.
                 * @function verify
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SourceCodeInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.location != null && message.hasOwnProperty("location")) {
                        if (!Array.isArray(message.location))
                            return "location: array expected";
                        for (var i = 0; i < message.location.length; ++i) {
                            var error = $root.google.protobuf.SourceCodeInfo.Location.verify(message.location[i]);
                            if (error)
                                return "location." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a SourceCodeInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
                 */
                SourceCodeInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.SourceCodeInfo)
                        return object;
                    var message = new $root.google.protobuf.SourceCodeInfo();
                    if (object.location) {
                        if (!Array.isArray(object.location))
                            throw TypeError(".google.protobuf.SourceCodeInfo.location: array expected");
                        message.location = [];
                        for (var i = 0; i < object.location.length; ++i) {
                            if (typeof object.location[i] !== "object")
                                throw TypeError(".google.protobuf.SourceCodeInfo.location: object expected");
                            message.location[i] = $root.google.protobuf.SourceCodeInfo.Location.fromObject(object.location[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SourceCodeInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {google.protobuf.SourceCodeInfo} message SourceCodeInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SourceCodeInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.location = [];
                    if (message.location && message.location.length) {
                        object.location = [];
                        for (var j = 0; j < message.location.length; ++j)
                            object.location[j] = $root.google.protobuf.SourceCodeInfo.Location.toObject(message.location[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this SourceCodeInfo to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.SourceCodeInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SourceCodeInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                SourceCodeInfo.Location = (function() {
    
                    /**
                     * Properties of a Location.
                     * @memberof google.protobuf.SourceCodeInfo
                     * @interface ILocation
                     * @property {Array.<number>|null} [path] Location path
                     * @property {Array.<number>|null} [span] Location span
                     * @property {string|null} [leadingComments] Location leadingComments
                     * @property {string|null} [trailingComments] Location trailingComments
                     * @property {Array.<string>|null} [leadingDetachedComments] Location leadingDetachedComments
                     */
    
                    /**
                     * Constructs a new Location.
                     * @memberof google.protobuf.SourceCodeInfo
                     * @classdesc Represents a Location.
                     * @implements ILocation
                     * @constructor
                     * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                     */
                    function Location(properties) {
                        this.path = [];
                        this.span = [];
                        this.leadingDetachedComments = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Location path.
                     * @member {Array.<number>} path
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.path = $util.emptyArray;
    
                    /**
                     * Location span.
                     * @member {Array.<number>} span
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.span = $util.emptyArray;
    
                    /**
                     * Location leadingComments.
                     * @member {string} leadingComments
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.leadingComments = "";
    
                    /**
                     * Location trailingComments.
                     * @member {string} trailingComments
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.trailingComments = "";
    
                    /**
                     * Location leadingDetachedComments.
                     * @member {Array.<string>} leadingDetachedComments
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.leadingDetachedComments = $util.emptyArray;
    
                    /**
                     * Creates a new Location instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                     * @returns {google.protobuf.SourceCodeInfo.Location} Location instance
                     */
                    Location.create = function create(properties) {
                        return new Location(properties);
                    };
    
                    /**
                     * Encodes the specified Location message. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Location.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.path != null && message.path.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.path.length; ++i)
                                writer.int32(message.path[i]);
                            writer.ldelim();
                        }
                        if (message.span != null && message.span.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.span.length; ++i)
                                writer.int32(message.span[i]);
                            writer.ldelim();
                        }
                        if (message.leadingComments != null && Object.hasOwnProperty.call(message, "leadingComments"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.leadingComments);
                        if (message.trailingComments != null && Object.hasOwnProperty.call(message, "trailingComments"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.trailingComments);
                        if (message.leadingDetachedComments != null && message.leadingDetachedComments.length)
                            for (var i = 0; i < message.leadingDetachedComments.length; ++i)
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.leadingDetachedComments[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Location message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Location.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Location message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.SourceCodeInfo.Location} Location
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Location.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo.Location();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.path && message.path.length))
                                    message.path = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.path.push(reader.int32());
                                } else
                                    message.path.push(reader.int32());
                                break;
                            case 2:
                                if (!(message.span && message.span.length))
                                    message.span = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.span.push(reader.int32());
                                } else
                                    message.span.push(reader.int32());
                                break;
                            case 3:
                                message.leadingComments = reader.string();
                                break;
                            case 4:
                                message.trailingComments = reader.string();
                                break;
                            case 6:
                                if (!(message.leadingDetachedComments && message.leadingDetachedComments.length))
                                    message.leadingDetachedComments = [];
                                message.leadingDetachedComments.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Location message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.SourceCodeInfo.Location} Location
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Location.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Location message.
                     * @function verify
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Location.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.path != null && message.hasOwnProperty("path")) {
                            if (!Array.isArray(message.path))
                                return "path: array expected";
                            for (var i = 0; i < message.path.length; ++i)
                                if (!$util.isInteger(message.path[i]))
                                    return "path: integer[] expected";
                        }
                        if (message.span != null && message.hasOwnProperty("span")) {
                            if (!Array.isArray(message.span))
                                return "span: array expected";
                            for (var i = 0; i < message.span.length; ++i)
                                if (!$util.isInteger(message.span[i]))
                                    return "span: integer[] expected";
                        }
                        if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                            if (!$util.isString(message.leadingComments))
                                return "leadingComments: string expected";
                        if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                            if (!$util.isString(message.trailingComments))
                                return "trailingComments: string expected";
                        if (message.leadingDetachedComments != null && message.hasOwnProperty("leadingDetachedComments")) {
                            if (!Array.isArray(message.leadingDetachedComments))
                                return "leadingDetachedComments: array expected";
                            for (var i = 0; i < message.leadingDetachedComments.length; ++i)
                                if (!$util.isString(message.leadingDetachedComments[i]))
                                    return "leadingDetachedComments: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a Location message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.SourceCodeInfo.Location} Location
                     */
                    Location.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.SourceCodeInfo.Location)
                            return object;
                        var message = new $root.google.protobuf.SourceCodeInfo.Location();
                        if (object.path) {
                            if (!Array.isArray(object.path))
                                throw TypeError(".google.protobuf.SourceCodeInfo.Location.path: array expected");
                            message.path = [];
                            for (var i = 0; i < object.path.length; ++i)
                                message.path[i] = object.path[i] | 0;
                        }
                        if (object.span) {
                            if (!Array.isArray(object.span))
                                throw TypeError(".google.protobuf.SourceCodeInfo.Location.span: array expected");
                            message.span = [];
                            for (var i = 0; i < object.span.length; ++i)
                                message.span[i] = object.span[i] | 0;
                        }
                        if (object.leadingComments != null)
                            message.leadingComments = String(object.leadingComments);
                        if (object.trailingComments != null)
                            message.trailingComments = String(object.trailingComments);
                        if (object.leadingDetachedComments) {
                            if (!Array.isArray(object.leadingDetachedComments))
                                throw TypeError(".google.protobuf.SourceCodeInfo.Location.leadingDetachedComments: array expected");
                            message.leadingDetachedComments = [];
                            for (var i = 0; i < object.leadingDetachedComments.length; ++i)
                                message.leadingDetachedComments[i] = String(object.leadingDetachedComments[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Location message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {google.protobuf.SourceCodeInfo.Location} message Location
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Location.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.path = [];
                            object.span = [];
                            object.leadingDetachedComments = [];
                        }
                        if (options.defaults) {
                            object.leadingComments = "";
                            object.trailingComments = "";
                        }
                        if (message.path && message.path.length) {
                            object.path = [];
                            for (var j = 0; j < message.path.length; ++j)
                                object.path[j] = message.path[j];
                        }
                        if (message.span && message.span.length) {
                            object.span = [];
                            for (var j = 0; j < message.span.length; ++j)
                                object.span[j] = message.span[j];
                        }
                        if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                            object.leadingComments = message.leadingComments;
                        if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                            object.trailingComments = message.trailingComments;
                        if (message.leadingDetachedComments && message.leadingDetachedComments.length) {
                            object.leadingDetachedComments = [];
                            for (var j = 0; j < message.leadingDetachedComments.length; ++j)
                                object.leadingDetachedComments[j] = message.leadingDetachedComments[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this Location to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Location.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return Location;
                })();
    
                return SourceCodeInfo;
            })();
    
            protobuf.GeneratedCodeInfo = (function() {
    
                /**
                 * Properties of a GeneratedCodeInfo.
                 * @memberof google.protobuf
                 * @interface IGeneratedCodeInfo
                 * @property {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>|null} [annotation] GeneratedCodeInfo annotation
                 */
    
                /**
                 * Constructs a new GeneratedCodeInfo.
                 * @memberof google.protobuf
                 * @classdesc Represents a GeneratedCodeInfo.
                 * @implements IGeneratedCodeInfo
                 * @constructor
                 * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
                 */
                function GeneratedCodeInfo(properties) {
                    this.annotation = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GeneratedCodeInfo annotation.
                 * @member {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>} annotation
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @instance
                 */
                GeneratedCodeInfo.prototype.annotation = $util.emptyArray;
    
                /**
                 * Creates a new GeneratedCodeInfo instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
                 * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo instance
                 */
                GeneratedCodeInfo.create = function create(properties) {
                    return new GeneratedCodeInfo(properties);
                };
    
                /**
                 * Encodes the specified GeneratedCodeInfo message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GeneratedCodeInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.annotation != null && message.annotation.length)
                        for (var i = 0; i < message.annotation.length; ++i)
                            $root.google.protobuf.GeneratedCodeInfo.Annotation.encode(message.annotation[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GeneratedCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GeneratedCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GeneratedCodeInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GeneratedCodeInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.annotation && message.annotation.length))
                                message.annotation = [];
                            message.annotation.push($root.google.protobuf.GeneratedCodeInfo.Annotation.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GeneratedCodeInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GeneratedCodeInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GeneratedCodeInfo message.
                 * @function verify
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GeneratedCodeInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.annotation != null && message.hasOwnProperty("annotation")) {
                        if (!Array.isArray(message.annotation))
                            return "annotation: array expected";
                        for (var i = 0; i < message.annotation.length; ++i) {
                            var error = $root.google.protobuf.GeneratedCodeInfo.Annotation.verify(message.annotation[i]);
                            if (error)
                                return "annotation." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GeneratedCodeInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
                 */
                GeneratedCodeInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.GeneratedCodeInfo)
                        return object;
                    var message = new $root.google.protobuf.GeneratedCodeInfo();
                    if (object.annotation) {
                        if (!Array.isArray(object.annotation))
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: array expected");
                        message.annotation = [];
                        for (var i = 0; i < object.annotation.length; ++i) {
                            if (typeof object.annotation[i] !== "object")
                                throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: object expected");
                            message.annotation[i] = $root.google.protobuf.GeneratedCodeInfo.Annotation.fromObject(object.annotation[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GeneratedCodeInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo} message GeneratedCodeInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GeneratedCodeInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.annotation = [];
                    if (message.annotation && message.annotation.length) {
                        object.annotation = [];
                        for (var j = 0; j < message.annotation.length; ++j)
                            object.annotation[j] = $root.google.protobuf.GeneratedCodeInfo.Annotation.toObject(message.annotation[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GeneratedCodeInfo to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GeneratedCodeInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                GeneratedCodeInfo.Annotation = (function() {
    
                    /**
                     * Properties of an Annotation.
                     * @memberof google.protobuf.GeneratedCodeInfo
                     * @interface IAnnotation
                     * @property {Array.<number>|null} [path] Annotation path
                     * @property {string|null} [sourceFile] Annotation sourceFile
                     * @property {number|null} [begin] Annotation begin
                     * @property {number|null} [end] Annotation end
                     */
    
                    /**
                     * Constructs a new Annotation.
                     * @memberof google.protobuf.GeneratedCodeInfo
                     * @classdesc Represents an Annotation.
                     * @implements IAnnotation
                     * @constructor
                     * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                     */
                    function Annotation(properties) {
                        this.path = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Annotation path.
                     * @member {Array.<number>} path
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     */
                    Annotation.prototype.path = $util.emptyArray;
    
                    /**
                     * Annotation sourceFile.
                     * @member {string} sourceFile
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     */
                    Annotation.prototype.sourceFile = "";
    
                    /**
                     * Annotation begin.
                     * @member {number} begin
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     */
                    Annotation.prototype.begin = 0;
    
                    /**
                     * Annotation end.
                     * @member {number} end
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     */
                    Annotation.prototype.end = 0;
    
                    /**
                     * Creates a new Annotation instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                     * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation instance
                     */
                    Annotation.create = function create(properties) {
                        return new Annotation(properties);
                    };
    
                    /**
                     * Encodes the specified Annotation message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Annotation.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.path != null && message.path.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.path.length; ++i)
                                writer.int32(message.path[i]);
                            writer.ldelim();
                        }
                        if (message.sourceFile != null && Object.hasOwnProperty.call(message, "sourceFile"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.sourceFile);
                        if (message.begin != null && Object.hasOwnProperty.call(message, "begin"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.begin);
                        if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.end);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Annotation message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Annotation.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an Annotation message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Annotation.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.path && message.path.length))
                                    message.path = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.path.push(reader.int32());
                                } else
                                    message.path.push(reader.int32());
                                break;
                            case 2:
                                message.sourceFile = reader.string();
                                break;
                            case 3:
                                message.begin = reader.int32();
                                break;
                            case 4:
                                message.end = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an Annotation message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Annotation.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an Annotation message.
                     * @function verify
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Annotation.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.path != null && message.hasOwnProperty("path")) {
                            if (!Array.isArray(message.path))
                                return "path: array expected";
                            for (var i = 0; i < message.path.length; ++i)
                                if (!$util.isInteger(message.path[i]))
                                    return "path: integer[] expected";
                        }
                        if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                            if (!$util.isString(message.sourceFile))
                                return "sourceFile: string expected";
                        if (message.begin != null && message.hasOwnProperty("begin"))
                            if (!$util.isInteger(message.begin))
                                return "begin: integer expected";
                        if (message.end != null && message.hasOwnProperty("end"))
                            if (!$util.isInteger(message.end))
                                return "end: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates an Annotation message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                     */
                    Annotation.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.GeneratedCodeInfo.Annotation)
                            return object;
                        var message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                        if (object.path) {
                            if (!Array.isArray(object.path))
                                throw TypeError(".google.protobuf.GeneratedCodeInfo.Annotation.path: array expected");
                            message.path = [];
                            for (var i = 0; i < object.path.length; ++i)
                                message.path[i] = object.path[i] | 0;
                        }
                        if (object.sourceFile != null)
                            message.sourceFile = String(object.sourceFile);
                        if (object.begin != null)
                            message.begin = object.begin | 0;
                        if (object.end != null)
                            message.end = object.end | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an Annotation message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {google.protobuf.GeneratedCodeInfo.Annotation} message Annotation
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Annotation.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.path = [];
                        if (options.defaults) {
                            object.sourceFile = "";
                            object.begin = 0;
                            object.end = 0;
                        }
                        if (message.path && message.path.length) {
                            object.path = [];
                            for (var j = 0; j < message.path.length; ++j)
                                object.path[j] = message.path[j];
                        }
                        if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                            object.sourceFile = message.sourceFile;
                        if (message.begin != null && message.hasOwnProperty("begin"))
                            object.begin = message.begin;
                        if (message.end != null && message.hasOwnProperty("end"))
                            object.end = message.end;
                        return object;
                    };
    
                    /**
                     * Converts this Annotation to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Annotation.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return Annotation;
                })();
    
                return GeneratedCodeInfo;
            })();
    
            return protobuf;
        })();
    
        return google;
    })();

    return $root;
});
