import { makeObservable, observable, action, computed } from 'mobx';
import { protocol } from '../api/proto';
import { AppState } from './AppState';
import { getMainRouteByRole } from './Roles';
import { formatServerErrors } from './utils/format';

export class LoginStore {
  private appState: AppState;

  @observable login: string = '';
  @observable password: string = '';
  @observable isLoading: boolean = false;
  @observable error?: string;

  constructor(appState: AppState) {
    makeObservable(this);
    this.appState = appState;
  }

  @action
  changeLogin = (val: string) => {
    this.login = val;
  };

  @action
  changePassword = (val: string) => {
    this.password = val;
  };

  @computed
  get isValid() {
    return this.password?.length > 1 && this.login?.length > 1;
  }

  @action
  logIn = () => {
    if (this.isValid) {
      this.isLoading = true;
      this.appState.api.authUserRequest(
        {
          login: this.login,
          password: this.password,
        },
        (msg: protocol.IServerResponse) => {
          this.isLoading = false;
          if (msg?.authUserResponse?.token) {
            this.appState.setTokenToLocalStorage(msg.authUserResponse.token);
            const route = getMainRouteByRole(msg.authUserResponse.user?.accessGroups);
            if (this.appState.navigate && this.appState.clientId)
              this.appState.navigate(route.replace(':clientId', this.appState.clientId));
          } else {
            this.error = formatServerErrors(msg.error);
          }
        },
      );
    }
  };
}
